import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { isNullish } from 'src/types';
import {
  asyncGetLastEventForDevice,
  FORCE_UPDATE_INTERVAL_MS,
  FORCE_UPDATE_MAX_TRIES,
  getNextForceUpdate
} from './reducer';

/**
 *
 */
function ForceUpdateTracker(): null {
  const { deviceId, numTries, fetchStatus } = useAppSelector(
    getNextForceUpdate
  ) ?? {
    numTries: 0
  };
  const dispatch = useAppDispatch();

  React.useEffect(
    /**
     * When a new device comes to the front of the queue
     * or is status changes from 'pending' to 'should fetch',
     * query the db for a new event
     */
    function handleForceUpdate() {
      if (
        !isNullish(deviceId) &&
        numTries <= FORCE_UPDATE_MAX_TRIES &&
        fetchStatus === 'shouldFetch'
      ) {
        const queryForEvent = () =>
          dispatch(asyncGetLastEventForDevice({ deviceId }));

        if (numTries === 0) {
          queryForEvent();
        } else {
          setTimeout(() => {
            queryForEvent();
          }, FORCE_UPDATE_INTERVAL_MS);
        }
      }
    },
    [deviceId, dispatch, fetchStatus, numTries]
  );
  return null;
}

export default ForceUpdateTracker;
