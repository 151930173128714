import type { DrawerProps, ModalProps, PaperProps } from '@material-ui/core';
import { createStyles, Drawer, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppIcon } from 'src/theme';
import { DashboardAppBar } from './AppBar';
import { APP_STYLE } from './AppThemeProvider';
import { AppFab } from './components';
import { DASHBOARD_ROUTES } from './dashboardRoutes';
import { useIsMobile } from './hooks';
import { Sidebar } from './sidebar';
import testIds from './testIds';

type StyleProps = {
  sidebarIsDocked: boolean;
};

const useStyles = makeStyles(({ spacing, palette, zIndex }) => {
  const { sidebarWidth, toolbarHeightPx, sidebarToggleSpacing } = APP_STYLE;
  return createStyles({
    root: {},
    pageContent: ({ sidebarIsDocked }: StyleProps) => ({
      bottom: 0,
      left: sidebarIsDocked ? sidebarWidth : 0,
      position: 'absolute',
      right: 0,
      top: toolbarHeightPx,
      paddingTop: spacing(1),
      paddingRight: spacing(2),
      paddingLeft: spacing(2)
    }),
    toolbar: (props) => ({
      marginLeft: props.sidebarIsDocked ? sidebarWidth : undefined
    }),
    sidebarRoot: {},
    sidebarContent: {
      zIndex: zIndex.appBar + 1,
      maxWidth: sidebarWidth,
      overflowX: 'hidden',
      padding: spacing(1),
      width: sidebarWidth
    },
    sidebarToggle: {
      backgroundColor: palette.common.white,
      color: palette.primary.dark,
      position: 'fixed',
      bottom: spacing(sidebarToggleSpacing.y),
      right: spacing(sidebarToggleSpacing.x)
    }
  });
});
const { sidebar: Ids } = testIds;

/**
 * @param props
 * @param props.className
 */
export function SidebarToggleBtn({
  className
}: {
  className?: string;
}): JSX.Element | null {
  const handleToggleSidebar = Sidebar.useToggle();

  const sidebarIsDocked = useSelector(Sidebar.getIsDocked);

  const classes = useStyles({ sidebarIsDocked });

  return sidebarIsDocked ? null : (
    <AppFab
      className={classNames(classes.sidebarToggle, className)}
      id={Ids.toggleBtn}
      onClick={handleToggleSidebar}
    >
      <AppIcon iconKey="LIST" />
    </AppFab>
  );
}
type Props = React.PropsWithChildren<{
  drawerContent: React.ReactNode;
  id: string;
}>;
const paperProps: PaperProps & { ['data-cy']: string } = {
  id: testIds.sidebar.content,
  'data-cy': testIds.sidebar.content
};
/**
 * @param props
 * @param props.drawerContent
 * @param props.pageContent
 * @param props.children
 * @param props.id
 */
function SidebarLayout({ drawerContent, children, id }: Props): JSX.Element {
  const sidebarIsDocked = useSelector(Sidebar.getIsDocked);
  const sidebarIsVisible = useSelector(Sidebar.getIsVisible);

  Sidebar.useDocker(useIsMobile());

  const handleToggleSidebar = Sidebar.useToggle();
  const { sidebarRoot, root, sidebarContent, ...classes } = useStyles({
    sidebarIsDocked
  });
  const drawerClasses = React.useMemo(
    () => ({
      root: sidebarRoot,
      paper: sidebarContent
    }),
    [sidebarContent, sidebarRoot]
  );
  const sidebarVariant: DrawerProps['variant'] = sidebarIsDocked
    ? 'permanent'
    : 'temporary';

  const modalPropsMemo = React.useMemo(
    (): Partial<ModalProps> => ({
      BackdropProps: {
        id: testIds.sidebar.backdrop,
        onClick: handleToggleSidebar
      }
    }),
    [handleToggleSidebar]
  );

  return (
    <div className={root} data-cy={id} id={id}>
      <DashboardAppBar classes={classes} links={DASHBOARD_ROUTES} />
      <Drawer
        ModalProps={modalPropsMemo}
        PaperProps={paperProps}
        anchor="left"
        classes={drawerClasses}
        data-cy={Ids.root}
        id={Ids.root}
        onClose={handleToggleSidebar}
        open={sidebarIsVisible}
        variant={sidebarVariant}
      >
        {drawerContent}
      </Drawer>
      <div
        className={classes.pageContent}
        data-cy={testIds.pageContent}
        id={testIds.pageContent}
      >
        {children}
      </div>
    </div>
  );
}

export default SidebarLayout;
