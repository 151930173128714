import type { TextFieldProps, Theme } from '@material-ui/core';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { isFalsy } from 'utility-types';

const useStyles = makeStyles<Theme, { readonly isError: boolean }>(
  ({ palette }) =>
    createStyles({
      helperText: ({ isError }) => {
        return {
          color: isError ? palette.error.main : undefined
        };
      },
      root: {}
    })
);

type AppTextFieldProps<T extends string = string> = Pick<
  TextFieldProps,
  | 'autoComplete'
  | 'className'
  | 'disabled'
  | 'fullWidth'
  | 'helperText'
  | 'id'
  | 'InputLabelProps'
  | 'InputProps'
  | 'inputProps'
  | 'inputRef'
  | 'label'
  | 'margin'
  | 'maxRows'
  | 'minRows'
  | 'multiline'
  | 'onChange'
  | 'onClick'
  | 'placeholder'
  | 'required'
  | 'rows'
  | 'rowsMax'
  | 'size'
  | 'style'
  | 'type'
  | 'value'
  | 'variant'
> & {
  readonly name: T;
  readonly errorMessage?: string | null;
};
type AppTextFieldPropsWithRegister<T extends string = string> = Omit<
  AppTextFieldProps<T>,
  'errorMessage' | 'inputRef'
> & {
  readonly registerOptions: RegisterOptions;
};
export type { AppTextFieldProps, AppTextFieldPropsWithRegister };
/**
 * Default text input field, which sets test ids and displays error messages when passed as a prop

 * @param props
 * @param props.errorMessage
 * @param props.autoComplete
 * @param props.InputProps
 * @param props.value
 * @param props.inputProps
 * @param props.name
 * @param props.className
 * @param props.onClick
 * @param props.onChange
 * @param props.id
 * @param props.variant
 * @param props.helperText
 * @param props.placeholder
 * @param props.fullWidth
 * @param props.inputRef
 * @param props.size
 * @param props.classes
 * @param props.label
 * @param props.dataCy
 * @param props.InputLabelProps
 * @param props.disabled
 * @param props.required
 * @param props.type
 * @param props.rest
 */
export default function AppTextField<T extends string = string>(
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  {
    autoComplete,
    className,
    errorMessage,
    fullWidth,
    helperText,
    id,
    inputProps,
    InputProps,
    inputRef,
    label,
    name,
    onChange,
    onClick,
    placeholder,
    InputLabelProps,
    size,
    value,
    variant,
    disabled,
    required,
    type,

    ...rest
  }: // ...rest
  AppTextFieldProps<T>
): JSX.Element {
  const isError = !isFalsy(errorMessage);
  const styles = useStyles({ isError });

  const testId = id ?? name;

  return (
    <TextField
      {...rest}
      FormHelperTextProps={{
        id: `${name}-feedback`
      }}
      InputLabelProps={{
        id: `${name}-label`,
        ...InputLabelProps
      }}
      InputProps={{
        autoComplete,
        id: `mui-${testId}`,
        ...InputProps
      }}
      autoComplete={autoComplete}
      className={classNames(styles.root, className)}
      data-cy={testId ?? `${name}-field`}
      disabled={disabled}
      error={isError}
      fullWidth={fullWidth}
      helperText={errorMessage ?? helperText}
      id={testId}
      inputProps={{
        ...inputProps,
        autoComplete,
        id: `${name}-input`
      }}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      required={required}
      size={size}
      type={type}
      value={value ?? (typeof onChange === 'undefined' ? undefined : '')}
      variant={variant}
    />
  );
}
