import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSignOut } from 'src/auth/authRequests';
import type { EnhancedButtonProps } from 'src/components';
import { AppButton } from 'src/components';
import { User } from 'src/userSession.reducer';

/**
 * @param props
 * @param props.variant
 * @param props.rest
 */
export default function SignOutBtn({
  variant = `text`
}: EnhancedButtonProps): JSX.Element | null {
  const history = useHistory();
  const signOut = useSignOut();
  const authStatus = useSelector(User.getAuthStatus);

  const handleClick = React.useCallback(async () => {
    return signOut().then(() => history.push('/'));
  }, [history, signOut]);

  return authStatus === `fulfilled` ? (
    <AppButton
      id="sign-out-btn"
      onClick={handleClick}
      presetKey="ACTION"
      variant={variant}
    >
      Sign Out
    </AppButton>
  ) : null;
}
