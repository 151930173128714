import type { EntityState, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { isString } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import store from 'storejs';

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAllOf,
  isAnyOf,
  isAsyncThunkAction,
  isFulfilled,
  isRejected,
  isRejectedWithValue
} from '@reduxjs/toolkit';

import { asyncCreateFarm } from './account-settings/asyncCreateFarm';
import asyncUserJoinFarm from './account-settings/asyncUserJoinFarm';
import {
  asyncAcceptTermsOfService,
  asyncSetDebugMode,
  asyncToggleMetric
} from './account-settings/userRequests';
import Actions from './actions';
import asyncGetUserData from './asyncGetUserData';
import authRequests from './auth/authRequests';
import { Geo } from './geo';
import { useAppSelector } from './hooks';
import FarmUserPermissions from './permissions/FarmUserPermissions';
import { getActiveFarmId } from './selectors';
import { asyncGetReelRunsForField } from './status-map/fieldRunHistory.reducer';
import {
  isArrayOrReadonlyArray,
  isNullish,
  isPlainObj,
  isPositiveInt
} from './types';

import type { FarmSummary } from './asyncGetUserData';
import type { FarmAccount, ThemeType, UserAccount } from './farm';
import type { FarmUserPermissionsMap } from './permissions';
import type {
  FarmUserPermissionKey,
  RequiredPermissions
} from './permissions/FarmUserPermissions';
import type { RootState, RootThunkApi } from './store';
type PermissionDenied = {
  code: 'DENIED';
  missingPermissions: FarmUserPermissionKey[];
};

/**
 * @param target
 */
function isPermissionDeniedObject(target: unknown): target is PermissionDenied {
  if (isPlainObj(target)) {
    const x = target as Partial<PermissionDenied>;
    if (x.code === 'DENIED') {
      return (x.missingPermissions?.length ?? 0) > 1;
    }
  }
  return false;
}

const PERMISSION_GRANTED = {
  code: 'OK',
  missingPermissions: null
} as const;
type PermissionGranted = typeof PERMISSION_GRANTED;
/**
 * @param arg
 * @param arg.type
 */
function parseActionType(arg: { readonly type: string }) {
  return arg.type.split(`/`)[0] ?? arg.type ?? '';
}
type RequestName = string | { typePrefix: string };

/**
 * @param r
 */
const parseRequestName = (r: RequestName) =>
  typeof r === 'string' ? r : r.typePrefix;

type FetchStatus = 'fulfilled' | 'pending' | 'rejected' | 'shouldFetch';
type TrackedRequest = {
  readonly requestName: string;
  readonly fetchStatus?: FetchStatus;
  readonly requestId: string;
};
const requestTracker = createEntityAdapter<TrackedRequest>({
  selectId: (model) => model.requestName
});

type ActiveFarm = Pick<FarmAccount, 'gpsLocation' | 'id' | 'name'> & {
  address: string;
};
type UserPreferences = Pick<
  UserAccount,
  'isDebug' | 'themePreference' | 'tipsEnabled' | 'usesMetricSystem'
> & {
  adminMode?: boolean;
};

type LoaderKey = 'modal' | 'root';

interface State
  extends Pick<UserAccount, 'isAdmin' | 'termsOfServiceAccepted'> {
  permissionDenied?: {
    missingPermissions: FarmUserPermissionKey[];
  };
  activeFarmId?: number;
  authStatus: FetchStatus;
  email?: string;
  errorCode?: RootThunkApi['rejectValue'];
  farmAccounts: FarmSummary[];
  farmStatus?: 'USER_CREATED_FARM' | 'USER_JOINED_FARM';
  loadGmaps: FetchStatus;
  permissions: FarmUserPermissionsMap;
  preferences: UserPreferences;
  requests: EntityState<TrackedRequest>;
  showLoader?: LoaderKey;
}
const initialState: State = {
  authStatus: 'shouldFetch',
  farmAccounts: [],
  isAdmin: false,
  loadGmaps: 'shouldFetch',
  permissions: { ...FarmUserPermissions.allFalse },
  preferences: {
    tipsEnabled: false,
    usesMetricSystem: false
  },
  requests: requestTracker.getInitialState({}),
  termsOfServiceAccepted: false,
  showLoader: 'root'
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.clearNewFarm, (state) => {
        state.farmStatus = undefined;
        requestTracker.upsertOne(state.requests, {
          fetchStatus: 'shouldFetch',
          requestId: '',
          requestName: asyncGetUserData.typePrefix
        });
      })
      .addCase(Actions.clearFetchStatus, (state, { payload: id }) => {
        requestTracker.updateOne(state.requests, {
          changes: {
            fetchStatus: undefined
          },
          id
        });
      })

      .addCase(
        asyncCreateFarm.fulfilled,
        /**
         *
         * @param state
         * @param action
         * @param action.payload
         */
        function setNewActiveFarm(state, { payload: farm }) {
          state.activeFarmId = farm.id;
          state.farmStatus = 'USER_CREATED_FARM';
        }
      )
      .addCase(asyncUserJoinFarm.fulfilled, (state, { payload }) => {
        if (payload.codeStatus === 'SUCCESS') {
          state.activeFarmId = payload.activeFarmId;
          state.farmStatus = 'USER_JOINED_FARM';
        }
      })
      .addCase(asyncSetDebugMode.fulfilled, (state, action) => {
        state.preferences.isDebug = action.meta.arg.value;
      })
      .addCase(asyncToggleMetric.fulfilled, (state) => {
        state.preferences.usesMetricSystem =
          !state.preferences.usesMetricSystem;
      })
      .addCase(asyncAcceptTermsOfService.fulfilled, (state) => {
        state.termsOfServiceAccepted = true;
      })
      .addCase(Actions.setAdminMode, (state, { payload: value }) => {
        if (state.isAdmin) {
          state.preferences.adminMode = value;
        }
      })

      .addCase(
        asyncGetUserData.fulfilled,
        /**
         *
         * @param state
         * @param action
         */
        function handleActiveUserResponse(state, action) {
          const {
            isAdmin,
            termsOfServiceAccepted,
            usesMetricSystem,
            email,
            activeFarm,
            farms,
            permissions,
            tipsEnabled,
            activeFarmId
          } = action.payload;
          state.isAdmin = isAdmin;
          state.email = email;
          state.farmAccounts = farms;
          state.termsOfServiceAccepted = termsOfServiceAccepted;
          state.preferences = {
            tipsEnabled,
            usesMetricSystem
          };
          state.activeFarmId = activeFarmId ?? undefined;
          if (!isNullish(activeFarm)) {
            state.permissions = {
              ...permissions
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(asyncGetReelRunsForField.pending),
        function showModalLoader(state) {
          state.showLoader = 'modal';
        }
      )
      .addMatcher(
        isAnyOf(asyncGetUserData.pending),
        /**
         *
         * @param state
         */
        function showRootLoader(state) {
          state.showLoader = 'root';
        }
      )
      .addMatcher(
        isAnyOf(isRejected, isFulfilled),

        /**
         *
         * @param state
         */
        function hideLoaders(state) {
          state.showLoader = undefined;
        }
      )
      .addMatcher(
        isRejectedWithValue,
        /**
         *
         * @param state
         * @param action
         */
        function handleThunkErrors(state, action) {
          if (
            action.meta.rejectedWithValue &&
            isPermissionDeniedObject(action.payload)
          ) {
            state.permissionDenied = {
              missingPermissions: [...action.payload.missingPermissions]
            };
          }
        }
      )
      .addMatcher(
        isAnyOf(
          authRequests.asyncSubmitForgotPasswordEmail.pending,
          authRequests.asyncSignIn.pending
        ),

        /**
         *
         * @param state
         * @param param1
         * @param param1.meta
         */
        function getEmailFromSignIn(state, { meta }) {
          state.email = meta.arg.email;
        }
      )

      .addMatcher(
        isAnyOf(
          authRequests.asyncCheckAuth.pending,
          authRequests.asyncCheckAuth.rejected,
          authRequests.asyncCheckAuth.fulfilled,
          authRequests.asyncSignIn.rejected,
          authRequests.asyncSignIn.fulfilled
        ),
        /**
         *
         * @param state
         * @param action
         */
        function trackUserAuthStatus(state, action) {
          state.authStatus = action.meta.requestStatus;
        }
      )
      .addMatcher(
        isAnyOf(
          authRequests.asyncCheckAuth.fulfilled,
          authRequests.asyncSignIn.fulfilled
        ),
        (state) => {
          requestTracker.upsertOne(state.requests, {
            fetchStatus: 'shouldFetch',
            requestId: '',
            requestName: asyncGetUserData.typePrefix
          });
        }
      )
      .addMatcher(
        isAnyOf(
          authRequests.asyncSignOut.fulfilled,
          authRequests.asyncCheckAuth.rejected
        ),
        (state) => {
          state.authStatus = 'rejected';
        }
      )
      .addMatcher(
        isAsyncThunkAction,
        /**
         *
         * @param state
         * @param param1
         * @param param1.meta
         * @param param1.type
         * @param action
         */
        function trackRequests(state, action) {
          const { meta, type } = action;

          const { requestId, requestStatus } = meta;
          requestTracker.upsertOne(state.requests, {
            fetchStatus: requestStatus,
            requestId,
            requestName: parseActionType({ type })
          });
        }
      )

      .addMatcher(
        isAllOf(isAsyncThunkAction, isRejectedWithValue),
        (state, action) => {
          state.errorCode = action.payload;
        }
      ),
  initialState,
  name: 'userSession',
  reducers: {
    /**
     * @param state
     */
    clearErrors(state) {
      requestTracker.updateMany(
        state.requests,
        state.requests.ids
          .filter(
            (id) => state.requests.entities[id]?.fetchStatus === 'rejected'
          )
          .map((id) => {
            const prev = state.requests.entities[id];
            if (isNullish(prev)) {
              throw new Error('Not tracker request');
            }
            return {
              changes: { fetchStatus: undefined },
              id
            };
          })
      );
    },

    /**
     * @param state
     */
    clearPermissionDenied(state) {
      state.permissionDenied = undefined;
    },

    /**
     * @param state
     * @param action
     */
    denyPermission(
      state,
      action: PayloadAction<{
        missingPermissions: FarmUserPermissionKey[];
      }>
    ) {
      state.permissionDenied = action.payload;
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    setMapLoadStatus(state, { payload }: PayloadAction<FetchStatus>) {
      state.loadGmaps = payload;
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    setThemePreference(state, { payload }: PayloadAction<ThemeType>) {
      state.preferences.themePreference = payload;
    }
  }
});

const { actions } = slice;
const {
  clearErrors,
  setMapLoadStatus,
  denyPermission,
  setThemePreference,
  clearPermissionDenied
} = actions;

const getSlice = (state: RootState) => state.userSession;
const getErrorCode = (
  state: RootState
):
  | 'CodeMismatchException'
  | 'InvalidParameterException'
  | 'No current user'
  | 'NotAuthorizedException'
  | 'UsernameExistsException'
  | 'UserNotConfirmedException'
  | 'UserNotFoundException'
  | null => state.auth.errorCode;

const getRequests = createSelector(getSlice, (s) => s.requests);
const getActiveLoader = createSelector(getSlice, (s) => s.showLoader);
const { selectById } = requestTracker.getSelectors(getRequests);

const getRequestByName = createCachedSelector(
  (state: RootState, name: RequestName) =>
    selectById(state, parseRequestName(name)),
  (req) => req
)((_, n) => parseRequestName(n));

const getFetchStatusByName = createCachedSelector(
  getRequestByName,
  (req) => req?.fetchStatus
)((_, n) => parseRequestName(n));
/**
 * Checks http request slice to find whether given request is current pending
 *
 * @param req async thunk or its name (type prefix)
 * @returns true if the request is pending else false
 */
const useIsPending = (req: RequestName): boolean =>
  useSelector((s) => getFetchStatusByName(s, req) === 'pending');

const getLoaderIsActive = createCachedSelector(
  (state: RootState) => state.userSession.showLoader,
  (_s: RootState, name: LoaderKey) => name,
  (current, key) => key === current
)((_, name) => name);

const getAuthStatus = createSelector(getSlice, (s) => s.authStatus);

const getActiveFarm = createSelector(
  getActiveFarmId,
  (state: RootState) => state.userSession.farmAccounts,
  (id, farms) => {
    if (isPositiveInt(id)) {
      return farms.find((f) => f.id === id);
    }
    return undefined;
  }
);

const getUserPreferences = createStructuredSelector<RootState, UserPreferences>(
  {
    themePreference: (state) => state.userSession.preferences.themePreference,
    tipsEnabled: (state) => state.userSession.preferences.tipsEnabled,
    usesMetricSystem: (state) => state.userSession.preferences.usesMetricSystem
  }
);
const getIsAdmin = createSelector(getSlice, (state) => state.isAdmin);
const getFarms = createSelector(getSlice, (state) => state.farmAccounts);

const getAppIsLoaded = createSelector(
  (state: RootState) =>
    getFetchStatusByName(state, authRequests.asyncCheckAuth),
  (state: RootState) => getFetchStatusByName(state, asyncGetUserData),
  (auth, user) => auth === 'fulfilled' && user === 'fulfilled'
);
const getPermissions = createSelector(getSlice, (state) => state.permissions);
const getHasPermissions = createCachedSelector(
  getPermissions,
  getIsAdmin,
  (_state: RootState, required: RequiredPermissions | null | undefined) =>
    required,
  (provided, isAdmin, required): PermissionDenied | PermissionGranted => {
    const passed = { code: 'OK', missingPermissions: null } as const;
    if (isAdmin || isNullish(required)) {
      return passed;
    }
    const asArray = isArrayOrReadonlyArray(required) ? required : [required];
    const initial = [] as FarmUserPermissionKey[];
    const missingPermissions = asArray.reduce(
      (acc, key) => (!provided[key] ? [...acc, key] : acc),
      initial
    );
    const failed = { code: 'DENIED', missingPermissions } as const;
    return missingPermissions.length ? failed : PERMISSION_GRANTED;
  }
)((_state, required) =>
  isString(required)
    ? required
    : isNullish(required)
    ? 'null'
    : JSON.stringify(required)
);

const userSession: Reducer<State> = slice.reducer;

const getFarmLocationGmaps = createSelector(getActiveFarm, (farm) => {
  if (farm?.gpsLocation) {
    return Geo.points.to.googleMaps(farm.gpsLocation);
  }
  return undefined;
});
const getActiveUserEmail = createSelector(getSlice, ({ email }) => email);
export const User = {
  ...actions,
  getAuthStatus,
  getEmail: getActiveUserEmail,
  getFarmLocationGmaps,
  getFarms,
  getHasNoFarms: createSelector(
    (state: RootState) =>
      selectById(state, asyncGetUserData.typePrefix)?.fetchStatus,
    getFarms,
    (loadStatus, farms) => loadStatus === 'fulfilled' && farms.length === 0
  ),
  getHasPermissions,
  getIsAdmin,
  getMissingPermissions: createSelector(
    getSlice,
    (s) => s.permissionDenied?.missingPermissions
  ),
  getOtherFarms: createSelector(getFarms, getActiveFarmId, (farms, activeId) =>
    farms.filter((f) => f.id !== activeId)
  ),

  getPermissions,
  getPreferences: getUserPreferences,

  /**
   *
   */
  useActiveFarm() {
    return useSelector(getActiveFarm);
  },

  /**
   * @param required
   */
  useHasPermissions(
    required?: RequiredPermissions | null
  ): ReturnType<typeof getHasPermissions> {
    return useAppSelector((state) => {
      if (!isNullish(required)) {
        return getHasPermissions(state, required);
      }
      return PERMISSION_GRANTED;
    });
  },

  /**
   *
   */
  useIsAdmin(): boolean {
    return useAppSelector(getIsAdmin);
  },

  /**
   *
   */
  useIsMetric(): boolean {
    return useAppSelector(getUserPreferences).usesMetricSystem;
  },

  /**
   *
   */
  useThemePreference(): ThemeType | undefined {
    return store.get('themePreference') as ThemeType | undefined;
  }
};

const useAppIsLoaded = (): boolean => useSelector(getAppIsLoaded);

export default userSession;
export type {
  FetchStatus,
  TrackedRequest,
  FarmSummary,
  UserPreferences,
  PermissionDenied,
  PermissionGranted,
  ActiveFarm
};
export {
  getFarmLocationGmaps,
  getErrorCode,
  asyncSetDebugMode,
  asyncToggleMetric,
  asyncUserJoinFarm,
  clearPermissionDenied,
  getActiveUserEmail,
  getFetchStatusByName,
  denyPermission,
  getActiveLoader,
  getActiveFarm,
  getRequests,
  getAuthStatus,
  getLoaderIsActive,
  getUserPreferences,
  isPermissionDeniedObject,
  PERMISSION_GRANTED,
  clearErrors,
  setMapLoadStatus,
  setThemePreference,
  useIsPending,
  useAppIsLoaded,
  getAppIsLoaded
};
