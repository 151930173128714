import { isNumber } from 'lodash-es';
import React from 'react';
import PressureReadingDisplay from 'src/devices/sensors/pressure-thresholds/PressureReadingDisplay';
import { isNullish } from 'src/types';
import type { DeviceIdProp } from '../models';
import {
  useSensorConfigByDeviceId,
  useSensorEventByDeviceId
} from '../selectors';
import useFormatSensorValue from '../sensors/useFormatSensorValue';

/**
 * @param props
 * @param props.deviceId
 */
export default function PressureSensorSummary({
  deviceId
}: DeviceIdProp): JSX.Element | null {
  const data = useSensorEventByDeviceId('pressure', deviceId);
  const config = useSensorConfigByDeviceId('pressure', deviceId);
  const formatVal = useFormatSensorValue();
  if (config === null) {
    return null;
  }
  if (isNullish(data)) {
    return null;
  }
  const { thresholdPsiLower, thresholdPsiUpper } = config;
  if (isNullish(data.readingKpa)) {
    return null;
  }
  return (
    <React.Fragment>
      {formatVal('readingKpa', data.readingKpa)}
      {isNumber(thresholdPsiLower) && isNumber(thresholdPsiUpper) ? (
        <PressureReadingDisplay
          readingKpa={data.readingKpa}
          thresholdPsiLower={thresholdPsiLower}
          thresholdPsiUpper={thresholdPsiUpper}
        />
      ) : null}
    </React.Fragment>
  );
}
