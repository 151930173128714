import { isString } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Flip, ToastContainer } from 'react-toastify';
import { useAppDispatch, useIsMobile } from 'src/hooks';
import PermissionDeniedDialog from 'src/permissions/PermissionDeniedDialog';
import store from 'storejs';

import { CssBaseline } from '@material-ui/core';
import { useJsApiLoader } from '@react-google-maps/api';
import * as Sentry from '@sentry/react';

import { TermsOfServiceDialog } from './account-settings';
import NewFarmSuccess from './account-settings/NewFarmSuccess';
import NoFarms from './account-settings/NoFarms';
import Actions from './actions';
import { getThemePreference } from './appSelectors';
import AppThemeProvider from './AppThemeProvider';
import asyncGetUserData from './asyncGetUserData';
import { AUTH_PATHS } from './auth/auth-paths';
import AuthCheck from './auth/AuthCheck';
import SignOut from './auth/SignOut';
import { RootLoader } from './components';
import ModalLoader from './components/loading/ModalLoader';
import { FullscreenLoader } from './components/loading/RootLoader';
import { googleMapsApiKey, NO_CURRENT_USER } from './constants';
import PATHS from './constants/Paths';
import Dev from './development/Dev';
import ForceUpdateTracker from './devices/force-update/ForceUpdateTracker';
import Page404 from './error/Page404';
import Page500 from './error/Page500';
import { handleApiRequestError } from './requests';
import { Sidebar } from './sidebar';
import StatusMap from './status-map';
import testIds from './testIds';
import { isPlainObj, isPositiveInt } from './types';
import {
  getFetchStatusByName,
  setMapLoadStatus,
  User
} from './userSession.reducer';
import { appLogger } from './utilities';

import type { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader';
import type { ConnectedProps } from 'react-redux';
const Support = React.lazy(async () => import('./support/Support'));
const AccountSettings = React.lazy(async () => import('./account-settings'));
const AdminSwitchFarms = React.lazy(
  async () => import('./account-settings/AdminSwitchFarms')
);

const testId = testIds.appRoot;

const MAP_LIBRARIES: UseLoadScriptOptions['libraries'] = ['drawing'];

const connector = connect((state) => ({
  userHasNoFarms: User.getHasNoFarms(state),
  authStatus: User.getAuthStatus(state),
  themePreference: getThemePreference(state),
  userLoadStatus: getFetchStatusByName(state, asyncGetUserData.typePrefix)
}));
type Props = ConnectedProps<typeof connector>;
/**
 * @param props
 * @param props.userHasNoFarms
 * @param props.userLoadStatus
 * @param props.authStatus
 * @param props.sidebarIsDocked
 * @param props.themePreference
 */
function App({
  userHasNoFarms,
  userLoadStatus,
  authStatus,
  themePreference
}: Props): JSX.Element | null {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  /**
   * Docks/undocks sidebar when screen size changes
   */
  Sidebar.useDocker(isMobile);

  /**
   * Loads google maps API
   */
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
    libraries: MAP_LIBRARIES
  });

  React.useEffect(() => {
    /**
     * When the user navigates from one page to another, close the sidebar
     */
    function handleUserNavigation() {
      dispatch(Actions.closeSidebar());
    }
    isString(pathname) && handleUserNavigation();
  }, [dispatch, pathname]);

  React.useEffect(
    /**
     * Defer app loading until google maps is ready to go
     */
    function trackMapsLoading() {
      dispatch(setMapLoadStatus('pending'));
      if (isLoaded) {
        dispatch(setMapLoadStatus('fulfilled'));
      } else if (loadError) {
        dispatch(setMapLoadStatus('rejected'));
      }
    },
    [dispatch, isLoaded, loadError]
  );

  React.useEffect(() => {
    /**
     * Once the user is authenticated, request their data from the FHQ backend
     *
     * Active farm id is kept in local storage. If the id is undefined,
     * an additional request is sent to the backend (before the main request)
     * to find an active farm for the user and get their id.
     *
     * We use this approach because when keeping the active farm id purely
     * in the database,  we had issues with multi-farm users that shared
     * accounts performing database operations from the UI after their
     * active farm id had been changed by another user, and invalid operations
     * were committed to the database as a result
     */
    function getUserData() {
      dispatch(asyncGetUserData())
        .unwrap()
        .then(({ activeFarmId }) => {
          if (isPositiveInt(activeFarmId)) {
            store.set('activeFarmId', activeFarmId);
          }
        })
        .catch((err) => {
          if (
            err === NO_CURRENT_USER ||
            (isPlainObj(err) &&
              'message' in err &&
              isString(err.message) &&
              err.message === NO_CURRENT_USER)
          ) {
            appLogger.info(err);
          } else {
            handleApiRequestError(err);
          }
        });
    }
    if (authStatus === 'fulfilled' && userLoadStatus === 'shouldFetch') {
      getUserData();
    }
  }, [authStatus, dispatch, userLoadStatus]);

  const isTest = process.env.REACT_APP_IS_TEST === 'TRUE' ? 300 : 3000;
  React.useEffect(
    /**
     * Keep theme preference in local storage
     */
    function saveThemePreference() {
      if (themePreference) {
        store.set('themePreference', themePreference);
      }
    },
    [themePreference]
  );

  return !isLoaded ? null : (
    <div data-cy={testId} id={testId}>
      <AppThemeProvider themePreference={themePreference}>
        <CssBaseline />
        <Sentry.ErrorBoundary fallback={Page500}>
          <ToastContainer
            autoClose={isTest}
            closeButton={false}
            closeOnClick
            hideProgressBar
            position={isMobile ? 'top-center' : 'top-right'}
            transition={Flip}
          />
          <React.Suspense fallback={<FullscreenLoader />}>
            <ForceUpdateTracker />
            <PermissionDeniedDialog />
            <AdminSwitchFarms />
            <NewFarmSuccess />
            <ModalLoader />
            <RootLoader />
            <TermsOfServiceDialog />
            <AuthCheck>
              <div data-cy={testIds.layout.root} id={testIds.layout.root}>
                {userHasNoFarms ? (
                  <NoFarms />
                ) : (
                  <Switch>
                    <Route component={Support} path={PATHS.support.base} />
                    <Route
                      component={AccountSettings}
                      path={PATHS.account.base}
                    />
                    <Route component={SignOut} path="/sign-out" />
                    <Route component={Dev} path="/development" />
                    <Route component={StatusMap} exact path="/" />
                    <Redirect exact from={AUTH_PATHS.signIn} to="/" />
                    <Redirect exact from="/status-map" to="/" />
                    <Route component={Page404} />
                  </Switch>
                )}
              </div>
            </AuthCheck>
          </React.Suspense>
        </Sentry.ErrorBoundary>
      </AppThemeProvider>
    </div>
  );
}

export default Sentry.withProfiler(connector(App));
