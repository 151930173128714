import type { ValuesType } from 'utility-types';
import type { MapKeysToBool } from './FarmUserPermissions';

const DEVICE_PERMISSION_KEY = {
  canControlDevicesRemotely: `canControlDevicesRemotely`,
  canManageCustomTriggers: `canManageCustomTriggers`,
  canManageDeviceConfiguration: `canManageDeviceConfiguration`,
  canManageDeviceNotifications: 'canManageDeviceNotifications',
  canManageDevicePairs: `canManageDevicePairs`,
  canManageDevicePermissions: `canManageDevicePermissions`,
  canToggleDeviceRelay: `canToggleDeviceRelay`
} as const;
type DevicePermissionKeys = ValuesType<typeof DEVICE_PERMISSION_KEY>;
const DEVICE_PERMISSION_KEYS = Object.values(DEVICE_PERMISSION_KEY);
type DevicePermissionsMap = MapKeysToBool<DevicePermissionKeys>;
const mapToFalse = (): DevicePermissionsMap => {
  const initializer = {} as DevicePermissionsMap;
  return Object.values(DEVICE_PERMISSION_KEY).reduce((acc, key) => {
    return {
      ...acc,
      [key]: false
    };
  }, initializer);
};
const DeviceFarmPermissions = {
  allFalse: mapToFalse(),
  keys: Object.values(DEVICE_PERMISSION_KEY),
  ...DEVICE_PERMISSION_KEY
} as const;
export { DEVICE_PERMISSION_KEYS };
export type { DevicePermissionKeys, DevicePermissionsMap };
export default DeviceFarmPermissions;
