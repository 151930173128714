import type { SliderProps } from '@material-ui/core';
import { Slider } from '@material-ui/core';
import React from 'react';
import { useIsAnyRequestPending } from 'src/appSelectors';

const ROW_DIRECTION_SLIDER_MARKS = [
  {
    label: `S`,
    value: 180
  },
  {
    label: `E`,
    value: 90
  },
  {
    label: `N`,
    value: 0
  },
  {
    label: `W`,
    value: 270
  },
  {
    label: `N`,
    value: 360
  }
];

type Props = Omit<SliderProps, 'onChange' | 'onChangeCommitted' | 'value'> & {
  readonly onCancel?: () => void;
  readonly onChange: (result: number | readonly number[]) => void;
  readonly onChangeCommitted?: (result: number) => Promise<void>;
  readonly value: number | null | undefined;
};

/**
 * Slider showing cardinal directions. Disabled itself if any requests
 * are pending. If onChangeCommitted is provided, it should be a post request
 * to set the row direction of whatever the target is. The request will fire
 * as soon as the user lets go of the slider.

 * @param props
 * @param props.disabled
 * @param props.onChangeCommitted
 * @param props.onChange
 * @param props.value
 */
export default function RowDirectionSlider({
  disabled,
  onChangeCommitted,
  onChange,
  value
}: Props): JSX.Element {
  /**
   * Callback fired as value changes
   */
  const isAnyRequestPending = useIsAnyRequestPending();

  const handleChange = (_: unknown, v: number | readonly number[]) =>
    onChange(v);

  const handleChangeCommitted = (
    _: React.ChangeEvent<unknown>,
    newVal: number | readonly number[]
  ): Promise<unknown> | null => {
    if (onChangeCommitted) {
      return onChangeCommitted(newVal as number);
    }
    return null;
  };
  return (
    <Slider
      data-cy="RowDirectionSlider"
      disabled={Boolean(isAnyRequestPending || disabled)}
      marks={ROW_DIRECTION_SLIDER_MARKS}
      max={360}
      min={0}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      value={value ?? 0}
    />
  );
}
