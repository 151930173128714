import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { AppButton } from 'src/components';
import type { ConfigSensorName } from 'src/devices/sensors';
import { SensorNames } from 'src/devices/sensors';
import { useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import type { DeviceIdProp } from '../models';
import { getConfiguredSensorsByDeviceId } from '../selectors';
import { useConfigMangerActions } from './configManager.reducer';
import ResetConfigToDefaults from './ResetConfigToDefaults';
import SensorCard from './SensorCard';

const testId = testIds.manageConfiguration.root;
const REQUIRED_PERMISSIONS = [
  'canManageDeviceConfiguration',
  'canManageDevicePairs'
] as const;
/**
 * Allows user to edit existing sensor configurations,
 * rename a device, or reset all sensors to default values
 * @param props
 * @param props.deviceId
 */
function ConfigManager({ deviceId }: DeviceIdProp): JSX.Element | null {
  const [sensorName, setSensorName] = React.useState<ConfigSensorName>('gps');
  const handleClickReset = useConfigMangerActions().clickReset;

  const sensorNames = useAppSelector((state) =>
    getConfiguredSensorsByDeviceId(state, deviceId)
  );

  return isNullish(sensorNames) ? null : (
    <div data-cy={testId} id={testId}>
      <ResetConfigToDefaults />
      <Grid
        container
        data-cy={testIds.manageConfiguration.configuredSensors}
        id={testIds.manageConfiguration.configuredSensors}
        justifyContent="flex-start"
        spacing={1}
      >
        {sensorNames.map((sn) => (
          <Grid item key={sn}>
            <AppButton
              fullWidth
              id={testIds.manageConfiguration.editSensorBtn(sn)}
              key={sn}
              onClick={() => setSensorName(sn)}
              size="small"
              text={SensorNames.humanize(sn)}
              variant={sensorName === sn ? 'contained' : 'outlined'}
            />
          </Grid>
        ))}
      </Grid>
      <SensorCard deviceId={deviceId} sensorName={sensorName} />
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="flex-end"
        mt={2}
      >
        <AppButton
          color="secondary"
          fullWidth={false}
          id={testIds.manageConfiguration.resetConfig.initBtn}
          onClick={handleClickReset}
          requiredPermissions={REQUIRED_PERMISSIONS}
          size="small"
          text="Factory Reset"
          variant="outlined"
        />
      </Box>
    </div>
  );
}

export default ConfigManager;
