import React from 'react';
import { MapCallout } from 'src/components/map';
import { Geo } from 'src/geo';
import { useSelectedReelRun } from './reelRunActive.reducer';

/**
 *
 */
function ReelRunCallout(): JSX.Element | null {
  const runData = useSelectedReelRun();
  if (runData === null) {
    return null;
  }
  const newLocal = runData.endPoint
    ? Geo.points.to.googleMaps(runData.endPoint)
    : null;
  return newLocal ? (
    <MapCallout position={newLocal}>
      <div>Reel Run</div>
    </MapCallout>
  ) : null;
}

export default ReelRunCallout;
