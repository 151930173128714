import DeviceFarmPermissions from './DevicePermissions';

const FARM_PERMISSION_KEYS = [
  `canManageJoinCodes`,
  `canManageFields`,
  `canManageFarmAccount`,
  `canManageUserPermission`
] as const;
const FARM_USER_PERMISSION_KEYS = [
  ...FARM_PERMISSION_KEYS,
  ...DeviceFarmPermissions.keys
] as const;
type MapKeysToBool<K extends string> = { [key in K]: boolean };
type FarmUserPermissionKey = typeof FARM_USER_PERMISSION_KEYS[number];
type FarmUserPermissionsMap = MapKeysToBool<FarmUserPermissionKey>;

const mapToFalse = (): FarmUserPermissionsMap => {
  const initializer = {} as FarmUserPermissionsMap;
  return FARM_USER_PERMISSION_KEYS.reduce((acc, key) => {
    return {
      ...acc,
      [key]: false
    };
  }, initializer);
};
const FarmUserPermissions = {
  allFalse: mapToFalse(),
  keys: FARM_USER_PERMISSION_KEYS
} as const;

type RequiredPermissions =
  | FarmUserPermissionKey
  | FarmUserPermissionKey[]
  | readonly FarmUserPermissionKey[];

export type {
  FarmUserPermissionKey,
  FarmUserPermissionsMap,
  RequiredPermissions,
  MapKeysToBool
};
export { FARM_PERMISSION_KEYS, FARM_USER_PERMISSION_KEYS };
export default FarmUserPermissions;
