import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import Actions from 'src/actions';
import { useAppSelector } from 'src/hooks';

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf
} from '@reduxjs/toolkit';

import { asyncInvokeRemoteControl } from './asyncInvokeRemoteControl';
import { default as REMOTE_CONTROL_KEY } from './RemoteControlKeys';

import type { RootState } from 'src/root.reducer';
import type { FetchStatus } from 'src/userSession.reducer';
import type { DeviceIdProp } from '../models';
import type { RemoteControlKey } from './RemoteControlKeys';
export type RemoteControlsTracker = DeviceIdProp &
  {
    [K in RemoteControlKey]?: {
      fetchStatus: FetchStatus;
    };
  };

interface State extends EntityState<RemoteControlsTracker> {
  isVisible: boolean;
  confirmingCommand?: RemoteControlKey;
}

const adapter = createEntityAdapter<RemoteControlsTracker>({
  selectId: (d) => d.deviceId
});
const selectors = adapter.getSelectors();

const initialState: State = adapter.getInitialState({
  isVisible: false
});

const getSlice = (state: RootState): State => state.remoteControls;
const isVisible = createSelector(getSlice, (state) => state.isVisible);

const getStatus = createCachedSelector(
  (state: RootState, deviceId: string) =>
    selectors.selectById(state.remoteControls, deviceId),
  (_state: RootState, _id: string, key: RemoteControlKey) => key,
  (tracker, key) => {
    return tracker ? tracker[key] : undefined;
  }
)((_state, id, key: RemoteControlKey) => `${id}/${key}`);

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncInvokeRemoteControl.fulfilled, (state) => {
        state.confirmingCommand = undefined;
      })
      .addCase(
        asyncInvokeRemoteControl.rejected,
        /**
         * If the command requires confirmation, show a dialog to the user
         * @param state
         * @param args
         * @param args.meta
         * @param args.payload
         */
        function handleConfirmationRequired(state, { meta, payload }) {
          if (
            meta.rejectedWithValue &&
            payload?.code === 'CONFIRMATION_REQUIRED'
          ) {
            state.confirmingCommand = meta.arg.commandKey;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          asyncInvokeRemoteControl.pending,
          asyncInvokeRemoteControl.fulfilled,
          asyncInvokeRemoteControl.rejected
        ),
        /**
         * Update tracker state for device/command
         * @param state
         * @param action
         * @param action.meta
         * @param action.meta.requestStatus
         * @param action.meta.arg
         */
        function trackRequests(state, { meta: { requestStatus, arg } }) {
          const { deviceId, commandKey } = arg;
          adapter.upsertOne(state, {
            [commandKey]: {
              fetchStatus: requestStatus
            },
            deviceId
          });
        }
      )
      .addMatcher(
        isAnyOf(Actions.clickCancel, Actions.closeDialog),
        (state) => {
          state.confirmingCommand = undefined;
        }
      ),
  initialState,
  name: 'remoteControls',
  reducers: {
    /**
     * Show/hide in device menu
     * @param state
     */
    toggleShowMenu(state) {
      state.isVisible = !state.isVisible;
    }
  }
});

export const { toggleShowMenu } = slice.actions;
const remoteControls: Reducer<State> = slice.reducer;
export default remoteControls;
export const RemoteControls = {
  REMOTE_CONTROL_KEY,
  getStatus,
  isVisible,
  toggleShowMenu,
  useStatus: (
    id: string,
    key: RemoteControlKey
  ): { fetchStatus: FetchStatus } | undefined =>
    useAppSelector((state) => getStatus(state, id, key)),
  ...selectors
} as const;
