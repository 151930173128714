import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { useGoogleMap } from '@react-google-maps/api';
import * as turf from '@turf/turf';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Actions from 'src/actions';
import { IconBtn, MapCard } from 'src/components';
import NoListItems from 'src/components/lists/NoListItems';
import Sensors from 'src/devices/sensors';
import { FarmFields } from 'src/fields/farmFields.reducer';
import { Geo } from 'src/geo';
import { useAppDispatch, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isNullish, isPositiveInt } from 'src/types';
import getTimeDelta from 'src/utilities/getTimedelta';
import {
  asyncGetReelRunsForField,
  RunHistory
} from './fieldRunHistory.reducer';
import ReelRunPolygonHistorical from './ReelRunPolygonHistorical';

const startDateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short'
});
type RowProps = {
  index: number;
  id: number;
};

const Ids = testIds.fieldActions.fieldRunHistory;

/**
 * @param props
 * @param props.id

 * @param props.index
 */
function Row({ id, index }: RowProps) {
  const formatVal = Sensors.useFormatValue();

  const data = useSelector((state) => RunHistory.selectById(state, id));
  if (isNullish(data)) {
    return null;
  }
  const { startTimestamp, distanceMmMax } = data;

  const rowId = Ids.list.item(index);
  const textPrimary = `Started: ${startDateFormatter.format(
    new Date(startTimestamp)
  )}`;
  return (
    <Grid alignItems="center" container data-cy={rowId} id={rowId} spacing={2}>
      <Grid item xs={1}>
        {index + 1}
      </Grid>
      <Grid item xs={6}>
        <ListItemText primary={textPrimary} />
      </Grid>
      <Grid container item justifyContent="flex-end" xs={5}>
        <ListItemText primary={formatVal('runDistanceMmMax', distanceMmMax)} />
      </Grid>
    </Grid>
  );
}

/**
 * @param props
 * @param props.id
 * @param props.fieldData
 * @param props.fieldId
 */
export default function FieldRunHistory({
  fieldId
}: {
  fieldId: number;
}): JSX.Element | null {
  const data = FarmFields.useField(fieldId);
  const shouldFetch = useSelector((state) => state.fieldRunHistory.shouldFetch);
  if (isNullish(data)) {
    throw new Error('No field for id');
  }
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const runIds = useSelector((state) => state.fieldRunHistory.ids as number[]);
  const fieldData = FarmFields.useField(fieldId);
  const selectedRunId = useSelector(
    (state) => state.fieldRunHistory.selectedId
  );
  const dispatch = useAppDispatch();
  const map = useGoogleMap();
  const center = fieldData?.center;

  React.useEffect(() => {
    if (center) {
      const projected = turf.destination(center, 500, 0, {
        units: 'meters'
      });
      const toGmaps = Geo.points.to.googleMaps(projected.geometry);
      map?.panTo(toGmaps);
    }
  }, [center, map]);

  const { sendRequest, isPending } = useThunk(asyncGetReelRunsForField);
  React.useEffect((): void => {
    if (isPositiveInt(fieldId) && shouldFetch) {
      const maxDateMs = new Date().getTime();
      const minDateMs = getTimeDelta({ unit: 'days', value: 180 });

      sendRequest({ fieldId, maxDateMs, minDateMs })
        .then(() => {
          if (isInitialLoad) {
            setIsInitialLoad(false);
          } else {
            toast.info('Runs updated');
          }
        })
        .catch(handleApiRequestError);
    }
  }, [fieldId, isInitialLoad, sendRequest, shouldFetch]);

  const fieldName = fieldData?.fieldName;
  return (
    <div data-cy={Ids.root} id={Ids.root}>
      <MapCard maxHeight={300} overflowY="hidden">
        <CardHeader
          action={
            <React.Fragment>
              <IconBtn
                iconKey="REFRESH"
                id={Ids.reloadBtn}
                onClick={() => dispatch(RunHistory.refreshRuns())}
              />
              <IconBtn
                iconKey="CLOSE"
                onClick={() => dispatch(Actions.setActiveFeature(null))}
              />
            </React.Fragment>
          }
          subheader="Run History"
          title={fieldName}
        />
        <CardContent
          style={{
            overflowY: 'scroll',
            maxHeight: 200
          }}
        >
          <Divider />
          {runIds.length === 0 ? (
            <NoListItems mt={2} title="No historical runs found for field" />
          ) : (
            <List
              data-cy={Ids.list.root}
              dense
              disablePadding
              id={Ids.list.root}
            >
              {runIds.map((id, index) => {
                const selected = id === selectedRunId;
                if (runIds.length === 0) {
                  return <NoListItems title="No runs found for this field" />;
                }
                return (
                  <ListItem
                    button
                    dense
                    divider
                    key={id}
                    onClick={() => dispatch(RunHistory.clickRun(id))}
                    selected={selected}
                  >
                    <Row id={id} index={index} key={id} />
                  </ListItem>
                );
              })}
            </List>
          )}
        </CardContent>
      </MapCard>
      {isPending
        ? null
        : runIds.map((id) => <ReelRunPolygonHistorical key={id} runId={id} />)}
    </div>
  );
}
