import React from 'react';
import { useSelector } from 'react-redux';
import { getHasVerifiedPhoneNumbers } from 'src/appSelectors';
import { AppButton, GridWrapper } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { isPositiveInt } from 'src/types';

import { Typography } from '@material-ui/core';

import { DeviceUserAssociations, showAlertsToggleDenied } from '../device-user';
import ActiveNotificationsList from './ActiveNotificationsList';
import AlertsToggleSwitch from './AlertsToggleSwitch';
import ConfirmDeleteNotification from './ConfirmDeleteNotification';
import CreateNotification from './CreateNotification';
import CustomizeNotificationString from './CustomizeNotificationString';

import type { DeviceMetadata } from 'src/devices';
type Props = DeviceMetadata & {
  global?: true;
};

type State = {
  selectedId?: number;
  action?: 'CREATE' | 'DELETE' | 'RENAME';
};

const initialState: State = {};

/**
 * @param props
 * @param props.deviceId
 * @param props.onCancel
 * @param props.deviceName
 */
function NotificationManager({ deviceId, deviceName }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  const hasVerifiedNumber = useSelector(getHasVerifiedPhoneNumbers);

  // const deviceNotifications = useTriggersForDevice(deviceId, 'NOTIFICATION');
  const enabled =
    useSelector((s) => DeviceUserAssociations.selectById(s, deviceId))
      ?.notificationsSuppressed !== true;
  const [state, setState] = React.useState<State>(initialState);
  const { selectedId: idToDelete } = state;
  // const noNotifications = deviceNotifications.length === 0;
  // const isGlobal = deviceId === 'GLOBAL';

  const handleReset = () => setState({ ...initialState });
  const handleClickRename = (id: number) =>
    setState({ action: 'RENAME', selectedId: id });
  const handleClickDelete = (id: number) =>
    setState({ action: 'DELETE', selectedId: id });

  if (state.action === 'CREATE') {
    return (
      <CreateNotification
        cancelBtn={
          <AppButton id={testIds.cancelBtn} onClick={handleReset}>
            Cancel
          </AppButton>
        }
        deviceId={deviceId}
        onCancel={handleReset}
        onSuccess={handleReset}
      />
    );
  }

  const isRenaming = state.action === 'RENAME';
  const handleClickCreate = () => {
    setState({ action: 'CREATE' });
    if (hasVerifiedNumber) {
      setState({ action: 'CREATE' });
    } else {
      dispatch(showAlertsToggleDenied());
    }
  };
  return (
    <React.Fragment>
      {isPositiveInt(idToDelete) && state.action === 'DELETE' ? (
        <ConfirmDeleteNotification
          onCancel={handleReset}
          onSuccess={handleReset}
          triggerId={idToDelete}
        />
      ) : null}

      <GridWrapper alignItems="center" justifyContent="flex-end" spacing={2}>
        <Typography
          data-cy={testIds.manageNotifications.notificationStatusText}
          id={testIds.manageNotifications.notificationStatusText}
          variant="body2"
        >
          {`Notifications ${enabled ? 'enabled' : 'disabled'}`}
        </Typography>
        <AlertsToggleSwitch
          deviceId={deviceId}
          deviceName={deviceName}
          isEnabled={enabled}
        />
      </GridWrapper>
      {isPositiveInt(state.selectedId) && isRenaming ? (
        <CustomizeNotificationString
          onResetState={handleReset}
          triggerId={state.selectedId}
        />
      ) : (
        <React.Fragment>
          <ActiveNotificationsList
            deviceId={deviceId}
            onClickDelete={handleClickDelete}
            onClickRename={handleClickRename}
          />
          <GridWrapper justifyContent="flex-end">
            <AppButton
              iconKey="ADD_ITEM"
              id={testIds.createNotification.initializeBtn}
              onClick={handleClickCreate}
            >
              New Rule
            </AppButton>
          </GridWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

/**
 * @param props
 */
export default function Notifications(props: Props): JSX.Element {
  return (
    <div
      data-cy={testIds.manageNotifications.root}
      id={testIds.manageNotifications.root}
    >
      <NotificationManager {...props} />
    </div>
  );
}
