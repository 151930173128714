/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */
import type { EntityId } from '@reduxjs/toolkit';
import { isArray, isString } from 'lodash';
import { isNumber, isPlainObject } from 'lodash-es';
import type React from 'react';
import { FORM_VALIDATION } from 'src/constants/FormValidation';
import type { Falsey, Primitive } from 'utility-types';
import { isFalsy } from 'utility-types';
import type { IconKey } from './theme/AppIcon';

type KeysOfUnion<T> = T extends T ? keyof T : never;
type DateString = string;
type Nullable<T> = T | null;
type PlainObject = {
  [key: string]: unknown;
};
type CamelToSnakeCase<S extends number | string | symbol> = S extends string
  ? S extends `${infer T}${infer U}`
    ? `${T extends Capitalize<T>
        ? '_'
        : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
    : S
  : S;
type SnakeCaseKeys<T extends PlainObject> = {
  [K in CamelToSnakeCase<keyof T>]: T[K];
};

// type T21 = CamelToSnakeCase<'hello'>; // "hello"
// type T22 = CamelToSnakeCase<'helloWorld'>; // "hello_world"
// type T23 = CamelToSnakeCase<'helloTsWorld'>;
type NullableValues<T extends PlainObject> = {
  [K in keyof T]: T[K] | null;
};
type PartialNullable<T extends { [key: string]: unknown }> = Partial<
  NullableValues<T>
>;

type DeepPlainObject = {
  [key: string]: DeepPlainObject | DeepPlainObject[] | Primitive;
};

/**
 * Determine whether a value (string typically) is only alphanumeric
 * @param value
 */
function isNumericString(value: unknown): value is string {
  return (
    typeof value === 'string' &&
    FORM_VALIDATION.patterns.decimalNumber.value.test(value)
  );
}

/**
 * @param target
 */
function isTruthyString(target: unknown): target is string {
  return !isFalsy(target) && isString(target);
}
type Nullish = null | undefined;
/**
 * @param x
 */
function isNullish(x: unknown): x is Nullish {
  return x === null || typeof x === 'undefined';
}
/**
 * @param x
 */
function notNullish<T>(x: T): x is NonNullable<T> {
  return !isNullish(x);
}

const isPositiveInt = (x: unknown): x is number => {
  return isNumber(x) && x % 1 === 0 && x > 0;
};

type ListRes<T> = {
  readonly items: readonly T[];
};

type PlainObjectOf<T> = Readonly<{ readonly [key: string]: T }>;

const isPlainObj = (val: unknown): val is PlainObject => isPlainObject(val);
type ArrayOrReadonlyArray = unknown[] | readonly unknown[];

/**
 * @param val
 */
function isArrayOrReadonlyArray(val: unknown): val is ArrayOrReadonlyArray {
  return isArray(val);
}
/**
 * @param id
 */
function entityIdToInt(id: EntityId): number {
  const toInt = isString(id) ? parseInt(id) : id;
  if (typeof toInt !== 'number') {
    throw new Error(`${id} is not a number`);
  }
  const remainder = toInt % 1;
  if (remainder !== 0) {
    throw new Error(
      `${toInt} is not an int (type=${typeof toInt}; remainder=${remainder})`
    );
  }
  return toInt;
}

type Truthy<T> = T extends Falsey ? never : T;

interface RouteDef {
  name: string;
  path: string;
  component: React.ComponentType;
  iconKey?: IconKey;
  isAdminOnly?: boolean;
  isUnderConstruction?: boolean;
  isDevOnly?: boolean;
}

/**
 * @param val
 */
function isTruthy<T>(val: unknown): val is Truthy<T> {
  return !isFalsy(val);
}

/**
 * @param target
 */
function isStringOrNumber(target: unknown): target is React.ReactText {
  return isString(target) || isNumber(target);
}

/**
 * @param target
 */
function isNum(target: unknown): target is number {
  return isNumber(target);
}

export {
  entityIdToInt,
  isArrayOrReadonlyArray,
  isNullish,
  isNum,
  isNumericString,
  isPlainObj,
  isPositiveInt,
  isStringOrNumber,
  isTruthy,
  notNullish,
  isTruthyString
};
export type {
  ArrayOrReadonlyArray,
  CamelToSnakeCase,
  DateString,
  DeepPlainObject,
  PlainObject,
  KeysOfUnion,
  ListRes,
  Nullable,
  NullableValues,
  Nullish,
  PartialNullable,
  PlainObjectOf,
  RouteDef,
  SnakeCaseKeys
};
