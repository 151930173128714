/* eslint-disable react/jsx-newline */
import type { TypographyProps } from '@material-ui/core';
import {
  CardActions,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIsAnyRequestPending } from 'src/appSelectors';
import { CancelBtn, SubmitBtn } from 'src/components';
import PATHS from 'src/constants/Paths';
import { DevicePermissions } from 'src/permissions';
import type {
  FarmUserPermissionKey,
  MapKeysToBool
} from 'src/permissions/FarmUserPermissions';
import FarmUserPermissions, {
  FARM_PERMISSION_KEYS
} from 'src/permissions/FarmUserPermissions';
import usePermissionToggles from 'src/permissions/usePermissionToggles';
import testIds from 'src/testIds';
import AppIcon from 'src/theme/AppIcon';
import { humanizeFarmUsePermission } from 'src/utilities';
const primaryTypoProps: TypographyProps = {
  variant: 'body2'
};

type ItemProps = {
  enabled: boolean;
  isSuccess: boolean;
  onToggle: () => void;
  name: FarmUserPermissionKey;
  disabled: boolean;
  divider: boolean;
};

/**
 * @param props
 * @param props.enabled
 * @param props.onToggle
 * @param props.name
 * @param props.disabled
 * @param props.divider
 * @param props.isSuccess
 */
function PermissionItem({
  onToggle: onClick,
  name: permission,
  enabled,
  divider,
  isSuccess,
  disabled
}: ItemProps) {
  const testId = testIds.permissionListItem(permission, enabled);
  return (
    <ListItem
      button={!isSuccess as unknown as true}
      data-cy={testId}
      disabled={disabled || isSuccess}
      divider={divider}
      id={testId}
      key={permission}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      selected={enabled}
      style={{ padding: 5 }}
    >
      <ListItemText
        primary={humanizeFarmUsePermission(permission)}
        primaryTypographyProps={primaryTypoProps}
      />
      <ListItemSecondaryAction onClick={onClick}>
        <AppIcon
          color={enabled ? 'primary' : 'disabled'}
          iconKey={enabled ? 'CHECK_MARK' : 'DISABLED'}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

interface Props<K extends FarmUserPermissionKey> {
  submitId?: string;
  showSubheaders?: boolean;
  onCancel?: () => void;
  keys: readonly K[];
  subtitle?: string;
  successHandler: React.ReactNode;
  onSubmit: (arg: MapKeysToBool<K>) => Promise<void>;
  readonly initialPermissions?: MapKeysToBool<K> | null;
}
const PERMISSIONS = {
  Device: DevicePermissions.keys,
  Farm: FARM_PERMISSION_KEYS
} as const;

const LIST_STYLE: React.CSSProperties = {
  flex: 1,
  maxHeight: 600,
  overflowY: 'scroll'
};
/**
 * @param props
 * @param props.targetUserId
 * @param props.email
 * @param props.initialPermissions
 * @param props.title
 * @param props.subtitle
 * @param props.onSubmit
 * @param props.successHandler
 * @param props.keys
 * @param props.showSubheaders
 * @param props.onCancel
 * @param props.submitId
 */
export default function PermissionsForm<
  K extends FarmUserPermissionKey = FarmUserPermissionKey
>({
  successHandler,
  subtitle,
  showSubheaders = true,
  submitId,
  onSubmit: handleSubmit,
  onCancel,
  initialPermissions,
  keys
}: Props<K>): JSX.Element {
  const { handleToggle, formData } = usePermissionToggles<K>({
    initial: initialPermissions,
    keySet: keys
  });

  const [isSuccess, setIsSuccess] = React.useState(false);
  const history = useHistory();
  const isPending = useIsAnyRequestPending();

  const handleRedirect = () => {
    setIsSuccess(false);
    return history.push(PATHS.account.base);
  };
  return (
    <div
      data-cy={testIds.permissionEditor.root}
      id={testIds.permissionEditor.root}
    >
      <Typography color="textSecondary" variant="body2">
        {subtitle}
      </Typography>
      <div>
        <Typography
          color="primary"
          id={testIds.instructions}
          variant="subtitle1"
        />
        <Divider />
        <Grid container>
          {isSuccess
            ? successHandler
            : Object.entries(PERMISSIONS).map(([subsetName, keysList]) => {
                if (!keysList.some((k) => keys.includes(k as K))) {
                  return null;
                }
                return (
                  <Grid item key={subsetName} md={6} xs={12}>
                    <List style={LIST_STYLE}>
                      {showSubheaders ? (
                        <ListSubheader>{subsetName}</ListSubheader>
                      ) : null}
                      {keysList.map((permission, index) => {
                        const value = formData[permission as K];
                        if (typeof value === 'undefined') {
                          return null;
                        }
                        return (
                          <PermissionItem
                            disabled={isPending || isSuccess}
                            divider={
                              index !== FarmUserPermissions.keys.length - 1
                            }
                            enabled={value}
                            isSuccess={isSuccess}
                            key={permission}
                            name={permission}
                            onToggle={() => handleToggle(permission as K)}
                          />
                        );
                      })}
                    </List>
                  </Grid>
                );
              })}
        </Grid>
      </div>
      <CardActions>
        <CancelBtn
          disabled={isPending || isSuccess}
          onClick={onCancel ?? handleRedirect}
          variant="outlined"
        />

        <SubmitBtn
          disabled={isPending || isSuccess}
          id={submitId ?? testIds.permissionEditor.submitBtn}
          isHidden={isSuccess}
          onClick={() => handleSubmit(formData).then(() => setIsSuccess(true))}
        />
      </CardActions>
    </div>
  );
}
