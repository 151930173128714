/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsdoc/require-jsdoc */
import center from '@turf/center';
import type { Feature, Polygon } from '@turf/helpers';
import * as turf from '@turf/turf';
import type * as d3Geo from 'd3-geo';
import { isNumber } from 'lodash';
import type { DeepPartial } from 'redux';
import { isArrayOrReadonlyArray, isNullish, isPlainObj } from 'src/types';
import { appLogger } from 'src/utilities';
import GeoLines from './lines';
import GeoPoints from './points';
import type {
  AnyPoint,
  ArrayOfThree,
  Coordinates,
  LatLng,
  GmapsPolygonPath,
  LinearRing,
  PointCoordinatesArray,
  PointFeature,
  PointGeoJson,
  PolygonFeature
} from './types';
import { isArrayOfFourOrMorePoints, isLinearRing } from './types';

// const SIG_DIGITS = 7;
const DEFAULT_POLYGON_BUFFER_METERS = 10;

// /**
//  * @param c
//  */
// function roundCoords(c: Coordinates | number[]): Coordinates {
//   if (!isValidCoordinates(c)) {
//     throw new Error(`Invalid coordinates`);
//   }
//   if (isValidCoordinates(c)) {
//     const [lng, lat] = c;
//     return [
//       parseFloat(lng.toFixed(SIG_DIGITS)),
//       parseFloat(lat.toFixed(SIG_DIGITS)),
//     ];
//   }
//   throw new Error(`Not coordinates`);
// }

function isGeoJson(val: unknown): val is PolygonGeoJson {
  if (isPlainObj(val)) {
    const { type } = val;
    if (type === 'Polygon') {
      return 'coordinates' in val && isArrayOrReadonlyArray(val.coordinates);
    }
    // return (
    //   `type` in val &&
    //   val.type === `Polygon` &&
    //   `coordinates` in val &&
    //   isArrayOrReadonlyArray(coords) &&
    //   isLinearRing(coords[0])
    // );
  }
  return false;
}

function isGeoJsonFeature(val: unknown): val is Feature<Polygon> {
  if (isPlainObj(val)) {
    const asFeature = val as DeepPartial<Feature<Polygon>>;
    return isGeoJson(asFeature.geometry);
  }

  return false;
}

function isGmapsPolygonPath(
  ptArray?: unknown
): ptArray is google.maps.LatLngLiteral[] {
  return (
    isArrayOrReadonlyArray(ptArray) && ptArray.every(GeoPoints.is.gmapsLiteral)
  );
}

function toGmaps(
  val: PointCoordinatesArray | PolygonFeature | PolygonGeoJson
): GmapsPolygonPath {
  if (isGeoJson(val)) {
    const [outerRing] = val.coordinates;
    if (isNullish(outerRing)) {
      throw new Error('Polygon has no outer ring');
    }
    const asGmaps = outerRing.map(GeoPoints.to.googleMaps);
    const newLength = asGmaps.length;
    if (!isNumber(newLength) || newLength < 4) {
      throw new Error(`Not enough coordinates`);
    }
    if (isGmapsPolygonPath(asGmaps)) {
      return asGmaps;
    }
  }

  return toGmaps(toGeoJson(val));
}

function createIsUnclosed<T extends AnyPoint>(
  typeGuard: (v: unknown) => boolean
) {
  return (v: unknown): v is ArrayOfThree<T> =>
    GeoLines.isArrayOfLength(v, 3) && v.every(typeGuard);
}
const isUnclosedArrayOf = {
  coordinates: createIsUnclosed<Coordinates>(GeoPoints.is.pointCoordinates),
  geoJson: createIsUnclosed<PointGeoJson>(GeoPoints.is.geoJson),
  gmaps: createIsUnclosed<LatLng>(GeoPoints.is.gmapsLiteral),
  pointFeature: createIsUnclosed<PointFeature>(GeoPoints.is.pointFeature)
};

type ClosedOrUnclosedLinearRing = ArrayOfThree<Coordinates> | LinearRing;
function isClosedOrUnclosedLinearRing(
  val: unknown
): val is ClosedOrUnclosedLinearRing {
  return isLinearRing(val) || isUnclosedArrayOf.coordinates(val);
}

function isUnclosed(val: unknown) {
  if (GeoLines.isArrayOfLength(val, 3)) {
    return !isNullish(
      Object.values(isUnclosedArrayOf).find((check) => check(val))
    );
  }
  return false;
}
type ProperPolygon = Feature<Polygon> | Polygon;
function isProperPolygon(val: unknown): val is ProperPolygon {
  return isGeoJson(val) || isGeoJsonFeature(val);
}

type AnyValidPolygonPath =
  | Array<Feature<PointGeoJson>>
  | google.maps.LatLngLiteral[]
  | PointCoordinatesArray
  | PointGeoJson[]
  | PolygonFeature
  | PolygonGeoJson;

function toGeoJson(pathOrPolygon: AnyValidPolygonPath): Polygon {
  if (isGeoJson(pathOrPolygon)) {
    return closePolygon(pathOrPolygon);
  }
  if (isGeoJsonFeature(pathOrPolygon)) {
    return toGeoJson(pathOrPolygon.geometry);
  }
  if (isLinearRing(pathOrPolygon)) {
    return {
      coordinates: [[...pathOrPolygon]],
      type: `Polygon`
    };
  }

  if (isGmapsPolygonPath(pathOrPolygon)) {
    appLogger.log(`Converting gmaps path`);
    return toGeoJson({
      coordinates: [
        pathOrPolygon.map((p) => {
          const { lng, lat } = p;
          if (isNumber(lng) && isNumber(lat)) {
            return [lng, lat];
          }
          throw new Error(`Invalid point in path: ${JSON.stringify(p)}`);
        })
      ],
      type: 'Polygon'
    });
  }

  return toGeoJson({
    coordinates: [
      pathOrPolygon.map((pt) => {
        if (GeoPoints.is.pointFeature(pt)) {
          return pt.geometry.coordinates;
        }
        return pt.coordinates;
      })
    ],
    type: `Polygon`
  });
}

/**
 * @param poly
 */
function toD3GeoCollection(
  poly: Polygon
): d3Geo.ExtendedGeometryCollection | null {
  const [outerRing] = poly.coordinates;
  if (isNullish(outerRing)) {
    throw new Error('No outer ring on polygon');
  }
  return {
    geometries: [{ coordinates: [[...outerRing]], type: 'Polygon' }],
    type: `GeometryCollection`
  };
}

/**
 * @param polygon
 * @param point
 */
function containsPoint(
  polygon: PolygonGeoJson,
  point: PointGeoJson | null | undefined
): boolean {
  return (
    !isNullish(polygon) &&
    !isNullish(point) &&
    turf.booleanPointInPolygon(point, polygon)
  );
}

/**
 * @param val
 * @param bufferMeters
 */
function getBbox(
  val: AnyValidPolygonPath,
  bufferMeters?: number
): turf.helpers.BBox {
  return turf.bbox(
    turf.buffer(toGeoJson(val), bufferMeters ?? DEFAULT_POLYGON_BUFFER_METERS, {
      units: `meters`
    })
  );
}
/**
 * @param val
 * @param label
 */
function getCenter(val: AnyValidPolygonPath, label = `center`): PointFeature {
  const asGeoJSON = toGeoJson(val);
  const [outerRing] = asGeoJSON.coordinates;
  if (isNullish(outerRing)) {
    throw new Error(`Polygon has no outer ring`);
  }
  const coords = outerRing.map(([lng, lat]) => {
    if (isNullish(lng) || isNullish(lat)) {
      throw new Error('undefined point in coordinates');
    }
    return [lng, lat];
  });

  const result = center(turf.polygon([coords]), { properties: { label } });
  const resCoords = result.geometry.coordinates;
  if (!GeoPoints.is.pointCoordinates(resCoords)) {
    throw new Error(`Invalid coordinates`);
  }
  return GeoPoints.to.pointFeature(resCoords, { label });
}

function closeGmapsPath(path: LatLng[]): LatLng[] {
  const [first] = path;
  const last = path[path.length - 1];
  const { lng: firstLng, lat: firstLat } = first ?? {};
  const { lng: lastLng, lat: lastLt } = last ?? {};

  if (
    isNumber(firstLng) &&
    isNumber(firstLat) &&
    isNumber(lastLng) &&
    isNumber(lastLt) &&
    (path.length === 3 || firstLng !== lastLng || firstLat !== lastLt)
  ) {
    return [...path, { lat: firstLat, lng: firstLng }];
  }
  return path;
}

function closePolygon(polygon: PolygonGeoJson): PolygonGeoJson {
  const [outerRing] = polygon.coordinates;
  if (isNullish(outerRing)) {
    return polygon;
  }
  const [first] = outerRing;
  const last = outerRing[outerRing.length - 1];
  if (isNullish(first) || isNullish(last)) {
    return polygon;
  }

  const lngEqual = first[0] === last[0];
  const latEqual = first[1] === last[1];

  if (outerRing.length === 3 || !lngEqual || !latEqual) {
    const closed = {
      ...polygon,
      coordinates: [[...outerRing, [...first]]]
    };

    return closed;
  }

  return polygon;
}

/**
 * @param polygon
 * @param bufferMeters
 */
function makeBufferedPolygon(
  polygon: AnyValidPolygonPath,
  bufferMeters?: number
): Polygon {
  const asGeoJSON = toGeoJson(polygon);

  const buffered = turf.buffer(
    asGeoJSON,
    bufferMeters ?? DEFAULT_POLYGON_BUFFER_METERS,
    { units: `meters` }
  );
  // TODO: accommodate multipolygons
  const result = buffered.geometry;
  if (isGeoJson(result)) {
    return result;
  }
  throw new Error(
    `Buffer produced non-polygon object ${JSON.stringify(buffered)}`
  );
}

const GeoPolygons = {
  containsPoint,
  defaults: {
    bufferM: DEFAULT_POLYGON_BUFFER_METERS
  },
  get: {
    bbox: getBbox,
    center: getCenter
  },
  is: {
    closedOrUnclosedLinearRing: isClosedOrUnclosedLinearRing,
    geojson: isGeoJson,
    geojsonFeature: isGeoJsonFeature,
    gmaps: isGmapsPolygonPath,
    linearRing: isLinearRing,
    polygon: isProperPolygon,
    validPolygonInput(val: unknown): val is AnyValidPolygonPath {
      return (
        isGeoJson(val) ||
        isGeoJsonFeature(val) ||
        isUnclosed(val) ||
        isArrayOfFourOrMorePoints(val)
      );
    }
  },
  make: {
    buffer: makeBufferedPolygon,
    mutableCopy: toGeoJson
  },
  to: {
    geojson: toGeoJson,
    gmaps: toGmaps,
    // linearRing: createClosedPath,
    toD3GeoCollection
  }
};
export default GeoPolygons;
export { closePolygon, closeGmapsPath };
export type PolygonGeoJson = Polygon;
