import { isString } from 'lodash-es';
import type { ReelRunObservationKey } from 'src/reel-runs/RunObservationReports';
import RunObservationReports from 'src/reel-runs/RunObservationReports';
import type { AnySensorConfigKey } from './SensorConfig';
import type { AnySensorEventKey } from './SensorEvent';

/**
 * @param x
 */
type ConversionTarget = 'database' | 'user';

const DB_CUSTOM_UNITS = ['mm/m', 'millirpm'] as const;
type DbCustomUnit = typeof DB_CUSTOM_UNITS[number];
const APP_CUSTOM_UNITS = ['ft/hr', 'meters/hr', 'rpm'] as const;
type AppCustomUnit = typeof APP_CUSTOM_UNITS[number];

const DEFAULT_UNITS = [
  'C',
  'F',
  'ft',
  'ft/s',
  'mV', // 1/1000 of a volt
  'V', // 1 volt
  'in',
  'kPa',
  'm',
  'gal/min',
  'l/min',
  'mm',
  'psi'
] as const;
type Unit = typeof DEFAULT_UNITS[number];

type AnySensorKey =
  | AnySensorConfigKey
  | AnySensorEventKey
  | ReelRunObservationKey
  | 'sensorName';
const DEVICE_DATA_CONVERSION_KEYS = [
  'altitude',
  'diameterMm',
  'hoseDiameterMm',
  'nozzleDiameterMm',
  'outerHoseWrapRadiusMm',
  'voltageMv',
  'readingCelsius',
  'readingKpa',
  'runDistanceMmCurrent',
  'runDistanceMmMax',
  'runSpeedMmpm',
  'speedMillirpm',
  'swathWidthMm',
  'swathWidthMm',
  'milliRpm',
  'voltageThresholdLowMv',
  'speedMmpm',
  'thresholdPsiLower',
  'thresholdPsiUpper',
  'widthMm',

  ...RunObservationReports.keys
] as const;
type KeyWithConversion = typeof DEVICE_DATA_CONVERSION_KEYS[number];
type RequiresConversion<K> = K extends KeyWithConversion ? K : never;

/**
 * @param x
 */
function requiresConversion<K>(x: K): x is RequiresConversion<K> {
  if (
    isString(x) &&
    DEVICE_DATA_CONVERSION_KEYS.includes(x as KeyWithConversion)
  ) {
    return true;
  }
  return false;
}

interface GetConversionUnitArgs<K extends AnySensorKey> {
  readonly key: K;
  readonly isMetric: boolean;
  readonly target: ConversionTarget;
}
export type { AnySensorKey, RequiresConversion, ConversionTarget };
export { requiresConversion };

/**
 * @param args
 * @param args.key
 * @param args.isMetric
 * @param args.target
 */
export default function getConversionUnits<K extends AnySensorKey>({
  key,
  isMetric,
  target
}: GetConversionUnitArgs<K>):
  | {
      readonly rawUnit: DbCustomUnit | Unit;
      readonly userPref: AppCustomUnit | Unit;
      readonly sigDigits: number;
      readonly labels: { readonly short: string; readonly long: string };
    }
  | undefined {
  if (!requiresConversion(key)) {
    return undefined;
  }

  switch (key as AnySensorKey) {
    //  * LENGTH (config measurements etc.)
    case 'applicationRateEstimatedMm':
    case 'diameterMm':
    case 'outerHoseWrapRadiusMm':
    case 'nozzleDiameterMm':
    case 'hoseDiameterMm':
    case 'widthMm': {
      return {
        labels: isMetric
          ? { long: 'millimeters', short: 'mm' }
          : { long: 'inches', short: 'in' },
        rawUnit: 'mm',
        sigDigits:
          (key === 'nozzleDiameterMm' ||
            key === 'hoseDiameterMm' ||
            key === 'diameterMm') &&
          !isMetric &&
          target === 'user'
            ? 1
            : 0,
        userPref: isMetric ? 'mm' : 'in'
      };
    }
    // * PRESSURE
    case 'pressureObservedKpa':
    case 'readingKpa':
    case 'thresholdPsiLower':
    case 'thresholdPsiUpper': {
      return {
        labels: isMetric
          ? { long: 'kilopascals', short: 'kPa' }
          : { long: 'PSI', short: 'PSI' },
        rawUnit: 'psi',
        sigDigits: isMetric ? 0 : 1,
        userPref: isMetric ? 'kPa' : 'psi'
      };
    }
    // * LENGTH (field dimensions)
    case 'runDistanceMmCurrent':
    case 'distanceObservedMm':
    case 'altitude':
    case 'runDistanceMmMax':
    case 'swathWidthMm': {
      return {
        labels: isMetric
          ? { long: 'meters', short: 'm' }
          : { long: 'feet', short: 'ft' },
        rawUnit: 'mm',
        sigDigits: 0,
        userPref: isMetric ? 'm' : 'ft'
      };
    }
    case 'readingCelsius': {
      const degreesSymbol = 'U+00B0`';
      const short = `${degreesSymbol} ${isMetric ? 'C' : 'F'}`;
      return {
        labels: {
          long: `degrees ${short}`,
          short
        },
        rawUnit: 'C',
        sigDigits: 1,
        userPref: isMetric ? 'C' : 'F'
      };
    }
    case 'voltageThresholdLowMv':
      return {
        labels: { long: 'Volts', short: 'V' },
        rawUnit: 'mV',
        sigDigits: 0,
        userPref: 'V'
      };

    case 'speedMillirpm':
    case 'milliRpm':
      return {
        labels: { long: 'rpm', short: 'rpm' },
        rawUnit: 'millirpm',
        sigDigits: 4,
        userPref: 'rpm'
      };
    case 'speedObservedMmpm':
    case 'runSpeedMmpm':
    case 'speedMmpm':
      return {
        labels: isMetric
          ? { long: 'meters per hour', short: 'm/hr.' }
          : { long: 'feet per hour', short: 'ft/hr.' },
        rawUnit: 'mm/m',
        sigDigits: 1,
        userPref: isMetric ? 'meters/hr' : 'ft/hr'
      };
    case 'voltageMv': {
      return {
        labels: { long: 'volts', short: 'V' },
        rawUnit: 'mV',
        sigDigits: 0,
        userPref: 'V'
      };
    }
    case 'flowRateEstimatedLpm': {
      return {
        labels: {
          long: isMetric ? 'Liters/Min.' : 'Gallons/Min.',
          short: isMetric ? 'lt./min.' : 'gal./min.'
        },
        rawUnit: 'l/min',
        sigDigits: 1,
        userPref: isMetric ? 'l/min' : 'gal/min'
      };
    }
    default: {
      throw new Error(key as string);
    }
  }
}
