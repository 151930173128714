import { Box, Grid } from '@material-ui/core';
import { captureException } from '@sentry/react';
import React from 'react';
import { AppTextField, CancelBtn, SubmitBtn } from 'src/components';
import { asyncRenameField } from 'src/fields';
import { useThunk } from 'src/hooks';
import testIds from 'src/testIds';
import { showErrorToast } from 'src/theme/toasts';
import { isFalsy } from 'utility-types';
const Ids = testIds.fieldActions.renameField;

type Props = {
  initialValue: string;
  onCancel: () => void;
  fieldId: number;
};

/**
 * @param props
 * @param props.fieldName
 * @param props.id
 * @param props.onCancel
 * @param props.onSuccess
 * @param props.fieldId
 * @param props.initialValue
 */
function RenameField({ onCancel, fieldId, initialValue }: Props): JSX.Element {
  const [fieldName, setFieldName] = React.useState(initialValue);
  const { sendRequest, isPending } = useThunk(asyncRenameField);

  const handleSubmit = async () =>
    sendRequest({ fieldId, fieldName })
      .then(() => {
        onCancel();
      })
      .catch((err) => {
        captureException(err);
        showErrorToast();
      });
  const submitDisabled =
    isPending || fieldName === initialValue || isFalsy(fieldName);
  const testId = Ids.root;
  return (
    <Grid
      alignItems="center"
      container
      data-cy={testId}
      id={testId}
      spacing={2}
    >
      <Grid item xs={12}>
        <Box py={2}>
          <AppTextField
            name={Ids.textInput}
            onChange={(e) => setFieldName(e.target.value)}
            value={fieldName}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <CancelBtn fullWidth onClick={onCancel} />
      </Grid>
      <Grid item xs={6}>
        <SubmitBtn
          disabled={submitDisabled}
          fullWidth
          id={Ids.submitBtn}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
}

export default RenameField;
