/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import type { DeepPartial } from 'redux';

/**
 * @param fnName
 */
function createLoggingHandler(
  fnName: 'debug' | 'error' | 'info' | 'log' | 'warn'
) {
  return (...args: readonly any[]) => {
    if (
      (process as DeepPartial<NodeJS.Process> | undefined)?.env?.NODE_ENV ===
      'development'
    ) {
      console[fnName](...args);
    }
    return null;
  };
}
const info = createLoggingHandler(`info`);
const error = createLoggingHandler(`error`);
const debug = createLoggingHandler(`debug`);
const log = createLoggingHandler(`log`);
const warn = createLoggingHandler(`warn`);

export const appLogger = {
  debug,
  error,
  info,
  log,
  warn
} as const;
