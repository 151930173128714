/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ActionType } from 'typesafe-actions';
import type { SetDifference } from 'utility-types';
import type { SelectItemPayload } from './activeFeature.reducer';

import type { DeviceActionName } from './devices/DeviceProfileOptionsMenu';
import type { FieldActionName } from './status-map/FieldActions';
import type { MapItemType } from './status-map/statusMap.reducer';
const useActiveFeature = () =>
  useSelector((state) => state.appFeature.activeFeature);

type ActiveFeature =
  | DeviceActionName
  | FieldActionName
  | 'ADD_DEVICE'
  | 'CHANGE_FARMS'
  | 'CREATE_FIELD'
  | 'GLOBAL_NOTIFICATIONS'
  | 'SET_MAP_TYPE_ID'
  | 'SET_RUN_DIRECTION';

interface StartDeviceAction {
  id: string;
  name: DeviceActionName;
  kind: 'device';
}
interface StartFieldAction {
  id: number;
  name: FieldActionName;
  kind: 'field';
}
interface StartReelRunAction {
  id: number;
  name: 'SET_RUN_DIRECTION';
  kind: 'reelRun';
}

type GlobalFeature = SetDifference<
  ActiveFeature,
  DeviceActionName | FieldActionName | StartReelRunAction
>;

interface StartGlobalAction {
  id?: undefined;
  kind?: undefined;
  name: GlobalFeature;
}

type ActiveFeaturePayload =
  | StartDeviceAction
  | StartFieldAction
  | StartGlobalAction
  | StartReelRunAction;

const closeDialog = createAction('GLOBAL/CLOSE_DIALOG');

const Actions = {
  setAdminMode: createAction<boolean>('SET_ADMIN_MODE'),
  clearFetchStatus: createAction<string>('CLEAR_FETCH_STATUS'),
  clearNewFarm: createAction('CLEAR_NEW_FARM'),
  clearSearch: createAction('CLEAR_SEARCH'),
  clickCancel: createAction('CLICK_CANCEL'),
  clickDone: createAction('CLICK_DONE'),
  closeDialog,
  closeSidebar: createAction('CLOSE_SIDEBAR'),
  deselectItem: createAction<{
    kind: MapItemType;
  }>('DESELECT_ITEM'),
  dockSidebar: createAction('DOCK_SIDEBAR'),
  onMapCalloutClosed: createAction('ON_MAP_CALLOUT_CLOSED'),
  openSidebar: createAction('OPEN_SIDEBAR'),
  resetToDefaultState: createAction('RESET_TO_DEFAULT_STATE'),
  selectItem: createAction<SelectItemPayload>('SELECT_ITEM'),
  setActiveFeature: createAction<ActiveFeaturePayload | null>(
    'SET_ACTIVE_FEATURE'
  ),
  undockSidebar: createAction('UN-DOCK_SIDEBAR'),
  updateSearchFilter: createAction<string>('UPDATE_SEARCH_VALUE')
} as const;
type AppAction = ActionType<typeof Actions>;

/**
 *
 */
function useClearActiveFeature(): () => {
  payload: ActiveFeaturePayload | null;
  type: string;
} {
  const dispatch = useDispatch();
  return React.useCallback(
    () => dispatch(Actions.setActiveFeature(null)),
    [dispatch]
  );
}
/**
 *
 */
function useSetActiveFeature(): (payload: ActiveFeaturePayload | null) => {
  payload: ActiveFeaturePayload | null;
  type: string;
} {
  const dispatch = useDispatch();
  return React.useCallback(
    (payload: ActiveFeaturePayload | null) =>
      dispatch(Actions.setActiveFeature(payload)),
    [dispatch]
  );
}

function useStartDeviceAction(id: string): (name: DeviceActionName) => void;
function useStartDeviceAction(
  id?: undefined
): (name: DeviceActionName, id: string) => void;

/**
 * @param id
 */
function useStartDeviceAction(
  id?: string
):
  | ((name: DeviceActionName, id: string) => void)
  | ((name: DeviceActionName) => void) {
  const setFeature = useSetActiveFeature();
  if (typeof id === 'undefined') {
    return (name: DeviceActionName, deviceId: string) => {
      setFeature({ id: deviceId, kind: 'device', name });
    };
  }
  return (name: DeviceActionName) => {
    setFeature({ id, kind: 'device', name });
  };
}

/**
 *
 */
function useActiveFeatureState(): {
  activeFeature: ActiveFeature | null;
  setActiveFeature: (payload: ActiveFeaturePayload | null) => {
    payload: ActiveFeaturePayload | null;
    type: string;
  };
} {
  const activeFeature = useActiveFeature();
  const setActiveFeature = useSetActiveFeature();
  return { activeFeature, setActiveFeature };
}

export type {
  AppAction,
  ActiveFeaturePayload,
  GlobalFeature,
  StartDeviceAction,
  StartFieldAction,
  StartGlobalAction,
  ActiveFeature
};
export {
  useClearActiveFeature,
  useSetActiveFeature,
  useStartDeviceAction,
  useActiveFeatureState
};

export default Actions;
