import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid
} from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import SignOutBtn from 'src/auth/SignOutBtn';
import { AppButton } from 'src/components';
import ShowOnlyIf from 'src/components/util/ShowOnlyIf';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { clearErrors } from 'src/userSession.reducer';

const rootStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  height: 500,
  justifyContent: 'center'
} as const;

type Props = React.PropsWithChildren<{
  readonly title: string;
}>;

/**
 * Shared wrapper for all error pages
 * @param props
 * @param props.title
 * @param props.children
 */
export default function ErrorPageWrapper({
  title,
  children
}: Props): JSX.Element {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const handleClear = () => dispatch(clearErrors());
  const history = useHistory();

  const handleClickHome = () => {
    handleClear();
    return history.push(`/`);
  };
  const handleClickBack = () => {
    handleClear();
    return history.goBack();
  };
  return (
    <div
      data-cy={testIds.errors.pageWrapper}
      id={testIds.errors.pageWrapper}
      style={rootStyle}
    >
      <Container maxWidth="xs">
        <Card>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
          >
            <CardHeader
              avatar={
                <Avatar variant="square">
                  <AppIcon iconKey="Leaf" />
                </Avatar>
              }
              title={title}
              titleTypographyProps={{ variant: 'h2' }}
            />
            <CardContent>{children}</CardContent>
          </Grid>
          <CardActions>
            <ShowOnlyIf userIsAuthenticated>
              <SignOutBtn />
            </ShowOnlyIf>
            <AppButton onClick={handleClickBack} text="back" />
            {pathname === `/` ? null : (
              <AppButton onClick={handleClickHome} text="Home" />
            )}
          </CardActions>
        </Card>
      </Container>
    </div>
  );
}
