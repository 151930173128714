import { Polygon } from '@react-google-maps/api';
import React from 'react';
import type { PolygonBaseProps } from 'src/components/map/PolygonBase';
import type { GmapsPolygonPath } from 'src/geo';
import { Geo } from 'src/geo';
import { BASE_COLORS, COMPONENT_STYLES } from 'src/theme';

const DEFAULTS = {
  field: {
    fillOpacity: 0.1,
    strokeWeight: 1
  }
};

type Props = Pick<
  PolygonBaseProps,
  | 'clickable'
  | 'editable'
  | 'fillColor'
  | 'isHidden'
  | 'onClick'
  | 'onLoad'
  | 'onMouseUp'
> & {
  children?: React.ReactNode;
  pathGmaps: GmapsPolygonPath;
  id?: string;
  isArchived: boolean;
  isHighlighted: boolean;
  isMuted: boolean;
  isSelected: boolean;
  rowDirectionOverride?: number;
};

/**
 * Represents a farm field on the status map
 *
 *
 * @param props
 * @param props.clickable
 * @param props.pathGmaps
 * @param props.onClick
 * @param props.isHidden
 * @param props.children
 * @param props.editable
 * @param props.onLoad
 * @param props.onMouseUp
 * @param props.fillColor
 * @param props.isHighlighted
 * @param props.isMuted
 */
function FarmFieldPolygon({
  clickable,
  pathGmaps,
  onClick,
  isHidden = false,
  children,
  editable,
  onLoad,
  onMouseUp,
  fillColor,
  isHighlighted,
  isMuted
}: Props): JSX.Element | null {
  let { strokeWeight, fillOpacity } = DEFAULTS.field;
  if (typeof fillColor === 'undefined') {
    fillColor = COMPONENT_STYLES.fields.polygon.color;
  }
  let outlineColor = fillColor;

  if (isHighlighted) {
    fillOpacity = fillOpacity + 0.1;
    outlineColor = 'gold';
    strokeWeight = 2;
  } else if (isMuted) {
    fillColor = 'green';
    outlineColor = BASE_COLORS.charcoal;
    fillOpacity = 0.3;
    strokeWeight = 0.5;
  }

  // const handleStatusChange = React.us

  const optionsMemoized = React.useMemo(
    (): google.maps.PolygonOptions => ({
      clickable,
      fillColor,
      fillOpacity,
      strokeColor: outlineColor,
      strokeWeight,
      visible: !isHidden
    }),
    [clickable, fillColor, fillOpacity, isHidden, outlineColor, strokeWeight]
  );

  const handleClick = (e: google.maps.MapMouseEvent): void => {
    onClick && onClick(e);
  };
  return Geo.polygons.is.gmaps(pathGmaps) ? (
    <Polygon
      editable={editable}
      onClick={handleClick}
      onLoad={onLoad}
      onMouseUp={onMouseUp}
      options={optionsMemoized}
      path={pathGmaps}
    >
      {children}
    </Polygon>
  ) : null;
}

export default React.memo(FarmFieldPolygon);
