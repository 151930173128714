import { ListItem, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import InstallationTypes from '../installation-type';
import { useMetadataByDeviceId } from '../selectors';
import { Triggers, useTriggersForDevice } from '../triggers.reducer';

type Props = {
  deviceId: string;
  index: number;
  action?: React.ReactNode;
};

/**
 * @param props
 * @param props.deviceId
 * @param props.index
 * @param props.action
 */
export default function PotentialPartnerListItem({
  deviceId,
  action,
  index
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const isSelected = useAppSelector(
    (state) => state.triggers.partnerId === deviceId
  );
  const md = useMetadataByDeviceId(deviceId);
  const pairs = useTriggersForDevice(deviceId, 'DEVICE_PAIR');
  if (isNullish(md)) {
    return null;
  }
  const { deviceName, codaDeviceAlias: alias, deviceInstallationType } = md;
  const numOtherPairs = pairs.length;
  const testId = testIds.devicePairing.potentialPartners.item(alias);
  const handleSelect = () => dispatch(Triggers.selectPartnerDevice(deviceId));
  return (
    <ListItem
      button
      data-cy={testId}
      dense
      divider={index !== length - 1}
      id={testId}
      onClick={handleSelect}
      selected={isSelected}
    >
      <ListItemText
        primary={
          <span>
            <Typography color="textSecondary" component="span">
              {InstallationTypes.humanize(deviceInstallationType)}
            </Typography>{' '}
            <Typography component="span">{deviceName}</Typography>
          </span>
        }
        secondary={`Paired with ${numOtherPairs} other devices`}
        secondaryTypographyProps={{
          variant: 'caption'
        }}
      />
      {action}
    </ListItem>
  );
}
