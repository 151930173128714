import type { StyleRules } from '@material-ui/core';
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider
} from '@material-ui/core';
import type { PaletteOptions } from '@material-ui/core/styles/createPalette';
import type { Overrides } from '@material-ui/core/styles/overrides';
import type { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ExpandMore } from '@material-ui/icons';
import type { AlertClassKey, ToggleButtonClassKey } from '@material-ui/lab';
import React from 'react';
import { useAppDispatch, useIsMobile } from 'src/hooks';
import { BASE_COLORS } from 'src/theme/Theme';
import Actions from './actions';
import OpenSansLightTTF from './assets/fonts/open-sans/OpenSans-Light.ttf';
import OpenSansRegularTTF from './assets/fonts/open-sans/OpenSans-Regular.ttf';
import PoppinsBoldTTF from './assets/fonts/poppins/Poppins-Bold.ttf';
import PoppinsSemiboldTTF from './assets/fonts/poppins/Poppins-SemiBold.ttf';
import RobotoSlabTTF from './assets/fonts/roboto-slab/RobotoSlab-VariableFont_wght.ttf';
import type { ThemeType } from './farm';

const APP_STYLE = {
  sidebarToggleSpacing: {
    x: 2,
    y: 3
  },
  mapFloatingCard: {
    breakPoint: 'xs',
    spacing: { x: 1, y: 2 }
  },
  pageLayoutTopMarginPx: 30,
  sidebarWidth: 300,
  toolbarHeightPx: 56,
  mapCardMaxWidth: 500
} as const;

const MuiToggleButton: StyleRules<ToggleButtonClassKey> = {
  disabled: {},
  label: {},
  root: {},
  selected: {},
  sizeLarge: {},
  sizeSmall: {}
};

const MuiAlert: StyleRules<AlertClassKey> = {
  action: {},
  filledError: {},
  filledInfo: {},
  filledSuccess: {},
  filledWarning: {},
  icon: {},
  message: {},
  outlinedError: {},
  outlinedInfo: {},
  outlinedSuccess: {},
  outlinedWarning: {},
  root: {},
  standardError: {},
  standardInfo: {},
  standardSuccess: {},
  standardWarning: {}
};
// * NOTE: These are missing typescript definitions in the props mapping
const missingComponents = {
  MuiAlert,
  MuiToggleButton
};

const PALETTE: PaletteOptions = {
  background: {
    default: BASE_COLORS.lightGray,
    paper: BASE_COLORS.white
  },
  error: BASE_COLORS.red,
  info: {
    dark: ``,
    light: ``,
    main: `rgb(25, 120, 230)`
  },
  primary: BASE_COLORS.green,
  secondary: BASE_COLORS.yellow,
  success: BASE_COLORS.success,
  // text: {
  //   primary: `rgb(21,31,24)`,
  // },
  warning: BASE_COLORS.warning
};

const POPPINS_BOLD = 'Poppins Bold';
const POPPINS_SEMIBOLD = 'Poppins Semibold';
const ROBOTO_SLAB_BOLD = 'Roboto Slab Bold';
const OPEN_SANS_LIGHT = 'Open Sans Light';
const OPEN_SANS_REGULAR = 'Open Sans Regular';
const FONT_FAMILY_NAMES = [
  POPPINS_BOLD,
  POPPINS_SEMIBOLD,
  ROBOTO_SLAB_BOLD,
  OPEN_SANS_LIGHT,
  OPEN_SANS_REGULAR
] as const;
type FontFamilyName = typeof FONT_FAMILY_NAMES[number];
const FONT_FAMILY_TO_SOURCE: { [K in FontFamilyName]: string } = {
  [OPEN_SANS_LIGHT]: OpenSansLightTTF,
  [OPEN_SANS_REGULAR]: OpenSansRegularTTF,
  [POPPINS_BOLD]: PoppinsBoldTTF,
  [POPPINS_SEMIBOLD]: PoppinsSemiboldTTF,
  [ROBOTO_SLAB_BOLD]: RobotoSlabTTF
};

/**
 * @param fontFamily
 * @param options
 * @param fontWeight
 * @param fontStyle
 */
function makeFontFamily(
  fontFamily: FontFamilyName,
  fontWeight: CSSProperties['fontWeight'] = 400,
  fontStyle: CSSProperties['fontStyle'] = 'normal'
) {
  return {
    fontFamily,
    fontStyle,
    fontWeight,
    src: `
local('${fontFamily}'),
url(${FONT_FAMILY_TO_SOURCE[fontFamily]}) format('woff2')
`,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
  };
}
/**
 * @param props
 * @param props.children
 * @param props.isMobile
 * @param props.isDark
 * @param props.themePreference
 * @param props.isDarkMode
 */
function AppThemeProvider({
  children,
  themePreference
}: React.PropsWithChildren<{ themePreference: ThemeType }>): JSX.Element {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const isDark = themePreference === 'dark';

  const theme = React.useMemo(() => {
    const primary = isDark ? BASE_COLORS.yellow : BASE_COLORS.green;
    const secondary = isDark ? BASE_COLORS.blue : BASE_COLORS.yellow;
    const palette: PaletteOptions = {
      error: PALETTE.error,
      info: PALETTE.info,
      primary,
      secondary,
      success: PALETTE.success,
      type: themePreference,
      warning: PALETTE.warning
    } as const;
    return responsiveFontSizes(
      createTheme({
        overrides: {
          MuiAppBar: {},
          MuiButton: {
            contained: {
              backgroundColor: BASE_COLORS.yellow.main
            },
            root: {
              fontFamily: POPPINS_BOLD,
              fontWeight: 800
            },

            startIcon: {
              display: `flex`,
              justifyContent: `space-between`
            }
          },
          MuiButtonBase: {
            root: {}
          },
          MuiButtonGroup: {},
          MuiCard: {
            root: {
              flex: 1
            }
          },
          MuiCardActions: {
            root: {
              justifyContent: `flex-end`
            }
          },
          MuiCardContent: {},
          MuiCardHeader: {
            action: {
              alignSelf: `baseline`,
              display: `flex`,
              flex: 0,
              justifyContent: `flex-end`,
              marginRight: 0,
              marginTop: 8
            },
            root: {
              paddingBottom: 10,
              paddingTop: 8
            }
          },
          MuiCssBaseline: {
            '@global': {
              '@font-face': [
                makeFontFamily(POPPINS_BOLD, 700),
                makeFontFamily(POPPINS_SEMIBOLD, 500),
                makeFontFamily(OPEN_SANS_LIGHT, 300),
                makeFontFamily(OPEN_SANS_REGULAR),
                makeFontFamily(ROBOTO_SLAB_BOLD, 500)
              ],
              body: {
                height: `100%`,
                minHeight: `100%`,
                minWidth: `100%`,
                position: `relative`,
                width: `100%`
              },
              html: {
                height: `100%`,
                width: `100%`
              }
            }
          },
          MuiDialogContent: {},
          MuiFab: {
            root: {
              // backgroundColor: BASE_COLORS.yellow.main,
              // color: '#fcfcfc',
              fontFamily: POPPINS_BOLD
            }
          },

          MuiIcon: {},
          MuiIconButton: {},
          MuiList: {
            root: {
              flex: 1
            }
          },
          ...(missingComponents as Partial<Overrides>)
        },
        palette,
        props: {
          MuiAccordion: {},
          MuiAccordionSummary: {
            expandIcon: <ExpandMore />
          },
          MuiAppBar: {
            color: 'default',
            elevation: 1
          },
          MuiButton: {
            disableElevation: true
            // fullWidth: isMobile,
          },
          MuiButtonBase: {},
          MuiButtonGroup: {},
          MuiCard: {
            variant: `outlined`
          },
          MuiCardActions: {},
          MuiCardContent: {},
          MuiCardHeader: {
            titleTypographyProps: {
              variant: `h6`
            }
          },
          MuiChip: {
            size: isMobile ? `small` : `medium`
          },
          MuiDialog: {
            fullWidth: true,
            id: 'dialog',
            maxWidth: 'sm',
            onClose: () => dispatch(Actions.closeDialog()),
            scroll: `paper`
          },
          MuiDialogActions: {},
          MuiDialogContent: {
            dividers: false
          },
          MuiDialogTitle: {},
          MuiFab: {
            color: `secondary`,
            variant: `extended`
          },
          MuiInputBase: {
            fullWidth: true
          },
          MuiPaper: {
            elevation: 0
          },
          MuiSelect: {
            fullWidth: true,
            variant: `outlined`
          },
          MuiSlider: {},
          MuiTextField: {
            fullWidth: true,
            size: `small`,
            variant: `outlined`
          },
          MuiToolbar: {},
          MuiTooltip: {
            arrow: true
          }
        },
        typography: {
          body1: {
            fontFamily: OPEN_SANS_REGULAR
          },
          body2: {
            fontFamily: OPEN_SANS_REGULAR
          },
          button: { fontFamily: POPPINS_SEMIBOLD },
          caption: {
            fontFamily: POPPINS_SEMIBOLD
          },
          fontFamily: FONT_FAMILY_NAMES.join(','),
          h1: {
            fontFamily: POPPINS_BOLD
          },
          h2: {
            fontFamily: ROBOTO_SLAB_BOLD
          },
          h3: {
            fontFamily: POPPINS_SEMIBOLD
          },
          h4: {
            fontFamily: POPPINS_BOLD
          },
          h5: {
            fontFamily: POPPINS_SEMIBOLD
          },
          h6: { fontFamily: ROBOTO_SLAB_BOLD },
          // overline: {},
          subtitle1: { fontFamily: ROBOTO_SLAB_BOLD },
          subtitle2: { fontFamily: POPPINS_SEMIBOLD }
        }
        // shape: {
        //   borderRadius: 0,
        // },
      })
    );
  }, [dispatch, isDark, isMobile, themePreference]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
export { APP_STYLE };
export default AppThemeProvider;
