import { createCachedSelector } from 're-reselect';
import { Geo } from 'src/geo';
import type { RootState } from 'src/store';
import { isNullish } from 'src/types';
import createEntitySelectors from 'src/utilities/createEntitySelectors';
import type { FarmField } from './farmFields.reducer';
import getClosestFieldToEvent from './getClosestFieldToEvent';

const getFieldForDeviceEventCached = createCachedSelector(
  (state: RootState, id: string) =>
    state.deviceEventLast.entities[id]?.gps?.location,
  createEntitySelectors((state) => state.farmFields).all,
  (location, fields) => {
    if (location) {
      const asGeoJson = Geo.points.to.geoJson(location);
      let inField = false;
      let field: FarmField | null | undefined = fields.find((f) => {
        return Geo.polygons.containsPoint(f.polygon, asGeoJson);
      });

      if (isNullish(field)) {
        field = getClosestFieldToEvent({
          fields,
          location: asGeoJson
        });
      } else {
        inField = true;
      }
      if (isNullish(field)) {
        return undefined;
      }

      return {
        ...field,
        inField
      };
    }
    return undefined;
  }
)((_, id) => id);
export default getFieldForDeviceEventCached;
