import type { SlideProps } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton, IconBtn } from 'src/components';
import { NoListItems } from 'src/components/lists';
import Sensors from 'src/devices/sensors';
import { useAppDispatch, useAppSelector, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish, isPositiveInt, notNullish } from 'src/types';
import { DateFormatting } from 'src/utilities';
import getTimeDelta from 'src/utilities/getTimedelta';

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Slide,
  Typography
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import {
  asyncGetEventsForDevice,
  DeviceEventInspector
} from './inspectRecentEvents.reducer';
import { ReloadButton } from './ReloadButton';
import { SensorReadingsList } from './SensorReadingsList';

import type { DeviceMetadata } from 'src/devices';
const STYLES: {
  [k in 'loadingContainer' | 'refreshBtnContainer']: React.CSSProperties;
} = {
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
    width: '100%'
  },
  refreshBtnContainer: {
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short'
});
const Ids = testIds.inspectRecentEvents;

const {
  actions,
  getFetchStatus,
  getAllEvents,
  getSelectedIndex,
  getSensorNameOptions,
  getSelectedEvent,
  getSelectedSensorName
} = DeviceEventInspector;

/**
 * @param props
 * @param props.deviceId
 */
function Main({ deviceId }: DeviceMetadata): JSX.Element {
  const dispatch = useAppDispatch();
  const { sendRequest, isPending } = useThunk(asyncGetEventsForDevice);

  const selectedSensor = useSelector(getSelectedSensorName);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [lastResponseTime, setLastResponseTime] = React.useState<number>();

  const responseTimeFormatted = isPositiveInt(lastResponseTime)
    ? DateFormatting.toTimeAgoText(new Date(lastResponseTime))
    : null;

  const fetchStatus = useAppSelector(getFetchStatus);
  const selectedIndex = useAppSelector(getSelectedIndex);
  const event = useAppSelector(getSelectedEvent);
  const sensorNames = useSelector(getSensorNameOptions);
  const events = useAppSelector(getAllEvents);
  const numEvents = events.length;

  const [slideProps, setSlideProps] = React.useState<SlideProps>({
    in: true,
    direction: 'left'
  });
  const handleGetEvents = React.useCallback(async (): Promise<void> => {
    const minDateMs = getTimeDelta({
      unit: 'days',
      value: 180
    });
    await sendRequest({ deviceId, maxDateMs: new Date().getTime(), minDateMs })
      .then(() => {
        setLastResponseTime(new Date().getTime());
        if (isInitialLoad) {
          setIsInitialLoad(false);
        } else {
          showSuccessToast('Events updated ');
        }
      })
      .catch(handleApiRequestError);
  }, [deviceId, isInitialLoad, sendRequest]);

  React.useEffect(
    /**
     *
     */
    function handleNoSensorOnResponse() {
      if (isNullish(selectedSensor) && sensorNames.length) {
        const [sn] = sensorNames;
        if (sn) {
          dispatch(actions.selectSensorName(sn));
        }
      }
    },
    [dispatch, selectedSensor, sensorNames]
  );

  // const formatKey = Sensors.useFormatKey();
  const formatValue = Sensors.useFormatValue();

  React.useEffect(
    /**
     *
     */
    function onLoad() {
      dispatch(actions.initialize(deviceId));
    },
    [deviceId, dispatch]
  );

  React.useEffect(
    /**
     *
     */
    function getEvents() {
      if (
        fetchStatus === 'shouldFetch' &&
        notNullish(deviceId) &&
        numEvents === 0
      ) {
        handleGetEvents();
      }
    },
    [deviceId, fetchStatus, handleGetEvents, numEvents]
  );

  const dt = event?.deviceEventTimestamp;
  const formattedTimestamp = notNullish(dt)
    ? dateFormatter.format(new Date(dt))
    : null;

  const currentEventText =
    typeof selectedIndex === 'number'
      ? `Showing event ${selectedIndex + 1} of  last ${events.length} events`
      : '';
  if (isPending) {
    return (
      <div style={STYLES.loadingContainer}>
        <Card>
          <CardContent>
            <CircularProgress />
            Loading Events...
          </CardContent>
        </Card>
      </div>
    );
  }
  if (events.length === 0) {
    return (
      <NoListItems title="No updates available">
        <div style={STYLES.refreshBtnContainer}>
          <Typography>
            {notNullish(responseTimeFormatted)
              ? `Last Checked: ${responseTimeFormatted}`
              : null}
          </Typography>
          <Avatar>
            <IconBtn
              iconKey="REFRESH"
              onClick={handleGetEvents}
              showLoading={isPending}
            />
          </Avatar>
        </div>
      </NoListItems>
    );
  }

  return (
    <div>
      <Box mb={2}>
        <React.Fragment>
          <CardHeader subheader={currentEventText} title="Recent Events" />
          <CardActions data-cy={Ids.navigator} id={Ids.navigator}>
            <ReloadButton id={Ids.refreshBtn} onClick={handleGetEvents} />
            <AppButton
              disabled={isPending || selectedIndex === numEvents - 1}
              icon={<ArrowBack />}
              id={Ids.olderBtn}
              onClick={() => {
                dispatch(actions.selectOlderEvent());
                setSlideProps({ in: false, direction: 'left' });
              }}
              text="Older"
            />
            <AppButton
              disabled={isPending || selectedIndex === 0}
              icon={<ArrowForward />}
              iconPosition="right"
              id={Ids.newerBtn}
              onClick={() => {
                dispatch(actions.selectNewerEvent());
                setSlideProps({ in: false, direction: 'right' });
              }}
              text="Newer"
            />
            <Grid item>{formattedTimestamp}</Grid>
          </CardActions>
        </React.Fragment>
      </Box>
      {notNullish(event) ? (
        <React.Fragment>
          <CardActions>
            <Grid container spacing={1}>
              {sensorNames?.map((sn) => {
                const isSelected = sn === selectedSensor;
                const sensorNameFormatted = formatValue('sensorName', sn);
                const handleClickSensor = (): void => {
                  dispatch(actions.selectSensorName(sn));
                };
                return sensorNameFormatted === null ? null : (
                  <Grid item key={sn} xs={4}>
                    <AppButton
                      fullWidth
                      id={Ids.viewSensorBtn(sn)}
                      onClick={handleClickSensor}
                      size="small"
                      text={sensorNameFormatted}
                      variant={isSelected ? `contained` : 'outlined'}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CardActions>

          <Slide
            onExited={() =>
              setSlideProps((prev) => ({
                in: true,
                direction: prev.direction === 'left' ? 'right' : 'left'
              }))
            }
            {...slideProps}
          >
            <CardContent>
              <Grid container spacing={1}>
                {notNullish(selectedSensor) ? (
                  <Grid item xs={12}>
                    <SensorReadingsList
                      deviceId={deviceId}
                      sensorName={selectedSensor}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
/**
 * @param props
 */
export default function InspectRecentEvents(
  props: DeviceMetadata
): JSX.Element {
  return (
    <div data-cy={Ids.root} id={Ids.root}>
      <Main {...props} />
    </div>
  );
}
