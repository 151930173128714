import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  TextField
} from '@material-ui/core';
import React from 'react';
import InputMask from 'react-input-mask';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { AppButton, CancelBtn, GridWrapper, SubmitBtn } from 'src/components';
import { NoListItems } from 'src/components/lists';
import { useAppDispatch, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isPositiveInt, notNullish } from 'src/types';
import { pluralize } from 'src/utilities';
import PhoneNumberListItem from './PhoneNumberListItem';
import {
  asyncCreatePhoneNumber,
  asyncGetPhoneVerificationCode,
  PHONE_NUMBER_VALIDATION,
  removePhoneNumberSeparators,
  Upn as Base
} from './phoneNumbers.reducer';
const { clickAddPhoneNumber, selectIds } = Base;
/**
 *
 */
function AddNumberForm(): JSX.Element {
  const Ids = testIds.phoneNumbers.create;
  const { sendRequest, isPending } = useThunk(asyncCreatePhoneNumber);
  const [value, setValue] = React.useState('');
  const phoneNumberStr = `+${removePhoneNumberSeparators(value)}`;
  const disableSubmit =
    phoneNumberStr.length < PHONE_NUMBER_VALIDATION.NUM_DIGITS + 1 || isPending;
  const handleSubmit = () =>
    sendRequest({ phoneNumberStr }).catch(handleApiRequestError);
  return (
    <div id={Ids.field}>
      <Box my={2}>
        <InputMask
          disabled={isPending}
          mask={PHONE_NUMBER_VALIDATION.INPUT_MASK}
          maskPlaceholder={PHONE_NUMBER_VALIDATION.PLACEHOLDER}
          name="phoneNumber"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        >
          {() => (
            <TextField
              inputProps={{
                id: Ids.input
              }}
              label="Phone Number"
            />
          )}
        </InputMask>
      </Box>
      <GridWrapper spacing={2}>
        <CancelBtn fullWidth />
        <SubmitBtn
          color="secondary"
          disabled={disableSubmit}
          fullWidth
          id={Ids.submitBtn}
          onClick={handleSubmit}
          showLoading={isPending}
        />
      </GridWrapper>
    </div>
  );
}

const connector = connect((state) => {
  const { verifyingId } = state.userPhoneNumbers;
  return {
    verifyingId,
    isAddingNumber: state.userPhoneNumbers.isAdding === true,
    phoneNumberIds: selectIds(state) as number[],
    isVerifying: notNullish(verifyingId)
  };
});

type Props = ConnectedProps<typeof connector>;

/**
 * @param props
 * @param props.isVerifying
 * @param props.phoneNumberIds
 * @param props.isAddingNumber
 * @param props.verifyingId
 */
function PhoneNumbers({
  isVerifying,
  verifyingId,
  phoneNumberIds,
  isAddingNumber
}: Props) {
  const Ids = testIds.phoneNumbers;

  const dispatch = useAppDispatch();

  const { sendRequest, isPending } = useThunk(asyncGetPhoneVerificationCode);

  React.useEffect(() => {
    if (isPositiveInt(verifyingId)) {
      sendRequest({ upnId: verifyingId }).catch(handleApiRequestError);
    }
  }, [sendRequest, verifyingId]);

  const title = pluralize({
    count: phoneNumberIds.length,
    word: 'Phone Number'
  });
  const handleClickAddNumber = () => dispatch(clickAddPhoneNumber());
  return (
    <Card data-cy={Ids.root} id={Ids.root}>
      <CardHeader title={title} />
      <CardContent>
        {isAddingNumber ? (
          <AddNumberForm />
        ) : phoneNumberIds.length === 0 ? (
          <NoListItems title="Add your phone number to begin receiving status updates" />
        ) : (
          <List data-cy={Ids.list.root} id={Ids.list.root}>
            {phoneNumberIds.map((id, index) => (
              <PhoneNumberListItem
                index={index}
                isVerifying={id === verifyingId}
                key={id}
                upnId={id}
              />
            ))}
          </List>
        )}
      </CardContent>
      <CardActions>
        <AppButton
          disabled={isPending}
          iconKey="ADD_ITEM"
          id={Ids.addBtn}
          isHidden={isAddingNumber || isVerifying}
          onClick={handleClickAddNumber}
          presetKey="ACTION"
          text="add phone number"
          variant="text"
        />
      </CardActions>
    </Card>
  );
}

export default connector(PhoneNumbers);
