import { isNumber, isString } from 'lodash-es';
import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import Actions from 'src/actions';
import { getActiveFeature } from 'src/appSelectors';
import { RowDirection } from 'src/components/map';
import { FarmFields } from 'src/fields';
import { Geo } from 'src/geo';
import { useAppDispatch } from 'src/hooks';
import type { RootState } from 'src/root.reducer';
import { COMPONENT_STYLES } from 'src/theme';
import { isNullish, isPositiveInt, isTruthyString } from 'src/types';
import FieldNameLabel from '../fields/FieldLabel';
import FieldPolygonMemoized from '../fields/FieldPolygon';
import { getIsVisibleOnMap } from './statusMap.reducer';

type OwnProps = {
  searchVal: string;
  fieldId: number;
  rowDirectionOverride?: number;
};
const connector = connect(
  (state: RootState, { fieldId, searchVal }: OwnProps) => {
    const selectedId = FarmFields.getSelectedFieldId(state);

    const isSelected = selectedId === fieldId;
    const { currentZoom } = state.statusMap;
    const activeFeature = getActiveFeature(state);
    const isEditingShape = activeFeature === 'EDIT_FIELD_SHAPE';
    const isSettingRowDirection = activeFeature === 'SET_ROW_DIRECTION';
    const field = FarmFields.getFieldByIdCached(state, fieldId);
    const fieldName = field?.fieldName.toLocaleLowerCase() ?? '';

    let isHidden = false;
    if (field?.isActive === false && !isSelected) {
      isHidden = true;
    } else if (!getIsVisibleOnMap(state, 'field')) {
      isHidden = true;
    } else if (isTruthyString(searchVal) && !fieldName.includes(searchVal)) {
      isHidden = true;
    }

    const clickable = activeFeature === null;
    const polygon = field?.polygon;
    const latLngBounds = polygon
      ? Geo.polygons.make.buffer(polygon)
      : undefined;
    return {
      ...field,
      clickable,
      fieldId: field?.id,
      fillColor:
        state.statusMap.fieldColor ?? COMPONENT_STYLES.fields.polygon.color,
      hideArrows: isHidden || currentZoom < 13,
      hideLabels: isHidden || currentZoom < 13,
      id: fieldId,
      isEditingShape,
      isHidden,
      isMuted: isNumber(selectedId) && !isSelected,
      isSelected,
      isSettingRowDirection,
      latLngBounds,
      selectedId
    };
  }
);

type Props = ConnectedProps<typeof connector>;

/**
 * @param props
 * @param props.clickable
 * @param props.isHidden
 * @param props.fillColor
 * @param props.isSelected
 * @param props.pathGmaps
 * @param props.isMuted
 * @param props.isActive
 * @param props.isSettingRowDirection
 * @param props.isEditingShape
 * @param props.centerGmaps
 * @param props.center
 * @param props.rowDirectionAzimuthDegrees
 * @param props.fieldId
 * @param props.fieldName
 * @param props.hideArrows
 * @param props.hideLabels
 * @param props.boundingRadiusMeters
 * @param props.polygon
 */
function Field({
  clickable,
  isHidden,
  fillColor,
  isSelected,
  pathGmaps,
  isMuted,
  isActive,
  isSettingRowDirection,
  isEditingShape,
  centerGmaps,
  center,
  rowDirectionAzimuthDegrees,
  fieldId,
  fieldName,
  hideLabels,
  boundingRadiusMeters,
  polygon
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const handleClick = () =>
    isPositiveInt(fieldId) &&
    dispatch(Actions.selectItem({ id: fieldId, kind: 'field' }));
  if (isHidden || isNullish(pathGmaps) || pathGmaps.length <= 3) {
    return null;
  }
  return !isNullish(pathGmaps) && pathGmaps.length >= 3 ? (
    <React.Fragment>
      <FieldPolygonMemoized
        clickable={clickable}
        editable={isEditingShape}
        fillColor={fillColor}
        isArchived={isActive === false}
        isHidden={isHidden || isEditingShape}
        isHighlighted={isSelected}
        isMuted={isMuted}
        isSelected={isSelected}
        onClick={handleClick}
        pathGmaps={pathGmaps}
      />
      {!isNullish(centerGmaps) ? (
        <React.Fragment>
          {isString(fieldName) ? (
            <FieldNameLabel
              center={centerGmaps}
              clickable={clickable}
              fieldName={fieldName}
              isArchived={isActive === false}
              isHidden={hideLabels}
              onClick={handleClick}
              rowDirectionAzimuthDegrees={rowDirectionAzimuthDegrees ?? 0}
              testId={fieldName}
            />
          ) : null}
          {!isNullish(center) &&
          !isNullish(polygon) &&
          isNumber(rowDirectionAzimuthDegrees) ? (
            <RowDirection.Arrow
              azimuth={rowDirectionAzimuthDegrees}
              boundingRadiusMeters={boundingRadiusMeters ?? 200}
              center={center}
              clickable={clickable}
              enclosingPolygon={polygon}
              fillColor="cyan"
              fillOpacity={1}
              isHidden={!isSettingRowDirection}
              onClick={handleClick}
              strokeOpacity={1}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  ) : null;
}

const StatusMapField = connector(Field);
export default StatusMapField;
