import React from 'react';
import { SubmitBtn } from 'src/components';
import useMagnetSelect from '../forms/useMagnetSelect';
import { useSubmitEditSensorConfig } from './configManager.reducer';
import FormWrapper from './FormWrapper';
import type { FieldProps } from './NumericTextField';

/**
 * @param props
 * @param props.initialValue
 * @param props.sensorName
 */
export function MagnetForm<S extends 'reel' | 'wheel'>({
  initialValue,
  sensorName
}: Omit<FieldProps<S>, 'fieldName'>): JSX.Element {
  const { MagnetSelect, nMagnets } = useMagnetSelect({
    initialValue: initialValue ?? undefined,
    isActive: true,
    sensorName
  });

  const { onSubmit } = useSubmitEditSensorConfig();
  const handleSubmit = () => onSubmit(nMagnets);
  return (
    <FormWrapper submitBtn={<SubmitBtn onClick={handleSubmit} />}>
      <MagnetSelect />
    </FormWrapper>
  );
}
