import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AppButton, MailToButton, ShowOnlyIf } from 'src/components';
import PATHS from 'src/constants/Paths';
import { useAppSelector } from 'src/hooks';
import FarmUserPermissions from 'src/permissions/FarmUserPermissions';
import PermissionListItem from 'src/permissions/PermissionListItem';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { isNullish } from 'src/types';
import { DateFormatting } from 'src/utilities';
import { getFarmJoinCodeState } from './farmJoinCodes.reducer';

/**
 * @param codeStatus
 * @param codeStr
 */
function getMessage(
  codeStatus: string | null | undefined,
  codeStr: string | null | undefined
): React.ReactNode {
  return codeStatus === `NOT_CREATED` || isNullish(codeStr)
    ? `Your code has not been created. Users will not be able to join your farm account. `
    : codeStatus === `EXPIRED`
    ? `Your farm join code is expired. Click to create a new code.`
    : codeStr;
}
const Ids = testIds.farmJoinCodes;
/**
 *
 */
function FarmJoinCode(): JSX.Element {
  const { codeStatus, permissions, expirationDate, codeStr } =
    useAppSelector(getFarmJoinCodeState);
  const activeCode = codeStatus === 'ACTIVE';
  return (
    <Card data-cy={Ids.current.root} id={Ids.current.root}>
      <CardHeader
        data-cy={Ids.current.status}
        subheader={codeStatus}
        title="Join Code"
      />
      <CardContent>
        <Alert
          data-cy={activeCode ? 'attention' : 'warning'}
          icon={activeCode ? false : <AppIcon iconKey="warning" />}
          severity={activeCode ? 'info' : 'warning'}
        >
          {getMessage(codeStatus, codeStr)}
          {codeStatus === 'ACTIVE'
            ? `(Expires: ${
                DateFormatting.toDateHoursAndMinutes(expirationDate) ?? 'N/A'
              })`
            : null}
        </Alert>
      </CardContent>
      <CardActions>
        <ShowOnlyIf requiredPermissions="canManageJoinCodes">
          <AppButton
            iconKey="ADD_ITEM"
            id={Ids.newCode.startBtn}
            linkTo={PATHS.account.newJoinCode}
            requiredPermissions="canManageJoinCodes"
            text="New Code"
          />
        </ShowOnlyIf>
        <MailToButton
          disabled={codeStatus !== `ACTIVE`}
          id={testIds.shareCodeBtn}
          startIcon={<AppIcon iconKey="SHARE" />}
        >
          Share
        </MailToButton>
      </CardActions>
      {codeStatus === `ACTIVE` && !isNullish(permissions) ? (
        <CardContent>
          <Accordion>
            <AccordionSummary data-cy={Ids.current.viewPermissionsBtn}>
              View Permissions
            </AccordionSummary>
            <AccordionDetails>
              <List
                data-cy={Ids.current.permissionsList}
                dense
                disablePadding
                style={{ flex: 1 }}
              >
                {FarmUserPermissions.keys.map((key, i) => (
                  <PermissionListItem
                    dense
                    disableGutters
                    divider={i < FarmUserPermissions.keys.length - 1}
                    isEnabled={permissions[key]}
                    key={key}
                    perm={key}
                  />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      ) : null}
    </Card>
  );
}
export default FarmJoinCode;
