import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import testIds from 'src/testIds';
import Actions from '../actions';
import { getEventsNearField } from '../appSelectors';
import type { FarmField } from '../fields';
import FieldIconMemoized from '../fields/FieldIcon';
import { useAppDispatch } from '../hooks';
import { pluralize } from '../utilities';

type Props = Pick<FarmField, 'fieldName' | 'id' | 'isActive' | 'polygon'> & {
  index: number;
};

const FieldListRow = React.memo<Props>(
  ({ id, index, fieldName, isActive, polygon }) => {
    const dispatch = useAppDispatch();
    const devicesNearField = useSelector((state) =>
      getEventsNearField(state, id)
    );

    const isArchived = !isActive;

    const numActiveNearBy = devicesNearField.length ?? 0;

    const nearbyActivityText =
      numActiveNearBy > 0
        ? `${numActiveNearBy} ${pluralize({
            count: numActiveNearBy,
            word: 'device'
          })} active nearby`
        : 'No nearby activity';
    const handleClick = () =>
      dispatch(
        Actions.selectItem({
          id,
          kind: 'field'
        })
      );
    const primary = `${fieldName} ${isArchived ? '(Archived)' : ''}`;
    const testId = testIds.fieldsList.item(index);
    return (
      <ListItem
        button
        data-cy={testId}
        dense
        divider
        id={testId}
        key={id}
        onClick={handleClick}
      >
        <ListItemIcon>
          <FieldIconMemoized polygon={polygon} />
        </ListItemIcon>
        <ListItemText primary={primary} secondary={nearbyActivityText} />
      </ListItem>
    );
  }
);

FieldListRow.displayName = 'FieldListRow';

export default FieldListRow;
