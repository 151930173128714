import { isString } from 'lodash-es';
import { appLogger } from './logger';

/**
 * @param dt
 * @param options
 */
export default function formatExpiration(
  dt: string | undefined,
  options?: Intl.DateTimeFormatOptions
): string | null {
  const formatter = new Intl.DateTimeFormat(
    undefined,
    options ?? {
      dateStyle: 'medium',
      timeStyle: 'short'
    }
  );

  if (isString(dt)) {
    try {
      const asDate = new Date(dt);
      const ms = asDate.getTime();
      const seconds = ms / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      const years = Math.floor(days / 365);
      if (years > 10) {
        return 'Never';
      }
      return formatter.format(asDate);
    } catch (err) {
      appLogger.debug(`Failed to format expiration date: value=${dt}`);
    }
  }
  return null;
}
