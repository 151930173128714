import convert from 'convert-units';
import { isNullish, isNum, isNumericString } from 'src/types';
import { appLogger } from 'src/utilities';
import type { RawSensorValue } from './formatSensorValue';
import type {
  AnySensorKey,
  ConversionTarget,
  RequiresConversion
} from './getConversionUnits';
import getConversionUnits, { requiresConversion } from './getConversionUnits';

type Args<K extends AnySensorKey, V extends RawSensorValue> = {
  readonly key: K;
  readonly value: V;
  readonly isMetric: boolean;
  readonly target?: ConversionTarget;
};

type Converted = {
  result: 'CONVERTED';
  value: number;
};
type NotConverted<V extends RawSensorValue> = {
  result: 'NOT_CONVERTED';
  value: V;
};
type Result<
  K extends AnySensorKey,
  V extends RawSensorValue
> = K extends RequiresConversion<K>
  ? V extends number
    ? Converted
    : NotConverted<V>
  : NotConverted<V>;

// type T = Result<'ioPin', 3>;
/**
 *
 * @param args
 * @param args.key
 * @param args.value
 * @param args.isMetric
 * @param args.target
 * @returns converted sensor value based on user preference and specified target
 */
export default function convertSensorValue<
  K extends AnySensorKey,
  V extends RawSensorValue
>({ key, value, isMetric, target = 'user' }: Args<K, V>): Result<K, V> {
  const conversionSpec = getConversionUnits({
    isMetric,
    key,
    target
  });
  if (!requiresConversion(key)) {
    return {
      result: 'NOT_CONVERTED',
      value
    } as Result<K, V>;
  }
  if (isNullish(conversionSpec)) {
    throw new Error(`Not a conversion key: ${JSON.stringify(key)}`);
  }

  const { rawUnit, userPref, sigDigits } = conversionSpec;
  const inputValue = isNum(value)
    ? value
    : isNumericString(value)
    ? parseFloat(value)
    : 0;
  let converted: number;
  switch (rawUnit) {
    case 'mm/m': {
      const divisor = isMetric ? 16.67 : 5.08;
      if (target === 'database') {
        appLogger.warn('Converting key in wrong direction', key);
      }
      converted = Math.round((inputValue / divisor) * 10) / 10;
      break;
    }
    case 'millirpm':
      if (target === 'database') {
        converted = Math.round(inputValue * 1000);
        break;
      }
      converted = inputValue / 1000;
      break;

    default: {
      if (userPref === 'ft/hr' || userPref === 'meters/hr') {
        throw new Error(`Fallthrough in switch: ${JSON.stringify(key)}`);
      }

      const result =
        target === 'database'
          ? convert(inputValue).from(userPref).to(rawUnit)
          : convert(inputValue).from(rawUnit).to(userPref);

      if (sigDigits > 0) {
        converted = parseFloat(result.toFixed(sigDigits));
        break;
      }
      converted = Math.round(result);
      break;
    }
  }
  return {
    result: 'CONVERTED',
    value: converted
  } as Result<K, V>;
}
