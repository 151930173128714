import React from 'react';
import { useAppDispatch } from 'src/hooks';
import { setFieldColor } from 'src/status-map/statusMap.reducer';
import ZOOM_THRESHOLDS from 'src/status-map/ZoomThresholds';
import { COMPONENT_STYLES } from 'src/theme';
import localStorage from 'storejs';

export type MapZoomProps = {
  readonly currentZoom: number;
};

type UseMapController = {
  readonly currentZoom: number;
  readonly getMapRef: (map: google.maps.Map) => void;
  readonly handleZoomChange: () => void;
  readonly mapRef: React.MutableRefObject<google.maps.Map | null>;
};

/**
 *
 */
function useMapController(): UseMapController {
  const mapRef = React.useRef<google.maps.Map | null>(null);
  const dispatch = useAppDispatch();

  const [currentZoom, setZoom] = React.useState<number>(() => {
    return ZOOM_THRESHOLDS.mapDefault;
  });

  const getMapRef = React.useCallback(
    (map: google.maps.Map) => {
      dispatch(
        setFieldColor(
          localStorage.get('fieldColor') ??
            COMPONENT_STYLES.fields.polygon.color
        )
      );
      mapRef.current = map;
      setZoom(mapRef.current.getZoom());
    },
    [dispatch]
  );

  const handleZoomChange = React.useCallback(
    () =>
      setZoom((prev) => {
        return mapRef.current?.getZoom() ?? prev;
      }),
    []
  );
  return {
    currentZoom,
    getMapRef,
    handleZoomChange,
    mapRef
  };
}

export default useMapController;
