import type { RootState } from './root.reducer';
import { notNullish } from './types';

/**
 * @param state
 */
export function getActiveFarmId(state: RootState): number | undefined {
  return state.userSession.activeFarmId;
}
/**
 *
 * @param target
 * @param activeFarmId
 */
export function findActiveFarm<T extends { id: number }>(
  target: T[],
  activeFarmId: number | null | undefined
): { farm: T; index: number } | undefined {
  if (notNullish(activeFarmId)) {
    const index = target.findIndex((farm) => farm.id === activeFarmId);
    const result = target[index];
    if (result) {
      return {
        farm: result,
        index
      };
    }
  }
  return undefined;
}
