import rootReducer from 'src/root.reducer';

import { configureStore } from '@reduxjs/toolkit';

import reduxLogger from './utilities/reduxLogger';

import type { AuthErrorCodeValue } from 'src/constants/Cognito';
import type { RootState } from 'src/root.reducer';
import type { JoinFarmErrorCode } from './account-settings/asyncUserJoinFarm';
import type { AddDeviceStatus } from './devices/device-farm-association';
const store = configureStore({
  /**
   * @param getDefaultMiddleware
   */
  middleware: (getDefaultMiddleware) => {
    if (process.env.REACT_APP_LOG_ACTIONS === 'TRUE') {
      return getDefaultMiddleware().concat(reduxLogger);
    }
    // return getDefaultMiddleware();
    return getDefaultMiddleware();
  },

  reducer: rootReducer
});

type AppDispatch = typeof store.dispatch;
type RootSelector<R, A extends [...any[]] = never[]> = (
  state: RootState,
  ...args: A
) => R extends infer U ? U : R;

type ThunkRejectMessage =
  | AddDeviceStatus
  | AuthErrorCodeValue
  | JoinFarmErrorCode
  | 'CONFIRMATION_REQUIRED'
  | 'DENIED'
  | 'No current user'
  | 'TIMED_OUT';

type RootThunkApi<T extends ThunkRejectMessage = ThunkRejectMessage> = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: {
    code: T;
    message?: string;
  };
};

export type {
  ThunkRejectMessage,
  AppDispatch,
  RootThunkApi,
  RootState,
  RootSelector
};
export default store;
