import userPhoneNumbers from 'src/account-settings/phoneNumbers.reducer';
import auth from 'src/auth/reducer';
import deviceEventLast from 'src/devices/deviceEventLast.reducer';
import inspectRecentEvents from 'src/devices/inspect-recent-events/inspectRecentEvents.reducer';
import triggers from 'src/devices/triggers.reducer';
import farmUsers from 'src/farm/farmUsers.reducer';
import farmFields from 'src/fields/farmFields.reducer';
import reelRuns from 'src/reel-runs/reelRunActive.reducer';
import userSession from 'src/userSession.reducer';

import { combineReducers } from '@reduxjs/toolkit';

import appFeature from './activeFeature.reducer';
import configManager from './devices/config-manager/configManager.reducer';
import createConfiguration from './devices/create-configuration/createConfig.reducer';
import deviceFarmAssociations from './devices/device-farm-association/deviceFarmAssociations.reducer';
import deviceShareCodes from './devices/device-ownership/deviceShareCodes.reducer';
import deviceUserAssociations from './devices/device-user/deviceUserAssociations.reducer';
import devices from './devices/devices.reducer';
import forceUpdate from './devices/force-update/reducer';
import remoteControls from './devices/remote-controls/remoteControls.reducer';
import farmJoinCode from './farm/farmJoinCodes.reducer';
import sidebar from './sidebar/sidebar.reducer';
import createField from './status-map/create-field/createField.reducer';
import fieldRunHistory from './status-map/fieldRunHistory.reducer';
import statusMap from './status-map/statusMap.reducer';

import type { StateType } from 'typesafe-actions';
const rootReducer = combineReducers({
  appFeature,
  auth,
  configManager,
  createConfiguration,
  createField,
  deviceEventLast,
  inspectRecentEvents,
  deviceFarmAssociations,
  deviceShareCodes,
  deviceUserAssociations,
  devices,
  farmFields,
  farmJoinCode,
  farmUsers,
  fieldRunHistory,
  forceUpdate,
  reelRunsActive: reelRuns,
  remoteControls,
  sidebar,
  statusMap,
  triggers,
  userPhoneNumbers,
  userSession
});

export default rootReducer;
export type RootState = StateType<typeof rootReducer>;
