import type { IconButtonProps as MuiProps } from '@material-ui/core';
import { IconButton, Tooltip } from '@material-ui/core';
import { isString } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { useIsAnyRequestPending } from 'src/appSelectors';
import type {
  CanBeLink,
  ChecksPermission,
  HasIconKey,
  HideableProps,
  ShowsLoader,
  WithHelpText
} from 'src/components/types';
import { useAppDispatch, useIsMobile } from 'src/hooks';
import AppIcon from 'src/theme/AppIcon';
import { denyPermission, User } from 'src/userSession.reducer';

interface IconBtnProps
  extends WithHelpText,
    HasIconKey,
    HideableProps,
    ChecksPermission,
    ShowsLoader,
    CanBeLink,
    Pick<
      MuiProps,
      | 'className'
      | 'color'
      | 'disabled'
      | 'id'
      | 'onClick'
      | 'size'
      | 'TouchRippleProps'
    > {
  responsive?: boolean;
}

const IconBtn = React.forwardRef<any, React.PropsWithChildren<IconBtnProps>>(
  (
    {
      showLoading: isLoading,
      linkTo,
      iconKey,
      size,
      requiredPermissions,
      children,

      helpText: tooltip,
      onClick,
      disabled,
      color,
      responsive,
      isHidden,
      className,
      id,
      TouchRippleProps
    },
    ref
  ) => {
    const testId = id;
    const dispatch = useAppDispatch();
    const permCheck = User.useHasPermissions(requiredPermissions);

    const history = useHistory();
    const isMobile = useIsMobile();

    const isAnyRequestPending = useIsAnyRequestPending();
    if (isHidden === true) {
      return null;
    }
    const iconElement = (
      <IconButton
        TouchRippleProps={TouchRippleProps}
        className={className}
        color={color}
        data-cy={testId}
        disabled={disabled ?? (isAnyRequestPending || isLoading)}
        id={testId}
        onClick={(e) => {
          if (isString(linkTo)) {
            e.preventDefault();
            history.push(linkTo);
          }

          if (permCheck.code === 'DENIED') {
            return dispatch(denyPermission(permCheck));
          }
          if (onClick) {
            return onClick(e);
          }
          return null;
        }}
        ref={ref}
        size={responsive === true && isMobile ? 'small' : size}
      >
        {children}
        {iconKey ? <AppIcon iconKey={iconKey} /> : null}
      </IconButton>
    );

    return isString(tooltip) ? (
      <Tooltip title={tooltip}>
        <span>{iconElement}</span>
      </Tooltip>
    ) : (
      iconElement
    );
  }
);
IconBtn.displayName = 'IconBtn';
export type { IconBtnProps };
export default IconBtn;
