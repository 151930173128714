import type { TypographyProps } from '@material-ui/core';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormFeedback, GridWrapper, SubmitBtn } from 'src/components';
import type { ConfigSensorName, GetStatesOfSensor } from 'src/devices/sensors';
import { getPrimaryConfigSensor, SensorStates } from 'src/devices/sensors';
import { useAppSelector, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';
import type { CreateNotificationArgs } from '../actions';
import { asyncCreateNotification } from '../actions';
import { GLOBAL_DEVICE_ID } from '../devices.reducer';
import formatTrigger from '../formatTrigger';
import type { DeviceIdProp, NotificationTrigger } from '../models';
import { useMetadataByDeviceId } from '../selectors';
import SensorSelect from '../SensorSelect';
import StateSelect from '../StateSelect';
import { getTriggerExistsForDevice } from '../triggers.reducer';

type State<S extends ConfigSensorName = ConfigSensorName> = Pick<
  NotificationTrigger<S>,
  'sourceSensor' | 'sourceSensorStateCurrent' | 'sourceSensorStatePrevious'
> & {
  errorMessage?: string;
};

/**
 * @param props
 * @param props.initialState
 */
function useCreateNotification<S extends ConfigSensorName>({
  initialState
}: {
  initialState: State<S>;
}) {
  const [state, setState] = React.useState<State<S>>(initialState);

  const handleChooseSensor = (sensorName: S) => {
    const [sourceSensorStateCurrent, sourceSensorStatePrevious] =
      SensorStates.getStatesForSensorName(sensorName);
    if (
      !SensorStates.isStateOfSensor(sensorName, sourceSensorStateCurrent) ||
      !SensorStates.isStateOfSensor(sensorName, sourceSensorStatePrevious)
    ) {
      throw new Error('invalid sensor state');
    }
    return setState({
      sourceSensor: sensorName,
      sourceSensorStateCurrent,
      sourceSensorStatePrevious
    });
  };
  React.useEffect(() => {
    if (
      !isNullish(state.sourceSensor) &&
      (isNullish(state.sourceSensorStateCurrent) ||
        isNullish(state.sourceSensorStatePrevious))
    ) {
      const [first, second] = SensorStates.getStatesForSensorName(
        state.sourceSensor
      );

      setState((prev) => ({
        ...prev,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sourceSensorStateCurrent: prev.sourceSensorStateCurrent ?? first,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sourceSensorStatePrevious: prev.sourceSensorStatePrevious ?? second
      }));
    }
  }, [
    state.sourceSensor,
    state.sourceSensorStateCurrent,
    state.sourceSensorStatePrevious
  ]);
  return {
    current: state.sourceSensorStateCurrent,
    handleChooseCurrent: (value: GetStatesOfSensor<S>) =>
      setState((prev) => ({ ...prev, sourceSensorStateCurrent: value })),
    handleChoosePrevious: (value: GetStatesOfSensor<S>) =>
      setState((prev) => ({ ...prev, sourceSensorStatePrevious: value })),
    handleChooseSensor,
    previous: state.sourceSensorStatePrevious,
    sensor: state.sourceSensor,
    setErrorMessage: (errorMessage: string) =>
      setState((prev) => ({ ...prev, errorMessage })),
    ...state
  };
}

/**
 * @param props
 * @param props.variant
 * @param props.rest
 */
const ConnectiveText = React.forwardRef<any, TypographyProps>(
  ({ variant = 'body1', color = 'textSecondary', ...rest }, ref) => {
    return <Typography color={color} ref={ref} variant={variant} {...rest} />;
  }
);
ConnectiveText.displayName = 'ConnectiveText';
/**
 *
 * @param props
 * @param props.deviceId
 * @param props.cancelBtn
 * @param props.onSuccess
 * @returns
 */
export default function CreateNotification({
  deviceId,
  cancelBtn,
  onSuccess
}: DeviceIdProp & {
  cancelBtn: React.ReactNode;
  onSuccess: () => void;
  onCancel: () => void;
}): JSX.Element | null {
  const metadata = useMetadataByDeviceId(deviceId);
  const isGlobal = deviceId === GLOBAL_DEVICE_ID;
  const primarySensorName = useSelector((state) =>
    getPrimaryConfigSensor(state, deviceId)
  );
  const [initialCurrent, initialPrevious] =
    SensorStates.getStatesForSensorName(primarySensorName);

  const {
    handleChooseCurrent,
    handleChoosePrevious,
    handleChooseSensor,
    sensor,
    current,
    previous
  } = useCreateNotification({
    initialState: {
      sourceSensor: primarySensorName,
      sourceSensorStateCurrent: initialCurrent,
      sourceSensorStatePrevious: initialPrevious
    } as State
  });
  const { sendRequest, isPending } = useThunk(asyncCreateNotification);

  const triggerExists = useAppSelector((state) =>
    getTriggerExistsForDevice(state, deviceId, {
      notificationString: '',
      notify: true,
      sourceSensor: sensor,
      sourceSensorStateCurrent: current,
      sourceSensorStatePrevious: previous,
      targetAction: null
    })
  );

  if (isNullish(metadata)) {
    return null;
  }
  let errorMessage: string | undefined = undefined;
  if (current === previous) {
    errorMessage =
      'Notifications can be only be created when a device changes from one state to another';
  } else if (triggerExists) {
    errorMessage = 'This notification has already been created for this device';
  }
  const { deviceId: sourceDeviceId, deviceName } = metadata;
  const handleSubmit = async () => {
    if (
      isNullish(sourceDeviceId) ||
      isNullish(sensor) ||
      isNullish(current) ||
      isNullish(previous)
    ) {
      throw new Error(
        `Incomplete args: ${JSON.stringify({
          current,
          previous,
          sensor,
          sourceDeviceId
        })}`
      );
    }

    const args: Omit<CreateNotificationArgs, 'notificationString'> = {
      notify: true,
      sourceDeviceId,
      sourceSensor: sensor,
      sourceSensorStateCurrent: current,
      sourceSensorStatePrevious: previous,
      targetAction: null,
      targetDeviceId: undefined
    };
    return sendRequest({
      ...args,
      notificationString: formatTrigger(args)
    })
      .then(() => {
        showSuccessToast();
        return onSuccess();
      })
      .catch(handleApiRequestError);
  };

  return (
    <Card
      data-cy={testIds.createNotification.root}
      id={testIds.createNotification.root}
    >
      <CardHeader
        title={`New notification rule for  ${
          isGlobal ? 'all devices' : deviceName
        }`}
      />
      <CardContent>
        <GridWrapper
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <ConnectiveText>{`Notify me when ${
            isGlobal ? 'any' : 'the'
          }`}</ConnectiveText>
          <SensorSelect
            deviceId={deviceId}
            isGlobal={isGlobal}
            onChange={handleChooseSensor}
            value={sensor}
          />
          <ConnectiveText>changes from</ConnectiveText>
          <StateSelect
            isError={Boolean(errorMessage)}
            name="statePrevious"
            onChange={handleChoosePrevious}
            sensorName={sensor}
            value={previous}
          />
          <ConnectiveText>to</ConnectiveText>
          <StateSelect
            isError={Boolean(errorMessage)}
            name="stateCurrent"
            onChange={handleChooseCurrent}
            sensorName={sensor}
            value={current}
          />
          <FormFeedback iconKey="error">{errorMessage}</FormFeedback>
        </GridWrapper>
      </CardContent>
      <CardActions>
        {cancelBtn}
        <SubmitBtn
          disabled={Boolean(errorMessage)}
          id={testIds.createNotification.submitBtn}
          onClick={handleSubmit}
          presetKey="SUBMIT"
          showLoading={isPending}
        />
      </CardActions>
    </Card>
  );
}
