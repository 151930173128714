import {
  createStyles,
  Fab,
  makeStyles,
  useScrollTrigger,
  Zoom
} from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { APP_STYLE } from 'src/AppThemeProvider';
import testIds from 'src/testIds';

const useStyles = makeStyles(({ spacing }) => {
  const { x, y } = APP_STYLE.sidebarToggleSpacing;
  return createStyles({
    cardContent: {},
    cardImage: {},
    cardImageAccordion: { marginTop: spacing(2) },
    cardImages: {},
    cardRoot: {},
    cardTextMain: {},
    scrollToTop: {
      bottom: spacing(y),
      position: `fixed`,
      left: spacing(x)
    }
  });
});

type Props = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  readonly window?: () => Window;
  readonly children?: React.ReactElement;
};

/**
 * @param props
 */
function ScrollToTop(props: Props): JSX.Element {
  const { children } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    // target: window ? window() : undefined,
    threshold: 100
  });

  /**
   * @param event
   */
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const eventTarget = event.target as HTMLDivElement;
    const anchor = eventTarget.ownerDocument.querySelector(
      `#${testIds.appBar.root}`
    );
    anchor &&
      anchor.scrollIntoView({
        behavior: `smooth`,
        block: `center`
      });
  };

  return (
    <Zoom in={trigger}>
      <div
        className={classes.scrollToTop}
        onClick={handleClick}
        role="presentation"
      >
        {children}
      </div>
    </Zoom>
  );
}
/**
 * Floating action button that appears on long pages
 */

/**
 *
 */
export default function ScrollToTopBtn(): JSX.Element {
  return (
    <ScrollToTop>
      <Fab aria-label="scroll back to top" color="secondary">
        <KeyboardArrowUp />
      </Fab>
    </ScrollToTop>
  );
}
