import React from 'react';
import { useForm } from 'react-hook-form';
import { AppTextField } from 'src/components';
import type {
  ConfigSensorName,
  GetConfigKeysBySensorName
} from 'src/devices/sensors';
import Sensors from 'src/devices/sensors';
import useSensorValueConverter from 'src/devices/sensors/useSensorValueConverter';
import { isNullish } from 'src/types';
import type { DeviceIdProp } from '../models';
import { useSubmitEditSensorConfig } from './configManager.reducer';
import FormWrapper from './FormWrapper';

export type FieldProps<
  S extends ConfigSensorName,
  F extends GetConfigKeysBySensorName<S> = GetConfigKeysBySensorName<S>
> = DeviceIdProp & {
  sensorName: S;
  fieldName: F;
  initialValue: number | string | null;
};
/**
 * @param props
 * @param props.fieldName
 * @param props.value
 * @param props.sensorName
 * @param props.initialValue
 */
export function NumericTextField<S extends ConfigSensorName>({
  fieldName,
  initialValue
}: FieldProps<S>): JSX.Element {
  const formatKey = Sensors.useFormatKey();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, setValue } =
    useForm<{ [key: string]: number | string }>();
  const getValidation = Sensors.useFieldValidation('user');
  const convertValue = useSensorValueConverter();
  const { onSubmit } = useSubmitEditSensorConfig();

  React.useEffect(() => {
    if (initialValue !== null) {
      const converted = convertValue(fieldName, initialValue).value;
      setValue(fieldName, converted);
    }
  }, [fieldName, setValue, initialValue, convertValue]);

  return (
    <form
      onSubmit={handleSubmit((value) => {
        const val = value[fieldName];
        if (isNullish(val)) {
          throw new Error('undefined value in handle submit');
        }
        onSubmit(val);
      })}
    >
      <FormWrapper>
        <AppTextField
          errorMessage={errors[fieldName]?.message}
          fullWidth
          id={fieldName}
          inputRef={register(getValidation(fieldName))}
          label={formatKey(fieldName)}
          name={fieldName}
        />
      </FormWrapper>
    </form>
  );
}
