import type { Polygon } from '@turf/helpers';
import { range } from 'lodash';
import { Geo } from 'src/geo';
import { isNullish } from 'src/types';

import drawRectangularProjection from '../drawing/drawRectangularProjection';

import type { SprinklerType } from 'src/devices/sensors';
import type { PointGeoJson } from 'src/geo';
import type { RectangleOf } from '../drawing/drawRectangularProjection';
/**
 * @param args
 * @param args.circleCenter
 * @param args.azimuth
 * @param args.radius
 */
function drawSwathSemicircle({
  azimuth,
  radius,
  circleCenter
}: {
  circleCenter: PointGeoJson;
  azimuth: number;
  radius: number;
}): PointGeoJson[] {
  return range(90, 271, 15).map(
    (increment) =>
      Geo.points.make.projection({
        azimuth: azimuth + increment,
        distanceMm: radius,
        origin: circleCenter
      }).geometry
  );
}

type OutlineArgs = {
  reelToStartAzimuthDegrees: number;
  runDistanceTotalMm: number;
  swathWidthMm: number;
  sprinklerType: SprinklerType;
  reelLocation: PointGeoJson;
};

/**
 * @param args
 * @param args.reelSprinklerType
 * @param args.azimuth
 * @param args.runDistanceTotalMm
 * @param args.swathWidthMm
 * @param args.sprinklerType
 * @param args.reelLocation
 * @param args.reelToStartAzimuthDegrees
 */
function drawSwathOutline(args: OutlineArgs): Polygon {
  const {
    swathWidthMm,
    reelLocation,
    reelToStartAzimuthDegrees,
    runDistanceTotalMm,
    sprinklerType
  } = args;
  const gunStartPoint = Geo.points.make.projection({
    azimuth: reelToStartAzimuthDegrees,
    distanceMm: runDistanceTotalMm,
    origin: reelLocation
  }).geometry;

  const rectangle = drawRectangularProjection({
    azimuth: reelToStartAzimuthDegrees,
    destinationPoint: gunStartPoint,
    originPoint: reelLocation,
    widthMm: swathWidthMm
  });
  const semicircle =
    sprinklerType === `gun`
      ? drawSwathSemicircle({
          azimuth: reelToStartAzimuthDegrees - 180,
          circleCenter: gunStartPoint,
          radius: swathWidthMm / 2
        })
      : null;

  if (!isNullish(semicircle)) {
    // combine the semicircle and the rectangle
    const [first, second, ...rest] = semicircle;
    if (isNullish(first) || isNullish(second)) {
      throw new Error(`Invalid semicircle`);
    }

    return Geo.polygons.to.geojson([
      // rectangle.points.corner1Clockwise,
      rectangle.points.corner1Clockwise,
      rectangle.points.corner2Clockwise,
      first,
      second,
      ...rest,
      rectangle.points.corner4Clockwise
      // rectangle.points.base,
    ]);
  }

  return Geo.polygons.to.geojson([...rectangle.asArray]);
}

type HeatmapArgs = {
  isMostRecent: boolean;
  gunToReelAzimuthDegrees: number;
  swathWidthMm: number;
  sprinklerType: SprinklerType;
  gunStartPosition: PointGeoJson;
  gunEndPosition: PointGeoJson;
  isFirst: boolean;
};

type ReelRunHeatmapGeometry = {
  polygon: Polygon;
  rectangle: RectangleOf<PointGeoJson>;
};

/**
 * @param args
 * @param args.gunEndPosition
 * @param args.gunStartPosition
 * @param args.gunToReelAzimuthDegrees
 * @param args.sprinklerType
 * @param args.isFirst
 * @param args.swathWidthMm
 */
function drawHeatmapPolygon({
  gunEndPosition,
  gunStartPosition,
  gunToReelAzimuthDegrees,
  sprinklerType,
  isFirst,
  swathWidthMm
}: HeatmapArgs): ReelRunHeatmapGeometry {
  // ? Here, we are projecting FROM the gun end point to the start point in drawing
  // ? the rectangle, but the gun is moving in the opposite direction as that
  const rectangle = drawRectangularProjection({
    azimuth: gunToReelAzimuthDegrees,
    destinationPoint: gunStartPosition,
    originPoint: gunEndPosition,
    widthMm: swathWidthMm
  });
  const semicircle =
    sprinklerType === `gun` && isFirst
      ? drawSwathSemicircle({
          azimuth: gunToReelAzimuthDegrees - 180,
          circleCenter: gunStartPosition,
          radius: swathWidthMm / 2
        })
      : null;

  if (!isNullish(semicircle)) {
    // combine the semicircle and the rectangle
    const [first, second, ...rest] = semicircle;
    if (isNullish(first) || isNullish(second)) {
      throw new Error(`Invalid semicircle`);
    }

    const points = [
      rectangle.points.corner1Clockwise,
      rectangle.points.corner2Clockwise,
      first,
      second,
      ...rest,
      rectangle.points.corner3Clockwise,
      rectangle.points.corner4Clockwise,
      rectangle.points.corner1Clockwise
      // rectangle.points.base,
    ] as const;
    if (Geo.polygons.is.validPolygonInput(points)) {
      return {
        polygon: Geo.polygons.to.geojson(points),
        rectangle: rectangle.points
      };
    }
  }

  return {
    polygon: Geo.polygons.to.geojson([...rectangle.asArray]),
    rectangle: rectangle.points
  };
}
export type { ReelRunHeatmapGeometry };

const ReelRunSwath = {
  drawHeatmapPolygon,
  drawSwathOutline,
  drawSwathSemicircle
};
export default ReelRunSwath;
