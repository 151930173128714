import type { AnyPoint, PointGeoJson } from 'src/geo';
import { Geo } from 'src/geo';

const RECTANGLE_POINTS_CLOCKWISE = [
  `base`,
  `corner1Clockwise`,
  `corner2Clockwise`,
  `corner3Clockwise`,
  `corner4Clockwise`,
  `end`
] as const;
type RectangleOf<P extends AnyPoint> = {
  [key in typeof RECTANGLE_POINTS_CLOCKWISE[number]]: P;
};

type Args = {
  originPoint: PointGeoJson;
  destinationPoint: PointGeoJson;
  widthMm: number;
  azimuth: number;
};

/**
 * @param args
 * @param args.startPoint
 * @param args.endPoint
 * @param args.widthMm
 * @param args.azimuth
 * @param args.originPoint
 * @param args.destinationPoint
 */
function drawRectangularProjection({
  originPoint,
  destinationPoint,
  widthMm,
  azimuth
}: Args): {
  points: RectangleOf<PointGeoJson>;
  asArray: [
    PointGeoJson,
    PointGeoJson,
    PointGeoJson,
    PointGeoJson,
    PointGeoJson,
    PointGeoJson,
    PointGeoJson
  ];
} {
  const startGeoJSON = Geo.points.to.geoJson(originPoint);
  const endGeoJSON = Geo.points.to.geoJson(destinationPoint);

  /**
   * @param origin
   * @param degreeOffset
   * @param label
   */
  const getCorner = (origin: AnyPoint, degreeOffset: number, label: string) =>
    Geo.points.make.projection({
      azimuth: azimuth + degreeOffset,
      distanceMm: widthMm / 2,
      label,
      origin
    });

  const corner1Clockwise = getCorner(startGeoJSON, -90, `Corner 1`).geometry;
  const corner2Clockwise = getCorner(endGeoJSON, -90, `Corner 2`).geometry;
  const corner3Clockwise = getCorner(endGeoJSON, 90, `Corner 3`).geometry;
  const corner4Clockwise = getCorner(startGeoJSON, 90, `Corner 4`).geometry;

  const basePoint = Geo.points.to.pointFeature([...startGeoJSON.coordinates], {
    label: `Base`
  }).geometry;
  const endPoint = Geo.points.to.pointFeature([...endGeoJSON.coordinates], {
    label: `End`
  }).geometry;
  const points = {
    base: basePoint,
    corner1Clockwise,
    corner2Clockwise,
    corner3Clockwise,
    corner4Clockwise,
    end: endPoint
  };
  return {
    asArray: [
      basePoint,
      corner1Clockwise,
      corner2Clockwise,
      endPoint,
      corner3Clockwise,
      corner4Clockwise,
      basePoint
    ],
    points
  };
}

export type { RectangleOf };
export default drawRectangularProjection;
