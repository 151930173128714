import { Box, DialogActions, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AppTextField, AppButton, SubmitBtn } from 'src/components';
import { asyncDeleteField, FarmFields } from 'src/fields';
import { useThunk } from 'src/hooks';
import { showErrorToast, showSuccessToast } from 'src/theme';
import { appLogger } from 'src/utilities';

type Props = {
  fieldId: number;
  onCancel: () => void;
};

/**
 * Ensures user wants to permanently delete field by forcing them to type the
 * field name.
 *
 * Once confirmed, send request to permanently delete the field
 *
 * @param props
 * @param props.onCancel
 * @param props.fieldId
 */
function DeleteField({ onCancel, fieldId }: Props): JSX.Element {
  const [value, setFieldName] = React.useState(``);

  const { sendRequest, isPending } = useThunk(asyncDeleteField);
  const fieldName = FarmFields.useField(fieldId)?.fieldName ?? '';

  const handleSubmit = async () =>
    sendRequest({ fieldId })
      .then(() => showSuccessToast())
      .catch((e) => {
        appLogger.error(e);
        showErrorToast();
      });

  return (
    <React.Fragment>
      <Box mb={2}>
        <Alert severity="warning">This action cannot be undone</Alert>
      </Box>
      <Typography>Type the name of the field to confirm:</Typography>
      <Box py={1}>
        <AppTextField
          name="fieldName"
          onChange={(e) => setFieldName(e.target.value)}
          placeholder={fieldName}
          value={value}
        />
      </Box>
      <DialogActions>
        <AppButton onClick={onCancel}>Cancel</AppButton>
        <SubmitBtn
          disabled={isPending || value !== fieldName}
          onClick={handleSubmit}
          variant="contained"
        />
      </DialogActions>
    </React.Fragment>
  );
}

export default DeleteField;
