import { isFalsy } from 'utility-types';
import useIsMobile from './useIsMobile';
export const REPLACE_WORDS = [`click`, `Click`] as const;
export type ReplaceWord = typeof REPLACE_WORDS[number];
export const MOBILE_TEXT: { readonly [key in ReplaceWord]: string } = {
  Click: `Tap`,
  click: `tap`
} as const;

/**
 * Replaces words in text based on screen size
 * @param text
 */
export default function useResponsiveText(text?: string | null): string {
  const isMobile = useIsMobile();
  if (isFalsy(text)) {
    return ``;
  }
  if (isMobile) {
    const splitStr = text.split(` `);
    return splitStr
      .map((word) => {
        if (REPLACE_WORDS.includes(word as ReplaceWord)) {
          return MOBILE_TEXT[word as ReplaceWord];
        }
        return word;
      })
      .join(` `);
  }

  return text;
}
