import type { AvatarProps } from '@material-ui/core';
import React from 'react';
import { isNum } from 'src/types';

import {
  Avatar,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core';

import { useMetadataByDeviceId } from '../selectors';
import mapInstallationTypeToColor from './mapInstallationTypeToColor';

import type { DeviceIdProp } from '../models';
import type { InstallationType } from './InstallationTypes';
type ITypeAvatarProps = AvatarProps &
  DeviceIdProp & {
    index?: number;
    readonly size?: 'lg' | 'md' | 'sm';
  };
const useAvatarStyle = makeStyles(({ palette }) =>
  createStyles({
    root: ({ size }: ITypeAvatarProps & { iType: InstallationType }) => {
      const height = size === `sm` ? 40 : size === `lg` ? 60 : 40;
      return {
        backgroundColor: palette.background.paper,
        borderColor: palette.grey[500],
        borderStyle: `solid`,
        borderWidth: 1,
        maxHeight: height,
        maxWidth: height,
        minHeight: height,
        minWidth: height
      };
    },
    /**
     * Pumps and reels get their own color; everything else is gray
     * @param props
     * @param props.iType
     */
    text: ({ iType }) => ({
      color: mapInstallationTypeToColor({ deviceInstallationType: iType })
    })
  })
);

const InstallationTypeAvatar = React.forwardRef<any, ITypeAvatarProps>(
  ({ index, size, deviceId, ...rest }, ref) => {
    const iType =
      useMetadataByDeviceId(deviceId)?.deviceInstallationType ?? 'unconfigured';
    const styles = useAvatarStyle({
      deviceId,
      iType,
      size
    });
    // const text = abbreviateDeviceInstallationType(iType);
    const text = `${iType.substr(0, 1).toUpperCase()}${
      isNum(index) ? `-${index + 1}` : ''
    }`;
    return (
      <Avatar className={styles.root} data-cy={text} ref={ref} {...rest}>
        <Typography className={styles.text} variant="overline">
          {text}
        </Typography>
      </Avatar>
    );
  }
);
InstallationTypeAvatar.displayName = 'InstallationTypeAvatar';

export default InstallationTypeAvatar;
