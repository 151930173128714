import type React from 'react';
import type { ChecksPermission } from 'src/components';
import useAppSelector from 'src/hooks/useAppSelector';
import { User } from 'src/userSession.reducer';

type HideProps = ChecksPermission & {
  isDev?: boolean;
  activeUserIsAdmin?: boolean;
  userIsAuthenticated?: boolean;
  isDebugEnabled?: boolean;
  isMultiFarmUser?: boolean;
  userHasAFarm?: boolean;
};

/**
 * @param props
 * @param requirements
 * @param isWeb
 */
function useOnlyIf(requirements: HideProps) {
  const adminMode = useAppSelector(
    (state) =>
      state.userSession.isAdmin &&
      state.userSession.preferences.adminMode === true
  );
  const isAdmin = User.useIsAdmin() && adminMode;
  const result = useAppSelector((state) =>
    User.getHasPermissions(state, requirements.requiredPermissions)
  );

  const authStatus = useAppSelector(User.getAuthStatus);
  const farms = useAppSelector(User.getFarms);
  const hasOtherFarms = farms.length >= 2;

  return () => {
    if (requirements.userHasAFarm === true && farms.length === 0) {
      return false;
    }
    if (requirements.activeUserIsAdmin === true && (!isAdmin || !adminMode)) {
      return false;
    }
    if (
      requirements.userIsAuthenticated === true &&
      authStatus !== `fulfilled`
    ) {
      return false;
    }
    if (requirements.isMultiFarmUser === true && hasOtherFarms) {
      return false;
    }
    if (requirements.isDev === true && process.env.NODE_ENV === 'development') {
      return false;
    }
    if (result.code === 'DENIED') {
      return false;
    }
    return true;
  };
}

/**
 * @param props
 */
const ShowOnlyIf: React.FC<HideProps> = (props) => {
  const getVisibility = useOnlyIf(props)();

  return getVisibility ? (props.children as JSX.Element) : null;
};
export default ShowOnlyIf;
