import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import SearchField from 'src/components/search/SearchField';

type Props = {
  value: string;
  onChange(val: string): void;
  classes: {
    searchContainer: string;
  };
};

/**
 * Contains search field overlaid on map
 * @param props
 * @param props.classes
 * @param props.classes.searchContainer
 * @param props.value
 * @param props.onChange
 */
export default function SearchBar({
  classes,
  value,
  onChange
}: Props): JSX.Element {
  return (
    <Card className={classes.searchContainer}>
      <CardContent>
        <Typography variant="body2">
          Enter a device name, type, or field name
        </Typography>
        <SearchField onCommitValue={onChange} value={value} />
      </CardContent>
    </Card>
  );
}
