import React from 'react';

import { Typography } from '@material-ui/core';

import ErrorPageWrapper from './ErrorPageWrapper';

export default function Page404(): JSX.Element {
  return (
    <ErrorPageWrapper title="404">
      <Typography data-cy="page-404" id="page-404">
        The page you are looking for does not exist.
      </Typography>
    </ErrorPageWrapper>
  );
}
