import { isNumber } from 'lodash-es';
import React from 'react';
import { AppButton } from 'src/components';
import type { PressureThresholds } from 'src/devices/sensors';
import {
  PressureThresholdForm,
  usePressureThresholdForm
} from 'src/devices/sensors';
import { isNullish } from 'src/types';
import type { DeviceIdProp } from '../models';
import { useSensorConfigByDeviceId } from '../selectors';
import {
  useConfigMangerActions,
  useSubmitEditSensorConfig
} from './configManager.reducer';
import FormWrapper from './FormWrapper';

/**
 * @param props
 * @param props.deviceId
 */
function EditPressureThresholds({ deviceId }: DeviceIdProp): JSX.Element {
  const { cancel: handleCancel } = useConfigMangerActions();
  const pressure = useSensorConfigByDeviceId('pressure', deviceId);
  const { onSubmit } = useSubmitEditSensorConfig();

  if (isNullish(pressure)) {
    throw new Error('Null pressure sensor');
  }
  const { rawValues, handleChange } = usePressureThresholdForm(pressure);

  const handleChangeCommitted = (newValues: PressureThresholds): void => {
    let key: keyof PressureThresholds;
    let value: number;
    const currentValues = { ...pressure };
    if (currentValues.thresholdPsiLower !== newValues.thresholdPsiLower) {
      key = 'thresholdPsiLower';
      value = newValues.thresholdPsiLower;
    } else {
      key = 'thresholdPsiUpper';
      value = newValues.thresholdPsiUpper;
    }

    if (isNumber(value)) {
      onSubmit(value, key);
    }
  };

  return (
    <FormWrapper
      submitBtn={
        <AppButton
          fullWidth
          iconKey="CHECK_MARK"
          id="done-btn"
          onClick={handleCancel}
          text="Done"
          variant="contained"
        />
      }
      testId="edit-thresholds-form"
    >
      <PressureThresholdForm
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        valueLowerPsi={rawValues.thresholdPsiLower}
        valueUpperPsi={rawValues.thresholdPsiUpper}
      />
    </FormWrapper>
  );
}
export default EditPressureThresholds;
