import { createCachedSelector } from 're-reselect';
import InstallationTypes from 'src/devices/installation-type';
import { isNullish } from 'src/types';

import RemoteControlKeys from './RemoteControlKeys';

import type { RootState } from 'src/root.reducer';
import type { DeviceMetadata } from '../models';
import type {
  RemoteControlFunctionName,
  RemoteControlKey
} from './RemoteControlKeys';
interface RemoteControlSpec {
  functionName: RemoteControlFunctionName;
  label: string;
  confirmationRequired?: boolean;
  description?: string[] | string;
  warningText?: string[] | string;
  adminOnly?: boolean;
  options?: {
    extraParameters?: {
      seconds?: number;
    };
  };
}

type RemoteControlOptions = {
  [K in RemoteControlKey]?: RemoteControlSpec;
};

/**
 * @param config
 * @param config.deviceName
 * @param config.deviceInstallationType
 */
function generateRemoteControlsForDevice({
  deviceName,
  deviceInstallationType
}: Pick<
  DeviceMetadata,
  'deviceInstallationType' | 'deviceName'
>): RemoteControlOptions {
  const makeRelayAction = (targetSetting: 'off' | 'on'): RemoteControlSpec => ({
    confirmationRequired: true,
    description: `This will set the switch on the ${InstallationTypes.humanizeInstallationType(
      deviceInstallationType
    )} to ${targetSetting.toUpperCase()}`,
    functionName: 'RLYD',
    label: `switch ${targetSetting}`,
    warningText: [
      RemoteControlKeys.WARNINGS.circuitDisclaimer,
      RemoteControlKeys.WARNINGS.dataLoss,
      RemoteControlKeys.WARNINGS.noCancellation
    ]
  });

  const isPump = deviceInstallationType === 'pump';
  return {
    ACTIVATE_RELAY: isPump ? makeRelayAction('on') : undefined,
    DEACTIVATE_RELAY: isPump ? makeRelayAction('off') : undefined,
    DOWNLOAD_CONFIG: {
      confirmationRequired: true,
      description: `This will cause ${deviceName} to immediately 
      attempt to download any changes you have made to its configuration. `,
      functionName: 'reconfigure_device',
      label: 'sync config',
      warningText: [
        RemoteControlKeys.WARNINGS.dataLoss,
        RemoteControlKeys.WARNINGS.noCancellation
      ]
    },
    FORCE_UPDATE: {
      adminOnly: false,
      confirmationRequired: false,
      description: 'Request a status update from the device',
      functionName: 'send_device_event',
      label: 'Force Update'
    },
    REBOOT_CHIP: {
      adminOnly: false,
      confirmationRequired: true,
      description: `This will reboot the device's chip.`,
      functionName: 'reset_device',
      label: `reboot chip`,
      warningText: [
        RemoteControlKeys.WARNINGS.dataLoss,
        RemoteControlKeys.WARNINGS.noCancellation
      ]
    },
    SHUT_DOWN: isPump
      ? {
          adminOnly: false,
          confirmationRequired: true,
          description: [
            'This will engage the shutdown routine specified for the pump:',
            'TOGGLE THE RELAY FOR 10 SECONDS'
          ],
          functionName: 'RLYAS',
          label: 'shutdown',
          options: {
            extraParameters: { seconds: 10 }
          },
          warningText: [
            RemoteControlKeys.WARNINGS.circuitDisclaimer,
            RemoteControlKeys.WARNINGS.dataLoss,
            RemoteControlKeys.WARNINGS.noCancellation
          ]
        }
      : undefined,
    TEST_CONNECTION: isPump
      ? undefined
      : {
          adminOnly: false,
          description:
            'This will ping the device to ensure it is powered on and has cell reception',
          functionName: 'RLYAS',
          label: 'ping',
          options: {
            extraParameters: { seconds: 3 }
          }
        }
  };
}
const getRemoteControlsOptions = createCachedSelector(
  (state: RootState, id: string) => {
    return state.devices.entities[id];
  },
  (config) => {
    if (!isNullish(config)) {
      const { deviceName, deviceInstallationType } = config;
      return generateRemoteControlsForDevice({
        deviceInstallationType,
        deviceName
      });
    }
    return undefined;
  }
)((_state, deviceId) => deviceId);
const getRemoteControlSpec = createCachedSelector(
  getRemoteControlsOptions,
  (_state: RootState, _id: string, commandKey: RemoteControlKey) => commandKey,
  (controls, commandKey) => {
    if (controls) {
      return controls[commandKey];
    }
    return undefined;
  }
)(
  (_state, deviceId, commandKey: RemoteControlKey) =>
    `${deviceId}/${commandKey}`
);
export type { RemoteControlSpec, RemoteControlOptions };
export { getRemoteControlSpec, getRemoteControlsOptions };
