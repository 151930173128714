import type { TypographyProps } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsDarkMode } from 'src/appSelectors';
import type { HasIconKey, HideableProps } from 'src/components/types';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { isTruthy, notNullish } from 'src/types';

type Props = HasIconKey & HideableProps & TypographyProps;
const STYLE: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};
/**
 */
const FormFeedback = React.forwardRef<any, Props>((props, ref) => {
  const {
    iconKey,
    align = `center`,
    isHidden: hide,
    variant = `body1`,
    children,
    ...rest
  } = props;
  const isDark = useSelector(getIsDarkMode);
  const id = rest.id ?? testIds.formError;
  return !isTruthy(children) || hide === true ? null : (
    <Typography
      align={align}
      color={isDark ? 'primary' : 'error'}
      data-cy={id}
      id={id}
      ref={ref}
      style={STYLE}
      variant={variant}
      {...rest}
    >
      {notNullish(iconKey) ? <AppIcon iconKey={iconKey} /> : null}
      {children}
    </Typography>
  );
});

FormFeedback.displayName = 'FormFeedback';
export default FormFeedback;
