import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { pick } from 'lodash-es';
import React from 'react';
import { IconBtn } from 'src/components';
import { NoListItems } from 'src/components/lists';
import testIds from 'src/testIds';
import formatTrigger from '../formatTrigger';
import type {
  DeviceIdProp,
  NotificationTrigger,
  TriggerPropertyKey
} from '../models';
import { TRIGGER_PROPERTY_KEYS } from '../models';
import { useTriggersForDevice } from '../triggers.reducer';

/**
 * @param index
 */
function makeId(index: number) {
  return `notification-list-item-${index}`;
}

const REQUIRED_PERMISSION = ['canManageDeviceNotifications'] as const;
const Ids = testIds.manageNotifications;
/**
 * @param props
 * @param props.notificationString
 * @param props.onClickDelete
 * @param props.onClickRename
 * @param props.rest
 */
function NotificationRow({
  notificationString,
  onClickDelete,
  onClickRename,
  ...rest
}: Pick<NotificationTrigger, TriggerPropertyKey> & {
  id: number;
  onClickRename: () => void;
  onClickDelete: () => void;
}) {
  const formatted = React.useMemo(
    () =>
      formatTrigger(rest, {
        formatStyle: 'shortHand'
      }).replace('send a notification', ''),
    [rest]
  );
  return (
    <Grid alignItems="center" container>
      <React.Fragment>
        <Grid item xs={12}>
          <ListItemText
            primary={notificationString ?? formatTrigger(rest)}
            secondary={formatted}
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={10}>
          <IconBtn
            iconKey="EDIT_ITEM"
            id={Ids.editNotificationStringBtn}
            onClick={onClickRename}
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={2}>
          <IconBtn
            id={Ids.deleteNotificationBtn}
            onClick={onClickDelete}
            requiredPermissions={REQUIRED_PERMISSION}
          >
            <DeleteForever />
          </IconBtn>
        </Grid>
      </React.Fragment>
    </Grid>
  );
}

type Props = DeviceIdProp & {
  onClickRename: (id: number) => void;
  onClickDelete: (id: number) => void;
};

const LIST_ID = Ids.notificationsList;
const NO_NOTIFICATIONS_ID = Ids.noNotifications;
/**
 * @param props
 * @param props.deviceId
 * @param props.onClickDelete
 * @param props.onClickRename
 * @param props.onClickCreate
 */
export default function ActiveNotificationsList({
  deviceId,
  onClickDelete,
  onClickRename
}: Props): JSX.Element {
  const deviceNotifications = useTriggersForDevice(deviceId, 'NOTIFICATION');
  if (deviceNotifications.length === 0) {
    return (
      <NoListItems
        id={NO_NOTIFICATIONS_ID}
        title="No notification rules exist for this device"
      />
    );
  }
  return (
    <List data-cy={LIST_ID} dense disablePadding id={LIST_ID}>
      <ListSubheader disableSticky>Send an alert...</ListSubheader>
      {deviceNotifications.map((n, index) => {
        const testId = makeId(index);
        const handleClickRename = () => onClickRename(n.id);
        const handleClickDelete = () => onClickDelete(n.id);

        return (
          <ListItem
            data-cy={testId}
            disableGutters
            divider
            id={testId}
            key={n.id}
          >
            <NotificationRow
              id={n.id}
              {...pick(n, TRIGGER_PROPERTY_KEYS)}
              onClickDelete={handleClickDelete}
              onClickRename={handleClickRename}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
