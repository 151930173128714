import Arrow from './RowDirectionArrow';
import Slider from './RowDirectionSlider';
import useSetDirection from './useSetDirection';

const RowDirection = {
  Arrow,
  Slider
};
export default RowDirection;
export { useSetDirection };
