/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AvatarProps, ListItemAvatarProps } from '@material-ui/core';
import { Avatar, ListItemAvatar } from '@material-ui/core';
import React from 'react';

type Props = Omit<ListItemAvatarProps, 'children'> &
  Pick<AvatarProps, 'id' | 'variant'> & {
    readonly indexId: number;
    // readonly size?: React.CSSProperties['height'];
    // readonly avatarClass?: string;
  };

const ListIndexAvatar = React.forwardRef<any, Props>((props, ref) => {
  return (
    <ListItemAvatar ref={ref}>
      <Avatar variant={props.variant}>{props.indexId + 1}</Avatar>
    </ListItemAvatar>
  );
});
ListIndexAvatar.displayName = 'ListIndexAvatar';
export default ListIndexAvatar;
