import React from 'react';
import { AppTextField, SubmitBtn } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { getSelectedDeviceMetadata } from '../selectors';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 * The user can give the device a custom nickname. By default,
 * we use the coda device alias
 * @param props
 * @param props.children
 */
export default function DeviceNameForm({
  children
}: React.PropsWithChildren<unknown>): JSX.Element {
  const initialValue = useAppSelector(getSelectedDeviceMetadata)?.deviceName;
  const [deviceName, setDeviceName] = React.useState(initialValue);
  const dispatch = useAppDispatch();

  return (
    <FormWrapper
      instructions="Customize the device name (optional)"
      submitBtn={
        <SubmitBtn
          onClick={() => dispatch(CreateConfig.submitDeviceName(deviceName))}
        />
      }
      testId={testIds.createConfiguration.deviceName}
    >
      <AppTextField
        id="deviceName"
        name="deviceName"
        onChange={(e) => setDeviceName(e.target.value)}
        required
        value={deviceName ?? ''}
      />
      {children}
    </FormWrapper>
  );
}
