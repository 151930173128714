import { useTheme } from '@material-ui/core';
import type { MarkerProps } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import React from 'react';
import type { HideableProps } from 'src/components/types';

export type MarkerBaseProps = HideableProps &
  Pick<
    MarkerProps,
    | 'animation'
    | 'clickable'
    | 'clusterer'
    | 'cursor'
    | 'draggable'
    | 'icon'
    | 'noClustererRedraw'
    | 'onClick'
    | 'onDrag'
    | 'onLoad'
    | 'onVisibleChanged'
    | 'opacity'
    | 'position'
    | 'title'
  > &
  Pick<google.maps.MarkerOptions, 'opacity' | 'optimized'> & {
    readonly label?: google.maps.MarkerLabel;
    readonly labelText?: string;
  };

/**
 * @param props
 * @param props.position
 * @param props.label
 * @param props.labelText
 * @param props.title
 * @param props.animation
 * @param props.clickable
 * @param props.clusterer
 * @param props.cursor
 * @param props.draggable
 * @param props.icon
 * @param props.noClustererRedraw
 * @param props.onClick
 * @param props.onDrag
 * @param props.onLoad
 * @param props.onVisibleChanged
 * @param props.opacity
 * @param props.optimized
 * @param props.children
 * @param props.rest
 * @param props.isHidden
 */
export default function MarkerBase({
  position,
  label,
  labelText,
  title,
  animation,
  clickable,
  clusterer,
  cursor,
  draggable,
  icon,
  noClustererRedraw,
  onClick,
  onDrag,
  onLoad,
  onVisibleChanged,
  opacity,
  optimized,
  isHidden
}: // ...rest
MarkerBaseProps): JSX.Element {
  const { palette } = useTheme();
  const labelMemo = React.useMemo(
    () => ({
      color: palette.common.white,
      text: labelText ?? '',
      ...label
    }),
    [label, labelText, palette.common.white]
  );

  const optionsMemo = React.useMemo(
    () => ({
      opacity,
      optimized
    }),
    [opacity, optimized]
  );

  return (
    <Marker
      animation={animation}
      clickable={clickable}
      clusterer={clusterer}
      cursor={cursor}
      draggable={draggable}
      icon={icon}
      label={labelMemo}
      noClustererRedraw={noClustererRedraw}
      onClick={onClick}
      onDrag={onDrag}
      onLoad={onLoad}
      onVisibleChanged={onVisibleChanged}
      opacity={opacity}
      options={optionsMemo}
      position={position}
      title={title}
      visible={!(isHidden === true)}
    />
  );
}
