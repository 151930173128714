import { isNumber } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { isNullish } from 'src/types';

import { getEventByDeviceId } from './selectors';

import type { RootSelector } from 'src/store';
import type { GenericSensorEvent } from './sensors';

/**
 * @param args
 * @param args.runDistanceMmCurrent
 * @param args.runDistanceMmMax
 */
export default function calculateRunCompletionPct({
  runDistanceMmCurrent,
  runDistanceMmMax
}: Pick<GenericSensorEvent, 'runDistanceMmCurrent' | 'runDistanceMmMax'>):
  | number
  | null {
  if (
    isNullish(runDistanceMmCurrent) ||
    isNullish(runDistanceMmMax) ||
    runDistanceMmMax == 0
  ) {
    return null;
  }
  const elapsed = runDistanceMmMax - runDistanceMmCurrent;
  const resultAsDecimal = elapsed / runDistanceMmMax;
  const pct = resultAsDecimal * 100;

  if (isNumber(pct)) {
    if (pct > 99) {
      return 100;
    }
    if (pct < 0) {
      return 0;
    }
    return pct;
  }
  return null;
}

export const getRunCompletionPctByEventId: RootSelector<
  number | null,
  [deviceId: string]
> = createCachedSelector(getEventByDeviceId, (event) => {
  if (event && event.reel) {
    return calculateRunCompletionPct(event.reel);
  }
  return null;
})((_, id) => id);
