import { CircularProgress, Modal } from '@material-ui/core';
import React from 'react';
import { useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { getActiveLoader } from 'src/userSession.reducer';

const testId = testIds.loaders.modal;
const style: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
/**
 * Loader that blocks user from taking actions while leaving page content visible
 *
 *
 */
export default function ModalLoader(): JSX.Element | null {
  const activeLoader = useAppSelector(getActiveLoader);
  return activeLoader === 'modal' ? (
    <Modal keepMounted open>
      <div data-cy={testId} id={testId} style={style}>
        <CircularProgress
          color="secondary"
          size={100}
          thickness={6}
          variant="indeterminate"
        />
      </div>
    </Modal>
  ) : null;
}
