import { useTheme } from '@material-ui/core';
import type { AppLogoIconKey } from 'src/theme/AppLogoIcon';
import LeafDark from '../assets/images/logo/dark/Leaf Icon.png';
import InlineLockupDark from '../assets/images/logo/dark/Long Lockup.png';
import StackedLockupLockupDark from '../assets/images/logo/dark/Stacked Lockup.png';
import LeafLight from '../assets/images/logo/light/Leaf Icon.png';
import InlineLockupLight from '../assets/images/logo/light/Long Lockup.png';
import StackedLockupLockupLight from '../assets/images/logo/light/Stacked Lockup.png';

const logos = {
  dark: {
    Leaf: LeafDark,
    InlineLockup: InlineLockupDark,
    StackedLockup: StackedLockupLockupDark
  },
  light: {
    Leaf: LeafLight,
    InlineLockup: InlineLockupLight,
    StackedLockup: StackedLockupLockupLight
  }
} as const;
/**
 * @param variant
 */
export default function useLogo(variant: AppLogoIconKey): string {
  const themeType = useTheme().palette.type;
  return logos[themeType][variant];
}
