import formatSensorKey from './formatSensorKey';
import type { SensorFormattingOptions } from './formatSensorValue';
import type { AnySensorKey } from './getConversionUnits';
import useDefaultSensorFormatArgs from './useDefaultSensorFormatArgs';

export type FormatKeyResult = {
  readonly label: string;
  readonly adminOnly: boolean;
};

/**
 * Convenience method that provides default arguments to formatSensorKey
 * @param options
 */
export default function useFormatSensorKey<K extends AnySensorKey>(
  options?: SensorFormattingOptions
): (key: K) => string | null {
  const defaultArgs = useDefaultSensorFormatArgs();
  return (key: K) =>
    formatSensorKey({
      key,
      options: { ...defaultArgs, ...options },
      ...defaultArgs
    });
}
