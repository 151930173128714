import type { LatLng } from 'src/geo';

const ZOOM_THRESHOLDS = {
  breakpoints: {
    gunMarker: 14
  },
  fieldLabel: 0.1,
  mapDefault: 13
} as const;

export const DEFAULT_CENTER: LatLng = {
  lat: 49,
  lng: -123
};

export default ZOOM_THRESHOLDS;
