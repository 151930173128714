import { isString } from 'lodash-es';
import React from 'react';
import { toast } from 'react-toastify';
import Actions from 'src/actions';
import { useIsAnyRequestPending } from 'src/appSelectors';
import { AppButton, GridWrapper, Warning } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';

import {
  Box,
  Checkbox,
  createStyles,
  Divider,
  makeStyles,
  Toolbar,
  Typography
} from '@material-ui/core';

import { useMetadataByDeviceId } from '../selectors';
import { asyncInvokeRemoteControl } from './asyncInvokeRemoteControl';
import { getRemoteControlSpec } from './RemoteControlSpec';

import type { DeviceIdProp } from '../models';
import type { RemoteControlKey } from './RemoteControlKeys';
const useStyles = makeStyles(() => {
  return createStyles({
    descriptionText: {},
    root: {},
    warningBox: {}
  });
});

/**
 * @param props
 * @param props.deviceId
 * @param props.commandKey
 */
export default function RemoteControlConfirmation({
  deviceId,
  commandKey
}: DeviceIdProp & {
  commandKey: RemoteControlKey;
}): JSX.Element | null {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const metadata = useMetadataByDeviceId(deviceId);
  const deviceName = metadata?.deviceName;
  // const installationType = metadata?.deviceInstallationType;
  const isPending = useIsAnyRequestPending();
  const [checked, setChecked] = React.useState(false);

  const command = useAppSelector((state) => {
    if (isNullish(commandKey) || isNullish(deviceId)) {
      return null;
    }
    return getRemoteControlSpec(state, deviceId, commandKey);
  });
  if (isNullish(commandKey)) {
    return null;
  }
  if (isNullish(command)) {
    throw new Error('No command for device and key');
  }

  const description = isString(command.description)
    ? [command.description]
    : Array.isArray(command.description)
    ? command.description
    : [];

  const warningText = isString(command.warningText)
    ? [command.warningText]
    : Array.isArray(command.warningText)
    ? command.warningText
    : [];

  const handleCancel = () => dispatch(Actions.closeDialog());
  const handleToggleCheckbox = () => setChecked(!checked);
  const handleSubmit = () =>
    dispatch(
      asyncInvokeRemoteControl({
        commandKey,
        deviceId
      })
    )
      .then(() => {
        setChecked(false);

        toast.success(`Request sent to ${deviceName ?? 'the device'} `);
      })
      .catch(handleApiRequestError);
  const { confirmationRoot } = testIds.remoteControls;
  const confirmationText = `I acknowledge that I am responsible for the outcome of this action`;
  return (
    <div data-cy={confirmationRoot} id={confirmationRoot}>
      <Typography className={classes.descriptionText} variant="body2">
        {description}
      </Typography>
      <Warning icon={false}>
        {warningText.map((t) => (
          <Typography className={classes.warningBox} key={t} variant="body2">
            {t}
          </Typography>
        ))}
        <Divider />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          my={1}
        >
          <Typography variant="body2">{confirmationText}</Typography>
          <Checkbox
            checked={checked}
            color="primary"
            data-cy={testIds.remoteControls.confirmationCheckbox}
            id={testIds.remoteControls.confirmationCheckbox}
            name="confirmation-checkbox"
            onChange={handleToggleCheckbox}
          />
        </Box>
      </Warning>
      <Box my={1}>
        <GridWrapper justifyContent="space-between">
          <AppButton
            disabled={isPending}
            onClick={handleCancel}
            presetKey="CANCEL"
          />
          <AppButton
            disabled={isPending || !checked}
            iconKey="REMOTE_CONTROLS"
            id={testIds.remoteControls.submitBtn}
            onClick={handleSubmit}
            presetKey="SUBMIT"
            text="send request"
          />
        </GridWrapper>
      </Box>
      <Toolbar />
    </div>
  );
}
