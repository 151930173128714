import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import asyncUserJoinFarm from 'src/account-settings/asyncUserJoinFarm';
import {
  AppButton,
  AppTextField,
  Attention,
  FormFeedback,
  GridWrapper,
  SubmitBtn
} from 'src/components';
import { INVALID_CODE_MESSAGE } from 'src/constants';
import PATHS from 'src/constants/Paths';
import useThunk from 'src/hooks/useThunk';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      // alignItems: 'center',
      flexDirection: 'column'
    }
  });
});

interface JoinWithCodeProps {
  readonly isFirstFarm: boolean;
}

type JoinFarmState = {
  farmName: string | null;
  activeFarmId: number | null;
  codeString: string;
  errorMessage: string | null;
};
const JOIN_FARM_INITIAL_STATE: JoinFarmState = {
  activeFarmId: null,
  codeString: ``,
  errorMessage: null,
  farmName: null
};

/**
 * New user enters an existing farm's join code to link their account to the farm
 * @param props
 * @param props.isFirstFarm
 */
function JoinFarmWithCode({ isFirstFarm }: JoinWithCodeProps): JSX.Element {
  const classes = useStyles();
  const [{ farmName, codeString, errorMessage }, setState] =
    React.useState<JoinFarmState>({
      ...JOIN_FARM_INITIAL_STATE
    });
  const { sendRequest, isPending } = useThunk(asyncUserJoinFarm);

  const farmStatus = useSelector((state) => state.userSession.farmStatus);
  const success = !isNullish(farmStatus);

  const instructions =
    farmStatus === 'USER_CREATED_FARM' && farmName !== null
      ? `Your account is now linked to ${farmName}`
      : `Enter your farm's join code to connect your account.`;
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void =>
    setState(
      (prev): JoinFarmState => ({
        ...prev,
        codeString: e.target.value.toUpperCase(),
        errorMessage: null
      })
    );
  const handleSubmit = async () =>
    sendRequest({ codeString }).then((res) => {
      if (res.codeStatus === 'INVALID') {
        setState((prev) => ({
          ...prev,
          errorMessage: INVALID_CODE_MESSAGE
        }));
      } else if (res.codeStatus === 'EXPIRED') {
        setState((prev) => ({
          ...prev,
          errorMessage: 'The code you entered is expired.'
        }));
      } else {
        toast.info(`Success.`);
      }
    });
  const Ids = testIds.joinFarmWithCode;
  return (
    <div className={classes.root} data-cy={Ids.root} id={Ids.root}>
      {!success ? (
        <React.Fragment>
          <Typography id={Ids.instructions}>{instructions}</Typography>
          <AppTextField
            disabled={isPending}
            label="Enter Code"
            name="codeString"
            onChange={handleChange}
            size="medium"
            value={codeString}
          />
          <Box display="flex" justifyContent="center" my={2}>
            <SubmitBtn
              disabled={codeString.length !== 6}
              id={Ids.submitBtn}
              onClick={handleSubmit}
              size="large"
            />
          </Box>
          <FormFeedback>{errorMessage}</FormFeedback>
          {isFirstFarm ? (
            <Box
              display="flex"
              flex={1}
              justifyContent="center"
              my={2}
              width="100%"
            >
              <Attention
                icon={false}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <GridWrapper alignItems="center" direction="column">
                  <Typography variant="body2">
                    Are you the first user at your farm?
                  </Typography>
                  <AppButton
                    id={testIds.createFarm.linkTo}
                    linkTo={PATHS.noFarms.newFarm}
                    text="Create A Farm Account"
                  />
                </GridWrapper>
              </Attention>
            </Box>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default JoinFarmWithCode;
