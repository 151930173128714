import React from 'react';
import { SensorConfigs, SensorNames } from 'src/devices/sensors';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import ChoiceBtn from './ChoiceBtn';
import ChoicesWrapper from './ChoicesWrapper';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

type Props = React.PropsWithChildren<unknown>;

/**
 * @param props
 * @param props.onSubmit
 * @param props.sensorName
 * @param props.children
 * @param props.backBtn
 */
export default function SensorPortForm({ children }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const sensorName = useAppSelector(CreateConfig.getPrimarySensor);

  return (
    <FormWrapper
      instructions={`Where is the ${SensorNames.humanize(
        sensorName
      ).toLowerCase()} sensor installed?`}
      testId="sensor-port-form"
    >
      <ChoicesWrapper>
        {SensorConfigs.SENSOR_PORTS.map((sensorPort) => (
          <ChoiceBtn
            id={`${sensorPort}`}
            key={sensorPort}
            onClick={() =>
              dispatch(
                CreateConfig.submitSensorPort({ sensorName, sensorPort })
              )
            }
            text={`Port ${sensorPort}`}
          />
        ))}
      </ChoicesWrapper>
      {children}
    </FormWrapper>
  );
}
