import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { AppTextField } from 'src/components';
import type { AppTextFieldProps } from 'src/components/forms/AppTextField';
import { isTruthyString } from 'src/types';
import { User } from 'src/userSession.reducer';
import type { AnySensorKey } from './getConversionUnits';
import getConversionUnits from './getConversionUnits';
import useFormatSensorKey from './useSensorKeyFormatter';

/**
 * Automatically formats sensor field labels and adds
 * unit labels to sensor values where appropriate
 * @param props
 * @param props.label
 * @param props.name
 * @param props.rest
 * @param props.hideLabel
 */
export default function SensorTextField({
  label,
  name,
  hideLabel,
  ...rest
}: AppTextFieldProps<AnySensorKey> & {
  readonly hideLabel?: boolean;
}): JSX.Element | null {
  const isMetric = User.useIsMetric();
  const formattedLabel = useFormatSensorKey()(name);
  const unitLabel = getConversionUnits({ isMetric, key: name, target: 'user' })
    ?.labels.short;

  const inputProps = isTruthyString(unitLabel)
    ? {
        endAdornment: (
          <InputAdornment position="end">{unitLabel}</InputAdornment>
        )
      }
    : undefined;
  return formattedLabel === null ? null : (
    <AppTextField
      InputProps={inputProps}
      label={hideLabel === true ? undefined : label ?? formattedLabel}
      name={name}
      {...rest}
    />
  );
}
