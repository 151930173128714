import type { SvgIconProps } from '@material-ui/core';
import { SvgIcon, useTheme } from '@material-ui/core';
import React from 'react';
import type { ThemeType } from 'src/farm';
import { ReactComponent as InlineLockupDark } from '../assets/svg/bg-black/SVG/inlineLockup.svg';
import { ReactComponent as LeafDark } from '../assets/svg/bg-black/SVG/leaf.svg';
import { ReactComponent as StackedLockupLockupDark } from '../assets/svg/bg-black/SVG/stackedLockup.svg';
import { ReactComponent as InlineLockupLight } from '../assets/svg/bg-white/SVG/inlineLockup.svg';
import { ReactComponent as LeafLight } from '../assets/svg/bg-white/SVG/leaf.svg';
import { ReactComponent as StackedLockupLockupLight } from '../assets/svg/bg-white/SVG/stackedLockup.svg';

const IconComponents = {
  dark: {
    Leaf: LeafDark,
    InlineLockup: InlineLockupDark,
    StackedLockup: StackedLockupLockupDark
  },
  light: {
    Leaf: LeafLight,
    InlineLockup: InlineLockupLight,
    StackedLockup: StackedLockupLockupLight
  }
} as const;
export type AppLogoIconKey = keyof typeof IconComponents[ThemeType];

type Props = SvgIconProps & {
  variant: AppLogoIconKey;
};

/**
 * Dynamically imports the correct logo base on key and theme type
 * @param props
 * @param props.variant
 * @param props.rest
 */
const Base = React.forwardRef<any, Props>(
  ({ variant, ...rest }, ref): JSX.Element => {
    const themeType = useTheme().palette.type;
    const Component = IconComponents[themeType][variant];
    return (
      <SvgIcon ref={ref} {...rest}>
        <Component />
      </SvgIcon>
    );
  }
);
Base.displayName = 'LogoIcon';

/**
 * Just a leaf
 * @param props
 */
const Leaf = React.forwardRef<any, SvgIconProps>((props, ref): JSX.Element => {
  return <Base ref={ref} {...props} variant="Leaf" />;
});
Leaf.displayName = 'LeafIcon';
/**
 * Leaf positioned to the left of Farm HQ
 * @param props
 */
const InlineLockup = React.forwardRef<any, SvgIconProps>(
  (props, ref): JSX.Element => {
    return <Base ref={ref} {...props} variant="InlineLockup" />;
  }
);
InlineLockup.displayName = 'InlineLockupIcon';

/**
 * Leaf stacked on top of Farm HQ
 * @param props
 */
const StackedLockup = React.forwardRef<any, SvgIconProps>(
  (props, ref): JSX.Element => {
    return <Base ref={ref} {...props} variant="StackedLockup" />;
  }
);
StackedLockup.displayName = 'StackedLockupIcon';

const AppLogoIcon = {
  Leaf,
  InlineLockup,
  StackedLockup
} as const;

export default AppLogoIcon;
