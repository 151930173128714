import type { Theme } from '@material-ui/core';
import React from 'react';
import { useActiveFeatureState, useClearActiveFeature } from 'src/actions';
import { useMenuState } from 'src/components/menu';
import Automations from 'src/devices/automations';
import CreateConfiguration from 'src/devices/create-configuration';
import InspectRecentEvents from 'src/devices/inspect-recent-events';
import DevicePairing from 'src/devices/pairing/DevicePairing';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { isNullish, notNullish } from 'src/types';

import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery
} from '@material-ui/core';

import { AppButton } from '../components';
import { ConfigManager, DeviceOwnership } from '../devices';
import NotificationManager from '../devices/notifications';
import { useMetadataByDeviceId } from '../devices/selectors';
import DeviceProfileOptionsMenu, {
  DEVICE_ACTION_NAMES
} from './DeviceProfileOptionsMenu';
import InstallationTypes from './installation-type';

import type { ActiveFeature } from 'src/actions';
import type { ValuesType } from 'utility-types';
import type { DeviceIdProp, DeviceMetadata } from '../devices';
const DEVICE_PROFILE_ACTIONS = [
  DEVICE_ACTION_NAMES.AUTOMATIONS,
  DEVICE_ACTION_NAMES.CREATE_CONFIGURATION,
  DEVICE_ACTION_NAMES.NOTIFICATIONS,
  DEVICE_ACTION_NAMES.MANAGE_CONFIGURATION,
  DEVICE_ACTION_NAMES.INSPECT_RECENT_EVENTS,
  DEVICE_ACTION_NAMES.PAIRING,
  DEVICE_ACTION_NAMES.SHARE_DEVICE
] as const;
export type DeviceProfileActionName = ValuesType<typeof DEVICE_PROFILE_ACTIONS>;

/**
 * @param x
 */
function isDeviceProfileActionName(
  x: ActiveFeature | null
): x is DeviceProfileActionName {
  return (
    notNullish(x) &&
    DEVICE_PROFILE_ACTIONS.includes(x as DeviceProfileActionName)
  );
}
type ContentProps = DeviceMetadata & {
  activeFeature: DeviceProfileActionName;
};

/**
 * @param props
 * @param props.currentAction
 * @param props.metadata
 * @param props.onSuccess
 * @param props.activeFeature
 */
const Content = React.memo<ContentProps>(({ activeFeature, ...metadata }) => {
  switch (activeFeature as DeviceProfileActionName | null) {
    case 'AUTOMATIONS':
      return <Automations {...metadata} />;
    case 'CREATE_CONFIGURATION':
      return <CreateConfiguration />;
    case 'DEVICE_NOTIFICATIONS':
      return <NotificationManager {...metadata} />;
    case 'SHARE_DEVICE':
      return <DeviceOwnership {...metadata} />;
    case 'MANAGE_CONFIGURATION':
      return <ConfigManager {...metadata} />;
    case 'INSPECT_RECENT_EVENTS':
      return <InspectRecentEvents {...metadata} />;
    case 'PAIRING': {
      return <DevicePairing {...metadata} />;
    }
    case null: {
      return null;
    }
    default: {
      throw new Error(activeFeature as string);
    }
  }
});
Content.displayName = 'Content';
const Ids = testIds.deviceProfile;

export { DEVICE_PROFILE_ACTIONS, isDeviceProfileActionName };
/**
 * @param props
 * @param props.deviceId
 * @param props.onClose
 * @param props.rest
 */
export default function DeviceProfileDialog({
  deviceId
}: DeviceIdProp): JSX.Element | null {
  const metadata = useMetadataByDeviceId(deviceId);
  const { menuAnchor, menuOpen, handleCloseMenu, handleOpenMenu } =
    useMenuState();
  const handleExit = useClearActiveFeature();
  const { activeFeature } = useActiveFeatureState();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  if (isNullish(metadata) || !isDeviceProfileActionName(activeFeature)) {
    return null;
  }
  const { deviceName, deviceInstallationType } = metadata;

  const rootId = Ids.root;
  return (
    <Dialog
      data-cy={rootId}
      fullScreen={fullScreen}
      id={rootId}
      onClose={(_e, r) => {
        if (r !== 'backdropClick') {
          handleExit();
        }
      }}
      open={isDeviceProfileActionName(activeFeature) && notNullish(deviceId)}
    >
      <DeviceProfileOptionsMenu
        anchorEl={menuAnchor}
        deviceId={deviceId}
        onClose={handleCloseMenu}
        open={menuOpen}
      />
      <CardHeader
        action={
          <AppButton
            iconKey="EXPAND_MORE"
            id={Ids.openActionsMenuBtn}
            onClick={handleOpenMenu}
            text="options"
            variant="outlined"
          />
        }
        avatar={<AppIcon iconKey={deviceInstallationType ?? 'unconfigured'} />}
        subheader={InstallationTypes.humanize(deviceInstallationType)}
        title={deviceName}
      />
      <DialogContent dividers>
        <Content activeFeature={activeFeature} {...metadata} />
      </DialogContent>
      <DialogActions>
        <AppButton id={Ids.closeBtn} onClick={handleExit} text="close" />
      </DialogActions>
    </Dialog>
  );
}
