/* eslint-disable jsdoc/require-jsdoc */

import { isNumber } from 'lodash-es';
import { isArrayOrReadonlyArray, isPlainObj } from 'src/types';
import { appLogger } from 'src/utilities';
import type { AnyBounds } from './types';
import { isBbox } from './types';
type GmapsLatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;

/**
 * Type guard for google.maps.latLngBoundsLiteral objects
 * @param value any value
 * @returns {boolean} if value has north, south, east, and west properties and all are numbers
 */
function isGmaps(value: unknown): value is GmapsLatLngBoundsLiteral {
  if (isPlainObj(value)) {
    const asBounds = value as unknown as GmapsLatLngBoundsLiteral;
    const points = [
      asBounds.north,
      asBounds.south,
      asBounds.east,
      asBounds.west
    ];
    return isArrayOrReadonlyArray(points) && points.every(isNumber);
  }
  return false;
}

function toGmaps(bounds: AnyBounds): GmapsLatLngBoundsLiteral {
  if (isGmaps(bounds)) {
    return { ...bounds };
  }

  const [west, south, east, north] = bounds;

  return new google.maps.LatLngBounds(
    {
      lat: south,
      lng: west
    },
    {
      lat: north,
      lng: east
    }
  ).toJSON();
}

/**
 * @param value
 */
function isAnyBounds(value: unknown): value is AnyBounds {
  return isGmaps(value) || isBbox(value);
}
/**
 * Convert bbox string from database to usable bounds for map.
 * Order is minLng, minLat, maxLng, maxLat
 * @example
 * // returns  {west:1, south: 2, east: 3, north: 4}
 * parseBboxString("BOX(1,2,3,4)")
 * @param bboxString The bbox as a string
 * @returns {GmapsLatLngBoundsLiteral | null} The bbox as a google maps bounds literal
 */
function parseBboxString(bboxString: string): GmapsLatLngBoundsLiteral | null {
  // e.g. "BOX(1,2,3,4)"
  try {
    const noBox = bboxString
      .replace('BOX(', '')
      .replace(')', '')
      .split(',')
      .flatMap((ptStr) => ptStr.split(' '))
      .map(parseFloat);

    if (!noBox.every((n) => typeof n === 'number')) {
      throw new Error(JSON.stringify(noBox));
    }

    const [minX, minY, maxX, maxY] = noBox;
    if (isNumber(minX) && isNumber(minY) && isNumber(maxX) && isNumber(maxY)) {
      return {
        east: maxX,
        north: maxY,
        south: minY,
        west: minX
      };
    }
    appLogger.error('parse bbox failed');
    return null;
  } catch (err) {
    appLogger.error(err);
    return null;
  }
}
const bounds = {
  isAnyBounds,
  isValid: {
    bbox: isBbox,
    latLngBoundsLiteral: isGmaps
  },
  parseBboxString,
  to: {
    gmaps: toGmaps
  }
};
export default bounds;
export type { GmapsLatLngBoundsLiteral };
