/* eslint-disable @typescript-eslint/no-use-before-define */
import destination from '@turf/destination';
import type { Units } from '@turf/helpers';
import { distance } from '@turf/turf';
import { isNullish } from 'src/types';
import type {
  AnyPoint,
  Coordinates,
  LatLng,
  PointFeature,
  PointGeoJson,
  PointProperties
} from './types';
import {
  isAnyPoint,
  isGmapsInstance,
  isGmapsLiteral,
  isPointFeature,
  isPointGeoJSON,
  isValidCoordinates
} from './types';

const USA_CENTER: PointGeoJson = {
  coordinates: [-95.712891, 37.09024],
  type: `Point`
};

const CENTER_OF_USA_MULTIPLATFORM = {
  geoJSON: USA_CENTER,
  gmaps: {
    lat: USA_CENTER.coordinates[1],
    lng: USA_CENTER.coordinates[0]
  },
  native: {
    latitude: USA_CENTER.coordinates[1],
    longitude: USA_CENTER.coordinates[0]
  },
  type: `PointMultiplatform`
} as const;
/**
 * Preserve immutability
 */
const getCenterOfUSA = () => {
  return {
    ...CENTER_OF_USA_MULTIPLATFORM
  };
};

/**
 * Convert any point-like feature to a coordinate array ([lng, lat])
 * @param val
 */
function toCoordinates(val: AnyPoint): Coordinates {
  if (isValidCoordinates(val)) {
    return val;
  }
  if (isPointGeoJSON(val)) {
    return toCoordinates([...val.coordinates]);
  }

  if (isGmapsLiteral(val)) {
    return toCoordinates([val.lng, val.lat]);
  }
  if (isGmapsInstance(val)) {
    return toCoordinates({
      ...val.toJSON()
    });
  }

  if (isPointFeature(val)) {
    return toCoordinates([...val.geometry.coordinates]);
  }

  return toCoordinates(val);
}

/**
 * Convert any point-like feature to a google maps lat lng literal value
 * @param val
 */
function toGoogleMaps(val: AnyPoint): LatLng {
  if (isGmapsLiteral(val)) {
    return val;
  }
  if (isPointGeoJSON(val)) {
    const [lng, lat] = val.coordinates;
    if (isNullish(lng) || isNullish(lat)) {
      throw new Error('undefined coordinates');
    }
    return toGoogleMaps({
      lat,
      lng
    });
  }
  const asGeoJSON = toGeoJson(val);
  return toGoogleMaps(asGeoJSON);
}

/**
 * Convert any point-like value to a GeoJSON point geometry
 * @param val
 */
const toGeoJson = (val: AnyPoint): PointGeoJson => {
  if (isPointGeoJSON(val)) {
    return val;
  }

  if (isValidCoordinates(val)) {
    return { coordinates: [...val], type: `Point` };
  }

  if (isGmapsLiteral(val)) {
    return toGeoJson([val.lng, val.lat]);
  }

  if (isPointFeature(val)) {
    return toGeoJson(val.geometry);
  }
  if (isGmapsInstance(val)) {
    const { lng, lat } = val.toJSON();
    return toGeoJson([lng, lat]);
  }

  throw new Error(`Invalid coordinates received`);
};

/**
 * @param val
 * @param props
 */
function toPointFeature(val: AnyPoint, props?: PointProperties): PointFeature {
  if (isValidCoordinates(val)) {
    const [lng, lat] = val;
    if (isNullish(lng) || isNullish(lat)) {
      throw new Error('undefined coordinates');
    }
    return {
      geometry: {
        coordinates: [lng, lat],
        type: `Point`
      },
      properties: props ?? null,
      type: `Feature`
    };
  }
  return toPointFeature(toCoordinates(val), props);
}

/**
 * Compare lat and lng of gmaps
 * @param a
 * @param b
 */
function gmapsEquals(
  a: google.maps.LatLngLiteral | null | undefined,
  b: google.maps.LatLngLiteral | null | undefined
) {
  if (a && b) {
    if (a.lat !== b.lat) {
      return false;
    }
    if (a.lng !== b.lng) {
      return false;
    }
  }
  return true;
}

/**
 * Plot new point at given distance and direction from origin
 * @param args
 * @param args.origin
 * @param args.distanceMm
 * @param args.azimuth
 * @param args.label
 */
function makeProjection(args: {
  origin: AnyPoint;
  distanceMm: number;
  azimuth: number;
  label?: string;
}): PointFeature {
  const { origin } = args;

  const result = destination(
    [...toCoordinates(toGeoJson(origin))],
    args.distanceMm / 1000,
    args.azimuth,
    { properties: { label: args.label }, units: `meters` }
  );
  if (!isPointFeature(result)) {
    throw new Error(`Projection failed`);
  }
  const [lng, lat] = result.geometry.coordinates;

  if (isNullish(lng) || isNullish(lat)) {
    throw new Error('Undefined coordinates');
  }
  const coords = [lng, lat] as const;
  return toPointFeature([...coords], result.properties);
}

/**
 * @param from
 * @param to
 * @param units
 */
function getDistance(from: AnyPoint, to: AnyPoint, units: Units = `meters`) {
  return distance(
    [...toGeoJson(from).coordinates],
    [...toGeoJson(to).coordinates],
    {
      units
    }
  );
}
const GeoPoints = {
  constants: {
    getCenterOfUSA
  },
  equality: {
    gmapsEquals
  },
  get: {
    distance: getDistance,
    label: (val: AnyPoint, backup = `Location`) => {
      if (isPointFeature(val)) {
        return val.properties?.label ?? backup;
      }
      return backup;
    }
  },
  is: {
    any: isAnyPoint,
    geoJson: isPointGeoJSON,
    gmapsLiteral: isGmapsLiteral,
    isGmapsInstance,

    pointCoordinates: isValidCoordinates,
    pointFeature: isPointFeature
  },
  make: {
    projection: makeProjection
  },
  to: {
    coordinates: toCoordinates,
    geoJson: toGeoJson,
    googleMaps: toGoogleMaps,
    pointFeature: toPointFeature
  }
} as const;
export default GeoPoints;
