import React from 'react';
import { connect } from 'react-redux';
import testIds from 'src/testIds';
import { isTruthyString } from 'src/types';

import {
    Box, createStyles, Grid, List, ListItem, ListSubheader, makeStyles, Toolbar
} from '@material-ui/core';

import Actions from '../actions';
import { getSelectedDeviceId } from '../appSelectors';
import { AppButton, AppFab } from '../components';
import NoListItems from '../components/lists/NoListItems';
import { getAllDeviceIds } from '../devices/selectors';
import { FarmFields, getSelectedFieldId } from '../fields';
import { useAppDispatch } from '../hooks';
import { humanize } from '../utilities';
import DeviceListRow from './DeviceListRow';
import FieldListRow from './FieldListRow';

import type { ConnectedProps } from 'react-redux';
import type { RequiredPermissions } from 'src/permissions';
type MapSidebarListProps<T extends number | string> = React.PropsWithChildren<{
  addItemRequiredPermissions?: RequiredPermissions;
  selectedId: T | null;
  searchBar?: React.ReactNode;
  numItems: number;
  name: string;
  onClickNew: () => void;
}>;

const useStyles = makeStyles(() => {
  return createStyles({
    list: {
      flex: 1,
      overflowY: 'scroll'
    }
  });
});

/**
 * @param props
 * @param props.name
 * @param props.ids
 * @param props.selectedId
 * @param props.onClickNew
 * @param props.ItemComponent
 * @param props.addItemRequiredPermissions
 * @param props.children
 * @param props.numItems
 * @param props.searchBar
 */
function MapSidebarList<T extends number | string>({
  name,
  onClickNew,
  children,
  numItems,
  addItemRequiredPermissions,
  searchBar
}: MapSidebarListProps<T>) {
  const classes = useStyles();

  const testId = `${name.toLowerCase()}-list-root`;
  const addActionId = `add-${name}-btn`;
  return (
    <List className={classes.list} data-cy={testId} id={testId}>
      <ListSubheader>
        {searchBar}
        <AppButton
          color="default"
          fullWidth
          iconKey="ADD_ITEM"
          id={addActionId}
          isHidden={numItems === 0}
          onClick={onClickNew}
          requiredPermissions={addItemRequiredPermissions}
          text={`New ${name}`}
        />
      </ListSubheader>
      {numItems === 0 ? (
        <NoListItems title={`Your farm account has no ${name}s`}>
          <Box py={1}>
            <AppFab
              iconKey="ADD_ITEM"
              id={addActionId}
              onClick={onClickNew}
              requiredPermissions={addItemRequiredPermissions}
            >
              {`New ${name}`}
            </AppFab>
          </Box>
        </NoListItems>
      ) : (
        children
      )}
      <ListItem>
        <Toolbar />
      </ListItem>
    </List>
  );
}

const LIST_VIEWS = ['DEVICES', 'FIELDS'] as const;
type ListView = typeof LIST_VIEWS[number];

const connector = connect((state, ownProps: { searchValue: string }) => ({
  ...ownProps,
  deviceIds: getAllDeviceIds(state),
  fields: FarmFields.selectAll(state),
  selectedDeviceId: getSelectedDeviceId(state),
  selectedFieldId: getSelectedFieldId(state)
}));

type Props = ConnectedProps<typeof connector>;

const StatusMapSidebar = React.memo<Props>((props) => {
  const dispatch = useAppDispatch();
  const { deviceIds, fields, selectedDeviceId, selectedFieldId, searchValue } =
    props;
  const [listView, setListView] = React.useState<ListView>('DEVICES');
  const handleClickCreateField = () =>
    dispatch(Actions.setActiveFeature({ name: 'CREATE_FIELD' }));

  const handleClickAddDevice = () =>
    dispatch(Actions.setActiveFeature({ name: 'ADD_DEVICE' }));

  return (
    <div
      data-cy={testIds.statusMapSidebar.root}
      id={testIds.statusMapSidebar.root}
    >
      <Box my={1} width="100%">
        <Grid
          alignItems="center"
          component={ListSubheader}
          container
          justifyContent="space-between"
        >
          {LIST_VIEWS.map((v) => {
            const selected = v === listView;
            const onChange = () => setListView(v);
            return (
              <Grid color="primary" item key={v} xs={6}>
                <AppButton
                  fullWidth
                  id={`${v.toLocaleLowerCase()}-list-toggle-btn`}
                  onClick={onChange}
                  text={humanize(v)}
                  variant={selected ? 'contained' : 'text'}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {listView === 'DEVICES' ? (
        <MapSidebarList
          addItemRequiredPermissions="canManageDevicePermissions"
          name="device"
          numItems={deviceIds.length}
          onClickNew={handleClickAddDevice}
          selectedId={selectedDeviceId}
        >
          {deviceIds.map((deviceId) => {
            return (
              <DeviceListRow
                
                id={deviceId}
                isSelected={deviceId === selectedDeviceId}
                key={deviceId}
                searchValue={searchValue}
              />
            );
          })}
        </MapSidebarList>
      ) : (
        <MapSidebarList
          addItemRequiredPermissions="canManageFields"
          name="field"
          numItems={fields.length}
          onClickNew={handleClickCreateField}
          selectedId={selectedFieldId}
        >
          {fields.map((f, i) =>
            isTruthyString(searchValue) &&
            !f.fieldName.toLocaleLowerCase().includes(searchValue) ? null : (
              <FieldListRow
                fieldName={f.fieldName}
                id={f.id}
                index={i}
                isActive={f.isActive}
                key={f.id}
                polygon={f.polygon}
              />
            )
          )}
        </MapSidebarList>
      )}
    </div>
  );
});
StatusMapSidebar.displayName = 'StatusMapSidebar';
export default connector(StatusMapSidebar);
