import React from 'react';
import { useForm } from 'react-hook-form';
import { AppButton } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';

import useMagnetSelect from '../forms/useMagnetSelect';
import useSprinklerSelect from '../forms/useSprinklerSelect';
import { REEL_CONFIG_KEYS_BY_SECTION } from '../installation-type/ReelConfigKeysBySection';
import Sensors, { SensorTextField } from '../sensors';
import useSensorConfigConverter from '../sensors/useSensorConfigConverter';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

import type { SensorConfig, SensorPort } from '../sensors';
type FormData = {
  [K in keyof Omit<
    SensorConfig<'reel'>,
    'nMagnets' | 'sensorName' | 'sensorPort' | 'sprinklerType'
  >]: number;
};
/**
 *
 */
function ReelForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const currentStage = useAppSelector(CreateConfig.getCurrentStage);
  if (
    !(
      currentStage === 'HOSE' ||
      currentStage === 'NOZZLE' ||
      currentStage === 'SPOOL'
    )
  ) {
    throw new Error('Invalid stage');
  }
  const keys = REEL_CONFIG_KEYS_BY_SECTION[currentStage];

  const form = useForm<FormData>();

  const currentData = useAppSelector((state) => CreateConfig.getReel(state));
  const sensorPort: SensorPort = currentData?.sensorPort ?? 1;
  const convertReel = useSensorConfigConverter('reel', 'database');
  const getValidation = Sensors.useFieldValidation();
  const { MagnetSelect, nMagnets } = useMagnetSelect({
    isActive: currentStage === 'SPOOL',
    sensorName: 'reel'
  });
  const { SprinklerSelect, sprinklerType } = useSprinklerSelect(
    currentStage === 'NOZZLE'
  );

  const onSubmit = form.handleSubmit((values) => {
    const data = {
      ...values,
      nMagnets,
      sensorName: 'reel',
      sensorPort,
      sprinklerType
    } as const;
    const convertedReel = convertReel(data, ...keys);
    dispatch(CreateConfig.submitReelData(convertedReel));
  });
  return (
    <form onSubmit={onSubmit}>
      <FormWrapper
        instructions={`Enter ${currentStage.toLowerCase()} calibrations`}
        submitBtn={<AppButton presetKey="SUBMIT" />}
        testId="reel-form"
      >
        {keys.map((key) =>
          key === 'sprinklerType' || key === 'nMagnets' ? null : (
            <SensorTextField
              errorMessage={form.errors[key]?.message}
              id={key}
              inputRef={form.register(getValidation(key))}
              key={key}
              name={key}
            />
          )
        )}
        <MagnetSelect />
        <SprinklerSelect />
      </FormWrapper>
    </form>
  );
}

export default ReelForm;
