import { Box } from '@material-ui/core';
import React from 'react';
import { asyncChangeDeviceName } from 'src/devices/actions';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import type { DeviceNameProp } from '.';
import { AppTextField, CancelBtn, GridWrapper, SubmitBtn } from '../components';
import type { DeviceIdProp } from './models';

/**
 * @param props
 * @param props.value
 * @param props.onChange
 * @param props.onReset
 * @param props.deviceId
 * @param props.deviceName
 */
export function RenameDevice({
  onReset,
  deviceId,
  deviceName
}: DeviceIdProp &
  DeviceNameProp & {
    onReset: () => void;
  }): JSX.Element {
  const [value, setValue] = React.useState(deviceName);
  const { sendRequest, isPending } = useThunk(asyncChangeDeviceName);
  const handleSubmitRename = () =>
    sendRequest({
      deviceId,
      deviceName: value
    })
      .then(() => {
        showSuccessToast();
        onReset();
      })
      .catch(handleApiRequestError);

  return (
    <div>
      <AppTextField
        id={testIds.deviceActions.deviceName}
        name="deviceName"
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <Box pb={1} pt={1}>
        <GridWrapper gridItemProps={{ xs: 6 }} spacing={1}>
          <CancelBtn
            disabled={isPending}
            fullWidth
            id={testIds.cancelBtn}
            onClick={onReset}
            size="small"
          />
          <SubmitBtn
            fullWidth
            onClick={handleSubmitRename}
            showLoading={isPending}
            size="small"
          />
        </GridWrapper>
      </Box>
    </div>
  );
}
