import type { AsyncThunk } from '@reduxjs/toolkit';
import { isString } from 'lodash-es';
import makeApiRequest from 'src/requests/makeApiRequest';
import { isNullish } from 'src/types';

import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RequestName } from 'src/constants';
import type {
  FarmUserPermissionKey,
  RequiredPermissions
} from 'src/permissions';
import type { RootState } from 'src/root.reducer';
import type { RootThunkApi } from 'src/store';
import type { PlainObject } from 'src/types';
type Options<A extends PlainObject | null, R> = {
  onError?: {
    showToast?: string;
  } | null;
  checkPermissions?: RequiredPermissions;
  successToast?: string | ((t: { arg: A; res: R; state: RootState }) => string);
  staticArgs?: PlainObject | undefined;
  routeNameOverride?: string;
  transformResponse?: (t: { arg: A; res: R; state: RootState }) => R;
  transformRequest?: (t: { arg: A; state: RootState }) => A;
  getDemoData?: (arg: A, res: R, state: RootState) => Promise<R> | R;
};

/**
 * @param requestName
 * @param options
 */
export default function createAsyncAppThunk<
  A extends PlainObject | null = null,
  R = unknown
>(
  requestName: RequestName,
  options?: Options<A, R>
): AsyncThunk<R, A, RootThunkApi> {
  return createAsyncThunk<R, A, RootThunkApi>(
    requestName,
    async (arg, thunkApi) => {
      const state = thunkApi.getState();

      const {
        checkPermissions,
        transformRequest,
        staticArgs,
        routeNameOverride,
        getDemoData,
        transformResponse
      } = options ?? {};

      if (!isNullish(checkPermissions)) {
        const userPermissions = state.userSession.permissions;
        const { isAdmin } = state.userSession;
        const missing = [] as FarmUserPermissionKey[];
        if (!isAdmin) {
          const checkArray = isString(checkPermissions)
            ? [checkPermissions]
            : checkPermissions;
          const permCheck = checkArray.reduce((acc, next) => {
            if (userPermissions[next]) {
              return acc;
            }
            return [...acc, next];
          }, missing);
          if (permCheck.length) {
            return thunkApi.rejectWithValue({
              code: 'DENIED'
            });
          }
        }
      }

      return makeApiRequest<R, A>(routeNameOverride ?? requestName, {
        ...staticArgs,
        ...(transformRequest ? transformRequest({ arg, state }) : arg)
      }).then((response) => {
        if (transformResponse) {
          return transformResponse({
            arg,
            res: response,
            state
          });
        }

        if (getDemoData) {
          return getDemoData(arg, response, state);
        }
        return response;
      });
    },
    {
      condition: (_arg, { getState }) => {
        const fetchStatus =
          getState().userSession.requests.entities[requestName]?.fetchStatus;
        if (fetchStatus === 'pending') {
          return false;
        }
        return true;
      }
    }
  );
}
