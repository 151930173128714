import { Grid } from '@material-ui/core';
import React from 'react';

/**
 * @param props
 * @param props.children
 */
export default function ChoicesWrapper({
  children
}: React.PropsWithChildren<unknown>): JSX.Element {
  return (
    <Grid
      container
      data-cy="choices"
      justifyContent="space-between"
      spacing={2}
    >
      {React.Children.map(children, (child) => (
        <Grid item xs>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}
