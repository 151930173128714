import type { Mark } from '@material-ui/core';
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Slider,
  Typography
} from '@material-ui/core';
import { isNumber } from 'lodash';
import React from 'react';
import type {
  AnySensorKey,
  PressureThresholds,
  RawSensorValue
} from 'src/devices/sensors';
import testIds from 'src/testIds';
import type { PartialNullable } from 'src/types';
import { isNullish } from 'src/types';
import { CONFIG_DEFAULTS } from '../SensorConfig';
import useFormatSensorValue from '../useFormatSensorValue';

/**
 * @param props
 * @param props.thresholdPsiLower
 * @param props.thresholdPsiUpper
 */
function usePressureMarks({
  thresholdPsiLower,
  thresholdPsiUpper
}: PressureThresholds): Mark[] {
  const formatValue = useFormatSensorValue();

  return React.useMemo(
    (): Mark[] =>
      [0, thresholdPsiLower, thresholdPsiUpper, CONFIG_DEFAULTS.maxPsi].map(
        (value) => ({
          label: formatValue('thresholdPsiLower', value),
          value
        })
      ),
    [formatValue, thresholdPsiLower, thresholdPsiUpper]
  );
}

type FormState = PressureThresholds & {
  readonly isError?: false;
};

/**
 * @param initialValue
 * @param fallbacks
 * @param fallbacks.psiLower
 * @param fallbacks.psiUpper
 * @param fallbacks.thresholdPsiLower
 * @param fallbacks.thresholdPsiUpper
 */
function getInitialState(
  initialValue: PartialNullable<PressureThresholds> | null | undefined,
  fallbacks: { readonly thresholdPsiLower: 60; readonly thresholdPsiUpper: 140 }
): FormState | (() => FormState) {
  return {
    thresholdPsiLower:
      initialValue?.thresholdPsiLower ?? fallbacks.thresholdPsiLower,
    thresholdPsiUpper:
      initialValue?.thresholdPsiUpper ?? fallbacks.thresholdPsiUpper
  };
}

/**
 * Logic for slider form allowing user to input pressure thresholds
 *
 * @param onSubmit - callback function taking the form values
 * @param initialValue - used if editing an existing configuration
 */
function usePressureThresholdForm(
  initialValue?: PartialNullable<PressureThresholds> | null
): {
  convertToUserPref: (
    key: AnySensorKey,
    rawValue: RawSensorValue
  ) => string | null;
  handleChange: ([psiLower, thresholdPsiUpper]: number[]) => void;
  makeSliderMark: (value: number) => { label: string | null; value: number };
  markers: Array<{ label: string | null; value: number }>;
  maxValToUserPref: string | null;
  rawValues: FormState;
} {
  const defaultValues = CONFIG_DEFAULTS.pressure;
  const [state, setState] = React.useState<FormState>(
    getInitialState(initialValue, defaultValues)
  );

  const formatValue = useFormatSensorValue();

  const makeSliderMark = (value: number) => {
    return { label: formatValue('thresholdPsiLower', value), value };
  };

  return {
    convertToUserPref: formatValue,
    handleChange: ([thresholdPsiLower, thresholdPsiUpper]: number[]) => {
      if (isNullish(thresholdPsiUpper) || isNullish(thresholdPsiLower)) {
        throw new Error(`Null value for pressure`);
      }
      setState((prevState) => {
        return {
          ...prevState,
          thresholdPsiLower,
          thresholdPsiUpper
        };
      });
    },
    makeSliderMark,
    markers: [
      defaultValues.thresholdPsiLower,
      defaultValues.thresholdPsiUpper,
      CONFIG_DEFAULTS.maxPsi
    ].map(makeSliderMark),
    maxValToUserPref: formatValue('thresholdPsiUpper', CONFIG_DEFAULTS.maxPsi),
    rawValues: state
  };
}

const useStyles = makeStyles(({ typography, palette }) =>
  createStyles({
    sliderMark: {},
    sliderMarkLabel: {},
    thresholdDisplay: {
      fontSize: typography.fontSize * 1.1
    },
    thresholdRail: {
      // height: 20,
    },
    thresholdTrack: {
      // height: 20,
    },
    thresholdValue: {
      fontWeight: typography.fontWeightBold
    },
    thumb: {},
    thumbLabel: {
      fontSize: typography.pxToRem(typography.fontSize * 0.95),
      fontWeight: typography.fontWeightBold,
      textAlign: 'center',
      textShadow: `1px 1px 1px ${palette.text.secondary}`
    }
  })
);
type ThresholdDisplayProps = {
  readonly rawValue: number | null | undefined;
  readonly kind: 'lower' | 'upper';
};

const testId = testIds.pressureThresholdForm.root;

/**
 * @param props
 * @param props.value
 * @param props.kind
 * @param props.rawValue
 */
function ThresholdDisplay({ rawValue, kind }: ThresholdDisplayProps) {
  const format = useFormatSensorValue();
  const converted = format('thresholdPsiUpper', rawValue ?? 0);

  return !isNullish(converted) ? (
    <Typography data-cy={`${kind}-display`}>
      {`Consider anything ${
        kind === 'lower' ? 'under' : 'over'
      } ${converted} too ${kind === 'lower' ? 'low' : 'high'} `}
    </Typography>
  ) : null;
}

/**
 * @param index
 */
function makeAriaLabel(index: number) {
  return `slider-control-${index + 1}`;
}

interface FormProps {
  valueUpperPsi: number;
  valueLowerPsi: number;
  onChange: (values: number[]) => void;
  onChangeCommitted?: (values: PressureThresholds) => void;
}

export { usePressureMarks, usePressureThresholdForm };
/**
 * @param props

 * @param props.valueLowerPsi
 * @param props.valueUpperPsi
 * @param props.onChange
 * @param props.onChangeCommitted
 */
export default function PressureThresholdForm({
  valueLowerPsi,
  valueUpperPsi,
  onChange,
  onChangeCommitted
}: FormProps): JSX.Element {
  const classes = useStyles();
  const formatValue = useFormatSensorValue();
  const markers = usePressureMarks({ ...CONFIG_DEFAULTS.pressure });

  const sliderClasses = {
    mark: classes.sliderMark,
    markLabel: classes.sliderMarkLabel,
    thumb: classes.thumb,
    valueLabel: classes.thumbLabel
  };

  /**
   * @param value
   */
  const valueLabelFormat = React.useCallback(
    (value: number): string => {
      if (!isNumber(value)) {
        return `None`;
      }
      return formatValue('thresholdPsiUpper', value) ?? '';
    },
    [formatValue]
  );

  const handleChange = React.useCallback(
    (_: unknown, val: unknown) => onChange(val as number[]),
    [onChange]
  );

  const handleChangeCommitted = React.useCallback(
    (_: unknown, values: number[] | number): void => {
      const [thresholdPsiLower, thresholdPsiUpper] = values as number[];
      if (isNullish(thresholdPsiUpper) || isNullish(thresholdPsiLower)) {
        throw new Error(`Null value for pressure`);
      }
      return (
        onChangeCommitted &&
        onChangeCommitted({
          thresholdPsiLower,
          thresholdPsiUpper
        })
      );
    },
    [onChangeCommitted]
  );
  const valuesMemo = React.useMemo(() => {
    return [valueLowerPsi, valueUpperPsi];
  }, [valueLowerPsi, valueUpperPsi]);
  return (
    <Box data-cy={testId} id={testId} width="100%">
      <Box mb={4}>
        <ThresholdDisplay kind="lower" rawValue={valueLowerPsi} />
        <Divider orientation="horizontal" />
        <ThresholdDisplay kind="upper" rawValue={valueUpperPsi} />
      </Box>
      <Box pt={1} px={4}>
        <Slider
          aria-labelledby="thumb"
          classes={sliderClasses}
          getAriaLabel={makeAriaLabel}
          marks={markers}
          max={CONFIG_DEFAULTS.maxPsi}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={valuesMemo}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Box>
    </Box>
  );
}
