import { createAsyncAppThunk } from 'src/requests';

import { RequestNames } from '../constants';

const {
  SET_DEBUG_MODE,
  TOGGLE_METRIC,
  ACCEPT_TERMS_OF_SERVICE,
  CHANGE_FIELD_COLOR_PREFERENCE
} = RequestNames;
export const asyncChangeGlobalFieldColor = createAsyncAppThunk<{
  fillColor: string;
}>(CHANGE_FIELD_COLOR_PREFERENCE);
export const asyncAcceptTermsOfService = createAsyncAppThunk(
  ACCEPT_TERMS_OF_SERVICE
);
export const asyncSetDebugMode =
  createAsyncAppThunk<{ value: boolean }>(SET_DEBUG_MODE);
export const asyncToggleMetric = createAsyncAppThunk<{
  usesMetricSystem: boolean;
}>(TOGGLE_METRIC, {
  successToast: ({ state }) =>
    `Now using ${
      state.userSession.preferences.usesMetricSystem ? `metric` : `US`
    } measurements`
});
