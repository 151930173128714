export default {
  accountSettings: {
    activeFarm: 'active-farm',
    associatedFarms: {
      list: 'farms-list',
      root: 'associated-farms'
    },
    farmPermissions: 'farm-permissions',
    metricToggleBtn: 'metric-toggle-btn',
    root: 'account-settings-root',
    switchFarmsBtn: 'switch-farms-btn',
    userSettings: {
      preferences: 'user-preferences',
      root: 'user-settings-root'
    },
    viewPermissionsBtn: 'view-permissions-btn'
  },
  activeFarmMarker: 'active-farm-marker',
  addDevice: {
    addMoreBtn: 'add-more-devices-btn',
    aliasInput: 'codaDeviceAlias',
    initBtn: 'add-device-btn',
    inputMode: {
      alias: 'alias',
      shareCode: 'share-code'
    },
    root: 'add-device-root',
    shareCodeInput: 'deviceShareCode',
    submitBtn: 'submit-add-device-btn',
    toggleInputMode: 'toggle-share-code-input'
  },
  alertsToggleDenied: 'alerts-toggle-denied',
  alertsToggleSwitch: {
    input: 'alerts-toggle-switch-input',
    root: 'alerts-toggle-switch'
  },
  appBar: {
    menu: {
      adminSwitchFarms: 'admin-switch-farms',
      adminViewToggle: 'admin-view-toggle',
      adminViewToggleSwitch: 'admin-view-toggle-switch',
      changeMapType: 'change-map-type',
      root: 'appbar-menu-root',
      themeToggle: (pref: 'dark' | 'light') => `theme-toggle-${pref}`
    },
    menuToggle: 'appbar-menu-toggle',
    root: 'root-app-bar'
  },
  appRoot: `app-root`,
  attention: 'attention',
  auth: {
    confirmAccount: {
      linkTo: 'confirm-account-link',
      root: 'confirm-account-root'
    },
    fields: {
      codeString: 'codeString',
      confirmPassword: 'confirmPassword',
      currentPassword: 'currentPassword',
      email: 'email',
      newPassword: 'newPassword'
    },
    links: 'auth-links',
    notConfirmedDialog: 'not-confirmed-dialog',
    passwordReset: {
      linkTo: 'password-reset-link',
      root: 'password-reset-root'
    },
    resendCodeBtn: 'resend-code-btn',
    root: 'auth-root',
    signIn: {
      linkTo: 'sign-in-link',
      root: 'sign-in-root'
    },
    signOut: {
      linkTo: 'sign-out-link',
      root: 'sign-out-root'
    },
    signUp: {
      linkTo: 'sign-up-link',
      root: 'sign-up-root'
    }
  },
  backToTopAnchor: 'back-to-top-anchor',
  cancelBtn: 'cancel-btn',
  clearActiveFeatureBtn: 'clear-active-feature-btn',
  createConfiguration: {
    backBtn: 'go-back',
    chooseInstallationType: 'choose-installation-type',
    choosePumpType: 'choose-pump-type',
    chooseSwitchType: 'choose-switch-type',
    closeBtn: 'close-create-configuration-btn',
    confirmAndSubmit: {
      confirmKey: (k: string) => `confirm-${k}-key`,
      confirmVal: (k: string) => `confirm-${k}-value`,
      deviceName: 'device-name-value',
      installationType: 'installation-type-value',
      root: 'confirm-and-submit',
      submitBtn: 'submit-configuration-btn'
    },
    deviceName: 'device-name',
    hasPressureSensor: 'has-pressure-sensor',
    initBtn: 'set-up-device-btn',
    pressureThresholds: 'pressure-threshold-form',
    reelForm: 'reel-form',
    root: 'create-configuration-root',
    thresholds: 'pressure-threshold-form',
    wheelForm: 'wheel-form'
  },
  createFarm: {
    countrySelect: 'select-country',
    fields: {
      addressLine1: 'addressLine1',
      addressLine2: 'addressLine2',
      city: 'city',
      name: 'name',
      postalCode: 'postalCode',
      region: 'region'
    },
    form: 'create-farm-form',
    linkTo: 'create-farm-account-link',
    regionSelect: 'select-region',
    root: 'create-farm-root',
    submitBtn: 'submit-create-farm-btn'
  },
  createField: {
    cancelBtn: 'cancel-new-field-btn',
    confirmAndSubmit: {
      root: 'confirm'
    },
    drawField: {
      editBtn: 'redraw-field-btn',
      root: 'draw-field',
      submitBtn: 'submit-field-boundary-btn'
    },
    editBoundary: {
      editBtn: 'edit-boundary-btn',
      root: 'edit-boundary',
      submitBtn: 'enter-boundary-btn'
    },
    enterName: {
      editBtn: 'enter-name-btn',
      fieldNamePreview: 'field-name-preview-text',
      root: 'enter-name',
      submitBtn: 'submit-name-btn',
      textInput: 'fieldName'
    },
    initBtn: 'add-field-btn',
    root: 'create-field-root',
    setRowDirection: {
      bearingText: 'bearing-text',
      bearingTextRow: 'bearing-text-row',
      editBtn: 'edit-row-direction-btn',
      flipBtn: 'flip-row-direction-btn',
      root: 'set-row-direction',
      submitBtn: 'submit-row-direction-btn'
    },
    submitBtn: 'submit-new-field-btn',
    success: {
      drawMoreBtn: 'draw-another-field-btn'
    }
  },
  createNotification: {
    initializeBtn: 'initialize-create-notification-btn',
    root: 'create-notification-root',
    submitBtn: 'submit-notification-btn'
  },
  development: {
    linkTo: 'development-link'
  },
  deviceActions: {
    actionsRow: 'selected-device-actions',
    closeBtn: 'close-device-actions-btn',
    content: 'selected-device-content',
    deviceName: 'deviceName',
    dpMenu: 'device-profile-options-menu',
    installationType: 'installation-type',
    metadata: 'device-metadata',
    openDpMenuBtn: 'open-device-profile-menu-btn',
    renameDeviceBtn: 'rename-device-btn',
    root: 'device-actions-root',
    showRemoteControlsBtn: 'show-remote-controls-btn'
  },
  deviceAutomations: {
    confirmDelete: {
      root: 'confirm-delete-automation'
    },
    create: {
      notifyCheckbox: 'notify-checkbox',
      root: 'create-automation-root',
      startBtn: 'create-automation-btn',
      submitBtn: 'submit-new-rule-btn'
    },
    existingAutomations: {
      deleteBtn: (i: number) => `delete-btn${i}`,
      list: 'existing-automations-list',
      listItem: (i: number) => `automations-list-item-${i}`,
      root: 'existing-automations'
    },
    noAutomations: 'no-automations',
    root: 'device-automations'
  },
  deviceList: {
    item: {
      deviceName: 'device-name-text',
      etaText: 'run-eta-text',
      installationType: 'installation-type-text',
      primarySensorName: 'primary-sensor-name',
      primarySensorState: 'primary-sensor-state',
      progressBar: 'reel-progress-bar',
      psiText: 'psi-text',
      root: (alias: string) => `device-list-item-${alias}`,
      stateCurrent: 'state-current-value',
      textContainer: 'text-container'
    },
    items: 'device-list-items',
    root: 'device-list-root'
  },
  deviceOwnership: {
    closePermissions: 'close-existing-code-permissions-btn',
    createShareCode: {
      initBtn: 'initialize-create-share-code-btn',
      root: 'create-share-code-root',
      submitBtn: 'permission-editor-submit-btn'
    },
    existingCode: {
      actions: 'existing-code-actions',
      actionsBtn: 'existing-code-actions-btn',
      actionsMenu: 'existing-code-actions-menu',
      root: 'existing-share-code',
      viewPerms: 'existing-code-permissions',
      viewPermsBtn: 'view-existing-code-permissions-btn'
    },
    farmsList: 'farms-borrowing-device-list',
    invalidate: {
      cancelBtn: 'cancel-invalidate-code-btn',
      initBtn: 'invalidate-existing-code-btn',
      root: 'confirm-invalidate-code',
      submitBtn: 'submit-invalidate-code-btn'
    },
    root: 'device-ownership-root',
    title: 'device-ownership-title'
  },
  devicePairing: {
    confirmDelete: {
      root: 'confirm-delete-pair-root'
    },
    currentPairs: {
      deleteBtn: 'delete-pair-btn',
      item: (index: number) => `current-pairs-list-item-${index}`,
      list: 'current-pairs-list'
    },
    initBtn: 'device-pairing-init-btn',
    noPairs: 'no-pairs',
    potentialPartners: {
      item: (alias: string) => `potential-partner-${alias}`,
      list: 'potential-partners',
      noneAvailable: 'no-potential-partners'
    },
    root: 'device-pairing-root',
    submitPairBtn: 'submit-pair-btn'
  },
  deviceProfile: {
    closeBtn: 'close-device-profile-btn',
    menu: 'device-profile-options-menu',
    menuActions: {
      deviceOwnership: 'device-ownership',
      manageConfig: 'manage-configuration',
      notifications: 'device-notifications',
      showOnMapBtn: 'show-on-map'
    },
    openActionsMenuBtn: 'open-device-profile-options-btn',
    root: 'device-profile-root'
  },
  dialog: {
    closeBtn: 'close-dialog-btn',
    root: 'dialog'
  },
  doneBtn: 'done-btn',
  errors: {
    pageWrapper: 'error-page-wrapper'
  },
  farmJoinCodes: {
    current: {
      permissionsList: 'fjc-current-permissions',
      root: 'fjc-current',
      status: 'code-status-display',
      viewPermissionsBtn: 'view-fjc-permissions-btn'
    },
    newCode: {
      root: 'new-fjc-root',
      startBtn: 'start-new-fjc-btn',
      submit: 'new-fjc-submit'
    },
    shareCodeBtn: 'share-code-btn'
  },
  farmManager: {
    farmNameAndAddress: 'farm-name-and-address',
    onlyUserView: 'only-user-view',
    permissionEditor: {
      root: 'permission-editor-root',
      submitBtn: 'permission-editor-submit-btn'
    },
    root: 'farm-manager-root',
    usersList: 'farm-users-list',
    usersListItem: (index: number) => `farm-users-list-item-${index}`,
    usersListRoot: 'farm-users-list-root'
  },
  fieldActions: {
    editFieldShape: {
      root: 'edit-field-shape'
    },
    fieldRunHistory: {
      list: {
        item: (index: number) => `field-run-history-list-${index}`,
        root: 'field-run-history-list'
      },
      polygons: {
        root: 'field-run-history-polygons'
      },
      reloadBtn: 'reload-run-history-btn',
      root: 'field-run-history-root'
    },
    openOptionsBtn: 'open-field-options-btn',
    optionsMenu: {
      archiveField: 'archive-field',
      deleteField: 'delete-field',
      editFieldShape: 'edit-field-shape',
      reactivateField: 'reactivate-field',
      renameField: 'rename-field',
      root: 'field-options-menu',
      setRowDirection: 'set-row-direction',
      viewRunHistory: 'view-run-history'
    },
    renameField: {
      root: 'rename-field',
      submitBtn: 'submit-field-name-btn',
      textInput: 'fieldName'
    },
    root: 'field-actions-root',
    setRowDirection: {
      bearingText: 'bearing-text',
      doneBtn: 'close-set-row-direction-btn',
      flipBtn: 'flip-row-direction-btn',
      root: 'set-row-direction-root',
      submitBtn: 'submit-row-direction-btn'
    }
  },
  fieldsList: {
    item: (i: number) => `field-list-item-${i}`,
    root: 'field-list-root'
  },
  formError: 'form-error',
  inspectRecentEvents: {
    navigator: 'event-navigator',
    newerBtn: 'newer-btn',
    olderBtn: 'older-btn',
    refreshBtn: 'refresh-events-btn',
    root: 'inspect-recent-events',
    sensorData: 'sensor-data',
    viewSensorBtn: (sn: string) => `view-${sn}-btn`
  },
  instructions: 'instructions',
  joinFarmWithCode: {
    codeInput: 'codeString',
    instructions: 'instructions',
    linkTo: 'join-farm-with-code-link',
    root: 'join-farm-with-code-root',
    submitBtn: 'submit-join-farm-with-code-btn'
  },
  layout: {
    root: 'app-layout-root'
  },
  loadError: 'load-error',
  loaders: {
    modal: 'modal-loader',
    root: 'root-loader'
  },
  mailToBtn: 'mail-to-btn',
  manageConfiguration: {
    configuredSensors: 'configured-sensors',
    editSensorBtn: (sn: string) => `edit-${sn}-btn`,
    editThresholds: {
      root: 'edit-thresholds-form'
    },
    renameDevice: {
      deviceNameInput: 'deviceName',
      initBtn: 'rename-device-btn',
      root: 'rename-device'
    },
    resetConfig: {
      initBtn: 'reset-config-btn',
      root: 'restore-config-to-defaults'
    },
    root: 'manage-configuration-root',
    sensorValue: {
      row: (sn: string) => `${sn}-row`
    }
  },
  manageNotifications: {
    confirmDeleteNotification: {
      root: 'confirm-delete-notification',
      submitBtn: 'submit-delete-notification-btn'
    },
    deleteNotificationBtn: 'delete-notification-btn',
    editNotificationStringBtn: 'edit-notification-string-btn',
    noNotifications: 'no-notifications',
    notificationsList: 'notifications-list',
    notificationStatusText: 'notification-status-text',
    notificationStringInput: 'notificationString',
    root: 'manage-notifications-root'
  },
  newFarmSuccess: {
    addDevicesBtn: 'add-devices-btn',
    getSupportBtn: 'get-support-btn',
    links: 'new-farm-success-links',
    openMapBtn: 'open-map-btn',
    root: 'new-farm-success-root'
  },
  noFarms: {
    formWrapper: 'no-farms-form',
    root: 'no-farms-root'
  },
  noListItems: 'no-list-items',
  pageContent: 'page-content',
  permissionDenied: 'permission-denied',
  permissionEditor: {
    actions: 'permission-editor-actions',
    root: 'permission-editor-root',
    submitBtn: 'permission-editor-submit-btn'
  },
  permissionListItem: (p: string, val: boolean) =>
    `${p}-${val ? 'true' : 'false'}`,
  phoneNumbers: {
    addBtn: 'add-phone-number-btn',
    confirmVerify: {
      submitBtn: 'submit-verification-code-btn'
    },
    create: {
      field: 'phoneNumber',
      form: 'phone-number-form',
      input: 'phoneNumber-input',
      submitBtn: 'submit-new-phone-number-btn'
    },
    list: {
      item: (index: number) => `phone-number-list-item-${index}`,
      root: 'phone-numbers-list',
      verifiedBadge: `verified-badge`,
      verifyBtn: 'verify-phone-number-btn'
    },
    root: 'phone-numbers-root',
    verify: {
      resendCodeBtn: 'resend-code-btn',
      submitCodeBtn: 'submit-code-btn'
    }
  },
  pressureThresholdForm: {
    display: {
      lower: 'lower-display',
      upper: 'upper-display'
    },
    root: 'pressure-threshold-form',
    slider: {
      lower: 'slider-control-1',
      upper: 'slider-control-2'
    }
  },
  relayActionSelect: {
    item: (index: number) => `relay-action-option-${index}`,
    root: 'relay-action-select'
  },
  remoteControls: {
    confirmationCheckbox: 'confirmation-checkbox',
    confirmationRoot: 'remote-control-confirmation',
    root: 'remote-controls-root',
    submitBtn: 'send-rc-request-btn'
  },
  searchBar: {
    input: 'search-bar-input',
    root: 'search-bar-root'
  },
  shareCodeBtn: 'share-code-btn',
  sidebar: {
    backdrop: 'sidebar-backdrop',
    content: 'sidebar-content',
    root: 'sidebar-root',
    toggleBtn: 'sidebar-toggle'
  },
  sourceSensorSelect: {
    input: 'sourceSensor-select-input',
    root: 'sourceSensor-select'
  },
  triggerSelect: (
    name: 'sourceSensor' | 'stateCurrent' | 'statePrevious' | 'targetDeviceId'
  ) => {
    const prefix = `state-${name}-select` as const;
    return {
      label: `${prefix}-label`,
      root: `${prefix}-root`,
      input: `${prefix}-input`,
      menu: `${prefix}-menu`,
      menuItem: `${prefix}-menu-item`
    } as const;
  },

  statusMap: {
    loading: 'status-map-loading',
    map: 'status-map',
    root: 'status-map-root'
  },
  statusMapSidebar: {
    lists: {
      devices: 'device-list-root',
      fields: 'field-list-root'
    },
    listToggles: {
      device: 'devices-list-toggle-btn',
      fields: 'fields-list-toggle-btn'
    },
    root: 'status-map-sidebar-root'
  },
  submitBtn: 'submit-btn',
  success: 'success',
  support: {
    pages: {
      basicUsage: 'basic-usage',
      deviceOverview: 'device-overview',
      devices: 'devices',
      farm: 'farm',
      fields: 'fields',
      inTheBox: 'in-the-box',
      pumpInstallation: 'pump-installation',
      reelInstallation: 'reel-installation',
      troubleshooting: 'troubleshooting'
    },
    pageTitle: 'support-page-title',
    root: 'support-root',
    tableOfContents: {
      link: (id: string) => `toc-link-${id}`,
      links: {
        reelInstallation: 'reel-installation'
      },
      root: 'table-of-contents-root'
    }
  },
  targetDeviceSelect: {
    menu: 'target-device-menu',
    root: 'target-device-select',
    input: 'target-device-select-input'
  },
  termsOfService: {
    acceptBtn: 'accept-tos-btn',
    checkBox: 'accept-tos-checkbox',
    checkboxLabel: 'accept-tos-checkbox-label',
    declineBtn: 'decline-tos-btn',
    root: 'terms-of-service-dialog'
  },
  unconfiguredDeviceWarning: 'unconfigured-device-warning',
  warning: 'warning'
} as const;
