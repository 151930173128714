import camelcaseKeys from 'camelcase-keys';
import { isString, toSafeInteger } from 'lodash-es';
import { Geo } from 'src/geo';
import geocodeFarmAddress from 'src/geo/geocoding';
import makeApiRequest from 'src/requests/makeApiRequest';
import store from 'storejs';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { appConfig } from './configure-app';
import { RequestNames } from './constants';
import demo_data from './demo/demo_data/active_user_data.json';
import { findActiveFarm, getActiveFarmId } from './selectors';
import { isNullish, isPositiveInt, notNullish } from './types';
import { appLogger } from './utilities';

import type { PhoneNumber } from './account-settings';
import type {
  DeviceConfig,
  DeviceEvent,
  DeviceShareCode,
  DeviceUserAssociation,
  Trigger
} from './devices';
import type { DeviceFarmAssociation } from './devices/device-farm-association';
import type {
  FarmAccount,
  FarmJoinCode,
  OtherFarmUser,
  UserAccount
} from './farm';
import type { FarmField } from './fields';
import type { FarmUserPermissionsMap } from './permissions';
import type { ReelRun } from './reel-runs/reelRunActive.reducer';
import type { PointGeoJson } from './status-map/geo';
import type { RootState } from './store';
import type { Nullable } from './types';
type FarmSummary = Pick<FarmAccount, 'gpsLocation' | 'id' | 'name'>;

interface ActiveUserResponse extends UserAccount {
  farms: FarmAccount[];
  phoneNumbers: PhoneNumber[];
  deviceUserAssociations: DeviceUserAssociation[];
  permissions: FarmUserPermissionsMap;
  activeFarm: Nullable<{
    deviceConfigurations: DeviceConfig[];
    deviceEvents: Array<Omit<DeviceEvent, 'indexId'>>;
    deviceFarmAssociations: DeviceFarmAssociation[];
    deviceShareCodes: DeviceShareCode[];
    farmFields: FarmField[];
    farmJoinCode: FarmJoinCode | null;
    otherUsers: OtherFarmUser[];
    reelRuns: ReelRun[];
    triggers: Trigger[];
  }>;
}
const { GET_ACTIVE_USER_DATA } = RequestNames;

type GetActiveFarmIdResponse = {
  activeFarmId: number | null;
};

const asyncGetActiveFarmId = createAsyncThunk(
  'GET_ACTIVE_FARM_ID',
  async (): Promise<GetActiveFarmIdResponse> => {
    try {
      return await makeApiRequest<GetActiveFarmIdResponse>(
        'GET_ACTIVE_FARM_ID'
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
const demoData = camelcaseKeys(demo_data, { deep: true });
export default createAsyncThunk<
  ActiveUserResponse,
  { setFarmIdTo?: number } | undefined,
  { state: RootState }
>(
  GET_ACTIVE_USER_DATA,
  async (arg, { getState, dispatch }): Promise<ActiveUserResponse> => {
    let responseData: ActiveUserResponse;
    try {
      const state = getState();
      const localStorageFarmID = store.get('activeFarmId') as unknown;

      let activeFarmId: number | undefined =
        arg?.setFarmIdTo ??
        (isString(localStorageFarmID)
          ? toSafeInteger(localStorageFarmID)
          : isPositiveInt(localStorageFarmID)
          ? localStorageFarmID
          : getActiveFarmId(state)) ??
        undefined;

      if (isNullish(activeFarmId)) {
        appLogger.log(`No farm id`);
        activeFarmId =
          (await dispatch(asyncGetActiveFarmId()).unwrap()).activeFarmId ??
          undefined;
      }
      responseData = await makeApiRequest<ActiveUserResponse>(
        GET_ACTIVE_USER_DATA,
        arg
      );
      const { farm: activeFarm, index } =
        findActiveFarm(responseData.farms, activeFarmId) ?? {};
      if (notNullish(activeFarm) && isNullish(location)) {
        const geocodeResponse = await geocodeFarmAddress(activeFarm);
        const [bestMatch] = geocodeResponse.data.results;
        if (isNullish(bestMatch)) {
          throw new Error(`Got undefined value for farm location`);
        }
        const latLng = bestMatch.geometry.location;
        const gpsLocation = Geo.points.to.geoJson(latLng);
        const { farms } = responseData;
        if (typeof index === 'number') {
          farms[index] = { ...activeFarm, gpsLocation };
          responseData = {
            ...responseData,
            farms
          };
        }
      }

      console.log(appConfig);

      if (process.env.REACT_APP_DEMO_MODE === 'enabled') {
        const demoReelId = 'demo-reel';
        const demoPumpId = 'demo-pump';

        const demoReelEndPoint: PointGeoJson = {
          type: 'Point',
          coordinates: [-122.4256588, 48.5417881]
        };
        const demoPumpEndpoint: PointGeoJson = {
          type: 'Point',
          coordinates: [-122.4256588, 48.5417881]
        };
        const reelMaxDistance = 500000;
        const reelCurrentDistance = 300000;
        const nowString = new Date().toUTCString();
        let reelEvent: DeviceEvent | undefined = undefined;
        const pumpEvent: DeviceEvent = {
          deviceId: demoPumpId,
          deviceEventTimestamp: nowString,
          indexId: 1,
          id: 2,
          gps: {
            location: demoPumpEndpoint,
            altitude: 0,
            sensorName: 'gps',
            stateCurrent: 'A',
            statePrevious: 'A',
            stateCurrentInitDt: nowString,
            statePreviousInitDt: nowString,
            nSats: 10,
            sensorTriggeredUpdate: false
          },

          pressure: {
            readingKpa: 135,
            sensorName: 'pressure',
            sensorTriggeredUpdate: false,
            stateCurrent: 'PHI',
            statePrevious: 'PHI',
            stateCurrentInitDt: nowString,
            statePreviousInitDt: nowString
          },
          battery: null,
          temperature: null,

          particleEventTimestamp: nowString,
          relay: null,
          wheel: null,
          device: null,
          reel: null,
          hallSwitch: null,
          pressureSwitch: null
        };
        if (typeof demoReelId === 'string') {
          reelEvent = {
            battery: null,
            temperature: null,
            pressure: null,
            particleEventTimestamp: nowString,
            relay: null,
            wheel: null,
            device: null,
            hallSwitch: null,
            pressureSwitch: null,
            gps: {
              altitude: 0,
              stateCurrent: 'A',
              statePrevious: 'I',
              sensorName: 'gps',
              sensorTriggeredUpdate: false,
              stateCurrentInitDt: nowString,
              nSats: 10,
              location: demoReelEndPoint,
              statePreviousInitDt: nowString
            },

            deviceId: demoReelId,
            id: 1,
            indexId: 0,
            firmwareVersion: 1,
            deviceEventTimestamp: nowString,
            reel: {
              stateCurrent: 'RR',
              statePrevious: 'RR',
              runDistanceMmCurrent: reelCurrentDistance,
              runDistanceMmMax: reelMaxDistance,
              sensorName: 'reel',
              speedMillirpm: 765,
              sensorTriggeredUpdate: false,
              stateCurrentInitDt: nowString,
              statePreviousInitDt: nowString,
              runMagnetCountCurrent: 30,
              runMagnetCountMax: 50,
              runSpeedMmpm: 765
            }
          };
        }
        responseData = {
          ...responseData,
          activeFarm: {
            ...responseData.activeFarm,
            otherUsers: responseData.activeFarm?.otherUsers ?? [],
            farmJoinCode: responseData.activeFarm?.farmJoinCode ?? null,
            deviceFarmAssociations:
              responseData.activeFarm?.deviceFarmAssociations ?? [],
            deviceShareCodes: responseData.activeFarm?.deviceShareCodes ?? [],
            deviceConfigurations:
              responseData.activeFarm?.deviceConfigurations ?? [],
            farmFields: demoData.activeFarm.farmFields as FarmField[],
            deviceEvents: demoData.activeFarm.deviceEvents.map(
              (de, id): DeviceEvent => {
                return { ...de, indexId: id, id } as DeviceEvent;
              }
            ),
            reelRuns: [
              {
                reelRunId: 1,
                reelSprinklerType: 'gun',
                startPoint: {
                  type: 'Point',
                  coordinates: [-122.35017, 48.49457]
                },
                deviceConfigurationId: 1,
                deviceId: demoReelId,
                inProgress: true,
                lastEventTimestamp: new Date().toUTCString(),
                directionOverrideAzimuthDegrees: 0,
                startTimestamp: new Date().toUTCString(),
                fieldId: 0,
                fieldRowDirectionAzimuthDegrees: 0,
                distanceMmMax: reelMaxDistance,

                endPoint: demoReelEndPoint,
                duration: 10000,
                endTimestamp: new Date().toUTCString(),
                observations: [
                  reelMaxDistance,
                  400000,
                  reelCurrentDistance
                ].map(
                  (distanceObservedMm): ReelRun['observations'][number] => ({
                    distanceObservedMm,
                    speedObservedMmpm: 765,
                    applicationRateEstimatedMm: null,
                    flowRateEstimatedLpm: null,
                    pressureObservedKpa: null
                  })
                ),

                reelSwathWidthMm: 73512
              }
            ],
            triggers: responseData.activeFarm?.triggers ?? []
          }
        };
        if (reelEvent) {
          responseData.activeFarm?.deviceEvents.push(reelEvent);
        }
        responseData.activeFarm?.deviceEvents.push(pumpEvent);
      }
      return {
        ...responseData,
        activeFarm: {
          ...responseData.activeFarm,
          reelRuns: [
            {
              reelRunId: 1,
              reelSprinklerType: 'gun',
              startPoint: null,
              deviceConfigurationId: 1,
              deviceId:
                responseData.activeFarm?.deviceConfigurations[0]?.deviceId ??
                '',
              inProgress: true,
              lastEventTimestamp: new Date().toUTCString(),
              directionOverrideAzimuthDegrees: 0,
              startTimestamp: new Date().toUTCString(),
              fieldId: 0,
              fieldRowDirectionAzimuthDegrees: 0,
              distanceMmMax: 400000,
              endPoint: {
                type: 'Point',
                coordinates: [-122.4256588, 48.5417881]
              },
              duration: 10000,
              endTimestamp: new Date().toUTCString(),
              observations: [500000, 400000, 300000].map(
                (distanceObservedMm): ReelRun['observations'][number] => ({
                  distanceObservedMm,
                  speedObservedMmpm: 765,
                  applicationRateEstimatedMm: null,
                  flowRateEstimatedLpm: null,
                  pressureObservedKpa: null
                })
              ),
              reelSwathWidthMm: 73512
            }
          ]
        }
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export type { ActiveUserResponse, FarmSummary };
