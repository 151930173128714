import type { MenuProps } from '@material-ui/core';
import { Divider, Menu } from '@material-ui/core';
import { useGoogleMap } from '@react-google-maps/api';
import destination from '@turf/destination';
import { kebabCase } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getHasVerifiedPhoneNumbers, useActiveFeature } from 'src/appSelectors';
import { useActiveFeatureState, useStartDeviceAction } from 'src/actions';
import type { DeviceIdProp } from 'src/devices/models';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish, notNullish } from 'src/types';
import type { ValuesType } from 'utility-types';
import { AppMenuItem } from '../components/menu';
import { getLastEventLocationByIdGeoJson } from '../devices/selectors';
import { Geo } from '../geo';
import type { RequiredPermissions } from '../permissions';
import type { RootState } from '../store';
import type { IconKey } from '../theme';
import { humanize } from '../utilities';
import { showAlertsToggleDenied } from './device-user';

export const DEVICE_ACTION_NAMES = {
  AUTOMATIONS: 'AUTOMATIONS',
  CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
  INSPECT_RECENT_EVENTS: 'INSPECT_RECENT_EVENTS',
  MANAGE_CONFIGURATION: 'MANAGE_CONFIGURATION',
  NOTIFICATIONS: 'DEVICE_NOTIFICATIONS',
  PAIRING: 'PAIRING',
  REMOTE_CONTROLS: 'REMOTE_CONTROLS',
  SENSOR_STATES: 'SENSOR_STATES',
  SHARE_DEVICE: 'SHARE_DEVICE',
  VIEW_PERMISSIONS: 'VIEW_PERMISSIONS'
} as const;
export type DeviceActionName = ValuesType<typeof DEVICE_ACTION_NAMES>;

const MENU_ACTIONS: DeviceActionName[] = [
  DEVICE_ACTION_NAMES.AUTOMATIONS,
  DEVICE_ACTION_NAMES.PAIRING,
  DEVICE_ACTION_NAMES.NOTIFICATIONS,
  DEVICE_ACTION_NAMES.INSPECT_RECENT_EVENTS
];

const DEVICE_ACTIONS: {
  [key in DeviceActionName]: {
    requiredPermissions?: RequiredPermissions;
    label?: string;
    helpText?: string;
    iconKey?: IconKey;
  };
} = {
  AUTOMATIONS: {
    label: 'Automation',
    requiredPermissions: ['canManageDevicePairs', 'canManageCustomTriggers']
  },
  CREATE_CONFIGURATION: {
    requiredPermissions: ['canManageDeviceConfiguration']
  },
  DEVICE_NOTIFICATIONS: {
    label: 'Alerts',
    requiredPermissions: ['canManageDeviceNotifications']
  },
  INSPECT_RECENT_EVENTS: {
    label: 'View Activity'
  },
  MANAGE_CONFIGURATION: {
    label: 'Configuration',
    requiredPermissions: ['canManageDeviceConfiguration']
  },
  PAIRING: {
    requiredPermissions: ['canManageDevicePairs']
  },
  REMOTE_CONTROLS: {
    requiredPermissions: ['canControlDevicesRemotely']
  },
  SENSOR_STATES: {
    iconKey: 'DEVICE_EVENT',
    label: 'Sensor Status'
  },
  SHARE_DEVICE: {
    label: 'Sharing',
    requiredPermissions: ['canManageDevicePermissions']
  },
  VIEW_PERMISSIONS: {}
};

const getMenuActionsForDevice = createSelector(
  (state: RootState, id: string) => state.devices.entities[id],
  (state: RootState, id: string) => state.deviceFarmAssociations.entities[id],
  (config, dfa) => {
    const options = [...MENU_ACTIONS];

    options.push(
      config?.deviceInstallationType === 'unconfigured'
        ? 'CREATE_CONFIGURATION'
        : 'MANAGE_CONFIGURATION'
    );

    options.push(
      dfa?.isPrimaryOwner === true ? 'SHARE_DEVICE' : 'VIEW_PERMISSIONS'
    );
    return options;
  }
);
const getDeviceZoomTarget = createCachedSelector(
  (state: RootState, id: string) => getLastEventLocationByIdGeoJson(state, id),
  (state: RootState) => state.statusMap.currentZoom,
  (gpsLocation, zoom) => {
    if (gpsLocation) {
      const zoomTarget =
        zoom >= 13
          ? gpsLocation
          : destination(gpsLocation, 500, -180, {
              units: 'meters'
            }).geometry;

      return Geo.points.to.googleMaps(zoomTarget);
    }
    return null;
  }
)((_, id) => id);

const TEST_ID = `device-profile-options-menu`;

type Props = DeviceIdProp & MenuProps;
const DeviceProfileOptionsMenu = React.forwardRef<any, Props>((props, ref) => {
  const { deviceId, onClose, ...menuProps } = props;
  const { setActiveFeature } = useActiveFeatureState();
  const handleCloseMenu = () => onClose && onClose({}, 'backdropClick');
  const dispatch = useAppDispatch();

  const map = useGoogleMap();
  const currentAction = useActiveFeature();
  const options = useSelector((s) => getMenuActionsForDevice(s, deviceId));
  const handleClickAction = useStartDeviceAction(deviceId);
  const hasVerifiedNumbers = useSelector(getHasVerifiedPhoneNumbers);
  const zoomTarget = useSelector((state) =>
    getDeviceZoomTarget(state, deviceId)
  );

  return (
    <Menu
      data-cy={TEST_ID}
      id={TEST_ID}
      onClose={onClose}
      {...menuProps}
      ref={ref}
    >
      {options.map((key, index) => {
        const { label, iconKey, requiredPermissions } = DEVICE_ACTIONS[key];

        const handleClick = () => {
          if (key === 'DEVICE_NOTIFICATIONS' && !hasVerifiedNumbers) {
            dispatch(showAlertsToggleDenied());
          } else {
            handleClickAction(key);
          }
          handleCloseMenu();
        };
        return (
          <AppMenuItem
            button
            dense
            divider={index === options.length - 1}
            iconKey={iconKey ?? key ?? 'DEVICE_EVENT'}
            id={kebabCase(key)}
            key={key}
            onClick={handleClick}
            requiredPermissions={requiredPermissions}
            selected={currentAction === key}
            textPrimary={label ?? humanize(key)}
          />
        );
      })}
      <Divider />
      <AppMenuItem
        button
        dense
        disabled={isNullish(zoomTarget)}
        iconKey="CENTER_MAP"
        id={testIds.deviceProfile.menuActions.showOnMapBtn}
        onClick={() => {
          if (notNullish(zoomTarget)) {
            map?.setCenter(zoomTarget);
            setActiveFeature(null);
            handleCloseMenu();
          }
        }}
        textPrimary="Show On Map"
      />
    </Menu>
  );
});
DeviceProfileOptionsMenu.displayName = 'DeviceOptions';
export default DeviceProfileOptionsMenu;
