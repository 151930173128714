import { isString, omit } from 'lodash-es';
import type { LabelLength } from 'src/components/types';
import { humanize } from 'src/utilities';
import type { ValuesType } from 'utility-types';
import type { ConfigSensorName } from './SensorConfig';

const SENSOR_NAME = {
  battery: 'battery',
  device: 'device',
  gps: 'gps',
  hallSwitch: 'hallSwitch',
  pressure: 'pressure',
  pressureSwitch: 'pressureSwitch',
  reel: 'reel',
  relay: 'relay',
  temperature: 'temperature',
  wheel: 'wheel'
} as const;
type SensorName = ValuesType<typeof SENSOR_NAME>;
const SENSOR_NAMES = Object.values(SENSOR_NAME);
const CONFIG_SENSOR_NAMES: ConfigSensorName[] = SENSOR_NAMES.filter(
  (s) => s !== 'device'
) as ConfigSensorName[];
/**
 * @param maybeSensorName
 */
function isValidSensorName(
  maybeSensorName?: unknown
): maybeSensorName is SensorName {
  return (
    isString(maybeSensorName) &&
    SENSOR_NAMES.includes(maybeSensorName as SensorName)
  );
}
/**
 * @param value
 * @param options
 * @param options.labelLength
 */
function humanizeSensorName(
  value: string,
  options?: { readonly labelLength?: LabelLength }
): string {
  const useShort = options?.labelLength === 'short';
  switch (value.toLowerCase() as SensorName) {
    case `gps`:
      return value.toUpperCase();
    case `pressure`:
      return `${useShort ? '' : 'Analog '}Pressure`;
    case 'pressureSwitch':
      return 'Pressure Switch';
    case 'hallSwitch':
      return 'Hall Switch';
    default:
      return humanize(value);
  }
}
const SENSOR_NAME_TO_NULL: { readonly [key in SensorName]: null } = {
  battery: null,
  device: null,
  gps: null,
  hallSwitch: null,
  pressure: null,
  pressureSwitch: null,
  reel: null,
  relay: null,
  temperature: null,
  wheel: null
};
/**
 * @param hasSensorName
 */
function removeSensorName<A extends { readonly sensorName: SensorName }>(
  hasSensorName: A
): Omit<A, 'sensorName'> {
  return omit(hasSensorName, 'sensorName');
}

type PumpType = typeof SENSOR_NAME.pressure | typeof SENSOR_NAME.pressureSwitch;

type SensorNameToBoolean = { [S in SensorName]: boolean };
/**
 * @param sn
 */
export function isValidConfigSensorName(sn: unknown): sn is ConfigSensorName {
  return isValidSensorName(sn) && sn !== 'device';
}
const SensorNames = {
  ...SENSOR_NAME,
  CONFIG_SENSOR_NAMES,
  SENSOR_NAMES,
  get: {
    keysList: SENSOR_NAMES
  },
  getList: (): typeof SENSOR_NAMES => [...SENSOR_NAMES],
  humanize: humanizeSensorName,
  is: {
    valid: isValidSensorName
  },
  isValidSensorName,
  mapToBoolean: (value = false): SensorNameToBoolean => {
    const initial = {} as SensorNameToBoolean;
    return SENSOR_NAMES.reduce(
      (acc, next) => ({
        ...acc,
        [next]: value
      }),
      initial
    );
  },
  mapToNull: (): typeof SENSOR_NAME_TO_NULL => ({ ...SENSOR_NAME_TO_NULL }),
  removeName: removeSensorName
};

export default SensorNames;
export { SENSOR_NAMES, CONFIG_SENSOR_NAMES };
export type { SensorName, PumpType };
