import type { EntityState, Reducer } from '@reduxjs/toolkit';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import { createAsyncAppThunk } from 'src/requests';

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { asyncAddDeviceToFarmAccount } from '../device-farm-association';

import type { DevicePermissionsMap } from 'src/permissions';
import type { RootState } from 'src/root.reducer';
import type { ListRes } from 'src/types';
import type { DeviceFarmAssociation } from '../device-farm-association';
import type { DeviceIdProp } from '../models';

interface DeviceShareCode extends DeviceIdProp {
  id: number;
  permissions: DevicePermissionsMap;
  farmName: string;
  expirationDate: string;
  permissionExpirationDate: string;
  codeStr: string;
}
const adapter = createEntityAdapter<DeviceShareCode>({
  selectId: (d) => d.deviceId
});

const {
  CREATE_DEVICE_SHARE_CODE,
  GET_FARMS_BORROWING_DEVICE,
  INVALIDATE_DEVICE_SHARE_CODE
} = RequestNames;
const asyncCreateDeviceShareCode = createAsyncAppThunk<
  Pick<DeviceShareCode, 'deviceId' | 'permissions'>,
  DeviceShareCode
>(CREATE_DEVICE_SHARE_CODE, {
  checkPermissions: 'canManageDevicePermissions',
  successToast: 'Code created.'
});

const asyncInvalidateDeviceShareCode = createAsyncAppThunk<
  { codeId: number },
  DeviceIdProp
>(INVALIDATE_DEVICE_SHARE_CODE);
const asyncGetFarmsBorrowingDevice = createAsyncAppThunk<
  DeviceIdProp,
  ListRes<DeviceFarmAssociation>
>(GET_FARMS_BORROWING_DEVICE);

type State = EntityState<DeviceShareCode>;
const getSlice = (state: RootState) => state.deviceShareCodes;
const selectors = adapter.getSelectors(getSlice);

const initialState: State = adapter.getInitialState({
  interaction: null
});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetUserData.fulfilled, (state, action) => {
        adapter.upsertMany(
          state,
          action.payload.activeFarm?.deviceShareCodes ?? []
        );
      })
      .addCase(asyncCreateDeviceShareCode.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, payload);
      })
      .addCase(asyncAddDeviceToFarmAccount.fulfilled, (state, { payload }) => {
        if (payload.status === 'VALID' && payload.inputMode === 'SHARE_CODE') {
          adapter.upsertOne(state, payload.deviceShareCode);
        }
      })
      .addCase(asyncInvalidateDeviceShareCode.fulfilled, (state, action) => {
        adapter.removeOne(state, action.payload.deviceId);
      }),
  initialState,
  name: 'DEVICE_SHARE_CODES',
  reducers: {}
});

const { actions } = slice;
const deviceShareCodes: Reducer<State> = slice.reducer;

const DeviceShareCodes = {
  ...actions,
  ...selectors,
  selectIds: (state: RootState): string[] =>
    selectors.selectIds(state) as string[]
};
export type { DeviceShareCode };
export {
  asyncInvalidateDeviceShareCode,
  DeviceShareCodes,
  asyncGetFarmsBorrowingDevice,
  asyncCreateDeviceShareCode
};
export default deviceShareCodes;
