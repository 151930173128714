/* eslint-disable react/function-component-definition */
import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * Get screen size from Mui breakpoint query
 */
function useIsMobile(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.only(`xs`));
}
export default useIsMobile;
