import { Alert } from '@material-ui/lab';
import React from 'react';
import { useStartDeviceAction } from 'src/actions';
import { useActiveFeature } from 'src/appSelectors';
import { AppButton } from 'src/components';
import type { DeviceIdProp, PropInstallationType } from 'src/devices';

/**
 * @param props
 * @param props.deviceId
 * @param props.deviceInstallationType
 * @param props.onClickSetUp
 * @param props.isHidden
 * @param props.className
 */
export default function UnconfiguredDeviceWarning({
  deviceInstallationType,
  deviceId,
  className
}: DeviceIdProp &
  PropInstallationType & {
    className?: string;
  }): JSX.Element | null {
  const handleSetFeature = useStartDeviceAction();
  const handleClickSetUp = () =>
    handleSetFeature('CREATE_CONFIGURATION', deviceId);
  const isHidden = useActiveFeature() === 'CREATE_CONFIGURATION';

  const id = `unconfigured-device-warning`;
  return deviceInstallationType !== 'unconfigured' || isHidden ? null : (
    <Alert
      action={
        <AppButton
          id="set-up-device-btn"
          onClick={handleClickSetUp}
          text="set-up"
        >
          Set Up
        </AppButton>
      }
      className={className}
      data-cy={id}
      id={id}
      severity="warning"
    >
      This device is not configured
    </Alert>
  );
}
