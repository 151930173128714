import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from '@material-ui/core';
import { captureException, captureMessage } from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { asyncAcceptTermsOfService } from 'src/account-settings/userRequests';
import { useSignOut } from 'src/auth/authRequests';
import { CancelBtn, SubmitBtn } from 'src/components';
import { useIsMobile } from 'src/hooks';
import useAppSelector from 'src/hooks/useAppSelector';
import useThunk from 'src/hooks/useThunk';
import testIds from 'src/testIds';
import { showErrorToast } from 'src/theme';
import { getActiveUserEmail, useAppIsLoaded } from 'src/userSession.reducer';
import { TermsOfServiceText } from './TermsOfServiceText';

/**
 * @param props
 * @param props.isOpen
 */
function TermsOfServiceDialog(): JSX.Element {
  const [isChecked, setIsChecked] = React.useState(false);
  const signOut = useSignOut();

  /**
   * Controls "I agree" checkbox
   */
  const { isPending, sendRequest } = useThunk(asyncAcceptTermsOfService);
  const isAccepted = useAppSelector(
    (state) => state.userSession.termsOfServiceAccepted
  );
  const appIsLoaded = useAppIsLoaded();
  const isMobile = useIsMobile();
  const email = useSelector(getActiveUserEmail);

  const handleSubmit = async () =>
    sendRequest(null)
      .then(() => {
        captureMessage(`${email ?? ''} accepted CODA terms of service`);
      })
      .catch((e) => {
        captureException(e);
        showErrorToast();
      });
  const Ids = testIds.termsOfService;
  return (
    <Dialog
      data-cy={Ids.root}
      fullScreen={isMobile}
      id={Ids.root}
      open={appIsLoaded && !isAccepted}
    >
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        <TermsOfServiceText />
      </DialogContent>
      <DialogActions>
        <CancelBtn
          id={Ids.declineBtn}
          onClick={signOut}
          text="Decline"
          variant="text"
        />
        <SubmitBtn
          disabled={!isChecked || isPending}
          id={Ids.acceptBtn}
          onClick={handleSubmit}
          showLoading={isPending}
          text="Accept"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              data-cy={Ids.checkBox}
              disabled={isPending}
              id={Ids.checkBox}
              onChange={() => setIsChecked(!isChecked)}
            />
          }
          id={Ids.checkboxLabel}
          label="I Agree"
        />
      </DialogActions>
    </Dialog>
  );
}
export default TermsOfServiceDialog;
