import { Typography } from '@material-ui/core';
import React from 'react';
import type { SensorName } from 'src/devices/sensors';
import { useAppSelector } from 'src/hooks';
import { isNullish } from 'src/types';
import { getSensorEventByDeviceId } from './selectors';
import { SensorNames } from './sensors';
import useFormatSensorValue from './sensors/useFormatSensorValue';

type ConnectedProps<S extends SensorName> = {
  sensorName: S;
  deviceId: string;
};
/**
 * @param props
 * @param props.sensorName
 * @param props.deviceId
 * @param props.children
 */
function SensorStateDescription<S extends SensorName>({
  sensorName,
  deviceId
}: ConnectedProps<S>): JSX.Element | null {
  const formatValue = useFormatSensorValue();
  const sensorData = useAppSelector((state) =>
    getSensorEventByDeviceId(state, deviceId, sensorName)
  );
  if (isNullish(sensorData)) {
    return null;
  }
  const { stateCurrent, stateCurrentInitDt } = sensorData;
  return (
    <div>
      <Typography color="textSecondary" component="span" variant="overline">
        {SensorNames.humanize(sensorName)}
        <Typography color="primary" component="span" variant="overline">
          {' '}
          {formatValue('stateCurrent', stateCurrent)}{' '}
        </Typography>
      </Typography>
      <Typography component="span" variant="body2">
        as of {formatValue('stateCurrentInitDt', stateCurrentInitDt)}
      </Typography>
    </div>
  );
}

const SensorStateDescriptionMemo = React.memo(SensorStateDescription);
export default SensorStateDescriptionMemo;
