import type { ConversionTarget, SensorConfig } from 'src/devices/sensors';
import { isNullish, isStringOrNumber } from 'src/types';
import { User } from 'src/userSession.reducer';
import convertSensorValue from './convertSensorValue';
import type { ConfigSensorName } from './SensorConfig';
import SensorConfigs from './SensorConfig';

/**
 * @param sensorName
 * @param config
 * @param target
 */
export default function useSensorConfigConverter<
  S extends ConfigSensorName,
  C extends SensorConfig<S>,
  K extends keyof SensorConfig<S>
>(
  sensorName: S,
  target: ConversionTarget
): (config: C, ...keys: [...K[]]) => C {
  const isMetric = User.useIsMetric();

  return (config: C, ...keys: [...K[]]) =>
    Object.entries(config).reduce(
      (acc, [key, value]) => {
        if (SensorConfigs.isKeyOfSensorConfig(sensorName, key)) {
          if (keys.length > 0 && !keys.includes(key as typeof keys[number])) {
            return acc;
          }
          if (isNullish(value)) {
            return {
              ...acc,
              key: null
            };
          }
          if (isStringOrNumber(value)) {
            const converted = convertSensorValue({
              isMetric,
              key,
              target,
              value
            });
            return {
              ...acc,
              [key]: converted.value
            };
          }
        }
        return { ...acc };
      },
      { ...config }
    );
}
