import type { ValuesType } from 'utility-types';
import type { RelayActionType } from '../RelayAction';

const REMOTE_CONTROL_KEY = {
  ACTIVATE_RELAY: 'ACTIVATE_RELAY',
  DEACTIVATE_RELAY: 'DEACTIVATE_RELAY',
  DOWNLOAD_CONFIG: 'DOWNLOAD_CONFIG',
  FORCE_UPDATE: 'FORCE_UPDATE',
  REBOOT_CHIP: 'REBOOT_CHIP',
  SHUT_DOWN: 'SHUT_DOWN',
  TEST_CONNECTION: 'TEST_CONNECTION'
} as const;

export type RemoteControlKey = ValuesType<typeof REMOTE_CONTROL_KEY>;

export type RemoteControlFunctionName =
  | RelayActionType
  | 'reconfigure_device'
  | 'reset_device'
  | 'send_device_event';
const WARNINGS = {
  circuitDisclaimer: `Controlling a circuit manually can have dangerous consequences. 
    You must accept all responsibility all equipment connected 
    to the remote switch.`,
  dataLoss: `If the device is tracking active equipment, data may be lost.`,
  noCancellation: 'This action cannot be canceled.'
} as const;

export default {
  ...REMOTE_CONTROL_KEY,
  KEYS_LIST: Object.values(REMOTE_CONTROL_KEY),
  WARNINGS
} as const;
