import React from 'react';
import type {
  RawSensorValue,
  SensorFormattingOptions
} from './formatSensorValue';
import formatSensorValue from './formatSensorValue';
import type { AnySensorKey } from './getConversionUnits';
import useDefaultSensorFormatArgs from './useDefaultSensorFormatArgs';

// export interface SensorFormattingOptions {
//   readonly labelLength?: LabelLength;
//   readonly dtFormat?: DtFormat;
//   readonly unitLabel?: boolean;
// }

/**
 * @param key
 * @param options
 */
export default function useFormatSensorValue<
  K extends AnySensorKey,
  V extends RawSensorValue
>(options?: SensorFormattingOptions): (key: K, rawValue: V) => string | null {
  const defaultArgs = useDefaultSensorFormatArgs();
  const formatValue = React.useCallback(
    (key: K, rawValue: V): string | null =>
      formatSensorValue({
        key,
        options: { ...defaultArgs, ...options },
        rawValue
      }),
    [defaultArgs, options]
  );
  return formatValue;
}
