import { toast } from 'react-toastify';
import useAppDispatch from 'src/hooks/useAppDispatch';
import cancelOnPending from 'src/requests/cancelOnPending';

import { Auth } from '@aws-amplify/auth';
import { createAction, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import type { RootThunkApi } from 'src/store';
import type { AuthState } from './reducer';

const clearAuthError = createAction(`clearAuthError`);
const setAuthStage = createAction<AuthState['currentStage']>('setAuthStage');

/**
 *
 */
function useClearAuthError(): () => void {
  const dispatch = useAppDispatch();
  return (): void => {
    dispatch(clearAuthError());
  };
}

const SUBMIT_NEW_PASSWORD = `asyncSubmitNewPassword` as const;
const asyncSubmitNewPassword = createAsyncThunk<
  200,
  {
    readonly newPassword: string;
    readonly email: string;
    readonly codeString: string;
  },
  RootThunkApi
>(SUBMIT_NEW_PASSWORD, async (args) => {
  try {
    await Auth.forgotPasswordSubmit(
      args.email.toLocaleLowerCase(),
      args.codeString.toLocaleUpperCase(),
      args.newPassword
    );
    return 200;
  } catch (err) {
    return Promise.reject(err);
  }
});

const SIGN_UP = `asyncSignUp` as const;
const asyncSignUp = createAsyncThunk<
  200,
  { readonly email: string; readonly newPassword: string },
  RootThunkApi
>(
  SIGN_UP,
  async ({ email, newPassword }) => {
    try {
      await Auth.signUp(email.toLocaleLowerCase(), newPassword);
      return 200;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  { condition: cancelOnPending(SIGN_UP) }
);

const GET_SIGN_UP_CODE = 'asyncGetSignUpCode' as const;
const asyncGetSignUpCode = createAsyncThunk<
  string,
  { readonly email: string },
  RootThunkApi
>(
  GET_SIGN_UP_CODE,
  async ({ email }) => {
    try {
      await Auth.resendSignUp(email.toLocaleLowerCase());
      return email;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  {
    condition: cancelOnPending(GET_SIGN_UP_CODE)
  }
);

const SUBMIT_SIGN_UP_CONFIRMATION = `asyncSubmitSignupConfirmation` as const;
const asyncSubmitSignUpConfirmation = createAsyncThunk<
  200,
  { readonly codeString: string; readonly email: string },
  RootThunkApi
>(
  SUBMIT_SIGN_UP_CONFIRMATION,
  async ({ codeString, email }) => {
    try {
      await Auth.confirmSignUp(email.toLocaleLowerCase(), codeString);
      toast.info(`Account created successfully.`);
      return 200;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  { condition: cancelOnPending(SUBMIT_SIGN_UP_CONFIRMATION) }
);

const RESEND_PASSWORD_CODE = 'asyncResendPasswordResetCode' as const;
const asyncResendPasswordResetCode = createAsyncThunk<
  string,
  { readonly email: string },
  RootThunkApi
>(
  RESEND_PASSWORD_CODE,
  async ({ email }) => {
    try {
      await Auth.forgotPassword(email.toLocaleLowerCase());
      return email;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  { condition: cancelOnPending(RESEND_PASSWORD_CODE) }
);

const isPendingAuthThunk = isAnyOf(
  asyncSignUp.pending,

  asyncSubmitNewPassword.pending,
  asyncResendPasswordResetCode.pending,
  asyncGetSignUpCode.pending,
  asyncSubmitSignUpConfirmation.pending
);
const isRejectedAuthThunk = isAnyOf(
  asyncSignUp.rejected,

  asyncSubmitNewPassword.rejected,
  asyncResendPasswordResetCode.rejected,
  asyncGetSignUpCode.rejected,
  asyncSubmitSignUpConfirmation.rejected
);
export {
  setAuthStage,
  isPendingAuthThunk,
  clearAuthError,
  useClearAuthError,
  isRejectedAuthThunk,
  asyncGetSignUpCode,
  asyncResendPasswordResetCode,
  asyncSignUp,
  asyncSubmitSignUpConfirmation,
  asyncSubmitNewPassword
};
