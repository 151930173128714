import type { Dictionary, EntityState } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { isNullish } from 'src/types';

import type { RootState } from 'src/root.reducer';
import type { RootSelector } from '../store';

const createGetAll = <T>(
  inputSelector: RootSelector<EntityState<T>>
): ((state: RootState) => T[]) => {
  return createSelector(inputSelector, (state) => {
    const { ids, entities } = state;
    const initializer = [] as T[];
    return ids.reduce<T[]>((acc, id) => {
      const entity = entities[id];
      if (isNullish(entity)) {
        return acc;
      }
      return [...acc, entity];
    }, initializer);
  });
};

/**
 * @param getSlice
 */
export default function createEntitySelectors<S>(
  getSlice: (state: RootState) => EntityState<S>
): {
  all: (state: RootState) => S[];
  entities: (state: RootState) => Dictionary<S>;
} {
  return {
    all: createGetAll(getSlice),
    entities: (state: RootState): Dictionary<S> => {
      return getSlice(state).entities;
    }
  };
}
