import type { SerializedError } from '@reduxjs/toolkit';
import type { ValuesType } from 'utility-types';

const COGNITO_CONFIG: {
  readonly region: string;
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly identityPoolId: string;
} = {
  identityPoolId: `us-west-2:377f50ea-27ee-46c1-8aa2-2f3b6d8b7b39`,
  region: `us-west-2`,
  userPoolId: `us-west-2_vY9ky781T`,
  userPoolWebClientId: `nl7di6o994hgsuuilc3qiuire`
} as const;

const NO_CURRENT_USER = 'No current user' as const;

const USER_NOT_CONFIRMED_EXCEPTION = `UserNotConfirmedException` as const;
const NOT_AUTHORIZED_EXCEPTION = `NotAuthorizedException` as const;
const USER_ALREADY_CONFIRMED_EXCEPTION = `InvalidParameterException` as const;
const USER_NOT_FOUND_EXCEPTION = `UserNotFoundException` as const;
const CODE_MISMATCH_EXCEPTION = `CodeMismatchException` as const;
const USERNAME_EXISTS_EXCEPTION = `UsernameExistsException` as const;

const INVALID_CODE_MESSAGE = `The code you entered is invalid. Please double-check and try again.`;

const AUTH_ERROR_CODES = {
  authCheckFailed: NO_CURRENT_USER,
  codeMismatchException: CODE_MISMATCH_EXCEPTION,
  notAuthorizedException: NOT_AUTHORIZED_EXCEPTION,
  userAlreadyConfirmedException: USER_ALREADY_CONFIRMED_EXCEPTION,
  userNotConfirmedException: USER_NOT_CONFIRMED_EXCEPTION,
  userNotFoundException: USER_NOT_FOUND_EXCEPTION,
  usernameExistsException: USERNAME_EXISTS_EXCEPTION
} as const;

type AuthErrorCodes = typeof AUTH_ERROR_CODES;
type AuthErrorCodeValue = ValuesType<AuthErrorCodes>;
interface AuthError<T extends AuthErrorCodeValue = AuthErrorCodeValue>
  extends SerializedError {
  readonly code: T;
  readonly message: string;
}
export type { AuthError, AuthErrorCodeValue, AuthErrorCodes };
export {
  INVALID_CODE_MESSAGE,
  AUTH_ERROR_CODES,
  COGNITO_CONFIG,
  NO_CURRENT_USER
};
