import { kebabCase } from 'lodash-es';
import React from 'react';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import InstallationTypes from '../installation-type';
import ChoiceBtn from './ChoiceBtn';
import ChoicesWrapper from './ChoicesWrapper';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 * Stage 1: User selects the equipment type of the installation
 */
export default function ChooseInstallationType(): JSX.Element {
  const dispatch = useAppDispatch();
  return (
    <FormWrapper
      instructions="What type of equipment is the device installed on?"
      testId={testIds.createConfiguration.chooseInstallationType}
    >
      <ChoicesWrapper>
        {CreateConfig.CONFIG_TYPES.map((dit) => (
          <ChoiceBtn
            id={kebabCase(dit)}
            key={dit}
            onClick={() => dispatch(CreateConfig.submitInstallationType(dit))}
            text={InstallationTypes.humanize(dit)}
          />
        ))}
      </ChoicesWrapper>
    </FormWrapper>
  );
}
