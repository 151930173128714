import type { MenuItemProps, Theme } from '@material-ui/core';
import {
  createStyles,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useIsAnyRequestPending } from 'src/appSelectors';
import LoadingMenuIcon from 'src/components/menu/LoadingMenuIcon';
import type {
  AdminOnlyProps,
  CanBeLink,
  ChecksPermission,
  HideableProps,
  ShowsLoader
} from 'src/components/types';
import { useAppDispatch } from 'src/hooks';
import type { IconKey } from 'src/theme';
import { AppIcon } from 'src/theme';
import { isNullish, isTruthyString } from 'src/types';
import { denyPermission, User } from 'src/userSession.reducer';

type AppMenuItemProps = AdminOnlyProps &
  CanBeLink &
  ChecksPermission &
  HideableProps &
  Pick<
    MenuItemProps,
    | 'alignItems'
    | 'button'
    | 'children'
    | 'dense'
    | 'disabled'
    | 'divider'
    | 'id'
    | 'onClick'
    | 'selected'
    | 'value'
  > &
  ShowsLoader & {
    secondaryAction?: React.ReactNode;
    textPrimary: string | null;
    textSecondary?: string | null;
    iconPosition?: 'left' | 'right';
  } & (
    | {
        iconKey?: IconKey;
        icon?: undefined;
      }
    | {
        iconKey?: undefined;
        icon?: React.ReactNode;
      }
  );

const useStyles = makeStyles<Theme, Pick<AppMenuItemProps, 'linkTo'>>(
  ({ palette }) => {
    return createStyles({
      root: ({ linkTo }) => {
        return {
          color:
            typeof linkTo === 'undefined'
              ? undefined
              : palette.type === 'dark'
              ? palette.common.white
              : palette.common.black
        };
      },
      selected: {}
    });
  }
);
/**
 * Add permission checking, icon keys, navigation, and other features
 * to Material UI menu item component
 * @augments MenuItem
 */
const AppMenuItem = React.forwardRef<any, AppMenuItemProps>(
  (props, ref): JSX.Element | null => {
    const {
      iconKey,
      textPrimary: primary,
      textSecondary: secondary,
      linkTo,
      children,
      button,
      isAdminOnly,
      disabled,
      showLoading,
      icon,
      iconPosition = 'left',
      onClick,
      id,
      secondaryAction,
      isHidden,
      requiredPermissions,
      ...rest
    } = props;
    const isAnyRequestPending = useIsAnyRequestPending();
    const isAdmin = User.useIsAdmin();
    const isDisabled = isAnyRequestPending || disabled;

    const dispatch = useAppDispatch();
    const classes = useStyles({ linkTo });
    const permCheckRes = User.useHasPermissions(requiredPermissions);

    const iconElement = !isNullish(icon) ? (
      icon
    ) : !isNullish(iconKey) ? (
      <LoadingMenuIcon isHidden={!(showLoading === true)}>
        <AppIcon iconKey={iconKey} />
      </LoadingMenuIcon>
    ) : null;
    return isHidden === true || (isAdminOnly === true && !isAdmin) ? null : (
      <MenuItem
        button={button as false}
        classes={classes}
        component={isTruthyString(linkTo) ? Link : `li`}
        data-cy={id}
        disabled={isDisabled}
        id={id}
        ref={ref}
        to={linkTo}
        {...rest}
        onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (permCheckRes.code === 'DENIED') {
            return dispatch(denyPermission(permCheckRes));
          } else if (onClick) {
            return onClick(e);
          }
          return undefined;
        }}
      >
        {iconPosition === 'left' ? iconElement : null}
        {!isNullish(primary) || !isNullish(secondary) ? (
          <ListItemText primary={primary} secondary={secondary} />
        ) : null}
        {children}
        {iconPosition === 'right' ? iconElement : null}
        {!isNullish(secondaryAction) ? (
          <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
        ) : null}
      </MenuItem>
    );
  }
);
AppMenuItem.displayName = 'AppMenuItem';
export type { AppMenuItemProps };
export default AppMenuItem;
