import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import asyncGetUserData from 'src/asyncGetUserData';
import { AppButton, GridWrapper, StyledLink, SubmitBtn } from 'src/components';
import PATHS from 'src/constants/Paths';
import { useThunk } from 'src/hooks';
import FarmUserPermissions from 'src/permissions/FarmUserPermissions';
import PermissionListItem from 'src/permissions/PermissionListItem';
import { handleApiRequestError } from 'src/requests';
import { getActiveFarmId } from 'src/selectors';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import { getActiveFarm, User } from 'src/userSession.reducer';

import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

const Ids = testIds.accountSettings;

interface FarmListItemProps {
  readonly farmName: string;
  readonly farmId: number;
  readonly index: number;
  readonly numFarms: number;
  readonly togglePermissions: () => void;
}

/**
 * @param props
 * @param props.togglePermissions
 * @param props.farmName
 * @param props.farmId
 * @param props.index
 * @param props.numFarms
 */
function FarmListItem({
  togglePermissions,
  farmName,
  farmId,
  index,
  numFarms
}: FarmListItemProps) {
  const isActiveFarm = User.useActiveFarm()?.id === farmId;
  const { sendRequest, isPending } = useThunk(asyncGetUserData);
  const history = useHistory();

  const handleSwitchFarms = async () =>
    sendRequest({ setFarmIdTo: farmId })
      .then(() => {
        toast(farmName, { position: 'top-right' });
        history.push('/');
      })
      .catch(handleApiRequestError);
  return (
    <ListItem
      alignItems="flex-start"
      button={false}
      data-cy={isActiveFarm ? 'active-farm' : `other-farm-${index}`}
      dense
      divider={index !== numFarms - 1}
      selected={isActiveFarm}
    >
      <Grid
        alignItems="baseline"
        component="div"
        container
        justifyContent="space-between"
      >
        <Grid
          alignItems="baseline"
          component="div"
          container
          justifyContent="space-between"
        >
          <Grid item>
            <ListItemText primary={farmName} />
          </Grid>

          {isActiveFarm ? (
            <Grid item>
              <Chip color="primary" label="Active Farm" />
            </Grid>
          ) : null}
        </Grid>
        <Grid container item justifyContent="flex-end">
          {isActiveFarm ? (
            <AppButton
              id={Ids.viewPermissionsBtn}
              onClick={togglePermissions}
              text="view permissions"
            />
          ) : (
            <SubmitBtn
              disabled={isPending}
              id={Ids.switchFarmsBtn}
              onClick={handleSwitchFarms}
              showLoading={isPending && isActiveFarm}
              text="switch"
            />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
}

/**
 * List user's farm accounts along with a link to switch accounts,
 * if more than one exist
 */
export default function AssociatedFarmsList(): JSX.Element {
  const [showPermissions, setShowPermissions] = React.useState(false);

  const permissions = useSelector(User.getPermissions);
  // const activeFarmId = useAppSelector(getActiveFarmId);
  const togglePermissions = () => setShowPermissions(!showPermissions);
  // const { sendRequest, isPending } = useThunk(asyncChangeActiveFarm);
  const otherFarms = useSelector(User.getOtherFarms);
  const activeFarm = useSelector(getActiveFarm);

  const activeFarmName = activeFarm?.name;
  const id = useSelector(getActiveFarmId);
  const handleCloseDialog = () => setShowPermissions(false);
  return (
    <React.Fragment>
      {!isNullish(permissions) ? (
        <Dialog
          data-cy={Ids.farmPermissions}
          id={Ids.farmPermissions}
          onClose={handleCloseDialog}
          open={showPermissions}
          title="Farm Permissions"
        >
          <DialogTitle>Farm Permissions</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can perform the following actions
            </DialogContentText>
            <List>
              {FarmUserPermissions.keys.map((key, index) => (
                <PermissionListItem
                  dense
                  isEnabled={permissions[key]}
                  key={`${key}/${index}`}
                  perm={key}
                />
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <AppButton
              id={testIds.dialog.closeBtn}
              onClick={handleCloseDialog}
              text="Done"
            />
          </DialogActions>
        </Dialog>
      ) : null}
      {isNullish(activeFarmName) || isNullish(id) ? null : (
        <Card data-cy={Ids.associatedFarms.root} id={Ids.associatedFarms.root}>
          <CardHeader
            subheader="Your account is currently linked to the following farms:"
            title="Associated Farms"
          />
          <CardContent>
            <List
              data-cy={Ids.associatedFarms.list}
              id={Ids.associatedFarms.list}
            >
              <FarmListItem
                farmId={id}
                farmName={activeFarmName}
                index={0}
                numFarms={otherFarms.length + 1}
                togglePermissions={togglePermissions}
              />
              {otherFarms.map((f, index) => (
                <FarmListItem
                  farmId={f.id}
                  farmName={f.name}
                  index={index}
                  key={f.id}
                  numFarms={otherFarms.length}
                  togglePermissions={togglePermissions}
                />
              ))}
            </List>
            <GridWrapper justifyContent="space-between" spacing={2}>
              <StyledLink
                id={testIds.createFarm.linkTo}
                linkTo={PATHS.account.newFarm}
                text="New Farm Account"
                variant="body2"
              />
              <StyledLink
                id={testIds.joinFarmWithCode.linkTo}
                linkTo={PATHS.account.joinFarm}
                text="Join Another Farm Account"
                variant="body2"
              />
            </GridWrapper>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
}
