import type {
  CircularProgressProps,
  ListItemIconProps
} from '@material-ui/core';
import { CircularProgress, ListItemIcon } from '@material-ui/core';
import React from 'react';
import type { HideableProps } from 'src/components/types';

type Props = HideableProps &
  ListItemIconProps & {
    readonly progressProps?: CircularProgressProps;
  };

/**
 * @param props
 * @param props.isHidden
 * @param props.children
 * @param props.progressProps
 * @param props.rest
 */
export default function LoadingMenuIcon({
  isHidden,
  children,
  progressProps,
  ...rest
}: Props): JSX.Element {
  return (
    <ListItemIcon {...rest}>
      {isHidden === false ? <CircularProgress {...progressProps} /> : children}
    </ListItemIcon>
  );
}
