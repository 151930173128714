import { Switch } from '@material-ui/core';
import { capitalize } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import type { DeviceNameProp } from 'src/devices';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { AppIcon, showSuccessToast } from 'src/theme';
import {
  asyncToggleNotificationsFromDevices,
  DeviceUserAssociations,
  showAlertsToggleDenied
} from '../device-user/deviceUserAssociations.reducer';
import type { DeviceIdProp } from '../models';

const INPUT_PROPS = {
  id: testIds.alertsToggleSwitch.input
};

type Props = DeviceIdProp &
  DeviceNameProp & {
    isEnabled: boolean;
  };

/**
 * Toggle switch allowing user to enable or disable
 * notifications for a given device
 * @param props
 * @param props.deviceId
 */
const AlertsToggleSwitch = React.forwardRef<any, Props>(
  ({ deviceId, deviceName, isEnabled }, ref) => {
    const dispatch = useAppDispatch();
    const notificationStatus = useSelector((state) =>
      DeviceUserAssociations.getNotificationStatusForDevice(state, deviceId)
    );

    const notificationsOn = isEnabled;
    const handleChange = async () => {
      if (notificationStatus === 'no phone numbers') {
        dispatch(showAlertsToggleDenied());
      } else {
        const action = notificationStatus === 'enabled' ? 'SUPPRESS' : 'ENABLE';
        await dispatch(
          asyncToggleNotificationsFromDevices({
            action,
            ids: [deviceId]
          })
        );
        showSuccessToast(
          `${capitalize(
            `${action.toLocaleLowerCase()}ed`
          )} notifications for ${deviceName}`
        );
      }
    };

    return (
      <Switch
        checked={notificationStatus === 'enabled'}
        data-cy={testIds.alertsToggleSwitch.root}
        icon={
          <AppIcon
            color={notificationsOn ? 'secondary' : 'disabled'}
            iconKey={notificationsOn ? 'NOTIFICATIONS_ON' : 'NOTIFICATIONS_OFF'}
          />
        }
        id={testIds.alertsToggleSwitch.root}
        inputProps={INPUT_PROPS}
        onChange={handleChange}
        ref={ref}
        value={notificationsOn}
      />
    );
  }
);
AlertsToggleSwitch.displayName = 'AlertsToggleSwitch';
export default AlertsToggleSwitch;
