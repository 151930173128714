import type { TypographyProps } from '@material-ui/core';
import React from 'react';
import {
  CancelBtn,
  FormFeedback,
  GridWrapper,
  SubmitBtn
} from 'src/components';
import { SensorStates } from 'src/devices/sensors';
import { useAppSelector, useIsMobile, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import TargetDeviceSelect from 'src/TargetDeviceSelect';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish, notNullish } from 'src/types';
import { isFalsy } from 'utility-types';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { asyncCreateTrigger } from '../actions';
import { GLOBAL_DEVICE_ID } from '../devices.reducer';
import formatTrigger from '../formatTrigger';
import RelayActionSelect from '../RelayActionSelect';
import { useMetadataByDeviceId } from '../selectors';
import SensorSelect from '../SensorSelect';
import StateSelect from '../StateSelect';
import { getTriggerExistsForDevice } from '../triggers.reducer';

import type { ConfigSensorName, GetStatesOfSensor } from 'src/devices/sensors';

import type { DeviceIdProp, Trigger } from '../models';
import type { RelayAction, RelayActionType } from '../RelayAction';
const Ids = testIds.deviceAutomations.create;

type ErrorMessage =
  | 'Target device is required'
  | `Automations can only be triggered when a sensor's state changes`
  | `This automation already exists`;

type State<S extends ConfigSensorName = ConfigSensorName> = Pick<
  Trigger<S>,
  | 'notify'
  | 'sourceSensor'
  | 'sourceSensorStateCurrent'
  | 'sourceSensorStatePrevious'
  | 'targetAction'
  | 'targetDeviceId'
> & {
  showErrorMessage: boolean;
};
/**
 *
 */
function getInitialState(): State {
  return {
    sourceSensor: 'gps',
    sourceSensorStateCurrent: 'A',
    targetDeviceId: '',
    sourceSensorStatePrevious: 'I',
    notify: false,
    showErrorMessage: false,
    targetAction: { actionType: 'RLYA' }
  };
}

/**
 * @param initialState.initialState
 * @param initialState
 * @param initialState.isGlobal
 */
function useCreateActionTrigger<S extends ConfigSensorName>({
  initialState
}: {
  initialState: State<S>;
}) {
  const [formData, setFormData] = React.useState<State<S>>(initialState);

  const handleChooseSensor = (sensorName: S) => {
    const [sourceSensorStateCurrent, sourceSensorStatePrevious] =
      SensorStates.getStatesForSensorName(sensorName);
    if (
      !SensorStates.isStateOfSensor(sensorName, sourceSensorStateCurrent) ||
      !SensorStates.isStateOfSensor(sensorName, sourceSensorStatePrevious)
    ) {
      throw new Error('invalid sensor state');
    }
    return setFormData((prev) => ({
      ...prev,
      sourceSensor: sensorName,
      sourceSensorStateCurrent,
      sourceSensorStatePrevious
    }));
  };
  React.useEffect(() => {
    if (
      !isNullish(formData.sourceSensor) &&
      (isNullish(formData.sourceSensorStateCurrent) ||
        isNullish(formData.sourceSensorStatePrevious))
    ) {
      const [first, second] = SensorStates.getStatesForSensorName(
        formData.sourceSensor
      );

      setFormData((prev) => ({
        ...prev,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sourceSensorStateCurrent: prev.sourceSensorStateCurrent ?? first,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sourceSensorStatePrevious: prev.sourceSensorStatePrevious ?? second
      }));
    }
  }, [
    formData.sourceSensor,
    formData.sourceSensorStateCurrent,
    formData.sourceSensorStatePrevious
  ]);
  const { showErrorMessage, ...rest } = formData;
  return {
    current: formData.sourceSensorStateCurrent,
    /**
     * @param actionType
     */
    handleChooseAction(actionType: RelayActionType): void {
      const targetAction: RelayAction =
        actionType === 'RLYAS'
          ? {
              actionType,
              seconds: 10
            }
          : {
              actionType
            };
      setFormData(
        (prev): State<S> => ({ ...prev, showErrorMessage: false, targetAction })
      );
    },
    /**
     * @param value
     */
    handleChooseTarget(value: string): void {
      setFormData(
        (prev): State<S> => ({
          ...prev,
          targetDeviceId: value,
          showErrorMessage: false
        })
      );
    },
    /**
     * @param value
     */
    handleChooseCurrent(value: GetStatesOfSensor<S>): void {
      setFormData(
        (prev): State<S> => ({
          ...prev,
          sourceSensorStateCurrent: value,
          showErrorMessage: false
        })
      );
    },
    /**
     * @param value
     */
    handleChoosePrevious(value: GetStatesOfSensor<S>): void {
      setFormData(
        (prev): State<S> => ({
          ...prev,
          sourceSensorStatePrevious: value,
          showErrorMessage: false
        })
      );
    },
    handleChooseSensor,
    /**
     *
     */
    handleToggleNotify(): void {
      setFormData(
        (prev): State<S> => ({ ...prev, notify: !(prev.notify === true) })
      );
    },
    previous: formData.sourceSensorStatePrevious,
    sensor: formData.sourceSensor,
    /**
     * @param errorMessage
     */
    toggleErrorMessage(): void {
      setFormData(
        (prev): State<S> => ({
          ...prev,
          showErrorMessage: !prev.showErrorMessage
        })
      );
    },
    showErrorMessage,
    formData: rest
  };
}

/**
 * @param props
 * @param props.variant
 * @param props.rest
 */
const ConnectiveText = React.forwardRef<any, TypographyProps>(
  ({ variant = 'body1', color = 'textSecondary', ...rest }, ref) => {
    return <Typography color={color} ref={ref} variant={variant} {...rest} />;
  }
);
ConnectiveText.displayName = 'ConnectiveText';

/**
 * @param state
 * @param triggerExists
 */
function getErrorMessage(
  state: Omit<State, 'showErrorMessage'>,
  triggerExists: boolean
): ErrorMessage | undefined {
  if (state.sourceSensorStateCurrent === state.sourceSensorStatePrevious) {
    return `Automations can only be triggered when a sensor's state changes`;
  } else if (triggerExists) {
    return `This automation already exists`;
  } else if (isFalsy(state.targetDeviceId)) {
    return 'Target device is required';
  }
  return undefined;
}
// /**
//  * @param state
//  * @param deviceId
//  * @param isGlobal
//  */
// function getFirstSensor(
//   state: RootState,
//   deviceId: string,
//   isGlobal: boolean
// ): SensorName {
//   if (isGlobal) {
//     return 'reel';
//   }
//   const sensors = getConfiguredSensorsByDeviceId(state, deviceId) ?? [];
//   const [first] = sensors;
//   if (isNullish(first)) {
//     return 'gps';
//   }
//   return first;
// }

type Props = DeviceIdProp & {
  onSuccess: () => void;
  onCancel: () => void;
};

/**
 *
 * @param props
 * @param props.deviceId
 * @param props.cancelBtn
 * @param props.onSuccess
 * @param props.onCancel
 * @returns
 */
export default function CreateActionTrigger({
  deviceId: sourceDeviceId,
  onCancel,
  onSuccess
}: Props): JSX.Element | null {
  const metadata = useMetadataByDeviceId(sourceDeviceId);
  const isGlobal = sourceDeviceId === GLOBAL_DEVICE_ID;
  const {
    handleChooseCurrent,
    handleChoosePrevious,
    handleChooseSensor,
    sensor,
    toggleErrorMessage,
    handleToggleNotify,
    current,
    handleChooseAction,
    formData,
    showErrorMessage,
    handleChooseTarget,
    previous
  } = useCreateActionTrigger({
    initialState: getInitialState()
  });
  const isMobile = useIsMobile();

  // const firstSensor: SensorName = useAppSelector((s) =>
  //   getFirstSensor(s, sourceDeviceId, isGlobal)
  // );

  const { sendRequest } = useThunk(asyncCreateTrigger);

  const triggerExists = useAppSelector((state): boolean =>
    getTriggerExistsForDevice(state, sourceDeviceId, {
      ...formData,
      notificationString: ''
    })
  );

  if (isNullish(metadata)) {
    return null;
  }

  const { deviceName } = metadata;
  const errorMessage = getErrorMessage(formData, triggerExists);
  const handleSubmit = async () => {
    if (notNullish(errorMessage)) {
      return toggleErrorMessage();
    }
    if (
      isNullish(sourceDeviceId) ||
      isNullish(sensor) ||
      isNullish(current) ||
      isNullish(previous)
    ) {
      throw new Error(
        `Incomplete args: ${JSON.stringify({
          current,
          previous,
          sensor,
          sourceDeviceId
        })}`
      );
    }

    return sendRequest({
      triggerProperties: {
        ...formData,
        sourceDeviceId,
        notificationString:
          formData.notify === true ? formatTrigger(formData) : null
      }
    })
      .then(() => {
        showSuccessToast('Automation created.');
        return onSuccess();
      })
      .catch(handleApiRequestError);
  };
  const disableSubmit = isNullish(current) || isNullish(previous);
  const notifyText = `Notify me ${isMobile ? '' : 'with a text message'}?`;
  return (
    <Card data-cy={Ids.root} id={Ids.root}>
      <CardHeader
        title={`New automation rule for  ${
          isGlobal ? 'all devices' : deviceName
        }`}
      />

      <CardContent>
        <GridWrapper
          alignItems="center"
          container
          justifyContent="flex-start"
          spacing={3}
        >
          <ConnectiveText>
            When{' '}
            <Typography
              color="primary"
              component="span"
              variant="inherit"
            >{`${deviceName}'s'`}</Typography>
          </ConnectiveText>
          <SensorSelect
            deviceId={sourceDeviceId}
            isGlobal={isGlobal}
            onChange={handleChooseSensor}
            value={sensor}
          />
          <ConnectiveText>changes from</ConnectiveText>
          <StateSelect
            isError={
              showErrorMessage && errorMessage !== 'Target device is required'
            }
            name="statePrevious"
            onChange={handleChoosePrevious}
            sensorName={sensor}
            value={previous}
          />
          <ConnectiveText>to</ConnectiveText>
          <StateSelect
            isError={
              showErrorMessage && errorMessage !== 'Target device is required'
            }
            name="stateCurrent"
            onChange={handleChooseCurrent}
            sensorName={sensor}
            value={current}
          />
          {isGlobal ? null : (
            <TargetDeviceSelect
              error={
                showErrorMessage && errorMessage === 'Target device is required'
              }
              onChange={handleChooseTarget}
              value={formData.targetDeviceId}
              variant="standard"
            />
          )}
          <ConnectiveText>should</ConnectiveText>
          <RelayActionSelect
            onChange={handleChooseAction}
            value={formData.targetAction?.actionType}
          />

          {showErrorMessage && notNullish(errorMessage) ? (
            <Grid item xs={12}>
              <FormFeedback iconKey="error" variant="body2">
                {errorMessage}
              </FormFeedback>
            </Grid>
          ) : null}
        </GridWrapper>
      </CardContent>
      <CardActions>
        {notifyText}
        <Checkbox
          checked={Boolean(formData.notify)}
          data-cy={Ids.notifyCheckbox}
          id={Ids.notifyCheckbox}
          onChange={handleToggleNotify}
        />
        <CancelBtn onClick={onCancel} />
        <SubmitBtn
          disabled={disableSubmit}
          id={Ids.submitBtn}
          onClick={handleSubmit}
        />
      </CardActions>
    </Card>
  );
}
