import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';
import { INVALID_CODE_MESSAGE } from 'src/auth/errors';
import {
  AppTextField,
  AppButton,
  FormFeedback,
  GridWrapper,
  Instructions,
  SubmitBtn
} from 'src/components';
import { useThunk } from 'src/hooks';
import useAppSelector from 'src/hooks/useAppSelector';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';
import {
  asyncConfirmSmsVerification,
  asyncGetPhoneVerificationCode,
  formatPhoneNumberDigits,
  Upn
} from './phoneNumbers.reducer';

/**
 * User verifies unverified number by entering a code generated and sent by Twilio
 *
 * @param props
 * @param props.upnId
 */
function VerifyPhoneNumber({ upnId }: { upnId: number }) {
  const [{ codeString, invalidCode }, setState] = React.useState<{
    codeString: string;
    invalidCode: boolean;
  }>({ codeString: '', invalidCode: false });
  const verify = useThunk(asyncConfirmSmsVerification);
  const getCode = useThunk(asyncGetPhoneVerificationCode);
  const disableGetCode = getCode.isPending;
  const disableSubmit =
    codeString.length !== 6 || verify.isPending || disableGetCode;

  /**
   * Error is cleared on input change
   * @param e
   */
  const handleCodeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void =>
    setState({
      codeString: e.target.value.toLocaleUpperCase(),
      invalidCode: false
    });
  /**
   * Send request to API, which sends request to Twilio, which
   * sends a verification code to the user's phone
   */
  const handleResendCode = () =>
    getCode
      .sendRequest({ upnId })
      .then(() => showSuccessToast('Code sent'))
      .catch(handleApiRequestError);

  /**
   * Checks the code to ensure that is matches, then marks the number as
   * verified. If the response is 'INVALID', show an error message
   */
  const handleSubmitCode = () =>
    verify
      .sendRequest({ upnId, codeString })
      .then((response) => {
        if (response === 'INVALID') {
          setState((prev) => ({ ...prev, invalidCode: true }));
        } else {
          showSuccessToast();
        }
      })
      .catch(handleApiRequestError);
  const Ids = testIds.phoneNumbers.verify;
  return (
    <Grid container direction="column" spacing={1} style={{ flex: 1 }}>
      <Instructions>Enter the code we sent to this phone number</Instructions>
      <AppTextField
        fullWidth
        id="codeString"
        name="codeString"
        onChange={handleCodeInput}
        value={codeString}
      />
      {invalidCode ? <FormFeedback>{INVALID_CODE_MESSAGE}</FormFeedback> : null}
      <Box my={2} />
      <GridWrapper>
        <AppButton
          disabled={disableGetCode}
          id={Ids.resendCodeBtn}
          onClick={handleResendCode}
          text="Resend Code"
        />
        <SubmitBtn
          color="secondary"
          disabled={disableSubmit}
          id={Ids.submitCodeBtn}
          onClick={handleSubmitCode}
          variant="contained"
        />
      </GridWrapper>
    </Grid>
  );
}

type Props = {
  upnId: number;
  isVerifying: boolean;
  index: number;
};

/**
 * Shows serialized phone number along with verification status.
 * User can verify an unverified phone number by clicking 'verify' indicator.
 *
 * @augments ListItem
 * @param props
 * @param props.upnId
 * @param props.index
 * @param props.onClickVerify
 * @param props.isVerifying
 */
export default function PhoneNumberListItem({
  upnId,
  index,
  isVerifying
}: Props): JSX.Element {
  const { sendRequest, isPending } = useThunk(asyncGetPhoneVerificationCode);
  const number = useAppSelector((state) => Upn.selectById(state, upnId));
  if (isNullish(number)) {
    throw new Error(`Not found`);
  }
  const { digits, isVerified } = number;
  const serialized = formatPhoneNumberDigits({ digits });
  const Ids = testIds.phoneNumbers.list;
  const handleClickVerify = () =>
    sendRequest({ upnId })
      .then(() => showSuccessToast('Code sent.'))
      .catch(handleApiRequestError);
  return (
    <ListItem
      alignItems="center"
      data-cy={Ids.item(index)}
      divider
      id={Ids.item(index)}
      selected={isVerifying}
    >
      {isPending ? (
        <Box height={50} width="100%">
          <CircularProgress />
        </Box>
      ) : isVerifying ? (
        <VerifyPhoneNumber upnId={upnId} />
      ) : (
        <React.Fragment>
          <ListItemText primary={serialized} />
          <ListItemSecondaryAction>
            {isVerified ? (
              <Chip
                data-cy={Ids.verifiedBadge}
                icon={<Check color="primary" />}
                id={Ids.verifiedBadge}
                label="Verified"
              />
            ) : (
              <Chip
                clickable
                color="secondary"
                data-cy={Ids.verifyBtn}
                id={Ids.verifyBtn}
                label="Verify"
                onClick={handleClickVerify}
                size="medium"
              />
            )}
          </ListItemSecondaryAction>
        </React.Fragment>
      )}
    </ListItem>
  );
}
