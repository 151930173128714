import type {
  Dictionary,
  EntityState,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit';
import { isString } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import Actions from 'src/actions';
import { getActiveFeature, getSelectedDeviceId } from 'src/appSelectors';
import asyncGetUserData from 'src/asyncGetUserData';
import { Geo } from 'src/geo';
import { useAppSelector } from 'src/hooks';
import { isNullish, isTruthyString, notNullish } from 'src/types';

import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import {
  asyncCreateNotification,
  asyncCreatePair,
  asyncCreateTrigger,
  asyncCustomizeNotificationString,
  asyncDeleteTriggers,
  togglePartnerCallout
} from './actions';
import INSTALLATION_TYPE from './installation-type/InstallationTypes';
import { isActionTrigger, isDevicePair, isNotificationTrigger } from './models';
import RelayActions from './RelayAction';
import { getAllDevices, getDeviceMetadataById } from './selectors';
import {
  CONFIG_SENSOR_NAMES,
  isValidConfigSensorName,
  SensorStates
} from './sensors';

import type { LatLng } from 'src/geo';
import type { RootSelector, RootState } from 'src/store';
import type {
  ActionTrigger,
  DeviceConfig,
  DevicePair,
  NotificationTrigger,
  PropInstallationType,
  Trigger,
  TriggerProperties
} from './models';
import type { ConfigSensorName } from './sensors';
/**
 * @param t
 * @param deviceId
 */
export function isSourceDeviceInTrigger(t: Trigger, deviceId: string): boolean {
  return t.sourceDeviceId === deviceId;
}
/**
 * @param t
 * @param deviceId
 */
export function isTargetDeviceInTrigger(t: Trigger, deviceId: string): boolean {
  return t.targetDeviceId === deviceId;
}

/**
 * @param t
 * @param deviceId
 */
export function deviceIsInTrigger(t: Trigger, deviceId: string): boolean {
  return (
    isSourceDeviceInTrigger(t, deviceId) || isTargetDeviceInTrigger(t, deviceId)
  );
}

/**
 * Return true if the device is a 'source' in a device pair
 * @param d
 */
export function deviceIsValidTriggerSource<T extends PropInstallationType>(
  d: T
): boolean {
  return (
    d.deviceInstallationType === INSTALLATION_TYPE.reel ||
    d.deviceInstallationType === INSTALLATION_TYPE.softHoseTraveller
  );
}

interface TriggersState extends EntityState<Trigger> {
  isPairing: boolean;
  selectedPairId: number | null;
  partnerId?: string;
  showPartnerCallout: boolean;
}
/**
 * @param state
 */
function getSlice(state: RootState): TriggersState {
  return state.triggers;
}
const adapter = createEntityAdapter<Trigger>({
  selectId: (t) => t.id,
  sortComparer: (a, b) => a.sourceSensor.localeCompare(b.sourceSensor)
});

const triggerSelectors = adapter.getSelectors(
  (state: RootState) => state.triggers
);
export const getTriggerIds = triggerSelectors.selectIds as RootSelector<
  number[]
>;

export const { selectById: selectTriggerById, selectAll } =
  adapter.getSelectors(getSlice);
export const getTriggerById: RootSelector<Trigger | undefined, [id: number]> =
  createCachedSelector(selectTriggerById, (trigger) => trigger)((_, id) => id);

export const getSelectedPartnerId = createSelector(
  getSlice,
  (state): string | undefined => state.partnerId
);
export const getSelectedPairId = createSelector(
  getSlice,
  (state): number | null => state.selectedPairId
);

/**
 *
 */
export function useSelectedPairId(): number | null {
  return useAppSelector(getSelectedPairId);
}

/**
 * @param state
 */
export function getSelectedPair(state: RootState): DevicePair | undefined {
  const id = getSelectedPairId(state);
  if (notNullish(id)) {
    const trigger = getTriggerById(state, id);
    if (notNullish(trigger) && isDevicePair(trigger)) {
      return trigger;
    }
  }
  return undefined;
}

export const useSelectedPair = (): DevicePair | undefined =>
  useAppSelector(getSelectedPair);

type PairLocationData = {
  path: LatLng[] | undefined;
  source: {
    id: string;
    location: LatLng | undefined;
  };
  target: {
    id: string;
    location: LatLng | undefined;
  };
};

export const getSelectedPairLocations: RootSelector<
  PairLocationData | undefined
> = createSelector(
  getSelectedPair,
  (state: RootState) => state.deviceEventLast.entities,
  (pair, events) => {
    if (notNullish(pair)) {
      const { sourceDeviceId, targetDeviceId } = pair;
      const sourceLocation = events[sourceDeviceId]?.gps?.location;
      const targetLocation = events[targetDeviceId]?.gps?.location;

      const sourceLatLng = notNullish(sourceLocation)
        ? Geo.points.to.googleMaps(sourceLocation)
        : undefined;
      const targetLatLng = notNullish(targetLocation)
        ? Geo.points.to.googleMaps(targetLocation)
        : undefined;

      if (notNullish(sourceLatLng) && notNullish(targetLatLng)) {
        return {
          path: [sourceLatLng, targetLatLng],
          target: {
            id: targetDeviceId,
            location: targetLatLng
          },
          source: {
            id: sourceDeviceId,
            location: sourceLatLng
          }
        };
      }
    }
    return undefined;
  }
);
export const useSelectedPairLocations = (): PairLocationData | undefined =>
  useAppSelector(getSelectedPairLocations);

const initialState: TriggersState = adapter.getInitialState({
  isPairing: false,
  pairing: null,
  selectedPairId: null,
  showPartnerCallout: false
});

const slice = createSlice({
  /**
   * @param builder
   */
  extraReducers: (builder) =>
    builder
      .addCase(Actions.resetToDefaultState, (state) => {
        state.selectedPairId = null;
      })
      .addCase(asyncCustomizeNotificationString.fulfilled, (state, action) => {
        const { notificationString, triggerId } = action.meta.arg;
        adapter.updateOne(state, {
          changes: { notificationString } as NotificationTrigger,
          id: triggerId
        });
      })
      .addCase(togglePartnerCallout, (state, action) => {
        state.showPartnerCallout = !state.showPartnerCallout;
        state.partnerId = action.payload;
      })
      .addCase(asyncCreateNotification.fulfilled, (state, { payload }) => {
        adapter.upsertMany(state, [...payload.items]);
      })
      .addCase(asyncGetUserData.pending, (state) => {
        adapter.removeAll(state);
      })
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        if (payload.activeFarm?.triggers) {
          adapter.setAll(state, [...payload.activeFarm.triggers]);
        }
      })
      .addCase(asyncCreatePair.fulfilled, (state, { payload }) => {
        const pairs = [...payload.items];
        adapter.upsertMany(state, pairs);
        state.partnerId = undefined;
        state.showPartnerCallout = false;
        state.isPairing = false;
        state.selectedPairId = pairs[0]?.id ?? null;
      })
      .addCase(asyncCreateTrigger.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, payload);
      })
      .addCase(asyncDeleteTriggers.fulfilled, (state, { meta }) => {
        adapter.removeMany(state, meta.arg.triggerIds);
      }),
  // .addCase(deleteTriggers.request.fulfilled, (state, action) => {
  //   triggersAdapter.removeMany(state, [...action.meta.arg.triggerIds]);
  // }),
  initialState,
  name: `triggers`,
  reducers: {
    /**
     * @param state
     */
    cancelPairing(state) {
      state.isPairing = false;
      state.partnerId = undefined;
    },

    /**
     * @param state
     */
    clickNewDevicePair(state) {
      state.isPairing = true;
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    selectPartnerDevice(state, { payload }: PayloadAction<string>) {
      state.partnerId = payload;
      state.showPartnerCallout = true;
    },
    /**
     * @param state
     * @param args
     * @param args.payload
     */
    selectPair(state, { payload }: PayloadAction<number>) {
      state.selectedPairId = payload;
    }
  }
});

const { actions } = slice;

/**
 * @param t
 */
function serializeTrigger(t: TriggerProperties): string {
  return `${t.sourceSensor}/${t.sourceSensorStatePrevious}/${
    t.sourceSensorStateCurrent
  }/${
    t.targetAction ? RelayActions.serializeRelayAction(t.targetAction) : ''
  }/${t.targetDeviceId ?? ''}/${t.notify === true ? 'true' : 'false'}`;
}
/**
 * @param t
 */
export function parseTrigger(
  t: string
): Omit<Trigger, 'id' | 'notificationString' | 'sourceDeviceId'> | undefined {
  const [
    sourceSensor,
    sourceSensorStatePrevious,
    sourceSensorStateCurrent,
    targetAction,
    targetDeviceId,
    notify
  ] = t.split('/');
  if (
    isValidConfigSensorName(sourceSensor) &&
    SensorStates.isStateOfSensor(sourceSensor, sourceSensorStateCurrent) &&
    SensorStates.isStateOfSensor(sourceSensor, sourceSensorStatePrevious)
  ) {
    const relayAction = isString(targetAction)
      ? RelayActions.parseRelayAction(targetAction)
      : null;
    return {
      notify: notify === 'true' ? true : false,
      sourceSensor,
      sourceSensorStateCurrent,
      sourceSensorStatePrevious,
      targetAction: relayAction ?? null,
      targetDeviceId
    };
  }
  return undefined;
}

/**
 * @param _state
 * @param deviceId
 */
function deviceIdInput(_state: RootState, deviceId: string): string {
  return deviceId;
}
/**
 * @param state
 */
function deviceEntityInput(state: RootState): Dictionary<DeviceConfig> {
  return state.devices.entities;
}
/**
 * @param state
 */
function deviceIdsInput(state: RootState): string[] {
  return state.devices.ids as string[];
}

export const getSelectedDeviceTriggers = createSelector(
  getSelectedDeviceId,
  selectAll,
  (deviceId, triggers): Trigger[] =>
    isTruthyString(deviceId)
      ? triggers.filter((t) => deviceIsInTrigger(t, deviceId))
      : []
);

/**
 * @param id
 */
export function useTriggerById(id: number): Trigger | undefined {
  return useAppSelector((state) => getTriggerById(state, id));
}
/**
 * @param id
 */
export function useDevicePairById(id: number): DevicePair | null {
  const pair = useTriggerById(id);
  if (pair && isDevicePair(pair)) {
    return pair;
  }
  return null;
}

type TriggerFilterKey =
  | 'ACTION_TRIGGER'
  | 'ALL'
  | 'DEVICE_PAIR'
  | 'NOTIFICATION';

type GetTriggerType<T extends TriggerFilterKey> = T extends T
  ? T extends 'NOTIFICATION'
    ? NotificationTrigger
    : T extends 'DEVICE_PAIR'
    ? DevicePair
    : T extends 'ACTION_TRIGGER'
    ? ActionTrigger
    : T extends 'ALL'
    ? Trigger
    : never
  : never;
export type TriggerList<K extends TriggerFilterKey> = Array<GetTriggerType<K>>;

/**
 * @param filterKey
 */
function makeFilteredTriggerSelector<K extends TriggerFilterKey>(filterKey: K) {
  const selector: RootSelector<TriggerList<K>> = createSelector(
    selectAll,
    (triggers) => {
      let filter: (t: Trigger) => boolean;
      switch (filterKey) {
        case 'ALL':
          filter = () => true;
          break;
        case 'ACTION_TRIGGER':
          filter = isActionTrigger;
          break;
        case 'DEVICE_PAIR':
          filter = isDevicePair;
          break;
        case 'NOTIFICATION':
          filter = isNotificationTrigger;
          break;
        default:
          throw new Error(`Invalid filter key: ${filterKey}`);
      }
      return triggers.filter(filter) as Array<GetTriggerType<K>>;
    }
  );
  return selector;
}

/**
 * Get all triggers with a target action and target device id
 * @param t
 */
export const getActionTriggers = makeFilteredTriggerSelector('ACTION_TRIGGER');
export const getActionTriggersForDevice: RootSelector<
  ActionTrigger[],
  [deviceId: string]
> = createCachedSelector(
  deviceIdInput,
  (state: RootState) => getActionTriggers(state),
  (id, triggers) => triggers.filter((p) => deviceIsInTrigger(p, id))
)(deviceIdInput);
/**
 * Get all actions triggers between a stopped reel and a pump
 * @param t
 */
export const getPairs = makeFilteredTriggerSelector('DEVICE_PAIR');
export const getPairsForDevice: RootSelector<DevicePair[], [deviceId: string]> =
  createCachedSelector(
    deviceIdInput,
    (state: RootState) => getPairs(state),
    (id, pairs) => pairs.filter((p) => deviceIsInTrigger(p, id))
  )((_state, deviceId) => deviceId);
/**
 * Get all triggers that send notifications
 * @param t
 */
export const getNotifications = makeFilteredTriggerSelector('NOTIFICATION');
export const getNotificationsForDevice: RootSelector<
  NotificationTrigger[],
  [deviceId: string]
> = createCachedSelector(
  deviceIdInput,
  (state: RootState) => getNotifications(state),
  (id, triggers) => triggers.filter((p) => deviceIsInTrigger(p, id))
)(deviceIdInput);

export const getAllTriggers = makeFilteredTriggerSelector('ALL');
export const getTriggersForDevice: RootSelector<Trigger[], [deviceId: string]> =
  createCachedSelector(
    deviceIdInput,
    (state: RootState) => getAllTriggers(state),
    (id, triggers) => triggers.filter((p) => deviceIsInTrigger(p, id))
  )(deviceIdInput);

/**
 * @param id
 * @param key
 */
export function useTriggersForDevice<
  K extends TriggerFilterKey,
  R extends TriggerList<K> = TriggerList<K>
>(id: string, key: K): R {
  return useAppSelector((state): R => {
    let result: R = [] as unknown as R;
    switch (key) {
      case 'ACTION_TRIGGER': {
        result = getActionTriggersForDevice(state, id) as R;
        break;
      }
      case 'DEVICE_PAIR': {
        result = getPairsForDevice(state, id) as R;
        break;
      }
      case 'NOTIFICATION': {
        result = getNotificationsForDevice(state, id) as R;
        break;
      }
      case 'ALL': {
        result = getTriggersForDevice(state, id) as R;
        break;
      }
      default: {
        throw new Error('Invalid trigger key');
      }
    }
    return result;
  });
}

/**
 *
 */
export const getTriggerExistsForDevice: RootSelector<
  boolean,
  [id: string, props: TriggerProperties]
> = createCachedSelector(
  getTriggersForDevice,
  (_state: RootState, _deviceId: string, properties: TriggerProperties) =>
    properties,
  (triggers, properties) => {
    const serialized = serializeTrigger(properties);
    const match = triggers.find((t) => {
      const next = serializeTrigger(t);
      return serialized === next;
    });

    return Boolean(match);
  }
)((_, id, st: TriggerProperties) => `${id}/${serializeTrigger(st)}`);

// const getGlobalTriggers = createCachedSelector(
//   (state: RootState, key: TriggerFilterKey) =>

//   (triggers): GlobalTrigger[] => {
//     return triggers.filter(
//       (t) => t.sourceDeviceId === 'GLOBAL'
//     ) as GlobalTrigger[];
//   }
// )((_, key) => key);

/**
 * TODO: figure out how to identify triggers as 'global'
 * @param key
 */
// export function useGlobalTriggers(key: TriggerFilterKey): GlobalTrigger[] {
//   return useAppSelector((state) => getGlobalTriggers(state, key));
// }
/**
 * Get all triggers for the selected device
 */
export function useSelectedDeviceTriggers(): Trigger[] {
  return useAppSelector(getSelectedDeviceTriggers);
}
export const getDevicePartnerIsSelected: RootSelector<
  boolean,
  [deviceId: string]
> = createCachedSelector(
  getSelectedDeviceId,
  deviceIdInput,
  getPairsForDevice,
  (selectedId, ownId, pairs) => {
    if (notNullish(selectedId) && pairs.length > 0) {
      const selected = pairs.find((p) => {
        if (p.sourceDeviceId === ownId) {
          return selectedId === p.targetDeviceId;
        } else if (p.targetDeviceId === ownId) {
          return selectedId === p.sourceDeviceId;
        }
        return undefined;
      });
      return notNullish(selected);
    }
    return false;
  }
)(deviceIdInput);

type TargetOption = Pick<
  DeviceConfig,
  'deviceId' | 'deviceInstallationType' | 'deviceName'
> & {
  configuredSensorNames: ConfigSensorName[];
};

/**
 * @param ownId
 * @param deviceIds
 * @param configs
 */
export function findTargetsForActionTrigger(
  ownId: string,
  deviceIds: string[],
  configs: Dictionary<DeviceConfig>
): TargetOption[] {
  const ownConfig = configs[ownId];
  if (isNullish(ownConfig)) {
    return [];
  }
  const initializer = [] as TargetOption[];
  const isSource = deviceIsValidTriggerSource(ownConfig);
  return deviceIds.reduce<TargetOption[]>((acc, targetId) => {
    if (targetId === ownId) {
      return acc;
    }
    const otherConfig = configs[targetId];
    if (isNullish(otherConfig)) {
      return acc;
    }
    if (isSource) {
      if (deviceIsValidTriggerSource(otherConfig)) {
        return acc;
      }
    }
    const { deviceInstallationType, deviceName, ...rest } = otherConfig;
    return [
      ...acc,
      {
        deviceName,
        deviceInstallationType,
        deviceId: targetId,
        configuredSensorNames: CONFIG_SENSOR_NAMES.filter((sn) => {
          return notNullish(rest[sn]);
        })
      }
    ];
  }, initializer);
}
export const getPairingOptionsByDeviceId = createSelector(
  getAllDevices,
  getDeviceMetadataById,
  getPairsForDevice,
  (configs, metadata, currentPairs) => {
    const { deviceId, deviceInstallationType } = metadata ?? {};
    return configs
      .filter((targetConfig) => {
        const targetId = targetConfig.deviceId;

        // if the device id is the same as the target, return false
        if (targetId === deviceId) {
          return false;
        }

        if (targetConfig?.deviceInstallationType === deviceInstallationType) {
          return false;
        }
        if (
          deviceInstallationType === 'reel' ||
          deviceInstallationType === 'traveller_soft'
        ) {
          if (
            targetConfig?.deviceInstallationType === 'reel' ||
            targetConfig?.deviceInstallationType === 'traveller_soft'
          ) {
            return false;
          }
        }
        if (currentPairs.length === 0) {
          return true;
        }

        const alreadyPaired = Boolean(
          currentPairs.find((p) => {
            if (deviceInstallationType === 'pump') {
              return p.targetDeviceId === targetId;
            }
            return p.sourceDeviceId === targetId;
          })
        );

        if (notNullish(alreadyPaired)) {
          return false;
        }
        return true;
      })
      .map((dc) => dc.deviceId);
  }
);

export const getDeviceIsValidPairingOption = createCachedSelector(
  getActiveFeature,
  (state: RootState) => state.triggers.isPairing,
  getPairingOptionsByDeviceId,
  deviceIdInput,
  (activeFeature, isPairing, options, id) => {
    return activeFeature === 'AUTOMATIONS' && isPairing && options.includes(id);
  }
)((_, deviceId) => {
  // console.log(deviceId);
  return deviceId;
});
export const getTargetsForActionTrigger: RootSelector<
  TargetOption[],
  [deviceId: string]
> = createCachedSelector(
  deviceIdInput,
  deviceIdsInput,
  deviceEntityInput,
  findTargetsForActionTrigger
)(deviceIdInput);

const triggers: Reducer<TriggersState> = slice.reducer;

export const Triggers = {
  ...actions
};

export default triggers;
