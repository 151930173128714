import { createCachedSelector } from 're-reselect';
import { getSelectedDeviceId } from 'src/appSelectors';
import {
  listConfigSensors,
  listEventSensors,
  SENSOR_NAMES
} from 'src/devices/sensors';
import { Geo } from 'src/geo';
import { useAppSelector } from 'src/hooks';
import { isNullish, notNullish } from 'src/types';

import { createSelector } from '@reduxjs/toolkit';

import { deviceAdapter, eventsAdapter } from './models';

import type {
  AnySensorConfig,
  AnySensorEvent,
  ConfigSensorName,
  SensorConfig,
  SensorEvent,
  SensorName
} from 'src/devices/sensors';
import type { LatLng, PointGeoJson } from 'src/geo';
import type { RootSelector, RootState } from 'src/store';
import type { InstallationType } from './installation-type';
import type { DeviceConfig, DeviceEvent, DeviceMetadata } from './models';
/**
 * @param _state
 * @param id
 */
function deviceIdInput(_state: RootState, id: string): string {
  return id;
}
/**
 * @param _state
 * @param _id
 * @param sensorName
 */
function configSensorNameInput(
  _state: RootState,
  _id: string,
  sensorName: ConfigSensorName
): ConfigSensorName {
  return sensorName;
}

/**
 * @param _state
 * @param _id
 * @param sensorName
 */
function eventSensorNameInput(
  _state: RootState,
  _id: string,
  sensorName: SensorName
): SensorName {
  return sensorName;
}
/**
 * @param _
 * @param installationType
 */
function installationTypeInput(
  _: RootState,
  installationType: InstallationType
) {
  return installationType;
}

const configSelectors = deviceAdapter.getSelectors(
  (state: RootState) => state.devices
);
export const getConfigById = configSelectors.selectById as RootSelector<
  DeviceConfig | undefined,
  [deviceId: string]
>;
export const getAllDevices = configSelectors.selectAll;
export const getAllDeviceIds = configSelectors.selectIds as RootSelector<
  string[]
>;

export const getConfigEntities = configSelectors.selectEntities;

const eventSelectors = eventsAdapter.getSelectors(
  (state: RootState) => state.deviceEventLast
);
const getLastEventById = eventSelectors.selectById as RootSelector<
  DeviceEvent | undefined,
  [deviceId: string]
>;
export const getLastEventIds = eventSelectors.selectIds as RootSelector<
  string[]
>;

export const getEventByDeviceId: RootSelector<
  DeviceEvent | undefined,
  [deviceId: string]
> = createCachedSelector(
  getLastEventById,
  (event) => event ?? undefined
)((_state, deviceId) => deviceId);

/**
 * Cached selector for device configuration
 * @param deviceId
 */
export const getConfigByDeviceId: RootSelector<
  DeviceConfig | undefined,
  [deviceId: string]
> = createCachedSelector(
  (state: RootState, deviceId: string) => getConfigById(state, deviceId),
  (config) => config
)((_state, deviceId) => deviceId);

/**
 * Get alias, deviceId, installation type and name for a device configuration if
 * it exists in state
 *
 * TODO: flesh out 'global' device entity for create global triggers etc.
 * @param id
 */
export const getDeviceMetadataById = createCachedSelector(
  deviceIdInput,
  getConfigByDeviceId,
  (id, config): DeviceMetadata | undefined => {
    if (id === 'GLOBAL') {
      return {
        codaDeviceAlias: 'GLOBAL',
        deviceId: 'GLOBAL',
        deviceInstallationType: 'unconfigured',
        deviceName: 'Global'
      };
    }
    if (config) {
      const { codaDeviceAlias, deviceId, deviceInstallationType, deviceName } =
        config;
      return {
        codaDeviceAlias,
        deviceId,
        deviceInstallationType,
        deviceName
      };
    }
    return undefined;
  }
)((_state, deviceId) => deviceId);
/**
 * Get metadata for the device id id currently clicked by the us
 * @param state
 */
export function getSelectedDeviceMetadata(
  state: RootState
): DeviceMetadata | undefined {
  const id = getSelectedDeviceId(state);
  if (notNullish(id)) {
    return getDeviceMetadataById(state, id);
  }
  return undefined;
}

/**
 * Get alias, deviceId, installation type and name for a device configuration if
 * it exists in state
 * @param id
 */
export function useMetadataByDeviceId(
  id: string | 'GLOBAL'
): DeviceMetadata | undefined {
  return useAppSelector((state) => {
    return getDeviceMetadataById(state, id);
  });
}

/**
 * @param _state
 * @param id
 * @param sensorName
 */
function makeSensorCacheKey(
  _state: RootState,
  id: string,
  sensorName: SensorName
): `${string}/${SensorName}` {
  return `${id}/${sensorName}` as const;
}

export const getSensorConfigByDeviceId: RootSelector<
  AnySensorConfig | null | undefined,
  [deviceId: string, sensorName: ConfigSensorName]
> = createCachedSelector(
  getConfigByDeviceId,
  configSensorNameInput,
  (config, sensorName) => {
    if (notNullish(config)) {
      return config[sensorName];
    }
    return undefined;
  }
)(makeSensorCacheKey);
/**
 * @param deviceId
 * @param sensorName
 */
export function useSensorConfigByDeviceId<S extends ConfigSensorName>(
  sensorName: S,
  deviceId: string
): SensorConfig<S> | null {
  const sensorData = useAppSelector((state) =>
    getSensorConfigByDeviceId(state, deviceId, sensorName)
  );
  if (!isNullish(sensorData)) {
    return sensorData as unknown as SensorConfig<S>;
  }
  return null;
}

/**
 * Find best sensor to display to user base on installation type
 * @param  deviceId - id to look up configuration
 */
export const getPrimaryEventSensorName: RootSelector<
  SensorName,
  [deviceId: string]
> = createCachedSelector(
  getEventByDeviceId,
  getConfigByDeviceId,
  (event, config) => {
    let result: SensorName = 'gps';
    const installationType = config?.deviceInstallationType;
    if (event) {
      const sensorNames = listEventSensors(event);
      let priorityList: SensorName[];
      if (installationType === 'reel') {
        priorityList = ['reel', 'pressure', 'gps'];
      } else if (installationType === 'traveller_soft') {
        priorityList = ['wheel', 'pressure', 'gps'];
      } else if (installationType === 'pump') {
        priorityList = ['pressure', 'pressureSwitch'];
      } else {
        priorityList = [...SENSOR_NAMES];
      }
      return priorityList.find((sn) => sensorNames.includes(sn)) ?? 'gps';
    }
    return result;
  }
)(deviceIdInput);

export const getSensorEventByDeviceId: RootSelector<
  AnySensorEvent | null | undefined,
  [deviceId: string, sensorName: SensorName]
> = createCachedSelector(
  getEventByDeviceId,
  eventSensorNameInput,
  (event, sensorName) => {
    if (notNullish(event)) {
      return event[sensorName];
    }
    return undefined;
  }
)(makeSensorCacheKey);
/**
 * Get sensor data for last event for the given device id if it exists
 * @param sensorName
 * @param deviceId
 */
export function useSensorEventByDeviceId<S extends SensorName>(
  sensorName: S,
  deviceId: string
): SensorEvent<S> | null {
  const sensorData = useAppSelector((state) =>
    getSensorEventByDeviceId(state, deviceId, sensorName)
  ) as SensorEvent<S> | null;
  if (!isNullish(sensorData)) {
    return sensorData;
  }
  return null;
}

export const getLastEventLocationByIdGeoJson: RootSelector<
  PointGeoJson | null | undefined,
  [deviceId: string]
> = createCachedSelector(
  (state: RootState, id: string) => getSensorEventByDeviceId(state, id, 'gps'),
  (event) => {
    if (event?.sensorName === 'gps') {
      return event.location === null ? undefined : event.location;
    }
    return undefined;
  }
)(deviceIdInput);

export const getLastEventLocationById: RootSelector<
  LatLng | null | undefined,
  [deviceId: string]
> = createCachedSelector(getLastEventLocationByIdGeoJson, (location) => {
  if (location) {
    return Geo.points.to.googleMaps(location);
  }
  return undefined;
})(deviceIdInput);

export const getSelectedEventLocation = createSelector(
  getSelectedDeviceId,
  eventSelectors.selectEntities,
  (id, events): LatLng | null | undefined => {
    if (notNullish(id)) {
      const location = events[id]?.gps?.location;
      return notNullish(location) ? Geo.points.to.googleMaps(location) : null;
    }
    return undefined;
  }
);

/**
 * Get number of devices for installation type
 */
export const getDeviceCountCached: RootSelector<
  number,
  [kind: InstallationType]
> = createCachedSelector(
  installationTypeInput,
  getAllDevices,
  (kind, devices) => {
    const initializer = {} as { [key in InstallationType]: number };
    return devices.reduce((acc, dc) => {
      const key = dc.deviceInstallationType ?? 'unconfigured';
      const current = acc[key];
      if (typeof current === 'undefined') {
        return { ...acc, [key]: 0 };
      }
      return {
        ...acc,
        [key]: acc[key] + 1
      };
    }, initializer)[kind];
  }
)(installationTypeInput);

export const getConfiguredSensorsByDeviceId: RootSelector<
  ConfigSensorName[] | null,
  [deviceId: string]
> = createCachedSelector(getConfigByDeviceId, (config) => {
  if (!isNullish(config)) {
    return listConfigSensors(config);
  }
  return null;
})(deviceIdInput);
