import type { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { INSTALLATION_TYPES } from 'src/devices/installation-type/InstallationTypes';
import type { RootState } from 'src/root.reducer';
import { COMPONENT_STYLES } from 'src/theme';
import type { ValuesType } from 'utility-types';
import type { PointGeoJson } from '../geo';
import type { GmapsLatLngBoundsLiteral } from '../geo/bounds';
import ZOOM_THRESHOLDS from './ZoomThresholds';

const MAP_ITEM_TYPES = ['device', 'field', 'reelRun'] as const;
type MapItemType = typeof MAP_ITEM_TYPES[number];

const MAP_TYPE_ID = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain'
} as const;
type MapTypeId = ValuesType<typeof MAP_TYPE_ID>;
const MAP_TYPE_IDS = Object.values(MAP_TYPE_ID);

const STATUS_MAP_VISIBILITY_KEYS = [
  ...INSTALLATION_TYPES,
  ...MAP_ITEM_TYPES,
  'fieldLabel',
  'pairLine',
  'directionArrow'
] as const;
type StatusMapVisibilityKey = typeof STATUS_MAP_VISIBILITY_KEYS[number];
type StatusMapVisibility = {
  readonly [key in StatusMapVisibilityKey]: boolean;
};
/**
 * @param zoomLevel
 */
function getInitialVisibility(zoomLevel?: number): StatusMapVisibility {
  const current = zoomLevel ?? ZOOM_THRESHOLDS.mapDefault;
  return {
    device: true,
    directionArrow: true,
    field: true,
    fieldLabel: current > ZOOM_THRESHOLDS.fieldLabel,
    pairLine: true,
    prototype: true,
    pump: true,
    reel: true,
    reelRun: true,
    traveller_soft: true,
    unconfigured: true
  };
}

type StatusMapState = {
  currentListView: MapItemType;
  fieldColor: string;
  currentZoom: number;
  mapBounds?: GmapsLatLngBoundsLiteral;
  mapCenter?: PointGeoJson;
  searchValue?: string;
  mapTypeId: MapTypeId;
  visibility: StatusMapVisibility;
};
const initialState: StatusMapState = {
  currentListView: 'device',
  currentZoom: ZOOM_THRESHOLDS.mapDefault,
  fieldColor: COMPONENT_STYLES.fields.polygon.color,
  mapTypeId: 'satellite' as google.maps.MapTypeId,
  visibility: getInitialVisibility()
};

const getMapControlsAreLocked = (state: RootState): boolean => {
  const { activeFeature } = state.appFeature;
  if (activeFeature === 'VIEW_RUN_HISTORY') {
    return true;
  }
  return false;
};

const getSlice = (state: RootState) => state.statusMap;
const getVisibility = createSelector(getSlice, (state) => state.visibility);
const getIsVisibleOnMap = createCachedSelector(
  (_state: RootState, key: StatusMapVisibilityKey) => key,
  getVisibility,
  (key, visibility) => visibility[key]
)((_state, key) => key);

const getMapListType = createSelector(
  getSlice,
  (state) => state.currentListView
);

const getCurrentMapZoom = createSelector(
  getSlice,
  (state) => state.currentZoom
);
const getMapTypeId = createSelector(getSlice, (state) => state.mapTypeId);
const getMapSearchValue = (state: RootState): string | undefined =>
  state.statusMap.searchValue;

const statusMap = createSlice({
  initialState,
  name: 'statusMap',
  reducers: {
    /**
     * @param state
     */
    cycleMapTypeId(state) {
      const currentIndex = MAP_TYPE_IDS.findIndex(
        (id) => id === state.mapTypeId
      );
      const next = MAP_TYPE_IDS[currentIndex + 1];
      state.mapTypeId = next ?? MAP_TYPE_IDS[0] ?? state.mapTypeId;
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    setFieldColor(state, { payload }: PayloadAction<string>) {
      state.fieldColor = payload;
    },
    /**
     * @param state
     * @param args
     * @param args.payload
     */
    setMapTypeId(state, { payload }: PayloadAction<MapTypeId>) {
      state.mapTypeId = payload;
    },

    setMapZoom: (state, action: PayloadAction<number>) => {
      state.currentZoom = action.payload;
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    toggleMapItemVisibility(
      state,
      { payload }: PayloadAction<StatusMapVisibilityKey>
    ) {
      state.visibility[payload] = !state.visibility[payload];
    }
  }
});
const {
  setMapZoom,
  cycleMapTypeId,
  setMapTypeId,
  setFieldColor,
  toggleMapItemVisibility
} = statusMap.actions;

const statusMapReducer: Reducer<StatusMapState> = statusMap.reducer;
export default statusMapReducer;
export {
  setMapZoom,
  setMapTypeId,
  cycleMapTypeId,
  toggleMapItemVisibility,
  MAP_ITEM_TYPES,
  setFieldColor,
  STATUS_MAP_VISIBILITY_KEYS,
  getCurrentMapZoom,
  getMapListType,
  getMapTypeId,
  getIsVisibleOnMap,
  getMapControlsAreLocked,
  getMapSearchValue,
  MAP_TYPE_IDS
};
export type {
  MapTypeId,
  StatusMapVisibilityKey,
  MapItemType,
  StatusMapVisibility,
  StatusMapState
};
