import React from 'react';
import { useForm } from 'react-hook-form';
import { AppButton } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useMagnetSelect from '../forms/useMagnetSelect';
import type { SensorConfig, SensorPort } from '../sensors';
import Sensors, { SensorTextField } from '../sensors';
import useSensorConfigConverter from '../sensors/useSensorConfigConverter';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 *
 */
function WheelForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const currentStage = useAppSelector(CreateConfig.getCurrentStage);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, errors, register } =
    useForm<{ [K in keyof SensorConfig<'wheel'>]: number }>();

  const convertWheel = useSensorConfigConverter('wheel', 'database');
  const getValidation = Sensors.useFieldValidation();
  const sensorData = useAppSelector(CreateConfig.getWheel);
  const sensorPort: SensorPort = sensorData?.sensorPort ?? 1;
  const { MagnetSelect, nMagnets } = useMagnetSelect({
    isActive: currentStage === 'WHEEL',
    sensorName: 'wheel'
  });

  const onSubmit = handleSubmit((values) =>
    dispatch(
      CreateConfig.submitWheelData(
        convertWheel({
          diameterMm: values.diameterMm,
          nMagnets,
          sensorName: 'wheel',
          sensorPort
        })
      )
    )
  );
  return (
    <form onSubmit={onSubmit}>
      <FormWrapper
        instructions="Enter the number of installed magnets and wheel diameter"
        submitBtn={<AppButton presetKey="SUBMIT" type="submit" />}
        testId="wheel-form"
      >
        <SensorTextField
          errorMessage={errors.diameterMm?.message}
          id="diameterMm"
          inputRef={register(getValidation('diameterMm'))}
          name="diameterMm"
        />
        <MagnetSelect />
      </FormWrapper>
    </form>
  );
}

export default WheelForm;
