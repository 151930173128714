import type { AsyncThunk } from '@reduxjs/toolkit';
import React from 'react';
import Actions from 'src/actions';
import type { RootThunkApi, ThunkRejectMessage } from 'src/store';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

/**
 * Tracks fetch status for request; memoizes request handler
 * @param request
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useThunk<
  RT = void,
  A = null,
  RJ extends ThunkRejectMessage = ThunkRejectMessage
>(
  request: AsyncThunk<
    RT extends infer U ? U : RT,
    // A extends infer Arg ? Arg : A,
    A,
    RootThunkApi<RJ>
  >
) {
  const dispatch = useAppDispatch();
  const fetchStatus = useAppSelector(
    (rootState) =>
      rootState.userSession.requests.entities[request.typePrefix]?.fetchStatus
  );

  const sendRequest = React.useCallback(
    async (arg: A) => dispatch(request(arg)).unwrap(),
    [dispatch, request]
  );
  return {
    clearFetchStatus: React.useCallback(
      () => dispatch(Actions.clearFetchStatus(request.typePrefix)),
      [dispatch, request.typePrefix]
    ),
    isFulfilled: fetchStatus === 'fulfilled',
    isIdle: typeof fetchStatus === 'undefined',
    isPending: fetchStatus === 'pending',
    isRejected: fetchStatus === 'rejected',
    sendRequest
  };
}
