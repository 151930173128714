import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { clearPermissionDenied, User } from 'src/userSession.reducer';
import { humanizeFarmUsePermission } from 'src/utilities';
import { AppButton, ShowOnlyIf } from '../components';
import { useAppDispatch } from '../hooks';
import AppIcon from '../theme/AppIcon';

/**
 * Displays when user attempts to do something that their farm-user-permissions don't allow them to
 * @param props
 * @param props.children
 */
export default function PermissionDeniedDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const missingPermissions = useSelector(User.getMissingPermissions);
  const isDenied = (missingPermissions?.length ?? 0) > 0;
  const deniedMessage = `You do not have permission to perform this action`;

  // const title = useAppSelector(state => state.activeUserPermissions.)
  return (
    <Dialog data-cy="permission-denied" open={isDenied}>
      <DialogTitle>
        <Grid alignItems="baseline" container spacing={2}>
          <Grid item>
            <AppIcon iconKey="warning" />
          </Grid>
          <Grid item>Permission Required</Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{deniedMessage}</DialogContentText>
        <DialogContentText>
          {`
        Permissions can be granted to your account 
        by other members of your farm. 
        To access this feature, you can request permission from another farm 
        user who has the ability to modify permissions 
        (for example, the user that opened the account).
        `}
        </DialogContentText>
        <ShowOnlyIf isDev>
          <List>
            {missingPermissions?.map((perm) => (
              <ListItem key={perm}>
                <ListItemText primary={humanizeFarmUsePermission(perm)} />
              </ListItem>
            ))}
          </List>
        </ShowOnlyIf>
      </DialogContent>
      <DialogActions>
        <AppButton
          onClick={() => dispatch(clearPermissionDenied())}
          text="close"
        />
      </DialogActions>
    </Dialog>
  );
}
