import type { DialogProps } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import { CancelBtn, SubmitBtn } from 'src/components';
import type { DeviceNameProp } from 'src/devices';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { asyncInvalidateDeviceShareCode } from './deviceShareCodes.reducer';

/**
 * @param props
 * @param props.id
 * @param props.deviceId
 * @param props.deviceName
 * @param props.onCancel
 * @param props.rest
 * @param props.codeId
 */
export default function ConfirmInvalidateCode({
  id,
  codeId,
  deviceName,
  onCancel: handleCancel,
  ...rest
}: DeviceNameProp &
  DialogProps & { onCancel: () => void; codeId: number }): JSX.Element {
  const { sendRequest, isPending } = useThunk(asyncInvalidateDeviceShareCode);
  const handleSubmit = () => {
    sendRequest({ codeId }).then(handleCancel).catch(handleApiRequestError);
  };
  return (
    <Dialog data-cy={id} id={id} {...rest}>
      <DialogTitle>Confirm Invalidate Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Other farms will no longer be able to use 
          this code to add ${deviceName} to their account`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelBtn
          disabled={isPending}
          id={testIds.deviceOwnership.invalidate.cancelBtn}
          onClick={handleCancel}
        />
        <SubmitBtn
          id={testIds.deviceOwnership.invalidate.submitBtn}
          onClick={handleSubmit}
          showLoading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
}
