import type { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { snakeCase } from 'lodash-es';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RequestNames } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { createAsyncAppThunk, handleApiRequestError } from 'src/requests';
import type { RootState } from 'src/root.reducer';
import { showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';
import { asyncChangeDeviceName, asyncRestoreConfigDefaults } from '../actions';
import InstallationTypes from '../installation-type';
import type { DeviceConfig } from '../models';
import { getSelectedDeviceMetadata } from '../selectors';
import type {
  AnySensorConfigKey,
  ConfigSensorName,
  GetConfigKeysBySensorName
} from '../sensors';
import Sensors, { SensorNames } from '../sensors';

const { UPDATE_SENSOR_CONFIGURATION } = RequestNames;

type ThunkArg<S extends ConfigSensorName = ConfigSensorName> = {
  deviceId: string;
  key: GetConfigKeysBySensorName<S>;
  value: number | string;
  sensorName: S;
};
const asyncUpdateSensor = createAsyncAppThunk<ThunkArg, DeviceConfig>(
  UPDATE_SENSOR_CONFIGURATION,
  {
    checkPermissions: 'canManageDeviceConfiguration',
    transformRequest: ({ arg }) => {
      const { key, ...rest } = arg;
      return {
        key: snakeCase(key),
        ...rest
      } as typeof arg;
    }
  }
);

type EditorAction = 'RENAME' | 'RESET';

interface State<S extends ConfigSensorName = ConfigSensorName> {
  selectedSensor?: S;
  selectedKey?: GetConfigKeysBySensorName<S>;
  action?: EditorAction;
}
const initialState: State = {};

// const getSlice = (state: RootState): State => state.configManager;
const getConfigEditorState = createStructuredSelector<RootState, State>({
  action: (state) => state.configManager.action,
  selectedKey: (state) => state.configManager.selectedKey,
  selectedSensor: (state) => state.configManager.selectedSensor
});
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncUpdateSensor.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { key }
          }
        } = action;
        if (key !== 'thresholdPsiLower' && key !== 'thresholdPsiUpper') {
          return { ...initialState };
        }
        return { ...state };
      })
      .addMatcher(
        isAnyOf(
          asyncChangeDeviceName.fulfilled,
          asyncRestoreConfigDefaults.fulfilled
        ),
        () => ({
          ...initialState
        })
      ),
  initialState,
  name: 'configManager',
  reducers: {
    /**
     *
     */
    cancel() {
      return {
        ...initialState
      };
    },
    /**
     * @param state
     * @param action
     * @param action.payload
     */
    selectAction(state, { payload }: PayloadAction<EditorAction>) {
      state.selectedKey = undefined;
      state.selectedSensor = undefined;
      state.action = payload;
    },
    /**
     * @param state
     * @param _state
     * @param action
     */
    selectKey<S extends ConfigSensorName>(
      _state: State<S>,
      action: PayloadAction<Pick<State<S>, 'selectedKey' | 'selectedSensor'>>
    ) {
      return {
        ...action.payload
      };
    }
  }
});

const configManager: Reducer<State> = slice.reducer;
const { actions } = slice;
const useConfigManagerState = (): State => useAppSelector(getConfigEditorState);
/**
 *
 */
function useConfigMangerActions(): {
  cancel: () => { payload: undefined; type: string };
  clickRename: () => { payload: EditorAction; type: string };
  clickReset: () => { payload: EditorAction; type: string };
  selectKey: <S extends ConfigSensorName>(
    selectedSensor: S,
    selectedKey: GetConfigKeysBySensorName<S>
  ) => {
    payload: Pick<State, 'selectedKey' | 'selectedSensor'>;
    type: string;
  };
} {
  const dispatch = useAppDispatch();
  return {
    cancel: () => dispatch(actions.cancel()),
    clickRename: () => dispatch(actions.selectAction('RENAME')),
    clickReset: () => dispatch(actions.selectAction('RESET')),
    selectKey: <S extends ConfigSensorName>(
      selectedSensor: S,
      selectedKey: GetConfigKeysBySensorName<S>
    ) => dispatch(actions.selectKey({ selectedKey, selectedSensor }))
  };
}
/**
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useSubmitEditSensorConfig(): {
  /**
   *
   * Send updated sensor value to api to save in database
   *
   * By default, the selected (by user) key in the configManager reducer is used
   * as the 'key'. There is currently one exception - thresholdPsiLower and thresholdPsiUpper.
   * This is due to the nature of the pressure threshold form; we can't
   * reliably predict whether the user will edit the upper or lower value based on
   * which key they select; they may drag the lower slider up above the upper slider for
   * example. Therefore for that field we need to compare the upper and lower
   * values to determine the correct key
   *
   * @param value
   * @param sensorKey - currently only used for threshold values
   */
  onSubmit(value: number | string, sensorKey?: AnySensorConfigKey): void;
} {
  const { selectedKey, selectedSensor } = useConfigManagerState();
  const deviceMetadata = useSelector(getSelectedDeviceMetadata);

  const convertValue = Sensors.useConvertValue({ target: 'database' });
  const formatKey = Sensors.useFormatKey();
  const formatVal = Sensors.useFormatValue();
  const dispatch = useAppDispatch();

  return {
    /**
     *
     * Send updated sensor value to api to save in database
     *
     * By default, the selected (by user) key in the configManager reducer is used
     * as the 'key'. There is currently one exception - thresholdPsiLower and thresholdPsiUpper.
     * This is due to the nature of the pressure threshold form; we can't
     * reliably predict whether the user will edit the upper or lower value based on
     * which key they select; they may drag the lower slider up above the upper slider for
     * example. Therefore for that field we need to compare the upper and lower
     * values to determine the correct key
     *
     * @param value
     * @param sensorKey - currently only used for threshold values
     */
    onSubmit(value: number | string, sensorKey?: AnySensorConfigKey) {
      if (
        isNullish(selectedSensor) ||
        isNullish(selectedKey) ||
        isNullish(deviceMetadata)
      ) {
        throw new Error('Invalid data');
      }

      const converted = convertValue(selectedKey, value);
      const { deviceId, deviceInstallationType, deviceName } = deviceMetadata;
      dispatch(
        asyncUpdateSensor({
          deviceId,
          key: sensorKey ?? selectedKey,
          sensorName: selectedSensor,
          value:
            converted.result === 'CONVERTED' ? converted.value : converted.value
        })
      )
        .unwrap()
        .then(() => {
          showSuccessToast(
            ` ${InstallationTypes.humanizeInstallationType(
              deviceInstallationType
            )} ${deviceName}'s ${SensorNames.humanize(selectedSensor)} ${
              formatKey(selectedKey) ?? ''
            } set to ${formatVal(selectedKey, value) ?? 'new value'}`
          );
        })
        .catch(handleApiRequestError);
    }
  };
}

export {
  useConfigManagerState,
  useConfigMangerActions,
  asyncUpdateSensor,
  useSubmitEditSensorConfig
};
export default configManager;
