/* eslint-disable @typescript-eslint/no-explicit-any */
import type { GridProps, GridSize } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';

type Props = React.PropsWithChildren<
  GridProps & {
    readonly fullWidth?: boolean;
    readonly justifyChildren?: GridProps['justifyContent'];
  }
>;

/**
 * @param props
 * @param props.children
 * @param props.xs
 * @param props.fullWidth
 * @param props.alignItems
 * @param props.spacing
 * @param props.direction
 * @param props.sm
 * @param props.justifyChildren
 * @param props.md
 * @param props.xl
 * @param props.lg
 * @param props.rest
 * @param props.justifyContent
 */
const FlexRow = React.forwardRef<any, Props>((props, ref) => {
  const {
    children,
    xs,
    fullWidth,
    alignItems = `center`,
    spacing = 2,
    direction = `row`,
    justifyContent = `space-between`,
    sm,
    justifyChildren,
    md,
    xl,
    lg,
    ...rest
  } = props;
  const childSize =
    fullWidth === true
      ? (Math.floor(12 / React.Children.count(children)) as GridSize)
      : undefined;

  return (
    <Grid
      alignItems={alignItems}
      component="div"
      container
      direction={direction}
      justifyContent={justifyContent}
      ref={ref}
      spacing={spacing}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        return (
          <Grid item lg={lg} md={md} sm={sm} xl={xl} xs={xs ?? childSize}>
            <Grid container justifyContent={justifyChildren}>
              {child}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});
FlexRow.displayName = 'FlexRow';
export default FlexRow;
