/* eslint-disable max-lines */

import type { EntityState } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { isString } from 'lodash-es';
import type { Reducer } from 'redux';
import asyncGetUserData from 'src/asyncGetUserData';
import { useAppSelector } from 'src/hooks';
import type { RootState } from 'src/root.reducer';
import asyncUpdateMap from 'src/status-map/asyncUpdateMap';
import type { RootSelector } from 'src/store';
import { asyncGetLastEventForDevice } from './force-update';
import type { DeviceEvent } from './models';
import { eventsAdapter } from './models';

const selectors = eventsAdapter.getSelectors(
  (state: RootState) => state.deviceEventLast
);
type DeviceEventLastState = EntityState<DeviceEvent>;

const initialState: DeviceEventLastState = eventsAdapter.getInitialState({
  selectedId: null
});

const slice = createSlice({
  /**
   * @param builder
   */
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetUserData.pending, () => {
        return { ...initialState };
      })
      .addCase(asyncGetUserData.fulfilled, (state, action) => {
        const entities = action.payload.activeFarm?.deviceEvents;
        if (entities) {
          eventsAdapter.upsertMany(
            state,
            entities.map((de, indexId) => ({ ...de, indexId }))
          );
        }
      })
      .addCase(asyncUpdateMap.fulfilled, (state, action) => {
        eventsAdapter.upsertMany(state, action.payload.events);
      })
      .addCase(asyncGetLastEventForDevice.fulfilled, (state, { payload }) => {
        if (payload !== `NO EVENTS` && payload !== `NOT NEW`) {
          eventsAdapter.removeOne(state, payload.deviceId);
          eventsAdapter.addOne(state, payload);
        }
      }),

  initialState,
  name: `deviceEventLast`,
  reducers: {}
});

// const actions = slice.actions;
const deviceEventLast: Reducer<DeviceEventLastState> = slice.reducer;
const DeviceEventLast = {
  adapter: eventsAdapter,
  useById: (deviceId: string | null | undefined) =>
    useAppSelector((state) =>
      isString(deviceId) ? selectors.selectById(state, deviceId) : null
    ),
  ...selectors,
  selectIds: selectors.selectIds as RootSelector<string[]>
} as const;
export default deviceEventLast;

export { DeviceEventLast };
