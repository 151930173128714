type PathArgs = {
  readonly spread: number;
  readonly lineHeight: number;
  readonly curveX: number;
  readonly curveY: number;
};
const getDefaultPath = (scale = 1) => {
  return {
    curveX: 27 * scale,
    curveY: 50 * scale,
    lineHeight: 20 * scale,
    spread: 10 * scale
  };
};
/**
 * Make teardrop-shaped svg path for map markers
 * @param args
 * @param args.spread
 * @param args.curveX
 * @param args.curveY
 * @param args.lineHeight
 * @param scale
 */
function createTearDropShape(args?: PathArgs, scale?: number): string {
  const { spread, curveX, curveY, lineHeight } = args ?? getDefaultPath(scale);
  return `
          M 0 0 
          L ${spread} -${lineHeight}
          C
            ${curveX} -${curveY},
            -${curveX} -${curveY}, 
          -${spread} -${lineHeight},
          Z`;
}

export default {
  createTearDropShape
};
