import { range } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'src/actions';
import { getConfigByDeviceId, getEventByDeviceId } from 'src/devices';
import { Geo } from 'src/geo';
import { ReelRunsActive } from 'src/reel-runs';
import { notNullish } from 'src/types';

import { Marker, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';

import { RunHistory } from './fieldRunHistory.reducer';
import { Point } from './geo';

import type { ReelRun } from 'src/reel-runs';
import type { PointGeoJson } from './geo';
export type BuildSwathProps = {
  completionPct: number;
  directionAz: number;
  endPoint: PointGeoJson;
  length: number;
  semicircle?: boolean;
  widthMm: number;
};
export function buildSwath(props: BuildSwathProps) {
  const completionPct = props.completionPct ?? 1;
  const { length } = props;
  const halfSwathWidth = props.widthMm / 2;

  const towardGun = props.directionAz;
  const reelLocation = new Point(props.endPoint);
  const gunMaxPosition: Point = reelLocation.projectNewPoint({
    azimuth: towardGun,
    distanceMm: length
  });
  const gunCurrentPosition = reelLocation.projectNewPoint({
    azimuth: towardGun,
    distanceMm: length * (1 - completionPct)
  });

  let semiCircle: Point[] = [];
  if (props.semicircle === true) {
    range(90, -90, -10).forEach((offset) => {
      semiCircle.push(
        new Point(gunMaxPosition).projectNewPoint({
          azimuth: offset,
          distanceMm: halfSwathWidth
        })
      );
    });
  } else {
    semiCircle = [gunMaxPosition];
  }
  const points: Point[] = [
    gunMaxPosition.projectNewPoint({
      azimuth: towardGun + 90,
      distanceMm: halfSwathWidth
    }),
    ...semiCircle,
    gunMaxPosition.projectNewPoint({
      azimuth: towardGun - 90,
      distanceMm: halfSwathWidth
    }),
    gunCurrentPosition.projectNewPoint({
      azimuth: towardGun - 90,
      distanceMm: halfSwathWidth
    }),
    gunCurrentPosition.projectNewPoint({
      azimuth: towardGun + 90,
      distanceMm: halfSwathWidth
    })
  ];

  return points.map((p) => p.to('google'));
}

export function SwathPolygon({
  onClick,
  ...props
}: BuildSwathProps & {
  onClick?: () => void;
}) {
  const { completionPct } = props;

  const outline = buildSwath({ ...props, completionPct: 1 });
  const completion = buildSwath({
    ...props,
    completionPct
  });

  const center = Geo.points.to.googleMaps(
    turf.centerOfMass({
      type: 'Polygon',
      coordinates: [outline.map((pt) => [pt.lng, pt.lat])]
    })
  );

  return (
    <React.Fragment>
      <Polygon
        onClick={onClick}
        options={{
          fillColor: 'blue',
          fillOpacity: 0.2,
          strokeColor: 'transparent'
        }}
        path={completion}
      />
      {notNullish(completionPct) ? (
        <Marker
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillOpacity: 0
          }}
          label={{
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            text: `${completionPct * 100} %`,
            color: 'white'
          }}
          onClick={onClick}
          position={center}
        />
      ) : null}
      <Polygon
        onClick={onClick}
        options={{
          fillColor: 'transparent',
          strokeColor: 'cyan'
        }}
        path={outline}
      />
    </React.Fragment>
  );
}

export function RunPolygon({
  id,
  variant
}: {
  id: number;
  variant: 'ACTIVE' | 'HISTORICAL';
}) {
  const dispatch = useDispatch();
  const props = useSelector((state): BuildSwathProps | undefined => {
    let reelRun: ReelRun | undefined = undefined;

    if (variant === 'ACTIVE') {
      reelRun = ReelRunsActive.selectById(state, id);
    } else {
      reelRun = RunHistory.selectById(state, id);
    }
    if (reelRun) {
      const deviceId = reelRun?.deviceId ?? '';
      const config = getConfigByDeviceId(state, deviceId);
      const event = getEventByDeviceId(state, deviceId);

      const endPoint = reelRun?.endPoint ?? event?.gps?.location;
      const distanceMmCurrent =
        reelRun.observations.slice(-1)[0]?.distanceObservedMm;
      let completionPct = 1;
      const distanceMmMax = reelRun?.distanceMmMax;
      if (endPoint && typeof distanceMmMax === 'number') {
        if (typeof distanceMmCurrent === 'number') {
          completionPct = distanceMmCurrent / distanceMmMax;
        }
        return {
          semicircle: true,
          endPoint,
          completionPct,
          widthMm: config?.reel?.swathWidthMm ?? 73512,
          directionAz: 0,
          length: distanceMmMax
        };
      }
    }
    return undefined;
  });
  if (props) {
    return (
      <SwathPolygon
        onClick={() => {
          dispatch(Actions.selectItem({ kind: 'reelRun', id }));
        }}
        {...props}
      />
    );
  }

  return null;
}
