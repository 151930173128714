import React from 'react';
import { isNullish } from 'src/types';
import { appLogger } from 'src/utilities';

import { Box, CircularProgress } from '@material-ui/core';
import {
  Add,
  ArrowBack,
  ArrowForwardRounded,
  CheckBoxOutlineBlank,
  CompassCalibration,
  ContactSupportOutlined,
  DeleteForever,
  DeviceHubOutlined,
  DeviceHubSharp,
  Edit,
  Error as ErrorIcon,
  Info,
  LinkOutlined,
  NotificationsActive,
  NotificationsOff,
  PhonelinkSetup,
  Settings,
  SettingsCell,
  SettingsRemote,
  Share,
  TimelineOutlined,
  ZoomInOutlined
} from '@material-ui/icons';
import ArrowUpwardTwoTone from '@material-ui/icons/ArrowUpwardTwoTone';
import BlockOutlined from '@material-ui/icons/BlockOutlined';
import Brightness3 from '@material-ui/icons/Brightness3';
import Brightness7 from '@material-ui/icons/Brightness7';
import Cached from '@material-ui/icons/Cached';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import CenterFocusStrongTwoTone from '@material-ui/icons/CenterFocusStrongTwoTone';
import Check from '@material-ui/icons/Check';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CheckCircleOutlineTwoTone from '@material-ui/icons/CheckCircleOutlineTwoTone';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Create from '@material-ui/icons/Create';
import ExitToAppTwoTone from '@material-ui/icons/ExitToAppTwoTone';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import FormatListBulletedTwoTone from '@material-ui/icons/FormatListBulletedTwoTone';
import Help from '@material-ui/icons/Help';
import LinkOffTwoTone from '@material-ui/icons/LinkOffTwoTone';
import MapIcon from '@material-ui/icons/Map';
import MenuIcon from '@material-ui/icons/Menu';
import PaletteTwoTone from '@material-ui/icons/PaletteTwoTone';
import Refresh from '@material-ui/icons/Refresh';
import RotateLeft from '@material-ui/icons/RotateLeft';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import SignalCellular4Bar from '@material-ui/icons/SignalCellular4Bar';
import SignalCellularOff from '@material-ui/icons/SignalCellularOff';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import SwapVert from '@material-ui/icons/SwapVert';
import SystemUpdate from '@material-ui/icons/SystemUpdate';
import Timeline from '@material-ui/icons/Timeline';
import ToggleOn from '@material-ui/icons/ToggleOn';
import Tune from '@material-ui/icons/Tune';
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import WarningTwoTone from '@material-ui/icons/WarningTwoTone';
import ZoomOutMapTwoTone from '@material-ui/icons/ZoomOutMapTwoTone';

import AppLogoIcon from './AppLogoIcon';
import Barn from './Barn';
import InstallationTypeIcon from './InstallationTypeIcons';

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SvgIconProps } from '@material-ui/core';
import type { AlertProps } from '@material-ui/lab';
import type { ActiveFeature } from 'src/actions';
import type { HasIconKey, ShowsLoader } from 'src/components/types';
import type { BasePageName } from 'src/dashboardRoutes';
import type { InstallationType } from 'src/devices';
import type { RemoteControlKey } from 'src/devices/remote-controls';
import type { MapItemType } from 'src/status-map/statusMap.reducer';
import type { SupportPageId } from 'src/support/Support';
import type { ValuesType } from 'utility-types';
import type { AppLogoIconKey } from './AppLogoIcon';
/**
 * Allows access to Material UI and custom icons by passing string constants
 * Also eliminates the need to memorize names of Material UI icons,
 * which are sometimes weird.
 */
const ICON_KEY = {
  ACTIVATE_FIELD: 'ACTIVATE_FIELD',
  ADD_ITEM: 'ADD_ITEM',
  ARCHIVE_FIELD: 'ARCHIVE_FIELD',
  CANCEL: 'CANCEL',
  CENTER_MAP: 'CENTER_MAP',
  CHECK_MARK: 'CHECK_MARK',
  CLOSE: 'CLOSE',
  COLOR_PALETTE: 'COLOR_PALETTE',
  CONFIGURATION_1: 'CONFIGURATION_1',
  CONFIGURATION_2: 'CONFIGURATION_2',
  CONFIRMATION: 'CONFIRMATION',
  DARK_THEME: 'DARK_THEME',
  DELETE_ITEM: 'DELETE_ITEM',
  DEVICE_EVENT: 'DEVICE_EVENT',
  DEVICE_PAIRS: 'DEVICE_PAIRS',
  DISABLED: 'DISABLED',
  DRAW_FIELD: 'DRAW_FIELD',
  EDIT_ITEM: 'EDIT_ITEM',
  ENABLED: 'ENABLED',
  EXIT: 'EXIT',
  EXPAND_MORE: 'EXPAND_MORE',
  FILTER: 'FILTER',
  FLIP_ROW_DIRECTION: 'SWITCH_VERTICAL',
  GO_BACK: 'GO_BACK',
  GO_FORWARD: 'GO_FORWARD',
  LIGHT_THEME: 'LIGHT_THEME',
  LINK: 'LINK',
  LIST: 'LIST',
  LOCATE_ON_MAP: 'LOCATE_ON_MAP',
  MENU: 'MENU',
  NOTIFICATIONS_OFF: 'NOTIFICATIONS_OFF',
  NOTIFICATIONS_ON: 'NOTIFICATIONS_ON',
  REFRESH: 'REFRESH',
  RESET: 'RESET',
  SHARE: 'SHARE',
  SUCCESS: 'SUCCESS',
  SWITCH_HORIZONTAL: 'SWITCH_HORIZONTAL',
  SWITCH_VERTICAL: 'SWITCH_VERTICAL',
  UNPAIR: 'UNPAIR',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT_MAP: 'ZOOM_OUT_MAP'
} as const;
export type IconKey = NonNullable<
  | ActiveFeature
  | AlertProps['severity']
  | AppLogoIconKey
  | BasePageName
  | InstallationType
  | MapItemType
  | RemoteControlKey
  | SupportPageId
  | ValuesType<typeof ICON_KEY>
>;

/**
 * @param key
 */
function getAppIcon(
  key: IconKey
  // eslint-disable-next-line @typescript-eslint/ban-types
) {
  // : OverridableComponent<SvgIconTypeMap>
  switch (key as IconKey) {
    case 'Leaf':
    case 'StackedLockup':
    case 'InlineLockup': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return AppLogoIcon[key as AppLogoIconKey];
    }
    case 'GO_BACK': {
      return ArrowBack;
    }
    case 'AUTOMATIONS':
      return PhonelinkSetup;
    case 'MANAGE_CONFIGURATION': {
      return Settings;
    }
    case 'REMOTE_CONTROLS': {
      return SettingsRemote;
    }
    case 'ACTIVATE_FIELD': {
      return ArrowUpwardTwoTone;
    }
    case 'ACTIVATE_RELAY': {
      return SignalCellular4Bar;
    }
    case 'ADD_ITEM':
    case 'ADD_DEVICE': {
      return Add;
    }
    case 'ARCHIVE_FIELD':
      return SystemUpdate;
    case 'basic-usage': {
      return SettingsApplications;
    }
    case 'CANCEL': {
      return CancelOutlined;
    }
    case 'CENTER_MAP':
    case 'LOCATE_ON_MAP': {
      return CenterFocusStrongTwoTone;
    }
    case 'success':
    case 'CHECK_MARK': {
      return Check;
    }
    case 'CLOSE': {
      return CloseOutlined;
    }
    case 'COLOR_PALETTE': {
      return PaletteTwoTone;
    }
    case 'CREATE_CONFIGURATION':
    case 'CONFIGURATION_1':
    case 'CONFIGURATION_2':
    case 'prototype':
    case 'unconfigured': {
      return SettingsApplications;
    }
    case 'CONFIRMATION':
      return Help;
    case 'DARK_THEME':
      return Brightness7;
    case 'DEACTIVATE_RELAY':
      return SignalCellularOff;
    case 'DELETE_ITEM':
    case 'DELETE_FIELD': {
      return DeleteForever;
    }
    case 'device': {
      return DeviceHubSharp;
    }
    case 'GO_FORWARD': {
      return ArrowForwardRounded;
    }
    case 'DEVICE_EVENT':
    case 'SENSOR_STATES':
    case 'INSPECT_RECENT_EVENTS':
    case 'VIEW_RUN_HISTORY': {
      return Timeline;
    }
    case 'device-overview':
      return SettingsCell;
    case 'LINK':
    case 'DEVICE_PAIRS':
    case 'PAIRING': {
      return LinkOutlined;
    }
    case 'devices': {
      return DeviceHubOutlined;
    }
    case 'DISABLED': {
      return BlockOutlined;
    }
    case 'DOWNLOAD_CONFIG': {
      return SystemUpdate;
    }
    case 'CREATE_FIELD':
    case 'DRAW_FIELD': {
      return Create;
    }
    case 'EDIT_ITEM':
    case 'RENAME_FIELD': {
      return Edit;
    }
    case 'ENABLED':
      return CheckCircleOutline;
    case 'EXIT': {
      return ExitToAppTwoTone;
    }
    case 'EXPAND_MORE': {
      return ExpandMoreRounded;
    }
    case 'error': {
      return ErrorIcon;
    }

    case 'account':
    case 'farm': {
      return Barn;
    }
    case 'fields':
    case 'field':
    case 'EDIT_FIELD_SHAPE':
      return ViewQuilt;
    case 'FILTER': {
      return Tune;
    }
    case 'FORCE_UPDATE': {
      return Refresh;
    }

    case 'VIEW_PERMISSIONS':
    case 'info': {
      return Info;
    }
    case 'in-the-box': {
      return CheckBoxOutlineBlank;
    }
    case 'LIGHT_THEME': {
      return Brightness3;
    }
    case 'LIST': {
      return FormatListBulletedTwoTone;
    }
    case 'SET_MAP_TYPE_ID':
    case 'map': {
      return MapIcon;
    }
    case 'MENU': {
      return MenuIcon;
    }
    case 'NOTIFICATIONS_OFF': {
      return NotificationsOff;
    }
    case 'GLOBAL_NOTIFICATIONS':
    case 'NOTIFICATIONS_ON':
    case 'DEVICE_NOTIFICATIONS': {
      return NotificationsActive;
    }

    case 'pump':
    case 'pump-installation': {
      return InstallationTypeIcon.Pump;
    }

    case 'reelRun': {
      return TimelineOutlined;
    }
    case 'REFRESH':
      return Cached;

    case 'RESET':
    case 'REBOOT_CHIP': {
      return RotateLeft;
    }
    case 'SET_ROW_DIRECTION':
    case 'SET_RUN_DIRECTION': {
      return CompassCalibration;
    }
    case 'traveller_soft':
    case 'reel':
    case 'reel-installation': {
      return InstallationTypeIcon.Reel;
    }
    case 'SHARE':
    case 'SHARE_DEVICE':
      return Share;
    case 'SUCCESS': {
      return CheckCircleOutlineTwoTone;
    }
    case 'support':
      return ContactSupportOutlined;
    case 'SWITCH_HORIZONTAL':
    case 'CHANGE_FARMS':
      return SwapHoriz;
    case 'SWITCH_VERTICAL':
      return SwapVert;
    case 'TEST_CONNECTION':
      return SettingsInputAntenna;
    case 'SHUT_DOWN': {
      return ToggleOn;
    }
    case 'troubleshooting': {
      return ErrorIcon;
    }
    case 'UNPAIR': {
      return LinkOffTwoTone;
    }

    case 'warning':
      return WarningTwoTone;
    case 'ZOOM_IN':
      return ZoomInOutlined;
    case 'ZOOM_OUT_MAP':
      return ZoomOutMapTwoTone;

    default: {
      const errorMessage = `Missing Icon Key: ${key as string} `;
      if (process.env.NODE_ENV === 'development') {
        throw new Error(errorMessage);
      }
      appLogger.error(errorMessage);
      return () => null as unknown as JSX.Element;
    }
  }
}

interface AppIconProps extends HasIconKey, SvgIconProps, ShowsLoader {
  readonly loadingIndicator?: typeof CircularProgress;
  readonly iconKey: IconKey;
}

const AppIcon = React.forwardRef<any, AppIconProps>(
  ({ iconKey, showLoading, ...rest }, ref) => {
    if (isNullish(iconKey)) {
      appLogger.warn('App Icon received nullish icon key');
      return null;
    }
    const IconComponent = getAppIcon(iconKey);

    if (showLoading === true) {
      return (
        <Box height="inherit" width="inherit">
          {rest.loadingIndicator ?? <CircularProgress />}
        </Box>
      );
    }

    return <IconComponent ref={ref} {...rest} />;
  }
);
AppIcon.displayName = 'AppIcon';
export default AppIcon;
