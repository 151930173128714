import type { EntityState, Reducer } from '@reduxjs/toolkit';
import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import type { PointGeoJson } from 'src/geo';
import type { FarmUserPermissionsMap } from 'src/permissions';
import { createAsyncAppThunk } from 'src/requests';
import type { RootState } from 'src/root.reducer';
import { User } from 'src/userSession.reducer';

interface FarmAccount {
  id: number;
  gpsLocation: PointGeoJson | null;
  mapViewbox?: google.maps.LatLngBoundsLiteral | null;
  //  mapViewboxPolygon?: PolygonGeoJSON | null;
  formattedAddress?: string;
  //  mapViewboxCenter?: PointGeoJSON | null;
  name: string;
  addressLine1: string;
  addressLine2: string | undefined;
  city: string;
  region: string;
  postalCode: string;
  country: string;
}

const {
  UPDATE_FARM_USER_PERMISSION,
  UPDATE_FARM_GPS_LOCATION,
  LOAD_USER_PERMISSIONS
} = RequestNames;
const asyncChangeFarmLocation = createAsyncAppThunk<PointGeoJson, FarmAccount>(
  UPDATE_FARM_GPS_LOCATION,
  {
    checkPermissions: 'canManageFarmAccount',
    successToast: 'Farm location updated.'
  }
);
type Args = {
  readonly targetUserId: string;
  readonly permissions: FarmUserPermissionsMap;
};

type ThemeType = 'dark' | 'light';

interface UserAccount {
  activeFarmId: number | null;
  currentStatus?: 'changing farms' | null;
  email: string;
  fieldColorPreference: string;
  id: string;
  isAdmin: boolean;
  isDebug?: boolean;
  termsOfServiceAccepted: boolean;
  themePreference?: ThemeType | null;
  tipsEnabled: boolean;
  usesMetricSystem: boolean;
}
interface FarmUserAssociation {
  readonly id: number;
  readonly farmId: number;
  readonly isAdmin: boolean | null;
  readonly email: string;
  readonly userId: string;
  readonly permissions: FarmUserPermissionsMap;
}
interface OtherFarmUser {
  isAdmin: boolean;
  associationId: number;
  userId: string;
  email: string;
}
const asyncUpdateFarmUserPermissions = createAsyncAppThunk<
  Args,
  FarmUserAssociation
>(UPDATE_FARM_USER_PERMISSION, {
  checkPermissions: 'canManageUserPermission',
  successToast: 'Permissions Updated.'
});
const asyncLoadPermissionEditor = createAsyncAppThunk<
  { readonly targetUserId: string },
  FarmUserPermissionsMap
>(LOAD_USER_PERMISSIONS, { checkPermissions: 'canManageUserPermission' });

const adapter = createEntityAdapter<OtherFarmUser>({
  selectId: (u) => {
    return u.email;
  },
  sortComparer: (a, b) => {
    return a.email.localeCompare(b.email);
  }
});
const selectors = adapter.getSelectors((state: RootState) => state.farmUsers);

interface State extends EntityState<OtherFarmUser> {
  readonly selectedEmail: string | null;
}
const initialState: State = adapter.getInitialState({
  selectedEmail: null
});

const getFarmUsersFiltered = createSelector(
  User.getIsAdmin,
  User.getEmail,
  selectors.selectAll,

  (activeUserIsAdmin, activeUserEmail, users): readonly OtherFarmUser[] => {
    return users.filter((otherUser): boolean => {
      if (activeUserIsAdmin) {
        // Admin see everyone
        return true;
      }

      if (otherUser.email === activeUserEmail) {
        // regular users don't see themselves (admin do and can set their own permissions)
        return false;
      }

      if (otherUser.isAdmin) {
        // regular users don't see admin users
        return false;
      }

      return true;
    });
  }
);
const getIsOnlyFarmUser = createSelector(
  User.getIsAdmin,
  getFarmUsersFiltered,
  (isAdmin, users) => {
    if (isAdmin) {
      return false;
    }
    return users.length === 0;
  }
);

const farmUsersSlice = createSlice({
  extraReducers: (builder) =>
    builder.addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
      if (payload.activeFarm?.otherUsers) {
        adapter.upsertMany(state, [...payload.activeFarm.otherUsers]);
      }
    }),
  initialState,
  name: `farmUsers`,
  reducers: {}
});

const FarmUsers = {
  adapter,
  ...selectors,
  getFarmUsersFiltered,
  getIsOnlyFarmUser
};

const farmUsers: Reducer<typeof initialState> = farmUsersSlice.reducer;
export default farmUsers;
export {
  asyncChangeFarmLocation,
  asyncUpdateFarmUserPermissions,
  asyncLoadPermissionEditor,
  FarmUsers
};
export type {
  OtherFarmUser,
  FarmAccount,
  FarmUserAssociation,
  UserAccount,
  ThemeType
};
