import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import testIds from 'src/testIds';
import { humanize } from 'src/utilities';
import type { RelayAction, RelayActionType } from './RelayAction';
import RelayActions from './RelayAction';

const options = RelayActions.RELAY_ACTION_TYPES;

type Props = {
  value: RelayActionType | undefined;
  onChange: (val: RelayActionType) => void;
};

/**
 * @param props
 * @param props.value
 * @param props.onChange
 */
function RelayActionSelect({ value, onChange }: Props): JSX.Element {
  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const newValue = e.target.value;
    if (RelayActions.isValidRelayActionType(newValue)) {
      onChange(newValue);
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel>Take Action</InputLabel>
      <Select
        data-cy={testIds.relayActionSelect.root}
        id={testIds.relayActionSelect.root}
        onChange={handleChange}
        value={value ?? ''}
        variant="standard"
      >
        {options.map((actionType, index) => {
          const testId = testIds.relayActionSelect.item(index);
          const action: RelayAction =
            actionType === 'RLYAS'
              ? {
                  actionType,
                  seconds: 10
                }
              : {
                  actionType
                };
          return (
            <MenuItem
              data-cy={testId}
              id={testId}
              key={actionType}
              value={actionType}
            >
              {humanize(RelayActions.humanize(action)).toLocaleLowerCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default RelayActionSelect;
