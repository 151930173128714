import React from 'react';
import type { HideableProps } from 'src/components';
import type { LatLng } from 'src/geo';
import { isNullish } from 'src/types';
import type { MarkerBaseProps } from '../components/map/MarkerBase';
import MarkerBase from '../components/map/MarkerBase';
import createFieldLabel from './createFieldLabel';
import type { FarmField } from './farmFields.reducer';

/**
 * @param args
 * @param args.fieldName
 * @param args.className
 */
function createMarkerLabel(args: {
  readonly fieldName?: string | null;
  readonly className?: string;
}): google.maps.MarkerLabel {
  return {
    className: args.className,
    color: `#fff`,
    fontSize: `14px`,
    // fontWeight: ``,
    text: args.fieldName ?? ``
  };
}

interface Props
  extends HideableProps,
    Pick<MarkerBaseProps, 'clickable' | 'onClick' | 'onLoad' | 'opacity'>,
    Pick<FarmField, 'fieldName' | 'rowDirectionAzimuthDegrees'> {
  readonly testId?: string;
  readonly isArchived?: boolean;
  readonly center: LatLng;
}

/**
 * Render field name at center of field
 * @param props
 * @param props.testId
 * @param props.center
 * @param props.fieldName
 * @param props.isArchived
 * @param props.isHidden
 * @param props.onLoad
 * @param props.onClick
 * @param props.opacity
 * @param props.rowDirectionAzimuthDegrees
 * @param props.clickable
 */
function FieldNameLabel({
  testId,
  center,
  fieldName,
  isArchived,
  isHidden,
  onLoad,
  onClick,
  opacity,
  rowDirectionAzimuthDegrees,
  clickable
}: Props) {
  const centerIcon = React.useMemo(():
    | google.maps.Icon
    | google.maps.Symbol => {
    return {
      labelOrigin: new google.maps.Point(0, 10),
      path: `M10 10 10 10`
    };
  }, []);

  return isNullish(center) ? null : (
    <MarkerBase
      clickable={clickable}
      icon={centerIcon}
      isHidden={isHidden}
      label={createMarkerLabel({
        fieldName: createFieldLabel({
          azimuth: rowDirectionAzimuthDegrees,
          fieldName,
          isArchived
        })
      })}
      onClick={onClick}
      onLoad={onLoad}
      opacity={opacity}
      position={center}
      title={testId ?? fieldName}
    />
  );
}

const FieldLabelMemoized = React.memo(FieldNameLabel);
export default FieldLabelMemoized;
