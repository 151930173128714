import classNames from 'classnames';
import React from 'react';
import { APP_STYLE } from 'src/AppThemeProvider';

import { Card, createStyles, makeStyles } from '@material-ui/core';

import type { CardProps } from '@material-ui/core';
type StyleProps = Pick<React.CSSProperties, 'maxHeight' | 'overflowY'>;

const useStyles = makeStyles(({ spacing, zIndex }) => {
  const { mapCardMaxWidth, mapFloatingCard } = APP_STYLE;
  // const smallScreen = breakpoints.down(mapFloatingCard.breakPoint);
  const buffer = mapFloatingCard.spacing;
  return createStyles({
    bottom: {
      bottom: buffer.y,
      left: buffer.x,
      maxWidth: mapCardMaxWidth,
      padding: spacing(1),
      position: 'absolute',
      right: buffer.x,
      zIndex: zIndex.modal
    },
    top: (props: StyleProps) => ({
      ...props,
      maxWidth: mapCardMaxWidth,
      position: 'absolute',
      borderRadius: 5,
      left: spacing(buffer.x),
      top: spacing(buffer.y),
      right: spacing(buffer.x)
    })
  });
});

type Props = CardProps &
  StyleProps & {
    position?: 'bottom' | 'top';
  };
const MapCard = React.forwardRef<any, Props>((props, ref) => {
  const { className, position = 'top', maxHeight, overflowY, ...rest } = props;
  const classes = useStyles({ maxHeight, overflowY });
  return (
    <Card
      data-cy={rest.id}
      ref={ref}
      {...rest}
      className={classNames(className, classes[position])}
    />
  );
});
MapCard.displayName = 'MapCard';

export default MapCard;
