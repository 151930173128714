import type { ButtonProps, TypographyVariant } from '@material-ui/core';
import React from 'react';
import { useIsAnyRequestPending } from 'src/appSelectors';
import type { EnhancedButtonProps } from 'src/components';
import { AppButton, StyledLink } from 'src/components';

type AuthLinkProps = {
  text: string;
  isHidden?: boolean;
  testId?: string;
  to: string;
  button?: boolean;
  typoVariant?: TypographyVariant;
  buttonVariant?: ButtonProps['variant'];
  iconKey?: EnhancedButtonProps['iconKey'];
};

/**
 * @param props
 * @param props.to
 * @param props.testId
 * @param props.isHidden
 * @param props.button
 * @param props.text
 * @param props.typoVariant
 * @param props.buttonVariant
 * @param props.iconKey
 */
export default function AuthLink({
  to,
  iconKey,
  testId,
  isHidden,
  button,
  text,
  typoVariant = 'subtitle2',
  buttonVariant = 'text'
}: AuthLinkProps): JSX.Element | null {
  const requestPending = useIsAnyRequestPending();
  if (isHidden === true) {
    return null;
  }

  if (button === true) {
    return (
      <AppButton
        disabled={requestPending}
        iconKey={iconKey}
        id={testId}
        linkTo={to}
        variant={buttonVariant}
      >
        {text}
      </AppButton>
    );
  }
  return (
    <div>
      <StyledLink
        disabled={requestPending}
        id={testId}
        linkTo={to}
        variant={typoVariant}
      >
        {text}
      </StyledLink>
    </div>
  );
}
