import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NoFarmsAppBar } from 'src/AppBar';
import { AUTH_PATHS } from 'src/auth/auth-paths';
import SignOut from 'src/auth/SignOut';
import PATHS from 'src/constants/Paths';
import testIds from 'src/testIds';

import CreateFarm from './CreateFarm';
import JoinFarmWithCode from './JoinFarmWithCode';
const STYLES: {
  [key in 'container' | 'root']: React.CSSProperties;
} = {
  container: {
    marginTop: 20
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

/**
 * Routes for user who has no farm accounts
 * @param props
 * @param props.children
 */
export default function NoFarms(): JSX.Element {
  const testId = testIds.noFarms.root;

  return (
    <div data-cy={testId} id={testId} style={STYLES.root}>
      <NoFarmsAppBar />
      <Container maxWidth="xs" style={STYLES.container}>
        <Typography color="textSecondary" variant="h4">
          <Route exact path="/">
            Join Farm
          </Route>
          <Route exact path={PATHS.noFarms.newFarm}>
            New Farm Account
          </Route>
        </Typography>
        <Switch>
          <Route component={SignOut} exact path="/sign-out" />
          <Route component={CreateFarm} exact path={PATHS.noFarms.newFarm} />
          <Route
            exact
            path={PATHS.noFarms.base}
            render={(props) => <JoinFarmWithCode isFirstFarm {...props} />}
          />
          <Redirect exact from={AUTH_PATHS.signIn} to={PATHS.noFarms.base} />
          <Redirect from="/" to={PATHS.noFarms.base} />
        </Switch>
      </Container>
    </div>
  );
}
