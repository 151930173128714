import type { InputProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import AppIcon from 'src/theme/AppIcon';
import { IconBtn } from '../buttons';
import { AppTextField } from '../forms';

// interface SearchBarProps
//   extends Pick<TextFieldProps, 'className' | 'onChange'> {
//   readonly onClose: () => void;
// }

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return createStyles({
    root: {
      marginRight: spacing(2)
    }
  });
});

type Props = {
  value: string;
  className?: string;
  onCommitValue(value: string): void;
};

/**
 * @param props
 * @param props.value
 * @param props.onChange
 * @param props.onClose
 * @param props.className
 * @param props.committedValue
 * @param props.onCommitValue
 */
export default function SearchField({
  className,
  value: committedValue,
  onCommitValue
}: Props): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const charCount = committedValue.length;
  const [searchTerm, setSearchTerm] = React.useState(committedValue ?? '');

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm !== committedValue) {
        onCommitValue(searchTerm);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [committedValue, dispatch, onCommitValue, searchTerm]);

  const handleClear = React.useCallback(() => {
    setSearchTerm('');
  }, []);
  const inputPropsMemo = React.useMemo(
    (): InputProps => ({
      endAdornment:
        charCount > 0 ? (
          <IconBtn onClick={handleClear}>
            <AppIcon iconKey="CLOSE" />
          </IconBtn>
        ) : (
          <SearchIcon />
        ),
      id: testIds.searchBar.input
    }),
    [charCount, handleClear]
  );
  const handleChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback((e) => setSearchTerm(e.target.value), []);
  return (
    <AppTextField
      InputProps={inputPropsMemo}
      className={classNames(classes.root, className)}
      id={testIds.searchBar.root}
      label="search"
      name="deviceSearch"
      onChange={handleChange}
      size="small"
      value={searchTerm}
      variant="standard"
    />
  );
}
