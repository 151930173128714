import { notNullish } from 'src/types';

import { createEntityAdapter } from '@reduxjs/toolkit';

import type {
  ConfigSensorName,
  GetStatesOfSensor,
  HardwareGeneration,
  SensorConfig,
  SensorEventData
} from 'src/devices/sensors';
import type { NullableValues } from 'src/types';
import type { InstallationType } from './installation-type';
import type { RelayAction } from './RelayAction';

export type BaseDeviceObject = {
  id: number;
  deviceId: string;
  devicePlatform?: 'argon' | 'boron' | 'bsom';
  deviceName: string;
  hardwareGeneration: HardwareGeneration;
  firmwareVersion?: number;
};
export type GlobalDeviceId = 'GLOBAL';
export type DeviceIdProp = Pick<BaseDeviceObject, 'deviceId'>;
export type DeviceNameProp = Pick<BaseDeviceObject, 'deviceName'>;

export type SensorConfigData = {
  [S in ConfigSensorName]: SensorConfig<S> | null;
} & {
  device?: never;
};

export interface DeviceConfig extends SensorConfigData, BaseDeviceObject {
  codaDeviceAlias: string;
  deviceInstallationType: InstallationType | undefined;
  isActive?: boolean;
}
export type PropInstallationType = Pick<DeviceConfig, 'deviceInstallationType'>;

export const deviceAdapter = createEntityAdapter<DeviceConfig>({
  selectId: (model) => model.deviceId,
  sortComparer: (deviceA, deviceB) =>
    deviceA.deviceName.localeCompare(deviceB.deviceName)
});

export type ReelRunEventData = {
  runCompletionPct: number;
  runEta: number;
  runHoursRemaining: number;
  runMinutesRemaining: number;
  runSpeedFtPerHr: number;
  runSpeedMPerHr: number;
};

/**
 * Event emitted by devices
 */
export type DeviceEvent = NullableValues<SensorEventData> &
  Omit<BaseDeviceObject, 'deviceName' | 'hardwareGeneration'> & {
    particleEventTimestamp: string;
    deviceEventTimestamp: string;
    indexId: number;
  };

export const eventsAdapter = createEntityAdapter<DeviceEvent>({
  selectId: (de) => de.deviceId,
  sortComparer: (eventA, eventB) => {
    const timeA = new Date(eventA.deviceEventTimestamp).getTime();
    const timeB = new Date(eventB.deviceEventTimestamp).getTime();
    return timeA - timeB;
  }
});
/**
 * Base for pairs, action triggers, and notifications
 */
export interface Trigger<
  S extends ConfigSensorName = ConfigSensorName,
  C extends GetStatesOfSensor<S> = GetStatesOfSensor<S>,
  P extends GetStatesOfSensor<S> = GetStatesOfSensor<S>
> {
  id: number;
  sourceDeviceId: string;
  targetDeviceId?: string | undefined;
  notify: boolean | null;
  sourceSensor: S;
  sourceSensorStateCurrent: C;
  sourceSensorStatePrevious: P;
  targetAction: RelayAction | null;
  notificationString: string | null;
}

export const TRIGGER_PROPERTY_KEYS = [
  'notify',
  'sourceSensor',
  'sourceSensorStateCurrent',
  'sourceSensorStatePrevious',
  'targetAction',
  'targetDeviceId',
  'notificationString'
] as const;
export type TriggerPropertyKey = typeof TRIGGER_PROPERTY_KEYS[number];
export type TriggerProperties = Pick<Trigger, TriggerPropertyKey>;
export type NotificationPayload = {
  readonly sensorType: null;
  readonly actionType: 'NOTIFY_ONLY';
  readonly actionArgument: {
    readonly notificationString: string;
    // "[device_name]'s [sensor_type] state changed from [state_previous] to [state_current]."
  };
};
export interface ActionTrigger<S extends ConfigSensorName = ConfigSensorName>
  extends Trigger<S> {
  targetAction: RelayAction;
}

/**
 * @param t
 */
export function isActionTrigger(t: Trigger): t is ActionTrigger {
  if (notNullish(t.targetAction) && notNullish(t.targetDeviceId)) {
    return true;
  }
  return false;
}

export interface NotificationTrigger<
  S extends ConfigSensorName = ConfigSensorName
> extends Trigger<S> {
  notify: true;
  notificationString: string;
}

/**
 * @param t
 */
export function isNotificationTrigger(t: Trigger): t is NotificationTrigger {
  return t.notify === true;
}
export interface DevicePair<S extends 'reel' | 'wheel' = 'reel' | 'wheel'>
  extends ActionTrigger<S> {
  targetDeviceId: string;
}

/**
 * @param t
 */
export function isDevicePair(t: Trigger): t is DevicePair {
  if (isActionTrigger(t)) {
    if (t.sourceSensor === 'reel') {
      return (
        t.sourceSensorStateCurrent === 'RS' &&
        t.sourceSensorStatePrevious === 'RR'
      );
    } else if (t.sourceSensor === 'wheel') {
      return (
        t.sourceSensorStateCurrent === 'WS' &&
        t.sourceSensorStatePrevious === 'WL'
      );
    }
  }
  return false;
}

export interface GlobalTrigger<S extends ConfigSensorName = ConfigSensorName>
  extends Trigger<S> {
  sourceDeviceId: 'GLOBAL';
}

export type PropDeviceAlias = Pick<DeviceConfig, 'codaDeviceAlias'>;
export type DeviceConfigOrEvent = DeviceConfig | DeviceEvent;
export type DeviceMetadata = DeviceIdProp &
  DeviceNameProp &
  PropDeviceAlias &
  PropInstallationType;
