import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AppButton, GridWrapper } from 'src/components';
import { asyncArchiveFields } from 'src/fields';
import useThunk from 'src/hooks/useThunk';
import { showSuccessToast } from 'src/theme/toasts';
import { pluralize } from 'src/utilities';

type Props = {
  fieldId: number;
  onCancel: () => void;
};

/**
 * @param props
 * @param props.fieldId
 * @param props.onCancel
 */
function ArchiveField({ fieldId, onCancel }: Props): JSX.Element {
  const { sendRequest } = useThunk(asyncArchiveFields);

  return (
    <React.Fragment>
      <Typography variant="body2">
        {`
  The ${pluralize({ count: 1, word: `field` })} will be hidden 
  from farm maps and ignored in updates on equipment location. 
  This will not impact data collection; if you reactivate the field any activity will 
  be immediately available.
  `}
      </Typography>
      <Box my={1}>
        <Alert severity="info">You can undo this action at any time</Alert>
      </Box>
      <GridWrapper justifyContent="space-between">
        <AppButton onClick={onCancel}>Cancel</AppButton>
        <AppButton
          onClick={async () =>
            sendRequest({ fieldIdList: [fieldId] })
              .then(() => {
                showSuccessToast();
                onCancel();
              })
              .catch((err) => {
                throw new Error(err);
              })
          }
          presetKey="SUBMIT"
        />
      </GridWrapper>
    </React.Fragment>
  );
}

export default ArchiveField;
