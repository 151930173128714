import { Grid } from '@material-ui/core';
import React from 'react';
import Actions from 'src/actions';
import { AppButton } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import FormWrapper from './FormWrapper';

/**
 *
 */
export default function Success(): JSX.Element {
  const dispatch = useAppDispatch();

  return (
    <FormWrapper
      instructions="Your configuration has been created"
      severity="success"
      testId="success"
    >
      <Grid container justifyContent="flex-end">
        <AppButton
          onClick={() => dispatch(Actions.setActiveFeature(null))}
          presetKey="DONE"
        />
      </Grid>
    </FormWrapper>
  );
}
