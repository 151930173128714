import type { GridSpacing } from '@material-ui/core';
import React from 'react';
import { AppButton, GridWrapper } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';

import { Box, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InstallationTypes from '../installation-type';
import { CreateConfig } from './createConfig.reducer';

import type { AlertProps } from '@material-ui/lab';
/**
 * @param props

 */
const { getInstallationType } = CreateConfig;

/**
 *
 */
function BackButton() {
  const dispatch = useAppDispatch();
  const stage = useAppSelector(CreateConfig.getCurrentStage);

  return (
    <AppButton
      id="go-back-btn"
      isHidden={stage === 'SUCCESS' || stage === 'INSTALLATION_TYPE'}
      onClick={() => dispatch(CreateConfig.goBack())}
    >
      Back
    </AppButton>
  );
}

export type WrapperProps = Pick<AlertProps, 'severity'> &
  React.PropsWithChildren<{
    spacing?: GridSpacing;
    submitBtn?: React.ReactNode;
    title?: string;
    instructions: string;
    testId: string;
  }>;

/**
 * @param props
 * @param props.title
 * @param props.instructions
 * @param props.children
 * @param props.testId
 * @param props.submitBtn
 * @param props.severity
 * @param props.spacing
 */
export default function FormWrapper({
  // title = 'Create Configuration',
  submitBtn,
  spacing = 2,
  severity = 'info',
  instructions,
  children,
  testId
}: WrapperProps): JSX.Element {
  const deviceInstallationType = useAppSelector(getInstallationType);
  return (
    <div data-cy={testId} id={testId}>
      {deviceInstallationType === 'unconfigured' ? null : (
        <Typography>
          Configure as {InstallationTypes.humanize(deviceInstallationType)}
        </Typography>
      )}
      <Box my={1}>
        <Alert severity={severity}>{instructions}</Alert>
      </Box>
      <Grid container justifyContent="space-between" spacing={spacing}>
        {React.Children.map(children, (child) => (
          <Grid container item justifyContent="space-between" xs={12}>
            {child}
          </Grid>
        ))}
      </Grid>
      <Box my={2}>
        <GridWrapper>
          <BackButton />
          {submitBtn}
        </GridWrapper>
      </Box>
    </div>
  );
}
