import type { SelectProps } from '@material-ui/core';
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import React from 'react';
import { useIsAnyRequestPending } from 'src/appSelectors';
import { isTruthyString } from 'src/types';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      // maxHeight: 300,
    },
    menuPaper: {
      maxHeight: 400
    },
    outlined: {
      // height: 100,
    },
    root: {
      // height: 100,
    },
    select: {
      // height: 100,
    },
    selectMenu: {
      // height: 200,
      // maxHeight: 200,
    }
  })
);

type Props<T extends number | string> = {
  readonly label?: string | null;
  readonly fieldName: string;
  readonly initialValue: T;
  readonly options: T[];
  readonly makeLabel?: (opt: T) => string;
  readonly isHidden?: boolean;
};

type ComponentProps = Omit<SelectProps, 'onChange' | 'value'>;

/**
 * Creates a select component and track its state
 *
 * @param props
 * @param props.label
 * @param props.options
 * @param props.initialValue
 * @param props.fieldName
 * @param props.makeLabel
 * @param props.validate
 * @param props.isHidden
 * @param props.testId
 */
export default function useControlledSelect<T extends number | string>({
  label,
  options,
  initialValue,
  fieldName,
  makeLabel,
  // validate,
  isHidden
}: Props<T>): {
  readonly value: T;
  readonly Component: (props: ComponentProps) => JSX.Element | null;
} {
  const isAnyRequestPending = useIsAnyRequestPending();
  const { formControl, menuPaper, ...classes } = useStyles();

  const [value, setValue] = React.useState<T>(initialValue);

  if (!isTruthyString(label)) {
    throw new Error(`no label`);
  }

  const MenuProps = {
    classes: {
      paper: menuPaper
    }
  };
  // eslint-disable-next-line react/function-component-definition

  return {
    /**
     * @param props
     */
    Component: function ControlledSelect(
      props: ComponentProps
    ): JSX.Element | null {
      return isHidden === true ? null : (
        <FormControl className={formControl} fullWidth>
          <InputLabel data-cy={`${fieldName}-label`} id={`${fieldName}-label`}>
            {label}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            classes={classes}
            data-cy={fieldName}
            disabled={isAnyRequestPending}
            fullWidth={true}
            id={fieldName}
            label={label}
            name={fieldName}
            variant="outlined"
            {...props}
            onChange={(e) => setValue(e.target.value as T)}
            value={value}
          >
            {options.map((opt) => (
              <MenuItem
                button
                data-cy={`${opt}`}
                id={`${opt}`}
                key={opt}
                value={opt}
              >
                {makeLabel ? makeLabel(opt) : `${opt}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    },
    value
  };
}
