import React from 'react';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { SensorConfigs } from '../sensors';
import useFormatSensorValue from '../sensors/useFormatSensorValue';
import ChoiceBtn from './ChoiceBtn';
import ChoicesWrapper from './ChoicesWrapper';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 * If the user is configuring a pump with a pressure switch sensor,
 * we need to know the default setting for the pressure switch
 * in order to calibrate readings
 */
export default function ChooseSwitchType(): JSX.Element {
  const dispatch = useAppDispatch();
  const formatValue = useFormatSensorValue();

  return (
    <FormWrapper
      instructions="Is the switch normally open or normally closed?"
      testId={testIds.createConfiguration.chooseSwitchType}
      title="Default Switch Setting"
    >
      <ChoicesWrapper>
        {SensorConfigs.SWITCH_TYPES.map((t) => (
          <ChoiceBtn
            key={t}
            onClick={() => dispatch(CreateConfig.submitSwitchType(t))}
            text={formatValue('switchType', t) ?? t}
          />
        ))}
      </ChoicesWrapper>
    </FormWrapper>
  );
}
