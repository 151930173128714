/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FabProps, Theme } from '@material-ui/core';
import { createStyles, Fab, makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import type {
  AbsolutePositionProps,
  ChecksPermission,
  HasIconKey,
  HideableProps,
  WithDataCy
} from 'src/components/types';
import { useAppDispatch } from 'src/hooks';
import AppIcon from 'src/theme/AppIcon';
import { denyPermission, User } from 'src/userSession.reducer';
import { appLogger } from 'src/utilities';

const useStyles = makeStyles<Theme, AbsolutePositionProps & HideableProps>(
  ({ zIndex }) => {
    return createStyles({
      root: (props) => {
        const styles = {
          visibility: props.isHidden === true ? 'hidden' : 'visible'
        } as const;
        if (props.absolute === true) {
          return {
            ...styles,
            bottom: props.bottom,
            left: props.left,
            position: 'absolute',
            right: props.right,
            top: props.top,
            zIndex: props.zIndex ?? zIndex.drawer + 2
          };
        }
        return {
          ...styles
        };
      }
    });
  }
);
type AppFabProps = AbsolutePositionProps &
  ChecksPermission &
  FabProps &
  HasIconKey &
  HideableProps &
  WithDataCy & {
    tooltip?: string;
  };

const AppFab = React.forwardRef<any, AppFabProps>(
  (
    {
      iconKey,
      absolute,
      top,
      left,
      tooltip,
      iconPosition = 'start',
      right,
      bottom,
      zIndex,
      type = 'submit',
      className,
      children = 'submit',
      id,
      dataCy,
      classes,
      isHidden,
      disabled,
      onClick,
      requiredPermissions,
      ...fabProps
    },
    ref
  ) => {
    const styles = useStyles({
      absolute,
      bottom,
      isHidden,
      left,
      right,
      top,
      zIndex
    });
    const dispatch = useAppDispatch();
    const permCheck = User.useHasPermissions(requiredPermissions);

    const mergedClasses = React.useMemo(
      () => ({ ...classes, ...styles }),
      [classes, styles]
    );

    const iconEl = iconKey ? <AppIcon iconKey={iconKey} /> : null;
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (process.env.NODE_ENV === 'development') {
          appLogger.log(dataCy, id);
        }
        if (permCheck.code === 'DENIED') {
          dispatch(denyPermission(permCheck));
        } else {
          onClick && onClick(e);
        }
      },
      [dataCy, dispatch, id, onClick, permCheck]
    );

    const button = (
      <Fab
        className={classNames(className, classes?.root, styles.root)}
        classes={mergedClasses}
        data-cy={dataCy ?? id}
        disabled={permCheck.code === 'DENIED' || disabled}
        id={id ?? dataCy}
        onClick={handleClick}
        ref={ref}
        type={type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...fabProps}
      >
        {iconPosition === 'start' ? iconEl : null}
        {children}
        {iconPosition === 'end' ? iconEl : null}
      </Fab>
    );
    let toolTipMessage: string | undefined = tooltip;
    if (permCheck.code === 'DENIED') {
      toolTipMessage = 'You do not have permission to perform this action';
    }
    if (typeof toolTipMessage === 'string') {
      return (
        <Tooltip title={toolTipMessage}>
          <span>{button}</span>
        </Tooltip>
      );
    }
    return button;
  }
);

AppFab.displayName = 'AppFab';

export type { AppFabProps };
export default AppFab;
