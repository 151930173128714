import type { ButtonProps } from '@material-ui/core';
import { Button } from '@material-ui/core';
import React from 'react';
import testIds from 'src/testIds';
import AppIcon from '../../theme/AppIcon';

export interface MailToProps extends ButtonProps {
  readonly toEmail?: string;
  readonly subject?: string;
  readonly body?: string;
}
/**
 * Opens system email client with pre-formatted email
 * @param props
 * @param props.toEmail
 * @param props.subject
 * @param props.body
 * @param props.children
 * @param props.rest
 */
export default function MailToButton({
  toEmail = ``,
  subject = ``,
  body = ``,
  children,
  ...rest
}: MailToProps): JSX.Element {
  /**
   *
   */
  const makeParams = () => {
    let params = subject || body ? `?` : ``;
    if (subject) {
      params += `subject=${encodeURIComponent(subject)}`;
    }
    if (body) {
      params += `${subject ? `&` : ``}body=${encodeURIComponent(body)}`;
    }
    return params;
  };

  const id = rest.id ?? testIds.mailToBtn;

  return (
    <Button
      href={`mailto:${toEmail}${makeParams()}`}
      startIcon={<AppIcon iconKey="SHARE" />}
      type="button"
      {...rest}
      data-cy={id}
      id={id}
    >
      {children}
    </Button>
  );
}
