import { Grid } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import React from 'react';
import { AppButton, Warning } from 'src/components';
import { useThunk } from 'src/hooks';
import type { DevicePermissionsMap } from 'src/permissions';
import { DEVICE_PERMISSION_KEYS } from 'src/permissions';
import PermissionsForm from 'src/permissions/PermissionsForm';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import type { DeviceIdProp } from '../models';
import { asyncCreateDeviceShareCode } from './deviceShareCodes.reducer';
import ExistingShareCode from './ExistingShareCode';

type State = {
  codeStr?: string;
};
type Props = DeviceIdProp & {
  onCancel: () => void;
  codeExists: boolean;
};

const testId = testIds.deviceOwnership.createShareCode.root;
/**
 * @param props
 * @param props.deviceId
 * @param props.onCancel
 * @param props.codeExists
 */
export default function CreateShareCode({
  deviceId,
  onCancel,
  codeExists
}: Props): JSX.Element {
  const { sendRequest } = useThunk(asyncCreateDeviceShareCode);

  const [state, setState] = React.useState<State>();

  const handleSubmit = async (formData: DevicePermissionsMap) =>
    sendRequest({ deviceId, permissions: formData })
      .then((res) => setState({ codeStr: res.codeStr }))
      .catch(handleApiRequestError);

  const codeExistsMsg = `This will invalidate the device's existing share code`;
  return (
    <div data-cy={testId} id={testId}>
      {codeExists ? (
        <Warning>
          <AlertTitle>Warning</AlertTitle>
          {codeExistsMsg}
        </Warning>
      ) : null}
      <PermissionsForm
        keys={DEVICE_PERMISSION_KEYS}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        // showSubheaders={false}
        showSubheaders
        subtitle="Set permissions for the recipient"
        successHandler={
          <ExistingShareCode
            codeStr={state?.codeStr ?? ''}
            testId={testIds.success}
          >
            <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
              <AppButton
                color="primary"
                id={testIds.doneBtn}
                onClick={onCancel}
                variant="contained"
              >
                Done
              </AppButton>
            </Grid>
          </ExistingShareCode>
        }
      />
    </div>
  );
}
