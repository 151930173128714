import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { kebabCase } from 'lodash-es';
import React from 'react';
import { useActiveFeatureState } from 'src/actions';
import {
  AppTextField,
  Attention,
  AppButton,
  CancelBtn,
  ClearActiveFeatureBtn,
  FormFeedback,
  SubmitBtn,
  Success
} from 'src/components';
import { RequestNames } from 'src/constants';
import type { DeviceShareCode } from 'src/devices';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import cancelOnPending from 'src/requests/cancelOnPending';
import makeApiRequest from 'src/requests/makeApiRequest';
import type { RootThunkApi } from 'src/store';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme/toasts';
import { useIsPending } from 'src/userSession.reducer';
import { humanize } from 'src/utilities';
import type { Overwrite } from 'utility-types';
import UnconfiguredDeviceWarning from '../config-manager/UnconfiguredDeviceWarning';
import type { DeviceConfig } from '../models';
import type { DeviceFarmAssociation } from './deviceFarmAssociations.reducer';
const Ids = testIds.addDevice;

type AddDeviceErrorCode =
  | 'ALREADY_ADDED'
  | 'ALREADY_CLAIMED'
  | 'EXPIRED'
  | 'INVALID';

type Success = {
  status: 'VALID';
  deviceConfiguration: DeviceConfig;
  deviceFarmAssociation: DeviceFarmAssociation;
} & (
  | {
      inputMode: 'ALIAS';
      deviceShareCode?: undefined;
    }
  | {
      inputMode: 'SHARE_CODE';
      deviceShareCode: DeviceShareCode;
    }
);

type ErrorResponse = {
  status?: AddDeviceErrorCode;
};

type State = {
  value: string;
  inputMode: 'ALIAS' | 'SHARE_CODE';
} & (
  | ErrorResponse
  | Overwrite<
      Success,
      {
        deviceShareCode: DeviceShareCode | undefined;
      }
    >
);

const { ADD_DEVICE } = RequestNames;

const asyncAddDeviceToFarmAccount = createAsyncThunk<
  ErrorResponse | Success,
  { value: string; inputMode: 'ALIAS' | 'SHARE_CODE' },
  RootThunkApi
>(
  ADD_DEVICE,
  async (args) => {
    try {
      const res = await makeApiRequest<ErrorResponse | Success>(ADD_DEVICE, {
        inputMode: args.inputMode,
        value: args.value.toLocaleLowerCase()
      });

      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  { condition: cancelOnPending(ADD_DEVICE) }
);

const INITIAL_STATE: State = {
  inputMode: 'ALIAS',
  value: ''
};
/**
 * @param state
 */
function getFeedbackMessage(state: State): React.ReactNode {
  return state.status === 'ALREADY_ADDED'
    ? 'This device has already been added to your farm account.'
    : state.status === 'EXPIRED'
    ? 'The code you entered is expired.'
    : state.status === 'ALREADY_CLAIMED'
    ? `The device has already been claimed by another farm. 
              If you are sharing the device with the owner farm, you can add it 
              to your account with a device share code provided to you by the owner. 
              If you believe this is an error, please contact customer support.`
    : state.status === 'INVALID'
    ? `The ${humanize(
        state.inputMode
      ).toLocaleLowerCase()} is invalid. Please double-check and try again.`
    : undefined;
}

type AddDeviceStatus = AddDeviceErrorCode | 'VALID';
export { asyncAddDeviceToFarmAccount };
export type { AddDeviceStatus, AddDeviceErrorCode };
/**
 * User enters share code or alias and submits form. If successful, device is immediately added to account.
 * @param props
 * @param props.onCancel
 * @param props.onCloseFeature
 * @param props.clearActiveFeature
 */
export default function AddDeviceToFarmAccount(): JSX.Element {
  const [state, setState] = React.useState<State>({
    ...INITIAL_STATE
  });

  const inputId = kebabCase(state.inputMode);
  const isSuccess = state.status === 'VALID';
  const isUsingShareCode = state.inputMode === 'SHARE_CODE';
  const isPending = useIsPending(asyncAddDeviceToFarmAccount);
  const { sendRequest } = useThunk(asyncAddDeviceToFarmAccount);
  const { activeFeature, setActiveFeature } = useActiveFeatureState();
  const handleClose = () => setActiveFeature(null);
  const disableForm = isSuccess || isPending || state.value.length === 0;

  /**
   *  Send request to API to add device
   *
   * If response status is "VALID", the device has been added to the farm account.
   * The response body returns a device configuration, device farm association,
   * device share code (if any exists) and a device event (if device has been recently active).
   *
   * Otherwise, the response body status is treated as an error, and a feedback message is shown
   */
  const onSubmit = (): Promise<void> =>
    sendRequest(state)
      .then((res) => {
        setState((prev): State => {
          if (res.status === 'VALID') {
            showSuccessToast();
            return {
              ...prev,
              deviceConfiguration: res.deviceConfiguration,
              deviceFarmAssociation: res.deviceFarmAssociation,
              deviceShareCode:
                res.inputMode === 'SHARE_CODE'
                  ? res.deviceShareCode
                  : undefined,
              status: 'VALID'
            };
          }
          return {
            status: res.status,
            inputMode: prev.inputMode,
            value: prev.value
          };
        });
      })
      .catch(handleApiRequestError);

  /**
   * Change from alias to share code and vice versa
   */
  const toggleInputMode = (): void => {
    return setState(
      (prev): State => ({
        ...prev,
        inputMode: prev.inputMode === 'SHARE_CODE' ? 'ALIAS' : 'SHARE_CODE'
      })
    );
  };

  /**
   * On success, user can choose to add another device which is achieved
   * by resetting state
   */
  const handleClickAddMore = (): void => setState({ ...INITIAL_STATE });

  /**
   * Update input value and clear error
   * @param e
   * @param e.target
   * @param e.target.value
   */
  const handleTextInput: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value }
  }): void => {
    setState((prev): State => ({ ...prev, value, status: undefined }));
  };

  return (
    <Dialog
      data-cy={Ids.root}
      id={Ids.root}
      open={activeFeature === 'ADD_DEVICE'}
    >
      <DialogTitle>
        Add Device To Farm Account
        <Typography color="textSecondary" variant="subtitle1">
          {isUsingShareCode ? 'Device Share Code' : 'Product Alias'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          {isSuccess ? null : (
            <Attention>
              {isUsingShareCode
                ? 'Enter the device share code provided to you by the owner farm'
                : `In order to enable your FarmHQ device, we need to validate its
                product alias.`}
            </Attention>
          )}
          {state.status === 'VALID' ? (
            <React.Fragment>
              <Success>
                <AlertTitle>Success</AlertTitle>
                {` ${state.deviceConfiguration.codaDeviceAlias} has been added to your farm account.`}
              </Success>
              <UnconfiguredDeviceWarning
                deviceId={state.deviceConfiguration.deviceId}
                deviceInstallationType={
                  state.deviceConfiguration.deviceInstallationType
                }
              />
            </React.Fragment>
          ) : (
            <Box py={2}>
              <AppTextField
                id={isUsingShareCode ? Ids.inputMode.shareCode : Ids.aliasInput}
                label={
                  isUsingShareCode
                    ? 'Enter Device Share Code'
                    : `Enter Product Alias`
                }
                name={inputId}
                onChange={handleTextInput}
                value={state.value}
              />
            </Box>
          )}
          <Link
            data-cy={Ids.toggleInputMode}
            id={Ids.toggleInputMode}
            onClick={toggleInputMode}
          >
            {isUsingShareCode ? 'Use product alias' : 'Use share code'}
          </Link>
          <FormFeedback>{getFeedbackMessage(state)}</FormFeedback>
        </Box>
      </DialogContent>
      <DialogActions>
        {isSuccess ? (
          <React.Fragment>
            <ClearActiveFeatureBtn text="Done" />
            <AppButton
              iconKey="ADD_ITEM"
              id={Ids.addMoreBtn}
              onClick={handleClickAddMore}
              text="add more"
              variant="text"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CancelBtn onClick={handleClose} />
            <SubmitBtn
              disabled={disableForm}
              id={Ids.submitBtn}
              onClick={onSubmit}
              showLoading={isPending}
            />
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}
