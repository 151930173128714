/* eslint-disable jsdoc/require-jsdoc */
import type { PointGeoJson } from 'src/geo';
import { isArrayOrReadonlyArray } from 'src/types';
import GeoPoints from './points';
import type {
  AnyLine,
  ArrayOfThree,
  LatLng,
  GmapsPolygonPath,
  PointCoordinatesArray,
  PointFeature
} from './types';

function isGeoJsonPointsArray(val: unknown): val is readonly PointGeoJson[] {
  if (isArrayOrReadonlyArray(val)) {
    if (val.length === 0) {
      return true;
    }
    const [sample] = val;

    return GeoPoints.is.geoJson(sample);
  }
  return false;
}

function isGmapsLiteralArray(val: unknown): val is GmapsPolygonPath {
  if (isArrayOrReadonlyArray(val)) {
    if (val.length) {
      return true;
    }
    const [sample] = val;
    return GeoPoints.is.gmapsLiteral(sample);
  }
  return false;
}

function isPointFeatureArray(val: unknown): val is readonly PointFeature[] {
  if (isArrayOrReadonlyArray(val)) {
    return val.every(GeoPoints.is.pointFeature);
  }
  return false;
}

function toCoordinates(path: AnyLine): PointCoordinatesArray {
  return path.map((pt) => GeoPoints.to.coordinates(pt));
}
function toGmapsPath(line: AnyLine): google.maps.LatLngLiteral[] {
  return line.map((point) => GeoPoints.to.googleMaps(point));
}

function isArrayOfLength(
  val: unknown,
  len: number
): val is ArrayOfThree<unknown> {
  return isArrayOrReadonlyArray(val) && val.length === len;
}
function isUnclosedGmapsPolygonPath(v: unknown): v is ArrayOfThree<LatLng> {
  return isArrayOfLength(v, 3) && v.every(GeoPoints.is.gmapsLiteral);
}

function toPointFeatureArray(val: AnyLine): readonly PointFeature[] {
  if (isPointFeatureArray(val)) {
    return val;
  }
  if (isGeoJsonPointsArray(val)) {
    return val.map((point) => GeoPoints.to.pointFeature(point));
  }
  return toPointFeatureArray(val.map(GeoPoints.to.geoJson));
}

const GeoLines = {
  is: {
    geoJsonPoints: isGeoJsonPointsArray,
    gmaps: isGmapsLiteralArray,
    pointFeatures: isPointFeatureArray
  },
  isArrayOfLength,
  isUnclosedGmapsPolygonPath,
  to: {
    coordinates: toCoordinates,
    featureArray: toPointFeatureArray,
    gmapsPath: toGmapsPath
  },
  toPointFeatureArray
};

export default GeoLines;
