import { createCachedSelector } from 're-reselect';
import { notNullish } from 'src/types';

import type { RootSelector, RootState } from 'src/store';
const getDeviceIsSearchMatch: RootSelector<
  boolean,
  [deviceId: string, searchValue: string]
> = createCachedSelector(
  (state: RootState, id: string) => state.devices.entities[id],
  (_state: RootState, _id: string, searchValue: string) => searchValue,
  (config, searchValue) => {
    if (searchValue === '') {
      return true;
    }
    if (notNullish(config)) {
      const { deviceName, deviceInstallationType } = config;
      if (
        notNullish(deviceInstallationType) &&
        (deviceInstallationType.includes(searchValue) ||
          deviceName.toLocaleLowerCase().includes(searchValue))
      ) {
        return true;
      }
    }
    return false;
  }
)((_state, deviceId, searchValue) => `${deviceId}/${searchValue}`);

export default getDeviceIsSearchMatch;
