import React from 'react';
import { useSelector } from 'react-redux';
import type { ZoomListenerProps } from '../components';
import { useAppDispatch } from '../hooks';
import { getCurrentMapZoom, setMapZoom } from './statusMap.reducer';

/**
 * @param props
 * @param props.currentZoom
 * @param currentZoom
 */
export function ZoomTracker({ currentZoom }: ZoomListenerProps): null {
  const dispatch = useAppDispatch();
  const committedZoom = useSelector(getCurrentMapZoom);
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentZoom !== committedZoom) {
        dispatch(setMapZoom(currentZoom));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [committedZoom, currentZoom, dispatch]);
  return null;
}
