import { appLogger, humanize } from 'src/utilities';
import type {
  FormatSensorKeyArgs,
  SensorFormattingArgs
} from './formatSensorValue';
import type { AnySensorKey } from './getConversionUnits';
import getConversionUnits from './getConversionUnits';

/**
 * @param args
 * @param args.key
 * @param args.options
 * @param args.options.labelLength
 * @param args.options.isAdmin
 * @param args.options.isMetric
 * @param args.options.unitLabel
 */
export default function formatSensorKey<K extends AnySensorKey>({
  key,
  options: { labelLength, isMetric, unitLabel }
}: FormatSensorKeyArgs<K, SensorFormattingArgs>): string {
  const useShort = labelLength === 'short';

  let result = '';
  switch (key) {
    // * CONFIGURATION
    case 'diameterMm': {
      result = `Wheel Diam${useShort ? '.' : 'eter'}`;
      break;
    }
    case 'hoseDiameterMm':
    case 'outerHoseWrapRadiusMm':
    case 'nozzleDiameterMm':
    case 'swathWidthMm':
    case 'voltageThresholdLowMv':
    case 'widthMm': {
      const long = `${humanize(key.slice(0, key.length - 2))}`;
      result = long;
      if (useShort) {
        result =
          key === 'outerHoseWrapRadiusMm'
            ? 'Hose Wrap Rad.'
            : key === 'nozzleDiameterMm'
            ? 'Nozzle Diam.'
            : key === 'hoseDiameterMm'
            ? 'Hose Diam.'
            : long;
      }

      break;
    }
    case 'gpsType':
      result = 'Chip Model';
      break;
    case 'thresholdPsiLower':
      result = useShort ? 'P.T. Low' : 'Lower Threshold';
      break;
    case 'thresholdPsiUpper':
      result = useShort ? 'P.T. High' : 'Upper Threshold';
      break;
    case 'nWrapsOuterLayer':
      result = useShort ? 'Num. Wraps' : 'Number of Outer Layer Wraps';
      break;
    case 'nMagnets':
      result = useShort ? 'Num. Magnets' : 'Number of Installed Magnets';
      break;
    case 'nNozzles':
      result = useShort ? 'Num. Nozzles' : 'Number of Nozzles';
      break;
    case 'ioPin':
      result = 'IO Pin';
      break;
    case 'switchType':
      result = 'Switch Default';
      break;
    case 'threshold':
      result = `${useShort ? 'P' : 'Pressure'} Threshold`;
      break;
    //  * EVENT
    case 'location':
      result = useShort ? 'Location' : 'Last Reported Location';
      break;
    case 'milliRpm':
      result = 'RPM';
      break;
    case 'nSats':
      result = useShort ? 'Signal' : 'Gps Signal Quality';
      break;
    case 'readingCelsius':
      result = useShort ? 'Temp' : 'Temperature';
      break;
    case 'pressureObservedKpa':
    case 'readingKpa':
      result = `Pressure ${useShort ? '' : ' Reading'}`;
      break;
    case 'runDistanceMmCurrent':
    case 'distanceObservedMm':
      result = `${useShort ? '' : 'Distance'} Remaining`;
      break;
    case 'distanceMmMax':
    case 'runDistanceMmMax':
      result = useShort ? 'Max Dist.' : 'Distance Extended from Reel';
      break;
    case 'runMagnetCountCurrent':
      result = useShort ? 'Mag. Count' : 'Current Magnets Counted';
      break;
    case 'runMagnetCountMax':
      result = useShort ? 'Mag. Max' : 'Max Magnets Counted';
      break;
    case 'sensorTriggeredUpdate':
      result = 'Sensor Triggered Update';
      break;
    case 'speedMillirpm':
      result = `Rot${useShort ? '.' : 'ational'} Speed`;
      break;
    case 'speedObservedMmpm':
    case 'runSpeedMmpm':
    case 'speedMmpm':
      result = 'Speed';
      break;
    case 'stateCurrent':
      result = `${useShort ? '' : 'Current'} Status`;
      break;
    case 'stateCurrentInitDt':
      result = 'Last Status Change';
      break;
    case 'statePrevious':
      result = `${useShort ? 'Prev.' : 'Previous'} Status`;
      break;
    case 'statePreviousInitDt':
      result = `${useShort ? 'Prev Change' : 'Previous Status Change'}`;
      break;
    case 'flowRateEstimatedLpm': {
      result = `${useShort ? '' : 'Est.'} Flow Rate`;
      break;
    }
    case 'runCompletionPct': {
      result = useShort ? 'Pct.' : 'Completion';
      break;
    }
    case 'runEta': {
      result = 'E.T.A.';
      break;
    }
    case 'otaEnabled': {
      result = 'O.T.A. Enabled';
      break;
    }
    case 'applicationRateEstimatedMm': {
      result = 'Application';
      break;
    }
    case 'runHoursRemaining': {
      result = 'Hours Remaining';
      break;
    }
    // Non-special cases
    case 'voltageMv':
    case 'voltageIntercept':
    case 'voltageSlope':
    case 'sprinklerType':
    case 'calibrationIntercept':
    case 'calibrationSlope':
    case 'sensorPort':
    case 'sensorPriority':
    case 'signalQuality':
    case 'signalStrength':
    case 'configurationId':
    case 'altitude':
    case 'sensorName':
      result = humanize(key);
      break;
    default:
      appLogger.log(`missed cased in formatSensorKey: ${key}`);
      if (process.env.NODE_ENV === 'production') {
        return humanize(key);
      }
      throw new Error(key as string);
  }
  if (unitLabel === true) {
    const conversionSpec = getConversionUnits({
      isMetric,
      key,
      target: 'user'
    });
    if (conversionSpec) {
      return `${result} (${conversionSpec.labels[labelLength ?? 'short']})`;
    }
  }
  return result;
}
