import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import { appLogger } from 'src/utilities';
import store from 'storejs';

import type { PlainObject } from 'src/types';
export type InferRequestArgType<T extends PlainObject | null> =
  T extends infer U ? U : T;
/**
 * Sends a request to Farm HQ API
 * @param requestedAction name of the ResourceAction class instance (in screaming snake case)
 * @param activeFarmId
 * @param actionArguments actions passed to handler
 * @param options
 * @param options.debug if true, logs the body
 */
export default async function makeApiRequest<R, A = PlainObject>(
  requestedAction: string,
  actionArguments?: A,
  options?: { readonly debug?: boolean }
): Promise<R> {
  const activeFarmId = store.get('activeFarmId') as unknown;
  const body = snakecaseKeys(
    {
      actionArguments,
      activeFarmId,
      devUserId: process.env.REACT_APP_DEV_USER_ID,
      requestedAction,
      testUserId: process.env.REACT_APP_TEST_USER_ID
    },
    { deep: true }
  );

  if (options?.debug === true) {
    appLogger.debug(body);
  }
  return axios
    .post<R>(``, body)
    .then((res) => res.data)
    .catch(async (err) => Promise.reject(err));
}
