import React from 'react';
import { AppButton } from 'src/components';
import {
  PressureThresholdForm as Form,
  usePressureThresholdForm
} from 'src/devices/sensors';
import { useAppDispatch } from 'src/hooks';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 * For pumps with analog pressure sensors, or reels/travelers with secondary
 * pressure sensors, the user may want to adjust the high/low
 * thresholds for the configuration.
 *
 * This form wraps the base pressure threshold form with submit-handler
 * logic
 *
 * @param props
 * @param props.onSubmit
 * @param props.backBtn
 */
export default function PressureThresholdForm(): JSX.Element {
  const { handleChange, rawValues } = usePressureThresholdForm();
  const dispatch = useAppDispatch();

  return (
    <FormWrapper
      instructions="Adjust pressure thresholds as needed"
      submitBtn={
        <AppButton
          id="submit-btn"
          onClick={() => dispatch(CreateConfig.submitThresholds(rawValues))}
          text="submit"
        />
      }
      testId="pressure-threshold-form"
    >
      <Form
        onChange={handleChange}
        valueLowerPsi={rawValues.thresholdPsiLower}
        valueUpperPsi={rawValues.thresholdPsiUpper}
        {...rawValues}
      />
    </FormWrapper>
  );
}
