import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelOnPending, makeApiRequest } from 'src/requests';
import { RequestNames } from '../constants';
import type { RootThunkApi } from '../store';
const { USER_JOIN_FARM_WITH_CODE } = RequestNames;
type JoinFarmErrorCode = 'ALREADY_JOINED' | 'EXPIRED' | 'INVALID';
interface SuccessResponse {
  codeStatus: 'SUCCESS';
  errorMessage?: undefined;
  farmName: string;
  activeFarmId: number;
}
interface ErrorResponse {
  codeStatus: JoinFarmErrorCode;
  errorMessage: string;
}
type JoinFarmRes = ErrorResponse | SuccessResponse;
type Arg = {
  codeString: string;
};

// export const asyncUserJoinFarm = createAsyncAppThunk<
//   { codeString: string },
//   JoinFarmRes,
//   JoinFarmErrorCode
// >(USER_JOIN_FARM_WITH_CODE, {
//   reject: {
//     key: 'codeStatus',
//     value: ['INVALID', 'ALREADY_JOINED', 'EXPIRED'] as const,
//   },
// });

const asyncUserJoinFarm = createAsyncThunk<JoinFarmRes, Arg, RootThunkApi>(
  USER_JOIN_FARM_WITH_CODE,
  async (arg) => {
    try {
      return await makeApiRequest<JoinFarmRes, Arg>(
        USER_JOIN_FARM_WITH_CODE,
        arg
      );
    } catch (error) {
      return Promise.reject(error);
    }
  },
  {
    condition: cancelOnPending(USER_JOIN_FARM_WITH_CODE)
  }
);

export type { JoinFarmErrorCode, JoinFarmRes };
export default asyncUserJoinFarm;
