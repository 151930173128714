import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedItem } from 'src/appSelectors';
import barnIconUrl from 'src/theme/icon/BarnPin.svg';
import { isNullish, isTruthyString } from 'src/types';
import { getActiveFarm, User } from 'src/userSession.reducer';

import { createStyles, makeStyles } from '@material-ui/core';
import { InfoWindow } from '@react-google-maps/api';

import MapCallout from './map-callout/MapCallout';
import MarkerBase from './MarkerBase';

import type { MarkerBaseProps } from 'src/components/map/MarkerBase';
import type { LatLng } from 'src/geo';
const useStyles = makeStyles(() =>
  createStyles({
    markerLabel: {
      textShadow: `1px 1px 5px rgb(0, 10, 10)`
    }
  })
);

// // TODO: Customize this
// /**
//  * @param props
//  * @param props.text
//  * @param props.className
//  */
// function createFarmMarkerLabel({
//   text,
//   className
// }: {
//   readonly text?: string;
//   readonly className?: string;
// } = {}): google.maps.MarkerLabel {
//   return {
//     className: classNames(className, 'active-farm-marker'),
//     color: `#fff`,
//     fontSize: `14px`,
//     fontWeight: `bold`,
//     text: text ?? `Your Farm`
//   };
// }

type FarmMarkerProps = Omit<MarkerBaseProps, 'label' | 'labelText'> & {
  readonly farmName: string;
};

/**
 * Shows farm location with custom icon
 * @param props
 * @param props.label
 * @param props.title
 * @param props.position
 * @param props.rest
 * @param props.farmName
 */
function FarmMarker({
  title,
  farmName,
  position,

  ...rest
}: FarmMarkerProps): JSX.Element | null {
  const classes = useStyles();
  const [isClicked, setIsClicked] = React.useState(false);

  const infoWindowOptionsMemo = React.useMemo(
    (): google.maps.InfoWindowOptions => ({
      pixelOffset: new google.maps.Size(0, -30)
    }),
    []
  );
  const markerIconMemo = React.useMemo(
    () => ({
      labelOrigin: new google.maps.Point(10, 45),
      scaledSize: new google.maps.Size(32, 48),
      url: barnIconUrl
    }),
    []
  );
  const markerLabelMemo = React.useMemo(
    () => ({
      className: classNames(classes.markerLabel, 'active-farm-marker'),
      color: `#fff`,
      fontSize: `14px`,
      fontWeight: `bold`,
      text: farmName ?? `Your Farm`
    }),
    [classes.markerLabel, farmName]
  );
  return !isNullish(position) ? (
    <React.Fragment>
      {isClicked ? (
        <InfoWindow options={infoWindowOptionsMemo} position={position}>
          <div>{farmName}</div>
        </InfoWindow>
      ) : null}
      <MarkerBase
        icon={markerIconMemo}
        onClick={() => setIsClicked(!isClicked)}
        title={title}
        {...rest}
        label={markerLabelMemo}
        position={position}
      />
    </React.Fragment>
  ) : null;
}
type Props = Omit<FarmMarkerProps, 'farmName' | 'position' | 'title'> & {
  readonly positionOverride?: LatLng | null;
};

const options: google.maps.InfoWindowOptions = {
  zIndex: 1
};
/**
 * @param props
 * @param props.position
 * @param props.label
 * @param props.positionOverride
 * @param props.rest
 */
export default function ActiveFarmMarker({
  positionOverride,
  ...rest
}: Props): JSX.Element | null {
  const { name } = useSelector(getActiveFarm) ?? {};
  const [showCallout, setShowCallout] = React.useState(false);
  const gpsLocation = useSelector(User.getFarmLocationGmaps);
  const selectedItem = useSelector(getSelectedItem);

  if (!isNullish(gpsLocation) && isTruthyString(name)) {
    const handleClick = (e: google.maps.MapMouseEvent): void => {
      e.stop();
      return setShowCallout(true);
    };
    return (
      <React.Fragment>
        {showCallout && isNullish(selectedItem) ? (
          <MapCallout
            onCloseClick={() => setShowCallout(false)}
            options={options}
            position={gpsLocation}
          >
            {name}
          </MapCallout>
        ) : null}
        <FarmMarker
          {...rest}
          farmName={!isNullish(positionOverride) ? 'New Location' : name}
          onClick={handleClick}
          position={positionOverride ?? gpsLocation}
          title="active-farm-marker"
        />
      </React.Fragment>
    );
  }
  return null;
}

export { FarmMarker };
