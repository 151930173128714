import { camelCase, isNumber } from 'lodash-es';
import type { LabelLength } from 'src/components';
import type { PointGeoJson } from 'src/geo';
import { Geo } from 'src/geo';
import { isNullish, isNumericString, isTruthyString } from 'src/types';
import type { DtFormat } from 'src/utilities';
import { DateFormatting, humanize } from 'src/utilities';
import convertSensorValue from './convertSensorValue';
import type { AnySensorKey } from './getConversionUnits';
import getConversionUnits from './getConversionUnits';
import humanizeSensorStateValue from './humanizeSensorStateValue';
import SensorConfigs from './SensorConfig';
import type { SensorName } from './SensorNames';
import SensorNames from './SensorNames';
import type { AnySensorStateValue, GetStatesOfSensor } from './SensorStates';
import type { FormatKeyResult } from './useSensorKeyFormatter';
interface SensorStateValueDetails<
  V extends AnySensorStateValue | null = AnySensorStateValue | null
> {
  readonly helpDescription?: string;
  readonly long: string;
  readonly rawValue: V;
  readonly short: string;
}

interface SensorFormattingOptions {
  readonly labelLength?: LabelLength;
  readonly dtFormat?: DtFormat;
  readonly unitLabel?: boolean;
}
interface SensorFormattingArgs extends SensorFormattingOptions {
  readonly isMetric: boolean;
  readonly isAdmin: boolean;
}

type SensorFormattingOptionsMap<K extends string> = {
  readonly [key in K]?: SensorFormattingArgs;
};
interface FormatSensorKeyArgs<
  K extends AnySensorKey,
  O extends SensorFormattingOptions = SensorFormattingOptions
> {
  readonly key: K;
  readonly options: O;
}
interface FormatValueArgs<
  K extends AnySensorKey,
  V,
  O extends SensorFormattingOptions
> extends FormatSensorKeyArgs<K, O> {
  readonly rawValue: V;
}

type RawSensorValue = PointGeoJson | boolean | number | string | null;

type FormattedSensorKeyValuePair<
  K extends AnySensorKey,
  V extends RawSensorValue = RawSensorValue
> = {
  readonly [Key in K]: FormatKeyResult & {
    readonly formatValue: (val: V, options?: SensorFormattingArgs) => string;
  };
};
export type {
  RawSensorValue,
  SensorFormattingArgs,
  SensorStateValueDetails,
  SensorFormattingOptions,
  SensorFormattingOptionsMap,
  FormatSensorKeyArgs,
  FormattedSensorKeyValuePair
};

/**
 * @param args
 * @param args.key
 * @param args.rawValue
 * @param args.options
 */
export default function formatSensorValue<
  S extends SensorName,
  K extends AnySensorKey,
  V extends RawSensorValue
>({
  key,
  rawValue,
  options: formatArgs
}: FormatValueArgs<K, V, SensorFormattingArgs>): string {
  const labelLength = formatArgs.labelLength ?? 'short';

  const inputValue = isNumericString(rawValue)
    ? parseFloat(rawValue)
    : rawValue;

  if (Geo.points.is.geoJson(inputValue)) {
    return JSON.stringify(inputValue);
  }
  if (inputValue === true) {
    return 'true';
  }
  if (inputValue === false) {
    return 'false';
  }
  if (inputValue === null) {
    return 'None';
  }
  if (isNumber(inputValue)) {
    // * HANDLE NUMBERS HERE
    let conversionResult: number = inputValue;
    if (key === 'nSats') {
      return `${
        inputValue < 3 ? 'poor' : inputValue < 6 ? 'moderate' : 'good'
      } (${inputValue} ${
        labelLength === 'short' ? 'sats.' : 'satellites in view'
      })`;
    }

    const conversionUnits = getConversionUnits({
      isMetric: formatArgs.isMetric,
      key,
      target: 'user'
    });
    if (!isNullish(conversionUnits)) {
      const converted = convertSensorValue({
        isMetric: formatArgs.isMetric,
        key,
        target: 'user',
        value: inputValue
      });
      conversionResult =
        converted.result === 'CONVERTED' ? converted.value : converted.value;
      const { labels } = conversionUnits;
      const selectedLabel = labels[labelLength];
      return `${conversionResult} ${selectedLabel}`;
    }
    conversionResult = inputValue;
    return inputValue.toFixed();
  }

  // * HANDLE EVERYTHING OTHER SPECIAL CASES HERE
  switch (key) {
    case 'statePreviousInitDt':
    case 'stateCurrentInitDt': {
      return (
        DateFormatting[formatArgs.dtFormat ?? 'toTimeAgoText'](inputValue) ??
        inputValue
      );
    }

    case 'switchType': {
      if (inputValue === SensorConfigs.SWITCH_TYPE.closed) {
        return 'Closed';
      }
      if (inputValue === SensorConfigs.SWITCH_TYPE.open) {
        return 'Open';
      }
      break;
    }
    case 'sprinklerType': {
      return humanize(inputValue);
    }
    case 'stateCurrent':
    case 'statePrevious': {
      if (isTruthyString(inputValue)) {
        return humanizeSensorStateValue(
          inputValue as unknown as GetStatesOfSensor<S>
        )[labelLength];
      }
      return '';
    }
    case 'gpsType': {
      return inputValue.toUpperCase();
    }
    case 'sensorName': {
      const toCamel = camelCase(inputValue);
      if (SensorNames.isValidSensorName(toCamel)) {
        return SensorNames.humanize(toCamel);
      }
      throw new Error(`invalid sensor name: ${toCamel}`);
    }

    default: {
      break;
    }
  }
  return inputValue;
}
