import type { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { isNumber, omit } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import Actions from 'src/actions';
import { getSelectedDeviceId } from 'src/appSelectors';
import { RequestNames } from 'src/constants';
import { makeApiRequest } from 'src/requests';
import { isNullish, isPlainObj, notNullish } from 'src/types';
import { User } from 'src/userSession.reducer';

import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import InstallationTypes from '../installation-type';
import { getSelectedDeviceMetadata } from '../selectors';
import { SensorConfigs, SensorNames } from '../sensors';

import type { RootState } from 'src/root.reducer';
import type { RootThunkApi } from 'src/store';
import type { ValuesType } from 'utility-types';
import type { DeviceConfig, DeviceMetadata } from '../models';
import type {
  AnySensorConfigKey,
  ConfigSensorName,
  PressureThresholds,
  PumpType,
  SensorConfig,
  SensorPort,
  SwitchType
} from '../sensors';
const CONFIG_TYPES = [
  InstallationTypes.reel,
  InstallationTypes.pump,
  InstallationTypes.softHoseTraveller
] as const;
type ConfigType = typeof CONFIG_TYPES[number];
const VALID_SENSORS = [
  SensorNames.reel,
  SensorNames.pressure,
  SensorNames.pressureSwitch,
  SensorNames.wheel
] as const;
type ValidSensor = typeof VALID_SENSORS[number];
type SensorData<S extends ConfigSensorName> = Omit<
  SensorConfig<S>,
  'sensorName'
>;
/**
 * @param target
 */
function removeSensorNames<T extends { [S in ValidSensor]?: SensorData<S> }>(
  target: T
) {
  return Object.entries(target).reduce(
    (acc, entry) => {
      const [key, val] = entry;
      if (notNullish(val)) {
        return {
          ...acc,
          [key]: isPlainObj(val) ? omit(val, 'sensorName') : val
        };
      }
      return {
        ...acc
      };
    },
    { ...target }
  );
}
const CONFIRM = 'CONFIRM' as const;
const DEVICE_NAME = 'DEVICE_NAME' as const;
const HAS_PRESSURE = 'HAS_PRESSURE' as const;
const HOSE = 'HOSE' as const;
const INSTALLATION_TYPE = 'INSTALLATION_TYPE' as const;
const NOZZLE = 'NOZZLE' as const;
const PRESSURE_THRESHOLDS = 'THRESHOLDS' as const;
const PUMP_TYPE = 'PUMP_TYPE' as const;
const SENSOR_PORT = 'SENSOR_PORT' as const;
const SPOOL = 'SPOOL' as const;
const SUCCESS = 'SUCCESS';
const SWITCH_TYPE = 'SWITCH_TYPE' as const;
const WHEEL = 'WHEEL' as const;
const SEQUENCES = {
  pressure: [
    PUMP_TYPE,
    PRESSURE_THRESHOLDS,
    SENSOR_PORT,
    DEVICE_NAME,
    CONFIRM,
    SUCCESS
  ],
  pressureSwitch: [
    PUMP_TYPE,
    SENSOR_PORT,
    SWITCH_TYPE,
    DEVICE_NAME,
    CONFIRM,
    SUCCESS
  ],
  reel: [
    INSTALLATION_TYPE,
    HOSE,
    NOZZLE,
    SPOOL,
    HAS_PRESSURE,
    PRESSURE_THRESHOLDS,
    SENSOR_PORT,
    DEVICE_NAME,
    CONFIRM,
    SUCCESS
  ],
  traveller_soft: [
    WHEEL,
    HAS_PRESSURE,
    PRESSURE_THRESHOLDS,
    SENSOR_PORT,
    DEVICE_NAME,
    CONFIRM,
    SUCCESS
  ],
  unconfigured: [INSTALLATION_TYPE]
} as const;
const { CREATE_CONFIGURATION } = RequestNames;

type Sequence = ValuesType<typeof SEQUENCES>;
type Stage = Sequence[number];
interface State {
  deviceName?: string;
  sensorData: {
    [K in ValidSensor]?: Partial<SensorData<K>> | undefined;
  };
  secondaryPressure?: boolean;
  pumpSensor?: PumpType;
  navStack: Stage[];
  installationType: ConfigType | 'unconfigured';
}

const initialState: State = {
  installationType: 'unconfigured',
  navStack: [INSTALLATION_TYPE],
  sensorData: {}
};

const getSlice = (state: RootState): State => state.createConfiguration;
const getDeviceName = createSelector(getSlice, (state) => state.deviceName);
const getSensorData = createDraftSafeSelector(
  getSlice,
  (state) => state.sensorData
);
const createSensorSelector = <S extends ValidSensor>(sensorName: S) => {
  return createDraftSafeSelector(getSensorData, (data) => data[sensorName]);
};
const getReel = createSensorSelector('reel');
const getPressure = createSensorSelector('pressure');
const getPressureSwitch = createSensorSelector('pressureSwitch');
const getWheel = createSensorSelector('wheel');

const getSensorDataByName = createCachedSelector(
  (state: RootState) => getSensorData(state),
  (_: RootState, sensorName: ValidSensor) => sensorName,
  (data, sensorName) => data[sensorName]
)((_state, sensorName) => sensorName);

const getInstallationType = createSelector(
  getSlice,
  (state) => state.installationType
);
const getPumpType = createSelector(getSlice, (state) => state.pumpSensor);
const chooseSequence = (
  configType: State['installationType'],
  pumpType: State['pumpSensor']
): Sequence => {
  if (configType === 'pump') {
    if (pumpType) {
      return SEQUENCES[pumpType];
    }
    return SEQUENCES.pressure;
  }

  return SEQUENCES[configType];
};
const getSequence = createSelector(
  getInstallationType,
  getPumpType,
  (state: RootState) => state.createConfiguration.secondaryPressure,
  chooseSequence
);
const getNavStack = createSelector(getSlice, (state) => state.navStack);
const getCurrentStage = createDraftSafeSelector(getNavStack, (stack) =>
  [...stack].pop()
);

/**
 * @param state
 */
function findNextStage(
  state: Pick<
    State,
    'installationType' | 'navStack' | 'pumpSensor' | 'secondaryPressure'
  >
): Stage {
  const { installationType, pumpSensor, navStack, secondaryPressure } = state;
  const seq = chooseSequence(installationType, pumpSensor);
  const stage = [...navStack].pop() ?? INSTALLATION_TYPE;
  const index = seq.findIndex((s) => s === stage);
  const next = seq[index + 1];

  // If configuring a mobile irrigator (e.g. reel/traveller),
  // if the e device   doesn't have a secondary pressure sensor
  //  (because, for example, it is a PC1 ), skip the threshold stage

  if (
    next === 'THRESHOLDS' &&
    installationType !== 'pump' &&
    secondaryPressure === false
  ) {
    // adjust stack and re-run function
    return findNextStage({
      ...state,
      navStack: [...navStack, PRESSURE_THRESHOLDS]
    });
  }
  if (typeof next === 'undefined') {
    return SUCCESS;
  }
  return next;
}

const getPrevStage = createDraftSafeSelector(getNavStack, (stack): Stage => {
  const prev = stack[stack.length - 1];
  if (typeof prev === 'undefined') {
    return INSTALLATION_TYPE;
  }
  return prev;
});
const findPrimarySensor = (
  configType: State['installationType'],
  pumpType?: State['pumpSensor']
): ValidSensor => {
  if (configType === 'pump') {
    return pumpType ?? 'pressureSwitch';
  }
  if (configType === 'reel') {
    return configType;
  }
  return 'wheel';
};
const getPrimarySensor = createSelector(
  getInstallationType,
  getPumpType,
  findPrimarySensor
);

/**
 * @param sensorName
 * @param input
 */
function isValid<S extends ValidSensor>(
  sensorName: S,
  input?: Partial<SensorData<S>>
): input is SensorData<S> {
  const data = input;

  const keys = SensorConfigs.getKeysOfSensorConfig(sensorName);

  return keys.every((key) => {
    if (!data) {
      return false;
    }
    const value = data[key as keyof typeof data];
    switch (key as AnySensorConfigKey) {
      case 'sensorName':
      case 'threshold': {
        return true;
      }
      case 'sprinklerType':
        return SensorConfigs.isValidSprinklerType(value);
      case 'sensorPort':
        return SensorConfigs.isValidSensorPort(value);
      case 'switchType':
        return SensorConfigs.isValidSwitchType(value);
      default:
        return isNumber(value);
    }
  });
}

const asyncCreateConfiguration = createAsyncThunk<
  DeviceConfig,
  void,
  RootThunkApi
>(CREATE_CONFIGURATION, async (_, { getState, rejectWithValue }) => {
  try {
    const rootState = getState();
    const state = rootState.createConfiguration;

    const permCheck = User.getHasPermissions(
      rootState,
      'canManageCustomTriggers'
    );
    if (permCheck.code === 'DENIED') {
      return rejectWithValue(permCheck);
    }

    type BaseArgs = {
      deviceId: string;
      deviceName: string;
    };

    interface PumpArgs {
      deviceInstallationType: 'pump';
      sensorData:
        | {
            pressure?: undefined;
            pressureSwitch: SensorData<'pressureSwitch'>;
          }
        | {
            pressureSwitch?: undefined;
            pressure: SensorData<'pressure'>;
          };
    }

    interface ReelArgs {
      deviceInstallationType: 'reel';
      sensorData: {
        reel: SensorData<'reel'>;
        pressure?: SensorData<'pressure'>;
      };
    }

    interface TravellerArgs {
      sensorData: {
        wheel: SensorData<'wheel'>;
        pressure?: SensorData<'pressure'>;
      };
      deviceInstallationType: 'traveller_soft';
    }

    type ThunkArg = BaseArgs & (PumpArgs | ReelArgs | TravellerArgs);
    // if (isNullish())
    const codaDeviceAlias =
      getSelectedDeviceMetadata(rootState)?.codaDeviceAlias;

    const deviceInstallationType = state.installationType;
    const { deviceName, sensorData, pumpSensor } = state;
    if (
      isNullish(codaDeviceAlias) ||
      deviceInstallationType === 'unconfigured' ||
      isNullish(deviceName)
    ) {
      throw new Error(
        `Invalid args ${JSON.stringify({
          codaDeviceAlias,
          deviceInstallationType,
          deviceName
        })}`
      );
    }
    const deviceId = getSelectedDeviceId(rootState);
    if (isNullish(deviceId)) {
      throw new Error('No device id in state');
    }
    const baseArgs: Omit<ThunkArg, 'deviceInstallationType' | 'sensorData'> = {
      deviceId,
      deviceName
    };

    let actionArguments: ThunkArg | undefined = undefined;

    const { pressure, pressureSwitch, reel, wheel } = sensorData;
    if (state.pumpSensor === 'pressure' || state.secondaryPressure === true) {
      if (!isValid('pressure', pressure)) {
        throw new Error(`Invalid pressure data: ${JSON.stringify(pressure)}`);
      }
    }

    switch (deviceInstallationType) {
      case 'pump':
        if (pumpSensor === 'pressure') {
          if (!isValid('pressure', pressure)) {
            throw new Error(
              `Invalid pressure data: ${JSON.stringify(pressure)}`
            );
          }
          actionArguments = {
            ...baseArgs,
            deviceInstallationType: 'pump',
            sensorData: {
              pressure
            }
          };
        } else {
          if (!isValid('pressureSwitch', pressureSwitch)) {
            throw new Error(
              `Invalid pressureSwitch ${JSON.stringify(pressureSwitch)}`
            );
          }
          actionArguments = {
            ...baseArgs,
            deviceInstallationType,
            sensorData: { pressureSwitch }
          };
        }
        break;
      case 'reel': {
        if (!isValid('reel', reel)) {
          throw new Error(`Invalid data: ${JSON.stringify(reel)}`);
        }
        actionArguments = {
          ...baseArgs,
          deviceInstallationType,
          sensorData: { reel }
        };
        if (state.secondaryPressure === true) {
          if (!isValid('pressure', pressure)) {
            throw new Error(
              `Invalid pressure data: ${JSON.stringify(pressure)}`
            );
          }
          actionArguments.sensorData.pressure = { ...pressure };
        }
        break;
      }
      case 'traveller_soft': {
        if (!isValid('wheel', wheel)) {
          throw new Error(`Invalid data: ${JSON.stringify(reel)}`);
        }
        actionArguments = {
          ...baseArgs,
          deviceInstallationType,
          sensorData: {
            wheel
          }
        };
        if (state.secondaryPressure === true) {
          if (!isValid('pressure', pressure)) {
            throw new Error(
              `Invalid pressure data: ${JSON.stringify(pressure)}`
            );
          }
          actionArguments.sensorData.pressure = {
            ...pressure,
            sensorPort: pressure.sensorPort
          };
        }
        break;
      }

      default:
        throw new Error('Invalid installation type');
    }

    const response = await makeApiRequest<DeviceConfig, ThunkArg>(
      CREATE_CONFIGURATION,
      {
        ...actionArguments,
        sensorData: { ...removeSensorNames(actionArguments.sensorData) }
      } as ThunkArg
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
});
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncCreateConfiguration.fulfilled, (state) => {
        state.navStack.push(SUCCESS);
      })
      .addCase(Actions.setActiveFeature, () => {
        return { ...initialState };
      }),
  initialState,
  name: 'createConfiguration',
  reducers: {
    /**
     * @param state
     */
    cancel() {
      return {
        ...initialState,
        sensorData: {
          ...initialState.sensorData
        }
      };
    },

    /**
     * @param state
     */
    goBack(state) {
      state.navStack = state.navStack.slice(0, -1);
      if (state.navStack.length === 0) {
        state.navStack = [...initialState.navStack];
      }
    },

    /**
     * @param _
     * @param action
     */
    initialize(_, action: PayloadAction<DeviceMetadata>) {
      return {
        ...initialState,
        metadata: action.payload,
        sensorData: {
          ...initialState.sensorData
        }
      };
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitDeviceName(state, { payload }: PayloadAction<string | undefined>) {
      state.deviceName = payload;
      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitHasSecondaryPressure(state, { payload }: PayloadAction<boolean>) {
      state.secondaryPressure = payload;
      if (!payload) {
        state.sensorData.pressure = undefined;
      }

      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitInstallationType(state, { payload }: PayloadAction<ConfigType>) {
      state.installationType = payload;
      state.secondaryPressure = payload === 'pump' ? false : undefined;
      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitPumpType(state, { payload }: PayloadAction<PumpType>) {
      state.pumpSensor = payload;
      if (payload === 'pressure') {
        state.sensorData.pressureSwitch = undefined;
      } else {
        state.sensorData.pressure = undefined;
      }
      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitReelData(
      state,
      { payload }: PayloadAction<Partial<SensorData<'reel'>>>
    ) {
      state.sensorData.reel = { ...state.sensorData.reel, ...payload };
      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     * @param action
     */
    submitSensorPort(
      state,
      action: PayloadAction<{
        sensorName: ValidSensor;
        sensorPort: SensorPort;
      }>
    ) {
      if (isNullish(state.secondaryPressure)) {
        throw new Error(`Secondary pressure is nullish`);
      }

      const { sensorName, sensorPort } = action.payload;
      if (sensorName === 'pressure') {
        state.sensorData.pressure = {
          ...state.sensorData.pressure,
          sensorPort
        };
      } else {
        state.sensorData[sensorName] = {
          ...state.sensorData[sensorName],
          sensorPort
        };
        if (state.secondaryPressure) {
          const secondary: SensorPort = sensorPort === 1 ? 2 : 1;
          state.sensorData.pressure = {
            ...state.sensorData.pressure,
            sensorPort: secondary
          };
        }
      }

      state.navStack.push(findNextStage(state));
    },

    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitSwitchType(state, { payload }: PayloadAction<SwitchType>) {
      state.sensorData.pressureSwitch = {
        ...state.sensorData.pressureSwitch,
        switchType: payload
      };
      state.sensorData.wheel = undefined;
      state.sensorData.reel = undefined;
      state.sensorData.pressure = undefined;
      state.navStack.push(findNextStage(state));
    },
    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitThresholds(state, { payload }: PayloadAction<PressureThresholds>) {
      state.sensorData.pressure = {
        ...state.sensorData.pressure,
        ...payload
      };
      if (state.secondaryPressure !== true) {
        state.sensorData.reel = undefined;
        state.sensorData.pressureSwitch = undefined;
        state.sensorData.wheel = undefined;
      }
      state.navStack.push(findNextStage(state));
    },
    /**
     * @param state
     * @param args
     * @param args.payload
     */
    submitWheelData(state, { payload }: PayloadAction<SensorData<'wheel'>>) {
      state.sensorData.wheel = {
        ...state.sensorData.wheel,
        ...payload
      };
      state.sensorData.reel = undefined;
      state.sensorData.pressureSwitch = undefined;
      state.navStack.push(findNextStage(state));
    }
  }
});

const { actions } = slice;
/**
 * @param id
 */

const createConfiguration: Reducer<State> = slice.reducer;
export default createConfiguration;
const CreateConfig = {
  ...actions,
  CONFIG_TYPES,
  VALID_SENSORS,
  getCurrentStage,
  getDeviceName,
  getInstallationType,
  getNavStack,

  getPressure,
  getPressureSwitch,
  getPrevStage,
  getPrimarySensor,
  getPumpType,
  getReel,
  getSensorData,
  getSensorDataByName,
  getSequence,
  getWheel
};
export type { ConfigType, ValidSensor };
export { CreateConfig, asyncCreateConfiguration };
