import type { ValuesType } from 'utility-types';

const DASHBOARD_URL = {
  account: '/account',
  devices: '/devices',
  field: '/fields',
  map: '/',
  support: '/support'
} as const;

const ACCOUNT_PATHS = {
  base: DASHBOARD_URL.account,
  joinFarm: `${DASHBOARD_URL.account}/join-farm`,
  newFarm: `${DASHBOARD_URL.account}/new-farm`,
  newJoinCode: `${DASHBOARD_URL.account}/new-join-code`,
  permissionEditor: `${DASHBOARD_URL.account}/permissions/:email`,
  repositionMarker: `${DASHBOARD_URL.account}/reposition-marker`
} as const;

const DEVICE_PATHS = {
  base: DASHBOARD_URL.devices,
  configuration: `${DASHBOARD_URL.devices}/configuration/:codaDeviceAlias`,
  events: `${DASHBOARD_URL.devices}/events/:codaDeviceAlias`,
  newConfiguration: `${DASHBOARD_URL.devices}/new-configuration/:codaDeviceAlias`,
  validateAlias: `${DASHBOARD_URL.devices}/validate-alias`
} as const;
const NO_FARMS_PATHS = {
  base: `/`,
  newFarm: `/new-farm`
} as const;
const FIELDS_PATHS = {
  base: DASHBOARD_URL.field,
  editShape: `${DASHBOARD_URL.field}/edit-shape/:fieldId`,
  fieldHistory: `${DASHBOARD_URL.field}/run-history/:fieldId`,
  newField: `${DASHBOARD_URL.field}/new-field`
} as const;

const SUPPORT_PATHS = {
  base: DASHBOARD_URL.support,
  basicUsage: `${DASHBOARD_URL.support}/basic-usage`,
  deviceOverview: `${DASHBOARD_URL.support}/device-overview`,
  devices: `${DASHBOARD_URL.support}/devices`,
  faq: `${DASHBOARD_URL.support}/faq`,
  farm: `${DASHBOARD_URL.support}/farm`,
  fields: `${DASHBOARD_URL.support}/fields`,
  inTheBox: `${DASHBOARD_URL.support}/in-the-box`,
  pumpInstallation: `${DASHBOARD_URL.support}/pump-installation`,
  reelInstallation: `${DASHBOARD_URL.support}/reel-installation`,
  sensorInstallation: `${DASHBOARD_URL.support}/sensorInstallation`,
  troubleshooting: `${DASHBOARD_URL.support}/troubleshooting`
} as const;
const PATHS = {
  auth: {
    confirmSignUp: '/confirm-sign-up',
    passwordReset: `/password-reset`,
    signIn: `/`,
    signUp: `/sign-up`,
    signOut: '/sign-out'
  },
  development: {
    root: '/development'
  },
  account: ACCOUNT_PATHS,
  dashboard: DASHBOARD_URL,
  devices: DEVICE_PATHS,
  // farm: FARM_PATHS,
  fields: FIELDS_PATHS,
  noFarms: NO_FARMS_PATHS,
  support: SUPPORT_PATHS
} as const;
type BasePagePath = ValuesType<typeof PATHS.dashboard>;
export default PATHS;
export type { BasePagePath };
export { DASHBOARD_URL, DEVICE_PATHS, ACCOUNT_PATHS, FIELDS_PATHS };
