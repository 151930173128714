import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { captureException } from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import type { RouteChildrenProps } from 'react-router-dom';
import { AppButton } from 'src/components';
import PATHS from 'src/constants/Paths';
import useThunk from 'src/hooks/useThunk';
import type { FarmUserPermissionsMap } from 'src/permissions';
import { FARM_USER_PERMISSION_KEYS } from 'src/permissions';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showErrorToast } from 'src/theme';
import { isNullish, isTruthyString, notNullish } from 'src/types';
import PermissionsForm from '../permissions/PermissionsForm';
import {
  asyncLoadPermissionEditor,
  asyncUpdateFarmUserPermissions,
  FarmUsers
} from './farmUsers.reducer';

/**
 * @param props
 * @param props.match
 */
export default function UserPermissionEditor({
  match
}: RouteChildrenProps<{ email: string }>): JSX.Element | null {
  const email = match?.params.email;

  const targetUserId = useSelector((state) =>
    isTruthyString(email) ? FarmUsers.selectById(state, email) : undefined
  )?.userId;
  const [initialPermissions, setInitialPermissions] =
    React.useState<FarmUserPermissionsMap>();

  const { sendRequest: getPermissions, isPending } = useThunk(
    asyncLoadPermissionEditor
  );
  React.useEffect(() => {
    if (
      notNullish(targetUserId) &&
      !isPending &&
      isNullish(initialPermissions)
    ) {
      getPermissions({ targetUserId })
        .then((res) => {
          setInitialPermissions(res);
        })
        .catch((e) => {
          handleApiRequestError(e);
        });
    }
  }, [getPermissions, initialPermissions, isPending, targetUserId]);
  const { sendRequest } = useThunk(asyncUpdateFarmUserPermissions);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleSubmit = async (
    formData: FarmUserPermissionsMap
  ): Promise<void> => {
    if (isNullish(formData) || isNullish(targetUserId)) {
      throw new Error(
        'In permissions editor: permissions or target user id are null'
      );
    }
    await sendRequest({
      permissions: { ...formData },
      targetUserId
    })
      .then(() => setIsSuccess(true))
      .catch((e) => {
        captureException(e);
        showErrorToast();
      });
  };
  if (
    isTruthyString(email) &&
    isTruthyString(targetUserId) &&
    notNullish(initialPermissions)
  ) {
    return (
      <div
        data-cy={testIds.farmManager.permissionEditor.root}
        id={testIds.farmManager.permissionEditor.root}
      >
        <PermissionsForm
          initialPermissions={initialPermissions}
          keys={FARM_USER_PERMISSION_KEYS}
          onSubmit={handleSubmit}
          subtitle={`Allow ${email} to perform the following actions:`}
          successHandler={
            <Dialog
              data-cy={testIds.success}
              id={testIds.success}
              maxWidth="xs"
              open={isSuccess}
            >
              <DialogTitle>Success</DialogTitle>
              <DialogContent>
                <Alert>User permissions updated</Alert>
              </DialogContent>
              <DialogActions>
                <AppButton
                  id={testIds.doneBtn}
                  linkTo={PATHS.account.base}
                  text="Done"
                />
              </DialogActions>
            </Dialog>
          }
        />
      </div>
    );
  }
  return null;
}
