/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { Reducer } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import useAppSelector from 'src/hooks/useAppSelector';

import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending as isPendingThunk,
  isRejected
} from '@reduxjs/toolkit';

import {
  asyncGetSignUpCode,
  asyncResendPasswordResetCode,
  asyncSignUp,
  asyncSubmitNewPassword,
  asyncSubmitSignUpConfirmation,
  clearAuthError,
  isRejectedAuthThunk,
  setAuthStage
} from './actions';
import { INVALID_CODE_MESSAGE, isAuthErrorCode } from './errors';

import type { RootState } from 'src/root.reducer';
import type { AuthErrorCodeValue } from './errors';
type AuthFieldName =
  | 'codeString'
  | 'confirmPassword'
  | 'currentPassword'
  | 'email'
  | 'newPassword';
type AuthStatus = 'AUTHENTICATED' | 'NOT_AUTHENTICATED' | 'SHOULD_CHECK';

type AuthInteractionStage =
  | 'Confirm Sign Up'
  | 'Password Reset'
  | 'Sign In'
  | 'Sign Up';

type AuthErrorMessage =
  | 'Account does not exist'
  | 'An account registered to that email address already exists.'
  | 'Incorrect email or password'
  | 'The code you entered is invalid. Please double-check and try again.'
  | 'Your account is already confirmed. You can sign in with your email and password.'
  | 'Your account is currently disabled; you need to reset your password. Click "Forgot Password" below';
export interface AuthState {
  readonly errorCode: AuthErrorCodeValue | null;
  readonly errorMessage: AuthErrorMessage | null;
  readonly currentStage: AuthInteractionStage | null;
  readonly requestPending: boolean;
  readonly codeRequestPending: boolean;
  readonly isSuccess: boolean;
}

const initialState: AuthState = {
  codeRequestPending: false,
  currentStage: null,
  errorCode: null,
  errorMessage: null,
  isSuccess: false,
  requestPending: false
};
/**
 * @param state
 */
function getAuthInteractionStage(state: RootState) {
  return state.auth.currentStage;
}
/**
 * @param state
 */
function getAuthErrorCode(state: RootState): AuthErrorCodeValue | null {
  return state.auth.errorCode ?? null;
}

/**
 *
 */
function useAuthErrorMessage(): AuthErrorMessage | null {
  return useAppSelector((state) => state.auth.errorMessage) ?? null;
}
/**
 *
 */
function useAuthInteraction(): AuthInteractionStage | null {
  return useAppSelector((state) => state.auth.currentStage);
}
/**
 *
 */
function useIsAuthRequestPending(): boolean {
  return useAppSelector((state) => state.auth.requestPending);
}
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(setAuthStage, (state, { payload }) => {
        state.currentStage = payload;
        state.errorMessage = null;
        state.errorCode = null;
        state.isSuccess = false;
      })
      // .addCase(asyncCheckAuth.pending, (state) => {

      // })
      .addCase(asyncGetSignUpCode.fulfilled, (state) => {
        state.codeRequestPending = false;
      })
      .addMatcher(
        isAnyOf(
          asyncSignUp.fulfilled,
          asyncSubmitNewPassword.fulfilled,
          asyncSubmitSignUpConfirmation.fulfilled
        ),
        (state) => {
          state.isSuccess = true;
        }
      )
      .addMatcher(
        isAnyOf(
          asyncGetSignUpCode.pending,
          asyncResendPasswordResetCode.pending
        ),
        /**
         *
         * @param state
         */
        function onSubmitCodeRequest(state) {
          state.codeRequestPending = true;
        }
      )
      .addMatcher(
        isAnyOf(
          asyncGetSignUpCode.fulfilled,
          asyncResendPasswordResetCode.fulfilled
        ),
        /**
         *
         * @param state
         * @param action
         */
        function onCompletedCodeRequest(state) {
          state.codeRequestPending = false;
        }
      )
      // .addMatcher(
      //   isAnyOf(
      //     asyncSubmitForgotPasswordEmail.fulfilled,
      //     asyncSubmitSignUpConfirmation.fulfilled
      //   ),
      //   (state, action) => {
      //     state.email = action.meta.arg.email;
      //   }
      // )
      .addMatcher(isAnyOf(clearAuthError), (state) => {
        state.currentStage = 'Sign In';
      })
      // .addMatcher(
      //   isAnyOf(asyncCheckAuth.fulfilled, asyncSignIn.fulfilled),
      //   (state, action) => {
      //     state.authStatus = 'AUTHENTICATED';
      //     state.currentStage = null;
      //     state.email = action.payload;
      //   }
      // )
      .addMatcher(isPendingThunk, (state) => {
        state.requestPending = true;
        state.isSuccess = false;
      })
      .addMatcher(isRejectedAuthThunk, (state, action) => {
        state.isSuccess = false;
        if (action.meta.rejectedWithValue) {
          const errorCode = action.payload?.code;
          if (isAuthErrorCode(errorCode)) {
            state.errorCode = errorCode;
          }
          switch (errorCode) {
            case null:
            case undefined:
            case 'UserNotConfirmedException':
            case 'No current user': {
              break;
            }

            case 'CodeMismatchException': {
              state.errorMessage = INVALID_CODE_MESSAGE;
              break;
            }
            case 'NotAuthorizedException': {
              if (state.currentStage === 'Sign In') {
                state.errorMessage = `Incorrect email or password`;
              }
              break;
            }
            case 'InvalidParameterException': {
              if (state.currentStage === 'Sign In') {
                state.errorMessage = `Your account is currently disabled; you need to reset your password. Click "Forgot Password" below`;
              }
              if (state.currentStage === 'Confirm Sign Up') {
                state.errorMessage =
                  'Your account is already confirmed. You can sign in with your email and password.';
              }
              break;
            }
            case 'UserNotFoundException': {
              state.errorMessage = 'Account does not exist';
              break;
            }
            case 'UsernameExistsException':
              state.errorMessage =
                'An account registered to that email address already exists.';
              break;
            default:
              break;
          }
        }
      })
      .addMatcher(isAnyOf(clearAuthError, isFulfilled), (state) => {
        state.errorCode = null;
        state.errorMessage = null;
      })
      .addMatcher(isAnyOf(isRejected, isFulfilled), (state) => {
        state.requestPending = false;
      }),
  initialState,
  name: 'AUTH',
  reducers: {}
});

const auth: Reducer<AuthState> = slice.reducer;

const getAuthState = createStructuredSelector<RootState, AuthState>({
  codeRequestPending: (state) => state.auth.codeRequestPending,
  currentStage: (state) => state.auth.currentStage,
  errorCode: getAuthErrorCode,
  errorMessage: (state) => state.auth.errorMessage,
  isSuccess: (state) => state.auth.isSuccess,
  requestPending: (state) => state.auth.requestPending
});
/**
 *
 */
function useAuthState() {
  return useAppSelector(getAuthState);
}

const SIGNUP_KEYS = [`email`, `password`, `confirmPassword`] as const;
type SignUpForm = {
  readonly [key in typeof SIGNUP_KEYS[number]]: string;
};

export type { SignUpForm, AuthFieldName, AuthErrorCodeValue, AuthStatus };
export {
  useIsAuthRequestPending,
  useAuthState,
  useAuthErrorMessage,
  useAuthInteraction,
  getAuthErrorCode,
  getAuthInteractionStage,
  getAuthState
};
export default auth;
