import { createCachedSelector } from 're-reselect';
import React from 'react';
import { ForceUpdateIconButton } from 'src/devices';
import { useAppSelector } from 'src/hooks';

import { Alert } from '@material-ui/lab';

import { getEventByDeviceId } from '../selectors';
import SensorStateDescriptionMemo from '../SensorStateDescription';
import PressureSensorSummary from './PressureSensorSummary';
import { ReelRunSummary } from './ReelRunSummary';

import type { SensorName } from 'src/devices/sensors';
import type { RootState } from 'src/store';
import type { DeviceIdProp } from '../models';
export const SHOW_STATE_OF_SENSOR: readonly SensorName[] = [
  'reel',
  'pressure',
  'pressureSwitch',
  'wheel'
];

const getReportingSensorsList = createCachedSelector(
  (state: RootState, id: string) => getEventByDeviceId(state, id),
  (event) => {
    const initializer = [] as SensorName[];
    const reportingSensors = event
      ? SHOW_STATE_OF_SENSOR.reduce((acc, sensorName) => {
          if (event[sensorName]) {
            return [...acc, sensorName];
          }
          return acc;
        }, initializer)
      : initializer;
    return reportingSensors;
  }
)((_state, id) => id);

/**
 * @param props
 * @param props.deviceId
 * @param props.className
 */
export default function SensorStates({
  deviceId,
  className
}: DeviceIdProp & { className?: string }): JSX.Element {
  const reportingSensors = useAppSelector((state) =>
    getReportingSensorsList(state, deviceId)
  );
  return (
    <div className={className}>
      {reportingSensors.length ? (
        <React.Fragment>
          {SHOW_STATE_OF_SENSOR.map((sn) => (
            <SensorStateDescriptionMemo
              deviceId={deviceId}
              key={sn}
              sensorName={sn}
            />
          ))}
          {reportingSensors.includes('reel') ? (
            <ReelRunSummary deviceId={deviceId} />
          ) : null}
          {reportingSensors.includes('pressure') ? (
            <PressureSensorSummary deviceId={deviceId} />
          ) : null}
        </React.Fragment>
      ) : (
        <Alert
          action={<ForceUpdateIconButton deviceId={deviceId} />}
          severity="info"
          variant="standard"
        >
          No state updates
        </Alert>
      )}
    </div>
  );
}
