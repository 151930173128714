import type { EntityState, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestNames } from 'src/constants';
import type { SensorEvent, SensorName } from 'src/devices/sensors';
import { SensorNames } from 'src/devices/sensors';
import { createAsyncAppThunk } from 'src/requests';
import type { RootState } from 'src/root.reducer';

import type { RootSelector } from 'src/store';
import type { DateString, ListRes } from 'src/types';
import { isNullish } from 'src/types';
import type { FetchStatus } from 'src/userSession.reducer';
import type { MinDateMaxDate } from 'src/utilities/getTimedelta';
import type { DeviceEvent } from '../models';
import transformDeviceEvent from './transformDeviceEvent';

const { LOAD_LAST_N_DEVICE_EVENTS } = RequestNames;
const asyncGetEventsForDevice = createAsyncAppThunk<
  MinDateMaxDate & {
    readonly deviceId: string;
  },
  ListRes<DeviceEvent>
>(LOAD_LAST_N_DEVICE_EVENTS, {
  transformResponse: ({ res }) => ({
    items: res.items.map(transformDeviceEvent)
  })
});

const SENSOR_NAMES = SensorNames.getList();

const adapter = createEntityAdapter<DeviceEvent>({
  selectId: (model) => model.indexId
});
const { selectAll, selectById } = adapter.getSelectors(
  (state: RootState) => state.inspectRecentEvents
);
interface State extends EntityState<DeviceEvent> {
  sensorName: SensorName | null;
  lastEventCheck?: DateString;
  fetchStatus?: FetchStatus;
  selectedIndex: number | null;
  deviceId: string | null;
}
const initialState: State = adapter.getInitialState({
  deviceId: null,
  selectedIndex: null,
  sensorName: null
});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetEventsForDevice.pending, (state) => {
        state.fetchStatus = 'pending';
      })
      .addCase(asyncGetEventsForDevice.fulfilled, (state, action) => {
        state.fetchStatus = 'fulfilled';
        state.selectedIndex = 0;
        adapter.upsertMany(
          state,
          action.payload.items.map((de, indexId) => {
            return { ...de, indexId };
          })
        );
      })
      .addCase(asyncGetEventsForDevice.rejected, (state) => {
        state.fetchStatus = 'rejected';
      }),
  initialState,
  name: 'deviceEventsInspector',
  reducers: {
    initialize: (state, { payload }: PayloadAction<string>) => {
      adapter.removeAll(state);
      state.deviceId = payload;

      state.fetchStatus = 'shouldFetch';
    },
    reloadEvents: (state) => {
      state.fetchStatus = 'shouldFetch';
    },
    selectEventIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedIndex = payload;
    },

    selectNewerEvent: (state) => {
      if (typeof state.selectedIndex === 'number' && state.selectedIndex > 0) {
        state.selectedIndex = state.selectedIndex - 1;
      }
    },
    selectOlderEvent: (state) => {
      if (
        typeof state.selectedIndex === 'number' &&
        state.selectedIndex < state.ids.length - 1
      ) {
        state.selectedIndex = state.selectedIndex + 1;
      }
    },

    selectSensorName: (state, { payload }: PayloadAction<SensorName>) => {
      state.sensorName = payload;
    }
  }
});

const { actions } = slice;

const inspectRecentEvents: Reducer<State> = slice.reducer;
export default inspectRecentEvents;

/**
 * @param state
 */
function getSelectedIndex(state: RootState): number | null {
  return state.inspectRecentEvents.selectedIndex;
}

/**
 * @param state
 */
function getSelectedSensorName(state: RootState): SensorName | null {
  return state.inspectRecentEvents.sensorName;
}

/**
 * @param state
 */
function getSelectedEvent(state: RootState): DeviceEvent | null {
  const index = getSelectedIndex(state);
  if (typeof index === 'number') {
    const event = state.inspectRecentEvents.entities[index];
    if (isNullish(event)) {
      return null;
      // throw new Error(`No event`);
    }
    return event;
  }
  return null;
}

const createSensorSelector = <S extends SensorName>(sensorName: S) => {
  return (state: RootState) => {
    const event = getSelectedEvent(state);
    if (!isNullish(event)) {
      const sensorData = event[sensorName] ?? null;
      return sensorData;
    }
    return null;
  };
};

type SensorSelectors = {
  [SN in SensorName]: RootSelector<SensorEvent<SN> | null>;
};

const initializer = {} as SensorSelectors;
const sensorDataSelectors = SensorNames.getList().reduce((acc, sensorName) => {
  return {
    ...acc,
    [sensorName]: createSensorSelector(sensorName)
  };
}, initializer);
const getDeviceId = (state: RootState): string | null =>
  state.inspectRecentEvents.deviceId;
const DeviceEventInspector = {
  actions,
  getAllEvents: selectAll,
  getDeviceId,
  getFetchStatus: (state: RootState): FetchStatus | undefined =>
    state.inspectRecentEvents.fetchStatus,
  getSelectedEvent,
  getSelectedIndex,
  getSelectedSensorName,
  getSensorData: sensorDataSelectors,
  getSensorNameOptions: (state: RootState): SensorName[] => {
    const event = getSelectedEvent(state);
    if (event !== null) {
      return SENSOR_NAMES.filter((sn) => {
        return !isNullish(event[sn]);
      });
    }
    return [];
  },
  selectById,
  sensorDataSelectors
};
export { DeviceEventInspector, asyncGetEventsForDevice };
