import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router';
import { useSignOut } from 'src/auth/authRequests';
import { handleApiRequestError } from 'src/requests';

/**
 * Signs user out on load
 */
export default function SignOut(): JSX.Element {
  const signOut = useSignOut();
  const [redirect, setRedirect] = React.useState(false);
  React.useEffect(() => {
    signOut()
      .then(() => {
        setRedirect(true);
      })
      .catch(handleApiRequestError);
  }, [signOut]);
  return redirect ? (
    <Redirect to="/" />
  ) : (
    <Box alignItems="center" display="flex" justifyContent="center">
      <Box height={60} width={60}>
        <CircularProgress />
      </Box>
    </Box>
  );
}
