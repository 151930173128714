import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RequestNames } from 'src/constants';
import type { ReelRun } from 'src/reel-runs';
import { createAsyncAppThunk, makeApiRequest } from 'src/requests';
import type { RootThunkApi } from 'src/store';
import type { ListRes } from 'src/types';
import { isNullish } from 'src/types';
import formatTrigger from './formatTrigger';
import type {
  DeviceConfig,
  DeviceEvent,
  DevicePair,
  NotificationTrigger,
  Trigger,
  TriggerPropertyKey
} from './models';
import { getAllDevices } from './selectors';

const {
  CHANGE_DEVICE_NAME,
  CREATE_EVENT_ACTION_TRIGGER,
  CREATE_REEL_PUMP_PAIR,
  CUSTOMIZE_NOTIFICATION_STRING,
  DELETE_EVENT_ACTION_TRIGGERS,
  RESTORE_CONFIGURATION_DEFAULTS,
  UPDATE_STATUS_MAP
} = RequestNames;

const asyncRestoreConfigDefaults = createAsyncAppThunk<
  { deviceId: string },
  DeviceConfig
>(RESTORE_CONFIGURATION_DEFAULTS, {
  checkPermissions: ['canManageDeviceConfiguration', 'canManageDevicePairs']
});
const asyncReloadMapEvents = createAsyncAppThunk<
  {
    maxEventAgeHours: number;
  },
  {
    readonly events: DeviceEvent[];
    readonly reelRuns: ReelRun[];
  }
>(UPDATE_STATUS_MAP);

const asyncChangeDeviceName = createAsyncAppThunk<
  Pick<DeviceConfig, 'deviceId' | 'deviceName'>,
  DeviceConfig
>(CHANGE_DEVICE_NAME, { checkPermissions: 'canManageDeviceConfiguration' });

const asyncDeleteTriggers = createAsyncAppThunk<
  { triggerIds: number[] },
  ListRes<number>
>(DELETE_EVENT_ACTION_TRIGGERS, { checkPermissions: ['canManageDevicePairs'] });
const asyncCreateTrigger = createAsyncAppThunk<
  { triggerProperties: Omit<Trigger, 'id'> },
  Trigger
>(CREATE_EVENT_ACTION_TRIGGER, {
  checkPermissions: ['canManageCustomTriggers']
});

const asyncCreatePair = createAsyncAppThunk<
  {
    reelPumpPairList: Array<{ sourceDeviceId: string; targetDeviceId: string }>;
  },
  ListRes<DevicePair>
>(CREATE_REEL_PUMP_PAIR, {
  checkPermissions: 'canManageDevicePairs',
  successToast: 'Created new pair'
});
const asyncCustomizeNotificationString = createAsyncAppThunk<{
  triggerId: number;
  notificationString: string;
}>(CUSTOMIZE_NOTIFICATION_STRING, {
  checkPermissions: 'canManageDeviceNotifications'
});
const togglePartnerCallout = createAction<string | undefined>(
  'TOGGLE_PARTNER_CALLOUT'
);
const { CREATE_NOTIFICATION } = RequestNames;
export type CreateNotificationArgs = Pick<
  NotificationTrigger,
  TriggerPropertyKey | 'sourceDeviceId'
>;
type NotificationProperties = Pick<
  CreateNotificationArgs,
  | 'sourceDeviceId'
  | 'sourceSensorStateCurrent'
  | 'sourceSensorStatePrevious'
  | 'targetDeviceId'
> & {
  notificationString: string;
};

type Response = ListRes<NotificationTrigger>;

export const asyncCreateNotification = createAsyncThunk<
  Response,
  CreateNotificationArgs,
  RootThunkApi
>(CREATE_NOTIFICATION, async (trigger, { getState }) => {
  try {
    const state = getState();

    let triggerIdsList: NotificationProperties[] = [];
    if (trigger.sourceDeviceId === 'GLOBAL') {
      triggerIdsList = getAllDevices(state)
        .filter((dc) => {
          const { sourceSensor } = trigger;
          if (!isNullish(dc[sourceSensor])) {
            return true;
          }
          return false;
        })
        .map((dc) => {
          const notification = {
            ...trigger,
            sourceDeviceId: dc.deviceId
          };

          return {
            ...notification,
            notificationString: formatTrigger(notification)
          };
        });
    } else {
      triggerIdsList = [
        { ...trigger, notificationString: formatTrigger(trigger) }
      ];
    }

    return await makeApiRequest<
      Response,
      { notificationList: NotificationProperties[] }
    >(
      CREATE_NOTIFICATION,

      {
        notificationList: triggerIdsList
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
});
export {
  togglePartnerCallout,
  asyncCreatePair,
  asyncCreateTrigger,
  asyncDeleteTriggers,
  asyncChangeDeviceName,
  asyncCustomizeNotificationString,
  asyncReloadMapEvents,
  asyncRestoreConfigDefaults
};
