import { createCachedSelector } from 're-reselect';
import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { getActiveFeature } from 'src/appSelectors';
import { getConfigEntities } from 'src/devices';

import { FarmFields } from 'src/fields';
import { useAppDispatch } from 'src/hooks';
import type { ReelRunGeometry } from 'src/reel-runs';
import { calculateRunGeometry } from 'src/reel-runs';
import type { ReelRunPolygonProps } from 'src/reel-runs/ReelRunPolygon';
import { ReelRunPolygon } from 'src/reel-runs/ReelRunPolygon';
import type { RootSelector } from 'src/store';
import { notNullish } from 'src/types';
import { RunHistory } from './fieldRunHistory.reducer';
import { getCurrentMapZoom } from './statusMap.reducer';

const getGeometryByRunId: RootSelector<ReelRunGeometry, [runId: number]> =
  createCachedSelector(
    RunHistory.selectById,
    FarmFields.selectAll,
    getConfigEntities,

    (runData, fields, configs) => {
      const geoms = calculateRunGeometry({
        configs,
        fields,
        runData
      });
      return geoms;
    }
  )((_, id) => id);

type OwnProps = {
  runId: number;
};
const connector = connect(
  (state, ownProps: OwnProps): Omit<ReelRunPolygonProps, 'onClick'> => {
    const { selectedId } = state.fieldRunHistory;
    const isSelected = ownProps.runId === selectedId;
    const anySelected = notNullish(selectedId);
    const otherIsSelected = anySelected && !isSelected;
    const geometry = getGeometryByRunId(state, ownProps.runId);
    const activeFeature = getActiveFeature(state);
    return {
      ...geometry,
      isHidden: activeFeature !== 'VIEW_RUN_HISTORY',
      isSelected,
      hideGunMarker: true,
      hideHeatmap: otherIsSelected,
      hideOutline: !isSelected,
      clickable: !anySelected,
      hideHosePath: true,
      currentZoom: getCurrentMapZoom(state),
      reelRunId: ownProps.runId,
      heatmapOpacity: isSelected ? 0.3 : 0.2,
      isMuted: !isSelected,
      outlineOpacity: isSelected ? 0.4 : 0
    };
  }
);
type Props = ConnectedProps<typeof connector>;
/**
 * @param props
 * @param props.runId
 * @param props.reelRunId
 * @param props.rest
 */
function ReelRunPolygonHistorical({
  reelRunId,
  ...rest
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();

  const handleClick = React.useCallback(() => {
    dispatch(RunHistory.clickRun(reelRunId));
  }, [dispatch, reelRunId]);

  return (
    <ReelRunPolygon onClick={handleClick} reelRunId={reelRunId} {...rest} />
  );
}
export default connector(ReelRunPolygonHistorical);
