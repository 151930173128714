import camelcaseKeys from 'camelcase-keys';

import albertS from './demo_data/reel_run_history_for_field_albert_s.json';
import damon from './demo_data/reel_run_history_for_field_damon.json';
import holtcampNw from './demo_data/reel_run_history_for_field_holtcamp_nw.json';
import lautenbach from './demo_data/reel_run_history_for_field_lautenbach.json';
import southOfJoeLearySlough from './demo_data/reel_run_history_for_field_south_of_joe_leary_slough.json';
import sunsetRd from './demo_data/reel_run_history_for_field_sunset_rd.json';
import timSHouse from './demo_data/reel_run_history_for_field_tim_s_house.json';

import type { CamelCasedPropertiesDeep } from 'type-fest';
import type { ReelRun } from 'src/reel-runs';
type DemoData = {
  fieldRunHistory: {
    [key: string]: CamelCasedPropertiesDeep<ReelRun[]>;
  };
};

export const DEMO_DATA = {
  fieldRunHistory: camelcaseKeys(
    {
      albertS,
      damon,
      holtcampNw,
      lautenbach,
      southOfJoeLearySlough,
      sunsetRd,
      timSHouse
    },
    { deep: true }
  )
} as DemoData;
