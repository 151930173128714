import React from 'react';
import type { ShowsLoader } from 'src/components';
import { IconBtn } from 'src/components';
import type { IconBtnProps } from 'src/components/buttons/IconBtn';
import { useAppDispatch } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import AppIcon from 'src/theme/AppIcon';
import type { DeviceIdProp } from '../models';
import {
  asyncInitializeForceUpdate,
  useForceUpdateInProgress
} from './reducer';

interface ForceUpdateIconProps
  extends Pick<IconBtnProps, 'className' | 'disabled' | 'id'>,
    DeviceIdProp,
    ShowsLoader {
  readonly useLoader?: boolean;
}

/**
 * Triggers force update cycle for device
 *
 * @param props
 * @param props.deviceId
 * @param props.disabled
 * @param props.dataCy
 * @param props.className
 * @param props.id
 * @param props.useLoader
 */
function ForceUpdateIconButton({
  deviceId,
  id = 'force-update-btn',
  disabled,
  className,
  useLoader
}: ForceUpdateIconProps): JSX.Element {
  const dispatch = useAppDispatch();
  const inProgress = useForceUpdateInProgress(deviceId);

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    return dispatch(asyncInitializeForceUpdate({ deviceId })).catch(
      handleApiRequestError
    );
  };
  return (
    <IconBtn
      className={className}
      data-cy={id}
      disabled={disabled === true || inProgress}
      helpText="Request a status update from the device"
      id={id}
      onClick={handleClick}
      size="small"
    >
      <AppIcon
        iconKey="FORCE_UPDATE"
        showLoading={inProgress && useLoader === true}
      />
    </IconBtn>
  );
}

export default ForceUpdateIconButton;
