import type { ValuesType } from 'utility-types';

const INSTALLATION_TYPE = {
  prototype: 'prototype',
  pump: 'pump',
  reel: 'reel',
  softHoseTraveller: 'traveller_soft',
  unconfigured: 'unconfigured'
} as const;

const INSTALLATION_TYPES = Object.values(INSTALLATION_TYPE);
export { INSTALLATION_TYPES };
export type InstallationType = ValuesType<typeof INSTALLATION_TYPE>;
export default INSTALLATION_TYPE;
