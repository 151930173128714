import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import Actions from 'src/actions';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import { createAsyncAppThunk } from 'src/requests';
import type { RootState } from 'src/root.reducer';

type PhoneNumber = {
  codeStr: string | null;
  codeExpirationDate: string | null;
  id: number;
  digits: {
    countryCode: string;
    areaCode: string;
    prefix: string;
    suffix: string;
  };
  userId: string;
  isVerified: boolean;
  notificationsEnabled: boolean;
};
/**
 * @param args
 * @param args.digits
 */
function formatPhoneNumberDigits({
  digits
}: Pick<PhoneNumber, 'digits'>): string {
  return `${digits.countryCode}-(${digits.areaCode})-${digits.prefix}-${digits.suffix}`;
}
const adapter = createEntityAdapter<PhoneNumber>({
  /**
   * @param model
   */
  selectId: (model) => model.id,
  /**
   * @param a
   * @param b
   */
  sortComparer: (a, b) => {
    if (a.isVerified === b.isVerified) {
      return 0;
    }
    return a.isVerified ? 1 : -1;
  }
});

const selectors = adapter.getSelectors(
  (state: RootState) => state.userPhoneNumbers
);
const {
  COMPLETE_SMS_VERIFICATION,
  GET_VERIFICATION_CODE,
  CREATE_PHONE_NUMBER
} = RequestNames;

const asyncConfirmSmsVerification = createAsyncAppThunk<
  {
    readonly codeString: string;
    readonly upnId: number;
  },
  'INVALID' | undefined
>(COMPLETE_SMS_VERIFICATION, {
  successToast: `Phone number verified`
});

const asyncGetPhoneVerificationCode = createAsyncAppThunk<{
  upnId: number;
}>(GET_VERIFICATION_CODE, { successToast: 'Code sent.' });

const asyncCreatePhoneNumber = createAsyncAppThunk<
  { phoneNumberStr: string },
  PhoneNumber
>(CREATE_PHONE_NUMBER, { successToast: 'Success.' });

/**
 * @param props
 * @param props.disabled
 * @param props.name
 * @param props.value
 * @param props.onChange
 * @param props.rest
 */

const INPUT_MASK = `9-999-999-9999`;
const PLACEHOLDER_CHAR = `X`;
const PHONE_NUMBER_VALIDATION = {
  DELIMITER: `-`,
  INPUT_MASK,
  NUM_DIGITS: 11,
  PLACEHOLDER: INPUT_MASK.replace(`/9/g`, PLACEHOLDER_CHAR),
  PLACEHOLDER_CHAR
};
/**
 * Convert phone number from 1-234-555-0000 to 123455550000
 * @param phoneNumber
 */
function removePhoneNumberSeparators(phoneNumber: string): string {
  return phoneNumber
    .replaceAll(PHONE_NUMBER_VALIDATION.DELIMITER, ``)
    .replaceAll(PLACEHOLDER_CHAR, ``)
    .replaceAll(`_`, ``);
}

/**
 * @param serialized
 */
function formatPhoneNumber(serialized: string): string {
  const removePlus = serialized.replace(`+`, '');
  if (removePlus.length === PHONE_NUMBER_VALIDATION.NUM_DIGITS) {
    const countryCode = removePlus.substr(0, 1);
    const areaCode = removePlus.substr(1, 3);
    const prefix = removePlus.substr(3, 3);
    const suffix = removePlus.substr(7, 4);
    return `${countryCode} - ${areaCode} - ${prefix} - ${suffix}`;
  }
  return serialized;
}

interface State extends EntityState<PhoneNumber> {
  isAdding?: boolean;
  verifyingId?: number;
}

const initialState: State = adapter.getInitialState();

const slice = createSlice({
  /**
   * @param builder
   */
  extraReducers: (builder) =>
    builder
      .addCase(Actions.clickCancel, (state) => {
        state.isAdding = false;
        state.verifyingId = undefined;
      })
      .addCase(asyncGetUserData.pending, (state) => {
        adapter.removeAll(state);
      })
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        adapter.upsertMany(state, [...payload.phoneNumbers]);
      })

      .addCase(asyncCreatePhoneNumber.fulfilled, (state, { payload }) => {
        adapter.upsertOne(state, payload);
        state.verifyingId = payload.id;
        state.isAdding = false;
      })
      .addCase(asyncGetPhoneVerificationCode.fulfilled, (state, action) => {
        state.verifyingId = action.meta.arg.upnId;
      })
      .addCase(asyncConfirmSmsVerification.fulfilled, (state, action) => {
        if (action.payload !== 'INVALID') {
          adapter.updateOne(state, {
            changes: { isVerified: true },
            id: action.meta.arg.upnId
          });
          state.verifyingId = undefined;
          state.isAdding = false;
        }
      }),
  initialState,
  name: `userPhoneNumbers`,
  reducers: {
    /**
     * @param state
     */
    clickAddPhoneNumber(state) {
      state.isAdding = true;
    }
  }
});

const { actions } = slice;
const Upn = {
  ...actions,
  ...selectors,
  PHONE_NUMBER_VALIDATION,
  PLACEHOLDER_CHAR,

  formatPhoneNumber,
  removePhoneNumberSeparators
} as const;

const phoneNumbers: Reducer<State> = slice.reducer;
export {
  Upn,
  PHONE_NUMBER_VALIDATION,
  INPUT_MASK,
  formatPhoneNumberDigits,
  PLACEHOLDER_CHAR,
  formatPhoneNumber,
  removePhoneNumberSeparators,
  asyncConfirmSmsVerification,
  asyncGetPhoneVerificationCode,
  asyncCreatePhoneNumber
};
export type { PhoneNumber };
export default phoneNumbers;
