/* eslint-disable max-lines */

import { isNumber } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import Actions from 'src/actions';
import { getActiveFeature } from 'src/appSelectors';
import {
  AppButton,
  AppTextField,
  FormFeedback,
  GridWrapper,
  Success
} from 'src/components';
import { RowDirection, useSetDirection } from 'src/components/map';
import FieldLabelMemoized from 'src/fields/FieldLabel';
import { asyncCreateField } from 'src/fields/fieldRequests.actions';
import { useAppDispatch, useAppSelector, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';

import { Box, Grid, Slide, Typography } from '@material-ui/core';
import { DrawingManager, useGoogleMap } from '@react-google-maps/api';

import FieldPolygonMemoized from '../../fields/FieldPolygon';
import { CreateField, extractPathFromPolygon } from './createField.reducer';
import { StageWrapper } from './StageWrapper';

import type { PayloadActionCreator } from '@reduxjs/toolkit';
import type { CreateFieldStage } from './createField.reducer';
const {
  getAzimuth,
  getCenter,
  getCenterGmaps,
  getCurrentStage,
  getFieldName,
  getPath,
  // getBearingText,
  submitFieldName,
  submitRowDirection,
  onDragBoundaryPoint,
  getLatLngBounds,
  clickAdjustBoundary,
  finishPolygon,
  getArrowLengthMeters,
  finishAdjustingBoundary,
  clickChangeRowDirection,
  clickRedrawField,
  clickRename,
  getPathAsGeoJson
} = CreateField;

/**
 * Buttons that allow user to edit initially
 * provided field details before committing to db
 */
const ADJUSTMENT_BUTTONS: Array<{
  key: CreateFieldStage;
  label: string;
  action: PayloadActionCreator;
  testId: string;
}> = [
  {
    action: clickRedrawField,
    key: `DRAW_BOUNDARY`,
    label: `Redraw`,
    testId: testIds.createField.drawField.editBtn
  },
  {
    action: clickChangeRowDirection,
    key: `SET_ROW_DIRECTION`,
    label: `Direction`,
    testId: testIds.createField.setRowDirection.editBtn
  },
  {
    action: clickAdjustBoundary,
    key: `EDIT_BOUNDARY`,
    label: `Boundary`,
    testId: testIds.createField.editBoundary.editBtn
  },
  {
    action: clickRename,
    key: `ENTER_NAME`,
    label: `Rename`,
    testId: testIds.createField.enterName.editBtn
  }
];
const DRAWING_OPTIONS: google.maps.drawing.DrawingManagerOptions = {
  drawingControl: false,
  drawingMode: 'polygon' as google.maps.drawing.OverlayType,
  polygonOptions: {
    strokeColor: `orange`,
    strokeWeight: 3
  }
};

/**
 * User taps/clicks the map to draw the initial field boundaries
 *
 */
function DrawField() {
  const showError = useSelector(
    (state) => state.createField.errorCode === 'NOT_ENOUGH_POINTS'
  );
  return (
    <StageWrapper
      cancelText="cancel"
      instructions="Click the map to draw the field boundary."
      testId={testIds.createField.drawField.root}
    >
      {showError ? (
        <FormFeedback>Field must have at last 4 points</FormFeedback>
      ) : null}
    </StageWrapper>
  );
}

/**
 * Customize the field name with a text input.
 *
 * On submit, the field label on the map is updated
 *
 * @param props
 * @param props.value
 * @param props.onChange
 */
function EnterFieldName({
  value,
  onChange
}: {
  value: string;
  onChange: (val: string) => void;
}) {
  const dispatch = useAppDispatch();
  const handleSubmitFieldName = React.useCallback(
    /**
     *
     */
    () => {
      dispatch(submitFieldName(value));
    },
    [dispatch, value]
  );
  return (
    <StageWrapper
      instructions="Enter Field Name"
      submitBtn={
        <AppButton
          id={testIds.createField.enterName.submitBtn}
          onClick={handleSubmitFieldName}
          presetKey="SUBMIT"
          text="submit"
        />
      }
      testId={testIds.createField.enterName.root}
    >
      <AppTextField
        id={testIds.createField.enterName.textInput}
        label="Field Name"
        name="fieldName"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </StageWrapper>
  );
}

/**
 * Adjust the field's initial row direction with a slider
 */
function SetRowDirection() {
  const rowDirection = useSetDirection();
  const dispatch = useAppDispatch();
  const handleSubmitRowDirection = React.useCallback(
    /**
     *
     * @returns
     */
    () => dispatch(submitRowDirection(rowDirection.azimuth)),
    [dispatch, rowDirection.azimuth]
  );
  return (
    <StageWrapper
      instructions="Set crop row watering direction"
      submitBtn={
        <GridWrapper>
          <AppButton
            color="default"
            iconKey="SWITCH_VERTICAL"
            id={testIds.createField.setRowDirection.flipBtn}
            onClick={rowDirection.handleClickFlip}
            text="Flip"
            variant="text"
          />
          <AppButton
            id={testIds.createField.setRowDirection.submitBtn}
            onClick={handleSubmitRowDirection}
            presetKey="SUBMIT"
            text="submit"
          />
        </GridWrapper>
      }
      testId={testIds.createField.setRowDirection.root}
    >
      <Box mx={2} px={2}>
        <Typography
          align="right"
          data-cy={testIds.createField.setRowDirection.bearingText}
          variant="overline"
        >
          {rowDirection.bearingText}
        </Typography>
        <RowDirection.Slider
          onChange={rowDirection.handleDragSlider}
          value={rowDirection.azimuth}
        />
      </Box>
    </StageWrapper>
  );
}

/**
 * @param props
 * @param props.onSubmit
 */
function EditBoundary({ onSubmit }: { onSubmit: () => void }) {
  return (
    <StageWrapper
      instructions="Drag field boundary points"
      submitBtn={
        <AppButton
          id={testIds.createField.editBoundary.submitBtn}
          onClick={onSubmit}
          text="done"
        />
      }
      testId={testIds.createField.editBoundary.root}
    />
  );
}

/**
 * @param props
 * @param props.bearingText
 */
function ConfirmAndSubmit() {
  const dispatch = useAppDispatch();
  const { sendRequest, isPending } = useThunk(asyncCreateField);

  const handleSubmitField = React.useCallback(
    /**
     * Sends name and boundary as geojson polygon to db
     * @returns the created field
     */
    () =>
      sendRequest()
        ?.then(() => showSuccessToast())
        .catch(handleApiRequestError),
    [sendRequest]
  );

  return (
    <StageWrapper
      instructions="Confirm or make adjustments"
      submitBtn={
        <AppButton
          id={testIds.createField.submitBtn}
          onClick={handleSubmitField}
          showLoading={isPending}
          text="submit"
          variant="contained"
        />
      }
      testId={testIds.createField.confirmAndSubmit.root}
    >
      <Grid container justifyContent="space-between" spacing={1}>
        {ADJUSTMENT_BUTTONS.map((btn) => (
          <Grid item key={btn.label} xs={6}>
            <AppButton
              fullWidth
              id={btn.testId}
              onClick={() => dispatch(btn.action())}
              presetKey="ACTION"
              text={btn.label}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    </StageWrapper>
  );
}

/**
 * Prompt user to continue drawing more fields or exit interaction.
 *
 * Clicking 'draw more' resets the interaction to the initial state
 */
function SuccessManager(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClickDrawMore = () =>
    dispatch(
      Actions.setActiveFeature({
        name: 'CREATE_FIELD'
      })
    );

  return (
    <StageWrapper
      cancelText="done"
      submitBtn={
        <AppButton
          id={testIds.createField.success.drawMoreBtn}
          onClick={handleClickDrawMore}
        >
          Draw Another Field
        </AppButton>
      }
      testId={testIds.success}
    >
      <Success>Your field was created successfully</Success>
    </StageWrapper>
  );
}
/**
 *
 */
export default function CreateFieldManager(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const mapRef = useGoogleMap();
  const polygonRef = React.useRef<google.maps.Polygon | null>(null);

  const [fieldName, setFieldName] = React.useState('New Field');

  const arrowLength = useSelector(getArrowLengthMeters);
  const azimuthCommitted = useAppSelector(getAzimuth) ?? 0;
  // const bearingText = useSelector(getBearingText);
  const center = useAppSelector(getCenter);
  const centerGmaps = useAppSelector(getCenterGmaps);
  const currentStage = useAppSelector(getCurrentStage);
  const fieldNameCommitted = useAppSelector(getFieldName);
  const latLngBounds = useSelector(getLatLngBounds);
  const pathGeoJson = useAppSelector(getPathAsGeoJson);
  const polygonPath = useAppSelector(getPath);
  const rowDirection = useSetDirection();

  const shouldDeleteField = useAppSelector(
    (state): boolean =>
      state.createField.currentStage === 'SUCCESS' ||
      getActiveFeature(state) === null
  );

  React.useEffect(
    /**
     *
     */
    function removeFieldFromMap() {
      if (shouldDeleteField) {
        polygonRef.current?.setMap(null);
      }
    },
    [dispatch, shouldDeleteField]
  );

  const getPolygonRef = React.useCallback(
    /**
     * Allows direct interaction with google maps polygon instance
     *
     * @param poly google maps polygon
     */
    (poly: google.maps.Polygon): void => {
      polygonRef.current = poly;
    },
    []
  );

  React.useEffect(
    /**
     *
     */
    function onFieldDrawn() {
      if (latLngBounds) {
        mapRef?.fitBounds(latLngBounds);
      }
    },
    [latLngBounds, mapRef]
  );

  const handleBoundaryDragPoint = React.useCallback(
    /**
     *
     */
    () => {
      if (polygonRef.current) {
        const newPath = extractPathFromPolygon(polygonRef.current);
        dispatch(onDragBoundaryPoint(newPath));
      }
    },
    [dispatch]
  );

  const handleSubmitBoundary = React.useCallback(
    /**
     *
     * @returns
     */
    () => dispatch(finishAdjustingBoundary()),
    [dispatch]
  );

  const handlePolygonComplete = React.useCallback(
    /**
     *
     * @param poly gmaps polygon instance
     */
    (poly: google.maps.Polygon): void => {
      dispatch(finishPolygon(extractPathFromPolygon(poly)));
      poly.setMap(null);
    },
    [dispatch]
  );
  return (
    <div id={testIds.createField.root}>
      <Slide direction="down" in>
        <div>
          {currentStage === 'DRAW_BOUNDARY' ? (
            <DrawField />
          ) : currentStage === 'ENTER_NAME' ? (
            <EnterFieldName onChange={setFieldName} value={fieldName} />
          ) : currentStage === 'SET_ROW_DIRECTION' ? (
            <SetRowDirection />
          ) : currentStage === 'EDIT_BOUNDARY' ? (
            <EditBoundary onSubmit={handleSubmitBoundary} />
          ) : currentStage === 'CONFIRM' ? (
            <ConfirmAndSubmit />
          ) : currentStage === 'SUCCESS' ? (
            <SuccessManager />
          ) : null}
        </div>
      </Slide>
      <FieldPolygonMemoized
        editable={currentStage === 'EDIT_BOUNDARY'}
        isArchived={false}
        isHidden={currentStage === 'DRAW_BOUNDARY'}
        isHighlighted
        isMuted={false}
        isSelected
        onLoad={getPolygonRef}
        onMouseUp={handleBoundaryDragPoint}
        pathGmaps={polygonPath}
      />
      {!isNullish(pathGeoJson) &&
      isNumber(azimuthCommitted) &&
      !isNullish(center) ? (
        <RowDirection.Arrow
          azimuth={rowDirection.azimuth}
          boundingRadiusMeters={arrowLength ?? 250}
          center={center}
          enclosingPolygon={pathGeoJson}
          isHidden={currentStage === 'DRAW_BOUNDARY'}
        />
      ) : null}
      {currentStage === 'DRAW_BOUNDARY' ? (
        <DrawingManager
          onPolygonComplete={handlePolygonComplete}
          options={DRAWING_OPTIONS}
        />
      ) : null}
      {centerGmaps ? (
        <FieldLabelMemoized
          center={centerGmaps}
          data-cy={testIds.createField.enterName.fieldNamePreview}
          fieldName={fieldNameCommitted ?? fieldName}
          isHidden={currentStage === 'DRAW_BOUNDARY'}
          rowDirectionAzimuthDegrees={azimuthCommitted ?? 0}
        />
      ) : null}
    </div>
  );
}
