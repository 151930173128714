/* eslint-disable no-nested-ternary */
import { Grid, Typography } from '@material-ui/core';
import { isArray } from 'lodash';
import React from 'react';
/* eslint-disable react/no-array-index-key */
import { isPlainObj, isTruthyString } from 'src/types';
import { isPrimitive } from 'utility-types';
import FlexRow from '../layout/FlexRow';

type Props = {
  readonly title?: string;
  readonly showHistory?: boolean;
  readonly showMatch?: boolean;
  children: unknown;
};

/**
 * @param props
 * @param props.children
 * @param props.title
 * @param props.showHistory
 * @param props.showMatch
 */
function PrettyPrint({ children, title }: Props): JSX.Element {
  if (isArray(children)) {
    return (
      <div>
        {children.map((v, i) => (
          <FlexRow key={i}>{JSON.stringify(v)}</FlexRow>
        ))}
      </div>
    );
  }

  if (isPrimitive(children)) {
    return <Typography variant="body1">{JSON.stringify(children)}</Typography>;
  }
  if (isPlainObj(children)) {
    return (
      <Grid container>
        {Object.entries(children).map(([k, v]) => (
          <Grid container key={k} spacing={2}>
            <Grid item xs={4}>
              <Typography color="primary" variant="body2">
                {k}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <PrettyPrint>{v}</PrettyPrint>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <React.Fragment>
      {isTruthyString(title) ? (
        <Typography variant="h5">{title}</Typography>
      ) : null}
      Null
    </React.Fragment>
  );
}

export default PrettyPrint;
