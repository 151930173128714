import { toast } from 'react-toastify';
import { isNullish } from 'src/types';

import { captureException } from '@sentry/react';

/**
 * Logs the error, shows a toast, and re-raises the error
 * @param err
 * @param options
 * @param options.toastMessage
 * @param thunkApi
 * @param thunkApi.dispatch
 */
const handleApiRequestError = (
  err: unknown,
  options?: {
    readonly toastMessage?: string;
  }
): void => {
  const {
    toastMessage = 'Your request could not be completed. Please try again later.'
  } = options ?? {};
  const exception = new Error(JSON.stringify(err));
  captureException(exception);

  if (!isNullish(toastMessage)) {
    toast.error(toastMessage);
  }
};
export default handleApiRequestError;
