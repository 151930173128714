import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton, IconBtn } from 'src/components';
import ListHeader from 'src/components/ListHeader';
import { NoListItems } from 'src/components/lists';
import testIds from 'src/testIds';

import {
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';

import formatTrigger from '../formatTrigger';
import { getConfigById } from '../selectors';
import { useTriggersForDevice } from '../triggers.reducer';
import { ConfirmDeleteAutomation } from './ConfirmDeleteAutomation';
import CreateActionTrigger from './CreateActionTrigger';

import type { DeviceMetadata } from '..';
import type { Trigger } from '../models';
const Ids = testIds.deviceAutomations;
type ClickDeleteHandler = (id: number) => () => void;

type State = {
  currentStatus: 'CREATING' | null;
  idToDelete?: number;
  pairsListCollapsed?: boolean;
};

/**
 * @param props
 */
function AutomationItem(
  props: Trigger & {
    onClickDelete: (id: number) => () => void;
    index: number;
  }
) {
  const { onClickDelete, targetDeviceId } = props;
  const targetDeviceName = useSelector((state) =>
    getConfigById(state, targetDeviceId ?? '')
  )?.deviceName;
  const testId = Ids.existingAutomations.listItem(props.index);

  return (
    <ListItem data-cy={testId} dense disableGutters divider id={testId}>
      <ListItemText
        primary={formatTrigger({ ...props, targetDeviceName })}
        secondary={formatTrigger(
          { ...props, targetDeviceName },
          { formatStyle: 'shortHand' }
        )}
      />
      <ListItemSecondaryAction>
        <IconBtn
          iconKey="DELETE_ITEM"
          id={Ids.existingAutomations.deleteBtn(props.index)}
          onClick={onClickDelete(props.id)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
/**
 * @param props
 * @param props.deviceId
 * @param props.deviceName
 * @param props.onExit
 * @param props.onClickNewRule
 * @param props.onClickDelete
 */
function ExistingAutomations({
  deviceId,
  onClickNewRule,
  onClickDelete
}: DeviceMetadata & {
  onClickNewRule: () => void;
  onClickDelete: ClickDeleteHandler;
}): JSX.Element | null {
  const triggers = useTriggersForDevice(deviceId, 'ACTION_TRIGGER');

  return (
    <div
      data-cy={Ids.existingAutomations.root}
      id={Ids.existingAutomations.root}
      title="Automations"
    >
      <CardHeader title="Automations" />
      {triggers.length === 0 ? (
        <NoListItems
          id={Ids.noAutomations}
          title="No automations exist for this device."
        >
          <AppButton
            iconKey="ADD_ITEM"
            id={Ids.create.startBtn}
            onClick={onClickNewRule}
            size="small"
            text="create"
          />
        </NoListItems>
      ) : (
        <List
          data-cy={Ids.existingAutomations.list}
          dense
          id={Ids.existingAutomations.list}
        >
          <ListHeader>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <AppButton
                  iconKey="ADD_ITEM"
                  id={Ids.create.startBtn}
                  onClick={onClickNewRule}
                  style={{ maxWidth: 100 }}
                  text="New Rule"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </ListHeader>
          {triggers.map((t, i) => {
            return (
              <AutomationItem
                index={i}
                key={t.id}
                onClickDelete={onClickDelete}
                {...t}
              />
            );
          })}
        </List>
      )}
    </div>
  );
}

/**
 * @param props
 */
export default function Automations(props: DeviceMetadata): JSX.Element {
  const [state, setState] = React.useState<State>();
  const handleCancel = () => setState({ currentStatus: null });

  const handleClickDeleteRule: ClickDeleteHandler = (id: number) => () =>
    setState({ currentStatus: null, idToDelete: id });
  const { deviceId } = props;

  return (
    <div data-cy={Ids.root} id={Ids.root} title="Automations">
      <ConfirmDeleteAutomation
        id={state?.idToDelete ?? null}
        onCancel={handleCancel}
      />
      {state?.currentStatus === 'CREATING' ? (
        <CreateActionTrigger
          deviceId={deviceId}
          onCancel={handleCancel}
          onSuccess={handleCancel}
        />
      ) : (
        <ExistingAutomations
          {...props}
          onClickDelete={handleClickDeleteRule}
          onClickNewRule={() => setState({ currentStatus: 'CREATING' })}
        />
      )}
    </div>
  );
}
