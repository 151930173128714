import type { RootState } from 'src/root.reducer';

type ThunkApi = {
  readonly getState: () => RootState;
};

/**
 * Prevents extra requests from being sent while the current
 * one is still resolving
 * @param requestName
 */

/**
 * @param requestName
 */
export default function cancelOnPending(
  requestName: string
): (_: unknown, { getState }: ThunkApi) => boolean {
  return (_: unknown, { getState }: ThunkApi): boolean => {
    const status =
      getState().userSession.requests.entities[requestName]?.fetchStatus;

    if (status === 'pending') {
      return false;
    }
    return true;
  };
}
