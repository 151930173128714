/* eslint-disable jsdoc/require-jsdoc */

import type React from 'react';
import type { RouteComponentProps } from 'react-router';
import type { AdminOnlyProps, HasIconKey } from 'src/components/types';

export type CountryRegion = {
  readonly country: string;
  readonly region: string;
};
export const FARM_FORM_DATA_KEYS = [
  'name',
  'addressLine1',
  'addressLine2',
  'city',
  'postalCode'
] as const;
export type FarmFormDataKeys = typeof FARM_FORM_DATA_KEYS[number];

export type FarmProperties = CountryRegion &
  {
    readonly [key in FarmFormDataKeys]: string;
  };

export type JSONPrimitive =
  | bigint
  | boolean
  | number
  | string
  | null
  | undefined;

export type JSONValue =
  | JSONPrimitive
  | Readonly<{ readonly [key: string]: JSONValue }>
  | readonly JSONValue[];

export interface RouteDef<
  T extends string = string,
  P extends RouteComponentProps = RouteComponentProps
> extends AdminOnlyProps,
    HasIconKey {
  readonly component: React.ComponentType<P>;

  id: string;
  readonly isAdminOnly?: boolean;
  readonly isDevOnly?: boolean;
  readonly isUnderConstruction?: boolean;
  readonly isSidebarLink?: boolean;
  readonly name: string;
  readonly path: T;
}
