import type { Dictionary } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import store from 'storejs';
import type { ActiveFeature } from './actions';
import type { SelectItemPayload } from './activeFeature.reducer';
import type { ThemeType } from './farm';
import type { FarmField } from './fields';
import { Geo } from './geo';
import { useAppSelector } from './hooks';
import type { RootSelector, RootState } from './store';
import { notNullish } from './types';
import type { TrackedRequest } from './userSession.reducer';
import createEntitySelectors from './utilities/createEntitySelectors';

/**
 * Get current feature selected by user
 *
 * if null, the app is in a 'neutral' state i.e. the user is viewing the map
 * in its initial state after data is loaded
 * @param state
 */
export function getActiveFeature(state: RootState): ActiveFeature | null {
  return state.appFeature.activeFeature;
}

/**
 * Get current feature selected by user
 *
 * if null, the app is in a 'neutral' state i.e. the user is viewing the map
 * in its initial state after data is loaded
 * @param state
 */
export function useActiveFeature(): ActiveFeature | null {
  return useAppSelector(getActiveFeature);
}

/**
 * @param state
 */
export function getSelectedItem(
  state: RootState
): SelectItemPayload | undefined {
  return state.appFeature.selectedItem;
}

/**
 * Get id of selected device if any
 * @param state
 * @returns the id or null if none selected
 */

export const getSelectedDeviceId = createSelector(
  getSelectedItem,
  (item): string | null => (item?.kind === 'device' ? item.id : null)
);

/**
 * Get id of selected field if any
 * @param state
 * @returns the id or null if none selected
 */
export const getSelectedFieldId = createSelector(
  getSelectedItem,
  (item): number | null => (item?.kind === 'field' ? item.id : null)
);

/**
 * Get id of selected reelr run if any
 * @param state
 * @returns the id or null if none selected
 */
export const getSelectedReelRunId = createSelector(
  getSelectedItem,
  (item): number | null => (item?.kind === 'reelRun' ? item.id : null)
);

/**
 * @param state
 * @param id
 */
function getEventById(state: RootState, id: number): FarmField | undefined {
  return state.farmFields.entities[id];
}

const getAllEvents = createEntitySelectors(
  (state) => state.deviceEventLast
).all;

export const getEventsNearField: RootSelector<string[], [fieldId: number]> =
  createCachedSelector(getAllEvents, getEventById, (events, field) => {
    if (field) {
      return events
        .filter((de): boolean => {
          const location = de.gps?.location;
          if (notNullish(location)) {
            return Geo.polygons.containsPoint(
              Geo.polygons.make.buffer(field.polygon, 100),
              location
            );
          }
          return false;
        })
        .map((de) => de.deviceId);
    }
    return [];
  })((_, id) => id);

/**
 * @param state
 */
export function getTrackedRequestNames(state: RootState): string[] {
  return state.userSession.requests.ids as string[];
}
/**
 * @param state
 */
function getRequests(state: RootState): Dictionary<TrackedRequest> {
  return state.userSession.requests.entities;
}
/**
 * Returns true if any async thunk (external API request) is currently pending
 */
export const getIsAnyRequestPending = createSelector(
  getTrackedRequestNames,
  getRequests,
  (ids, entities): boolean =>
    Boolean(ids.find((id) => entities[id]?.fetchStatus === 'pending'))
);

/**
 * Returns true if any async thunk (external API request) is currently pending
 */
export function useIsAnyRequestPending(): boolean {
  return useSelector(getIsAnyRequestPending);
}

/**
 * Get the user theme preference from app state or local storage
 * @param state
 */
export function getThemePreference(state: RootState): ThemeType {
  return (
    state.userSession.preferences.themePreference ??
    store.get('themePreference') ??
    'light'
  );
}
/**
 * Return true if theme is set to 'dark'
 */
export const getIsDarkMode = createSelector(
  getThemePreference,
  (pref): boolean => pref === 'dark'
);
export const getVerifiedPhoneNumbers = createSelector(
  createEntitySelectors((state) => state.userPhoneNumbers).all,
  (numbers) => Object.values(numbers).filter((pn) => pn.isVerified)
);
export const getHasVerifiedPhoneNumbers = createSelector(
  getVerifiedPhoneNumbers,
  (nums) => nums.length > 0
);
