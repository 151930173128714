import type {
  LinkProps,
  ListItemProps,
  TypographyVariant
} from '@material-ui/core';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import type { PropsWithChildren } from 'react';
import React from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { isNullish } from 'src/types';
import AppIcon from '../../theme/AppIcon';
import type { CanBeLink, HasIconKey, HasText, HasVariant } from '../types';

type ListItemLinkProps = CanBeLink &
  HasIconKey &
  HasText &
  HasVariant<TypographyVariant> &
  Pick<
    ListItemProps,
    | 'alignItems'
    | 'dense'
    | 'disabled'
    | 'disableGutters'
    | 'divider'
    | 'id'
    | 'selected'
  > & {
    onClick?: React.MouseEventHandler<HTMLElement>;
  } & (
    | {
        linkTo: string;
        href?: undefined;
        target?: undefined;
      }
    | {
        linkTo?: undefined;
        href: LinkProps['href'];
        target: LinkProps['target'];
      }
  );

/**
 * @param props
 * @param props.linkTo
 * @param props.onClick
 * @param props.variant
 * @param props.selected
 * @param props.dataCy
 * @param props.divider
 * @param props.id
 * @param props.alignItems
 * @param props.iconKey
 * @param props.text
 * @param props.children
 * @param props.disabled
 * @param props.dense
 * @param props.disableGutters
 */
export default function ListItemLink({
  linkTo,
  onClick,
  variant,
  selected,
  divider,
  id,
  alignItems,
  iconKey,
  text: textPrimary,
  children,
  disabled,
  dense,
  // ...rest
  disableGutters
}: PropsWithChildren<ListItemLinkProps>): JSX.Element {
  const renderLink = React.useMemo(() => {
    if (typeof linkTo === 'undefined') {
      throw new Error(
        'List item link requires linkTo prop (received undefined)'
      );
    }
    return (
      // eslint-disable-next-line react/display-name
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink ref={ref} to={linkTo} {...itemProps} />
      ))
    );
  }, [linkTo]);
  renderLink.displayName = 'renderLink';
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClick: React.MouseEventHandler<HTMLElement> = React.useCallback(
    (e) => {
      e.preventDefault();
      if (typeof onClick !== 'undefined') {
        onClick(e);
      }
      if (!isNullish(linkTo)) {
        history.push(linkTo);
      }
    },
    [history, linkTo, onClick]
  );
  const typographyProps = {
    variant
  };
  return (
    <li>
      <ListItem
        alignItems={alignItems}
        button
        component={renderLink}
        data-cy={id}
        dense={dense}
        disableGutters={disableGutters}
        disabled={disabled}
        divider={divider}
        id={id}
        onClick={handleClick}
        selected={selected === true || pathname === linkTo}
      >
        <ListItemIcon>
          {iconKey ? <AppIcon iconKey={iconKey} /> : null}
        </ListItemIcon>
        <ListItemText
          primary={textPrimary ?? children}
          primaryTypographyProps={typographyProps}
        />
      </ListItem>
    </li>
  );
}
