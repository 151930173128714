import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import Actions from 'src/actions';
import { getSelectedReelRunId } from 'src/appSelectors';
import asyncGetUserData from 'src/asyncGetUserData';
import { useAppSelector } from 'src/hooks';
import asyncUpdateMap from 'src/status-map/asyncUpdateMap';
import { isNullish } from 'src/types';

import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { asyncAdjustReelRunDirection } from './actions';

import type { SprinklerType } from 'src/devices/sensors';
import type { PointGeoJson } from 'src/geo';
import type { RootState } from 'src/root.reducer';
import type { RootSelector } from 'src/store';
import type { DateString, Nullable } from 'src/types';
import type { ReelRunObservationReport } from './RunObservationReports';

interface ReelRun {
  reelRunId: number;
  deviceId: string;
  deviceConfigurationId: number;
  startTimestamp: DateString;
  endTimestamp: DateString | null;
  lastEventTimestamp: DateString | null;
  directionOverrideAzimuthDegrees: number | null;
  fieldRowDirectionAzimuthDegrees: number | null;
  fieldId: Nullable<number>;
  inProgress: boolean;
  distanceMmMax: number;
  endPoint: PointGeoJson | null;
  startPoint: PointGeoJson | null;
  duration: number | null;
  reelSprinklerType: SprinklerType | null;
  reelSwathWidthMm: number | null;
  observations: ReelRunObservationReport[];
}

const adapter = createEntityAdapter<ReelRun>({
  selectId: (reelRun) => reelRun.reelRunId,
  sortComparer: (runA, runB) => {
    return (
      new Date(runA.lastEventTimestamp ?? '').getTime() -
      new Date(runB.lastEventTimestamp ?? '').getTime()
    );
  }
});

const getState = (state: RootState) => state.reelRunsActive;
const selectors = adapter.getSelectors(getState);

interface State extends EntityState<ReelRun> {
  selectedId: number | null;
}

const initialState: State = adapter.getInitialState({
  selectedId: null
});

const slice = createSlice({
  /**
   * @param builder
   */
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        if (payload.activeFarm?.reelRuns) {
          adapter.addMany(state, [...payload.activeFarm.reelRuns]);
        }
      })
      .addCase(asyncUpdateMap.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.reelRuns);
      })
      .addCase(asyncAdjustReelRunDirection.fulfilled, (state, action) => {
        const { reelRunId, directionAzimuthDegrees } = action.meta.arg;
        adapter.updateOne(state, {
          changes: {
            directionOverrideAzimuthDegrees: directionAzimuthDegrees
          },
          id: reelRunId
        });
      })
      .addCase(Actions.selectItem, (state, { payload }) => {
        if (payload.kind === 'reelRun' && state.selectedId === payload.id) {
          state.selectedId = payload.id;
        } else {
          state.selectedId = null;
        }
      }),
  initialState,
  name: `activeReelRuns`,
  reducers: {}
});

const reelRuns: Reducer<State> = slice.reducer;

const getRunById = createCachedSelector(
  (state: RootState, runId: number) => selectors.selectById(state, runId),
  (run) => run ?? null
)((_state, runId: number) => runId);

/**
 * Non-nullable
 * @param runId
 */
function useRunById(runId: number): ReelRun {
  const runData = useAppSelector((state) => getRunById(state, runId));
  if (isNullish(runData)) {
    throw new Error('No run data found');
  }

  return runData;
}

const getSelectedReelRun = createSelector(
  (state: RootState) => getSelectedReelRunId(state),
  (state: RootState) => (runId: number | null) => getRunById(state, runId ?? 0),
  (runId, getRun) => getRun(runId)
);

/**
 *
 */
function useSelectedReelRun(): ReelRun | null {
  return useAppSelector((state) => getSelectedReelRun(state));
}

const ReelRunsActive = {
  adapter,
  getSelectedReelRun,
  useRunById,
  useSelectedReelRun,
  ...selectors,
  getRunById,
  getSelectedRunId: getSelectedReelRunId,
  selectIds: selectors.selectIds as RootSelector<number[]>
};
export {
  ReelRunsActive,
  getRunById,
  useRunById,
  useSelectedReelRun,
  getSelectedReelRun
};
export type { ReelRun };
export default reelRuns;
