import type { AxiosResponse } from 'axios';
import axios from 'axios';
import googleMapsApiKey from 'src/constants/googleMapsApiKey';
import type { FarmAccount } from 'src/farm';
import { formatFarmAddress } from 'src/utilities/string-formatting';

type GeocoderResult = google.maps.GeocoderResult & {
  readonly geometry: { location: google.maps.LatLngLiteral };
};

type GmapsGeocodeResponse = {
  readonly results: GeocoderResult[];
};

const GEOCODE_BASE_URL =
  `https://maps.googleapis.com/maps/api/geocode/` as const;
/**
 * Gets geocode result for given address
 *
 * @param axiosInstance
 * @param env
 * @param address
 */

const axiosInstance = axios.create();

/**
 * Used to determine farm gps location on creation
 * @param farm
 */
export default async function geocodeFarmAddress(
  farm: Omit<FarmAccount, 'gpsLocation' | 'id'>
): Promise<AxiosResponse<GmapsGeocodeResponse>> {
  const address = formatFarmAddress(farm);
  const formattedAddress = address
    .split(` `)
    .filter((element) => element !== ``)
    .join(`+`);

  const format = `json`;
  const url = `${GEOCODE_BASE_URL}${format}?address=${formattedAddress}&key=${googleMapsApiKey}`;
  return axiosInstance.get<GmapsGeocodeResponse>(url);
}
