import { isPositiveInt } from 'src/types';
export type MinDateMaxDate = {
  readonly minDateMs?: number;
  readonly maxDateMs?: number;
};
/**
 * @param args
 * @param args.initialDateMs
 * @param args.unit
 * @param args.value
 */
export default function getTimeDelta(args: {
  initialDateMs?: number;
  unit: 'days' | 'hours';
  value: number;
}): number {
  const hoursAgo = args.unit === 'days' ? args.value * 24 : args.value;
  const minutesAgo = hoursAgo * 60;
  const secondsAgo = minutesAgo * 60;
  const minDateMs = secondsAgo * 1000;
  const now = isPositiveInt(args.initialDateMs)
    ? new Date(args.initialDateMs)
    : new Date();
  return now.getTime() - minDateMs;
}
