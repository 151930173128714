import type { MarkerProps } from '@react-google-maps/api';
import Color from 'color';
import { isNumber } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import Actions from 'src/actions';
import { getActiveFeature, getSelectedDeviceId } from 'src/appSelectors';
import { MapMarkers } from 'src/drawing';
import { Geo } from 'src/geo';
import { useAppDispatch } from 'src/hooks';
import { isNullish, isTruthyString } from 'src/types';
import { isFalsy } from 'utility-types';

import { Marker } from '@react-google-maps/api';

import { togglePartnerCallout } from './actions';
import { mapInstallationTypeToColor } from './installation-type';
import { getDeviceMetadataById, getEventByDeviceId } from './selectors';
import { getDevicePartnerIsSelected, Triggers } from './triggers.reducer';

import type { ConnectedProps } from 'react-redux';
import type { DeviceMetadata } from 'src/devices';
import type { RootState } from 'src/root.reducer';
import type { InstallationType } from './installation-type';
import type { DeviceIdProp } from './models';
/**
 * Create teardrop icon with color corresponding to installation type
 * @param variant
 * @param variant.fillColor
 * @param fillColor
 * @param variant.isPairing
 * @param variant.isMuted
 * @param variant.isSelected
 * @param variant.isSearchMatch
 */
function createMarkerIcon({
  fillColor,
  isSelected,
  isSearchMatch,
  isMuted
}: {
  fillColor: string;
  isMuted: boolean;
  isSelected: boolean;
  isSearchMatch: boolean;
}): google.maps.Symbol {
  return {
    fillColor: isMuted ? Color(fillColor).lighten(0.5).hex() : fillColor,
    fillOpacity: isMuted ? 0.3 : 1,
    labelOrigin: new google.maps.Point(1, -28),
    path: MapMarkers.createTearDropShape(),
    rotation: 0,
    strokeColor: isSearchMatch ? 'gold' : isSelected ? 'cyan' : `white`,
    strokeWeight: isSearchMatch || isSelected ? 3 : 1
  };
}

/**
 * Converts installation type to P or R
 * @param installationType
 * @param _indexId
 */
function createMarkerLabel(
  installationType: InstallationType | null | undefined
): google.maps.MarkerLabel {
  return {
    color: 'white',
    fontFamily: 'Roboto Slab Bold',
    fontSize: `12px`,
    fontWeight: `bold`,

    text: `${
      installationType === 'prototype'
        ? 'PR'
        : installationType?.substring(0, 1).toUpperCase() ?? ''
    }`
  };
}

/**
 * @param value
 * @param md
 */
function getIsInSearch(value: string, md?: DeviceMetadata | null): boolean {
  const lowerVal = value.toLocaleLowerCase();
  if (isNullish(md)) {
    return false;
  }
  const { codaDeviceAlias, deviceName, deviceInstallationType } = md;
  if (isFalsy(lowerVal)) {
    return true;
  }
  if (codaDeviceAlias.toLocaleLowerCase().includes(lowerVal)) {
    return true;
  } else if (deviceName.toLocaleLowerCase().includes(lowerVal)) {
    return true;
  } else if (
    deviceInstallationType &&
    deviceInstallationType.toLocaleLowerCase().includes(lowerVal)
  ) {
    return true;
  }
  return false;
}

type OwnProps = DeviceIdProp &
  Pick<MarkerProps, 'clusterer' | 'cursor' | 'onClick'> & {
    searchValue: string;
  };

const connector = connect(
  (state: RootState, { deviceId, searchValue, ...ownProps }: OwnProps) => {
    const selectedId = getSelectedDeviceId(state);

    const isSelected = selectedId === deviceId;
    const metadata = getDeviceMetadataById(state, deviceId);
    const anySelected = !isNullish(selectedId);
    const otherIsSelected = anySelected && !isSelected;

    const event = getEventByDeviceId(state, deviceId);
    // const isUpdating = getIsForceUpdateInProgress(state, deviceId);
    const isUpdating = false;
    const isSelectedForPairing = state.triggers.partnerId === deviceId;
    const { deviceInstallationType, codaDeviceAlias, deviceName } =
      metadata ?? {};
    const { gps, indexId } = event ?? {};
    const { isPairing } = state.triggers;
    const { visibility } = state.statusMap;
    const activeFeature = getActiveFeature(state);
    const location = gps?.location;
    // const isValidPairingOption = getDeviceIsValidPairingOption(state, deviceId);
    const isValidPairingOption = false;

    let isHidden = false;

    const isSearchMatch = getIsInSearch(searchValue, metadata);
    if (
      !isSearchMatch ||
      (deviceInstallationType && !visibility[deviceInstallationType])
    ) {
      isHidden = true;
    }
    if (activeFeature === 'VIEW_RUN_HISTORY') {
      isHidden = true;
    }
    const partnerIsSelected = getDevicePartnerIsSelected(state, deviceId);
    return {
      animation: isSelected || isUpdating ? 1 : undefined,
      clickable: activeFeature === null || isValidPairingOption,
      codaDeviceAlias,
      deviceId,
      deviceInstallationType,
      deviceName,
      icon: createMarkerIcon({
        fillColor: mapInstallationTypeToColor({
          deviceInstallationType
        }),
        isSearchMatch: isSearchMatch && isTruthyString(searchValue),
        isMuted:
          (otherIsSelected && !partnerIsSelected) ||
          (isPairing && !isValidPairingOption),
        isSelected: isSelected || partnerIsSelected
      }),
      isHidden,
      isPairing,
      isValidPairingOption,
      isStagedForPairing: isValidPairingOption && isSelectedForPairing,
      label: isUpdating
        ? { text: 'Updating...' }
        : isNumber(indexId)
        ? createMarkerLabel(deviceInstallationType)
        : deviceName,
      position: location ? Geo.points.to.googleMaps(location) : null,
      ...ownProps
    };
  }
);



type Props = ConnectedProps<typeof connector>;

/**
 *
 * @param props

 * @param props.animation
 * @param props.clickable
 * @param props.clusterer
 * @param props.codaDeviceAlias
 * @param props.cursor
 * @param props.deviceId
 * @param props.icon
 * @param props.isHidden
 * @param props.isPairing
 * @param props.isStagedForPairing
 * @param props.label
 * @param props.position
 * @param props.isValidPairingOption
 * @returns
 */
function DeviceMarker({
  animation,
  clickable,
  clusterer,
  codaDeviceAlias,
  cursor,
  deviceId,
  icon,
  isHidden,
  isPairing,
  isValidPairingOption,
  isStagedForPairing,
  label,
  position
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const handleClick = React.useCallback(() => {
    if (clickable) {
      if (isStagedForPairing) {
        dispatch(togglePartnerCallout(deviceId));
      } else if (isPairing && isValidPairingOption) {
        dispatch(Triggers.selectPartnerDevice(deviceId));
      } else {
        dispatch(Actions.selectItem({ id: deviceId, kind: 'device' }));
      }
    }
  }, [
    clickable,
    deviceId,
    dispatch,
    isPairing,
    isStagedForPairing,
    isValidPairingOption
  ]);

  // const defaultOptions = React.useMemo(
  //   /**
  //    * Type:  Array<number>
  //    * The format of this attribute depends on the value of the type and
  //    * follows the w3 AREA coords specification found at
  //    * http://www.w3.org/TR/REC-html40/struct/objects.html#adef-coords.
  //    * The coords attribute is an array of integers that specify the
  //    * pixel position of the shape relative to the top-left corner of
  //    * the target image. The coordinates depend on the value of type
  //    * as follows:
  //    - circle: coords is [x1,y1,r] where x1,y2 are the coordinates of the center of the circle, and r is the radius of the circle.
  //    - poly: coords is [x1,y1,x2,y2...xn,yn] where each x,y pair contains the coordinates of one vertex of the polygon.
  //    - rect: coords is [x1,y1,x2,y2] where x1,y1 are the coordinates of the upper-left corner of the rectangle and x2,y2 are the coordinates of the lower-right coordinates of the rectangle.
  //    */
  //   (): google.maps.MarkerOptions | undefined => {
  //     return {
  //       shape: position
  //         ? {
  //             coords: [position.lng, position.lat, 1],
  //             type: 'circle',
  //           }
  //         : undefined,
  //     };
  //   },
  //   [position]
  // );

  return !isNullish(position) ? (
    <Marker
      animation={animation}
      clickable={clickable}
      clusterer={clusterer}
      cursor={cursor}
      icon={icon}
      label={label}
      onClick={handleClick}
      opacity={1}
      options={{
        title: codaDeviceAlias?.toLocaleLowerCase()
      }}
      position={position}
      title={codaDeviceAlias}
      visible={!isHidden}
    />
  ) : null;
}

export default connector(DeviceMarker);
