import { createLogger } from 'redux-logger';

export default createLogger({
  collapsed: false,
  duration: true,
  level: {
    action: 'info',
    nextState: 'info'
  },
  logErrors: true
});
