import type { Theme } from '@material-ui/core';
import {
  Box,
  Container,
  createStyles,
  LinearProgress,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import asyncGetUserData from 'src/asyncGetUserData';
import useLogo from 'src/hooks/useLogo';
import type { RootState } from 'src/store';
import testIds from 'src/testIds';
import { getFetchStatusByName } from 'src/userSession.reducer';

const useStyles = makeStyles<Theme, { readonly isOpen: boolean }>((theme) =>
  createStyles({
    loader: {
      alignItems: `center`,
      bottom: 150,
      display: `flex`,
      justifyContent: `center`,
      left: 0,
      position: `absolute`,
      right: 0,
      top: 0,
      zIndex: theme.zIndex.modal + 2
    },
    loadingText: { textShadow: `1px 3px rgb(75,75,75) solid` },
    root: ({ isOpen }) => {
      return {
        bottom: 0,
        left: 0,
        position: `absolute`,
        right: 0,
        top: 0,
        visibility: isOpen ? 'visible' : 'hidden',
        zIndex: theme.zIndex.modal + 1
      };
    }
  })
);
/**
 * @param props
 * @param props.children
 * @param props.id
 * @param props.message
 */
export function FullscreenLoader({
  children,
  id,
  message = 'Loading...'
}: React.PropsWithChildren<{ id?: string; message?: string }>): JSX.Element {
  const logo = useLogo('StackedLockup');

  const classes = useStyles({ isOpen: true });
  return (
    <Paper className={classes.root} data-cy="paper" id="fullscreen-loader">
      <div className={classes.loader} id={id}>
        <Container id="loader-container-outer" maxWidth="xs">
          <Box padding={6} width="100%">
            <img src={logo} width="100%" />
            <LinearProgress
              aria-busy="true"
              aria-describedby="progress"
              color="secondary"
              id="loader-progress"
              variant="indeterminate"
            />
            <Box textAlign="right">
              <Typography
                className={classes.loadingText}
                color="textSecondary"
                id="loader-message"
                variant="subtitle2"
              >
                {message}
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
      {children}
    </Paper>
  );
}

/**
 * @param state
 */
function getUserIsLoading(state: RootState) {
  return getFetchStatusByName(state, asyncGetUserData);
}

const getIsOpen = createSelector(getUserIsLoading, (activeUser) => {
  if (activeUser === 'pending') {
    return true;
  }
  return false;
});

/**
 * @param props
 * @param props.children
 * @param props.isOpen
 */
export default function RootLoader({
  children
}: React.PropsWithChildren<unknown>): JSX.Element {
  const isOpen = useSelector(getIsOpen);
  const classes = useStyles({ isOpen });

  const id = 'loader';
  return (
    <div
      className={classes.root}
      data-cy={testIds.loaders.root}
      id={testIds.loaders.root}
    >
      {isOpen ? <FullscreenLoader id={id}>{children}</FullscreenLoader> : null}
    </div>
  );
}
