import type { RegisterOptions } from 'react-hook-form';
import { FORM_VALIDATION } from 'src/constants';
import { User } from 'src/userSession.reducer';
import type { ConversionTarget } from './getConversionUnits';
import getConversionUnits from './getConversionUnits';
import type { AnySensorConfigKey } from './SensorConfig';

/**
 * @param target
 */
export default function useSensorFieldValidation(
  target: ConversionTarget = 'user'
): (key: AnySensorConfigKey) => RegisterOptions {
  const isMetric = User.useIsMetric();

  return (key: AnySensorConfigKey) => {
    const sigDigits =
      getConversionUnits({ isMetric, key, target })?.sigDigits ?? 0;
    if (sigDigits === 0) {
      return {
        ...FORM_VALIDATION.requiredInt
      };
    }
    return {
      pattern: FORM_VALIDATION.patterns.decimalNumber,
      required: FORM_VALIDATION.required,
      valueAsNumber: true
    };
  };
}
