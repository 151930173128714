import type { SliderClassKey, StyleRules } from '@material-ui/core';
import React from 'react';

import { createStyles, makeStyles, Slider } from '@material-ui/core';

import useSensorUnitLabel from '../useSensorUnitLabel';
import { usePressureMarks } from './PressureThreshold';

import type { PressureThresholds } from '../SensorConfig';
import type { SensorEvent } from '../SensorEvent';
type Props = Partial<Pick<SensorEvent<'pressure'>, 'readingKpa'>> &
  PressureThresholds;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDisplayStyles = makeStyles(() => {
  const railHeight = 5;
  const styles: StyleRules<SliderClassKey, Props> = {
    active: () => ({}),
    colorPrimary: () => ({}),
    colorSecondary: () => ({}),
    disabled: () => ({
      // minHeight: 400,
    }),
    focusVisible: () => ({}),
    mark: () => ({
      height: railHeight
      // paddingBottom: 0,
    }),
    markActive: () => ({}),
    markLabel: () => ({}),
    markLabelActive: () => ({}),
    marked: () => ({
      // marginBottom: 20,
    }),
    rail: () => ({
      // background: `rgb(222,222,222)`;
      background: `linear-gradient(90deg, rgba(222,222,222,1) 0%, rgba(44,175, 244,1) 53%, rgba(255,106,0,1) 99%)`,
      height: railHeight

      // padding: 2,
      // paddingBottom: 0,
    }),
    root: () => ({
      // marginBottom: 0,
      // paddingRight: theme.spacing(2),
      // paddingTop: 5,
    }),
    thumb: (props: Props) => ({
      borderRadius: 20,
      height: railHeight + 5,
      visibility: typeof props.readingKpa === 'undefined' ? 'hidden' : 'visible'
    }),
    thumbColorPrimary: () => ({}),
    thumbColorSecondary: () => ({}),
    track: () => ({}),
    trackFalse: () => ({}),
    trackInverted: () => ({}),
    valueLabel: () => ({
      // backgroundColor: 'green',
    }),
    vertical: () => ({})
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  return createStyles(styles);
});

const PressureReadingDisplay = React.forwardRef<any, Props>((props, ref) => {
  const pressureClasses = useDisplayStyles(props);
  const { readingKpa, ...pressureThresholds } = props;
  const marks = usePressureMarks(pressureThresholds);
  const unitLabel = useSensorUnitLabel('short')('thresholdPsiLower');
  return (
    <Slider
      classes={pressureClasses}
      disabled
      marks={marks}
      max={pressureThresholds.thresholdPsiUpper + 20}
      min={-5}
      ref={ref}
      track={false}
      value={readingKpa ?? undefined}
      valueLabelDisplay="auto"
      valueLabelFormat={(val) => `${val} ${unitLabel}`}
    />
  );
});
PressureReadingDisplay.displayName = 'PressureReadingDisplay';
export default PressureReadingDisplay;
