import { LinearProgress } from '@material-ui/core';
import { isNumber } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import { isNullish, notNullish } from 'src/types';
import { getRunEtaByDeviceId } from '../calculateReelEta';
import type { DeviceIdProp } from '../models';
import { useSensorEventByDeviceId } from '../selectors';
import Sensors from '../sensors';

const dateFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' });
const STYLE: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
/**
 * @param props
 * @param props.deviceId
 */
export function ReelRunSummary({ deviceId }: DeviceIdProp): JSX.Element | null {
  const data = useSensorEventByDeviceId('reel', deviceId);
  const formatVal = Sensors.useFormatValue();
  const eta = useSelector((state) => getRunEtaByDeviceId(state, deviceId));
  if (isNullish(data)) {
    return null;
  }
  const {
    runDistanceMmCurrent: distanceMmCurrent,
    runDistanceMmMax: distanceMmMax
  } = data;
  if (isNullish(distanceMmMax) || isNullish(distanceMmCurrent)) {
    return null;
  }
  const elapsedDistanceMm = distanceMmMax - distanceMmCurrent;
  const distanceCurrentText = formatVal(
    'runDistanceMmCurrent',
    elapsedDistanceMm
  );
  if (distanceMmMax === 0) {
    return null;
  }
  const distanceMaxText = formatVal('runDistanceMmMax', distanceMmMax);
  const completionPct = Math.round((elapsedDistanceMm / distanceMmMax) * 100);
  const { runSpeedMmpm, runDistanceMmCurrent, runDistanceMmMax } = data;
  return (
    <div style={STYLE}>
      <div>
        <div>Speed: {formatVal('runSpeedMmpm', runSpeedMmpm)}</div>
        <div>ETA: {notNullish(eta) ? dateFormatter.format(eta) : 'N/A'}</div>
      </div>
      {isNumber(runDistanceMmCurrent) && isNumber(runDistanceMmMax) ? (
        <div style={{ width: 150 }}>
          {distanceCurrentText}/{distanceMaxText} ({completionPct} %)
          <LinearProgress value={completionPct} variant="determinate" />
        </div>
      ) : null}
    </div>
  );
}
