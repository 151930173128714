import bounds from './bounds';
import lines from './lines';
import points from './points';
import polygons from './polygons';

export const Geo = {
  bounds,
  lines,
  points,
  polygons
};

export * from './types';
