import { isNumber } from 'lodash-es';
import React from 'react';
import { Geo } from 'src/geo';
import { isArrayOrReadonlyArray } from 'src/types';

import PolygonBase from '../PolygonBase';
import PolylineBase from '../PolylineBase';
import drawRowDirectionArrow from './drawRowDirectionArrow';

import type { FarmField } from 'src/fields';
import type { PolygonBaseProps } from '../PolygonBase';
import type { PolylineBaseProps } from '../PolylineBase';
import type { RowDirectionShapeArguments } from './drawRowDirectionArrow';
const DEFAULTS: google.maps.PolygonOptions = {
  fillColor: 'cyan',
  fillOpacity: 1,
  strokeWeight: 2
};

interface RowDirectionArrowProps
  extends Pick<
      PolygonBaseProps,
      | 'clickable'
      | 'fillColor'
      | 'fillOpacity'
      | 'isHidden'
      | 'onClick'
      | 'strokeOpacity'
    >,
    Pick<
      RowDirectionShapeArguments,
      'arrowheadLengthMeters' | 'azimuth' | 'center' | 'enclosingPolygon'
    >,
    Pick<FarmField, 'boundingRadiusMeters'> {
  readonly lineWidth?: PolylineBaseProps['strokeWeight'];
}

/**
 * Polygon and polyline which form an arrow together
 * @param props

 * @param props.arrowheadLengthMeters
 * @param props.azimuth
 * @param props.boundingRadiusMeters
 * @param props.center
 * @param props.clickable
 * @param props.enclosingPolygon
 * @param props.fillColor
 * @param props.fillOpacity
 * @param props.isHidden
 * @param props.lineWidth
 * @param props.onClick
 * @param props.strokeOpacity
 */
function RowDirectionArrow({
  arrowheadLengthMeters,
  azimuth,
  boundingRadiusMeters,
  center,
  clickable,
  enclosingPolygon,
  fillColor,
  isHidden,
  onClick,
  strokeOpacity
}: RowDirectionArrowProps) {
  const paths = React.useMemo(() => {
    const shapes = isNumber(boundingRadiusMeters)
      ? drawRowDirectionArrow({
          arrowheadLengthMeters,
          azimuth,
          boundingRadiusMeters,
          center,
          enclosingPolygon
        })
      : null;
    return shapes
      ? {
          arrow: Geo.polygons.to.gmaps(shapes.arrowPolygon),
          line: Geo.lines.to.gmapsPath(shapes.linePath)
        }
      : null;
  }, [
    arrowheadLengthMeters,
    azimuth,
    boundingRadiusMeters,
    center,
    enclosingPolygon
  ]);

  const linePath = paths?.line;
  const arrowPath = paths?.arrow;

  const color = fillColor ?? DEFAULTS.fillColor;
  const opacity = strokeOpacity ?? 0.4;
  return !isArrayOrReadonlyArray(linePath) ||
    !isArrayOrReadonlyArray(arrowPath) ? null : (
    <React.Fragment>
      <PolylineBase
        isHidden={isHidden}
        onClick={onClick}
        path={linePath}
        strokeColor={color}
        strokeOpacity={opacity}
      />
      <PolygonBase
        clickable={clickable}
        fillColor={color}
        fillOpacity={opacity}
        isHidden={isHidden}
        onClick={onClick}
        path={arrowPath}
        strokeColor={color}
        strokeOpacity={opacity}
        strokeWeight={0.5}
      />
    </React.Fragment>
  );
}

const RowDirectionArrowMemoized = React.memo(RowDirectionArrow);
RowDirectionArrowMemoized.displayName = 'RowDirectionArrow';
export default RowDirectionArrowMemoized;
