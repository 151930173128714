import type { SvgIconProps } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { ReactComponent as PumpIcon } from './icon/Pump.svg';
import { ReactComponent as ReelIcon } from './icon/Reel.svg';

/**
 * @param props
 */
function Reel(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <ReelIcon />
    </SvgIcon>
  );
}
/**
 * @param props
 */
function Pump(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <PumpIcon />
    </SvgIcon>
  );
}
const InstallationTypeIcon = {
  Reel,
  Pump
};
export default InstallationTypeIcon;
