import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import { capitalize } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import { SubmitBtn } from 'src/components';
import { useAppSelector, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import InstallationTypes from '../installation-type';
import Sensors, { SensorConfigs } from '../sensors';
import type { ValidSensor } from './createConfig.reducer';
import { asyncCreateConfiguration, CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';
const Ids = testIds.createConfiguration.confirmAndSubmit;

/**
 * @param props
 * @param props.sensorName
 */
function SensorDisplay({ sensorName }: { sensorName: ValidSensor }) {
  const formatKey = Sensors.useFormatKey();
  const formatVal = Sensors.useFormatValue({ unitLabel: true });
  const data = useSelector(
    (state) => state.createConfiguration.sensorData[sensorName]
  );
  if (isNullish(data)) {
    return null;
  }
  const keys = SensorConfigs.getKeysOfSensorConfig(sensorName);

  return (
    <List data-cy={sensorName} dense id={sensorName}>
      <ListSubheader>{formatVal('sensorName', sensorName)}</ListSubheader>
      {keys.map((k) => {
        const val = data[k as keyof typeof data];
        const keyFormatted = formatKey(k);
        const valueFormatted = formatVal(k, val ?? null);
        const valId = Ids.confirmVal(k);
        const keyId = Ids.confirmKey(k);
        return k === 'sensorName' ? null : (
          <ListItem divider key={k}>
            <ListItemText data-cy={keyId} id={keyId}>
              {keyFormatted}
            </ListItemText>
            <ListItemSecondaryAction data-cy={valId} id={valId}>
              {valueFormatted}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

/**
 * @param props
 * @param props.children
 * @param props.deviceName
 * @param props.backBtn
 * @param props.onSuccess
 */
export default function ConfirmAndSubmit(): JSX.Element {
  const deviceName = useAppSelector(CreateConfig.getDeviceName);
  const deviceInstallationType = useAppSelector(
    CreateConfig.getInstallationType
  );
  const { sendRequest, isPending } = useThunk(asyncCreateConfiguration);

  const handleSubmit = async () => sendRequest().catch(handleApiRequestError);
  return (
    <FormWrapper
      instructions="A new configuration will be created"
      spacing={0}
      submitBtn={
        <SubmitBtn
          disabled={isPending}
          id={Ids.submitBtn}
          onClick={handleSubmit}
          presetKey="SUBMIT"
        />
      }
      testId={Ids.root}
    >
      <List dense>
        <ListItem data-cy={Ids.deviceName} divider id={Ids.deviceName}>
          <ListItemText data-cy="device-name-key" id="device-name-key">
            Name
          </ListItemText>
          <ListItemSecondaryAction
            data-cy="device-name-value"
            id="device-name-value"
          >
            {deviceName}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          data-cy={Ids.installationType}
          divider
          id={Ids.installationType}
        >
          <ListItemText>Installation Type</ListItemText>
          <ListItemSecondaryAction
            data-cy="installation-type-value"
            id="installation-type-value"
          >
            {capitalize(InstallationTypes.humanize(deviceInstallationType))}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {CreateConfig.VALID_SENSORS.map((sn) => (
        <SensorDisplay key={sn} sensorName={sn} />
      ))}
    </FormWrapper>
  );
}
