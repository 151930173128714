import { capitalize } from 'lodash-es';
import type React from 'react';
import { useControlledSelect } from 'src/components';
import type { SprinklerType } from 'src/devices/sensors';
import SensorConfigs, {
  CONFIG_DEFAULTS
} from 'src/devices/sensors/SensorConfig';
import useFormatSensorKey from 'src/devices/sensors/useSensorKeyFormatter';

/**
 * @param isActive
 * @param initialVal
 */
export default function useSprinklerSelect(
  isActive: boolean,
  initialVal?: SprinklerType
): {
  readonly SprinklerSelect: React.FC;
  readonly sprinklerType: 'boom' | 'gun';
} {
  const label = useFormatSensorKey()(`sprinklerType`);
  const { Component, value: sprinklerType } = useControlledSelect({
    fieldName: `sprinklerType`,
    initialValue: initialVal ?? CONFIG_DEFAULTS.reel.sprinklerType,
    isHidden: !isActive,
    label: label === null ? 'Sprinkler Type' : label,
    makeLabel: (val) => capitalize(val),
    options: [...SensorConfigs.SPRINKLER_TYPES]
  });
  if (!SensorConfigs.isValidSprinklerType(sprinklerType)) {
    throw new Error(`invalid sprinkler type`);
  }

  return { SprinklerSelect: Component, sprinklerType };
}
