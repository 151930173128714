import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { makeApiRequest } from 'src/requests';
import { showErrorToast, showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';
import { appLogger } from 'src/utilities';

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { asyncInvokeRemoteControl } from '../remote-controls';

import type { RootState } from 'src/root.reducer';
import type { RootThunkApi } from 'src/store';
import type { FetchStatus } from 'src/userSession.reducer';
import type { DeviceEvent } from '../models';
const FORCE_UPDATE_INTERVAL_MS = 3000;
const FORCE_UPDATE_MAX_TRIES = 10;
type ForceUpdateStatus = FetchStatus | 'timedOut';

type ForceUpdateTracker = {
  deviceId: string;
  fetchStatus: ForceUpdateStatus;
  numTries: number;
};

const { INITIALIZE_FORCE_UPDATE, GET_LAST_EVENT_FOR_DEVICE } = RequestNames;

const adapter = createEntityAdapter<ForceUpdateTracker>({
  selectId: (d) => d.deviceId,
  sortComparer: (a, b) => {
    if (a.fetchStatus === b.fetchStatus) {
      return 0;
    }
    if (a.fetchStatus === 'shouldFetch') {
      return 1;
    }
    return -1;
  }
});

interface State extends EntityState<ForceUpdateTracker> {
  readonly currentStatus: 'requestPending' | null;
}

const getSlice = (state: RootState): State => state.forceUpdate;
const selectors = adapter.getSelectors(getSlice);

const initialState: State = adapter.getInitialState({
  currentStatus: null
});
const asyncInitializeForceUpdate = createAsyncThunk<
  200,
  { readonly deviceId: string },
  RootThunkApi
>(
  INITIALIZE_FORCE_UPDATE,
  async ({ deviceId }, { dispatch }) => {
    try {
      await dispatch(
        asyncInvokeRemoteControl({ commandKey: 'FORCE_UPDATE', deviceId })
      );

      return 200 as const;
    } catch (e) {
      showErrorToast();
      throw new Error(e);
    }
  },
  {
    /**
     *  Do not initialize if already in progress
     * @param param0
     * @param param1
     * @param param0.deviceId
     * @param param1.getState
     */
    condition: ({ deviceId }, { getState }) => {
      const tracker = getSlice(getState()).entities[deviceId];
      if (tracker?.fetchStatus === 'pending') {
        return false;
      }
      return true;
    }
  }
);

const asyncGetLastEventForDevice = createAsyncThunk<
  DeviceEvent | 'NO EVENTS' | 'NOT NEW',
  { deviceId: string },
  RootThunkApi
>(
  GET_LAST_EVENT_FOR_DEVICE,
  async ({ deviceId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id: prevId } = state.deviceEventLast.entities[deviceId] ?? {};
      const config = state.devices.entities[deviceId];
      const { numTries } = selectors.selectById(state, deviceId) ?? {};
      if (numTries === FORCE_UPDATE_MAX_TRIES) {
        showErrorToast(
          `The request to the device timed out. 
          Pleas make sure it is turned on and has adequate 
          cell signal`
        );
        return rejectWithValue({
          code: 'TIMED_OUT'
        });
      }

      const responseData = await makeApiRequest<
        DeviceEvent | 'NO EVENTS' | 'NOT NEW',
        {
          readonly deviceId: string;
          readonly prevId?: number;
        }
      >(GET_LAST_EVENT_FOR_DEVICE, {
        deviceId,
        prevId
      });

      if (responseData !== `NO EVENTS` && responseData !== `NOT NEW`) {
        showSuccessToast(
          `Response received from ${config?.deviceName ?? 'the device'}.`
        );
      }
      return responseData;
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetUserData.pending, (state) => {
        adapter.removeAll(state);
      })
      .addCase(asyncInitializeForceUpdate.pending, (state, action) => {
        adapter.upsertOne(state, {
          deviceId: action.meta.arg.deviceId,
          fetchStatus: 'shouldFetch',
          numTries: 0
        });
      })
      .addCase(
        asyncGetLastEventForDevice.pending,
        /**
         * Increment the counter for this device so that it retries
         * @param state
         * @param action
         * @param action.meta
         * @param action.meta.arg
         */
        function onQueryForNewEvent(state, { meta: { arg } }) {
          const { deviceId } = arg;
          const pending = state.entities[deviceId];
          adapter.upsertOne(state, {
            ...pending,
            deviceId,
            fetchStatus: 'pending',
            numTries: (pending?.numTries ?? 0) + 1
          });
        }
      )
      .addCase(
        asyncGetLastEventForDevice.fulfilled,
        /**
         * If the response turns up a new event,
         * stop the update cycle by removing the tracker
         * @param state
         * @param action
         * @param action.payload
         * @param action.meta
         */
        function onNewEventQueryResponse(state, { payload, meta }) {
          const { deviceId } = meta.arg;
          const pending = state.entities[deviceId];
          if (isNullish(pending)) {
            appLogger.log('Got response for force update without tracker');
          } else if (payload === 'NO EVENTS' || payload === 'NOT NEW') {
            adapter.upsertOne(state, {
              ...pending,
              deviceId,
              fetchStatus: 'shouldFetch' // retry
            });
          } else {
            //success
            adapter.removeOne(state, deviceId);
          }
        }
      )
      .addCase(
        asyncGetLastEventForDevice.rejected,
        function handleTimeout(state, { meta, payload }) {
          if (meta.rejectedWithValue && payload?.code === 'TIMED_OUT') {
            adapter.removeOne(state, meta.arg.deviceId);
          }
        }
      ),
  initialState,
  name: 'forceUpdate',
  reducers: {}
});

const getNextForceUpdate = createSelector(selectors.selectAll, (trackers) =>
  trackers.find((t) => t.fetchStatus === 'shouldFetch')
);

const getIsForceUpdateInProgress = createCachedSelector(
  (state: RootState, id: string) => selectors.selectById(state, id),
  (tracker) => Boolean(tracker)
)((_, id) => id);
/**
 * @param id
 */
function useForceUpdateInProgress(id: string): boolean {
  return useAppSelector((state) => getIsForceUpdateInProgress(state, id));
}

// const { actions } = slice;
const forceUpdate: Reducer<State> = slice.reducer;
export default forceUpdate;
export {
  getIsForceUpdateInProgress,
  asyncGetLastEventForDevice,
  FORCE_UPDATE_MAX_TRIES,
  asyncInitializeForceUpdate,
  FORCE_UPDATE_INTERVAL_MS,
  getNextForceUpdate,
  useForceUpdateInProgress
};
