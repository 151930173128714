import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { captureException } from '@sentry/react';
import React from 'react';
import { AppButton } from 'src/components';
import { useThunk } from 'src/hooks';
import { showErrorToast, showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';
import { asyncDeleteTriggers } from '../actions';
import formatTrigger from '../formatTrigger';
import { useTriggerById } from '../triggers.reducer';

type Props = {
  triggerId: number;
  onSuccess: () => void;
  onCancel: () => void;
};

const TEST_ID = 'confirm-delete-notification';
/**
 * @param props
 * @param props.triggerId
 * @param props.onCancel
 * @param props.onSubmit
 * @param props.onSuccess
 */
export default function ConfirmDeleteNotification({
  triggerId,
  onCancel,
  onSuccess
}: Props): JSX.Element | null {
  const trigger = useTriggerById(triggerId);

  const { sendRequest: submitDeleteTrigger } = useThunk(asyncDeleteTriggers);

  const handleSubmit = async () => {
    await submitDeleteTrigger({ triggerIds: [triggerId] })
      .then(() => {
        showSuccessToast();
        return onSuccess();
      })
      .catch((err) => {
        captureException(err);
        showErrorToast();
      });
  };

  return trigger ? (
    <Dialog data-cy={TEST_ID} id={TEST_ID} open={!isNullish(triggerId)}>
      <DialogTitle>Delete Notification</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${TEST_ID}-text`}>
          {formatTrigger(trigger)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AppButton id="cancel-delete-notification-btn" onClick={onCancel}>
          Cancel
        </AppButton>
        <AppButton
          iconKey="CHECK_MARK"
          id="submit-delete-notification-btn"
          onClick={handleSubmit}
          variant="contained"
        >
          Delete
        </AppButton>
      </DialogActions>
    </Dialog>
  ) : null;
}
