import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import asyncGetUserData from 'src/asyncGetUserData';
import { appLogger } from 'src/utilities';
import { asyncChangeDeviceName, asyncRestoreConfigDefaults } from './actions';
import { asyncUpdateSensor } from './config-manager';
import { asyncCreateConfiguration } from './create-configuration/createConfig.reducer';
import { asyncAddDeviceToFarmAccount } from './device-farm-association';
import type { DeviceConfig } from './models';
import { deviceAdapter } from './models';

const GLOBAL_DEVICE_ID = 'GLOBAL' as const;

const SELECTED_DEVICE_VIEW = [
  {
    key: 'SENSOR_STATES',
    label: 'Sensors'
  },
  {
    key: 'DEVICE_OPTIONS',
    label: 'Options'
  },
  {
    key: 'REMOTE_CONTROL',
    label: 'Control'
  }
] as const;
type SelectedDeviceView = typeof SELECTED_DEVICE_VIEW[number]['key'];

interface State extends EntityState<DeviceConfig> {
  selectedItemView: SelectedDeviceView;
}

const DEFAULT_VIEW = 'SENSOR_STATES';
const initialState: State = deviceAdapter.getInitialState({
  selectedId: null,
  selectedItemView: DEFAULT_VIEW
});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncGetUserData.pending, () => ({ ...initialState }))
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        const { activeFarm } = payload;
        if (activeFarm?.deviceConfigurations) {
          deviceAdapter.upsertMany(state, activeFarm.deviceConfigurations);
        }
      })
      .addCase(asyncAddDeviceToFarmAccount.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          appLogger.debug(action);
        }
      })
      .addCase(asyncAddDeviceToFarmAccount.fulfilled, (state, { payload }) => {
        if (payload.status === `VALID`) {
          deviceAdapter.upsertOne(state, payload.deviceConfiguration);
        }
      })
      .addCase(asyncRestoreConfigDefaults.fulfilled, (state, action) => {
        const { payload, meta } = action;
        deviceAdapter.removeOne(state, meta.arg.deviceId);
        deviceAdapter.upsertOne(state, payload);
      })

      .addMatcher(
        isAnyOf(
          asyncUpdateSensor.fulfilled,
          asyncCreateConfiguration.fulfilled,
          asyncChangeDeviceName.fulfilled
        ),
        (state, { payload }) => {
          deviceAdapter.removeOne(state, payload.deviceId);
          deviceAdapter.upsertOne(state, payload);
        }
      ),
  initialState,
  name: `deviceConfigurations`,
  reducers: {}
});

const devices: Reducer<State> = slice.reducer;

export { GLOBAL_DEVICE_ID, SELECTED_DEVICE_VIEW };
export type { SelectedDeviceView };
export default devices;
