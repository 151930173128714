import type { ValuesType } from 'utility-types';

const RequestNames = {
  ACCEPT_TERMS_OF_SERVICE: 'ACCEPT_TERMS_OF_SERVICE',
  ACTIVATE_FIELDS: 'ACTIVATE_FIELDS',
  ADD_DEVICE: 'ADD_DEVICE',
  ADD_DEVICE_WITH_ALIAS: 'ADD_DEVICE_WITH_ALIAS',
  ADD_DEVICE_WITH_SHARE_CODE: 'ADD_DEVICE_WITH_SHARE_CODE',
  ARCHIVE_FIELDS: 'ARCHIVE_FIELDS',
  CHANGE_ACTIVE_FARM: 'CHANGE_ACTIVE_FARM',
  CHANGE_DEVICE_NAME: 'CHANGE_DEVICE_NAME',
  CHANGE_FIELD_COLOR_PREFERENCE: 'CHANGE_FIELD_COLOR_PREFERENCE',
  CHANGE_FIELD_STATUS: 'CHANGE_FIELD_STATUS',
  CHECK_AUTH: 'CHECK_AUTH',
  COMPLETE_SMS_VERIFICATION: 'COMPLETE_SMS_VERIFICATION',
  CREATE_CONFIGURATION: 'CREATE_CONFIGURATION',
  CREATE_DEVICE_SHARE_CODE: 'CREATE_DEVICE_SHARE_CODE',
  CREATE_EVENT_ACTION_TRIGGER: 'CREATE_EVENT_ACTION_TRIGGER',
  CREATE_FARM: 'CREATE_FARM',
  CREATE_FARM_JOIN_CODE: 'CREATE_FARM_JOIN_CODE',
  CREATE_FIELD: 'CREATE_FIELD',
  CREATE_NOTIFICATION: 'CREATE_CUSTOM_NOTIFICATION_TRIGGER',
  CREATE_PHONE_NUMBER: 'CREATE_PHONE_NUMBER',
  CREATE_REEL_PUMP_PAIR: 'CREATE_REEL_PUMP_PAIR',
  CUSTOMIZE_NOTIFICATION_STRING: 'CUSTOMIZE_NOTIFICATION_STRING',
  DELETE_EVENT_ACTION_TRIGGERS: 'DELETE_EVENT_ACTION_TRIGGERS',
  DELETE_FARM_FIELD: 'DELETE_FARM_FIELD',
  GET_ACTIVE_FARM_DATA: 'GET_ACTIVE_FARM_DATA',

  GET_ACTIVE_USER_DATA: 'GET_ACTIVE_USER_DATA',
  GET_ALL_DEVICES_BY_FARM: 'GET_ALL_DEVICES_BY_FARM',
  GET_FARMS_BORROWING_DEVICE: 'GET_FARMS_BORROWING_DEVICE',
  GET_LAST_EVENT_FOR_DEVICE: 'GET_LAST_EVENT_FOR_DEVICE',
  GET_REEL_RUN_HISTORY_FOR_FIELD: 'GET_REEL_RUN_HISTORY_FOR_FIELD',
  GET_VERIFICATION_CODE: 'GENERATE_SMS_VERIFICATION_CODE',
  INITIALIZE_FORCE_UPDATE: 'INITIALIZE_FORCE_UPDATE',
  INVALIDATE_DEVICE_SHARE_CODE: 'INVALIDATE_DEVICE_SHARE_CODE',
  INVOKE_REMOTE_CONTROL: 'INVOKE_PARTICLE_COMMAND',
  LOAD_FIELD_RUNS: 'GET_REEL_RUN_HISTORY_FOR_FIELD',
  LOAD_LAST_N_DEVICE_EVENTS: 'GET_LAST_N_DEVICE_EVENTS',
  LOAD_USER_PERMISSIONS: 'GET_FARM_PERMISSIONS_FOR_USER',
  RENAME_FIELD: 'RENAME_FIELD',
  RESTORE_CONFIGURATION_DEFAULTS: 'RESTORE_CONFIGURATION_DEFAULTS',
  SET_APP_THEME_PREFERENCE: 'SET_APP_THEME_PREFERENCE',
  SET_DEBUG_MODE: 'SET_DEBUG_MODE',
  SET_FIELD_ROW_DIRECTION: 'SET_FIELD_ROW_DIRECTION',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  TOGGLE_METRIC: 'TOGGLE_MEASUREMENT_PREFERENCE',
  TOGGLE_NOTIFICATIONS_FROM_DEVICES: 'TOGGLE_NOTIFICATIONS_FROM_DEVICES',
  UPDATE_FARM_GPS_LOCATION: 'UPDATE_FARM_GPS_LOCATION',
  UPDATE_FARM_USER_PERMISSION: 'UPDATE_FARM_USER_PERMISSION',
  UPDATE_FIELD_BOUNDARY: 'UPDATE_FIELD_BOUNDARY',
  UPDATE_REEL_RUN_DIRECTION: 'UPDATE_REEL_RUN_DIRECTION',
  UPDATE_SENSOR_CONFIGURATION: 'UPDATE_SENSOR_CONFIGURATION',
  UPDATE_STATUS_MAP: 'UPDATE_STATUS_MAP',
  USER_JOIN_FARM_WITH_CODE: 'USER_JOIN_FARM_WITH_CODE'
} as const;
export default RequestNames;

export type RequestName = ValuesType<typeof RequestNames>;
