/* eslint-disable @typescript-eslint/no-namespace */
export type RemoteEnvName = 'BETA' | 'DEMO' | 'PROD';

export type AppEnv = RemoteEnvName | 'DEV';
declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_ENV: AppEnv;
    }
  }
}

const BASE_URL =
  'https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com/live' as const;
const LOCALHOST_URL = 'http://localhost:8000' as const;
export type RemoteBackendUrl<T extends RemoteEnvName = RemoteEnvName> =
  `${typeof BASE_URL}/${T}`;
export type LocalhostUrl = typeof LOCALHOST_URL;

export type AppConfig =
  | {
      deployment: 'BETA';
      backendUrl: RemoteBackendUrl<'BETA'>;
      testClient?: undefined;
    }
  | {
      deployment: 'DEMO';
      backendUrl: RemoteBackendUrl<'PROD'>;
      testClient?: undefined;
    }
  | {
      deployment: 'DEV';
      backendUrl: LocalhostUrl;
      testClient: 'cypress' | null;
    }
  | {
      deployment: 'DEV';
      backendUrl: RemoteBackendUrl;
      testClient?: undefined;
    }
  | {
      deployment: 'PROD';
      backendUrl: RemoteBackendUrl<'PROD'>;
      testClient?: undefined;
    };

export const configureApp = (appEnv: AppEnv | undefined): AppConfig => {
  if (typeof appEnv === 'undefined') {
    throw new Error('Invalid app env');
  }
  const isDeployed = process.env.NODE_ENV === 'production';
  const cypressIsRunning = typeof window.Cypress !== 'undefined';

  if (appEnv === 'DEV') {
    if (isDeployed) {
      throw new Error('Code is deployed');
    }
    if (cypressIsRunning) {
      return {
        testClient: 'cypress',
        deployment: appEnv,
        backendUrl: 'http://localhost:8000'
      };
    }
    return {
      testClient: null,
      deployment: appEnv,
      backendUrl: 'http://localhost:8000'
    };
  }
  if (cypressIsRunning) {
    throw new Error('Cypress cannot run pointed at remote database');
  }

  if (appEnv === 'DEMO' || appEnv === 'PROD') {
    return {
      deployment: appEnv,
      backendUrl:
        'https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com/live/PROD'
    };
  }
  if (appEnv === 'BETA') {
    return {
      deployment: appEnv,
      backendUrl: `https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com/live/BETA`
    };
  }
  throw new Error('Invalid environment');
};
export const appConfig = configureApp(process.env.REACT_APP_ENV);
// eslint-disable-next-line no-console
console.log(appConfig);
