import humanizeInstallationType from './humanizeInstallationType';
import InstallationTypeAvatar from './InstallationTypeAvatar';
import INSTALLATION_TYPE from './InstallationTypes';
import mapInstallationTypeToColor from './mapInstallationTypeToColor';

const InstallationTypes = {
  ...INSTALLATION_TYPE,
  humanize: humanizeInstallationType,
  humanizeInstallationType,
  mapInstallationTypeToColor
} as const;

export * from './InstallationTypes';
export { InstallationTypeAvatar, mapInstallationTypeToColor };

export default InstallationTypes;
