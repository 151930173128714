import { kebabCase } from 'lodash-es';
import React from 'react';
import type { EnhancedButtonProps } from 'src/components';
import { AppButton } from 'src/components';

/**
 * @param props
 * @param props.id
 * @param props.text
 * @param props.color
 * @param props.variant
 * @param props.rest
 */
export default function ChoiceBtn({
  id,
  text,
  color = 'secondary',
  variant = 'contained',
  ...rest
}: Omit<EnhancedButtonProps, 'children'>): JSX.Element {
  return (
    <AppButton
      fullWidth
      type="button"
      {...rest}
      color={color}
      id={id ?? kebabCase(text)}
      text={text}
      variant={variant}
    />
  );
}
