import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import React from 'react';
import { IconBtn } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import type { DeviceIdProp, DevicePair } from '../models';
import { useMetadataByDeviceId } from '../selectors';
import { Triggers } from '../triggers.reducer';
type RowProps = DeviceIdProp &
  DevicePair & {
    index: number;
    numPairs: number;
    isSelected: boolean;
    onClickDelete: () => void;
  };

/**
 * @param props

 * @param props.deviceId
 * @param props.sourceDeviceId
 * @param props.index
 * @param props.numPairs
 * @param props.id
 * @param props.isSelected
 * @param props.targetDeviceId
 * @param props.onClickDelete
 */
export function CurrentPairListRow({
  deviceId,
  sourceDeviceId,
  index,
  numPairs,
  id,
  isSelected,
  targetDeviceId,
  onClickDelete
}: RowProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const testId = testIds.devicePairing.currentPairs.item(index);
  const otherId = deviceId === targetDeviceId ? sourceDeviceId : targetDeviceId;
  const metadata = useMetadataByDeviceId(otherId);
  const handleClickExistingPair = () => dispatch(Triggers.selectPair(id));
  if (isNullish(metadata)) {
    return null;
  }
  const { deviceName, deviceInstallationType } = metadata;
  return (
    <ListItem
      data-cy={testId}
      dense
      divider={index < numPairs - 1}
      id={testId}
      key={id}
      onClick={() => handleClickExistingPair()}
      selected={isSelected}
    >
      <ListItemText
        primary={deviceName}
        secondary={deviceInstallationType}
        secondaryTypographyProps={{
          variant: 'caption'
        }}
      />
      <ListItemSecondaryAction>
        <IconBtn
          iconKey="DELETE_ITEM"
          id={testIds.devicePairing.currentPairs.deleteBtn}
          onClick={() => onClickDelete()}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
