import React from 'react';
import Sensors, { SensorNames, SensorStates } from 'src/devices/sensors';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import type { GetStatesOfSensor, SensorName } from 'src/devices/sensors';
type Props<S extends SensorName, V = GetStatesOfSensor<S>> = {
  name: 'stateCurrent' | 'statePrevious';
  sensorName: S;
  onChange: (value: V) => void;
  value: V | undefined;
  isError: boolean;
};

/**
 * @param props
 * @param props.key
 * @param props.onChange
 * @param props.value
 * @param props.sensorName
 * @param props.isError
 * @param props.name
 */
export default function StateSelect<S extends SensorName>({
  name,
  sensorName,
  isError,
  onChange,
  value: currentVal
}: Props<S>): JSX.Element {
  const options = SensorNames.is.valid(sensorName)
    ? SensorStates.getStatesForSensorName(sensorName).map((stateValue) => ({
        stateValue
      }))
    : null;

  const ids = testIds.triggerSelect(name);

  const formatVal = Sensors.useFormatValue();
  const labelText = `${name === 'stateCurrent' ? 'Current' : 'Previous'} State`;

  return (
    <FormControl>
      <InputLabel id={ids.label}>{labelText}</InputLabel>
      <Select
        MenuProps={{ id: ids.menu }}
        data-cy={ids.root}
        disabled={(options?.length ?? 0) === 0}
        error={isError}
        id={ids.root}
        labelId={ids.label}
        onChange={({ target: { value } }) => {
          if (isNullish(sensorName)) {
            throw new Error('No sensor name');
          }
          if (!SensorStates.isStateOfSensor(sensorName, value)) {
            throw new Error(`Invalid state`);
          }
          onChange(value);
        }}
        style={{
          minWidth: 150
        }}
        value={currentVal ?? ''}
        variant="standard"
      >
        {options?.map(({ stateValue }) => {
          const formatted = formatVal('stateCurrent', stateValue);
          return isNullish(formatted) ? null : (
            <MenuItem
              data-cy={stateValue}
              // disabled={disabled}
              id={stateValue}
              key={stateValue}
              value={stateValue}
            >
              {formatted.toLocaleUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
