import { createCachedSelector } from 're-reselect';
import type { ConfigSensorName, DeviceConfig, DeviceEvent } from 'src/devices';
import type { RootState } from 'src/store';
import { isNullish } from 'src/types';
import type { SensorName } from './SensorNames';
import { CONFIG_SENSOR_NAMES, SENSOR_NAMES } from './SensorNames';

/**
 * Return all names of all sensors whose values are not null in a configuration or event
 * @param configOrEvent
 * @param kind
 * @param de
 */
function listEventSensors(de: DeviceEvent): SensorName[] {
  return SENSOR_NAMES.filter((sn) => {
    const data = de[sn];
    if (isNullish(data)) {
      return false;
    }
    return true;
  });
}
const getId = (_: RootState, id: string) => id;

const getSensorNamesInEvent = createCachedSelector(
  (state: RootState, id: string) => state.deviceEventLast.entities[id],
  (event) => (event ? listEventSensors(event) : undefined)
)(getId);

/**
 * @param de
 * @param dc
 */
function listConfigSensors(dc: DeviceConfig): ConfigSensorName[] {
  return CONFIG_SENSOR_NAMES.filter((sn) => Boolean(dc[sn]));
}
const getSensorNamesInConfig = createCachedSelector(
  (state: RootState, id: string) => state.devices.entities[id],
  (event) => (event ? listConfigSensors(event) : undefined)
)(getId);

/**
 * @param target
 */
function findPrimarySensorInDevice(target?: DeviceConfig): SensorName {
  if (target) {
    const installationType = target.deviceInstallationType;
    const configuredSensors = SENSOR_NAMES.filter((s) => Boolean(target[s]));

    if (installationType === `reel`) {
      if (configuredSensors.includes(`reel`)) {
        return `reel`;
      }
    }
    if (installationType === `pump`) {
      if (configuredSensors.includes(`pressure`)) {
        return `pressure`;
      }
      if (configuredSensors.includes(`pressureSwitch`)) {
        return `pressureSwitch`;
      }
    }
    return configuredSensors[0] ?? 'gps';
  }
  return 'gps';
}
const getPrimaryConfigSensor = createCachedSelector(
  (state: RootState, id: string) => state.devices.entities[id],
  (event) => {
    return findPrimarySensorInDevice(event);
  }
)((_, id) => id);

export {
  getSensorNamesInConfig,
  getSensorNamesInEvent,
  getPrimaryConfigSensor,
  findPrimarySensorInDevice,
  listConfigSensors,
  listEventSensors
};
