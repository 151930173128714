import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { isArrayOrReadonlyArray } from 'src/types';
import { humanizeAzimuth } from 'src/utilities';

const DEFAULT_INITIAL = 0; // true north
/**
 * Hook to track current azimuth value, which it uses to create the formatted
 * bearing text and generate polygon paths
 * @param initialValue.initialValue
 * @param initialValue
 * @param args
 */
export default function useSetDirection(
  args?: { initialValue: number | null | undefined } | undefined
): {
  azimuth: number;
  bearingText: string;
  handleClickFlip: () => void;
  handleDragSlider: (newValue: number | readonly number[]) => void;
  flipValue: () => number;
} {
  const initialValue = args?.initialValue ?? DEFAULT_INITIAL;
  const [azimuth, setAzimuth] = useState(initialValue);

  const flipValue = React.useCallback(() => {
    const flippedValue = azimuth + 180;
    return flippedValue % 360;
  }, [azimuth]);

  React.useEffect(() => {
    if (isNumber(initialValue)) {
      setAzimuth(initialValue);
    }
  }, [initialValue]);

  React.useEffect(() => {
    if (isNumber(initialValue)) {
      setAzimuth(initialValue);
    }
  }, [initialValue]);

  return {
    azimuth,
    bearingText: humanizeAzimuth(azimuth),
    flipValue,
    handleClickFlip: () => setAzimuth(flipValue()),
    handleDragSlider: React.useCallback(
      (newValue: number | readonly number[]) => {
        if (isNumber(newValue)) {
          setAzimuth(newValue);
        }
        if (isArrayOrReadonlyArray(newValue)) {
          const [azi] = newValue;
          if (isNumber(azi)) {
            setAzimuth(azi);
          }
        }
      },
      []
    )
  };
}
