import { isNumber } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { isNullish, notNullish } from 'src/types';
import { DateFormatting } from 'src/utilities';

import type { RootState } from 'src/store';
import type { GenericSensorEvent } from './sensors';

/**
 * @param args
 * @param args.runDistanceMmCurrent
 * @param args.runDistanceMmMax
 * @param args.runSpeedMmpm
 */
export default function calculateReelRunEta({
  runDistanceMmCurrent,
  runDistanceMmMax,
  runSpeedMmpm
}: Pick<
  GenericSensorEvent,
  'runDistanceMmCurrent' | 'runDistanceMmMax' | 'runSpeedMmpm'
>): number | null {
  if (
    isNullish(runDistanceMmCurrent) ||
    isNullish(runDistanceMmMax) ||
    isNullish(runSpeedMmpm) ||
    runSpeedMmpm < 50
  ) {
    return null;
  }

  const nowMs = new Date();

  const minutesRemaining = runDistanceMmCurrent / runSpeedMmpm;

  const seconds = minutesRemaining * 60;
  const remainingMilliseconds = seconds * 1000;

  return nowMs.getTime() + remainingMilliseconds;
}
/**
 * Get the run eta for an event if it exists and has a reel sensor
 * @param {string} deviceId
 */
export const getRunEtaByDeviceId = createCachedSelector(
  (state: RootState, id: number) =>
    Object.values(state.reelRunsActive.entities).find(
      (rr) => rr?.reelRunId === id
    ),
  (data) => {
    console.log(data);
    if (data && notNullish(data)) {
      const runDistanceMmMax = data.observations[0]?.distanceObservedMm;
      const runDistanceMmCurrent =
        data.observations.slice(-1)[0]?.distanceObservedMm;
      if (isNumber(runDistanceMmCurrent) && isNumber(runDistanceMmMax)) {
        console.log(runDistanceMmCurrent, runDistanceMmMax);
        const eta = calculateReelRunEta({
          runDistanceMmCurrent,
          runDistanceMmMax,
          runSpeedMmpm: 765
        });
        console.log(eta);
        if (isNumber(eta)) {
          return DateFormatting.toHoursAndMinutes(eta);
        }
      }
    }
    return null;
  }
)((_, id) => id);
