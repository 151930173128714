import type { BoxProps, TypographyVariant } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import testIds from 'src/testIds';

type Props = React.PropsWithChildren<{
  title: string;
  id?: string;
  variant?: TypographyVariant;
  mt?: BoxProps['margin'];
}>;

/**
 * Displays info message indicating that
 * the given list is empty.
 * Children are typically an action to add an item to the list
 *
 * @param props
 * @param props.title
 * @param props.children
 * @param props.dataCy
 * @param props.id
 * @param props.variant
 * @param props.mt
 */
export default function NoListItems({
  title,
  children,
  variant = 'body2',
  id,
  mt
}: Props): JSX.Element {
  const testId = id ?? testIds.noListItems;
  return (
    <Box
      alignItems="center"
      data-cy={testId}
      display="flex"
      flexDirection="column"
      id={testId}
      justifyContent="space-between"
      mt={mt}
    >
      <Alert severity="info">
        <Typography variant={variant}>{title}</Typography>
      </Alert>

      {children}
    </Box>
  );
}
