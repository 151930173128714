import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import type { FieldError, SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useIsAnyRequestPending } from 'src/appSelectors';
import {
  AppTextField,
  AppButton,
  FormFeedback,
  FormWrapperAvatar,
  GridWrapper,
  SubmitBtn
} from 'src/components';
import { FORM_VALIDATION } from 'src/constants';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import { humanize } from 'src/utilities/string-formatting';
import { AUTH_PATHS } from './auth-paths';
import AuthLink from './AuthLink';
import type { AuthFieldName } from './reducer';

export type AuthFormProps<
  K extends AuthFieldName = AuthFieldName,
  T extends {
    [key in K]: string;
  } = {
    [key in K]: string;
  }
> = {
  actions?: React.ReactNode;
  alert?: string;
  alertAction?: React.ReactNode;
  linkLeft?: React.ReactNode;
  linkRight?: React.ReactNode;
  errorMessage?: string;
  id: string;
  isSuccess?: boolean;
  keys: readonly K[];

  onClearFormError: () => void;
  onSubmit: SubmitHandler<T>;
  resendCodeBtn?: React.ReactNode;
  submitBtn?: React.ReactNode;
  title: string;
};

/**
 * @param props
 * @param props.keys
 * @param props.title
 * @param props.alert
 * @param props.alertAction
 * @param props.linkLeft
 * @param props.linkRight
 * @param props.errorMessage
 * @param props.isSuccess
 * @param props.submitBtn
 * @param props.resendCodeBtn
 * @param props.onSubmit
 * @param props.id
 * @param props.onClearFormError
 * @param props.actions
 * @param props.rest
 */
function AuthForm<K extends AuthFieldName, T extends { [key in K]: string }>({
  keys,
  title,
  alert,
  alertAction,
  linkLeft,
  linkRight,
  errorMessage,
  isSuccess,
  submitBtn,
  resendCodeBtn,
  onSubmit,
  id,
  onClearFormError,
  actions
}: // ...rest
AuthFormProps<K, T>): JSX.Element {
  const { handleSubmit, watch, register, errors } = useForm<T>();

  const successView = isSuccess === true;
  const requestPending = useIsAnyRequestPending();
  const alertId = successView ? 'success' : 'instructions';
  return (
    <div data-cy={id} id={id}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="xs">
          <Card>
            <CardHeader
              avatar={<FormWrapperAvatar />}
              title={title}
              titleTypographyProps={{
                component: `h1`,
                variant: 'h3'
              }}
            />
            <CardContent>
              <GridWrapper
                alignItems="center"
                gridItemProps={{
                  xs: 12
                }}
                spacing={2}
              >
                {!isNullish(alert) ? (
                  <Alert
                    action={alertAction}
                    data-cy={alertId}
                    id={alertId}
                    severity={successView ? 'success' : 'info'}
                  >
                    {alert}
                  </Alert>
                ) : null}
                {successView
                  ? null
                  : keys.map((key) => {
                      const fieldError = (errors[key] as FieldError | undefined)
                        ?.message;
                      return (
                        <AppTextField
                          autoComplete={
                            key === 'currentPassword'
                              ? 'current-password'
                              : key === 'newPassword' ||
                                key === 'confirmPassword'
                              ? 'new-password'
                              : key === 'email'
                              ? 'email'
                              : undefined
                          }
                          errorMessage={fieldError}
                          id={key}
                          inputRef={register({
                            required: 'This field is required.',
                            validate:
                              key === 'newPassword'
                                ? (value: string) =>
                                    FORM_VALIDATION.validateNewPassword(value)
                                : key === 'confirmPassword'
                                ? (value: string) =>
                                    watch('newPassword') === value ||
                                    'Passwords do not match.'
                                : key === 'codeString'
                                ? (value: string) =>
                                    value.length === 6 ||
                                    'Code must be 6 characters long'
                                : undefined
                          })}
                          key={key}
                          label={
                            key === 'newPassword' || key === 'currentPassword'
                              ? 'Password'
                              : key === 'codeString'
                              ? 'Enter Code'
                              : humanize(key)
                          }
                          name={key}
                          onClick={onClearFormError}
                          type={
                            key === 'codeString'
                              ? 'text'
                              : key === 'email'
                              ? key
                              : 'password'
                          }
                        />
                      );
                    })}
                <FormFeedback>{errorMessage}</FormFeedback>
                {successView ? (
                  <AppButton
                    id={testIds.doneBtn}
                    linkTo={AUTH_PATHS.signIn}
                    text="Done"
                  />
                ) : (
                  submitBtn ?? (
                    <SubmitBtn
                      fullWidth
                      showLoading={requestPending}
                      size="large"
                      type="submit"
                      variant="contained"
                    />
                  )
                )}
              </GridWrapper>
              {!isNullish(actions) ? actions : null}
              {resendCodeBtn}
            </CardContent>
          </Card>
          <Box my={2}>
            <Card>
              <CardContent>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="space-between"
                >
                  <Grid container item xs={4}>
                    {linkLeft ?? (
                      <AuthLink
                        iconKey="GO_BACK"
                        testId={testIds.auth.signIn.linkTo}
                        text="Sign In"
                        to={AUTH_PATHS.signIn}
                      />
                    )}
                  </Grid>
                  {!isNullish(linkRight) ? (
                    <Grid container item justifyContent="flex-end" xs={8}>
                      {linkRight}
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </form>
    </div>
  );
}

export default AuthForm;
