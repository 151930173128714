import React from 'react';
import { toast } from 'react-toastify';
import testIds from 'src/testIds';
import { SubmitBtn } from '../components';
import useThunk from '../hooks/useThunk';
import { asyncToggleMetric, User } from '../userSession.reducer';

/**
 * Allows user to change measurements from us to metric app-wide
 */
export default function MetricToggleBtn(): JSX.Element {
  const { sendRequest, isPending } = useThunk(asyncToggleMetric);
  const id = testIds.accountSettings.metricToggleBtn;
  const isMetric = User.useIsMetric();
  const handleToggleMetric = async () =>
    sendRequest({ usesMetricSystem: isMetric }).then(() =>
      toast.info(`Preferences updated`)
    );
  return (
    <SubmitBtn
      fullWidth={false}
      iconKey="SWITCH_HORIZONTAL"
      id={id}
      onClick={handleToggleMetric}
      showLoading={isPending}
      size="small"
    >
      {`USE ${isMetric ? 'US' : 'METRIC'}` + ' UNITS'}
    </SubmitBtn>
  );
}
