import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import type { HasSensorPort } from 'src/devices/sensors';
import Sensors, { SensorConfigs } from 'src/devices/sensors';
import ChoiceBtn from '../create-configuration/ChoiceBtn';
import { useSubmitEditSensorConfig } from './configManager.reducer';
import FormWrapper from './FormWrapper';
import type { FieldProps } from './NumericTextField';

/**
 * Button representing an option; submits when the user clicks
 * (think yes/no, A or B) etc.
 * @param props
 * @param props.fieldName
 */
export default function ChoiceField<S extends HasSensorPort>({
  fieldName
}: FieldProps<S, 'sensorPort' | 'sprinklerType' | 'switchType'>): JSX.Element {
  const choices =
    fieldName === 'sensorPort'
      ? [...SensorConfigs.SENSOR_PORTS]
      : fieldName === 'switchType'
      ? [...SensorConfigs.SWITCH_TYPES]
      : [...SensorConfigs.SPRINKLER_TYPES];
  const formatValue = Sensors.useFormatValue();
  const formatKey = Sensors.useFormatKey();
  const { onSubmit } = useSubmitEditSensorConfig();
  return (
    <FormWrapper submitBtn={false}>
      <Typography variant="subtitle1">{formatKey(fieldName)}</Typography>
      <Grid container item spacing={2} xs={12}>
        {choices.map((option: number | string) => (
          <Grid item key={option} xs>
            <ChoiceBtn
              fullWidth
              key={option}
              onClick={() => onSubmit(option)}
              text={formatValue(fieldName, option) ?? undefined}
            />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  );
}
