import { Container, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { APP_STYLE } from 'src/AppThemeProvider';
import { DashboardAppBar } from './AppBar';
import { DASHBOARD_ROUTES } from './dashboardRoutes';

const useStyles = makeStyles(() => {
  const { toolbarHeightPx } = APP_STYLE;

  const { sidebarWidth } = APP_STYLE;
  return createStyles({
    toolbar: (props: { sidebarIsDocked: boolean }) => ({
      marginLeft: props.sidebarIsDocked ? sidebarWidth : undefined,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }),
    root: {
      position: 'absolute',
      top: toolbarHeightPx,
      bottom: 0,
      right: 0,
      left: 0
    }
  });
});

/**
 * @param props
 * @param props.children
 * @param props.id
 */
function PageLayout({
  children,
  id
}: React.PropsWithChildren<{ id: string }>): JSX.Element {
  const classes = useStyles({ sidebarIsDocked: false });
  return (
    <React.Fragment>
      <DashboardAppBar links={DASHBOARD_ROUTES} />
      <div className={classes.root} data-cy={id} id={id}>
        <Container>
          <div>{children}</div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PageLayout;
