import { kebabCase } from 'lodash-es';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import InstallationTypes from '../installation-type';
import ChoiceBtn from './ChoiceBtn';
import ChoicesWrapper from './ChoicesWrapper';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

const CHOICES = ['yes', 'no'] as const;

type Props = React.PropsWithChildren<unknown>;

/**
 * If the device is a reel or a traveler ('mobile irrigator'),
 * it can have a pressure sensor installed in addition to its
 * own custom 'wheel/reel' sensor. If this is the case,
 * the user will need to confirm or adjust pressure thresholds
 * to make sure the device is calibrated correctly. Otherwise,
 * we skip the pressure threshold step.
 * @param props
 * @param props.children
 */
export default function DoesItHaveAPressureSensor({
  children
}: Props): JSX.Element {
  const deviceInstallationType = useAppSelector(
    CreateConfig.getInstallationType
  );
  if (isNullish(deviceInstallationType)) {
    throw new Error('Null installation type');
  }
  const dispatch = useAppDispatch();
  const onClick = (val: typeof CHOICES[number]) => () =>
    dispatch(CreateConfig.submitHasSecondaryPressure(val === 'yes'));
  return (
    <FormWrapper
      instructions={`Does the ${InstallationTypes.humanize(
        deviceInstallationType
      ).toLocaleLowerCase()} have a pressure sensor installed?`}
      testId={testIds.createConfiguration.hasPressureSensor}
    >
      <ChoicesWrapper>
        {CHOICES.map((opt) => (
          <ChoiceBtn
            id={kebabCase(opt)}
            key={opt}
            onClick={onClick(opt)}
            text={opt}
          />
        ))}
      </ChoicesWrapper>
      {children}
    </FormWrapper>
  );
}
