import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';

import { asyncInvokeRemoteControl } from './asyncInvokeRemoteControl';
import { RemoteControls } from './remoteControls.reducer';
import { getRemoteControlSpec } from './RemoteControlSpec';

import type {
  RemoteControlFunctionName,
  RemoteControlKey
} from './RemoteControlKeys';
type RemoteControlButtonProps<K extends RemoteControlKey> = {
  commandKey: K;
  deviceId: string;
};

/**
 * @param args
 * @param args.commandKey
 * @param args.deviceId
 */
export default function useRemoteControl<K extends RemoteControlKey>({
  commandKey,
  deviceId
}: RemoteControlButtonProps<K>):
  | {
      isConfirming: boolean;
      sendRequest: () => Promise<any>;
      showLoading: boolean;
      functionName: RemoteControlFunctionName;
      label: string;
      confirmationRequired?: boolean | undefined;
      description?: string[] | string | undefined;
      warningText?: string[] | string | undefined;
      adminOnly?: boolean | undefined;
      options?:
        | { extraParameters?: { seconds?: number | undefined } | undefined }
        | undefined;
    }
  | undefined {
  const dispatch = useAppDispatch();

  const spec = useAppSelector((state) =>
    getRemoteControlSpec(state, deviceId, commandKey)
  );
  const fetchStatus = RemoteControls.useStatus(
    deviceId,
    commandKey
  )?.fetchStatus;
  const sendRequest = React.useCallback(
    () => dispatch(asyncInvokeRemoteControl({ commandKey, deviceId })),
    [commandKey, deviceId, dispatch]
  );
  const confirmingKey = useAppSelector(
    (state) => state.remoteControls.confirmingCommand
  );
  const isConfirming = confirmingKey === commandKey;
  return spec
    ? {
        ...spec,
        isConfirming,
        sendRequest,
        showLoading: fetchStatus === 'pending'
      }
    : undefined;
}
export type { RemoteControlButtonProps };
