import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getEventsNearField } from 'src/appSelectors';
import { AppMenuItem } from 'src/components/menu';
import FieldIconMemoized from 'src/fields/FieldIcon';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isNullish, isPositiveInt, notNullish } from 'src/types';

import {
  Box,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Menu,
  Slide,
  Typography
} from '@material-ui/core';
import { useGoogleMap } from '@react-google-maps/api';

import { useActiveFeatureState, useClearActiveFeature } from '../actions';
import {
  AppButton,
  CancelBtn,
  GridWrapper,
  MapCard,
  SubmitBtn
} from '../components';
import { RowDirection, useSetDirection } from '../components/map';
import {
  FarmFields,
  FieldPolygon,
  getLatLngBoundsForField,
  getSelectedFieldId
} from '../fields';
import {
  asyncReactivateFields,
  asyncSetFieldRowDirection,
  asyncUpdateFieldBoundary
} from '../fields/fieldRequests.actions';
import { Geo } from '../geo';
import { useThunk } from '../hooks';
import { showSuccessToast } from '../theme';
import { humanize, humanizeAzimuth, pluralize } from '../utilities';
import ArchiveField from './ArchiveField';
import DeleteField from './DeleteField';
import FieldRunHistory from './FieldRunHistory';
import RenameField from './RenameField';

import type { ValuesType } from 'utility-types';
import type { RequiredPermissions } from '../permissions';
const Ids = testIds.fieldActions;
const FieldActionNames = {
  ARCHIVE_FIELD: 'ARCHIVE_FIELD',
  DELETE_FIELD: 'DELETE_FIELD',
  EDIT_FIELD_SHAPE: 'EDIT_FIELD_SHAPE',
  RENAME_FIELD: 'RENAME_FIELD',
  SET_ROW_DIRECTION: 'SET_ROW_DIRECTION',
  VIEW_RUN_HISTORY: 'VIEW_RUN_HISTORY'
} as const;
type FieldActionName = ValuesType<typeof FieldActionNames>;
const OPTIONS: {
  [F in FieldActionName]: {
    label: string;
    requiredPermissions?: RequiredPermissions;
    testId?: string;
  };
} = {
  ARCHIVE_FIELD: {
    label: 'Archive',
    requiredPermissions: 'canManageFields',
    testId: Ids.optionsMenu.archiveField
  },
  DELETE_FIELD: {
    label: 'Delete',
    requiredPermissions: 'canManageFields',
    testId: Ids.optionsMenu.deleteField
  },
  EDIT_FIELD_SHAPE: {
    label: 'Edit Shape',
    requiredPermissions: 'canManageFields',
    testId: Ids.optionsMenu.editFieldShape
  },
  RENAME_FIELD: {
    label: 'Rename',
    requiredPermissions: 'canManageFields',
    testId: Ids.optionsMenu.renameField
  },
  SET_ROW_DIRECTION: {
    label: 'Direction',
    requiredPermissions: 'canManageFields',
    testId: Ids.optionsMenu.setRowDirection
  },
  VIEW_RUN_HISTORY: {
    label: 'View History',
    testId: Ids.optionsMenu.viewRunHistory
  }
};

/**
 * Allows user to adjust the row direction of an existing field
 *
 * @param props
 * @param props.fieldId
 * @param props.cardClassName
 */
function SetDirection({ fieldId }: { fieldId: number }): JSX.Element | null {
  const id = Ids.setRowDirection.root;
  const fieldData = FarmFields.useField(fieldId);

  const initialValue = fieldData?.rowDirectionAzimuthDegrees;
  const rowDirection = useSetDirection({ initialValue });
  const handleCancel = useClearActiveFeature();
  const { sendRequest, isPending } = useThunk(asyncSetFieldRowDirection);

  const handleChangeCommitted = async (value: number): Promise<void> => {
    await sendRequest({ fieldId, value })
      .then(() => {
        toast.info(
          `Set row direction to ${humanizeAzimuth(rowDirection.azimuth)}`,
          { position: 'bottom-center' }
        );
      })
      .catch(handleApiRequestError);
  };

  const handleFlipDirection = (): void => {
    handleChangeCommitted(rowDirection.flipValue());
  };
  return notNullish(fieldData) ? (
    <React.Fragment>
      <Slide direction="right" in>
        <MapCard data-cy={id} id={id} position="bottom">
          <CardActions>
            <GridWrapper justifyContent="space-between" spacing={2}>
              <Typography
                data-cy={Ids.setRowDirection.bearingText}
                id={Ids.setRowDirection.bearingText}
              >
                {rowDirection.bearingText}
              </Typography>
              <GridWrapper>
                <AppButton
                  iconKey="SWITCH_VERTICAL"
                  id={Ids.setRowDirection.flipBtn}
                  onClick={handleFlipDirection}
                  text="Flip"
                />
                <CancelBtn
                  id={Ids.setRowDirection.doneBtn}
                  onClick={handleCancel}
                  text="Done"
                />
              </GridWrapper>
            </GridWrapper>
          </CardActions>
          <Box paddingX={4}>
            <RowDirection.Slider
              disabled={isPending}
              onChange={rowDirection.handleDragSlider}
              onChangeCommitted={handleChangeCommitted}
              value={rowDirection.azimuth}
            />
          </Box>
        </MapCard>
      </Slide>
      {/* In Map */}
      <RowDirection.Arrow
        azimuth={rowDirection.azimuth}
        boundingRadiusMeters={fieldData.boundingRadiusMeters}
        center={fieldData.center}
        enclosingPolygon={fieldData.polygon}
      />
    </React.Fragment>
  ) : null;
}

const FieldActions = React.memo(
  /**
   * @param polygon
   */
  () => {
    const fieldId = useSelector(getSelectedFieldId) ?? 0;
    const [menuAnchor, setAnchor] = React.useState<HTMLElement | null>(null);
    const handleOpenMenu: React.MouseEventHandler<HTMLElement> = (e) => {
      setAnchor(e.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchor(null);
    };

    const { activeFeature, setActiveFeature } = useActiveFeatureState();

    const map = useGoogleMap();

    const fieldData = FarmFields.useField(fieldId);
    const pathGmaps = fieldData?.pathGmaps ?? [];
    const eventsNearby = useSelector((s) => getEventsNearField(s, fieldId));
    const handleCancel = useClearActiveFeature();
    const polyRef = React.useRef<google.maps.Polygon | null>(null);

    const reactivate = useThunk(asyncReactivateFields);
    const updateBoundary = useThunk(asyncUpdateFieldBoundary);

    const handleSubmitBoundary = () => {
      const polygonPath = polyRef.current
        ?.getPath()
        .getArray()
        .map((p) => p.toJSON());

      if (!Geo.polygons.is.validPolygonInput(polygonPath)) {
        throw new Error(`Invalid polygon input ${JSON.stringify(polygonPath)}`);
      }

      updateBoundary
        .sendRequest({
          fieldId,
          polygon: Geo.polygons.to.geojson(polygonPath)
        })
        .then(() => showSuccessToast())
        .catch(handleApiRequestError);
    };

    const latLngBounds = useSelector((s) =>
      getLatLngBoundsForField(s, fieldId)
    );

    React.useEffect(() => {
      /**
       * Fits the map to a bounding box around the field
       */
      function zoomToFieldBounds() {
        if (latLngBounds) {
          map?.fitBounds(latLngBounds);
        }
      }
      if (
        activeFeature === 'EDIT_FIELD_SHAPE' ||
        activeFeature === 'SET_ROW_DIRECTION' ||
        activeFeature === 'VIEW_RUN_HISTORY'
      ) {
        zoomToFieldBounds();
      }
    }, [activeFeature, latLngBounds, map]);
    const nearbyEventsText = `${eventsNearby.length} ${pluralize({
      count: eventsNearby.length,
      word: 'device'
    })} active nearby`;

    const handleExit = (): void => {
      handleCancel();
      if (polyRef.current) {
        polyRef.current.setMap(null);
      }
    };
    return isNullish(fieldData) ? null : (
      <div data-cy={Ids.root} id={Ids.root}>
        <Grow in={isPositiveInt(fieldId)} onExit={handleExit}>
          <div>
            <MapCard>
              <Menu
                anchorEl={menuAnchor}
                data-cy={Ids.optionsMenu.root}
                id={Ids.optionsMenu.root}
                onClose={handleCloseMenu}
                open={Boolean(menuAnchor)}
              >
                {Object.values(FieldActionNames).map((key) => {
                  const { label, requiredPermissions, testId } = OPTIONS[key];
                  let handleClick = () => {
                    setActiveFeature({
                      name: key,
                      id: fieldId,
                      kind: 'field'
                    });
                    handleCloseMenu();
                  };
                  let buttonText = label;
                  let showLoading = false;

                  const isActive = fieldData?.isActive;
                  if (key === 'ARCHIVE_FIELD' && !isActive) {
                    buttonText = 'Activate';
                    handleClick = () =>
                      reactivate
                        .sendRequest({ fieldIdList: [fieldId] })
                        .then(() => {
                          showSuccessToast('Field reactivated');
                        })
                        .catch(handleApiRequestError);

                    showLoading = reactivate.isPending;
                  }

                  return (
                    <AppMenuItem
                      button
                      iconKey={key}
                      id={testId ?? key}
                      key={key}
                      onClick={handleClick}
                      requiredPermissions={requiredPermissions}
                      showLoading={showLoading}
                      textPrimary={buttonText}
                    />
                  );
                })}
              </Menu>
              <CardHeader
                action={
                  <AppButton
                    iconKey="EXPAND_MORE"
                    id={Ids.openOptionsBtn}
                    onClick={handleOpenMenu}
                    text="options"
                  />
                }
                avatar={<FieldIconMemoized polygon={fieldData?.polygon} />}
                subheader={
                  activeFeature === 'EDIT_FIELD_SHAPE' ? (
                    `Drag the field boundary to change its shape`
                  ) : activeFeature === 'SET_ROW_DIRECTION' ? (
                    `Adjust the field's row direction`
                  ) : activeFeature === 'RENAME_FIELD' ? (
                    <React.Fragment>
                      Enter field name
                      <RenameField
                        fieldId={fieldId}
                        initialValue={fieldData?.fieldName ?? ''}
                        onCancel={handleCancel}
                      />
                    </React.Fragment>
                  ) : (
                    nearbyEventsText
                  )
                }
                title={fieldData?.fieldName}
              />
              {activeFeature === 'EDIT_FIELD_SHAPE' ? (
                <CardActions data-cy={Ids.setRowDirection}>
                  <CancelBtn onClick={handleCancel} />
                  <SubmitBtn onClick={handleSubmitBoundary} text="Done" />
                </CardActions>
              ) : null}
            </MapCard>
          </div>
        </Grow>
        {activeFeature === 'SET_ROW_DIRECTION' ? (
          <SetDirection fieldId={fieldId} />
        ) : null}
        <Dialog
          data-cy={testIds.dialog.root}
          id={testIds.dialog.root}
          open={
            activeFeature === 'ARCHIVE_FIELD' ||
            activeFeature === 'DELETE_FIELD'
          }
        >
          <DialogTitle>{`Confirm ${humanize(activeFeature)}`}</DialogTitle>
          <DialogContent>
            {activeFeature === 'ARCHIVE_FIELD' ? (
              <ArchiveField fieldId={fieldId} onCancel={handleCancel} />
            ) : activeFeature === 'DELETE_FIELD' ? (
              <DeleteField fieldId={fieldId} onCancel={handleCancel} />
            ) : null}
          </DialogContent>
        </Dialog>
        {activeFeature === 'EDIT_FIELD_SHAPE' && notNullish(fieldData) ? (
          <FieldPolygon
            editable={!updateBoundary.isPending}
            isArchived={false}
            isHighlighted
            isMuted={false}
            isSelected={true}
            onLoad={(polygon) => {
              polyRef.current = polygon;
            }}
            pathGmaps={pathGmaps}
          />
        ) : null}
        {activeFeature === 'VIEW_RUN_HISTORY' && notNullish(fieldId) ? (
          <FieldRunHistory fieldId={fieldId} />
        ) : null}
      </div>
    );
  }
);

FieldActions.displayName = 'FieldPolygons';
export type { FieldActionName };
export default FieldActions;
