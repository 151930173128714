import { Chip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

type Props = React.PropsWithChildren<{
  codeStr: string;
  testId: string;
}>;

/**
 * @param props
 * @param props.codeStr
 * @param props.children
 * @param props.testId
 */
export default function ExistingShareCode({
  codeStr,
  children,
  testId
}: Props): JSX.Element {
  return (
    <div>
      <Alert
        action={
          <Chip label={<Typography variant="h5">{codeStr}</Typography>} />
        }
        data-cy={testId}
        id={testId}
      >
        You can now share this device with another farm by providing them with
        this code:
      </Alert>
      {children}
    </div>
  );
}
