import React from 'react';
import { useSelector } from 'react-redux';
import {
  MarkerBase as BaseMarker,
  PolygonBase as BasePolygon,
  PolylineBase as BasePolyline
} from 'src/components/map';
import { mapInstallationTypeToColor } from 'src/devices';
import { MapMarkers } from 'src/drawing';
import ZOOM_THRESHOLDS from 'src/status-map/ZoomThresholds';
import { isNullish, notNullish } from 'src/types';
import { humanize } from 'src/utilities';

import type { MarkerBaseProps } from 'src/components/map/MarkerBase';
import type { PolygonBaseProps } from 'src/components/map/PolygonBase';
import type { ReelRunGeometry } from 'src/reel-runs/getReelRunGeometry';
const SWATH_DEFAULTS = {
  fillColor: `transparent`,
  strokeColor: `cyan`,
  strokeOpacity: 1,
  strokeWeight: 3
} as const;

type ReelRunPolygonProps = ReelRunGeometry & {
  azimuthOverride?: number | null;
  onClick: () => void;
  clickable?: boolean;
  currentZoom: number;
  isHidden?: boolean;
  isMuted: boolean;
  isSelected?: boolean;
  reelRunId: number;
  hideHeatmap: boolean;
  outlineOpacity: number;
  heatmapOpacity: number;
  hideHosePath: boolean;
  hideGunMarker: boolean;
  hideOutline: boolean;
};

type GunMarkerProps = Pick<
  MarkerBaseProps,
  'clickable' | 'isHidden' | 'onClick' | 'position'
> &
  Pick<ReelRunPolygonProps, 'currentZoom' | 'sprinklerType'>;

/**
 * @param props
 * @param props.gunPositionMaxGmaps
 * @param props.isHidden
 * @param props.onClick
 * @param props.sprinklerType
 * @param props.currentZoom
 * @param props.rest
 */
function GunMarker({
  sprinklerType,
  currentZoom,
  ...rest
}: GunMarkerProps): JSX.Element | null {
  const mapTypeId = useSelector((state) => state.statusMap.mapTypeId);
  const labelMemo = React.useMemo(() => {
    return {
      color: mapTypeId === 'terrain' ? '#010101' : '#ffffff',
      fontSize: `12px`,
      fontWeight: `bold`,
      text: humanize(sprinklerType)
    };
  }, [mapTypeId, sprinklerType]);
  const iconMemo = React.useMemo(
    () => ({
      fillColor: mapInstallationTypeToColor({
        deviceInstallationType: `reel`
      }),
      fillOpacity: 0.8,
      path: MapMarkers.createTearDropShape(undefined, 0.8)
    }),
    []
  );
  return currentZoom > ZOOM_THRESHOLDS.breakpoints.gunMarker ? (
    <BaseMarker
      icon={iconMemo}
      label={labelMemo}
      title={sprinklerType ?? 'Sprinkler'}
      {...rest}
    />
  ) : null;
}

/**
 * @param props
 * @param props.clickable
 * @param props.hosePath
 * @param props.isHidden
 * @param props.onClick
 */
function HosePath(
  props: Pick<PolygonBaseProps, 'clickable' | 'isHidden' | 'onClick' | 'path'>
) {
  return <BasePolyline {...props} strokeOpacity={0.6} />;
}
/**
 * @param props
 * @param props.rest
 */
function HeatmapPane({
  ...rest
}: Pick<
  PolygonBaseProps,
  'clickable' | 'fillOpacity' | 'isHidden' | 'onClick' | 'path'
>): JSX.Element {
  return <BasePolygon {...rest} fillColor="blue" strokeColor="transparent" />;
}

/**
 * @param props
 * @param props.isSelected
 * @param props.rest
 */
function SwathOutline({
  isSelected,
  ...rest
}: Pick<
  PolygonBaseProps,
  'clickable' | 'isHidden' | 'onClick' | 'path' | 'strokeOpacity'
> &
  Pick<ReelRunPolygonProps, 'isSelected'>) {
  return (
    <BasePolygon
      {...rest}
      fillColor={SWATH_DEFAULTS.fillColor}
      strokeColor={SWATH_DEFAULTS.strokeColor}
      strokeWeight={isSelected === true ? 3 : 1}
    />
  );
}

/**
 * Display reel run progress on a map
 *
 * @param props
 * @param props.clickable
 * @param props.heatmapPanes
 * @param props.currentZoom
 * @param props.hosePath
 * @param props.isHidden
 * @param props.isMuted
 * @param props.isSelected
 * @param props.gunPositionCurrentGmaps
 * @param props.onClick
 * @param props.gunPositionMaxGmaps
 * @param props.reelRunId
 * @param props.sprinklerType
 * @param props.swathOutlinePath
 * @param props.outlineOpacity
 * @param props.heatmapOpacity
 * @param props.hideGunMarker
 * @param props.hideHeatmap
 * @param props.hideHosePath
 * @param props.rest
 * @param props.hideOutline
 */
export function ReelRunPolygon({
  clickable,
  currentZoom,
  gunPositionCurrentGmaps,
  heatmapOpacity,
  heatmapPanes,
  hideGunMarker,
  hideHeatmap,
  hideHosePath,
  hideOutline,
  hosePath,
  isHidden,
  isMuted,
  isSelected,
  onClick,
  outlineOpacity,
  sprinklerType,
  swathOutlinePath
}: // ...rest
ReelRunPolygonProps): JSX.Element | null {
  const handleClick = React.useCallback(() => {
    if (clickable !== false) {
      return onClick();
    }
    return undefined;
  }, [clickable, onClick]);
  const commonProps = {
    clickable,
    handleClick,
    isMuted,
    isSelected
  };
  if (isNullish(swathOutlinePath)) {
    return null;
  }
  return isHidden === true ? null : (
    <React.Fragment>
      {heatmapPanes?.map((report) => {
        return (
          <HeatmapPane
            clickable={clickable}
            fillOpacity={heatmapOpacity}
            isHidden={hideHeatmap}
            key={report.key}
            onClick={handleClick}
            path={report.pathGmaps}
          />
        );
      })}
      <SwathOutline
        clickable={clickable}
        isHidden={hideOutline}
        onClick={handleClick}
        path={swathOutlinePath}
        strokeOpacity={outlineOpacity}
      />
      {notNullish(hosePath) ? (
        <HosePath {...commonProps} isHidden={hideHosePath} path={hosePath} />
      ) : null}
      {notNullish(gunPositionCurrentGmaps) ? (
        <GunMarker
          currentZoom={currentZoom}
          isHidden={hideGunMarker}
          onClick={handleClick}
          position={gunPositionCurrentGmaps}
          sprinklerType={sprinklerType}
        />
      ) : null}
    </React.Fragment>
  );
}
export type { ReelRunPolygonProps };

const ReelRunPolygonMemoized = React.memo(ReelRunPolygon);

export default ReelRunPolygonMemoized;
