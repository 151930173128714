import { isNumber, isString } from 'lodash';
import { isNullish } from 'src/types';
import { appLogger } from 'src/utilities/logger';
import { pluralize } from 'src/utilities/string-formatting';

const hoursAndMinutes: Intl.DateTimeFormatOptions = {
  hour: `numeric`,
  minute: `numeric`
} as const;

export type DtInput = Date | number | string | null | undefined;

/**
 * @param dt
 */
function parseDate(dt: DtInput): Date | null {
  if (isString(dt)) {
    return new Date(dt);
  }
  if (isNumber(dt)) {
    return new Date(dt / 1000);
  }
  if (dt instanceof Date) {
    return parseDate(dt.toISOString());
  }
  return null;
}
type DurationText =
  | `${number} hours ${number} minutes`
  | `${number} hours`
  | `${number} minutes`
  | `N/A`;

/**
 * @param inputMs
 */
export function millisecondsToDuration(inputMs: number): DurationText {
  const secondsRaw = inputMs / 1000;
  const elapsedMinutes = Math.floor(secondsRaw / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const minutesRemainder = Math.round(elapsedMinutes % 60);
  if (elapsedHours === 0) {
    if (minutesRemainder > 0) {
      return `${minutesRemainder} minutes`;
    }
    return 'N/A';
  }
  if (minutesRemainder === 0) {
    return `${elapsedHours} hours`;
  }
  return `${elapsedHours} hours ${minutesRemainder} minutes`;
}

/**
 * @param milliseconds
 */
function makeDurationText(milliseconds: number): string {
  const secondsRaw = milliseconds / 1000;
  const minutesRaw = secondsRaw / 60;
  const minutesFloor = Math.floor(minutesRaw);
  const hoursRaw = secondsRaw / 360;
  const hoursFloor = Math.floor(hoursRaw);
  const minutesRemainder = Math.floor(minutesRaw % 60);
  if (hoursFloor === 0) {
    return `${minutesRemainder} minutes`;
  }
  if (minutesFloor < 1) {
    return `Less than one minute`;
  }
  return `${hoursFloor} hours ${
    minutesRemainder === 0
      ? ''
      : `and ${minutesRemainder} ${pluralize({
          count: minutesRemainder,
          word: 'minute'
        })}`
  }`;
}
const DateFormatting = {
  /**
   * @param dt
   */
  toDateHoursAndMinutes: (dt: DtInput) => {
    const date = parseDate(dt);
    return date?.toLocaleString();
  },

  /**
   * @param input
   * @param dt
   * @param fallback
   */
  toHoursAndMinutes: (dt: DtInput, fallback = ``) => {
    const input = parseDate(dt);
    if (isNullish(input)) {
      return fallback;
    }
    const date = isString(input) ? new Date(input) : input;

    return date.toLocaleTimeString(undefined, hoursAndMinutes);
  },

  toSinceWhenText: (dt: DtInput) => {
    if (isNullish(dt)) {
      return ``;
    }
    const date = isString(dt) ? new Date(dt) : dt;

    const eventDate = new Date(date);
    const now = new Date();
    const elapsedMs = now.getTime() - eventDate.getTime();

    if (elapsedMs < 0) {
      return null;
    }

    const secondsRaw = elapsedMs / 1000;

    const minutesRaw = secondsRaw / 60;
    const hoursRaw = minutesRaw / 60;
    const days = Math.floor(hoursRaw / 24);
    if (days >= 1) {
      if (days === 1) {
        return `since yesterday`;
      }
      return `for ${days} days`;
    }
    const hoursRounded = Math.floor(hoursRaw);
    const minutesRemainder = Math.floor(minutesRaw % 60);
    // const secondsRemainder = Math.floor(minutesRemainder % 60);

    const minutesLabel = pluralize({
      count: minutesRemainder,
      word: `minute`
    });
    const hoursLabel = pluralize({
      count: hoursRounded,
      word: `hour`
    });

    if (hoursRounded) {
      const andMinutesText = minutesRemainder
        ? `and ${minutesRemainder} ${minutesLabel}`
        : ``;

      return `for ${hoursRounded} ${hoursLabel} ${andMinutesText}`;
    }
    if (minutesRemainder) {
      // 30 minutes ago
      return `for ${minutesRemainder} ${minutesLabel}`;
    }

    if (isNumber(secondsRaw)) {
      return secondsRaw < 10
        ? `just now`
        : `${Math.round(secondsRaw)} seconds ago`;
    }
    appLogger.warn(`failed to format dt object`);
    return ``;
  },

  /**
   * @param dt
   */
  toTimeAgoText: (dt: DtInput) => {
    if (isNullish(dt)) {
      return ``;
    }
    const date = isString(dt) ? new Date(dt) : dt;

    const eventDate = new Date(date);
    const now = new Date();
    const elapsedMs = now.getTime() - eventDate.getTime();

    if (elapsedMs < 0) {
      return null;
    }

    const secondsRaw = elapsedMs / 1000;

    const minutesRaw = secondsRaw / 60;
    const hoursRaw = minutesRaw / 60;
    const days = Math.floor(hoursRaw / 24);
    if (days >= 1) {
      if (days === 1) {
        return `yesterday`;
      }
      return `${days} days ago`;
    }
    const hoursRounded = Math.floor(hoursRaw);
    const minutesRemainder = Math.floor(minutesRaw % 60);
    // const secondsRemainder = Math.floor(minutesRemainder % 60);

    const minutesLabel = pluralize({
      count: minutesRemainder,
      word: `minute`
    });
    const hoursLabel = pluralize({
      count: hoursRounded,
      word: `hour`
    });

    if (hoursRounded) {
      const andMinutesText = minutesRemainder
        ? `and ${minutesRemainder} ${minutesLabel}`
        : ``;
      // 2 hours and 20 minutes ago
      return `${hoursRounded} ${hoursLabel} ${andMinutesText} ago`;
    }
    if (minutesRemainder) {
      // 30 minutes ago
      return `${minutesRemainder} ${minutesLabel} ago`;
    }

    if (isNumber(secondsRaw)) {
      return secondsRaw < 10
        ? `just now`
        : `${secondsRaw.toFixed()} seconds ago`;
    }
    appLogger.warn(`failed to format dt object`);
    return undefined;
  }
} as const;

export type DtFormat = keyof typeof DateFormatting;
export { DateFormatting, makeDurationText };
