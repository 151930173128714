import type { GridProps } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import { isNullish } from 'src/types';

type Props = React.PropsWithChildren<
  GridProps & {
    gridItemProps?: GridProps;
  }
>;

const DEFAULTS: { [K in 'child' | 'parent']: GridProps } = {
  child: {},
  parent: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};

const GridWrapper = React.forwardRef<any, Props>(
  ({ children, gridItemProps, ...rest }, ref) => {
    return (
      <Grid container {...DEFAULTS.parent} {...rest} ref={ref}>
        {React.Children.map(children, (child) => {
          if (isNullish(child)) {
            return null;
          }
          return (
            <Grid item {...DEFAULTS.child} {...gridItemProps}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    );
  }
);
GridWrapper.displayName = 'GridWrapper';

export default GridWrapper;
