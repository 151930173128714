import React from 'react';
import { useSelector } from 'react-redux';
import Sensors, { SensorEvents } from 'src/devices/sensors';
import { Geo } from 'src/geo';
import { notNullish } from 'src/types';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import type { AnySensorEvent, SensorName } from 'src/devices/sensors';
import type { DeviceIdProp } from '../models';

type Props<S extends SensorName> = DeviceIdProp & {
  sensorName: S;
};

/**
 * @param props
 * @param props.deviceId
 * @param props.sensorName
 * @param props.onClose
 */
export function SensorReadingsList<S extends SensorName>({
  sensorName
}: Props<S>): JSX.Element | null {
  const index = useSelector(
    (state) => state.inspectRecentEvents.selectedIndex ?? 0
  );
  const data = useSelector((state): AnySensorEvent | null => {
    const event = state.inspectRecentEvents.entities[index];
    if (notNullish(event)) {
      return event[sensorName];
    }
    return null;
  });
  const keys = SensorEvents.getKeysOfSensorEvent(sensorName);
  const formatVal = Sensors.useFormatValue();
  const formatKey = Sensors.useFormatKey();
  if (notNullish(data)) {
    return (
      <List dense>
        {keys.map((k) => {
          if (!SensorEvents.isKeyOfSensorEvent(sensorName, k)) {
            return null;
          }
          const value = data[k as keyof typeof data];
          const keyFormatted = formatKey(k);
          if (Geo.points.is.geoJson(value)) {
            return null;
          }
          const valueFormatted = formatVal(k, value);

          if (notNullish(keyFormatted)) {
            return (
              <ListItem disableGutters divider key={k}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <ListItemText>{keyFormatted}</ListItemText>
                  </Grid>
                  <Grid alignItems="center" item justifyContent="flex-end">
                    <ListItemText>{valueFormatted}</ListItemText>
                  </Grid>
                </Grid>
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );
  }
  return null;
}
