import type { ChipProps } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import {
  SignalCellularAltRounded,
  SignalCellularOff
} from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { isNullish } from 'src/types';
import type { DeviceIdProp } from './models';
import { getLastEventLocationById } from './selectors';

const DEFAULT_PROPS: ChipProps = {
  size: 'small'
};
type Signal = 0 | 1;
const PROPS: { [K in Signal]: ChipProps } = {
  0: {
    label: 'NO GPS',
    color: 'default',
    icon: <SignalCellularOff />
  },
  1: {
    label: 'GPS',
    color: 'primary',
    icon: <SignalCellularAltRounded />
  }
};

/**
 *
 * Indicates whether the last event for the device has gps location data
 *
 * This is used so that the user will understand why (for example)
 * if they click on an event in the sidebar without gps location,
 * it can't be seen on the status map
 *
 * @augments Chip
 * @param props
 * @param props.deviceId - used to look up device event last
 * @param rest - Mui Chip props
 */
const SignalStrengthIndicator = React.forwardRef<any, ChipProps & DeviceIdProp>(
  ({ deviceId, ...rest }, ref): JSX.Element => {
    const location =
      useSelector((state) => getLastEventLocationById(state, deviceId)) ?? null;
    const strength = isNullish(location) ? 0 : 1;

    return (
      <Chip
        data-cy="signal-strength-indicator"
        id="signal-strength-indicator"
        ref={ref}
        {...DEFAULT_PROPS}
        {...PROPS[strength]}
        {...rest}
      />
    );
  }
);
SignalStrengthIndicator.displayName = 'SignalStrengthIndicator';
export default SignalStrengthIndicator;
