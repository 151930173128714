import type { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { length as lineLength, polygonToLineString } from '@turf/turf';
import { isNumber } from 'lodash-es';
import Actions from 'src/actions';
import { asyncCreateField } from 'src/fields/fieldRequests.actions';
import type { GmapsPolygonPath } from 'src/geo';
import { Geo } from 'src/geo';
import { closeGmapsPath } from 'src/geo/polygons';
import type { RootState } from 'src/root.reducer';
import { humanizeAzimuth } from 'src/utilities';
import { FarmFields } from '../../fields/farmFields.reducer';

/**
 * @param p
 * @param p.getPath
 * @param poly
 */
function extractPathFromPolygon(poly: google.maps.Polygon): GmapsPolygonPath {
  const latLngArray = poly
    .getPath()
    .getArray()
    .map((pt) => pt.toJSON());
  return closeGmapsPath(latLngArray);
}

type CreateFieldStage =
  | 'CONFIRM'
  | 'SUCCESS'
  | `DRAW_BOUNDARY`
  | `EDIT_BOUNDARY`
  | `ENTER_NAME`
  | `SET_ROW_DIRECTION`;

interface State {
  errorCode?: 'NOT_ENOUGH_POINTS';
  path: GmapsPolygonPath;
  fieldName: string | null;
  isConfirming: boolean;
  rowDirectionAzimuthDegrees: number | null;
  currentStage: CreateFieldStage;
}

const INITIAL_STATE: State = {
  currentStage: 'DRAW_BOUNDARY',
  fieldName: null,
  path: [],
  rowDirectionAzimuthDegrees: null,
  isConfirming: false
};
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.setActiveFeature, (state, action) => {
        if (action.payload?.name === 'CREATE_FIELD') {
          return {
            ...INITIAL_STATE
          };
        }
        return { ...state };
      })
      .addCase(asyncCreateField.fulfilled, (state) => {
        state.path = [];
        state.currentStage = 'SUCCESS';
        state.rowDirectionAzimuthDegrees = null;
        state.isConfirming = false;
      })
      .addMatcher(isAnyOf(Actions.clickCancel), (state) => ({
        ...INITIAL_STATE,
        shouldDeletePolygon: state.path.length > 0
      })),

  initialState: { ...INITIAL_STATE },
  name: 'createField',
  reducers: {
    clickAdjustBoundary: (state) => {
      state.currentStage = 'EDIT_BOUNDARY';
    },
    clickChangeRowDirection: (state) => {
      state.currentStage = 'SET_ROW_DIRECTION';
    },
    clickRedrawField: (state) => {
      state.currentStage = 'DRAW_BOUNDARY';
    },
    clickRename: (state) => {
      state.currentStage = 'ENTER_NAME';
    },
    finishAdjustingBoundary: (state) => {
      state.currentStage = 'CONFIRM';
    },
    finishPolygon: (state, action: PayloadAction<GmapsPolygonPath>) => {
      state.path = [...action.payload];
      if (state.path.length >= 3) {
        state.currentStage = state.isConfirming ? 'CONFIRM' : 'ENTER_NAME';
      } else {
        state.errorCode = 'NOT_ENOUGH_POINTS';
      }
    },
    onDragBoundaryPoint: (state, action: PayloadAction<GmapsPolygonPath>) => {
      state.path = [...action.payload];
    },
    submitFieldName: (state, action: PayloadAction<string>) => {
      state.fieldName = action.payload;
      state.currentStage = state.isConfirming ? 'CONFIRM' : 'SET_ROW_DIRECTION';
    },
    submitRowDirection: (state, action: PayloadAction<number | undefined>) => {
      state.rowDirectionAzimuthDegrees = action.payload ?? 0;
      state.isConfirming = true;
      state.currentStage = 'CONFIRM';
    }
  }
});
const getCurrentStage = (state: RootState): State['currentStage'] =>
  state.createField.currentStage;

const getFieldName = (state: RootState): string | null =>
  state.createField.fieldName;

const getAzimuth = (state: RootState): number | null =>
  state.createField.rowDirectionAzimuthDegrees;

const getPath = (state: RootState): GmapsPolygonPath => state.createField.path;

const getNumPoints = createSelector(getPath, (path) => path.length);

const getPathAsGeoJson = createSelector(getPath, (path) =>
  path.length > 3 ? Geo.polygons.to.geojson(path) : null
);
const getCenter = createSelector(getPathAsGeoJson, (geoJson) =>
  geoJson !== null ? Geo.polygons.get.center(geoJson).geometry : null
);

const getCenterGmaps = createSelector(getCenter, (center) =>
  center === null ? null : Geo.points.to.googleMaps(center)
);

const getBearingText = createSelector(getAzimuth, (azimuth) => {
  return isNumber(azimuth) ? humanizeAzimuth(azimuth) : null;
});

const getArrowLengthMeters = createSelector(
  getPathAsGeoJson,
  (polygon): number | undefined => {
    if (polygon) {
      const polygonLength = lineLength(polygonToLineString(polygon), {
        units: 'meters'
      });
      return polygonLength / 4;
    }
    return undefined;
  }
);

const getLatLngBounds = createSelector(getPathAsGeoJson, (polygon) => {
  if (polygon) {
    return FarmFields.calculateBounds(polygon);
  }
  return null;
});

const { actions } = slice;

const CreateField = {
  ...actions,
  getArrowLengthMeters,
  getAzimuth,
  getBearingText,
  getCenter,
  getCenterGmaps,
  getCurrentStage,
  getFieldName,
  getLatLngBounds,
  getPath,
  getPathAsGeoJson,
  getNumPoints
} as const;
const createField: Reducer<State> = slice.reducer;
export { CreateField, extractPathFromPolygon };
export type { CreateFieldStage };
export default createField;
