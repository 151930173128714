import { RequestNames } from 'src/constants';
import { makeApiRequest } from 'src/requests';
import { User } from 'src/userSession.reducer';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRemoteControlSpec } from './RemoteControlSpec';

import type { RootThunkApi } from 'src/store';
import type { RemoteControlKey } from './RemoteControlKeys';
const { INVOKE_REMOTE_CONTROL } = RequestNames;
type Args = {
  deviceId: string;
  commandKey: RemoteControlKey;
};

export const asyncInvokeRemoteControl = createAsyncThunk<
  200,
  Args,
  RootThunkApi
>(
  INVOKE_REMOTE_CONTROL,
  async ({ deviceId, commandKey: key }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const command = getRemoteControlSpec(state, deviceId, key);
      if (typeof command === 'undefined') {
        throw new Error('Command does not exist for device');
      }
      const { options, functionName, confirmationRequired } = command;
      if (
        confirmationRequired === true &&
        state.remoteControls.confirmingCommand !== key
      ) {
        return rejectWithValue({
          code: 'CONFIRMATION_REQUIRED'
        });
      }
      const extraParameters = options?.extraParameters;

      const permissionCheck = User.getHasPermissions(
        state,
        'canControlDevicesRemotely'
      );
      if (permissionCheck.code === 'DENIED') {
        return rejectWithValue(permissionCheck);
      }
      await makeApiRequest(INVOKE_REMOTE_CONTROL, {
        deviceId,
        extraParameters,
        functionName
      });

      return 200;
    } catch (error) {
      // return handleApiRequestError(error, {
      //   toastMessage: `The request sent to the device was unsuccessful.
      //   Please ensure it is powered on and has cellular signal, and try again.`,
      // });
      return Promise.reject(error);
    }
  },
  {
    condition: ({ deviceId, commandKey }, { getState }) => {
      const tracker = getState().remoteControls.entities[deviceId];
      if (tracker) {
        return !(tracker[commandKey]?.fetchStatus === 'pending');
      }
      return true;
    }
  }
);
