/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  LinkProps as MaterialLinkProps,
  TypographyVariant
} from '@material-ui/core';
import { Link as MaterialLink } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { HasText, HasVariant } from '../types';

interface StyledLinkProps
  extends Pick<MaterialLinkProps, 'id' | 'onClick'>,
    HasText,
    HasVariant<TypographyVariant> {
  linkTo: string;
  disabled?: boolean;
}

type Props = React.PropsWithChildren<StyledLinkProps>;

/**
 * @param props
 * @param props.children
 * @param props.disabled
 * @param props.dataCy
 * @param props.onClick
 * @param props.id
 * @param props.linkTo
 * @param props.variant
 * @param ref
 */
const StyledLink = React.forwardRef<any, Props>(
  ({ children, disabled, onClick, id, linkTo, variant, text }, ref) => {
    const testId = id ?? `${text}-link`;
    if (typeof linkTo === 'undefined') {
      throw new Error(`Styled link required "linkTo" property`);
    }
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        if (disabled === true) {
          e.preventDefault();
        }

        if (onClick) {
          onClick(e);
        }
      },
      [disabled, onClick]
    );
    return (
      <MaterialLink
        aria-disabled={disabled}
        component={RouterLink}
        data-cy={testId}
        id={testId}
        onClick={handleClick}
        ref={ref}
        to={linkTo}
        variant={variant}
      >
        {text ?? children}
      </MaterialLink>
    );
  }
);

StyledLink.displayName = 'StyledLink';
export default StyledLink;
