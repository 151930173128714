import {
  CircularProgress,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { isString } from 'lodash-es';
import React from 'react';
import { NoListItems } from 'src/components/lists';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import { notNullish } from 'src/types';
import formatExpiration from 'src/utilities/formatExpiration';
import type { DeviceFarmAssociation } from '../device-farm-association';
import type { DeviceIdProp } from '../models';
import { asyncGetFarmsBorrowingDevice } from './deviceShareCodes.reducer';

const useStyles = makeStyles(() => {
  return createStyles({
    expirationText: {
      marginLeft: 10
    },
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'center'
    },
    root: {},
    secondaryText: {}
  });
});

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
  timeStyle: 'short'
});
/**
 * @param props
 * @param props.deviceId
 * @param props.codeExists
 */
export default function FarmsBorrowingDeviceList({
  deviceId
}: DeviceIdProp): JSX.Element {
  const { sendRequest, isPending } = useThunk(asyncGetFarmsBorrowingDevice);
  const [farms, setItems] = React.useState<DeviceFarmAssociation[]>([]);
  const classes = useStyles();
  React.useEffect(() => {
    if (isString(deviceId)) {
      sendRequest({ deviceId })
        .then((res) => setItems([...res.items]))
        .catch(handleApiRequestError);
    }
  }, [deviceId, sendRequest]);
  if (isPending) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  const id = `farms-borrowing-device-list`;
  return (
    <List className={classes.root} data-cy={id} dense disablePadding id={id}>
      {!isPending && farms.length ? (
        farms.map((f) => {
          const expirationFormatted = formatExpiration(f.expirationDate);
          return (
            <ListItem dense key={f.farmName}>
              <ListItemText
                primary={f.farmName}
                secondary={
                  <span className={classes.secondaryText}>
                    <span>
                      {`Since ${dateFormatter.format(new Date(f.createDate))}`}
                    </span>
                    {notNullish(expirationFormatted) ? (
                      <span className={classes.expirationText}>
                        Expires: {expirationFormatted}
                      </span>
                    ) : null}
                  </span>
                }
              />
            </ListItem>
          );
        })
      ) : (
        <NoListItems title="No farms are currently borrowing this device" />
      )}
    </List>
  );
}
