import React from 'react';
import { User } from 'src/userSession.reducer';
import convertSensorValue from './convertSensorValue';
import type { AnySensorKey, ConversionTarget } from './getConversionUnits';

/**
 * @param args
 * @param args.target
 * @param arg
 * @param arg.target
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useSensorValueConverter<K extends AnySensorKey>(arg?: {
  target: ConversionTarget;
}) {
  const isMetric = User.useIsMetric();

  return React.useCallback(
    (key: K, value: number | string) => {
      const converted = convertSensorValue<K, number | string>({
        isMetric,
        key,
        target: arg?.target,
        value
      });
      return converted;
    },
    [arg?.target, isMetric]
  );
}
