import { isNumber, isString } from 'lodash-es';
import type { Nullable } from 'src/types';
import { isPlainObj } from 'src/types';
import { User } from 'src/userSession.reducer';
import type { SetDifference, Unionize, ValuesType } from 'utility-types';
import type { SensorName } from './SensorNames';

type HardwareGeneration = 'FC2' | 'PC1';

const SENSOR_PORTS = [1, 2] as const;
type SensorPort = typeof SENSOR_PORTS[number];

const isValidSensorPort = (x: unknown): x is SensorPort =>
  isNumber(x) ? [...SENSOR_PORTS].includes(x as SensorPort) : false;

const SPRINKLER_TYPE = {
  boom: 'boom',
  gun: 'gun'
} as const;
const SPRINKLER_TYPES = Object.values(SPRINKLER_TYPE);

type SprinklerType = ValuesType<typeof SPRINKLER_TYPE>;
const isValidSprinklerType = (x: unknown): x is SprinklerType => {
  return isString(x) && SPRINKLER_TYPES.includes(x as SprinklerType);
};

const SWITCH_TYPE = {
  closed: 'C',
  open: 'O'
} as const;
const SWITCH_TYPES = Object.values(SWITCH_TYPE);
type SwitchType = ValuesType<typeof SWITCH_TYPE>;

/**
 * @param x
 */
function isValidSwitchType(x: unknown): x is SprinklerType {
  return isString(x) && Object.values(SWITCH_TYPE).includes(x as SwitchType);
}

const REEL_N_MAGNETS = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] as const;
const WHEEL_N_MAGNETS = [2, 3, ...REEL_N_MAGNETS] as const;

const GPS_TYPE = {
  SAM_M8Q: `SAM_M8Q`
} as const;
type GpsType = ValuesType<typeof GPS_TYPE>;
type PressureThresholds = {
  thresholdPsiLower: number;
  thresholdPsiUpper: number;
};
/**
 * @param x
 */
function isCompletePressureThresholds(x: unknown): x is PressureThresholds {
  return (
    isPlainObj(x) &&
    'thresholdPsiLowerr' in x &&
    typeof x.psiLower === 'number' &&
    'thresholdPsiUpper' in x &&
    typeof x.psiUpper === 'number'
  );
}

const CONFIG_DEFAULTS = {
  battery: {
    ioPin: 1,
    voltageIntercept: 1,
    voltageSlope: 1,
    voltageThresholdLowMv: 1
  },
  gps: { gpsType: 'SAM_M8Q' },
  hallSwitch: {
    calibrationIntercept: 1,
    calibrationSlope: 1,
    sensorPort: 1,
    switchType: 'C'
  },
  maxPsi: 240,
  pressure: {
    sensorPort: 1,
    thresholdPsiLower: 60,
    thresholdPsiUpper: 140
  },
  pressureSwitch: {
    sensorPort: 1,
    switchType: 'C',
    threshold: 1
  },
  reel: {
    hoseDiameterMm: 100,
    nMagnets: 16,
    nNozzles: 1,
    nWrapsOuterLayer: 7,
    nozzleDiameterMm: 1,
    outerHoseWrapRadiusMm: 100,
    sensorPort: 1,
    sprinklerType: 'gun',
    swathWidthMm: 140,
    widthMm: 100
  },
  relay: {
    ioPin: 1
  },
  temperature: {
    calibrationIntercept: 100,
    calibrationSlope: 100
  },
  wheel: {
    diameterMm: 100,
    nMagnets: 4,
    sensorPort: 1
  }
} as const;
const BASE_CONFIG_KEYS = ['sensorName'] as const;

const SENSOR_NAME_TO_CONFIG_KEYS = {
  battery: [
    ...BASE_CONFIG_KEYS,
    'ioPin',
    'voltageIntercept',
    'voltageSlope',
    'voltageThresholdLowMv'
  ],

  gps: [...BASE_CONFIG_KEYS, 'gpsType'],
  hallSwitch: [
    ...BASE_CONFIG_KEYS,
    'switchType',
    'sensorPort',
    'calibrationIntercept',
    'calibrationSlope'
  ],
  pressure: [
    ...BASE_CONFIG_KEYS,
    'sensorPort',
    'thresholdPsiLower',
    'thresholdPsiUpper'
  ],
  pressureSwitch: [
    ...BASE_CONFIG_KEYS,
    'sensorPort',
    'threshold',
    'switchType'
  ],
  reel: [
    ...BASE_CONFIG_KEYS,
    'hoseDiameterMm',
    'nMagnets',
    'nNozzles',
    'sensorPort',
    'nozzleDiameterMm',
    'nWrapsOuterLayer',
    'outerHoseWrapRadiusMm',
    'sprinklerType',
    'swathWidthMm',
    'widthMm'
  ],
  relay: [...BASE_CONFIG_KEYS, 'ioPin'],
  temperature: [
    ...BASE_CONFIG_KEYS,
    'calibrationIntercept',
    'calibrationSlope'
  ],
  wheel: [...BASE_CONFIG_KEYS, 'sensorPort', 'diameterMm', 'nMagnets']
} as const;

type ConfigSensorName = SetDifference<SensorName, 'device'>;
type SensorNameToConfigKeys = typeof SENSOR_NAME_TO_CONFIG_KEYS;
type AnySensorConfigKey = ValuesType<SensorNameToConfigKeys>[number];

type ValueByKey<
  K extends AnySensorConfigKey,
  S extends ConfigSensorName = ConfigSensorName
> = K extends K
  ? K extends 'sensorPort'
    ? SensorPort
    : K extends 'sprinklerType'
    ? SprinklerType
    : K extends 'switchType'
    ? SwitchType
    : K extends 'gpsType'
    ? GpsType
    : K extends 'sensorName'
    ? S
    : number | null
  : never;
type GetConfigKeysBySensorName<S extends ConfigSensorName> =
  typeof SENSOR_NAME_TO_CONFIG_KEYS[S][number];
// type Test = GetConfigKeysBySensorName<'reel'>
type SensorConfig<S extends ConfigSensorName> = {
  [K in GetConfigKeysBySensorName<S>]: ValueByKey<K>;
};

type GetUndefinedKeys<S extends ConfigSensorName> = {
  [K in SetDifference<
    AnySensorConfigKey,
    GetConfigKeysBySensorName<S>
  >]?: undefined;
};

type GenericSensorConfig<S extends ConfigSensorName = ConfigSensorName> =
  S extends S
    ? GetUndefinedKeys<S> &
        {
          [K in GetConfigKeysBySensorName<S>]: K extends K
            ? Nullable<ValueByKey<K>>
            : never;
        }
    : never;

type SensorConfigsData = { [S in ConfigSensorName]: SensorConfig<S> };
type AnySensorConfig = ValuesType<Unionize<SensorConfigsData>>;
/**
 * @param sensorName
 * @param target
 */
function isKeyOfSensorConfig<S extends ConfigSensorName>(
  sensorName: S,
  target: unknown
): target is GetConfigKeysBySensorName<S> {
  return (
    isString(target) &&
    [...SENSOR_NAME_TO_CONFIG_KEYS[sensorName]].includes(
      target as GetConfigKeysBySensorName<S>
    )
  );
}
/**
 * @param sensorName
 */
function getKeysOfSensorConfig<S extends ConfigSensorName>(
  sensorName: S
): Array<
  {
    readonly battery: readonly [
      'sensorName',
      'ioPin',
      'voltageIntercept',
      'voltageSlope',
      'voltageThresholdLowMv'
    ];
    readonly gps: readonly ['sensorName', 'gpsType'];
    readonly hallSwitch: readonly [
      'sensorName',
      'switchType',
      'sensorPort',
      'calibrationIntercept',
      'calibrationSlope'
    ];
    readonly pressure: readonly [
      'sensorName',
      'sensorPort',
      'thresholdPsiLower',
      'thresholdPsiUpper'
    ];
    readonly pressureSwitch: readonly [
      'sensorName',
      'sensorPort',
      'threshold',
      'switchType'
    ];
    readonly reel: readonly [
      'sensorName',
      'hoseDiameterMm',
      'nMagnets',
      'nNozzles',
      'sensorPort',
      'nozzleDiameterMm',
      'nWrapsOuterLayer',
      'outerHoseWrapRadiusMm',
      'sprinklerType',
      'swathWidthMm',
      'widthMm'
    ];
    readonly relay: readonly ['sensorName', 'ioPin'];
    readonly temperature: readonly [
      'sensorName',
      'calibrationIntercept',
      'calibrationSlope'
    ];
    readonly wheel: readonly [
      'sensorName',
      'sensorPort',
      'diameterMm',
      'nMagnets'
    ];
  }[S][number]
> {
  return [...SENSOR_NAME_TO_CONFIG_KEYS[sensorName]];
}

const READ_ONLY_CONFIG_FIELDS = [
  'calibrationSlope',
  'calibrationIntercept',
  'gpsType',
  'ioPin',
  'sensorName',
  'voltageSlope',
  'voltageIntercept'
] as const;
type ReadonlySensorConfigKey = typeof READ_ONLY_CONFIG_FIELDS[number];
type IsReadonly<K extends AnySensorConfigKey = AnySensorConfigKey> =
  K extends ReadonlySensorConfigKey ? K : never;

/**
 *
 */
function useReadOnlyKeys() {
  const isAdmin = User.useIsAdmin();
  return (key: AnySensorConfigKey): key is IsReadonly => {
    if (isAdmin) {
      return false;
    }

    return READ_ONLY_CONFIG_FIELDS.includes(key as ReadonlySensorConfigKey);
  };
}
type GetEditableKeysOfSensorConfig<S extends ConfigSensorName> = SetDifference<
  keyof SensorConfig<S>,
  ReadonlySensorConfigKey
>;

type HasSensorPort<S extends ConfigSensorName = ConfigSensorName> = S extends
  | 'pressure'
  | 'pressureSwitch'
  | 'reel'
  | 'wheel'
  ? S
  : never;

const SensorConfigs = {
  CONFIG_DEFAULTS,
  GPS_TYPE,

  N_MAGNETS: {
    reel: REEL_N_MAGNETS,
    wheel: WHEEL_N_MAGNETS
  },
  SENSOR_NAME_TO_CONFIG_KEYS,
  SENSOR_PORTS,
  SPRINKLER_TYPE,
  SPRINKLER_TYPES,
  SWITCH_TYPE,
  SWITCH_TYPES,

  getKeysOfSensorConfig,
  isCompletePressureThresholds,
  isKeyOfPressureThresholds: (
    key: AnySensorConfigKey
  ): key is keyof PressureThresholds => {
    return key === 'thresholdPsiUpper' || key === 'thresholdPsiLower';
  },
  isKeyOfSensorConfig,
  isSensorWithSensorPort: (
    sensorName: ConfigSensorName
  ): sensorName is HasSensorPort =>
    getKeysOfSensorConfig(sensorName).includes('sensorPort'),
  isValidSensorPort,
  isValidSprinklerType,
  isValidSwitchType,
  useReadOnlyKeys
} as const;
export {
  getKeysOfSensorConfig,
  CONFIG_DEFAULTS,
  SENSOR_NAME_TO_CONFIG_KEYS,
  READ_ONLY_CONFIG_FIELDS
};
export type {
  AnySensorConfig,
  AnySensorConfigKey,
  GenericSensorConfig,
  GetConfigKeysBySensorName,
  GpsType,
  HardwareGeneration,
  ConfigSensorName,
  HasSensorPort,
  PressureThresholds,
  GetEditableKeysOfSensorConfig,
  SensorConfig,
  SensorPort,
  SensorConfigsData,
  SprinklerType,
  SwitchType
};
export default SensorConfigs;
