import React from 'react';
import { useClearActiveFeature } from 'src/actions';
import testIds from 'src/testIds';
import type { EnhancedButtonProps } from './AppButton';
import AppButton from './AppButton';

/**
 * @param props
 * @param props.id
 * @param props.rest
 * @param props.children
 * @param props.onClick
 */
function ClearActiveFeatureBtn({
  id,
  children,
  onClick,
  ...rest
}: EnhancedButtonProps): JSX.Element {
  const clearActiveFeature = useClearActiveFeature();
  return (
    <AppButton
      id={id ?? testIds.clearActiveFeatureBtn}
      onClick={onClick ?? clearActiveFeature}
      {...rest}
    >
      {children ?? 'cancel'}
    </AppButton>
  );
}

export default ClearActiveFeatureBtn;
