import { Avatar, createStyles, makeStyles, SvgIcon } from '@material-ui/core';
import React from 'react';
import { AppIcon } from 'src/theme';

const useStyles = makeStyles(({ palette }) => {
  const aviSize = 60;
  const iconSize = aviSize - 20;
  return createStyles({
    avatar: () => {
      return {
        backgroundColor: palette.grey[200],
        borderColor: palette.grey[300],
        borderStyle: `solid`,
        borderWidth: 2,
        height: aviSize,
        width: aviSize
      };
    },

    icon: () => {
      return {
        height: iconSize,
        overflow: `visible`,
        width: iconSize
      };
    }
  });
});
/**
 *
 */
export default function FormWrapperAvatar(): JSX.Element {
  const classes = useStyles();
  return (
    <Avatar
      classes={{
        root: classes.avatar
      }}
      variant="square"
    >
      <SvgIcon className={classes.icon}>
        <AppIcon iconKey="Leaf" />
      </SvgIcon>
    </Avatar>
  );
}
