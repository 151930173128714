import {
  Card,
  CardActionArea,
  createStyles,
  Grow,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  makeStyles,
  Typography
} from '@material-ui/core';
import { GoogleMap, useGoogleMap } from '@react-google-maps/api';
import React from 'react';
import type { ColorResult } from 'react-color';
import { SliderPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks';
import { notNullish } from 'src/types';
import localStorage from 'storejs';
import Actions from '../actions';
import { useActiveFeature } from '../appSelectors';
import { GridWrapper, IconBtn } from '../components';
import { getFarmLocationGmaps } from '../userSession.reducer';
import {
  getCurrentMapZoom,
  MAP_TYPE_IDS,
  setFieldColor,
  setMapTypeId
} from './statusMap.reducer';

const useStyles = makeStyles((theme) => {
  return createStyles({
    mapContainer: {
      height: 130,
      overflow: 'hidden',
      width: '100%'
    },
    root: {
      bottom: 0,
      left: 0,
      maxWidth: 400,
      position: 'absolute',

      right: 0,
      zIndex: theme.zIndex.modal
    },
    square: {},
    tileBar: {
      height: 20
    },
    title: {
      paddingLeft: theme.spacing(1)
    }
  });
});

/**
 * @param t
 */
function makeOptions(t: string): google.maps.MapOptions {
  return {
    clickableIcons: false,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggable: false,
    mapTypeId: t
  };
}

/**
 * @param props
 */
export function MapTypeMenu(): JSX.Element | null {
  const center = useSelector(getFarmLocationGmaps);
  const zoom = useSelector(getCurrentMapZoom);
  const fieldColor = useSelector((state) => state.statusMap.fieldColor);
  const dispatch = useAppDispatch();

  const handleColorChangeCommitted = ({ hex }: ColorResult) => {
    localStorage.set('fieldColor', hex);
    dispatch(setFieldColor(hex));
  };
  const visible = useActiveFeature() === 'SET_MAP_TYPE_ID';
  const classes = useStyles();
  const map = useGoogleMap();

  return notNullish(center) ? (
    <Grow in={visible}>
      <Card className={classes.root}>
        <GridWrapper justifyContent="space-between">
          <Typography className={classes.title} variant="subtitle1">
            Map Style
          </Typography>
          <IconBtn
            iconKey="CLOSE"
            onClick={() => dispatch(Actions.setActiveFeature(null))}
            size="small"
          />
        </GridWrapper>

        <SliderPicker
          color={fieldColor}
          // colors={['gold', 'orange', 'orangered', 'red']}
          onChange={handleColorChangeCommitted}
        />
        <ImageList rowHeight={100}>
          {MAP_TYPE_IDS.map((t) => {
            return (
              <ImageListItem
                className={classes.square}
                component={CardActionArea}
                key={t}
                onClick={() => dispatch(setMapTypeId(t))}
              >
                <GoogleMap
                  center={map?.getCenter() ?? center}
                  mapContainerClassName={classes.mapContainer}
                  mapTypeId={t}
                  options={makeOptions(t)}
                  zoom={zoom ?? 13}
                >
                  {t}
                </GoogleMap>
                <ImageListItemBar
                  className={classes.tileBar}
                  position="top"
                  subtitle={t}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Card>
    </Grow>
  ) : null;
}
