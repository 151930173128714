import { isTruthyString } from 'src/types';

import humanizeSensorStateValue from './humanizeSensorStateValue';

import type { ValuesType } from 'utility-types';
import type { SwitchType } from './SensorConfig';
import type { SensorName } from './SensorNames';
const NONE = 'NONE' as const;
const CONFIGURED = 'C' as const;
const NOT_CONFIGURED = 'NC' as const;
const BATTERY_LOW = 'BL' as const;
const BATTERY_NORMAL = 'BN' as const;
const GPS_ACTIVE = 'A' as const;
const GPS_INACTIVE = 'I' as const;
const MAGNET_ABSENT = 'MA' as const;
const MAGNET_PRESENT = 'MP' as const;
const PRESSURE_HIGH = 'PHI' as const;
const PRESSURE_LOW = 'PLO' as const;
const PRESSURE_OVER = 'POV' as const;
const REEL_EXTEND = 'RE' as const;
const REEL_RETRACT = 'RR' as const;
const REEL_STOPPED = 'RS' as const;
const RELAY_OFF = 'OFF' as const;
const RELAY_ON = 'ON' as const;
const SWITCH_CLOSED = 'C' as const;
const SWITCH_OPEN = 'O' as const;
const TEMP_LOW = 'TLO' as const;
const TEMP_HIGH = 'THI' as const;
const WHEEL_FAST = 'WF' as const;
const WHEEL_SLOW = 'WL' as const;
const WHEEL_STOPPED = 'WS' as const;
const ALL_SENSOR_STATES = [
  BATTERY_LOW,
  BATTERY_NORMAL,
  GPS_ACTIVE,
  GPS_INACTIVE,
  TEMP_HIGH,
  MAGNET_ABSENT,
  MAGNET_PRESENT,
  PRESSURE_HIGH,
  PRESSURE_LOW,
  PRESSURE_OVER,
  REEL_EXTEND,
  REEL_RETRACT,
  REEL_STOPPED,
  RELAY_OFF,
  RELAY_ON,
  SWITCH_CLOSED,
  SWITCH_OPEN,
  TEMP_LOW,
  WHEEL_FAST,
  WHEEL_SLOW,
  WHEEL_STOPPED,
  NONE
] as const;
const SENSOR_NAME_TO_STATE_VALUES = {
  device: [CONFIGURED, NOT_CONFIGURED],
  battery: [BATTERY_LOW, BATTERY_NORMAL],
  gps: [GPS_ACTIVE, GPS_INACTIVE],
  hallSwitch: [MAGNET_ABSENT, MAGNET_PRESENT],
  pressure: [PRESSURE_HIGH, PRESSURE_LOW, PRESSURE_OVER],
  pressureSwitch: [PRESSURE_HIGH, PRESSURE_LOW, PRESSURE_OVER],
  reel: [REEL_EXTEND, REEL_RETRACT, REEL_STOPPED],
  relay: [RELAY_OFF, RELAY_ON],
  temperature: [TEMP_LOW, TEMP_HIGH],
  wheel: [WHEEL_FAST, WHEEL_SLOW, WHEEL_STOPPED]
} as const;
type SensorNameToStateValue = typeof SENSOR_NAME_TO_STATE_VALUES;

type AnySensorStateValue =
  | SwitchType
  | ValuesType<SensorNameToStateValue>[number]
  | 'NONE';

type SensorNameToState = typeof SENSOR_NAME_TO_STATE_VALUES;
type GetStatesOfSensor<S extends SensorName> = S extends S
  ? SensorNameToState[S][number]
  : never;

/**
 * @param s
 */
function getStatesForSensorName<S extends SensorName>(
  s: S
): Array<
  {
    readonly device: readonly ['C', 'NC'];
    readonly battery: readonly ['BL', 'BN'];
    readonly gps: readonly ['A', 'I'];
    readonly hallSwitch: readonly ['MA', 'MP'];
    readonly pressure: readonly ['PHI', 'PLO', 'POV'];
    readonly pressureSwitch: readonly ['PHI', 'PLO', 'POV'];
    readonly reel: readonly ['RE', 'RR', 'RS'];
    readonly relay: readonly ['OFF', 'ON'];
    readonly temperature: readonly ['TLO', 'THI'];
    readonly wheel: readonly ['WF', 'WL', 'WS'];
  }[S][number]
> {
  return [...SENSOR_NAME_TO_STATE_VALUES[s]];
}
/**
 * @param sensorName
 * @param x
 */
function isStateOfSensor<S extends SensorName>(
  sensorName: S,
  x: unknown
): x is GetStatesOfSensor<S> {
  return (
    isTruthyString(x) &&
    getStatesForSensorName(sensorName).includes(x as GetStatesOfSensor<S>)
  );
}

const SensorStates = {
  SENSOR_NAME_TO_STATE_VALUES,
  getStatesForSensorName,
  isStateOfSensor,
  ALL_SENSOR_STATES,
  BATTERY_LOW,
  BATTERY_NORMAL,
  GPS_ACTIVE,
  GPS_INACTIVE,
  MAGNET_ABSENT,
  humanize: humanizeSensorStateValue,
  MAGNET_PRESENT,
  PRESSURE_HIGH,
  PRESSURE_LOW,
  PRESSURE_OVER,
  REEL_EXTEND,
  REEL_RETRACT,
  REEL_STOPPED,
  RELAY_OFF,
  RELAY_ON,
  SWITCH_CLOSED,
  SWITCH_OPEN,
  TEMP_LOW,
  TEMP_HIGH,
  WHEEL_FAST,
  WHEEL_SLOW,
  WHEEL_STOPPED
};
export type {
  GetStatesOfSensor,
  SensorNameToState,
  SensorNameToStateValue,
  AnySensorStateValue
};
export default SensorStates;
