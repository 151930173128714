import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { CancelBtn, SubmitBtn } from 'src/components';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isPositiveInt } from 'src/types';
import { asyncDeleteTriggers } from '../actions';
import formatTrigger from '../formatTrigger';
import { useTriggerById } from '../triggers.reducer';

/**
 * @param props
 * @param props.id
 * @param props.onCancel
 */
export function ConfirmDeleteAutomation({
  id,
  onCancel
}: {
  id: number | null;
  onCancel: () => void;
}): JSX.Element {
  const { isPending, sendRequest } = useThunk(asyncDeleteTriggers);
  const triggerToDelete = useTriggerById(id ?? 0);
  const handleSubmit = () => {
    if (isPositiveInt(id)) {
      sendRequest({
        triggerIds: [id]
      })
        .then(() => {
          showSuccessToast();
          onCancel();
        })
        .catch(handleApiRequestError);
    }
  };
  const testId = testIds.deviceAutomations.confirmDelete.root;

  return (
    <Dialog data-cy={testId} id={testId} open={isPositiveInt(id)}>
      <DialogTitle>Confirm Delete Automation</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Are you sure you want to delete this automation?
        </Alert>
        <DialogContentText>
          {triggerToDelete ? formatTrigger(triggerToDelete) : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelBtn
          disabled={isPending}
          id={testIds.cancelBtn}
          onClick={onCancel}
        />
        <SubmitBtn
          id={testIds.submitBtn}
          onClick={handleSubmit}
          showLoading={isPending}
        />
      </DialogActions>
    </Dialog>
  );
}
