import type { GoogleMapProps } from '@react-google-maps/api';
import { GoogleMap } from '@react-google-maps/api';
import React from 'react';
import { useAppSelector } from 'src/hooks';
import { User } from 'src/userSession.reducer';

const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: false,
  mapTypeId: `satellite`,
  panControl: false,
  scaleControl: false,
  streetViewControl: false,
  styles: [],
  zoomControlOptions: {
    position: 1
  }
};

const mapContainer = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
} as const;
const DEFAULT_ZOOM = 13;
type Props = GoogleMapProps & {
  showDirectionArrows?: boolean;
  showFields?: boolean;
  showFieldNames?: boolean;
  showFarm?: boolean;
};

const FarmMap = React.forwardRef<any, Props>((props, ref) => {
  const { mapContainerStyle, options, center, children, ...rest } = props;
  const farmLocation = useAppSelector(User.getFarmLocationGmaps);

  const mapOptionsMemoized = React.useMemo(
    () => ({ ...DEFAULT_MAP_OPTIONS, ...options }),
    [options]
  );

  const initialCenter = center ?? farmLocation;

  return initialCenter ? (
    <GoogleMap
      {...rest}
      center={initialCenter}
      id="status-map"
      mapContainerStyle={mapContainerStyle ?? mapContainer}
      mapTypeId="satellite"
      options={mapOptionsMemoized}
      ref={ref}
      zoom={DEFAULT_ZOOM}
    >
      {children}
    </GoogleMap>
  ) : null;
});
export default FarmMap;
FarmMap.displayName = 'FarmMap';
