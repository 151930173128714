/* eslint-disable @typescript-eslint/no-namespace */
import '@fontsource/roboto';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { snakeCase } from 'lodash-es';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { appLogger } from 'src/utilities';
import reportWebVitals from 'src/utilities/reportWebVitals';

/* eslint-disable no-console */
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';

import App from './App';
import { appConfig } from './configure-app';
import { COGNITO_CONFIG, SENTRY_DSN } from './constants';
import store from './store';
import { isNullish, isPlainObj } from './types';

Auth.configure({ Auth: COGNITO_CONFIG });

const isDeployed = process.env.NODE_ENV === 'production';

const { backendUrl, deployment } = appConfig;

axios.defaults.baseURL =
  'https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com/live/PROD';

axios.interceptors.request.use(async (req) => {
  const data = req.data as unknown;
  if (!isPlainObj(data) && !isNullish(data)) {
    throw new Error('Request data must be plain object or null');
  }
  const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const transformed = {
    ...req,
    headers: {
      ...req.headers,
      Authorization: authToken
    },
    data: snakecaseKeys({
      ...data,
      requestedAction: snakeCase(req.data.requested_action).toLocaleUpperCase(),
      deployment,
      devUserId: process.env.REACT_APP_DEV_USER_ID,
      testUserId: process.env.REACT_APP_TEST_USER_ID
    })
  };
  return transformed;
});
axios.interceptors.response.use((res) => {
  if (isPlainObj(res.data)) {
    return {
      ...res,
      data: camelcaseKeys(res.data, { deep: true })
    };
  }
  return res;
});
Sentry.init({
  dsn: SENTRY_DSN,
  enabled: isDeployed,
  environment: deployment
});
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/">
          <App />
        </Route>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById(`root`)
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
if (deployment === 'BETA') {
  reportWebVitals(appLogger.debug);
}
// if (module.hot) {
//   module.hot.accept();
// }
// export * from './account-settings';
// export * from './asyncGetUserData';
// export * from './devices';
// export * from './farm';
// export * from './fields';
// export * from './geo';
// export * from './permissions';
// export * from './types';
// export * from './userSession.reducer';
// export { testIds };
