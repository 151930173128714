import React from 'react';
import { PolylineBase } from 'src/components/map';
import { Geo } from 'src/geo';
import { useAppSelector } from 'src/hooks';
import { getIsVisibleOnMap } from 'src/status-map/statusMap.reducer';
import { isNullish } from 'src/types';
import type { DevicePair } from '../models';
import { useSensorEventByDeviceId } from '../selectors';

type Props = Pick<DevicePair, 'sourceDeviceId' | 'targetDeviceId'> & {
  isSelected: boolean;
  isHighlighted: boolean;
};

const PairLine = React.memo<Props>(
  ({ sourceDeviceId, targetDeviceId, isSelected, isHighlighted }) => {
    const isHidden = useAppSelector(
      (state) => !getIsVisibleOnMap(state, 'pairLine')
    );

    const sourceLocation = useSensorEventByDeviceId(
      'gps',
      sourceDeviceId
    )?.location;
    const targetLocation = useSensorEventByDeviceId(
      'gps',
      targetDeviceId
    )?.location;

    const linePath = React.useMemo(() => {
      if (isNullish(sourceLocation) || isNullish(targetLocation)) {
        return [];
      }
      return [sourceLocation, targetLocation].map(Geo.points.to.googleMaps);
    }, [sourceLocation, targetLocation]);

    const makeItPop = isSelected || isHighlighted;

    return (
      <PolylineBase
        isHidden={isHidden}
        path={linePath}
        strokeColor={makeItPop ? 'yellow' : 'orange'}
        strokeOpacity={makeItPop ? 1 : 0.6}
        strokeWeight={makeItPop ? 4 : 2}
      />
    );
  }
);
PairLine.displayName = 'PairLine';
export default PairLine;
