import {
  capitalize,
  isNumber,
  isString,
  kebabCase,
  lowerCase,
  range,
  replace
} from 'lodash-es';
import type { FarmAccount } from 'src/farm';
import { isNullish } from 'src/types';

/**
 * @param target
 */
function addParens<T extends string | null | undefined>(target: T): T | string {
  return typeof target === 'string' ? `(${target})` : target;
}

/**
 * @param permKey
 * @deprecated in favor of 'humanize'
 */
function camelToCapitalWords(permKey: string): string {
  const toKebab = kebabCase(permKey);
  const toArray = toKebab.split(`-`);
  const capitalized = toArray.map((word) => capitalize(word));
  return capitalized.join(` `);
}

/**
 * @param permKey
 */
function camelToLowercaseWords(permKey: string): string {
  const toKebab = kebabCase(permKey);
  const toArray = toKebab.split(`-`);
  const lower = toArray.map((word) => lowerCase(word));
  return lower.join(` `);
}

/**
 * @param value
 * @param opts
 * @param opts.lowercase
 */
function humanize(
  value: number | string | null | undefined,
  opts?: { readonly lowercase?: boolean }
): string {
  if (isNumber(value)) {
    return humanize(value.toString());
  }
  if (isString(value)) {
    if (opts?.lowercase === true) {
      return camelToLowercaseWords(value);
    }
    return camelToCapitalWords(value);
  }
  return ``;
}

/**
 * @param target
 */
function removeAllWhitespace(target: string): string {
  return target.replace(/ /g, ``);
}

/**
 * @param email
 */
function maskEmailAddress(email?: string): string {
  const fallback = `your email address`;
  if (isString(email)) {
    const splitEmail = email.split(`@`);
    const [prefix, suffix] = splitEmail;
    if (isNullish(prefix) || isNullish(suffix)) {
      return fallback;
    }
    const numStars = prefix.length - 1;
    const firstChar = prefix.substr(0, 1);
    return [firstChar, ...range(numStars).map(() => `*`), `@`, suffix].join(``);
  }
  return fallback;
}

/**
 * Pluralizes a word if given a count greater than 1
 * @param args
 * @param args.word
 * @param args.count
 * @example
 * // returns 'taco'
 * pluralize('taco', 1)
 * @example
 * // returns 'tacos'
 * pluralize('tacos', 2)
 */
function pluralize({
  word,
  count
}: {
  readonly word: string;
  readonly count?: number | null;
}): string {
  const addS = word.toLowerCase().endsWith(`s`) ? `` : `s`;
  return isNumber(count) && count === 1 ? word : `${word}${addS}`;
}

/**
 * Used to display to users, geocoding, and centering the map.
 *
 * @param f - farm account with individual address fields
 * @param f.addressLine1
 * @param f.addressLine2
 * @param f.city
 * @param f.country
 * @param f.region
 * @param f.postalCode
 * @returns - address as string
 */
function formatFarmAddress({
  addressLine1,
  addressLine2,
  city,
  country,
  region,
  postalCode
}: Omit<FarmAccount, 'gpsLocation' | 'id'>): string {
  return `${addressLine1}${` ${
    addressLine2 ?? ''
  } `}${city}, ${region},  ${country} ${postalCode}`;
}

/**
 * Converts azimuth to human-readable bearing text
 * @example
 * // returns `10`\u00b0` SW
 * convertAzimuthToBearingText(100)
 *
 * @param azimuth
 */
function convertAzimuthToBearingText(azimuth: number): string {
  const quadrantAsNumber = Math.floor(azimuth / 90);
  const degreesPastQuadrant = azimuth % 90;
  /**
   * Add degrees symbol and quadrant name
   * @param quadrant
   */
  const formatString = (quadrant: string) =>
    `${degreesPastQuadrant} ${`\u00b0`} ${quadrant}`;

  switch (quadrantAsNumber) {
    case 0:
      return formatString(`NE`);
    case 1:
      return formatString(`SE`);
    case 2:
      return formatString(`SW`);
    case 3:
      return formatString(`NW`);
    default:
      throw new Error(
        `Invalid quadrant: ${quadrantAsNumber} (azimuth=${azimuth})`
      );
  }
}

/**
 * Convert azimuth value to bearing with cardinal direction and degrees
 * between zero and 90
 * @param azimuth
 */
function humanizeAzimuth(azimuth: number): string {
  const rounded = Math.round(azimuth);
  switch (rounded) {
    case 0:
    case 360:
      return `Due North`;
    case 90:
      return `Due East`;
    case 180:
      return `Due South`;
    case 270:
      return `Due West`;
    default: {
      return convertAzimuthToBearingText(rounded);
    }
  }
}
/**
 * Transform user action permission key to either capitalized or
 * lower-cased human-readable words.
 *
 * @param key - name of permission
 * @returns - the key capitalized with the word 'can' removed
 */
function humanizeFarmUsePermission(key: string): string {
  return replace(humanize(key), `Can`, ``);
}

export {
  humanize,
  humanizeAzimuth,
  humanizeFarmUsePermission,
  camelToLowercaseWords,
  pluralize,
  formatFarmAddress,
  maskEmailAddress,
  removeAllWhitespace,
  addParens
};
