import { createSelector } from '@reduxjs/toolkit';
import { Geo } from 'src/geo';
import { isNullish } from 'src/types';
import { getAppIsLoaded, User } from 'src/userSession.reducer';
import { FarmFields } from './farmFields.reducer';

export default createSelector(
  getAppIsLoaded,
  User.getFarmLocationGmaps,
  FarmFields.selectAll,
  (userData, farmGps, fields) => {
    if (userData && typeof window.google !== 'undefined') {
      const bounds = new google.maps.LatLngBounds();
      fields.forEach((field) => {
        bounds.extend(Geo.points.to.googleMaps(field.center));
      });
      if (!isNullish(farmGps)) {
        bounds.extend(farmGps);
      }
      return bounds.toJSON();
    }
    return undefined;
  }
);
