import { CardActions, CardHeader, List } from '@material-ui/core';
import React from 'react';
import { AppButton } from 'src/components';
import { NoListItems } from 'src/components/lists';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { notNullish } from 'src/types';
import { pluralize } from 'src/utilities';
import type { DeviceIdProp, DeviceNameProp, DevicePair } from '../models';
import {
  Triggers,
  useSelectedPairId,
  useTriggersForDevice
} from '../triggers.reducer';
import ConfirmDeletePair from './ConfirmDeletePair';
import { CurrentPairListRow } from './CurrentPairListRow';

/**
 *
 */
function NewPairBtn(): JSX.Element {
  const dispatch = useAppDispatch();
  const handleClick = () => dispatch(Triggers.clickNewDevicePair());

  return (
    <AppButton
      iconKey="ADD_ITEM"
      id={testIds.devicePairing.initBtn}
      onClick={handleClick}
      text="create pair"
    />
  );
}

/**
 * @param props
 * @param props.deviceId
 * @param props.deviceName
 */
export default function CurrentPairsList({
  deviceId,
  deviceName
}: DeviceIdProp & DeviceNameProp): JSX.Element {
  const triggers = useTriggersForDevice(deviceId, 'DEVICE_PAIR');
  const numPairs = triggers.length;
  const selectedPairId = useSelectedPairId();

  const [idToDelete, setIdToDelete] = React.useState<number | null>(null);

  const pairsCountText = `${deviceName} is paired with ${numPairs} ${pluralize({
    count: numPairs,
    word: 'device'
  })} `;
  if (numPairs === 0) {
    return (
      <NoListItems
        id={testIds.devicePairing.noPairs}
        title="No pairs exist for this device."
      >
        <NewPairBtn />
      </NoListItems>
    );
  }
  const testId = testIds.devicePairing.currentPairs.list;
  return (
    <React.Fragment>
      <CardHeader subheader={pairsCountText} title="Paired Devices" />

      {notNullish(idToDelete) ? (
        <ConfirmDeletePair
          idToDelete={idToDelete}
          onCancel={() => setIdToDelete(null)}
        />
      ) : null}
      <List data-cy={testId} dense id={testId}>
        {(triggers as DevicePair[]).map(({ id, ...p }, index) => (
          <CurrentPairListRow
            deviceId={deviceId}
            id={id}
            index={index}
            isSelected={selectedPairId === id}
            key={id}
            numPairs={triggers.length}
            onClickDelete={() => setIdToDelete(id)}
            {...p}
          />
        ))}
      </List>
      <CardActions>
        <NewPairBtn />
      </CardActions>
    </React.Fragment>
  );
}
