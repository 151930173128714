import type { Reducer } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import Actions from 'src/actions';

import { RequestNames } from 'src/constants';
import type { FarmUserPermissionsMap } from 'src/permissions';
import { createAsyncAppThunk } from 'src/requests';
import type { RootState } from 'src/root.reducer';
import type { PartialNullable } from 'src/types';
import asyncGetUserData from 'src/asyncGetUserData';

type JoinCodeStatus = `ACTIVE` | `EXPIRED` | `NOT_CREATED`;
type FarmJoinCode = {
  codeStr: string;
  codeStatus: JoinCodeStatus;
  expirationDate: string;
  farmId: number;
  id: number;
  permissions: FarmUserPermissionsMap;
};
const { CREATE_FARM_JOIN_CODE } = RequestNames;
const asyncCreateFarmJoinCode = createAsyncAppThunk<
  { permissions: FarmUserPermissionsMap },
  FarmJoinCode
>(CREATE_FARM_JOIN_CODE, {
  checkPermissions: 'canManageJoinCodes',
  successToast: 'Join code created.'
});
interface FarmJoinCodeState extends PartialNullable<FarmJoinCode> {
  readonly currentStatus:
    | 'NEW_CODE_SUCCESS'
    | 'SETTING_NEW_PERMISSIONS'
    | 'VIEWING_EXISTING_PERMISSIONS'
    | null;
}
const initialState: FarmJoinCodeState = {
  codeStatus: null,
  codeStr: null,
  currentStatus: null,
  expirationDate: null,
  permissions: null
};

/**
 * @param state
 */
const getFarmJoinCodeState = createStructuredSelector<
  RootState,
  FarmJoinCodeState
>({
  codeStatus: (state) => state.farmJoinCode.codeStatus,
  codeStr: (state) => state.farmJoinCode.codeStr,
  currentStatus: (state) => state.farmJoinCode.currentStatus,
  expirationDate: (state) => state.farmJoinCode.expirationDate,
  permissions: (state) => state.farmJoinCode.permissions
});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.closeDialog, (state) => {
        state.currentStatus = null;
      })
      .addCase(asyncGetUserData.pending, () => {
        return { ...initialState };
      })
      .addCase(asyncGetUserData.fulfilled, (state, action) => {
        const joinCode = action.payload.activeFarm?.farmJoinCode;
        state.codeStatus = joinCode?.codeStatus ?? null;
        state.codeStr = joinCode?.codeStr ?? null;
        state.expirationDate = joinCode?.expirationDate ?? null;
        state.permissions = joinCode?.permissions ?? null;
        state.currentStatus = null;
      })
      .addCase(
        asyncCreateFarmJoinCode.fulfilled,
        (state, { payload: joinCode }) => {
          state.codeStatus = joinCode.codeStatus;
          state.codeStr = joinCode.codeStr;
          state.expirationDate = joinCode.expirationDate;
          state.permissions = joinCode.permissions;
          state.currentStatus = 'NEW_CODE_SUCCESS';
        }
      ),
  initialState,
  name: 'farmJoinCode',
  reducers: {
    clearFjcState: (state) => {
      state.currentStatus = null;
    },
    openFjcPermissions: (state) => {
      state.currentStatus = 'VIEWING_EXISTING_PERMISSIONS';
    },
    startNewJoinCode: (state) => {
      state.currentStatus = 'SETTING_NEW_PERMISSIONS';
    }
  }
});

const { startNewJoinCode, clearFjcState, openFjcPermissions } = slice.actions;

const farmJoinCode: Reducer<FarmJoinCodeState> = slice.reducer;
export default farmJoinCode;

export type { FarmJoinCode, JoinCodeStatus };
export {
  asyncCreateFarmJoinCode,
  getFarmJoinCodeState,
  startNewJoinCode,
  clearFjcState,
  openFjcPermissions
};
