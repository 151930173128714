import type { DeepPartial, EntityState, Reducer } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import { getSelectedFieldId } from 'src/appSelectors';
import asyncGetUserData from 'src/asyncGetUserData';
import { Geo } from 'src/geo';
import { closePolygon } from 'src/geo/polygons';
import { useAppSelector } from 'src/hooks';
import { isNullish, isPositiveInt } from 'src/types';
import createEntitySelectors from 'src/utilities/createEntitySelectors';

import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import bbox from '@turf/bbox';
import { square } from '@turf/turf';

import {
  asyncArchiveFields,
  asyncCreateField,
  asyncDeleteField,
  asyncReactivateFields,
  asyncRenameField,
  asyncSetFieldRowDirection,
  asyncUpdateFieldBoundary
} from './fieldRequests.actions';

import type { DeviceEvent } from 'src/devices';
import type { GmapsPolygonPath, PointGeoJson } from 'src/geo';
import type { PolygonGeoJson } from 'src/geo/polygons';
import type { ReelRun } from 'src/reel-runs';
import type { RootState } from 'src/root.reducer';
import type { RootSelector } from 'src/store';
interface FarmField {
  boundingRadiusMeters: number;
  center: PointGeoJson;
  farmId: number;
  fieldName: string;
  id: number;
  isActive: boolean;
  polygon: PolygonGeoJson;
  rowDirectionAzimuthDegrees: number;
}
/**
 * @param a
 * @param b
 */
function sortFields(a?: DeepPartial<FarmField>, b?: DeepPartial<FarmField>) {
  const activeA = a?.isActive ?? false;
  const activeB = b?.isActive ?? false;
  const nameA = a?.fieldName ?? '';
  const nameB = b?.fieldName ?? '';
  let result = 0;
  if (activeA > activeB) {
    result = 1;
  }
  if (activeA < activeB) {
    result = -1;
  }
  if (result === 0) {
    result = nameA.localeCompare(nameB);
  }
  return result;
}

const adapter = createEntityAdapter<FarmField>({
  selectId: (field) => field.id,
  sortComparer: sortFields
});
const selectors = adapter.getSelectors((state: RootState) => state.farmFields);

/**
 * @param args
 * @param field
 * @param runs
 * @param events
 * @param args.field
 * @param args.runs
 * @param args.events
 */
function enhanceField({
  field,
  runs,
  events
}: {
  readonly field: FarmField;
  readonly runs: ReelRun[];
  readonly events: DeviceEvent[];
}) {
  const { polygon } = field;
  if (isNullish(polygon)) {
    throw new Error(`No polygon: ${JSON.stringify(polygon)}`);
  }
  const polygonCopy = {
    ...field.polygon
  };
  const bufferedPolygon = isNullish(polygonCopy)
    ? null
    : Geo.polygons.make.buffer(Geo.polygons.to.geojson(polygon));
  const runsNearField = runs.filter((run) =>
    run.fieldId === field.id
      ? true
      : bufferedPolygon
      ? Geo.polygons.containsPoint(bufferedPolygon, run.endPoint)
      : false
  );
  const isArchived = !field.isActive;

  const eventsNearField = events.filter((de) => {
    const location = de.gps?.location;
    if (isNullish(location) || !Geo.polygons.is.geojson(bufferedPolygon)) {
      return false;
    }
    return Boolean(Geo.polygons.containsPoint(bufferedPolygon, location));
  });

  return {
    ...field,
    centerGmaps: Geo.points.to.googleMaps(field.center),
    eventsNearField,
    isArchived,
    pathGmaps: Geo.polygons.to.gmaps(field.polygon),
    runsNearField
  };
}

const getRuns = createEntitySelectors((state) => state.reelRunsActive).all;
const getEvents = createEntitySelectors((state) => state.deviceEventLast).all;
const getFieldByIdCached = createCachedSelector(
  selectors.selectById,
  getRuns,
  getEvents,
  (field, runs, events) =>
    isNullish(field)
      ? undefined
      : enhanceField({
          events,
          field,
          runs
        })
)((_state, fieldId) => fieldId);

type State = EntityState<FarmField>;

/**
 * @param state
 */

const initialState: State = adapter.getInitialState({
  selectedId: null
});

// const getSelectedFieldId = (state: RootState): number | null =>
//   state.appFeature.selectedItem?.kind ;
const getSelectedFieldIdNonNullable = createSelector(
  getSelectedFieldId,
  (id) => {
    if (isNullish(id)) {
      throw new Error('No field id');
    }
    return id;
  }
);

const slice = createSlice({
  /**
   * @param builder
   */
  extraReducers: (builder) =>
    builder

      .addCase(asyncGetUserData.pending, () => ({
        ...initialState
      }))
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        if (payload.activeFarm?.farmFields) {
          adapter.upsertMany(
            state,
            payload.activeFarm.farmFields.map((field) => ({
              ...field,
              polygon: closePolygon(field.polygon)
            }))
          );
        }
      })
      .addCase(asyncCreateField.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addCase(asyncRenameField.fulfilled, (state, action) => {
        const { fieldId, fieldName } = action.meta.arg;
        const prev = state.entities[fieldId];
        if (typeof prev === 'undefined') {
          throw new Error('No field for id in upsert');
        }

        adapter.upsertOne(state, {
          ...prev,
          fieldName
        });
      })
      .addCase(
        asyncSetFieldRowDirection.fulfilled,
        (state, { meta: { arg } }) => {
          const { fieldId, value: rowDirectionAzimuthDegrees } = arg;
          adapter.updateOne(state, {
            changes: {
              rowDirectionAzimuthDegrees
            },
            id: fieldId
          });
        }
      )
      .addCase(asyncUpdateFieldBoundary.fulfilled, (state, { payload }) => {
        adapter.updateOne(state, {
          changes: {
            boundingRadiusMeters: payload.boundingRadiusMeters,
            center: { ...payload.center },
            polygon: payload.polygon
          },
          id: payload.id
        });
      })
      .addCase(asyncArchiveFields.fulfilled, (state, { meta: { arg } }) => {
        adapter.updateMany(
          state,
          arg.fieldIdList.map((id) => ({
            changes: { isActive: false },
            id
          }))
        );
      })
      .addCase(asyncReactivateFields.fulfilled, (state, { meta: { arg } }) => {
        adapter.updateMany(
          state,
          arg.fieldIdList.map((id) => ({
            changes: { isActive: true },
            id
          }))
        );
      })
      .addCase(asyncDeleteField.fulfilled, (state, action) => {
        adapter.removeOne(state, action.meta.arg.fieldId);
      }),

  initialState,
  name: `farmFields`,
  reducers: {}
});

const getSelectedField = createSelector(
  (state: RootState) => state,
  getSelectedFieldId,
  (state, fieldId) => {
    return isPositiveInt(fieldId) ? getFieldByIdCached(state, fieldId) : null;
  }
);

const useField = (fieldId: number | null) =>
  useAppSelector((state) =>
    fieldId === null ? undefined : getFieldByIdCached(state, fieldId)
  );

/**
 * @param polygon
 */
function calculateBounds(polygon: GmapsPolygonPath | PolygonGeoJson) {
  const geoJson = Geo.polygons.is.geojson(polygon)
    ? polygon
    : Geo.polygons.to.geojson(polygon);

  const boundingBox = bbox(geoJson);
  const viewBox = square(boundingBox);
  const latLngBounds = Geo.bounds.to.gmaps(viewBox);
  return latLngBounds;
}

const getLatLngBoundsForField = createCachedSelector(
  (state: RootState, id: number) => getFieldByIdCached(state, id),
  (field) => {
    if (field) {
      return calculateBounds(field.polygon);
    }
    return undefined;
  }
)((_state, id) => id);

/**
 *
 */
function useSelectedField() {
  const fieldId = useAppSelector(getSelectedFieldId);

  return useField(fieldId ?? null);
}

const FarmFields = {
  adapter,
  calculateBounds,
  enhanceField,
  getFieldByIdCached,
  getSelectedField,
  getSelectedFieldId,
  getSelectedFieldIdNonNullable,
  useField,
  useSelectedField,
  ...selectors,
  selectIds: selectors.selectIds as RootSelector<number[]>
} as const;

const farmFields: Reducer<State> = slice.reducer;
export { FarmFields, getLatLngBoundsForField, getSelectedFieldId };
export type { FarmField };
export default farmFields;
