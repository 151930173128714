import type { SerializedError } from '@reduxjs/toolkit';
import { isNullish, isPlainObj, isTruthyString } from 'src/types';
import type { ValuesType } from 'utility-types';

const USER_NOT_CONFIRMED_EXCEPTION = `UserNotConfirmedException` as const;
const NOT_AUTHORIZED_EXCEPTION = `NotAuthorizedException` as const;
const USER_ALREADY_CONFIRMED_EXCEPTION = `InvalidParameterException` as const;
const USER_NOT_FOUND_EXCEPTION = `UserNotFoundException` as const;
const CODE_MISMATCH_EXCEPTION = `CodeMismatchException` as const;
const USERNAME_EXISTS_EXCEPTION = `UsernameExistsException` as const;
const NO_CURRENT_USER = `No current user` as const;
export const INVALID_CODE_MESSAGE = `The code you entered is invalid. Please double-check and try again.`;

export const AUTH_ERROR_CODES = {
  authCheckFailed: NO_CURRENT_USER,
  codeMismatchException: CODE_MISMATCH_EXCEPTION,
  notAuthorizedException: NOT_AUTHORIZED_EXCEPTION,
  userAlreadyConfirmedException: USER_ALREADY_CONFIRMED_EXCEPTION,
  userNotConfirmedException: USER_NOT_CONFIRMED_EXCEPTION,
  userNotFoundException: USER_NOT_FOUND_EXCEPTION,
  usernameExistsException: USERNAME_EXISTS_EXCEPTION
} as const;

export type AuthErrorCodes = typeof AUTH_ERROR_CODES;
export type AuthErrorCodeValue = ValuesType<AuthErrorCodes>;
export interface AuthError<T extends AuthErrorCodeValue = AuthErrorCodeValue>
  extends SerializedError {
  readonly code: T;
  readonly message: string;
}

/**
 * @param x
 */
export function isAuthErrorCode(x: unknown): x is AuthErrorCodeValue {
  if (isTruthyString(x)) {
    if (
      Object.values(AUTH_ERROR_CODES).includes(x as ValuesType<AuthErrorCodes>)
    ) {
      return true;
    }
  }
  return false;
}
/**
 * @param x
 */
export function isAuthError(x: unknown): x is AuthError {
  if (isNullish(x)) {
    return false;
  }
  if (x === NO_CURRENT_USER) {
    return true;
  }
  if (isPlainObj(x) && 'code' in x) {
    return isAuthErrorCode(x.code);
  }
  return false;
}
