import { kebabCase } from 'lodash-es';
import React from 'react';
import { SensorNames } from 'src/devices/sensors';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import ChoiceBtn from './ChoiceBtn';
import ChoicesWrapper from './ChoicesWrapper';
import { CreateConfig } from './createConfig.reducer';
import FormWrapper from './FormWrapper';

/**
 * If the user selects 'pump' for the installation type,
 * we need to know whether the primary (only) pressure sensor
 * is an analog pressure sensor or a pressure switch sensor
 *
 */
export default function ChoosePumpType(): JSX.Element {
  const dispatch = useAppDispatch();

  const PUMP_TYPES = [
    SensorNames.pressure,
    SensorNames.pressureSwitch
  ] as const;
  return (
    <FormWrapper
      instructions="What type of sensor is installed on the pump?"
      testId={testIds.createConfiguration.choosePumpType}
    >
      <ChoicesWrapper>
        {PUMP_TYPES.map((sn) => (
          <ChoiceBtn
            id={kebabCase(sn)}
            key={sn}
            onClick={() => dispatch(CreateConfig.submitPumpType(sn))}
            text={SensorNames.humanize(sn)}
          />
        ))}
      </ChoicesWrapper>
    </FormWrapper>
  );
}
