const REEL_RUN_OBSERVATION_REPORT_KEY = {
  applicationRateEstimatedMm: `applicationRateEstimatedMm`,
  distanceObservedMm: `distanceObservedMm`,
  flowRateEstimatedLpm: `flowRateEstimatedLpm`,
  pressureObservedKpa: `pressureObservedKpa`,
  speedObservedMmpm: `speedObservedMmpm`
} as const;
const RUN_OBSERVATION_REPORT_KEYS = [
  REEL_RUN_OBSERVATION_REPORT_KEY.distanceObservedMm,
  REEL_RUN_OBSERVATION_REPORT_KEY.speedObservedMmpm,
  REEL_RUN_OBSERVATION_REPORT_KEY.pressureObservedKpa,
  REEL_RUN_OBSERVATION_REPORT_KEY.flowRateEstimatedLpm,
  REEL_RUN_OBSERVATION_REPORT_KEY.applicationRateEstimatedMm
] as const;
type ReelRunObservationKey = typeof RUN_OBSERVATION_REPORT_KEYS[number];
type ReelRunObservationReport = {
  readonly [key in ReelRunObservationKey]: number | null;
};
export default {
  keys: RUN_OBSERVATION_REPORT_KEYS,
  ...REEL_RUN_OBSERVATION_REPORT_KEY
};
export type { ReelRunObservationKey, ReelRunObservationReport };
