const GREEN = {
  dark: `rgb(0, 101, 91)`,
  light: `rgb(51,  159, 148)`,
  main: `rgb(0, 135, 121)`
};
const BASE_COLORS = {
  black: `rgb(20,20,20)`,
  blue: {
    dark: `rgb(0, 19, 113)`,
    light: `rgb(51, 147, 171)`,
    main: `rgb(0, 120, 150)`
  },
  charcoal: `rgb(45,40,40)`,
  firebrick: `firebrick`,
  gold: `gold`,
  green: GREEN,
  lightGray: `rgb(250, 245, 245)`,
  orange: `orange`,
  orangeRed: `orangeRed`,
  red: {
    main: `rgb(183, 48, 48)`
  },
  success: {
    main: GREEN.light
  },
  tomato: `tomato`,
  warning: {
    dark: `rgb(229, 152, 40)`,
    light: `rgb(229, 152, 40)`,
    main: `rgb(229, 152, 40)`
  },
  white: `rgb(255, 250, 250)`,
  yellow: {
    dark: `rgb(172, 143, 24)`,
    light: `rgb(234, 203, 77)`,
    main: `rgb(229,190,32)`
  }
} as const;

const COMPONENT_STYLES = {
  fields: {
    archived: `rgba(50,50,50, .4)`,
    newField: {
      color: `rgba(255, 200, 100, .8)`
    },
    polygon: {
      color: BASE_COLORS.gold,
      stroke: `rgb(50,50,50)`
    },
    rowDirectionLine: {
      color: `rgba(3,202,252,0.9)`
    },
    swathe: {
      color: `rgba(3, 202, 252, 0.75)`
    }
  }
  // pump: {
  //   color: BASE_COLORS.blue.main
  // },
  // reel: {
  //   color: BASE_COLORS.green.light
  // },
} as const;

// const useThemePalette = () => PALETTE;
const FIELD_COLOR_CHOICES = [
  BASE_COLORS.red.main,
  BASE_COLORS.orangeRed,
  BASE_COLORS.tomato,
  BASE_COLORS.orange,
  BASE_COLORS.gold
  // BASE_COLORS.yellow.main
] as const;
export { FIELD_COLOR_CHOICES, BASE_COLORS, COMPONENT_STYLES };
