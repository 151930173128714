import type { ListItemProps } from '@material-ui/core';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { humanizeFarmUsePermission } from 'src/utilities';
import type { FarmUserPermissionKey } from './FarmUserPermissions';

type Props = {
  readonly perm: FarmUserPermissionKey;
  readonly isEnabled: boolean;
};

/**
 * @param props
 * @param props.perm
 * @param props.isEnabled
 * @param props.rest
 */
export default function PermissionListItem({
  isEnabled,
  perm,
  ...rest
}: ListItemProps & Props): JSX.Element {
  const id = testIds.permissionListItem(perm, isEnabled);
  return (
    <ListItem
      {...rest}
      button={false}
      data-cy={id}
      id={id}
      selected={isEnabled}
    >
      <ListItemText primary={humanizeFarmUsePermission(perm)} />
      <ListItemIcon>
        <AppIcon
          color={isEnabled ? 'primary' : 'disabled'}
          iconKey={isEnabled ? 'CHECK_MARK' : 'DISABLED'}
        />
      </ListItemIcon>
    </ListItem>
  );
}
