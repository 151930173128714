import type { AlertProps } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import React from 'react';
import testIds from 'src/testIds';
import AppIcon from 'src/theme/AppIcon';
import type { HasIconKey } from '../types';
type Props = AlertProps & HasIconKey;

/**
 * @param props
 * @param props.dataCy
 * @param props.severity
 * @param props.id
 * @param props.rest
 * @param props.iconKey
 */
function Attention({
  severity = 'info',
  id = 'attention',
  iconKey,
  ...rest
}: Props): JSX.Element {
  const testId = testIds.attention;
  return (
    <Alert
      data-cy={id ?? testId}
      icon={iconKey ? <AppIcon iconKey={iconKey} /> : undefined}
      id={id ?? testId}
      severity={severity}
      {...rest}
    />
  );
}
/**
 * @param props
 * @param props.dataCy
 * @param props.rest
 * @param props.id
 */
function Instructions({ id = 'instructions', ...rest }: Props): JSX.Element {
  return <Attention id={id ?? testIds.attention} {...rest} />;
}

/**
 * @param props
 * @param props.dataCy
 * @param props.rest
 * @param props.id
 */
function Success({ id = 'success', ...rest }: Props): JSX.Element {
  return <Attention id={id ?? testIds.success} severity="success" {...rest} />;
}
/**
 * @param props
 * @param props.dataCy
 * @param props.rest
 * @param props.id
 */
function Warning({ id = 'warning', ...rest }: Props): JSX.Element {
  return <Attention id={id ?? testIds.warning} severity="warning" {...rest} />;
}

export default Attention;
export { Warning, Success, Instructions };
