import { CardActionArea, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getLastEventLocationById } from 'src/devices/selectors';
import { getFieldForDeviceEventCached } from 'src/fields';
import Actions from '../actions';
import type { DeviceIdProp } from '../devices';
import { SignalStrengthIndicator } from '../devices';
import FieldIconMemoized from '../fields/FieldIcon';
import { useAppDispatch } from '../hooks';
import AppIcon from '../theme/AppIcon';
import { isNullish } from '../types';

/**
 * @param props
 * @param props.deviceId
 */
export function DeviceInField({ deviceId }: DeviceIdProp): JSX.Element {
  const dispatch = useAppDispatch();
  const inField = useSelector((state) =>
    getFieldForDeviceEventCached(state, deviceId)
  );
  const location = useSelector((state) =>
    getLastEventLocationById(state, deviceId)
  );
  if (isNullish(location)) {
    return <SignalStrengthIndicator deviceId={deviceId} />;
  }
  let text = 'Field Unknown';
  let icon: React.ReactNode = <AppIcon iconKey="support" />;
  let handleClick: (() => void) | undefined = undefined;
  let Wrapper: React.FC<{ onClick: any }> = Paper;
  if (!isNullish(inField)) {
    const { id, fieldName: name, polygon, inField: properly } = inField;
    text = `${properly ? 'In' : 'Near'} field ${name}`;
    icon = <FieldIconMemoized polygon={polygon} />;
    Wrapper = CardActionArea;

    handleClick = () => dispatch(Actions.selectItem({ id, kind: 'field' }));
  }

  return (
    <Wrapper onClick={() => handleClick && handleClick()}>
      <Typography
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
        variant="caption"
      >
        <span
          style={{
            marginRight: 5
          }}
        >
          {text}
        </span>
        <span>{icon}</span>
      </Typography>
    </Wrapper>
  );
}
