import type { SvgIconProps } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { ReactComponent as BarnIcon } from './icon/Barn.svg';

/**
 * @param props
 */
export default function Barn(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <BarnIcon />
    </SvgIcon>
  );
}
