import classNames from 'classnames';
import { isString } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import Actions from 'src/actions';
import { useIsAnyRequestPending } from 'src/appSelectors';
import { useIsMobile } from 'src/hooks';
import useAppDispatch from 'src/hooks/useAppDispatch';
import useAppSelector from 'src/hooks/useAppSelector';
import testIds from 'src/testIds';
import AppIcon from 'src/theme/AppIcon';
import { isArrayOrReadonlyArray, isNullish } from 'src/types';
import { denyPermission, getActiveLoader, User } from 'src/userSession.reducer';
import { appLogger } from 'src/utilities';

import {
  Button,
  CircularProgress,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import type { ButtonProps } from '@material-ui/core';
import type { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import type {
  ButtonPresetKey,
  CanBeLink,
  ChecksPermission,
  HasIconKey,
  HasText,
  HideableProps,
  ShowsLoader
} from 'src/components/types';

const styles = {
  label: {
    display: 'flex',
    flex: 1
  },
  root: {
    display: 'flex'
    // flex: 1,
  }
} as const;

interface EnhancedButtonProps
  extends CanBeLink,
    ChecksPermission,
    HasIconKey,
    HasText,
    HideableProps,
    ShowsLoader,
    Pick<
      ButtonProps,
      | 'children'
      | 'classes'
      | 'className'
      | 'color'
      | 'disabled'
      | 'fullWidth'
      | 'id'
      | 'onClick'
      | 'size'
      | 'style'
      | 'TouchRippleProps'
      | 'type'
      | 'variant'
    > {
  responsiveSize?: boolean;
  readonly presetKey?: ButtonPresetKey;
  helpText?: string[] | string | readonly string[];
  icon?: React.ReactNode;
}

type ButtonPreset = Pick<
  EnhancedButtonProps,
  'color' | 'iconKey' | 'id' | 'text' | 'type' | 'variant'
> & {
  readonly action?: ActionCreatorWithOptionalPayload<any>;
};
const ACTION: ButtonPreset = {
  id: 'submit-btn',
  text: 'submit',
  variant: 'contained'
} as const;
const CANCEL: ButtonPreset = {
  action: Actions.clickCancel,
  id: 'cancel-btn',
  // iconKey: 'CANCEL',
  text: 'cancel',
  variant: 'text'
};
const CLOSE_DIALOG: ButtonPreset = {
  ...CANCEL,
  action: Actions.closeDialog
};
const DONE: ButtonPreset = {
  action: Actions.clickDone,
  id: 'done-btn',
  iconKey: 'CHECK_MARK',
  text: 'done',
  variant: 'contained'
};
const REFRESH: ButtonPreset = {
  iconKey: 'REFRESH',
  text: 'reload',
  variant: 'contained'
};
const SUBMIT: ButtonPreset = {
  ...ACTION,
  // iconKey: 'CHECK_MARK',
  type: 'submit'
};
const BUTTON_PRESET: {
  readonly [key in ButtonPresetKey]: ButtonPreset;
} = {
  ACTION: { ...ACTION },
  CANCEL,
  CLOSE_DIALOG,
  DONE,
  REFRESH,
  SUBMIT
};

/**
 * Base button for app.
 *
 * If responsive is true, and an icon is provided as a prop,
 * shrinks itself to an icon on mobile. Can enable or disable
 * full-width at theme breakpoints (descending).
 *
 * 'text' props overrides children. Responsive icon takes precedence over both
 * @param props
 * @param ref
 */
const AppButton = React.forwardRef<any, EnhancedButtonProps>((props, ref) => {
  const {
    children,
    classes,
    className,
    color,
    disabled,
    fullWidth,
    helpText,
    iconKey,
    iconPosition = 'left',
    id,
    isHidden,
    icon,
    linkTo,
    responsiveSize,
    onClick,
    onFailedPermissionCheck,
    presetKey,
    requiredPermissions,
    showLoading,
    size,
    text,
    TouchRippleProps,
    type,
    variant
    // ...rest
  } = props;

  let disableClick = false;
  const dispatch = useAppDispatch();
  const presetProps = presetKey ? BUTTON_PRESET[presetKey] : undefined;
  const buttonType = type ?? presetProps?.type;

  let tooltipMessage: string[] | string | readonly string[] | undefined =
    helpText;
  const permissionCheck = User.useHasPermissions(requiredPermissions);

  if (
    permissionCheck.code === 'DENIED' &&
    onFailedPermissionCheck === 'DISABLE_ELEMENT'
  ) {
    disableClick = true;
    tooltipMessage = 'You do not have permission to perform this action';
  }
  const isAnyRequestPending = useIsAnyRequestPending();
  const history = useHistory();
  const theme = useTheme();

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (process.env.NODE_ENV === 'development') {
        appLogger.log('in AppButton.tsx', id);
      }
      if (permissionCheck.code === 'DENIED') {
        appLogger.warn('Permission denied for click handler');
        dispatch(denyPermission(permissionCheck));
      } else {
        if (buttonType !== 'submit' && !isNullish(linkTo)) {
          e.preventDefault();
        }
        if (presetProps?.action) {
          dispatch(presetProps.action());
        }
        if (!isNullish(linkTo)) {
          history.push(linkTo);
        }
        if (onClick) {
          return onClick(e);
        }
      }
      return e;
    },
    [
      buttonType,
      dispatch,
      history,
      id,
      linkTo,
      onClick,
      permissionCheck,
      presetProps
    ]
  );
  // * ICON * //
  const isMobile = useIsMobile();
  const fullWidthQuery = useMediaQuery(
    theme.breakpoints.down(isString(fullWidth) ? fullWidth : `xs`)
  );

  const otherLoaderIsOpen = useAppSelector(
    (state) => !isNullish(getActiveLoader(state))
  );
  const testId = id ?? text;

  if (isHidden === true) {
    return null;
  }

  const btnText = text ?? presetProps?.text ?? children;

  const usedIconKey = iconKey ?? presetProps?.iconKey;
  const iconEl =
    icon ?? (usedIconKey ? <AppIcon iconKey={usedIconKey} /> : null);
  const loaderIsVisible = showLoading === true && !otherLoaderIsOpen;
  const loadingIndicator = (
    <CircularProgress color="inherit" id="loading-indicator" />
  );

  const buttonColor =
    color ?? (variant === 'text' ? 'default' : presetProps?.color);

  const buttonElement = (
    <Button
      TouchRippleProps={TouchRippleProps}
      className={classNames(className, styles.root)}
      classes={classes}
      color={buttonColor}
      data-cy={id ?? id ?? presetProps?.id ?? 'btn'}
      disabled={
        disabled === true ||
        isAnyRequestPending ||
        loaderIsVisible ||
        disableClick
      }
      endIcon={
        iconPosition === 'right'
          ? showLoading === true
            ? loadingIndicator
            : iconEl
          : null
      }
      fullWidth={isString(fullWidth) ? fullWidthQuery : fullWidth}
      href={linkTo ?? undefined}
      id={testId}
      onClick={handleClick}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ref={ref}
      size={responsiveSize === true && isMobile ? 'small' : size}
      startIcon={
        iconPosition === 'left'
          ? showLoading === true
            ? loadingIndicator
            : iconEl
          : null
      }
      style={styles.root}
      type={buttonType}
      variant={variant ?? presetProps?.variant}
    >
      {children ?? btnText}
    </Button>
  );

  tooltipMessage = isArrayOrReadonlyArray(tooltipMessage)
    ? tooltipMessage.join('')
    : tooltipMessage;
  if (typeof tooltipMessage === 'undefined') {
    return buttonElement;
  }
  return (
    <Tooltip title={tooltipMessage}>
      <span>{buttonElement}</span>
    </Tooltip>
  );
});

/**
 * Enhanced button with default props
 * @param props
 * @param props.id
 * @param props.text
 * @param props.variant
 * @param props.rest
 * @param props.onClick
 */
function CancelBtn({
  id,
  text = 'cancel',
  onClick,
  variant = 'outlined',
  ...rest
}: EnhancedButtonProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      (e) => (onClick ? onClick(e) : dispatch(Actions.clickCancel())),
      [dispatch, onClick]
    );
  return (
    <AppButton
      id={id ?? testIds.cancelBtn}
      onClick={handleClick}
      text={text}
      variant={variant}
      {...rest}
    />
  );
}
/**
 * Enhanced button with default props
 * @param props
 * @param props.id
 * @param props.text
 * @param props.variant
 * @param props.rest
 * @param props.type
 */
function SubmitBtn({
  id,
  text = 'submit',
  variant = 'contained',
  type = 'submit',
  ...rest
}: EnhancedButtonProps): JSX.Element {
  return (
    <AppButton
      id={id ?? testIds.submitBtn}
      text={text}
      type={type}
      variant={variant}
      {...rest}
    />
  );
}
export type { EnhancedButtonProps };
export { SubmitBtn, CancelBtn };
AppButton.displayName = 'ButtonEnhanced';
export default AppButton;
