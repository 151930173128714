import { Box, createStyles, makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import type { RouteChildrenProps } from 'react-router';
import { toast } from 'react-toastify';
import type { FarmFormData } from 'src/account-settings/asyncCreateFarm';
import { asyncCreateFarm } from 'src/account-settings/asyncCreateFarm';
import { AppFab, AppTextField } from 'src/components';
import { FORM_VALIDATION } from 'src/constants';
import { useAppDispatch, useAppSelector, useIsMobile } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { isTruthyString } from 'src/types';
import { useIsPending } from 'src/userSession.reducer';
import type { CountryRegion } from 'src/utilities';
import { FARM_FORM_DATA_KEYS, humanize } from 'src/utilities';
import { isFalsy } from 'utility-types';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography, shape }) => {
    const isDark = palette.type === 'dark';
    const isMobile = breakpoints.down('xs');

    return createStyles({
      country: {},
      countryRegion: {
        border: 0,
        borderColor: palette.grey[isDark ? 700 : 400],
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: shape.borderRadius,
        backgroundColor: palette.background.default,
        color: palette.text.secondary,
        flex: 1,
        fontSize: typography.pxToRem(typography.fontSize + 2),
        [isMobile]: {
          maxWidth: '100%',
          minWidth: '100%'
        },
        maxWidth: '45%',
        minWidth: '45%',
        padding: spacing(1)
      },
      countryRegionContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: spacing(2)
      },
      region: {
        [isMobile]: {
          marginLeft: spacing(0),
          marginTop: spacing(2)
        },
        marginLeft: spacing(2)
      }
    });
  }
);

const PRIORITY_OPTIONS = ['US', 'MX', 'CA'];
const initialCountryRegion = {
  country: `US`,
  region: ``
};
/**
 * New user creates their farm account (if they are the first user of our system)
 * @param props
 * @param props.history
 */
function CreateFarm({ history }: RouteChildrenProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [{ country, region }, setCountryRegion] =
    React.useState<CountryRegion>(initialCountryRegion);

  const handleChangeCountryRegion = React.useCallback(
    (key: 'country' | 'region') =>
      (value: string): void =>
        setCountryRegion(
          (prevState): CountryRegion =>
            key === `country`
              ? { country: value, region: `` }
              : { ...prevState, region: value }
        ),
    []
  );

  const form = useForm<FarmFormData>();
  const result = useAppSelector((state) => state.userSession.farmStatus);
  const isSuccess = result === 'USER_CREATED_FARM';
  const requestPending = useIsPending(asyncCreateFarm);
  const countryRegionLabelLength = useIsMobile() ? 'short' : undefined;
  const classes = useStyles();

  React.useEffect(() => {
    if (isSuccess) {
      Sentry.captureMessage(`New farm account created!`);
    }
  }, [isSuccess]);

  const disableSubmit = requestPending || isFalsy(country) || isFalsy(region);
  const onSubmit = async (values: FarmFormData): Promise<void> => {
    await dispatch(asyncCreateFarm({ ...values, country, region }))
      .then(() => {
        toast.success('Farm Accont created');
        form.reset();
        history.push('/');
      })
      .catch(handleApiRequestError);
  };
  const Ids = testIds.createFarm;
  return (
    <div id={Ids.form}>
      {isSuccess ? null : (
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {FARM_FORM_DATA_KEYS.map((key) => (
            <Box key={key} mt={2}>
              <AppTextField
                InputProps={{ id: `input-${key}` }}
                errorMessage={form.errors[key]?.message}
                fullWidth
                id={key}
                inputRef={form.register(
                  key === 'addressLine2'
                    ? {}
                    : {
                        required: {
                          message: FORM_VALIDATION.required,
                          value: true
                        }
                      }
                )}
                key={key}
                label={humanize(key)}
                name={key}
                size="small"
                variant="outlined"
              />
            </Box>
          ))}
          <div className={classes.countryRegionContainer}>
            <CountryDropdown
              classes={classNames(classes.countryRegion, classes.country)}
              data-cy={Ids.countrySelect}
              disabled={requestPending}
              id={Ids.countrySelect}
              labelType={countryRegionLabelLength}
              name="country"
              onChange={handleChangeCountryRegion('country')}
              priorityOptions={PRIORITY_OPTIONS}
              value={country}
              valueType="short"
            />
            <RegionDropdown
              classes={classNames(classes.countryRegion, classes.region)}
              country={country}
              countryValueType="short"
              data-cy={Ids.regionSelect}
              disabled={requestPending || !isTruthyString(country)}
              id={Ids.regionSelect}
              labelType={countryRegionLabelLength}
              name="region"
              onChange={handleChangeCountryRegion('region')}
              value={region}
              valueType="short"
            />
          </div>
          <Box display="flex" justifyContent="center" mt={2}>
            <AppFab disabled={disableSubmit} id={Ids.submitBtn}>
              Submit
            </AppFab>
          </Box>
        </form>
      )}
    </div>
  );
}

export default CreateFarm;
