import type { EntityState, PayloadAction, Reducer } from '@reduxjs/toolkit';
import Actions from 'src/actions';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import { DEMO_DATA } from 'src/demo';
import { createAsyncAppThunk } from 'src/requests';
import { notNullish } from 'src/types';

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import type { ReelRun } from 'src/reel-runs';
import type { RootState } from 'src/root.reducer';
import type { ListRes } from 'src/types';
import type { MinDateMaxDate } from 'src/utilities/getTimedelta';

type Args = MinDateMaxDate & {
  readonly fieldId: number;
};

const { GET_REEL_RUN_HISTORY_FOR_FIELD } = RequestNames;
export const asyncGetReelRunsForField = createAsyncAppThunk<
  Args,
  ListRes<ReelRun>
>(GET_REEL_RUN_HISTORY_FOR_FIELD, {
  getDemoData: ({ fieldId }, res, state) => {
    const fieldName = state.farmFields.entities[fieldId]?.fieldName;
    if (typeof fieldName === 'string') {
      const data = DEMO_DATA.fieldRunHistory[fieldName];
      // console.log(DEMO_DATA.fieldRunHistory, fieldName, data);
      if (notNullish(data)) {
        return { items: data };
      }
    }
    return res;
  }
});

interface State extends EntityState<ReelRun> {
  selectedId?: number;
  isInitialLoad: boolean;
  shouldFetch: boolean;
}
const adapter = createEntityAdapter<ReelRun>({
  selectId: (run) => run.reelRunId
});
const initialState: State = adapter.getInitialState({
  hideFieldIds: [],
  isInitialLoad: true,
  shouldFetch: true
});
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.selectItem, (state, action) => {
        if (action.payload.kind === 'field') {
          state.shouldFetch = true;
        }
      })
      .addCase(asyncGetUserData.pending, () => ({
        ...initialState
      }))
      .addCase(asyncGetReelRunsForField.pending, (state) => {
        state.shouldFetch = false;
        adapter.removeAll(state);
      })
      .addCase(asyncGetReelRunsForField.fulfilled, (state, { payload }) => {
        state.isInitialLoad = false;
        adapter.upsertMany(state, [...payload.items]);
      }),
  initialState,
  name: `fieldRunHistory`,
  reducers: {
    cleanUp: (state) => {
      state.shouldFetch = false;
    },

    onLoad: (state) => {
      state.shouldFetch = true;
    },

    refreshRuns: (state) => {
      state.shouldFetch = true;
      state.isInitialLoad = false;
    },
    /**
     * @param state
     * @param props
     * @param props.payload
     */
    clickRun(state, { payload }: PayloadAction<number>) {
      if (state.selectedId === payload) {
        state.selectedId = undefined;
      } else {
        state.selectedId = payload;
      }
    }
  }
});

const { actions } = slice;

const getState = (state: RootState) => state.fieldRunHistory;
const { selectAll, selectIds, selectById } = adapter.getSelectors(getState);

const RunHistory = {
  selectAll,
  selectById,
  selectIds,
  ...actions
};
const fieldRunHistory: Reducer<State> = slice.reducer;
export { RunHistory };

export default fieldRunHistory;
