import { isString } from 'lodash-es';
import { RequestNames } from 'src/constants';
import { createAsyncAppThunk, makeApiRequest } from 'src/requests';
import { humanizeAzimuth } from 'src/utilities';

import { createAsyncThunk } from '@reduxjs/toolkit';
import cleanCoords from '@turf/clean-coords';

import { Geo } from '../geo';
import { isNum } from '../types';

import type { Polygon } from '@turf/helpers';
import type { PolygonGeoJson } from '../geo/polygons';
import type { RootThunkApi } from '../store';
import type { FarmField } from './farmFields.reducer';

const {
  RENAME_FIELD,
  CHANGE_FIELD_STATUS,
  CREATE_FIELD,
  DELETE_FARM_FIELD,
  ACTIVATE_FIELDS,
  ARCHIVE_FIELDS,
  SET_FIELD_ROW_DIRECTION,
  UPDATE_FIELD_BOUNDARY
} = RequestNames;

export const asyncReactivateFields = createAsyncAppThunk<{
  fieldIdList: number[];
}>(ACTIVATE_FIELDS, {
  checkPermissions: 'canManageFields',
  routeNameOverride: CHANGE_FIELD_STATUS,
  staticArgs: { isActive: true }
});
export const asyncArchiveFields = createAsyncAppThunk<{
  fieldIdList: number[];
}>(ARCHIVE_FIELDS, {
  checkPermissions: 'canManageFields',
  routeNameOverride: CHANGE_FIELD_STATUS,
  staticArgs: { isActive: false }
});
export const asyncRenameField = createAsyncAppThunk<
  { readonly fieldId: number; readonly fieldName: string },
  FarmField
>(RENAME_FIELD, {
  checkPermissions: 'canManageFields',
  successToast: 'Success.'
});
export const asyncDeleteField = createAsyncAppThunk<{ fieldId: number }>(
  DELETE_FARM_FIELD,
  { checkPermissions: 'canManageFields', successToast: 'Deleted field.' }
);
export const asyncSetFieldRowDirection = createAsyncAppThunk<
  {
    readonly fieldId: number;
    readonly value: number;
  },
  FarmField
>(SET_FIELD_ROW_DIRECTION, {
  checkPermissions: 'canManageFields',
  successToast: ({ arg: { value } }) =>
    `Set field row direction to ${humanizeAzimuth(value)}`
});
export const asyncUpdateFieldBoundary = createAsyncAppThunk<
  { readonly fieldId: number; readonly polygon: Polygon },
  FarmField
>(UPDATE_FIELD_BOUNDARY, {
  checkPermissions: 'canManageFields',
  successToast: 'Boundary updated for field'
});
const asyncCreateField = createAsyncThunk<FarmField, void, RootThunkApi>(
  CREATE_FIELD,
  async (_, { getState }) => {
    const state = getState();
    const args = state.createField;
    try {
      const { fieldName } = args;
      const { rowDirectionAzimuthDegrees } = args;
      const asGeoJson = Geo.polygons.to.geojson(args.path);
      const cleaned = cleanCoords(asGeoJson, { mutate: false }) as unknown;

      if (!isNum(rowDirectionAzimuthDegrees) || !isString(fieldName)) {
        throw new Error('Incomplete args');
      }

      type Args = {
        fieldName: string;
        polygon: PolygonGeoJson;
        rowDirectionAzimuthDegrees: number;
      };

      return await makeApiRequest<FarmField, Args>(
        CREATE_FIELD,

        {
          fieldName,
          polygon: Geo.polygons.is.geojson(cleaned) ? cleaned : asGeoJson,
          rowDirectionAzimuthDegrees
        }
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export { asyncCreateField };
