import type { SensorFormattingOptions } from 'src/devices/sensors';
import { SensorNames, SensorStates } from 'src/devices/sensors';
import humanizeSensorStateValue from 'src/devices/sensors/humanizeSensorStateValue';
import { notNullish } from 'src/types';
import type { TriggerProperties } from './models';
import RelayActions from './RelayAction';

interface TriggerFormattingOptions extends SensorFormattingOptions {
  includeAction?: boolean;
  includeCondition?: boolean;
  includeNotify?: boolean;
  formatStyle?: 'default' | 'shortHand';
}

/**
 *
 * @param t
 * @param formatValue
 * @param options
 */
export default function formatTrigger(
  t: Omit<TriggerProperties, 'notificationString'> & {
    targetDeviceName?: string;
  },
  options?: TriggerFormattingOptions
): string {
  const {
    includeNotify = true,
    includeCondition = true,
    formatStyle = 'default',
    includeAction = true,
    labelLength = 'short'
  } = options ?? {};
  let result = '';
  if (formatStyle === 'shortHand') {
    const {
      sourceSensor,
      sourceSensorStateCurrent,
      sourceSensorStatePrevious
    } = t;

    result = `
    ${SensorNames.humanize(sourceSensor, { labelLength })}:
    ${SensorStates.humanize(sourceSensorStatePrevious)[labelLength]} ->
    ${SensorStates.humanize(sourceSensorStateCurrent)[labelLength]}
    `;
  } else {
    const notify = t.notify === true;

    let actionClause = '';
    let notifyClause = '';
    let conditionClause = '';

    if (includeCondition) {
      conditionClause = `
      When the 
      ${SensorNames.humanize(t.sourceSensor).toLocaleLowerCase()} sensor
      changes from 
      ${humanizeSensorStateValue(t.sourceSensorStatePrevious)[
        labelLength
      ].toLocaleUpperCase()} to 
      ${humanizeSensorStateValue(t.sourceSensorStateCurrent)[
        labelLength
      ].toLocaleUpperCase()}`;
    }
    let targetDeviceClause = '';
    if (notNullish(t.targetDeviceName)) {
      targetDeviceClause = `${t.targetDeviceName} should`;
    }
    if (includeAction && t.targetAction) {
      actionClause = `, ${targetDeviceClause} ${RelayActions.humanize(
        t.targetAction
      )}`;
    }
    if (includeNotify && notify) {
      notifyClause = 'send a notification';
      if (actionClause !== '') {
        notifyClause = `, and ${notifyClause}`;
      }
    }
    result = `${conditionClause}${actionClause} ${notifyClause}`;
  }
  return result.replace(/\s+/g, ' ').trim();
}
