import { isNumber } from 'lodash';
import { isNullish, isTruthyString } from 'src/types';
import { humanizeAzimuth } from 'src/utilities';
type FieldLabelArg = {
  readonly fieldName: string;
  readonly azimuth?: number | null;
  readonly isArchived?: boolean;
};

/**
 * @param args
 * @param args.fieldName
 * @param args.azimuth
 * @param args.isArchived
 */
export default function createFieldLabel({
  fieldName,
  azimuth,
  isArchived
}: FieldLabelArg): string {
  if (isNullish(fieldName)) {
    return ``;
  }
  const bearing = isNumber(azimuth) ? humanizeAzimuth(azimuth) : null;
  const label = `${fieldName} ${isTruthyString(bearing) ? `(${bearing})` : ``}`;
  if (isArchived === true) {
    return `${label} (ARCHIVED)`;
  }
  return label;
}
