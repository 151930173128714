import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import { CancelBtn, SubmitBtn } from 'src/components';
import { useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { notNullish } from 'src/types';
import { asyncDeleteTriggers } from '../actions';

/**
 * @param props
 * @param props.idToDelete
 * @param props.onCancel
 */
export default function ConfirmDeletePair({
  idToDelete,
  onCancel
}: {
  idToDelete: number;
  onCancel: () => void;
}): JSX.Element {
  const { sendRequest, isPending } = useThunk(asyncDeleteTriggers);
  const handleSubmit = () =>
    notNullish(idToDelete) &&
    sendRequest({ triggerIds: [idToDelete] })
      .then(() => {
        showSuccessToast();
        onCancel();
      })
      .catch(handleApiRequestError);
  const id = testIds.devicePairing.confirmDelete.root;
  return (
    <Dialog data-cy={id} id={id} open={notNullish(idToDelete)}>
      <DialogTitle>Confirm Delete Pair</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this device pair ?
      </DialogContent>
      <DialogActions>
        <CancelBtn disabled={isPending} onClick={onCancel} />
        <SubmitBtn onClick={handleSubmit} showLoading={isPending} />
      </DialogActions>
    </Dialog>
  );
}
