/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ValuesType } from 'utility-types';

export const DEPLOYMENT_KEYS = {
  beta: 'BETA',
  dev: 'DEV',
  prod: 'PROD',
  test: 'TEST',
  demo: 'DEMO'
} as const;
export type UrlOverride = ValuesType<typeof DEPLOYMENT_KEYS>;

export const LOCALHOST_URL = `http://localhost:8000` as const;
export const BASE_URL =
  `https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com` as const;
// const LATEST_URL =
//   `https://3rknbjtgn8.execute-api.us-west-2.amazonaws.com` as const;

export type BaseUrl =
  | typeof LOCALHOST_URL
  | `${typeof BASE_URL}/live/${UrlOverride}`;
/**
 * @param target
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
