import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedDeviceId } from 'src/appSelectors';
import { AppButton } from 'src/components';
import { useAppSelector, useThunk } from 'src/hooks';
import { handleApiRequestError } from 'src/requests';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish } from 'src/types';

import { asyncCreatePair } from '../actions';
import { useMetadataByDeviceId } from '../selectors';
import { deviceIsValidTriggerSource } from '../triggers.reducer';

import type { EnhancedButtonProps } from 'src/components';
type UserSubmitNewPair = {
  clearFetchStatus: () => {
    payload:
      | string
      | {
          typePrefix: string;
        };
    type: string;
  };
  isFulfilled: boolean;
  isIdle: boolean;
  isPending: boolean;
  isRejected: boolean;
  handleSubmit: () => Promise<void>;
};

/**
 * @param props
 * @param props.partnerId
 */
export function useSubmitNewPair({
  partnerId
}: {
  partnerId: string | undefined;
}): UserSubmitNewPair {
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const partner = useMetadataByDeviceId(partnerId ?? '');

  const { sendRequest, ...rest } = useThunk(asyncCreatePair);

  const handleSubmit = async (): Promise<void> => {
    if (isNullish(partner) || isNullish(selectedDeviceId)) {
      throw new Error('No partner metadata');
    }
    let sourceDeviceId = selectedDeviceId;
    let targetDeviceId = partner.deviceId;

    if (deviceIsValidTriggerSource(partner)) {
      sourceDeviceId = partner.deviceId;
      targetDeviceId = selectedDeviceId;
    }
    await sendRequest({
      reelPumpPairList: [
        {
          sourceDeviceId,
          targetDeviceId
        }
      ]
    })
      .then((res) => {
        showSuccessToast();
        return res;
      })
      .catch(handleApiRequestError);
  };
  return { handleSubmit, ...rest };
}
type Props = Omit<EnhancedButtonProps, 'dataCy' | 'onClick' | 'showLoading'>;

/**
 * @param props
 * @param props.iconKey
 * @param props.rest
 * @param props.children
 */
export default function CreatePairButton({
  iconKey = 'CHECK_MARK',
  children = 'Create Pair',
  ...rest
}: Props): JSX.Element {
  const partnerId = useAppSelector((state) => state.triggers.partnerId);
  const { handleSubmit, isPending } = useSubmitNewPair({ partnerId });
  const disabled = rest.disabled ?? isNullish(partnerId);

  return (
    <AppButton
      size="small"
      {...rest}
      disabled={disabled}
      iconKey={iconKey}
      id={testIds.devicePairing.submitPairBtn}
      onClick={handleSubmit}
      showLoading={isPending}
    >
      {children}
    </AppButton>
  );
}
