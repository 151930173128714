import React from 'react';
import { cancelOnPending } from 'src/requests';

import { Auth } from '@aws-amplify/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { useAppDispatch } from '../hooks';
import { appLogger } from '../utilities';
import { AUTH_ERROR_CODES } from './errors';

import type { AuthErrorCodeValue } from '../constants';
import type { RootThunkApi } from '../store';
const CHECK_AUTH = 'asyncCheckAuth';
const asyncCheckAuth = createAsyncThunk<string, void, RootThunkApi>(
  CHECK_AUTH,
  /**
   *
   * @param _ void
   * @param thunkApi
   * @param thunkApi.rejectWithValue
   * @returns
   */
  async (_, { rejectWithValue }) => {
    try {
      if (process.env.REACT_APP_SKIP_AUTH_CHECK === 'TRUE') {
        return await Promise.resolve(
          process.env.REACT_APP_DEV_EMAIL_ADDRESS as string
        );
      }
      const session = await Auth.currentSession();
      appLogger.log(session);
      const payload = session.getIdToken().decodePayload() as {
        readonly email: string;
      };
      appLogger.debug(`[Auth success]`);
      return payload.email;
    } catch (err) {
      appLogger.log(JSON.stringify(err));
      appLogger.debug(`[CHECK AUTH ERROR]: `);
      if (err === AUTH_ERROR_CODES.authCheckFailed) {
        return rejectWithValue(err);
      }
      throw new Error(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      const { authStatus } = state.userSession;
      if (authStatus === 'rejected' || authStatus === 'fulfilled') {
        return false;
      }
      return true;
    }
  }
);
const SIGN_OUT = `asyncSignOut` as const;
const asyncSignOut = createAsyncThunk<void, void, RootThunkApi>(
  SIGN_OUT,
  /**
   *
   * @returns
   */
  async () => {
    await Auth.signOut().catch(appLogger.error);
    return undefined;
  },
  { condition: cancelOnPending(SIGN_OUT) }
);
const SIGN_IN = `signIn` as const;
const asyncSignIn = createAsyncThunk<
  string,
  { readonly email: string; readonly currentPassword: string },
  RootThunkApi
>(
  SIGN_IN,
  /**
   *
   * @param args
   * @param args.email
   * @param args.currentPassword
   * @returns
   */
  async ({ email, currentPassword }) => {
    try {
      await Auth.signIn({
        password: currentPassword,
        username: email.toLocaleLowerCase()
      });
      return email;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  { condition: cancelOnPending(SIGN_IN) }
);

const SUBMIT_FORGOT_PW_EMAIL = 'asyncSubmitForgotPasswordEmail' as const;
const asyncSubmitForgotPasswordEmail = createAsyncThunk<
  AuthErrorCodeValue | string,
  { readonly email: string },
  RootThunkApi
>(
  SUBMIT_FORGOT_PW_EMAIL,
  async ({ email }) => {
    try {
      await Auth.forgotPassword(email.toLocaleLowerCase());

      return email;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  { condition: cancelOnPending(SUBMIT_FORGOT_PW_EMAIL) }
);

/**
 *
 */
function useSignOut(): () => Promise<void> {
  const dispatch = useAppDispatch();
  const sendRequest = React.useCallback(async (): Promise<void> => {
    await dispatch(asyncSignOut()).catch((err) => {
      throw new Error(err);
    });
  }, [dispatch]);
  return sendRequest;
}

export { useSignOut };
export default {
  asyncCheckAuth,
  asyncSignIn,
  asyncSignOut,
  asyncSubmitForgotPasswordEmail
};
