import type { LabelLength } from 'src/components/types';
import { User } from 'src/userSession.reducer';
import type { AnySensorKey } from './getConversionUnits';
import getConversionUnits from './getConversionUnits';

/**
 * @param length
 */
export default function useSensorUnitLabel(
  length: LabelLength = 'short'
): (key: AnySensorKey) => string {
  const isMetric = User.useIsMetric();
  return (key: AnySensorKey) =>
    getConversionUnits({ isMetric, key, target: 'user' })?.labels[length] ?? '';
}
