import { DASHBOARD_URL } from './constants';

import type { ValuesType } from 'utility-types';
const DASHBOARD_ROUTE = {
  SUPPORT: {
    name: 'support',
    path: DASHBOARD_URL.support
  },
  ACCOUNT_SETTINGS: {
    name: 'account',
    path: DASHBOARD_URL.account
  },
  MAP: {
    iconKey: 'MAP',
    name: 'map',
    path: DASHBOARD_URL.map
  }
} as const;
export type BasePageName = ValuesType<typeof DASHBOARD_ROUTE>['name'];
export const DASHBOARD_ROUTES = [
  DASHBOARD_ROUTE.MAP,
  DASHBOARD_ROUTE.SUPPORT,
  DASHBOARD_ROUTE.ACCOUNT_SETTINGS
] as const;

export default DASHBOARD_ROUTES;
