import React from 'react';
import Actions from 'src/actions';
import { useActiveFeature } from 'src/appSelectors';
import useMenuState from 'src/components/menu/useMenuState';
import UnconfiguredDeviceWarning from 'src/devices/config-manager/UnconfiguredDeviceWarning';
import InstallationTypes from 'src/devices/installation-type';
import { useAppDispatch } from 'src/hooks';
import { DeviceInField } from 'src/status-map/DeviceInField';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { isNullish, notNullish } from 'src/types';

import {
  Avatar,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  Grow,
  makeStyles,
  Typography
} from '@material-ui/core';

import { AppButton, GridWrapper, IconBtn, MapCard } from '../components';
import SensorStates from './device-list-item/SensorStates';
import DeviceProfileDialog from './DeviceProfileDialog';
import DeviceProfileOptionsMenu from './DeviceProfileOptionsMenu';
import AlertsToggleDeniedDialog from './notifications/AlertsToggleDeniedDialog';
import RemoteControlMenu from './remote-controls/RemoteControlMenu';
import { RenameDevice } from './RenameDevice';
import { useMetadataByDeviceId } from './selectors';

import type { DeviceNameProp, PropInstallationType } from '.';
import type { DeviceIdProp } from './models';
const testId = testIds.deviceActions.root;

const useStyles = makeStyles(({ spacing }) => {
  // const { mapCardMaxWidth, mapFloatingCard } = APP_STYLE;

  return createStyles({
    remoteControlsMenu: {},
    cardHeader: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
      paddingBottom: 0
    },
    cardBody: {
      paddingRight: spacing(2),
      paddingBottom: spacing(1),
      paddingLeft: spacing(2)
    },
    subheader: {
      paddingTop: 5,
      paddingBottom: 5
    }
  });
});

/**
 *
 */
function CloseButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const handleClick = () => dispatch(Actions.resetToDefaultState());
  return (
    <IconBtn
      iconKey="CLOSE"
      id={testIds.deviceActions.closeBtn}
      onClick={handleClick}
      size="small"
    />
  );
}

/**
 * @param props
 * @param props.deviceName
 * @param props.onToggleRenaming
 */
function NameDisplay({
  deviceName,
  onToggleRenaming
}: DeviceNameProp & { onToggleRenaming: () => void }) {
  return (
    <Typography
      data-cy={testIds.deviceActions.deviceName}
      id={testIds.deviceActions.deviceName}
      variant="h5"
    >
      {deviceName}
      <IconBtn
        iconKey="RENAME_FIELD"
        id={testIds.deviceActions.renameDeviceBtn}
        onClick={onToggleRenaming}
        size="small"
      />
    </Typography>
  );
}
/**
 * @param props
 * @param props.deviceInstallationType
 */
function InstallationTypeDisplay({
  deviceInstallationType
}: PropInstallationType): JSX.Element {
  return (
    <Typography
      color="textSecondary"
      data-cy={testIds.deviceActions.installationType}
      id={testIds.deviceActions.installationType}
      variant="subtitle1"
    >
      {InstallationTypes.humanize(deviceInstallationType)}
    </Typography>
  );
}
/**
 * @param props
 * @param props.showRemoteControls
 * @param props.onClick
 */
function RemoteControlToggleButton({
  showRemoteControls,
  onClick
}: {
  showRemoteControls: boolean;
  onClick: () => void;
}): JSX.Element {
  return (
    <AppButton
      fullWidth
      iconKey={showRemoteControls ? 'DEVICE_EVENT' : 'REMOTE_CONTROLS'}
      id={testIds.deviceActions.showRemoteControlsBtn}
      onClick={onClick}
      text={showRemoteControls ? 'Status' : 'Controls'}
    />
  );
}

/**
 * @param props
 * @param props.deviceId
 */
export default function DeviceActions({
  deviceId
}: DeviceIdProp): JSX.Element | null {
  const activeFeature = useActiveFeature();
  const [showRemoteControls, setShowRemoteControls] = React.useState(false);
  const metadata = useMetadataByDeviceId(deviceId);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const { menuAnchor, menuOpen, handleCloseMenu, handleOpenMenu } =
    useMenuState();

  const classes = useStyles();
  React.useEffect(
    /**
     * If the user selects an option from the options menu, close the menu
     */
    function onChangeActiveFeature(): void {
      if (activeFeature) {
        handleCloseMenu();
      }
    },
    [activeFeature, handleCloseMenu]
  );
  if (isNullish(metadata)) {
    return null;
  }

  const handleToggleRenaming = () => setIsRenaming((prev) => !prev);

  const handleToggleRemoteControls = () =>
    setShowRemoteControls(!showRemoteControls);

  return (
    <React.Fragment>
      <DeviceProfileOptionsMenu
        anchorEl={menuAnchor}
        deviceId={deviceId}
        onClose={handleCloseMenu}
        open={menuOpen}
      />
      <DeviceProfileDialog deviceId={deviceId} />
      <AlertsToggleDeniedDialog />
      <Grow in={notNullish(deviceId)}>
        <MapCard id={testId}>
          <CardHeader
            action={<CloseButton />}
            avatar={
              <Avatar>
                <AppIcon
                  iconKey={metadata.deviceInstallationType ?? 'unconfigured'}
                />
              </Avatar>
            }
            className={classes.cardHeader}
            data-cy={testIds.deviceActions.metadata}
            disableTypography
            id={testIds.deviceActions.metadata}
            title={
              <div>
                {isRenaming ? (
                  <RenameDevice
                    deviceId={deviceId}
                    deviceName={metadata.deviceName}
                    onReset={handleToggleRenaming}
                  />
                ) : (
                  <NameDisplay
                    deviceName={metadata.deviceName}
                    onToggleRenaming={handleToggleRenaming}
                  />
                )}
                <Divider />
                <div className={classes.subheader}>
                  <GridWrapper spacing={1}>
                    <InstallationTypeDisplay
                      deviceInstallationType={metadata.deviceInstallationType}
                    />
                    <DeviceInField deviceId={deviceId} />
                  </GridWrapper>
                </div>
              </div>
            }
          />
          <Grid
            className={classes.cardBody}
            container
            data-cy={testIds.deviceActions.actionsRow}
            id={testIds.deviceActions.actionsRow}
            spacing={1}
          >
            <UnconfiguredDeviceWarning
              deviceId={deviceId}
              deviceInstallationType={metadata.deviceInstallationType}
            />
            <Grid item xs={12}>
              {showRemoteControls ? (
                <RemoteControlMenu
                  className={classes.remoteControlsMenu}
                  deviceId={deviceId}
                />
              ) : (
                <SensorStates deviceId={deviceId} />
              )}
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <RemoteControlToggleButton
                  onClick={handleToggleRemoteControls}
                  showRemoteControls={showRemoteControls}
                />
              </Grid>
              <Grid item xs={6}>
                <AppButton
                  fullWidth
                  iconKey="EXPAND_MORE"
                  id={testIds.deviceActions.openDpMenuBtn}
                  onClick={handleOpenMenu}
                  text="options"
                />
              </Grid>
            </Grid>
          </Grid>
        </MapCard>
      </Grow>
    </React.Fragment>
  );
}
