import * as d3 from 'd3';
import * as d3Geo from 'd3-geo';
import React from 'react';
import { Geo } from 'src/geo';
import { BASE_COLORS } from 'src/theme/Theme';
import { isNullish, notNullish } from 'src/types';

import * as turf from '@turf/turf';

import type { PolygonGeoJson } from 'src/geo/polygons';
const FIELD_SVG_PROPERTIES = {
  fillOpacity: 1,
  stroke: BASE_COLORS.charcoal,
  strokeOpacity: 1.0,
  strokeWidth: 1
} as const;

type ThumbnailProps = Omit<React.SVGProps<HTMLOrSVGElement>, 'onCopy'> & {
  height?: number;
  width?: number;
  muteColor?: boolean;
  polygon: PolygonGeoJson;
};

/**
 * Render shape of field as an icon. Can accept and id or a polygon directly.
 * @param props
 * @param props.polygon
 * @param props.muteColor
 * @param props.fillColor
 * @param props.width
 * @param props.height
 * @param props.fieldId
 * @param props.rest
 */
function FieldIcon({
  polygon: polygonPath,
  width = 24,
  height = 24,
  ...rest
}: ThumbnailProps) {
  const geoCollection = React.useMemo(() => {
    return notNullish(polygonPath)
      ? Geo.polygons.to.toD3GeoCollection(
          turf.rewind(polygonPath, { mutate: false, reverse: true })
        )
      : null;
  }, [polygonPath]);

  const ref = React.useRef(null);
  const options = {
    ...FIELD_SVG_PROPERTIES,
    ...rest
  };
  const { strokeWidth, strokeOpacity, fillOpacity, stroke } = options;
  React.useEffect(() => {
    if (geoCollection) {
      const svg = d3.select(ref.current);
      svg.selectAll(`*`).remove();
      // Transforms our geojson into usage x-y coordinates
      // Bind D3 data and put the data on the canvas
      if (!isNullish(geoCollection) && !isNullish(ref)) {
        // const rewound = rewind(geoCollection, {
        //   reverse: true
        // });

        svg
          .selectAll(`path`)
          .data([geoCollection])
          .enter()
          .append(`path`)
          .attr(
            `d`,
            d3Geo
              .geoPath()
              .projection(
                d3Geo.geoMercator().fitSize([width, height], geoCollection)
              )
          )
          .attr(`fill`, 'gold')
          .attr(`fill-opacity`, fillOpacity)
          .attr(`stroke`, stroke)
          .attr(`stroke-opacity`, strokeOpacity)
          .attr(`stroke-width`, strokeWidth);
      }
    }
  }, [
    fillOpacity,
    geoCollection,
    height,
    stroke,
    strokeOpacity,
    strokeWidth,
    width
  ]);

  return (
    <svg
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className="d3-component"
      height={height}
      ref={ref}
      width={width}
    />
  );
}

const FieldIconMemoized = React.memo(FieldIcon);
export default FieldIconMemoized;
