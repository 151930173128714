import type { DeviceEvent } from '../models';

/**
 * @param de
 * @param indexId
 */
export default function transformDeviceEvent(
  de: Omit<DeviceEvent, 'indexId'>,
  indexId: number
): DeviceEvent {
  return {
    ...de,
    indexId
  };
}
