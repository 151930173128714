import type { SelectProps } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import {
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { getSelectedDeviceId } from './appSelectors';
import { getTargetsForActionTrigger } from './devices';
import InstallationTypes from './devices/installation-type';
import testIds from './testIds';
import { AppIcon } from './theme';
import { notNullish } from './types';

import type { ConnectedProps } from 'react-redux';
import type { Overwrite } from 'utility-types';
type OwnProps = Overwrite<SelectProps, { onChange: (value: string) => void }>;
const connector = connect((state, ownProps: OwnProps) => {
  const ownId = getSelectedDeviceId(state);
  const options = notNullish(ownId)
    ? getTargetsForActionTrigger(state, ownId)
    : [];

  return {
    ...ownProps,
    options
  };
});
type Props = ConnectedProps<typeof connector> & OwnProps;

const Ids = testIds.triggerSelect('targetDeviceId');
const STYLE: React.CSSProperties = {
  minWidth: 300
};
/**
 * @param props
 * @param props.options
 * @param props.onChange
 * @param props.value
 * @param props.rest
 */
function TargetDeviceSelect({ options, onChange, value, ...rest }: Props) {
  const handleChange: React.ChangeEventHandler<{ value: unknown }> = (e) => {
    return onChange(e.target.value as string);
  };
  return (
    <FormControl style={STYLE}>
      <InputLabel id={Ids.label}>Target Device</InputLabel>
      <Select
        MenuProps={{ id: Ids.menu }}
        data-cy={Ids.root}
        fullWidth
        id={Ids.root}
        labelId={Ids.label}
        name="targetDeviceId"
        onChange={handleChange}
        value={value}
        {...rest}
      >
        {options.map(({ deviceId, deviceInstallationType, deviceName }) => (
          <MenuItem alignItems="center" key={deviceId} value={deviceId}>
            <ListItemIcon>
              <AppIcon iconKey={deviceInstallationType ?? 'unconfigured'} />
            </ListItemIcon>
            <Typography component="span">
              <Typography color="textSecondary" component="span">
                {InstallationTypes.humanize(deviceInstallationType)}
              </Typography>{' '}
              <Typography color="primary" component="span">
                {deviceName}
              </Typography>
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default connector(TargetDeviceSelect);
