/* eslint-disable max-len */
/* eslint-disable jsdoc/require-jsdoc */
import type { BBox, Feature, Polygon } from '@turf/helpers';
import { isNumber, isObject } from '@turf/helpers';
import { isFunction } from 'lodash-es';
import {
  isArrayOrReadonlyArray,
  isNullish,
  isNum,
  isPlainObj,
  isTruthy
} from 'src/types';
import type { GmapsLatLngBoundsLiteral } from './bounds';

/*
 *
 * COORDINATES
 *
 */

type Coordinates = number[];
/**
 * @param c
 */
function isValidCoordinates(c: unknown): c is Coordinates {
  return (
    isArrayOrReadonlyArray(c) &&
    c.length >= 2 &&
    c.length < 4 &&
    c.every(isNumber)
  );
}

/*
 *
 * POINTS
 *
 */

type PointGeoJson = {
  type: 'Point';
  coordinates: number[];
};
// Googlemaps

type LatLng = {
  lng: number;
  lat: number;
};

function isGmapsLiteral(maybeLatLng: unknown): maybeLatLng is LatLng {
  return Boolean(
    !isNullish(maybeLatLng) &&
      isPlainObj(maybeLatLng) &&
      `lng` in maybeLatLng &&
      `lat` in maybeLatLng &&
      isValidCoordinates([maybeLatLng.lng, maybeLatLng.lat])
  );
}
function isGmapsInstance(
  maybeLatLng: unknown
): maybeLatLng is google.maps.LatLng {
  if (!isNullish(maybeLatLng) && isObject(maybeLatLng)) {
    const asLatLng = maybeLatLng as google.maps.LatLng;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return isFunction(asLatLng.lng) && isFunction(asLatLng.lat);
  }
  return false;
}

function isPointGeoJSON(maybePoint: unknown): maybePoint is PointGeoJson {
  if (
    !isNullish(maybePoint) &&
    isPlainObj(maybePoint) &&
    `type` in maybePoint &&
    `coordinates` in maybePoint
  ) {
    const coords = maybePoint.coordinates;
    return (
      maybePoint.type === `Point` &&
      !isNullish(coords) &&
      isValidCoordinates(coords)
    );
  }
  return false;
}

type PointProperties = { label?: string } | null;
type PointFeature = Feature<PointGeoJson, PointProperties>;

function isPointFeature(
  maybeFeature: unknown
): maybeFeature is Feature<PointGeoJson> {
  return Boolean(
    !isNullish(maybeFeature) &&
      isPlainObj(maybeFeature) &&
      `type` in maybeFeature &&
      maybeFeature.type === `Feature` &&
      `geometry` in maybeFeature &&
      isPointGeoJSON(maybeFeature.geometry)
  );
}

type AnyPoint =
  | Coordinates
  | google.maps.LatLng
  | google.maps.LatLngLiteral
  | PointFeature
  | PointGeoJson;
function isAnyPoint(maybePoint: unknown): maybePoint is AnyPoint {
  return (
    isPointGeoJSON(maybePoint) ||
    isGmapsLiteral(maybePoint) ||
    isPointFeature(maybePoint) ||
    isGmapsInstance(maybePoint) ||
    isValidCoordinates(maybePoint)
  );
}

// * LINES *
type PointCoordinatesArray = Coordinates[];
type AnyLine =
  | Coordinates[]
  | google.maps.LatLngLiteral[]
  | PointFeature[]
  | PointGeoJson[];
type ArrayOfThree<T> = [T, T, T];

// * BOUNDS *
type BoundingBox = [west: number, south: number, east: number, north: number];
type AnyBounds = BBox | GmapsLatLngBoundsLiteral;
function isBbox(value: unknown): value is BoundingBox {
  if (isArrayOrReadonlyArray(value) && value.every(isNumber)) {
    if (value.length < 4) {
      return false;
    }

    const [west, south, east, north] = value;

    if (isNum(west) && isNum(south) && isNum(east) && isNum(north)) {
      return west < east && south < north;
    }
  }
  return false;
}

// * PSEUDO-POLYGONS
// Not actually polygons but can be coerced into polygons without adding any new points
type ArrayOfFourOurMore<T> = [T, T, T, T, ...T[]];
/**
 * @param ptArray
 */
function isArrayOfFourOrMorePoints(
  ptArray: unknown
): ptArray is ArrayOfFourOurMore<AnyPoint> {
  return (
    isArrayOrReadonlyArray(ptArray) &&
    ptArray.length >= 4 &&
    ptArray.every(isTruthy)
  );
}
type GmapsPolygonPath = google.maps.LatLngLiteral[];

type LinearRing = Coordinates[];
type PolygonFeature = Feature<Polygon>;

function isLinearRing(target: unknown): target is LinearRing {
  if (isArrayOrReadonlyArray(target)) {
    if (target.length >= 4) {
      return target.every(isValidCoordinates);
    }
  }
  return false;
}
export {
  isBbox,
  isPointGeoJSON,
  isPointFeature,
  isAnyPoint,
  isArrayOfFourOrMorePoints,
  isLinearRing,
  isGmapsInstance,
  isValidCoordinates,
  isGmapsLiteral
};
export type {
  BBox,
  BoundingBox,
  LatLng,
  AnyBounds,
  PolygonFeature,
  ArrayOfThree,
  PointCoordinatesArray,
  ArrayOfFourOurMore,
  AnyLine,
  LinearRing,
  Coordinates,
  PointFeature,
  PointGeoJson,
  PointProperties,
  AnyPoint,
  GmapsPolygonPath
};
