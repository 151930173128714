import type React from 'react';
import { toast } from 'react-toastify';

const DEFAULT_ERROR_TOAST = `Your request could not be completed. Please try again later`;

/**
 * @param msg
 */
export function showErrorToast(msg?: string): React.ReactText {
  return toast.error(msg ?? DEFAULT_ERROR_TOAST);
}
/**
 * @param msg
 */
export function showSuccessToast(msg = 'Success.'): React.ReactText {
  return toast.info(msg);
}
