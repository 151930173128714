import { isNumber, toSafeInteger } from 'lodash-es';
import { isNumericString, isTruthyString } from 'src/types';

const RELAY_ACTION_TYPES = [`RLYA`, `RLYD`, `RLYAS`] as const;
type RelayActionType = typeof RELAY_ACTION_TYPES[number];
/**
 * @param x
 */
function isValidRelayActionType(x: unknown): x is RelayActionType {
  return isTruthyString(x) && RELAY_ACTION_TYPES.includes(x as RelayActionType);
}

interface Activate {
  actionType: 'RLYA';
  seconds?: undefined;
}
interface Deactivate {
  seconds?: undefined;
  actionType: 'RLYD';
}
interface ActivateFor {
  actionType: 'RLYAS';
  seconds: number;
}
type RelayAction = Activate | ActivateFor | Deactivate;

/**
 * @param r
 */
function serializeRelayAction(r: RelayAction): `${RelayActionType};${number}` {
  return `${r.actionType};${r.seconds ?? 0}`;
}
/**
 * @param s
 */
function parseRelayAction(s: string): RelayAction | undefined {
  const [actionType, seconds] = s.split(';');
  if (isValidRelayActionType(actionType)) {
    if (actionType === 'RLYAS') {
      const parsedSeconds = isNumericString(seconds)
        ? toSafeInteger(seconds)
        : undefined;
      if (isNumber(parsedSeconds)) {
        return {
          actionType,
          seconds: parsedSeconds
        };
      }
      return undefined;
    }
    return {
      actionType
    };
  }
  return undefined;
}

/**
 * @param actionType
 * @param extraParameters
 * @param extraParameters.seconds
 */
function makeRelayAction(
  actionType: RelayActionType,
  extraParameters?: {
    seconds?: number;
  }
): RelayAction {
  switch (actionType) {
    case 'RLYA': {
      return {
        actionType: 'RLYA'
      };
    }
    case 'RLYD': {
      return { actionType: 'RLYD' };
    }
    case 'RLYAS': {
      return { actionType: 'RLYAS', seconds: extraParameters?.seconds ?? 10 };
    }

    default:
      throw new Error('invalid action type');
  }
}

/**
 * @param args
 * @param args.actionType
 * @param args.seconds
 * @param a
 */
function humanizeRelayAction(a: RelayAction): string {
  switch (a.actionType) {
    case 'RLYAS': {
      if (isNumber(a.seconds)) {
        return `activate its switch for ${a.seconds} seconds`;
      }
      return humanizeRelayAction({ actionType: 'RLYA' });
    }
    case 'RLYA':
      return `activate its switch`;
    case 'RLYD':
      return 'deactivate its switch';
    default:
      return 'take action';
  }
}
export type { RelayActionType, RelayAction };
const RelayActions = {
  RELAY_ACTION_TYPES,
  humanize: humanizeRelayAction,
  isValidRelayActionType,
  makeRelayAction,
  parseRelayAction,
  serializeRelayAction
};
export default RelayActions;
