import { Box } from '@material-ui/core';
import { captureException, captureMessage } from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppTextField, AppButton, GridWrapper } from 'src/components';
import { useThunk } from 'src/hooks';
import { showErrorToast } from 'src/theme';
import { isNullish } from 'src/types';
import { asyncCustomizeNotificationString } from '../actions';
import formatTrigger from '../formatTrigger';
import { isNotificationTrigger } from '../models';
import { getTriggerById } from '../triggers.reducer';

type Props = {
  onResetState: () => void;
  triggerId: number;
};

/**
 * @param props
 * @param props.initialValue
 * @param props.children
 * @param props.triggerId
 * @param props.onSuccess
 * @param props.onResetState
 */
export default function CustomizeNotificationString({
  triggerId,
  onResetState
}: Props): JSX.Element | null {
  const item = useSelector((state) => getTriggerById(state, triggerId));
  const initialValue =
    item?.notificationString ??
    (item
      ? formatTrigger(item, { includeNotify: false }).replace(
          'send a notification',
          ''
        )
      : '');
  const [notificationString, setNotificationString] =
    React.useState(initialValue);
  const { sendRequest, isPending } = useThunk(asyncCustomizeNotificationString);
  if (isNullish(item) || !isNotificationTrigger(item)) {
    captureMessage(
      `Customize notification string couldn't find a valid notification matching id ${triggerId}`
    );
    return null;
  }

  const handleSubmit = async () =>
    sendRequest({
      notificationString,
      triggerId
    })
      .then(onResetState)
      .catch((err) => {
        showErrorToast();
        captureException(err);
      });
  return (
    <React.Fragment>
      <Box flexGrow={2} maxHeight={300} mb={1}>
        <AppTextField
          id="notificationString"
          label="Enter Custom Label"
          maxRows={4}
          multiline
          name="notificationString"
          onChange={(e) => setNotificationString(e.target.value)}
          required
          value={notificationString}
          variant="standard"
        />
      </Box>
      <GridWrapper justifyContent="space-between">
        <AppButton onClick={onResetState}>Cancel</AppButton>
        <AppButton
          onClick={handleSubmit}
          presetKey="SUBMIT"
          showLoading={isPending}
        />
      </GridWrapper>
    </React.Fragment>
  );
}
