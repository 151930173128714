import type { PolygonProps } from '@react-google-maps/api';
import React from 'react';
import { isNullish } from 'src/types';
import { appLogger } from 'src/utilities';

import { Polygon } from '@react-google-maps/api';

import type { HideableProps } from 'src/components';
export interface PolygonBaseProps
  extends HideableProps,
    Omit<google.maps.PolygonOptions, 'map' | 'visible'>,
    Omit<
      PolygonProps,
      keyof google.maps.PolygonOptions | 'options' | 'path' | 'visible'
    > {
  path: google.maps.LatLngLiteral[];
}

/**
 *
 * @param props
 * @param props.children
 * @param props.clickable
 * @param props.draggable
 * @param props.editable
 * @param props.fillColor
 * @param props.fillOpacity
 * @param props.geodesic
 * @param props.isHidden
 * @param props.map
 * @param props.onClick
 * @param props.onDblClick
 * @param props.onDrag
 * @param props.onDragEnd
 * @param props.onDragStart
 * @param props.onLoad
 * @param props.onMouseDown
 * @param props.onMouseMove
 * @param props.onMouseOut
 * @param props.onMouseOver
 * @param props.onMouseUp
 * @param props.onRightClick
 * @param props.onUnmount
 * @param props.path
 * @param props.paths
 * @param props.strokeColor
 * @param props.strokeOpacity
 * @param props.strokePosition
 * @param props.strokeWeight
 * @param props.rest
 * @param props.zIndex
 */
export default function PolygonBase({
  clickable,
  draggable,
  editable,
  fillColor,
  fillOpacity,
  geodesic,
  isHidden,
  onClick,
  onDblClick,
  onDrag,
  onDragEnd,
  onDragStart,
  onLoad,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onRightClick,
  onUnmount,
  path,
  strokeColor,
  strokeOpacity,
  strokePosition,
  strokeWeight,
  zIndex,
  children
}: React.PropsWithChildren<PolygonBaseProps>): JSX.Element {
  if (isNullish(path)) {
    const msg = `Polygon failed to render because path was null`;
    appLogger.warn(msg);
    throw new Error(msg);
    // return null;
  }

  const optionsMemoized = React.useMemo(() => {
    return {
      clickable,
      editable: undefined,
      fillColor,
      fillOpacity,
      geodesic,
      // paths: undefined,
      strokeColor,
      strokeOpacity,
      strokePosition,
      strokeWeight,
      visible: !(isHidden === true),
      zIndex
    };
  }, [
    clickable,
    fillColor,
    fillOpacity,
    geodesic,
    isHidden,
    strokeColor,
    strokeOpacity,
    strokePosition,
    strokeWeight,
    zIndex
  ]);
  return (
    <Polygon
      draggable={draggable}
      editable={editable}
      onClick={onClick}
      onDblClick={onDblClick}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onLoad={onLoad}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      onMouseUp={onMouseUp}
      onRightClick={onRightClick}
      onUnmount={onUnmount}
      options={optionsMemoized}
      path={path}
      visible={!(isHidden === true)}
    >
      {children}
    </Polygon>
  );
}
