import { BASE_COLORS } from 'src/theme/Theme';
import type { InstallationType } from './InstallationTypes';

type Args = {
  readonly deviceInstallationType?: InstallationType | null;
};

/**
 * @param target
 * @param target.deviceInstallationType
 */
export default function mapInstallationTypeToColor(target?: Args): string {
  const kind = target?.deviceInstallationType;
  switch (kind) {
    case `reel`: {
      return `rgb(40,100,255)`;
    }
    case `pump`: {
      return `rgb(30,170,150)`;
    }
    case `prototype`: {
      return `rgb(190,150,100)`;
    }
    case `unconfigured`: {
      return `rgb(250,200,50)`;
    }
    case `traveller_soft`: {
      return `rgb(40, 150, 230)`;
    }
    default: {
      return BASE_COLORS.charcoal;
    }
  }
}
