import distance from '@turf/distance';
import { bbox, point, square } from '@turf/turf';
import { isNumber } from 'lodash-es';
import type { FarmField } from 'src/fields';
import type { PointCoordinatesArray, PointGeoJson } from 'src/geo';
import { Geo } from 'src/geo';
import GeoPoints from 'src/geo/points';
import type { PolygonGeoJson } from 'src/geo/polygons';
import GeoPolygons from 'src/geo/polygons';
import { isNullish } from 'src/types';

const ARROW_ANGLE = 170;
const RADIUS_FACTOR = 0.5;

const DEFAULT_LINE_DISTANCE_METERS = 150;
// const DEFAULT_ARROWHEAD_LENGTH_METERS = 35;
interface RowDirectionShapeArguments
  extends Partial<Pick<FarmField, 'boundingRadiusMeters'>> {
  center: PointGeoJson;
  azimuth: number;
  arrowheadLengthMeters?: number;
  enclosingPolygon: PolygonGeoJson;
}
/**
 *@param args
 */
function getLengthMeters(args: RowDirectionShapeArguments): number {
  if (isNumber(args.boundingRadiusMeters)) {
    return args.boundingRadiusMeters * RADIUS_FACTOR;
  }
  if (!isNullish(args.enclosingPolygon)) {
    const asGeoJSON = Geo.polygons.to.geojson(args.enclosingPolygon);
    const [west, south, east, north] = square(bbox(asGeoJSON));
    return (
      distance(point([west, north]), point([east, south]), {
        units: `meters`
      }) * RADIUS_FACTOR
    );
  }

  return DEFAULT_LINE_DISTANCE_METERS;
}

export type { RowDirectionShapeArguments };

/**
 * Creates shapes for the row direction arrow
 * @param args
 * @param args.center
 * @param args.azimuth
 * @param args.arrowheadLengthMeters
 * @param args.boundingRadiusMeters
 * @param args.enclosingPolygon
 */
export default function drawRowDirectionArrow(
  args: RowDirectionShapeArguments
): {
  readonly arrowPolygon: PolygonGeoJson;
  readonly linePath: PointCoordinatesArray;
} {
  const lengthMm = getLengthMeters(args) * 1000;

  /**
   * Plot one of the two ends of the line
   * @param args
   * @param args.offset
   */
  const getLineEndPoint = ({ offset }: { readonly offset: number }) =>
    GeoPoints.make.projection({
      azimuth: args.azimuth + offset,
      distanceMm: lengthMm,
      origin: args.center
    });

  const lineEndPointArrowSide = getLineEndPoint({ offset: 0 });

  const lineEndPointBase = getLineEndPoint({ offset: -180 });

  /**
   * Plot one of the 4 points on the arrowhead
   * @param offset
   */
  const getArrowPoint = (offset: number) =>
    GeoPoints.make.projection({
      azimuth: args.azimuth + offset,
      distanceMm: args.arrowheadLengthMeters ?? lengthMm * 0.3,
      origin: lineEndPointArrowSide
    });
  const arrowCorner1 = getArrowPoint(-ARROW_ANGLE);
  const arrowCorner2 = getArrowPoint(ARROW_ANGLE);
  const centerPointFeature = GeoPoints.to.pointFeature(args.center);

  const [linePath] = GeoPolygons.to.geojson([
    lineEndPointArrowSide,
    centerPointFeature,
    lineEndPointBase
  ]).coordinates;
  if (isNullish(linePath)) {
    throw new Error('undefined line path');
  }
  return {
    arrowPolygon: GeoPolygons.to.geojson([
      lineEndPointArrowSide,
      arrowCorner1,
      arrowCorner2,
      lineEndPointArrowSide
    ]),
    linePath
  };
}
