import React from 'react';
import { isNullish } from 'src/types';

/**
 *
 */
function useMenuState(): {
  handleCloseMenu: () => void;
  handleOpenMenu: (e: React.MouseEvent<HTMLElement>) => void;
  menuAnchor: HTMLElement | null;
  menuAnchorRef: React.MutableRefObject<HTMLElement | undefined>;
  menuOpen: boolean;
} {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuAnchorRef = React.useRef<HTMLElement>();

  return {
    handleCloseMenu: React.useCallback(() => setMenuOpen(false), []),
    handleOpenMenu: React.useCallback((e: React.MouseEvent<HTMLElement>) => {
      if (isNullish(menuAnchorRef.current)) {
        menuAnchorRef.current = e.currentTarget;
      }
      setMenuOpen(true);
    }, []),
    menuAnchor: menuAnchorRef.current ?? null,
    menuAnchorRef,
    menuOpen
  };
}

export default useMenuState;
