import React from 'react';
import { useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import ChooseInstallationType from './ChooseInstallationType';
import ChoosePumpType from './ChoosePumpType';
import ChooseSwitchType from './ChooseSwitchType';
import ConfirmAndSubmit from './ConfirmAndSubmit';
import { CreateConfig } from './createConfig.reducer';
import DeviceNameForm from './DeviceNameForm';
import DoesItHaveAPressureSensor from './DoesItHaveAPressureSensor';
import PressureThresholdForm from './PressureThresholdForm';
import ReelForm from './ReelForm';
import SensorPortForm from './SensorPortForm';
import Success from './Success';
import WheelForm from './WheelForm';

const { getCurrentStage } = CreateConfig;

/**
 * @param props
 * @param props.deviceId
 * @param props.stage
 * @param props.onSuccess
 */
function Content(): JSX.Element {
  const stage = useAppSelector(getCurrentStage);

  switch (stage) {
    case 'INSTALLATION_TYPE': {
      return <ChooseInstallationType />;
    }
    case 'PUMP_TYPE': {
      return <ChoosePumpType />;
    }
    case 'SENSOR_PORT': {
      return <SensorPortForm />;
    }
    case 'SPOOL':
    case 'NOZZLE':
    case 'HOSE': {
      return <ReelForm />;
    }
    case 'WHEEL': {
      return <WheelForm />;
    }
    case 'HAS_PRESSURE': {
      return <DoesItHaveAPressureSensor />;
    }
    case 'SWITCH_TYPE': {
      return <ChooseSwitchType />;
    }
    case 'THRESHOLDS': {
      return <PressureThresholdForm />;
    }
    case 'DEVICE_NAME': {
      return <DeviceNameForm />;
    }
    case 'CONFIRM': {
      return <ConfirmAndSubmit />;
    }
    case 'SUCCESS': {
      return <Success />;
    }

    default:
      throw new Error('Invalid stage');
  }
}

/**
 * @param props
 */
export default function CreateConfiguration(): JSX.Element | null {
  const testId = testIds.createConfiguration.root;
  return (
    <div data-cy={testId} id={testId}>
      <Content />
    </div>
  );
}
