import { appLogger } from 'src/utilities';
import type { SensorStateValueDetails } from './formatSensorValue';
import type { SwitchType } from './SensorConfig';
import type { AnySensorStateValue } from './SensorStates';

/**
 * @param rawValue
 */
export default function humanizeSensorStateValue(
  rawValue: AnySensorStateValue | SwitchType | 'NONE' | null
): SensorStateValueDetails {
  switch (rawValue as AnySensorStateValue) {
    case 'A': {
      return {
        helpDescription: undefined,
        long: 'active',
        rawValue,
        short: 'active'
      };
    }
    case 'BL': {
      return {
        helpDescription: undefined,
        long: 'low battery',
        rawValue,
        short: 'low'
      };
    }
    case 'BN': {
      return {
        helpDescription: undefined,
        long: 'battery charge is not low',
        rawValue,
        short: 'ok'
      };
    }
    case 'C': {
      return {
        helpDescription: undefined,
        long: 'switch closed',
        rawValue,
        short: 'closed'
      };
    }
    case 'I': {
      return {
        helpDescription: undefined,
        long: 'inactive',
        rawValue,
        short: 'inactive'
      };
    }
    case 'MA': {
      return {
        helpDescription: undefined,
        long: 'no magnet detected',
        rawValue,
        short: 'no magnet'
      };
    }
    case 'MP': {
      return {
        helpDescription: undefined,
        long: 'magnet detected',
        rawValue,
        short: 'detected'
      };
    }
    case 'O': {
      return {
        helpDescription: undefined,
        long: 'switch is open',
        rawValue,
        short: 'open'
      };
    }
    case 'OFF': {
      return {
        helpDescription: undefined,
        long: 'off',
        rawValue,
        short: 'off'
      };
    }
    case 'ON': {
      return {
        helpDescription: undefined,
        long: 'on',
        rawValue,
        short: 'on'
      };
    }
    case 'PHI': {
      return {
        helpDescription: undefined,
        long: 'high pressure',
        rawValue,
        short: 'high'
      };
    }
    case 'PLO': {
      return {
        helpDescription: undefined,
        long: 'low pressure',
        rawValue,
        short: 'low'
      };
    }
    case 'POV': {
      return {
        helpDescription: undefined,
        long: 'above threshold',
        rawValue,
        short: 'over'
      };
    }
    case 'RE': {
      return {
        helpDescription: undefined,
        long: 'extending to run start location',
        rawValue,
        short: 'extending'
      };
    }
    case 'RR': {
      return {
        helpDescription: undefined,
        long: 'retracting',
        rawValue,
        short: 'retracting'
      };
    }
    case 'RS': {
      return {
        helpDescription: undefined,
        long: 'stopped',
        rawValue,
        short: 'stopped'
      };
    }
    case 'THI': {
      return {
        helpDescription: undefined,
        long: 'high',
        rawValue,
        short: 'high'
      };
    }
    case 'TLO': {
      return {
        helpDescription: undefined,
        long: 'low',
        rawValue,
        short: 'low'
      };
    }
    case 'WF': {
      return {
        helpDescription: undefined,
        long: 'moving fast',
        rawValue,
        short: 'fast'
      };
    }
    case 'WL': {
      return {
        helpDescription: undefined,
        long: 'moving slowly',
        rawValue,
        short: 'slow'
      };
    }
    case 'WS': {
      return {
        helpDescription: undefined,
        long: 'stopped',
        rawValue,
        short: 'stopped'
      };
    }
    case 'NONE': {
      return {
        long: 'None',
        rawValue,
        short: 'None'
      };
    }
    default: {
      const msg = 'Got null or undefined for sensor state';

      appLogger.warn(msg);
      return {
        long: 'N/A',
        rawValue,
        short: 'N/A'
      } as const;
      // throw new Error(msg);
    }
  }
}
