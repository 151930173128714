/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-lines */

import React from 'react';

/**
 *
 */
export function TermsOfServiceText(): JSX.Element {
  return (
    <div data-cy="tos-text" id="tos-text">
      <p>CODA Farm Technologies, Inc. Terms of Service</p>
      <p>
        This Terms of Service (the "<b>Terms</b>
        ") describes the rights and responsibilities that apply to your use of
        CODA’s devices, website, mobile app, and services (collectively, the “
        <b>Service</b>
        ”), each owned and operated by CODA Farm Technologies, Inc. (“
        <b>CODA</b>
        ”, “<b>we</b>
        ”, “<b>our</b>” or “<b>us</b>
        ”).
      </p>
      <p>
        Please read the Terms carefully before using the Service.
        <b>
          By using the Service or by clicking to accept or agree to the Terms
          when this option is made available to you, you accept and agree to be
          bound and abide by the Terms, as well as CODA’s
          {` `}
          <a href="https://www.codafarmtech.com/privacy">Privacy Policy</a>
        </b>
        . If you do not agree to the Terms, as well as CODA’s
        {` `}
        <a href="https://www.codafarmtech.com/privacy">Privacy Policy</a>, you
        must not use the Service. If you are entering into the Terms on behalf
        of a company or other legal entity, you represent that you have the
        authority to bind such entity to the Terms. The Service is offered and
        available to users who are 18 years of age or older. By using the
        Service, you represent and warrant that you are of legal age to form a
        binding contract with CODA and meet all of the foregoing eligibility
        requirements. If you do not meet all these requirements, you must not
        use the Service.
      </p>
      <p>
        1.
        {` `}
        <u>Your CODA Account</u>. If you create an account on the Service (your
        “<b>Account</b>
        ”), you are responsible for maintaining the security of your Account,
        and you are fully responsible for all activities that occur under your
        Account and any other actions taken on the Service. CODA deems the
        person or organization under whose name appears on the credit or charge
        card or other payment method that pays the charges for the Service to be
        the owner of the Account. You must immediately notify CODA of any
        unauthorized uses of your Account or any other breaches of security.
        CODA will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions.
      </p>
      <p>
        2.
        {` `}
        <u>Fees and Payment</u>.
      </p>
      <p>
        (a)
        {` `}
        <u>Device Fees</u>. You may purchase from CODA one or more Devices at
        the price set forth on CODA’s website and upon the terms and conditions
        set forth in these Terms. “<b>Device</b>” means a CODA FarmHQ cellular
        device which allows for communication between an irrigation reel and the
        pump that supplies water to the reel, including without limitation the
        “FarmHQ PC-1” and the “FarmHQ React.FC-2”.
      </p>
      <p>
        (b)
        {` `}
        <u>Subscription Fees, Billing, Renewal, and Cancellation</u>.
      </p>
      <p>
        (i)
        {` `}
        <u>General</u>. Access to the Service is offered under a subscription
        plan with a 12-month term (“
        <b>Initial Term</b>
        ”), which may be renewed for successive 12-month terms (each, a “
        <b>Renewal Term</b>” and, together with the Initial Term, the “
        <b>Subscription Term</b>
        ”).
      </p>
      <p>
        (ii)
        {` `}
        <u>Subscription Fees</u>. You shall pay the subscription fees set forth
        in the invoice sent to you by CODA in US Dollars. If you fail to make
        any payment when due, and if such failure continues for 30 days or more,
        CODA may suspend your and your Authorized Users' access to any portion
        or all of the Service until such amounts are paid in full.
      </p>
      <p>
        (iii)
        {` `}
        <u>Billing</u>. You will be billed on an annual cycle that begins on the
        day that you sign up to receive the Service. By signing up for the
        Service, you authorize CODA to charge any applicable subscription fees
        to your credit card or other approved form of payment. If your billing
        payment method changes during the Subscription Term, you must promptly
        update the billing payment method associated with your Account.
      </p>
      <p>
        (iv)
        {` `}
        <u>Automatic Renewal</u>. If any Device you purchase is activated after
        the end of the Initial Term or the end of any Renewal Term, then you
        will be billed for a successive Renewal Term. CODA will provide you with
        notice one month before the end of the Initial Term and any Renewal
        Term, including a reminder that if you activate the Device after the end
        of the Initial Term or Renewal Term then in effect, then you will be
        billed for a successive Renewal Term.
        <b>
          YOU AGREE TO BE CHARGED FOR THE AUTOMATIC RENEWAL OF YOUR SUBSCRIPTION
          UNLESS AND UNTIL YOU CANCEL IN ACCORDANCE WITH SECTION 2(b)(v)
        </b>
        .
      </p>
      <p>
        (v)
        {` `}
        <u>Cancellations</u>. Cancellations may be made at any time by
        contacting CODA’s customer support directly at support@codafarmtech.com.
        After you cancel your subscription, you will still have access to the
        Service until the end of the Subscription Term, unless earlier
        suspended, discontinued, or terminated pursuant to Section 10 of the
        Terms.
      </p>
      <p>
        (c)
        {` `}
        <u>Taxes</u>. All fees and other amounts payable by you under the Terms
        are exclusive of taxes and similar assessments. You are responsible for
        all sales, use, and excise taxes, and any other similar taxes, duties,
        and charges of any kind imposed by any federal, state, or local
        governmental or regulatory authority on any amounts payable by you
        hereunder, other than any taxes imposed on CODA's income.
      </p>
      <p>
        3.
        {` `}
        <u>Access and Use</u>.
      </p>
      <p>
        (a)
        {` `}
        <u>Provision of Access</u>. Subject to the terms and conditions of these
        Terms, CODA hereby grants you a non-exclusive, non-transferable (except
        in compliance with Section 11(g)) right to access and use the Device and
        Service during the Subscription Term, solely for use by Authorized Users
        in accordance with the terms and conditions herein. Such use is limited
        to your internal use. "<b>Authorized User</b>" means your employees,
        consultants, contractors, and agents (i) who are authorized by you to
        access and use the Device and Service under the rights granted to you
        pursuant to the Terms and (ii) for whom access to the Device and Service
        has been purchased.
      </p>
      <p>
        (b)
        {` `}
        <u>Documentation License</u>. Subject to the terms and conditions
        contained in these Terms, CODA hereby grants to you a non-exclusive,
        non-sublicensable, non-transferable (except in compliance with Section
        11(g)) license to use the Documentation during the Subscription Term
        solely for your internal business purposes in connection with your use
        of the Service. "<b>Documentation</b>" means CODA's user manuals,
        handbooks, and guides relating to the Device and Service provided by
        CODA to you either electronically or in hard copy form.
      </p>
      <p>
        (c)
        {` `}
        <u>Use Restrictions</u>. You shall not use the Device or Service for any
        purposes beyond the scope of the access granted in these Terms. You
        shall not at any time, directly or indirectly, and shall not permit any
        Authorized Users to: (i) copy, modify, or create derivative works of the
        Device, Service, or Documentation, in whole or in part; (ii) rent,
        lease, lend, sell, license, sublicense, assign, distribute, publish,
        transfer, or otherwise make available the Device, Service, or
        Documentation, except that you may lend your equipment to other existing
        customers of CODA; (iii) reverse engineer, disassemble, decompile,
        decode, adapt, or otherwise attempt to derive or gain access to any
        hardware component of the Device, in whole or in part, unless authorized
        or instructed in the Documentation; (iv) reverse engineer, disassemble,
        decompile, decode, adapt, or otherwise attempt to derive or gain access
        to any software component of the Service, in whole or in part; (v)
        remove any proprietary notices from the Device, Service, or
        Documentation; or (vi) use the Device, Service, or Documentation in any
        manner or for any purpose that infringes, misappropriates, or otherwise
        violates any intellectual property right or other right of any person,
        or that violates any applicable law.
      </p>
      <p>
        (d)
        {` `}
        <u>Reservation of Rights</u>. CODA reserves all rights not expressly
        granted to you in these Terms. Except for the limited rights and
        licenses expressly granted under these Terms, nothing in these Terms
        grants, by implication, waiver, estoppel, or otherwise, to you or any
        third party any intellectual property rights or other right, title, or
        interest in or to the CODA IP. "<b>CODA IP</b>" means the Device, the
        Service, the Documentation, and any and all intellectual property
        provided to you or any Authorized User in connection with the foregoing.
        For the avoidance of doubt, CODA IP includes Aggregated Statistics and
        any information, data, or other content derived from CODA’s monitoring
        of your access to or use of the Device or Service, but does not include
        Customer Data.
      </p>
      <p>
        (e)
        {` `}
        <u>Suspension</u>. Notwithstanding anything to the contrary in these
        Terms, CODA may temporarily suspend your and any Authorized User's
        access to any portion or all of the Service if: (i) CODA reasonably
        determines that (A) there is a threat or attack on any of the CODA IP;
        (B) your or any Authorized User's use of the CODA IP disrupts or poses a
        security risk to the CODA IP or to any other customer or vendor of CODA;
        (C) you, or any Authorized User, is using the CODA IP for fraudulent or
        illegal activities; (D) subject to applicable law, you have ceased to
        continue your business in the ordinary course, made an assignment for
        the benefit of creditors or similar disposition of its assets, or become
        the subject of any bankruptcy, reorganization, liquidation, dissolution,
        or similar proceeding; or (E) CODA’s provision of the Service to you or
        any Authorized User is prohibited by applicable law; (ii) any vendor of
        CODA has suspended or terminated CODA's access to or use of any
        third-party services or products required to enable you to access the
        Service; or (iii) in accordance with Section 2(b)(ii) (any such
        suspension described in subclause (i), (ii), or (iii), a “
        <b>Service Suspension</b>
        ”). CODA shall use commercially reasonable efforts to provide written
        notice of any Service Suspension to you and to provide updates regarding
        resumption of access to the Service following any Service Suspension.
        CODA shall use commercially reasonable efforts to resume providing
        access to the Service as soon as reasonably possible after the event
        giving rise to the Service Suspension is cured. CODA will have no
        liability for any damage, liabilities, losses (including any loss of
        data or profits), or any other consequences that you or any Authorized
        User may incur as a result of a Service Suspension.
      </p>
      <p>
        (f)
        {` `}
        <u>Aggregated Statistics</u>. Notwithstanding anything to the contrary
        in these Terms, CODA may monitor your use of the Service and collect and
        compile Aggregated Statistics. "<b>Aggregated Statistics</b>" means data
        and information related to your use of the Device and Service that is
        used by CODA in an aggregate and anonymized manner, including to compile
        statistical and performance information related to the provision and
        operation of the Device and Service. As between CODA and you, all right,
        title, and interest in Aggregated Statistics, and all intellectual
        property rights therein, belong to and are retained solely by CODA. You
        acknowledge that CODA may compile Aggregated Statistics based on
        Customer Data input into the Service. "<b>Customer Data</b>" means,
        other than Aggregated Statistics, information, data, and other content,
        in any form or medium, that is submitted, posted, or otherwise
        transmitted by or on behalf of you or an Authorized User through the
        Device or Service. You agree that CODA may (i) make Aggregated
        Statistics publicly available in compliance with applicable law, and
        (ii) use Aggregated Statistics to the extent and in the manner permitted
        under applicable law; provided that such Aggregated Statistics do not
        identify you or your confidential information.
      </p>
      <p>
        4.
        <u>Your Responsibilities</u>.
      </p>
      <p>
        (a)
        {` `}
        <u>General</u>. You are responsible and liable for all uses of the
        Device, Service, and Documentation resulting from access provided by
        you, directly or indirectly, whether such access or use is permitted by
        or in violation of these Terms. Without limiting the generality of the
        foregoing, you are responsible for all acts and omissions of Authorized
        Users, and any act or omission by an Authorized User that would
        constitute a breach of these Terms if taken by you will be deemed a
        breach of these Terms by you.
      </p>
      <p>
        (b) Effect of Your Failure or Delay. CODA is not responsible or liable
        for any delay or failure of performance caused in whole or in part by
        your delay in performing, or failure to perform, any of its obligations
        under these Terms (each, a "<b>Customer Failure</b>
        ").
      </p>
      <p>
        5.
        <u>Installation</u>. You may request a verbal consultation with CODA to
        aid in planning your installation of your Device, to provide a brief
        tutorial of CODA’s Service, and to answer questions that you may have
        prior to installation. Please direct all such requests to
        support@codafarmtech.com.
      </p>
      <p>
        6.
        <u>Intellectual Property Ownership; Feedback</u>.
      </p>
      <p>
        (a)
        {` `}
        <u>CODA IP</u>. You acknowledge that CODA owns all right, title, and
        interest, including all intellectual property rights, in and to the CODA
        IP.
      </p>
      <p>
        (b)
        {` `}
        <u>Customer Data</u>. CODA acknowledges that you own all right, title,
        and interest, including all intellectual property rights, in and to the
        Customer Data. You hereby grant to CODA a non-exclusive, royalty-free,
        worldwide license to reproduce, distribute, and otherwise use and
        display the Customer Data and perform all acts with respect to the
        Customer Data as may be necessary for CODA to provide the Service to
        you, and a non-exclusive, perpetual, irrevocable, royalty-free,
        worldwide license to reproduce, distribute, modify, and otherwise use
        and display Customer Data incorporated within the Aggregated Statistics.
      </p>
      <p>
        (c)
        {` `}
        <u>Feedback</u>. If you or any of your employees or contractors sends or
        transmits any communications or materials to CODA by mail, email,
        telephone, or otherwise, suggesting or recommending changes to the CODA
        IP, including without limitation, new features or functionality relating
        thereto, or any comments, questions, suggestions, or the like ("
        <b>Feedback</b>
        "), CODA is free to use such Feedback irrespective of any other
        obligation or limitation between the parties governing such Feedback.
        You hereby assign to CODA on your behalf, and on behalf of your
        employees, contractors and/or agents, all right, title, and interest in,
        and CODA is free to use, without any attribution or compensation to any
        party, any ideas, know-how, concepts, techniques, or other intellectual
        property rights contained in the Feedback, for any purpose whatsoever,
        although CODA is not required to use any Feedback.
      </p>
      <p>
        7.
        <u>Device Warranty</u>.
      </p>
      <p>
        (a)
        {` `}
        <u>Limited Warranty</u>. CODA warrants that for a period of 12 months
        from the date of shipment of any Device (the “<b>Device</b>
        {` `}
        <b>Warranty Period</b>
        ”), such Device will materially conform to CODA’s published
        specifications in effect as of the date of shipment and will be free
        from significant defects in material and workmanship.
      </p>
      <p>
        (b)
        {` `}
        <u>Warranty Limitations</u>. The warranties under Section 7(a) do not
        apply where the Device has: (i) been subjected to misuse, neglect,
        improper installation, improper storage, improper handling, abnormal
        physical stress, abnormal environmental conditions or use contrary to
        any instructions issued by CODA; (ii) been reconstructed, repaired, or
        altered by anyone other than CODA or its authorized representative; or
        (iii) been used with any third-party product, hardware, or product that
        has not been previously approved in writing by CODA.
      </p>
      <p>
        (c)
        {` `}
        <u>Exclusive Remedy</u>. This Section 7(c) contains your exclusive
        remedy for Defective Devices. “<b>Defective Device</b>” means a Device
        that is not conforming to the warranties in Section 7(a). Your remedy
        under this Section 7(c) is conditioned upon your compliance with your
        obligations under Section 7(c)(i) and Section 7(c)(ii) below. During the
        Device Warranty Period, with respect to any allegedly Defective Device:
        (i) you shall notify CODA, in writing, of any alleged claim or defect
        within 15 days from the date you discover, or upon reasonable inspection
        should have discovered, such alleged claim or defect (but in any event
        before the expiration of the applicable Device Warranty Period; (ii) you
        shall ship, at CODA’s expenses and risk of loss, such allegedly
        Defective Device to CODA for inspection and testing by CODA; (iii) if
        CODA’s inspection and testing reveals that such Device is a Defective
        Device and any such defect has not been caused or contributed to by any
        of the factors described under Section 7(b), CODA shall in its sole
        discretion, and at its expense, either repair or replace such Defective
        Device, or credit or refund the amount paid for such Device; (iv) if
        CODA exercises its option to repair or replace, CODA shall, after
        receiving your shipment of such Defective Device, ship to you, at CODA’s
        expense and risk of loss, the repaired or replaced Device to the
        location designated by you.
      </p>
      <p>
        (d)
        {` `}
        <u>Disclaimer</u>. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN
        Section 7(a), CODA HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
        IMPLIED, STATUTORY, OR OTHERWISE. CODA SPECIFICALLY DISCLAIMS ALL
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
        DEALING, USAGE, OR TRADE PRACTICE. EXCEPT AS OTHERWISE SET FORTH IN
        Section 7(a), CODA MAKES NO WARRANTY OF ANY KIND THAT THE CODA IP, OR
        ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET YOURS OR ANY OTHER
        PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
        INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR
        OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE,
        OR ERROR FREE.
      </p>
      <p>
        (e)
        {` `}
        <u>Third-Party Products.</u>
        {` `}
        Third-Party Products may constitute, contain, be contained in,
        incorporated into, attached to or packaged together with, the CODA IP,
        including but not limited to the Device, whether by CODA or by you. "
        <b>Third-Party Products</b>" means any third-party products
        <b> </b>
        provided with or incorporated into the Device or Service, whether by
        CODA or by you. For the avoidance of doubt, CODA MAKES NO
        REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY THIRD-PARTY PRODUCT,
        INCLUDING ANY (a) WARRANTY OF MERCHANTABILITY; (b) WARRANTY OF FITNESS
        FOR A PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (d) WARRANTY AGAINST
        INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER
        EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE,
        USAGE OF TRADE OR OTHERWISE.
      </p>
      <p>
        8. Indemnification. You shall indemnify, hold harmless, and, at CODA's
        option, defend CODA from and against any and all losses, damages,
        liabilities, costs (including reasonable attorneys' fees) ("
        <b>Losses</b>
        ") resulting from any third-party claim, suit, action, or proceeding ("
        <b>Third-Party Claim</b>
        ") that the Customer Data, or any use of the Customer Data in accordance
        with these Terms, infringes or misappropriates such third party's US
        intellectual property rights and any Third-Party Claims based on your or
        any Authorized User's (i) negligence or willful misconduct; (ii) use of
        the Device or Service in a manner not authorized by these Terms; (iii)
        use of the Device or Service in combination with data, software,
        hardware, equipment, or technology expressly prohibited by CODA; or (iv)
        modifications to the Device or Service not made by CODA, provided that
        you may not settle any Third-Party Claim against CODA unless CODA
        consents to such settlement, and further provided that CODA will have
        the right, at its option, to defend itself against any such Third-Party
        Claim or to participate in the defense thereof by counsel of its own
        choice.
      </p>
      <p>9. Limitations of Liability.</p>
      <p>
        (a) IN NO EVENT WILL CODA BE LIABLE UNDER OR IN CONNECTION WITH THESE
        TERMS UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT,
        TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY:
        (i) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED,
        OR PUNITIVE DAMAGES; (ii) INCREASED COSTS, DIMINUTION IN VALUE OR LOST
        BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (iii) LOSS OF GOODWILL OR
        REPUTATION; (iv) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR
        RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (v) COST
        OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER
        CODA WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
        LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL CODA'S
        AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS UNDER ANY
        LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
        NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED TWO TIMES THE TOTAL
        AMOUNTS PAID TO CODA BY YOU UNDER THESE TERMS IN THE ONE-YEAR PERIOD
        PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
      </p>
      <p>
        (b) WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU ASSUME ALL
        RISK AND LIABILITY FOR THE RESULTS OBTAINED BY THE USE OF ANY DEVICE IN
        THE PRACTICE OF ANY PROCESS, WHETHER IN TERMS OF OPERATING COSTS,
        GENERAL EFFECTIVENESS, SUCCESS OR FAILURE, AND REGARDLESS OF ANY ORAL OR
        WRITTEN STATEMENTS MADE BY CODA, BY WAY OF TECHNICAL ADVICE OR
        OTHERWISE, RELATED TO THE USE OF THE DEVICE.
      </p>
      <p>10. Term and Termination.</p>
      <p>
        (a)
        {` `}
        <u>Term</u>. Your access and use rights to the Service are effective
        during the Subscription Term, unless earlier terminated by CODA or you
        as permitted under these Terms. Notwithstanding anything to the
        contrary, CODA reserves the right to suspend, permanently discontinue or
        terminate the Service at any time if you fail to comply with any
        material term of these Terms.
      </p>
      <p>
        (b)
        {` `}
        <u>Termination</u>. In addition to any other express termination right
        set forth in these Terms , CODA may terminate your access to the
        Service, effective on written notice to you, if you: (A) fail to pay any
        amount when due hereunder, and such failure continues more than 30 days
        after CODA's delivery of written notice thereof; or (B) breach any of
        your obligations under Section 3(c);
      </p>
      <p>
        (c)
        {` `}
        <u>Effect of Expiration or Termination</u>. Except as otherwise provided
        in Section 2(b)(v), upon expiration or earlier termination of your
        access to the Service, you shall immediately discontinue use of the CODA
        IP. No expiration or termination will affect your obligation to pay all
        fees that may have become due before such expiration or termination or
        entitle you to any refund.
      </p>
      <p>11. Miscellaneous.</p>
      <p>
        (a)
        {` `}
        <u>Entire Terms</u>
        . These Terms, together with any other documents incorporated herein by
        reference, constitutes the sole and entire agreement of the parties with
        respect to the subject matter of these Terms and supersedes all prior
        and contemporaneous understandings, agreements, and representations and
        warranties, both written and oral, with respect to such subject matter.
        <u />
      </p>
      <p>
        (b)
        {` `}
        <u>Notices</u>. All notices, requests, consents, claims, demands,
        waivers, and other communications hereunder (each, a "<b>Notice</b>
        ") must be in writing and addressed to the parties at such address that
        may be designated by the party giving Notice from time to time in
        accordance with this Section. All Notices must be delivered by personal
        delivery, nationally recognized overnight courier (with all fees
        pre-paid), facsimile or email (with confirmation of transmission), or
        certified or registered mail (in each case, return receipt requested,
        postage pre-paid). Except as otherwise provided in these Terms, a Notice
        is effective only: (i) upon receipt by the receiving party; and (ii) if
        the party giving the Notice has complied with the requirements of this
        Section.
      </p>
      <p>
        (c) Force Majeure. In no event shall CODA be liable to you, or be deemed
        to have breached these Terms, for any failure or delay in performing its
        obligations under these Terms, if and to the extent such failure or
        delay is caused by any circumstances beyond CODA's reasonable control,
        including but not limited to acts of God, flood, fire, earthquake,
        explosion, war, terrorism, invasion, epidemics, pandemics (INCLUDING BUT
        NOT LIMITED TO COVID-19), quarantines, riot or other civil unrest,
        strikes, labor stoppages or slowdowns or other industrial disturbances,
        or passage of law or any action taken by a governmental or public
        authority, including imposing an embargo.
      </p>
      <p>
        (d)
        {` `}
        <u>Changes</u>. CODA reserves the right, at its sole discretion, to
        modify or replace any part of the Terms. Your continued use of or access
        to the Service following the posting of any changes to the Terms
        constitutes acceptance of these changes. CODA may also, in the future,
        offer new services and/or features through the Service (including the
        release of new devices and resources). Such new services and/or features
        shall be subject to the Terms.
      </p>
      <p>
        (e)
        {` `}
        <u>Severability</u>
        . If any provision of these Terms is invalid, illegal, or unenforceable
        in any jurisdiction, such invalidity, illegality, or unenforceability
        will not affect any other term or provision of these Terms or invalidate
        or render unenforceable such term or provision in any other
        jurisdiction.
        <u />
      </p>
      <p>
        (f)
        {` `}
        <u>Governing Law; Submission to Jurisdiction</u>
        . These Terms are governed by and construed in accordance with the
        internal laws of the State of Washington without giving effect to any
        choice or conflict of law provision or rule that would require or permit
        the application of the laws of any jurisdiction other than those of the
        State of Washington. Any legal suit, action, or proceeding arising out
        of or related to these Terms or the licenses granted hereunder will be
        instituted exclusively in the federal courts of the United States or the
        courts of the State of Washington, and each Party irrevocably submits to
        the exclusive jurisdiction of such courts in any such suit, action, or
        proceeding.
        <u />
      </p>
      <p>
        (g)
        {` `}
        <u>Assignment</u>. You may not assign any of its rights or delegate any
        of its obligations hereunder, in each case whether voluntarily,
        involuntarily, by operation of law or otherwise, without the prior
        written consent of CODA. Any purported assignment or delegation in
        violation of this Section will be null and void. No assignment or
        delegation will relieve the assigning or delegating party of any of its
        obligations hereunder. These Terms are binding upon and inure to the
        benefit of the parties and their respective permitted successors and
        assigns.
      </p>
      <p>
        (h) Export Regulation. You shall comply with all applicable federal
        laws, regulations, and rules, and complete all required undertakings
        (including obtaining any necessary export license or other governmental
        approval), that prohibit or restrict the export or re-export of the
        Service or any Customer Data outside the US.
      </p>
      <p>
        (i)
        {` `}
        <u>Equitable Relief</u>. You acknowledge and agree that a breach or
        threatened breach of your obligations under Section 3(c) would cause
        CODA irreparable harm for which monetary damages would not be an
        adequate remedy and agrees that, in the event of such breach or
        threatened breach, CODA will be entitled to equitable relief, including
        a restraining order, an injunction, specific performance, and any other
        relief that may be available from any court, without any requirement to
        post a bond or other security, or to prove actual damages or that
        monetary damages are not an adequate remedy. Such remedy is not
        exclusive and is in addition to all other remedies that may be available
        at law, in equity, or otherwise.
      </p>
      <p>
        12.
        {` `}
        <u>Contacting CODA</u>. You may contact CODA by email at
        support@codafarmtech.com.
      </p>
    </div>
  );
}
