import type { Reducer } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import Actions from './actions';
import asyncGetUserData from './asyncGetUserData';
import {
    asyncArchiveFields, asyncDeleteField, asyncUpdateFieldBoundary
} from './fields/fieldRequests.actions';

import type { ActiveFeature } from './actions';
type SelectItemPayload =
  | {
      id: number;
      kind: 'field' | 'reelRun';
    }
  | {
      id: string;
      kind: 'device';
    }
  | {
      kind: 'farm';
      id?: undefined;
    };

interface State {
  sidebarIsOpen: boolean;
  sidebarIsDocked: boolean;
  activeFeature: ActiveFeature | null;
  calloutOpen: boolean;
  selectedItem?: SelectItemPayload;
  searchValue: string;
}
const initialState: State = {
  activeFeature: null,
  calloutOpen: false,
  searchValue: '',
  sidebarIsDocked: false,
  sidebarIsOpen: false
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder

      .addCase(Actions.setActiveFeature, (state, { payload }) => {
        if (payload === null) {
          state.activeFeature = null;
        } else {
          state.activeFeature = payload.name;
          if (payload.kind) {
            state.selectedItem = {
              id: payload.id,
              kind: payload.kind
            } as SelectItemPayload;
          } else {
            state.selectedItem = undefined;
          }
        }
      })
      .addCase(Actions.selectItem, (state, { payload }) => {
        const { selectedItem } = state;
        if (
          selectedItem?.id === payload.id &&
          selectedItem?.kind === payload.kind
        ) {
          if (state.calloutOpen) {
            // if the item is already selected, deselect it
            state.selectedItem = undefined;
          }
        } else {
          // otherwise select the item
          state.selectedItem = payload;
          // if mobile, close the sidebar (also shows the map card)
          if (!state.sidebarIsDocked) {
            state.sidebarIsOpen = false;
          }
        }
        if (state.selectedItem) {
          state.calloutOpen = true;
        }
      })
      .addCase(Actions.onMapCalloutClosed, (state) => {
        state.calloutOpen = false;
      })
      .addCase(Actions.clearSearch, (state) => {
        state.searchValue = '';
      })
      .addCase(Actions.updateSearchFilter, (state, { payload }) => {
        state.searchValue = payload;
      })
      .addCase(asyncGetUserData.fulfilled, (state, action) => {
        if (process.env.REACT_APP_DEMO_MODE === 'enabled') {
          const runId = action.payload.activeFarm?.reelRuns[0]?.reelRunId;
          if (runId) {
            state.selectedItem = {
              id: runId,
              kind: 'reelRun'
            };
          }
        }
      })
      .addMatcher(
        isAnyOf(
          asyncGetUserData.fulfilled,
          Actions.openSidebar,
          Actions.clickCancel,
          Actions.resetToDefaultState,
          Actions.onMapCalloutClosed,
          asyncArchiveFields.fulfilled,
          asyncDeleteField.fulfilled,
          asyncUpdateFieldBoundary.fulfilled
        ),
        (state) => {
          state.activeFeature = null;
          state.selectedItem = undefined;
        }
      ),
  initialState,
  name: 'appFeature',
  reducers: {}
});

const appFeature: Reducer<State> = slice.reducer;
export type { SelectItemPayload };

export default appFeature;
