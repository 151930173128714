import { kebabCase } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton } from 'src/components';
import testIds from 'src/testIds';
import { User } from 'src/userSession.reducer';

import { Grid } from '@material-ui/core';

import { getRemoteControlsOptions, RemoteControls } from './';
import RemoteControlKeys from './RemoteControlKeys';
import RemoteControlConfirmation from './RemoteControlsConfirmation';
import useRemoteControl from './useRemoteControls';

import type { RemoteControlKey } from './RemoteControlKeys';
import type { EnhancedButtonProps } from 'src/components';

import type { DeviceIdProp } from '../models';
/**
 * @param props
 * @param props.commandKey
 * @param props.deviceId
 * @param props.rest
 */
export function RemoteControlButton({
  commandKey,
  deviceId,
  ...rest
}: DeviceIdProp &
  EnhancedButtonProps & { commandKey: RemoteControlKey }): JSX.Element | null {
  const isAdmin = User.useIsAdmin();
  const command = useRemoteControl({ commandKey, deviceId });
  const showLoading =
    RemoteControls.useStatus(deviceId, commandKey)?.fetchStatus === 'pending';
  if (command) {
    const testId = kebabCase(commandKey);
    return (
      <AppButton
        {...rest}
        helpText={command.description}
        iconKey={commandKey}
        id={testId}
        isHidden={!isAdmin && command.adminOnly === true}
        onClick={command.sendRequest}
        requiredPermissions="canControlDevicesRemotely"
        showLoading={showLoading}
      >
        {command.label}
      </AppButton>
    );
  }
  return null;
}
/**
 * @param props
 * @param props.deviceId
 * @param props.className
 */
export default function RemoteControlMenu({
  deviceId,
  className
}: DeviceIdProp & { className: string }): JSX.Element | null {
  const availableCommands = useSelector((state) =>
    getRemoteControlsOptions(state, deviceId)
  );
  const confirmationCommand = useSelector(
    (state) => state.remoteControls.confirmingCommand
  );
  if (confirmationCommand) {
    return (
      <RemoteControlConfirmation
        commandKey={confirmationCommand}
        deviceId={deviceId}
      />
    );
  }

  return typeof availableCommands === 'undefined' ? null : (
    <Grid
      className={className}
      container
      data-cy={testIds.remoteControls.root}
      id={testIds.remoteControls.root}
      spacing={1}
    >
      {RemoteControlKeys.KEYS_LIST.map((key) => {
        if (typeof availableCommands[key] === 'undefined') {
          return null;
        }
        return (
          <Grid item key={key} md={4} xs={6}>
            <RemoteControlButton
              commandKey={key}
              deviceId={deviceId}
              fullWidth
              key={key}
              size="small"
              variant="contained"
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
