import { capitalize } from 'lodash-es';

import type { LabelLength } from 'src/components/types';
import type { InstallationType } from './InstallationTypes';

/**
 * @param t
 * @param options
 * @param options.capitalize
 * @param options.length
 */
export default function humanizeInstallationType(
  t: InstallationType | undefined,
  options?: {
    capitalize?: boolean;
    length?: LabelLength;
  }
): string {
  const shouldCapitalize = options?.capitalize ?? true;
  const length = options?.length ?? 'long';
  let result: string = t ?? 'unconfigured';

  switch (t) {
    case 'traveller_soft': {
      result = 'traveller';
      break;
    }
    case 'unconfigured': {
      result = 'unconfigured device';
      break;
    }

    default:
      break;
  }
  if (length === 'short') {
    result = result.substr(0, 2);
  }
  if (shouldCapitalize) {
    result = capitalize(result);
  }
  return result;
}
