import { createAsyncThunk } from '@reduxjs/toolkit';
import type { PointGeoJson } from 'src/geo';
import { Geo } from 'src/geo';
import geocodeFarmAddress from 'src/geo/geocoding';
import { cancelOnPending } from 'src/requests';
import makeApiRequest from 'src/requests/makeApiRequest';
import { RequestNames } from '../constants';
import type { FarmAccount } from '../farm/farmUsers.reducer';
import type { RootThunkApi } from '../store';

const { CREATE_FARM } = RequestNames;
export type FarmFormData = Pick<
  FarmAccount,
  | 'addressLine1'
  | 'addressLine2'
  | 'city'
  | 'country'
  | 'name'
  | 'postalCode'
  | 'region'
>;
export const asyncCreateFarm = createAsyncThunk<
  FarmAccount,
  FarmFormData,
  RootThunkApi
>(
  CREATE_FARM,
  async ({
    addressLine1,
    addressLine2,
    name,
    city,
    region,
    country,
    postalCode
  }) => {
    try {
      const geocodeResponse = await geocodeFarmAddress({
        addressLine1,
        addressLine2,
        city,
        country,
        name,
        postalCode,
        region
      });
      const location = geocodeResponse.data.results[0]?.geometry.location;
      if (typeof location === 'undefined') {
        throw new Error('Geocoding failed');
      }

      const response = await makeApiRequest<
        FarmAccount,
        Omit<FarmFormData, 'addressLine1' | 'addressLine2'> & {
          readonly gpsLocation: PointGeoJson;
          address_line_1: string;
          address_line_2?: string | null;
        }
      >(CREATE_FARM, {
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        city,
        country,
        gpsLocation: Geo.points.to.geoJson(location),
        name,
        postalCode,
        region
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  {
    condition: cancelOnPending(CREATE_FARM)
  }
);
