import { toSafeInteger } from 'lodash-es';
import type React from 'react';
import { useControlledSelect } from 'src/components';
import type { SensorName } from 'src/devices/sensors';
import { SensorConfigs } from 'src/devices/sensors';
import useFormatSensorKey from 'src/devices/sensors/useSensorKeyFormatter';
import { isNumericString } from 'src/types';

export type SelectEvent = React.ChangeEvent<{
  readonly name?: string | undefined;
  readonly value: unknown;
}>;
type HasMagnets<S extends SensorName> = S extends 'reel' | 'wheel' ? S : never;

type Args<T extends HasMagnets<SensorName>> = {
  isActive: boolean;
  sensorName: T;
  initialValue?: number | string | null;
};

/**
 * @param args
 * @param args.isActive
 * @param args.kind
 * @param args.Options
 * @param args.readonly
 * @param args.options
 * @param args.initialValue
 * @param args.sensorName
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useMagnetSelect<T extends HasMagnets<SensorName>>({
  sensorName,
  initialValue,
  isActive
}: Args<T>) {
  const formattedKey = useFormatSensorKey()('nMagnets');
  const label = formattedKey === null ? '' : formattedKey;
  const options = [...SensorConfigs.N_MAGNETS[sensorName]];

  const { Component, value } = useControlledSelect<number>({
    fieldName: `nMagnets`,
    initialValue: isNumericString(initialValue)
      ? toSafeInteger(initialValue)
      : initialValue ?? 8,
    isHidden: !isActive,
    label,
    makeLabel: (arg) => `${arg}`,
    options
  });
  return {
    MagnetSelect: Component,
    label,
    nMagnets: value
  };
}
