import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton, MailToButton } from 'src/components';
import { AppMenuItem } from 'src/components/menu';
import { useAppSelector } from 'src/hooks';
import { DEVICE_PERMISSION_KEYS, PermissionListItem } from 'src/permissions';
import testIds from 'src/testIds';
import { isNullish, notNullish } from 'src/types';
import { getActiveFarm } from 'src/userSession.reducer';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  Menu
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ConfirmInvalidateCode from './ConfirmInvalidateCode';
import CreateShareCode from './CreateShareCode';
import { DeviceShareCodes } from './deviceShareCodes.reducer';
import ExistingShareCode from './ExistingShareCode';
import FarmsBorrowingDeviceList from './FarmsBorrowingDeviceList';

import type { DeviceMetadata } from '../models';
type State = {
  anchorEl: HTMLElement | null;
  currentAction:
    | 'CONFIRM_INVALIDATE'
    | 'CREATE_SHARE_CODE'
    | 'VIEW_CODE_PERMISSIONS'
    | null;
};

const INITIAL_STATE: State = { anchorEl: null, currentAction: null };
/**
 * User can create a share code for the device, see which farms are borrowing
 * the device, view permissions or invalidate the existing code if it exists,
 *
 * @param props
 * @param props.deviceId
 * @param props.deviceName
 * @param props.deviceInstallationType
 */
function DeviceOwnership({
  deviceId,
  deviceName,
  deviceInstallationType
}: DeviceMetadata): JSX.Element {
  const [{ anchorEl, currentAction }, setState] =
    React.useState<State>(INITIAL_STATE);

  const currentCode = useAppSelector((s) =>
    DeviceShareCodes.selectById(s, deviceId)
  );
  const farmName = useSelector(getActiveFarm)?.name ?? 'Your farm';

  const isCreatingCode = currentAction === 'CREATE_SHARE_CODE';
  const isViewingPermissions = currentAction === 'VIEW_CODE_PERMISSIONS';

  const handleCancel = (): void => setState({ ...INITIAL_STATE });

  const handleClickCreateCode = (): void =>
    setState(
      (prev): State => ({
        ...prev,
        currentAction: 'CREATE_SHARE_CODE'
      })
    );

  const handleClickViewPermissions = (): void =>
    setState(
      (prev): State => ({
        ...prev,
        anchorEl: null,
        currentAction: 'VIEW_CODE_PERMISSIONS'
      })
    );

  const handleClickInvalidate = (): void =>
    setState({
      anchorEl: null,
      currentAction: 'CONFIRM_INVALIDATE'
    });
  const handleopenMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void =>
    setState(
      (prev): State => ({
        ...prev,
        anchorEl: e.currentTarget
      })
    );
  const handleCloseMenu = () =>
    setState((prev) => ({ ...prev, anchorEl: null }));
  const { deviceOwnership } = testIds;
  const { existingCode } = deviceOwnership;
  return (
    <div data-cy={deviceOwnership.root} id={deviceOwnership.root}>
      <Menu
        anchorEl={anchorEl}
        data-cy={existingCode.actionsMenu}
        id={existingCode.actionsMenu}
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
      >
        <AppMenuItem
          button
          id={existingCode.viewPermsBtn}
          onClick={handleClickViewPermissions}
          textPrimary="View Permissions"
        />
        <AppMenuItem
          button
          id={deviceOwnership.invalidate.initBtn}
          onClick={handleClickInvalidate}
          requiredPermissions="canManageDevicePermissions"
          textPrimary="Invalidate Code"
        />
      </Menu>
      {notNullish(currentCode) ? (
        <ConfirmInvalidateCode
          codeId={currentCode.id}
          deviceName={deviceName}
          id={deviceOwnership.invalidate.root}
          onCancel={handleCancel}
          open={currentAction === 'CONFIRM_INVALIDATE'}
        />
      ) : null}
      <Grid
        container
        data-cy={deviceOwnership.title}
        direction="column"
        id={deviceOwnership.title}
        spacing={2}
      >
        <Grid item>
          <Card>
            <CardHeader
              subheader={`${farmName} is the primary owner of this device`}
              title="Device Ownership"
            />
          </Card>
        </Grid>
        <Grid
          data-cy={deviceOwnership.existingCode.root}
          id={deviceOwnership.existingCode.root}
          item
        >
          <Card>
            <CardHeader
              action={
                <AppButton
                  disabled={isCreatingCode}
                  id={deviceOwnership.createShareCode.initBtn}
                  onClick={handleClickCreateCode}
                  text={isNullish(currentCode) ? 'create' : 'new code'}
                />
              }
              subheader={
                notNullish(currentCode)
                  ? `Active`
                  : `No share code has been created for ${deviceName}`
              }
              title="Share Code"
            />
            {isViewingPermissions && notNullish(currentCode) ? (
              <React.Fragment>
                <CardContent>
                  <List
                    data-cy={existingCode.viewPerms}
                    id={existingCode.viewPerms}
                  >
                    {DEVICE_PERMISSION_KEYS.map((k) => (
                      <PermissionListItem
                        dense
                        isEnabled={currentCode.permissions[k]}
                        key={k}
                        perm={k}
                      />
                    ))}
                  </List>
                </CardContent>
                <CardActions>
                  <AppButton
                    id={deviceOwnership.closePermissions}
                    onClick={handleCancel}
                    text="close"
                  />
                </CardActions>
              </React.Fragment>
            ) : isCreatingCode ? (
              <CardContent>
                <CreateShareCode
                  codeExists={notNullish(currentCode)}
                  deviceId={deviceId}
                  onCancel={handleCancel}
                />
              </CardContent>
            ) : notNullish(currentCode) ? (
              <React.Fragment>
                <CardContent>
                  <ExistingShareCode
                    codeStr={currentCode.codeStr}
                    testId={existingCode.root}
                  />
                </CardContent>
                <CardActions id={testIds.deviceOwnership.existingCode.actions}>
                  <MailToButton
                    body={`Use this code at farm-hq.com to gain access to the device: ${currentCode.codeStr}`}
                    subject={`Device Share Code for ${deviceInstallationType} ${deviceName}`}
                  >
                    Share Code
                  </MailToButton>
                  <AppButton
                    iconKey="EXPAND_MORE"
                    id={existingCode.actionsBtn}
                    onClick={handleopenMenu}
                    text="actions"
                  />
                </CardActions>
              </React.Fragment>
            ) : null}
          </Card>
        </Grid>
        <Grid
          data-cy={deviceOwnership.farmsList}
          id={deviceOwnership.farmsList}
          item
        >
          <Card>
            <CardHeader title="Farms Borrowing Device" />
            <CardContent>
              <FarmsBorrowingDeviceList deviceId={deviceId} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default DeviceOwnership;
