import React from 'react';
import { useSelector } from 'react-redux';
import { isNullish } from 'src/types';
import { useThunk } from '../hooks';
import { appLogger } from '../utilities';
import Authenticator from './Authentication';
import authRequests from './authRequests';

/**
 * Checks user auth status on load. If not authenticated,
 * displays Authenticator component
 *
 * @param props
 * @param props.children
 */
function AuthCheck({
  children
}: React.PropsWithChildren<unknown>): JSX.Element {
  const { sendRequest } = useThunk(authRequests.asyncCheckAuth);
  const authStatus = useSelector((state) => state.userSession.authStatus);
  React.useEffect(() => {
    if (isNullish(authStatus) || authStatus === 'shouldFetch') {
      sendRequest().catch((err) => {
        appLogger.debug(err);
      });
    }
  }, [authStatus, sendRequest]);
  if (authStatus === 'rejected') {
    return <Authenticator />;
  }
  return (children ?? null) as JSX.Element;
}

export default AuthCheck;
