import type { Reducer } from '@reduxjs/toolkit';
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'src/actions';
import { getActiveFeature, getSelectedItem } from 'src/appSelectors';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import type { RootState } from 'src/root.reducer';
import { notNullish } from 'src/types';

const { dockSidebar, undockSidebar, openSidebar, closeSidebar } = Actions;
interface State {
  docked: boolean;
  open: boolean;
}
const initialState: State = {
  docked: false,
  open: false
};

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.selectItem, (state) => {
        if (!state.docked) {
          state.open = false;
        }
      })
      .addCase(dockSidebar, (state) => {
        state.docked = true;
      })
      .addCase(undockSidebar, (state) => {
        state.docked = false;
      })
      .addMatcher(isAnyOf(openSidebar), (state) => {
        state.open = true;
      })
      .addMatcher(isAnyOf(closeSidebar), (state) => {
        if (!state.docked) {
          state.open = false;
        }
      }),
  initialState,
  name: 'sidebar',
  reducers: {}
});

const sidebar: Reducer<State> = slice.reducer;

const getSlice = (state: RootState): State => state.sidebar;
const getIsOpened = createSelector(getSlice, (s) => s.open);
const getIsDocked = createSelector(getSlice, (s) => s.docked);
const getIsHidden = createSelector(
  getIsDocked,
  getActiveFeature,
  getSelectedItem,
  (docked, feature, item) => {
    if (docked) {
      return false;
    }

    if (notNullish(feature) || notNullish(item)) {
      return true;
    }
    return false;
  }
);
/**
 * @param isMobile
 */
function useSidebarDocker(isMobile: boolean): void {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(isMobile ? undockSidebar() : dockSidebar());
  }, [dispatch, isMobile]);
}
const getSidebarIsVisible = createSelector(
  getIsOpened,
  getIsDocked,
  getIsHidden,
  (open, docked, hidden) => {
    if (docked) {
      return true;
    }
    if (hidden) {
      return false;
    }
    return open;
  }
);

/**
 *
 */
function useToggleSidebar(): () => void {
  const isDocked = useAppSelector(getIsDocked);
  const isOpen = useAppSelector(getIsOpened);
  const dispatch = useAppDispatch();
  return () => {
    if (!isDocked) {
      dispatch(isOpen ? closeSidebar() : openSidebar());
    }
  };
}
export const Sidebar = {
  getIsDocked,
  getIsOpen: getIsOpened,

  getIsVisible: getSidebarIsVisible,
  useDocker: useSidebarDocker,
  useToggle: useToggleSidebar
};

export default sidebar;
