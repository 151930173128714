import { lineString, pointToLineDistance } from '@turf/turf';
import type { FarmField } from 'src/fields';
import type { PointGeoJson } from 'src/geo';
import { Geo } from 'src/geo';
import { isNullish } from 'src/types';

const OPTIONS = {
  units: `meters`
} as const;
/**
 * Compares fields to see which one is closer to an event.
 *
 * If one of the fields contains the event, skip the comparison
 *
 * @param args
 * @param args.location
 * @param args.current
 * @param args.next
 * @param args.fieldA
 * @param args.fieldB
 */
function compareFieldDistance({
  location,
  fieldA,
  fieldB
}: {
  readonly location: PointGeoJson | undefined;
  readonly fieldA?: FarmField | null | undefined;
  readonly fieldB?: FarmField | null | undefined;
}): -1 | 0 | 1 {
  if (isNullish(location)) {
    return 0;
  }
  const bufferMeters = Geo.polygons.defaults.bufferM;
  const locationGeoJSON = Geo.points.to.geoJson(location);

  if (
    fieldA?.polygon &&
    Geo.polygons.containsPoint(fieldA?.polygon, locationGeoJSON)
  ) {
    return -1;
  }
  if (
    !isNullish(fieldB) &&
    Geo.polygons.containsPoint(fieldB?.polygon, locationGeoJSON)
  ) {
    return 1;
  }

  const outerRingA = fieldA?.polygon.coordinates[0];
  const outerRingB = fieldB?.polygon?.coordinates[0];
  if (isNullish(outerRingA) || isNullish(outerRingB)) {
    return 1;
  }
  const fieldALine = lineString([...outerRingA]);
  const fieldBLine = lineString([...outerRingB]);
  const coords = locationGeoJSON.coordinates;
  const distanceA = pointToLineDistance([...coords], fieldALine, OPTIONS);
  const distanceB = pointToLineDistance([...coords], fieldBLine, OPTIONS);
  const AisOutsideBuffer = distanceA > bufferMeters;
  const BisOutsideBuffer = distanceB > bufferMeters;

  if (AisOutsideBuffer && BisOutsideBuffer) {
    return 0;
  }

  if (isNullish(fieldB) || distanceA <= distanceB) {
    return -1;
  }
  return 1;
}

type Args = {
  location: PointGeoJson;
  fields: FarmField[];
};

/**
 * @param args
 * @param args.location
 * @param args.fields
 */
export default function getClosestFieldToEvent({
  location,
  fields
}: Args): FarmField | null {
  const [firstField] = fields;
  if (isNullish(firstField)) {
    return null;
  }
  if (fields.length && Geo.points.is.any(location)) {
    const initialArg = {
      ...firstField
    };

    return fields.reduce<FarmField | null>(
      (current: FarmField | null, nextField: FarmField) => {
        const comparison = compareFieldDistance({
          fieldA: current,
          fieldB: nextField,
          location
        });
        if (comparison === 1) {
          return {
            ...nextField
          };
        }
        return current;
      },
      initialArg
    );
  }

  return null;
}
