import React from 'react';
import { isNullish } from 'src/types';
import { humanizeFarmUsePermission } from 'src/utilities';

import { default as FarmUserPermissions } from './FarmUserPermissions';

import type {
  FarmUserPermissionKey,
  MapKeysToBool
} from './FarmUserPermissions';
const PERMISSION_TOGGLE_LABELS = FarmUserPermissions.keys.reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: humanizeFarmUsePermission(key as string)
    };
  },
  {} as {
    readonly [key in FarmUserPermissionKey]: string;
  }
);

type Args<K extends FarmUserPermissionKey> = {
  keySet?: readonly K[];
  initial?:
    | {
        [key in K]: boolean;
      }
    | null;
};

/**
 * @param initial.keySet
 * @param initial
 * @param initial.initial
 * @param initial.initialPermissions
 */
export default function usePermissionToggles<K extends FarmUserPermissionKey>({
  keySet,
  initial
}: Args<K> = {}): {
  formData: MapKeysToBool<K>;
  handleClear: () => void;
  handleReset: () => void;
  handleToggle: (key: K) => void;
  setFormData: React.Dispatch<React.SetStateAction<MapKeysToBool<K>>>;
  toggleLabels: {
    readonly canManageJoinCodes: string;
    readonly canManageFields: string;
    readonly canManageFarmAccount: string;
    readonly canManageUserPermission: string;
    readonly canControlDevicesRemotely: string;
    readonly canManageCustomTriggers: string;
    readonly canManageDeviceConfiguration: string;
    readonly canManageDeviceNotifications: string;
    readonly canManageDevicePairs: string;
    readonly canManageDevicePermissions: string;
    readonly canToggleDeviceRelay: string;
  };
} {
  const keys = keySet ?? FarmUserPermissions.keys;
  const getInitial = React.useCallback(() => {
    const initializer = {} as { [key in K]: boolean };
    if (!isNullish(initial)) {
      return { ...initial };
    }
    return [...keys].reduce((acc, key) => {
      return { ...acc, [key]: false };
    }, initializer);
  }, [initial, keys]);
  // const initialPerms = React.useMemo(() => getInitial(), [getInitial])
  const [formData, setFormData] = React.useState<MapKeysToBool<K>>(getInitial);

  return {
    formData,
    handleClear: () => setFormData(getInitial),
    handleReset: React.useCallback(() => {
      return setFormData(getInitial());
    }, [getInitial]),
    handleToggle: React.useCallback(
      <N extends K>(key: N) =>
        setFormData((prev) => ({
          ...prev,
          [key]: !prev[key]
        })),
      []
    ),
    setFormData,
    toggleLabels: PERMISSION_TOGGLE_LABELS
  };
}
