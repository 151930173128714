import React from 'react';
import { useSelector } from 'react-redux';
import { Attention, CancelBtn } from 'src/components';
import ListHeader from 'src/components/ListHeader';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { pluralize } from 'src/utilities';

import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemSecondaryAction,
  Slide
} from '@material-ui/core';

import {
  getPairingOptionsByDeviceId,
  getSelectedPartnerId,
  Triggers
} from '../triggers.reducer';
import CreatePairButton from './CreatePairButton';
import PotentialPartnerListItem from './PotentialPartnerListItem';

import type {
  DeviceIdProp,
  DeviceNameProp,
  PropInstallationType
} from '../models';
const Ids = testIds.devicePairing;

/**
 * @param props
 * @param props.deviceName
 * @param props.deviceInstallationType
 * @param props.deviceId
 */
export default function CreatePair({
  deviceName,
  deviceInstallationType,
  deviceId
}: DeviceIdProp & DeviceNameProp & PropInstallationType): JSX.Element {
  const dispatch = useAppDispatch();

  const selectedPartnerId = useSelector(getSelectedPartnerId);

  const pairingOptions = useAppSelector((state) =>
    getPairingOptionsByDeviceId(state, deviceId)
  );

  const numOptions = pairingOptions.length;
  const onCancel = () => dispatch(Triggers.cancelPairing());
  if (pairingOptions.length === 0) {
    const handleClose = () => dispatch(Triggers.cancelPairing());
    return (
      <Dialog
        data-cy={Ids.potentialPartners.noneAvailable}
        id={Ids.potentialPartners.noneAvailable}
        onClose={handleClose}
        open
      >
        <DialogTitle>No Available Partners</DialogTitle>
        <DialogContent>
          <DialogContent>
            <DialogContentText>
              {deviceName} cannot be paired with any other devices
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <CancelBtn onClick={handleClose} text="close" />
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Slide direction="left" in>
      <div>
        <CardHeader
          subheader={`${deviceInstallationType} ${deviceName} can be paired with ${numOptions} other ${pluralize(
            { count: numOptions, word: 'device' }
          )}:`}
          title="New Pair"
        />
        <List
          data-cy={Ids.potentialPartners.list}
          dense
          id={Ids.potentialPartners.list}
          subheader={
            <ListHeader>
              <Attention action={<CancelBtn onClick={onCancel} />}>
                Select a partner device
              </Attention>
            </ListHeader>
          }
        >
          {pairingOptions.map((id, index) => (
            <PotentialPartnerListItem
              action={
                id === selectedPartnerId ? (
                  <ListItemSecondaryAction>
                    <CreatePairButton
                      color="primary"
                      size="small"
                      text="confirm"
                      variant="contained"
                    />
                  </ListItemSecondaryAction>
                ) : null
              }
              deviceId={id}
              index={index}
              key={id}
            />
          ))}
        </List>
      </div>
    </Slide>
  );
}
