import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import { captureException } from '@sentry/react';
import React from 'react';
import type { RouteChildrenProps } from 'react-router';
import { AppFab, MailToButton, Success } from 'src/components';
import { DASHBOARD_URL } from 'src/constants/Paths';
import { useAppSelector, useThunk } from 'src/hooks';
import type { FarmUserPermissionsMap } from 'src/permissions/FarmUserPermissions';
import FarmUserPermissions, {
  FARM_USER_PERMISSION_KEYS
} from 'src/permissions/FarmUserPermissions';
import testIds from 'src/testIds';
import { showErrorToast } from 'src/theme';
import PermissionsForm from '../permissions/PermissionsForm';
import { asyncCreateFarmJoinCode } from './farmJoinCodes.reducer';
const Ids = testIds.farmJoinCodes.newCode;
const testId = Ids.root;

const keys = [...FARM_USER_PERMISSION_KEYS];
/**
 * Select permissions to enable and create new code, overwriting old one
 * @param props
 * @param props.isOpen
 * @param props.onClosed
 * @param props.history
 */
export default function CreateFarmJoinCode({
  history
}: RouteChildrenProps): JSX.Element {
  const { sendRequest } = useThunk(asyncCreateFarmJoinCode);

  const codeString = useAppSelector(
    (s): string | null => s.farmJoinCode.codeStr ?? null
  );
  const handleSubmit = async (
    formData: FarmUserPermissionsMap
  ): Promise<void> => {
    await sendRequest({ permissions: formData }).catch((err) => {
      captureException(err);
      showErrorToast();
    });
  };
  const handleClickDone = (): void => history.replace(DASHBOARD_URL.account);
  const successHandler = (
    <Dialog maxWidth="xs" open>
      <DialogTitle>Success</DialogTitle>
      <DialogContent>
        <Success>
          <AlertTitle>Your code has been created</AlertTitle>
          <Typography component="h3" variant="h4">
            {codeString}
          </Typography>
        </Success>
      </DialogContent>
      <DialogActions>
        <AppFab
          iconKey="CHECK_MARK"
          id={testIds.doneBtn}
          onClick={handleClickDone}
        >
          Done
        </AppFab>
        <MailToButton id={testIds.shareCodeBtn}>Share</MailToButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <div data-cy={testId} id={testId}>
      <PermissionsForm
        initialPermissions={FarmUserPermissions.allFalse}
        keys={keys}
        onSubmit={handleSubmit}
        submitId={Ids.submit}
        subtitle="Set starting permissions for new users"
        successHandler={successHandler}
      />
    </div>
  );
}
