import type { DialogProps } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar
} from '@material-ui/core';
import { isString } from 'lodash-es';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import authRequests from 'src/auth/authRequests';
import { AppButton, Attention } from 'src/components';
import type { AuthError } from 'src/constants';
import { INVALID_CODE_MESSAGE } from 'src/constants';
import { useThunk } from 'src/hooks';
import testIds from 'src/testIds';
import { showSuccessToast } from 'src/theme';
import { isNullish, isTruthyString } from 'src/types';
import { maskEmailAddress } from 'src/utilities';
import {
  asyncGetSignUpCode,
  asyncSignUp,
  asyncSubmitNewPassword,
  asyncSubmitSignUpConfirmation
} from './actions';
import { AUTH_PATHS } from './auth-paths';
import AuthLink from './AuthLink';
import { NoFarmsAppBar } from 'src/AppBar';
import AuthForm from './Wrapper';

type State = Partial<AuthError> & {
  email?: string;
  isSuccess?: boolean;
  codeSent?: boolean;
};
const KEYS = {
  confirmSignUp: ['codeString'],
  initialSignUp: ['email', 'newPassword', 'confirmPassword'],
  passwordReset: ['newPassword', 'confirmPassword', 'codeString'],
  signIn: ['email', 'currentPassword']
} as const;
const Ids = testIds.auth;

const DEFAULT_ERROR_MESSAGE =
  'An unknown error occurred. Please try again later.';

/**
 * @param props
 * @param props.open
 * @param props.children
 */
function ConfirmAccountDialog({
  open,
  children
}: Pick<DialogProps, 'children' | 'open'>) {
  return (
    <Dialog
      data-cy={Ids.notConfirmedDialog}
      id={Ids.notConfirmedDialog}
      open={open}
    >
      <DialogTitle data-cy="dialog-title">Account Not Confirmed</DialogTitle>
      <DialogContent>
        <Attention>
          You need to confirm your account before you can sign in.
        </Attention>
      </DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
}

/**
 *
 */
function PasswordResetLink(): JSX.Element {
  return (
    <AuthLink
      testId={Ids.passwordReset.linkTo}
      text="Forgot your password?"
      to={AUTH_PATHS.passwordReset}
    />
  );
}

/**
 *
 */
function SignUpLink(): JSX.Element {
  return (
    <AuthLink
      iconKey="GO_BACK"
      testId={Ids.signUp.linkTo}
      text="Sign Up"
      to="/sign-up"
    />
  );
}

/**
 * @param props
 */
function Authenticator(): JSX.Element {
  const [state, setState] = React.useState<State>();
  const { pathname } = useLocation();
  const history = useHistory();
  const signIn = useThunk(authRequests.asyncSignIn);
  const signUp = useThunk(asyncSignUp);
  const confirmSignUp = useThunk(asyncSubmitSignUpConfirmation);
  const pwResetEmail = useThunk(authRequests.asyncSubmitForgotPasswordEmail);
  const newPassword = useThunk(asyncSubmitNewPassword);
  const resendSignupCode = useThunk(asyncGetSignUpCode);
  const isSuccess = state?.isSuccess === true;

  const handleResetForm = React.useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        code: undefined,
        isSuccess: false,
        message: undefined
      })),
    []
  );

  const handleCodeSent = (emailArg?: string) => {
    showSuccessToast('Code sent.');
    return setState((prev) => ({
      ...prev,
      codeSent: true,
      email: emailArg ?? prev?.email
    }));
  };

  const handleError = (
    e: unknown,
    values?: { [key: string]: string | undefined }
  ) => {
    const code = (e as AuthError | undefined)?.code;
    setState((prev) => {
      let message: string | undefined = DEFAULT_ERROR_MESSAGE;
      switch (code) {
        case 'CodeMismatchException': {
          message = INVALID_CODE_MESSAGE;
          break;
        }
        case 'UserNotFoundException': {
          message = 'Account does not exist.';
          break;
        }
        case 'NotAuthorizedException':
          message = 'Incorrect email or password';
          break;
        case 'UserNotConfirmedException':
          // Show a dialog here
          break;
        case 'UsernameExistsException':
          message = `An account registered to that email address already exists`;
          break;
        case 'InvalidParameterException':
          message = 'Your account is already confirmed';
          break;
        default: {
          throw new Error(JSON.stringify(e));
          // break;
        }
      }
      return { ...prev, code, email: values?.email ?? prev?.email, message };
    });
    // setState((prev) => ({ ...prev, code, message }));
  };

  const { email } = state ?? {};

  React.useEffect(() => {
    /**
     * If in success view, reset the state. Otherwise,
     * email and codeSent are preserved between routes
     */
    function onChangeUrl() {
      setState((prev) => ({
        codeSent: prev?.isSuccess === true ? false : prev?.codeSent,
        email: prev?.email
      }));
    }
    if (pathname) {
      onChangeUrl();
    }
  }, [handleResetForm, pathname]);

  const handleSubmitSignIn = (values: {
    email: string;
    currentPassword: string;
  }) =>
    signIn
      .sendRequest(values)
      .then(() => setState((prev) => ({ ...prev, email: values.email })))
      .catch((e) => handleError(e, values));

  return (
    <div data-cy={Ids.root} id={Ids.root}>
      <NoFarmsAppBar />
      <Toolbar />
      <Switch>
        <Route
          exact
          path={AUTH_PATHS.signIn}
          render={() => (
            <React.Fragment>
              <ConfirmAccountDialog
                open={state?.code === 'UserNotConfirmedException'}
              >
                <AppButton
                  id={Ids.confirmAccount.linkTo}
                  linkTo={AUTH_PATHS.confirmSignUp}
                  onClick={() =>
                    isTruthyString(email) &&
                    resendSignupCode
                      .sendRequest({ email })
                      .then(() => handleCodeSent(email))
                      .catch(handleError)
                  }
                >
                  confirm account
                </AppButton>
              </ConfirmAccountDialog>
              <AuthForm
                errorMessage={state?.message}
                id={Ids.signIn.root}
                keys={KEYS.signIn}
                linkLeft={<PasswordResetLink />}
                linkRight={<SignUpLink />}
                onClearFormError={handleResetForm}
                onSubmit={handleSubmitSignIn}
                title="Sign In"
              />
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={AUTH_PATHS.signUp}
          render={() => (
            <AuthForm
              errorMessage={state?.message}
              id={Ids.signUp.root}
              isSuccess={state?.isSuccess === true}
              keys={KEYS.initialSignUp}
              linkRight={
                <AuthLink
                  testId={Ids.confirmAccount.linkTo}
                  text="Need to confirm your account?"
                  to={AUTH_PATHS.confirmSignUp}
                />
              }
              onClearFormError={handleResetForm}
              onSubmit={(values) =>
                signUp
                  .sendRequest(values)
                  .then(() => {
                    handleCodeSent(values.email);
                    return history.push(AUTH_PATHS.confirmSignUp);
                  })
                  .catch((err) => handleError(err))
              }
              submitBtn={
                state?.code === 'UsernameExistsException' ? (
                  <AppButton>Need To Reset Your Password?</AppButton>
                ) : undefined
              }
              title="Sign Up"
            />
          )}
        />
        <Route
          exact
          path={AUTH_PATHS.confirmSignUp}
          render={() => {
            if (isNullish(email) || state?.codeSent !== true) {
              return (
                <AuthForm
                  alert="Enter your email address"
                  errorMessage={state?.message}
                  id={Ids.confirmAccount.root}
                  keys={['email']}
                  onClearFormError={handleResetForm}
                  onSubmit={(values) =>
                    resendSignupCode
                      .sendRequest(values)
                      .then(() => handleCodeSent(values.email))
                      .catch(handleError)
                  }
                  title="Confirm Account"
                />
              );
            }
            return (
              <AuthForm
                alert={
                  isSuccess
                    ? 'Your account is confirmed. You can now sign in with your credentials.'
                    : `We sent a six-digit confirmation code to ${
                        isString(email) ? maskEmailAddress(email) : 'your email'
                      }`
                }
                errorMessage={state?.message}
                id={Ids.confirmAccount.root}
                isSuccess={state.isSuccess}
                keys={['codeString']}
                onClearFormError={handleResetForm}
                onSubmit={({ codeString }) =>
                  !isNullish(email) &&
                  confirmSignUp
                    .sendRequest({ codeString, email })
                    .then(() =>
                      setState((prev) => ({ ...prev, isSuccess: true }))
                    )
                    .catch(handleError)
                }
                resendCodeBtn={
                  <AppButton
                    id={Ids.resendCodeBtn}
                    onClick={() =>
                      resendSignupCode
                        .sendRequest({ email })
                        .then(handleCodeSent)
                        .catch(handleError)
                    }
                    text="Resend Code"
                  />
                }
                title="Confirm Account"
              />
            );
          }}
        />
        <Route
          exact
          path={AUTH_PATHS.passwordReset}
          render={() =>
            isNullish(email) || state?.codeSent !== true ? (
              <AuthForm
                alert="Enter your email to receive a one-time password reset code"
                errorMessage={state?.message}
                id={Ids.passwordReset.root}
                keys={['email']}
                onClearFormError={handleResetForm}
                onSubmit={(values) =>
                  pwResetEmail
                    .sendRequest(values)
                    .then(() => handleCodeSent(values.email))
                    .catch(handleError)
                }
                title="Password Reset"
              />
            ) : (
              <AuthForm
                alert={
                  isSuccess
                    ? 'You can now sign in with your new credentials'
                    : `For security purposes, we sent a one-time password reset code to ${maskEmailAddress(
                        email
                      )}`
                }
                errorMessage={state?.message}
                id={Ids.passwordReset.root}
                isSuccess={isSuccess}
                keys={KEYS.passwordReset}
                onClearFormError={handleResetForm}
                onSubmit={(values) =>
                  newPassword
                    .sendRequest({ ...values, email })
                    .then(() => {
                      showSuccessToast();
                      return setState((prev) => ({ ...prev, isSuccess: true }));
                    })
                    .catch(handleError)
                }
                title="Password Reset"
              />
            )
          }
        />
        <Redirect exact from="*" to={AUTH_PATHS.signIn} />
      </Switch>
    </div>
  );
}
type AuthFieldName =
  | 'codeString'
  | 'confirmPassword'
  | 'currentPassword'
  | 'email'
  | 'newPassword';

export type { AuthFieldName };
export default Authenticator;
