import type { PointGeoJson } from 'src/geo';
import type { DateString, Nullable } from 'src/types';
import type { Unionize, ValuesType } from 'utility-types';
import type { SensorName } from './SensorNames';
import SensorNames from './SensorNames';
import type { GetStatesOfSensor } from './SensorStates';

const BASE_SENSOR_EVENT_KEYS = [
  'stateCurrent',
  'stateCurrentInitDt',
  'statePrevious',
  'statePreviousInitDt',
  'sensorTriggeredUpdate'
] as const;
type BaseSensorEventKey = typeof BASE_SENSOR_EVENT_KEYS[number];
const KEYS_BY_NAME = {
  [SensorNames.battery]: [...BASE_SENSOR_EVENT_KEYS, 'voltageMv'],
  [SensorNames.gps]: [
    ...BASE_SENSOR_EVENT_KEYS,
    'location',
    'altitude',
    'nSats'
  ],
  [SensorNames.hallSwitch]: [...BASE_SENSOR_EVENT_KEYS],
  [SensorNames.pressure]: [...BASE_SENSOR_EVENT_KEYS, 'readingKpa'],
  [SensorNames.pressureSwitch]: [...BASE_SENSOR_EVENT_KEYS],
  [SensorNames.reel]: [
    ...BASE_SENSOR_EVENT_KEYS,
    'runDistanceMmCurrent',
    'runDistanceMmMax',
    'runSpeedMmpm',
    'runMagnetCountCurrent',
    'runMagnetCountMax',
    'speedMillirpm'
  ],
  [SensorNames.relay]: [...BASE_SENSOR_EVENT_KEYS],
  [SensorNames.temperature]: [...BASE_SENSOR_EVENT_KEYS, 'readingCelsius'],
  [SensorNames.wheel]: [...BASE_SENSOR_EVENT_KEYS, 'speedMmpm', 'milliRpm'],
  [SensorNames.device]: [
    ...BASE_SENSOR_EVENT_KEYS,
    'configurationId',
    'sensorPriority',
    'signalStrength',
    'signalQuality',
    'otaEnabled'
  ]
} as const;

type AnySensorEventKey = ValuesType<typeof KEYS_BY_NAME>[number];
type GetKeysOfSensorEvent<S extends SensorName> =
  typeof KEYS_BY_NAME[S][number];

/**
 * @param sensorName
 */
function getKeysOfSensorEvent<S extends SensorName = SensorName>(
  sensorName: S
): ReadonlyArray<GetKeysOfSensorEvent<S>> {
  return [...KEYS_BY_NAME[sensorName]];
}

type GetValueTypeOfSensorEventKey<
  S extends SensorName,
  K extends AnySensorEventKey
> = S extends S
  ? K extends K
    ? K extends 'location'
      ? PointGeoJson | null
      : K extends 'stateCurrent' | 'statePrevious'
      ? GetStatesOfSensor<S>
      : K extends 'sensorTriggeredUpdate'
      ? boolean
      : K extends 'stateCurrentInitDt' | 'statePreviousInitDt'
      ? DateString
      : Nullable<number>
    : never
  : never;

type GenericSensorEvent<S extends SensorName = SensorName> = {
  [K in AnySensorEventKey]: K extends GetKeysOfSensorEvent<S>
    ? GetValueTypeOfSensorEventKey<S, K>
    : undefined;
} & {
  sensorName: S;
};

type SensorEvent<S extends SensorName> = {
  [K in GetKeysOfSensorEvent<S>]: GetValueTypeOfSensorEventKey<S, K>;
} & {
  sensorName: S;
};

type SensorEventData = { [S in SensorName]: SensorEvent<S> };
type AnySensorEvent = ValuesType<Unionize<SensorEventData>>;

/**
 * @param sn
 * @param x
 */
function isKeyOfSensorEvent<S extends SensorName>(
  sn: S,
  x: unknown
): x is keyof SensorEvent<S> {
  const keys = [...BASE_SENSOR_EVENT_KEYS, ...KEYS_BY_NAME[sn]] as string[];

  if (typeof x === 'string') {
    return keys.includes(x);
  }
  return false;
}

export type {
  GetKeysOfSensorEvent,
  SensorEvent,
  BaseSensorEventKey,
  SensorEventData,
  GenericSensorEvent,
  AnySensorEvent,
  AnySensorEventKey,
  GetValueTypeOfSensorEventKey
};
export { isKeyOfSensorEvent, getKeysOfSensorEvent };
export default {
  BASE_SENSOR_EVENT_KEYS,
  getKeysOfSensorEvent,
  isKeyOfSensorEvent
};
