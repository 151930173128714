import { User } from 'src/userSession.reducer';
import type { SensorFormattingArgs } from './formatSensorValue';

/**
 * @param labelLength
 */
export default function useDefaultSensorFormatArgs(): SensorFormattingArgs {
  const isMetric = User.useIsMetric();
  const isAdmin = User.useIsAdmin();
  return {
    dtFormat: 'toTimeAgoText',
    isAdmin,
    isMetric,
    labelLength: 'short',
    unitLabel: true
  };
}
