import { isString } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Actions from 'src/actions';
import { APP_STYLE } from 'src/AppThemeProvider';
import { AppFab } from 'src/components';
import { AddDevice } from 'src/devices/device-farm-association';
import { Geo } from 'src/geo';
import { useAppDispatch, useIsMobile, useThunk } from 'src/hooks';
import { getSelectedReelRun } from 'src/reel-runs';
import { handleApiRequestError } from 'src/requests';
import SidebarLayout, { SidebarToggleBtn } from 'src/SidebarLayout';
import testIds from 'src/testIds';
import { AppIcon, showSuccessToast } from 'src/theme';

import { ClickAwayListener, createStyles, Grow, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { GoogleMap } from '@react-google-maps/api';
import * as turf from '@turf/turf';

import { getActiveFeature, getSelectedDeviceId, getSelectedFieldId } from '../appSelectors';
import { useMapController } from '../components/map';
import ActiveFarmMarker from '../components/map/FarmMarker';
import {
    DeviceMarker, getAllDeviceIds, getLastEventIds, getPairs, getSelectedEventLocation,
    getSelectedPairId
} from '../devices';
import DeviceActions from '../devices/DeviceActions';
import PairLine from '../devices/pairing/PairLine';
import { FarmFields } from '../fields';
import { isNullish, isNum, isTruthyString, notNullish } from '../types';
import { getFarmLocationGmaps } from '../userSession.reducer';
import asyncUpdateMap from './asyncUpdateMap';
import CreateFieldManager from './create-field';
import FieldActions from './FieldActions';
import { Point } from './geo';
import { MapTypeMenu } from './MapTypeMenu';
import SearchBar from './SearchBar';
import { getMapTypeId } from './statusMap.reducer';
import StatusMapField from './StatusMapField';
import StatusMapReelRuns from './StatusMapReelRuns';
import StatusMapSidebar from './StatusMapSidebar';
import { ZoomTracker } from './ZoomTracker';

import type { Palette } from '@material-ui/core/styles/createPalette';
import type { ConnectedProps } from 'react-redux';
import type { LatLng } from 'src/geo';
import type { RootSelector } from 'src/store';
import type { MapTypeId } from './statusMap.reducer';
/**
 * Button overlaid on the map that refreshes reel runs and device events
 * without the user needing to refresh the page
 *
 * @param props
 * @param props.className
 */
function StatusMapRefreshButton({ className }: { className: string }) {
  // const sidebarDocked = useSelector(Sidebar.getIsDocked);
  const { sendRequest, isPending } = useThunk(asyncUpdateMap);

  const handleClick: () => Promise<void> = React.useCallback(async () => {
    await sendRequest(null)
      .then(() => {
        showSuccessToast('Events updated');
      })
      .catch(handleApiRequestError);
  }, [sendRequest]);
  return (
    <AppFab className={className} disabled={isPending} onClick={handleClick}>
      <AppIcon iconKey="REFRESH" />
    </AppFab>
  );
}

// type StatusMapClasses = {
//   mapContainer: string;
//   bottomCard: string;
//   refreshButton: string;
//   listToggle: string;
// };

const getSelectedFieldCenter: RootSelector<LatLng | null> = createSelector(
  getSelectedFieldId,
  FarmFields.selectEntities,
  (id, fields) => {
    if (notNullish(id)) {
      const center = fields[id]?.center;
      if (notNullish(center)) {
        return Geo.points.to.googleMaps(center);
      }
    }
    return null;
  }
);

const getPanToPoint: RootSelector<LatLng | null> = createSelector(
  getSelectedEventLocation,
  getSelectedFieldCenter,
  getActiveFeature,
  getSelectedReelRun,
  (event, fieldCenter, activeFeature, run) => {
    if (event) {
      return new Point(event)
        .projectNewPoint({
          azimuth: 0,
          distanceMm: 400000
        })
        .to('google');
    }
    if (run && run.endPoint) {
      return new Point(run.endPoint)
        .projectNewPoint({
          azimuth: 0,
          distanceMm: 400000
        })
        .to('google');
    }
    let lng: number | undefined = undefined;
    let lat: number | undefined = undefined;
    switch (activeFeature) {
      case 'VIEW_RUN_HISTORY': {
        const pt = notNullish(fieldCenter)
          ? turf.destination(
              turf.point([fieldCenter.lng, fieldCenter.lat]).geometry,
              200,
              0,
              { units: 'meters' }
            )
          : undefined;
        if (notNullish(pt)) {
          lng = pt.geometry.coordinates[0];
          lat = pt.geometry.coordinates[1];
        }
        break;
      }

      default:
        lng = (event ?? fieldCenter)?.lng;
        lat = (event ?? fieldCenter)?.lat;
    }

    if (isNum(lng) && isNum(lat)) {
      return { lng, lat };
    }
    return null;
  }
);
type StyleProps = {
  mapTypeId: MapTypeId;
};

/**
 * Changes map control button colors based on the user's
 * map type preference
 * @param props
 * @param palette
 */
function getMapButtonColors(
  props: StyleProps,
  palette: Palette
): React.CSSProperties {
  if (props.mapTypeId === 'terrain') {
    return {
      backgroundColor: palette.secondary.main,
      color: palette.common.white
    };
  }
  return {
    backgroundColor: palette.common.white,
    color: palette.secondary.main
  };
}

const useStyles = makeStyles(({ spacing, palette }) => {
  const buttonSpacing = APP_STYLE.sidebarToggleSpacing;

  return createStyles({
    root: {},
    mapContainer: () => ({
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0
    }),
    sidebarToggle: (props) => ({ ...getMapButtonColors(props, palette) }),
    searchContainer: {
      position: 'absolute',
      bottom: spacing(buttonSpacing.y),
      right: spacing(buttonSpacing.x)
    },
    searchButton: (props: StyleProps) => ({
      ...getMapButtonColors(props, palette),
      position: 'absolute',
      bottom: spacing(buttonSpacing.y),
      right: spacing(buttonSpacing.x + 10)
    }),
    bottomCard: {
      bottom: 0,
      left: 0,
      maxHeight: 500,
      maxWidth: APP_STYLE.mapCardMaxWidth,
      minWidth: 360,
      position: 'absolute'
    },
    refreshBtn: (props) => ({
      position: 'absolute',
      ...getMapButtonColors(props, palette),
      bottom: spacing(buttonSpacing.y),
      left: spacing(buttonSpacing.x)
    })
  });
});

const connector = connect((state) => {
  const farmLocation = getFarmLocationGmaps(state);
  const selectedDeviceId = getSelectedDeviceId(state);

  return {
    farmLocation,
    panToPoint: getPanToPoint(state),
    activeFeature: getActiveFeature(state),
    deviceIds: getAllDeviceIds(state),
    eventIds: getLastEventIds(state),
    fieldIds: FarmFields.selectIds(state) as number[],
    mapTypeId: getMapTypeId(state),
    pairs: getPairs(state),
    selectedDeviceId,
    selectedPairId: getSelectedPairId(state)
  };
});
type SearchState = {
  searchValue: string;
  showSearch: boolean;
};
type Props = ConnectedProps<typeof connector>;

const INITIAL_SEARCH_STATE = {
  searchValue: '',
  showSearch: false
};
const StatusMap = React.memo<Props>((props): JSX.Element => {
  const {
    farmLocation,
    selectedPairId,
    eventIds,
    pairs,
    selectedDeviceId,
    fieldIds,

    mapTypeId,
    activeFeature,
    panToPoint
  } = props;
  const isMobile = useIsMobile();

  const classes = useStyles({ mapTypeId });
  const dispatch = useAppDispatch();

  const [state, setState] = React.useState<SearchState>(INITIAL_SEARCH_STATE);

  const { handleZoomChange, currentZoom, getMapRef, mapRef } =
    useMapController();

  const handleClickMap = React.useCallback((): void => {
    dispatch(Actions.closeSidebar());
  }, [dispatch]);
  const handleToggleSearch = React.useCallback((): void => {
    setState((prev) => ({ ...prev, showSearch: !prev.showSearch }));
  }, []);

  const optionsMemo = React.useMemo(
    (): google.maps.MapOptions => ({
      disableDefaultUI: true,
      mapTypeId
    }),
    [mapTypeId]
  );

  React.useEffect((): void => {
    if (notNullish(panToPoint)) {
      mapRef.current?.panTo(panToPoint);
    }
  }, [mapRef, panToPoint]);

  const handleSearchInput = (val: string): void =>
    setState((prev) => ({ ...prev, searchValue: val }));

  return (
    <SidebarLayout
      drawerContent={<StatusMapSidebar searchValue={state.searchValue} />}
      id={testIds.statusMap.root}
    >
      {isNullish(farmLocation) ? null : (
        <GoogleMap
          center={{
            lng: -122.4256588,
            lat: 48.5417881
          }}
          id={testIds.statusMap.map}
          mapContainerClassName={classes.mapContainer}
          mapTypeId={mapTypeId}
          onClick={handleClickMap}
          onLoad={getMapRef}
          onZoomChanged={handleZoomChange}
          options={optionsMemo}
          zoom={16}
        >
          <ActiveFarmMarker />
          <ZoomTracker currentZoom={currentZoom} />
          {isTruthyString(selectedDeviceId) ? (
            <DeviceActions deviceId={selectedDeviceId} />
          ) : null}
          {eventIds.map((id) => (
            <DeviceMarker
              deviceId={id}
              key={id}
              searchValue={state.searchValue}
            />
          ))}
          {pairs.map(({ sourceDeviceId, targetDeviceId, id }) =>
            isString(targetDeviceId) ? (
              <PairLine
                isHighlighted={
                  sourceDeviceId === selectedDeviceId ||
                  targetDeviceId === selectedDeviceId
                }
                isSelected={id === selectedPairId}
                key={id}
                sourceDeviceId={sourceDeviceId}
                targetDeviceId={targetDeviceId}
              />
            ) : null
          )}
          {fieldIds.map((id) => (
            <StatusMapField
              fieldId={id}
              key={id}
              searchVal={state.searchValue}
            />
          ))}
          <StatusMapReelRuns />

          <MapTypeMenu />
          {activeFeature === 'CREATE_FIELD' ? (
            <CreateFieldManager />
          ) : activeFeature === 'ADD_DEVICE' ? (
            <AddDevice />
          ) : activeFeature === 'SET_MAP_TYPE_ID' ? (
            <MapTypeMenu />
          ) : null}
          <SidebarToggleBtn className={classes.sidebarToggle} />
          <AppFab
            className={classes.searchButton}
            isHidden={!isMobile}
            onClick={handleToggleSearch}
          >
            <Search />
          </AppFab>
          <Grow in={state.showSearch}>
            <div>
              {state.showSearch ? (
                <ClickAwayListener onClickAway={handleToggleSearch}>
                  <div>
                    <SearchBar
                      classes={classes}
                      onChange={handleSearchInput}
                      value={state.searchValue}
                    />
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </Grow>
          <FieldActions />
          <StatusMapRefreshButton className={classes.refreshBtn} />
        </GoogleMap>
      )}
    </SidebarLayout>
  );
});
StatusMap.displayName = 'StatusMap';

export default connector(StatusMap);
