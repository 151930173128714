import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { AppButton } from 'src/components';
import type {
  ConfigSensorName,
  GetConfigKeysBySensorName,
  SensorConfig
} from 'src/devices/sensors';
import { SensorConfigs } from 'src/devices/sensors';
import { isNullish } from 'src/types';
import { appLogger } from 'src/utilities';
import { useSensorConfigByDeviceId } from '../selectors';
import {
  useConfigManagerState,
  useConfigMangerActions
} from './configManager.reducer';
import { SensorKeyValueRow } from './SensorKeyValueRow';

type Props<S extends ConfigSensorName, K extends keyof SensorConfig<S>> = {
  sensorName: S;
  showKeys?: K[];
  deviceId: string;
};

/**
 *
 * @param props
 * @param props.sensorName name of the sensor displayed
 * @param props.showKeys if provided, only show the specified key-value pairs (default is to show all)
 * @param props.deviceId
 * @param props.rest
 */
export default function SensorCard<
  S extends ConfigSensorName,
  K extends GetConfigKeysBySensorName<S>
>({ sensorName, showKeys, deviceId }: Props<S, K>): JSX.Element | null {
  const { selectedKey, selectedSensor } = useConfigManagerState();
  const { selectKey: handleClickEdit } = useConfigMangerActions();
  const getIsReadOnly = SensorConfigs.useReadOnlyKeys();
  const sensorData = useSensorConfigByDeviceId(sensorName, deviceId);

  if (isNullish(sensorData)) {
    return null;
  }
  const keysList = showKeys ?? SensorConfigs.getKeysOfSensorConfig(sensorName);

  const testId = `${sensorName}-values`;
  return (
    <Table data-cy={testId} id={testId}>
      <TableHead>
        <TableRow>
          <TableCell>Configuration</TableCell>
          <TableCell align="right">Value</TableCell>
          <TableCell align="right">Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keysList.map((key) => {
          if (!SensorConfigs.isKeyOfSensorConfig(sensorName, key)) {
            return null;
          }
          const initialValue = sensorData[key];

          if (typeof initialValue === 'undefined') {
            appLogger.warn(`Got undefined value for key ${key}`);
            return null;
          }

          const isEditing =
            key === selectedKey && sensorName === selectedSensor;
          return (
            <SensorKeyValueRow
              deviceId={deviceId}
              fieldName={key}
              initialValue={initialValue ?? null}
              isEditing={isEditing}
              key={key}
              sensorName={sensorName}
            >
              <Box display="flex" justifyContent="flex-end" my={1} width="100%">
                {getIsReadOnly(key) ? (
                  <AppButton
                    disabled
                    helpText="This field is read-only"
                    size="small"
                    variant="outlined"
                  >
                    Read Only
                  </AppButton>
                ) : (
                  <AppButton
                    iconKey="EDIT_ITEM"
                    id="edit"
                    onClick={() => handleClickEdit<S>(sensorName, key)}
                    requiredPermissions="canManageDeviceConfiguration"
                    size="small"
                    text="edit"
                    variant="outlined"
                  />
                )}
              </Box>
            </SensorKeyValueRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
