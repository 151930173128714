import React from 'react';
import { useSelector } from 'react-redux';
import testIds from 'src/testIds';
import type { DeviceMetadata } from '../models';
import CreatePair from './CreatePair';
import CurrentPairsList from './CurrentPairs';

/**
 * @param props
 * @param props.deviceId
 * @param props.deviceName
 * @param props.deviceInstallationType
 */
export default function DevicePairing({
  deviceId,
  deviceName,
  deviceInstallationType
}: DeviceMetadata): JSX.Element | null {
  const isPairing = useSelector((s) => s.triggers.isPairing);

  const id = testIds.devicePairing.root;
  return (
    <div data-cy={id} id={id}>
      {isPairing ? (
        <CreatePair
          deviceId={deviceId}
          deviceInstallationType={deviceInstallationType}
          deviceName={deviceName}
        />
      ) : (
        <CurrentPairsList deviceId={deviceId} deviceName={deviceName} />
      )}
    </div>
  );
}
