import React from 'react';
import { connect } from 'react-redux';
import { getRunCompletionPctByEventId } from 'src/devices/calculateRunCompletionPct';
import testIds from 'src/testIds';
import { AppIcon } from 'src/theme';
import { isNullish, notNullish } from 'src/types';

import {
    Avatar, LinearProgress, ListItem, ListItemAvatar, ListItemText, Typography
} from '@material-ui/core';

import Actions from '../actions';
import { getRunEtaByDeviceId } from '../devices/calculateReelEta';
import InstallationTypes from '../devices/installation-type';
import {
    getDeviceMetadataById, getPrimaryEventSensorName, getSensorEventByDeviceId
} from '../devices/selectors';
import Sensors, { SensorNames } from '../devices/sensors';
import { useAppDispatch } from '../hooks';
import getDeviceIsSearchMatch from './getDeviceIsSearchMatch';

import type { ConnectedProps } from 'react-redux';
import type { SensorEvent } from '../devices/sensors';
const Ids = testIds.deviceList.item;

type Props = {
  id: string;
  isSelected: boolean;
  searchValue: string;
};

const formatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'medium'
});
const connector = connect((state, props: Props) => {
  const isSearchMatch = getDeviceIsSearchMatch(
    state,
    props.id,
    props.searchValue
  );
  const metadata = getDeviceMetadataById(state, props.id);
  if (isNullish(metadata)) {
    throw new Error('No metadata');
  }
  const primarySensorName = getPrimaryEventSensorName(state, props.id);
  const primaryState = getSensorEventByDeviceId(
    state,
    props.id,
    primarySensorName
  )?.stateCurrent;
  const pressure = getSensorEventByDeviceId(state, props.id, 'pressure');
  const { deviceInstallationType } = metadata;
  return {
    runEta: getRunEtaByDeviceId(state, props.id),
    primarySensorName,
    primaryState,
    ...props,
    ...metadata,

    readingKpa: (pressure as Partial<SensorEvent<'pressure'> | undefined>)
      ?.readingKpa,
    isSearchMatch,
    installationTypeFormatted: deviceInstallationType
      ? InstallationTypes.humanize(deviceInstallationType)
      : null,

    primarySensorNameFormatted: notNullish(primarySensorName)
      ? SensorNames.humanize(primarySensorName)
      : null,
    completion: getRunCompletionPctByEventId(state, props.id)
  };
});

const DeviceListRow = React.memo<ConnectedProps<typeof connector>>((props) => {
  const {
    id: deviceId,
    isSelected,
    installationTypeFormatted,
    readingKpa,
    runEta,
    primaryState,
    primarySensorNameFormatted,
    completion,
    codaDeviceAlias,
    deviceName,
    deviceInstallationType
  } = props;
  const dispatch = useAppDispatch();

  const formatValue = Sensors.useFormatValue();
  const testId = Ids.root(codaDeviceAlias);
  const handleClick = React.useCallback(() => {
    dispatch(Actions.selectItem({ id: deviceId, kind: 'device' }));
  }, [deviceId, dispatch]);

  const primaryStateFormatted = notNullish(primaryState)
    ? formatValue('stateCurrent', primaryState)
    : null;
  const sensorStateText =
    notNullish(primarySensorNameFormatted) &&
    notNullish(primaryStateFormatted) ? (
      <Typography
        color="textSecondary"
        data-cy={Ids.primarySensorName}
        id={Ids.primarySensorName}
        variant="overline"
      >
        {primarySensorNameFormatted}{' '}
        <Typography
          color="textPrimary"
          component="span"
          data-cy={Ids.primarySensorState}
          id={Ids.primarySensorState}
          variant="inherit"
        >
          {primaryStateFormatted}
        </Typography>
      </Typography>
    ) : null;
  const etaFormatted = notNullish(runEta) ? (
    <Typography
      component="span"
      data-cy={Ids.etaText}
      id={Ids.etaText}
      variant="inherit"
    >
      ETA: {formatter.format(runEta)}
    </Typography>
  ) : null;
  const progressBar = notNullish(completion) ? (
    <LinearProgress
      data-cy={Ids.progressBar}
      id={Ids.progressBar}
      value={completion}
      variant="determinate"
    />
  ) : null;
  const psiFormatted = notNullish(readingKpa) ? (
    <Typography data-cy={Ids.psiText} id={Ids.psiText}>
      {' '}
      {formatValue('readingKpa', readingKpa)}
    </Typography>
  ) : null;
  return (
    <ListItem
      alignItems="center"
      button
      data-cy={testId}
      dense
      divider
      id={testId}
      onClick={handleClick}
      selected={isSelected}
    >
      <ListItemAvatar>
        <Avatar>
          <AppIcon iconKey={deviceInstallationType ?? 'unconfigured'} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        data-cy={Ids.textContainer}
        disableTypography
        id={Ids.textContainer}
        primary={
          <Typography variant="body2">
            {deviceId}

            <Typography
              color="textSecondary"
              component="span"
              data-cy={Ids.installationType}
              id={Ids.installationType}
              variant="inherit"
            >
              {installationTypeFormatted}{' '}
            </Typography>
            <Typography
              component="span"
              data-cy={Ids.installationType}
              id={Ids.deviceName}
              variant="inherit"
            >
              {deviceName}
            </Typography>
          </Typography>
        }
        secondary={
          <Typography component="div" variant="body2">
            {psiFormatted}
            {etaFormatted}
            {progressBar}
            {sensorStateText}
          </Typography>
        }
      />
    </ListItem>
  );
});
DeviceListRow.displayName = 'DeviceListRow';
export default connector(DeviceListRow);
