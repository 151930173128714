import type { Dictionary } from '@reduxjs/toolkit';
import { isNumber } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { appConfig } from 'src/configure-app';
import { getClosestFieldToEvent } from 'src/fields';
import { Geo } from 'src/geo';
import { isNullish, isPositiveInt, notNullish } from 'src/types';
import { appLogger } from 'src/utilities';
import createEntitySelectors from 'src/utilities/createEntitySelectors';

import center from '@turf/center';

import ReelRunSwath from './drawSwathOutline';
import HeatMaps from './HeatMaps';
import { ReelRunsActive } from './reelRunActive.reducer';

import type { DeviceConfig, SprinklerType } from 'src/devices';
import type { FarmField } from 'src/fields';
import type { LatLng, PointGeoJson } from 'src/geo';
import type { GmapsLatLngBoundsLiteral } from 'src/geo/bounds';
import type { PolygonGeoJson } from 'src/geo/polygons';
import type { RootState } from 'src/root.reducer';
import type { ReelRunHeatmapGeometry } from './drawSwathOutline';
import type { CompletedReports } from './HeatMaps';
import type { ReelRun } from './reelRunActive.reducer';
const makeCacheKey = (
  _state: RootState,
  runId: number,
  azimuthOverride?: number
) => `id=${runId};azimuthOverride=${azimuthOverride ?? 'null'}`;

const getById = (state: RootState, id: number) =>
  ReelRunsActive.selectById(state, id);
const getAzimuth = (_state: RootState, _id: number, azimuthOverride?: number) =>
  azimuthOverride;

const getConfigs = createEntitySelectors((state) => state.devices).entities;

const getSwathOutlineForRun = createCachedSelector(
  getById,
  getAzimuth,
  getConfigs,
  (runData, azimuthOverride, configs) => {
    if (runData) {
      const {
        reelSprinklerType,
        distanceMmMax,
        deviceId,
        directionOverrideAzimuthDegrees,
        fieldRowDirectionAzimuthDegrees,
        // fieldId,
        reelSwathWidthMm: reelSwathWidth,
        reelRunId,

        startPoint,
        endPoint
      } = runData;
      const azimuth =
        azimuthOverride ??
        directionOverrideAzimuthDegrees ??
        fieldRowDirectionAzimuthDegrees ??
        0;

      const config = configs[deviceId ?? ''];
      const sprinklerType =
        config?.reel?.sprinklerType ?? reelSprinklerType ?? 'gun';
      const swathWidthMm = config?.reel?.swathWidthMm ?? reelSwathWidth;
      const reelPosition = endPoint ? Geo.points.to.geoJson(endPoint) : null;
      if (isNullish(reelPosition) || isNullish(swathWidthMm)) {
        return undefined;
      }
      const outlineGeoJson = ReelRunSwath.drawSwathOutline({
        reelLocation: reelPosition,
        reelToStartAzimuthDegrees: azimuth,
        runDistanceTotalMm: distanceMmMax,
        sprinklerType,
        swathWidthMm
      });
      const centerGeoJson = center(outlineGeoJson);
      const centerGmaps = Geo.points.to.googleMaps(centerGeoJson);
      const outlineGmaps = Geo.polygons.to.gmaps(outlineGeoJson);
      return {
        centerGeoJson,
        centerGmaps,
        endPoint,
        id: reelRunId,
        outlineGeoJson,
        outlineGmaps,
        startPoint
      };
    }
    return undefined;
  }
)(makeCacheKey);

const getFieldForReelRun = createCachedSelector(
  getSwathOutlineForRun,
  (state: RootState) => state.farmFields.ids as number[],
  (state: RootState) => state.farmFields.entities,
  (runData, fieldIds, fieldEntities) => {
    if (runData) {
      let closest: number | undefined = undefined;
      // let distance: number | undefined = undefined;
      let shouldContinue = true;

      fieldIds.forEach((id: number) => {
        const polygon = fieldEntities[id]?.polygon;
        if (polygon && shouldContinue) {
          const buffered = Geo.polygons.make.buffer(polygon);

          const runCenter = runData.centerGeoJson.geometry;

          const containsCenter = Geo.polygons.containsPoint(
            buffered,
            runCenter
          );
          const { endPoint } = runData;
          const containsStart = Geo.polygons.containsPoint(buffered, endPoint);

          if (containsStart) {
            closest = id;
            shouldContinue = false;
          } else if (containsCenter) {
            if (isPositiveInt(closest)) {
              //TODO: add fallback logic here
            }
            closest = id;
          }
        }
      });
      if (notNullish(closest)) {
        return fieldEntities[closest];
      }
    }
    return undefined;
  }
)((_, id) => id);

type ReelRunVariant = 'ACTIVE' | 'HISTORICAL';
type HeatmapProps = ReelRunHeatmapGeometry & {
  key: string;
  pathGmaps: LatLng[];
};

type ReelRunGeometry = {
  azimuth: number | null;
  boundingBox: google.maps.LatLngBoundsLiteral | null;
  centerGeoJson: PointGeoJson | null;
  centerGmaps: google.maps.LatLngLiteral | null;
  hosePath: LatLng[];
  inField: FarmField | null;
  nearField: FarmField | null;
  gunPositionMax: PointGeoJson | null;
  gunPositionMaxGmaps: LatLng | null;
  gunPositionCurrent: PointGeoJson | null;
  gunPositionCurrentGmaps: LatLng | null;
  reelPosition: PointGeoJson | null;
  reelPositionGmaps: LatLng | null;
  heatmapPanes: HeatmapProps[];
  sprinklerType: SprinklerType;
  swathOutlinePolygon: PolygonGeoJson | null;
  swathOutlinePath: LatLng[];
  distanceMmCurrent: number | null;
  swathWidthMm: number | null;
};

type GeometryArgs = {
  runData: ReelRun | undefined;
  fields: FarmField[];
  configs: Dictionary<DeviceConfig>;
  azimuthOverride?: number | null;
};

/**
 * @param args
 * @param args.runData
 * @param args.fields
 * @param args.configs
 * @param args.azimuthOverride
 */
export function calculateRunGeometry({
  runData,
  fields,
  configs,
  azimuthOverride
}: GeometryArgs): ReelRunGeometry {
  const config = configs[runData?.deviceId ?? '']?.reel;
  let swathWidthMm = -1;
  let sprinklerType: SprinklerType = 'gun';
  if (config) {
    swathWidthMm = config.swathWidthMm ?? swathWidthMm;
    sprinklerType = config.sprinklerType ?? sprinklerType;
  } else if (runData) {
    swathWidthMm = runData.reelSwathWidthMm ?? swathWidthMm;
    sprinklerType = runData.reelSprinklerType ?? 'boom';
  }

  const reelPosition = runData?.endPoint
    ? Geo.points.to.geoJson(runData?.endPoint)
    : null;

  const inField = fields.find((f) => f.id === runData?.fieldId) ?? null;
  const distanceMmMax = runData?.distanceMmMax;
  const azimuth =
    azimuthOverride ??
    runData?.directionOverrideAzimuthDegrees ??
    runData?.fieldRowDirectionAzimuthDegrees ??
    inField?.rowDirectionAzimuthDegrees ??
    0;

  if (!isNumber(azimuth)) {
    appLogger.error(`No azimuth for run: ${runData?.reelRunId}`);
    // throw new Error(`No azimuth for run`);
  }

  // * We flip the azimuth here because it is being project FROM the reel in
  // * the opposite direction it moves during watering
  // ? NOTE: right now i am ignoring the gun location from the db because it seems
  // ? to be wrong be 180 degrees
  const observations = runData?.observations;
  let gunPositionMax: PointGeoJson | null = null;
  let completedReports: CompletedReports = [];
  let swathOutlinePolygon: PolygonGeoJson | null = null;
  let gunPositionCurrent: PointGeoJson | null = null;
  let centerGeoJson: PointGeoJson | null = null;
  let nearField: FarmField | null = null;
  let boundingBox: GmapsLatLngBoundsLiteral | null = null;
  let heatmapPanes: HeatmapProps[] = [];
  let distanceMmCurrent: number | null = null;

  if (
    notNullish(azimuth) &&
    notNullish(distanceMmMax) &&
    notNullish(reelPosition)
  ) {
    nearField = getClosestFieldToEvent({
      fields,
      location: reelPosition
    });

    gunPositionMax = Geo.points.make.projection({
      azimuth,
      distanceMm: distanceMmMax,
      origin: reelPosition
    }).geometry;

    if (notNullish(observations)) {
      completedReports = HeatMaps.addGunPositionsToObservationReports({
        azimuth: azimuth - 180,
        distanceMmMax,
        gunPositionMax,
        observations
      });

      if (appConfig.deployment === 'DEMO' && isNullish(swathWidthMm)) {
        swathWidthMm = 24000;
      }

      const lastObservation = completedReports[completedReports.length - 1];
      distanceMmCurrent = lastObservation?.distanceObservedMm ?? null;
      heatmapPanes =
        notNullish(sprinklerType) && isPositiveInt(swathWidthMm)
          ? completedReports.map(
              (
                { gunStartPointGeoJSON, gunEndPointGeoJSON, ...report },
                index
              ) => {
                const geoms = ReelRunSwath.drawHeatmapPolygon({
                  gunEndPosition: gunEndPointGeoJSON,
                  gunStartPosition: gunStartPointGeoJSON,
                  gunToReelAzimuthDegrees: azimuth,
                  isFirst: index === 0,
                  isMostRecent: index === heatmapPanes.length - 1,
                  sprinklerType,
                  swathWidthMm
                });
                return {
                  // gunEndPointGmaps: Geo.points.to.googleMaps(gunEndPointGeoJSON),
                  // gunStartPointGmaps: Geo.points.to.googleMaps(gunStartPointGeoJSON),
                  pathGmaps: Geo.polygons.to.gmaps(geoms.polygon),
                  ...geoms,
                  key: `${index}/distance=${report.distanceObservedMm ?? 'N/A'}`
                };
              }
            )
          : [];
      gunPositionCurrent =
        HeatMaps.getLastGunPosition(completedReports) ?? null;
    }
    swathOutlinePolygon =
      notNullish(swathWidthMm) && notNullish(sprinklerType)
        ? ReelRunSwath.drawSwathOutline({
            reelLocation: reelPosition,
            reelToStartAzimuthDegrees: azimuth,
            runDistanceTotalMm: distanceMmMax,
            sprinklerType,
            swathWidthMm
          })
        : null;
  }

  if (isNullish(swathWidthMm)) {
    appLogger.warn('No swath width');
  }

  if (isNullish(sprinklerType)) {
    appLogger.warn('No sprinkler type');
  }

  if (notNullish(swathOutlinePolygon)) {
    centerGeoJson = Geo.polygons.get.center(swathOutlinePolygon).geometry;
    boundingBox = Geo.bounds.to.gmaps(
      Geo.polygons.get.bbox(swathOutlinePolygon)
    );
  }
  return {
    azimuth,
    boundingBox,
    centerGeoJson,
    centerGmaps: notNullish(centerGeoJson)
      ? Geo.points.to.googleMaps(centerGeoJson)
      : null,
    distanceMmCurrent,
    heatmapPanes,
    hosePath:
      notNullish(gunPositionCurrent) && notNullish(reelPosition)
        ? [reelPosition, gunPositionCurrent].map(Geo.points.to.googleMaps)
        : [],
    inField,
    nearField,
    gunPositionCurrent,
    gunPositionMax,
    gunPositionMaxGmaps: notNullish(gunPositionMax)
      ? Geo.points.to.googleMaps(gunPositionMax)
      : null,
    gunPositionCurrentGmaps: isNullish(gunPositionCurrent)
      ? null
      : Geo.points.to.googleMaps(gunPositionCurrent),
    reelPosition,
    reelPositionGmaps: notNullish(reelPosition)
      ? Geo.points.to.googleMaps(reelPosition)
      : null,
    sprinklerType: sprinklerType ?? 'gun',
    swathOutlinePolygon,
    swathOutlinePath: notNullish(swathOutlinePolygon)
      ? Geo.polygons.to.gmaps(swathOutlinePolygon)
      : [],
    swathWidthMm
  };
}

export type { ReelRunGeometry, ReelRunVariant };
export { getSwathOutlineForRun, getFieldForReelRun };
