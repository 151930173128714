import React from 'react';
import { useSelector } from 'react-redux';
import Actions from 'src/actions';
import { AppButton } from 'src/components';
import PATHS from 'src/constants/Paths';
import { useAppDispatch } from 'src/hooks';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import { DeviceUserAssociations } from '../device-user';

const TEST_ID = `alerts-toggle-denied`;
const CONTENT_TEXT = `You have no verified phone numbers. You can register and verify phone
    numbers in your account settings menu.`;

const TITLE = `No Verified Phone Numbers`;
/**
 * If user has no verified phone numbers and attempts to toggle the alert subscription,
 * show this dialog
 */
export default function AlertsToggleDeniedDialog(): JSX.Element | null {
  const isDenied = useSelector(
    DeviceUserAssociations.isAlertsTogglePermissionDenied
  );
  const dispatch = useAppDispatch();
  return isDenied ? (
    <Dialog data-cy={TEST_ID} id={TEST_ID} open>
      <DialogTitle>{TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{CONTENT_TEXT}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <AppButton presetKey="CLOSE_DIALOG" />
        <AppButton
          linkTo={PATHS.account.base}
          onClick={() => dispatch(Actions.closeDialog())}
        >
          Account Settings
        </AppButton>
      </DialogActions>
    </Dialog>
  ) : null;
}
