import type { PolylineProps } from '@react-google-maps/api';
import { Polyline } from '@react-google-maps/api';
import React from 'react';
import type { HideableProps } from 'src/components';

export type PolylineOptions = {
  readonly clickable?: boolean;
  /**
   * If set to true, the user can drag this shape over the map.
   * The geodesic property defines the mode of dragging. Defaults to false.
   */
  readonly draggable?: boolean;
  /**
   * If set to true, the user can edit this shape by dragging the control
   * points shown at the vertices and on each segment. Defaults to false.
   */
  readonly editable?: boolean;
  /**
   * When true, edges of the polygon are interpreted as geodesic and will
   * follow the curvature of the Earth. When false, edges of the polygon are
   * rendered as straight lines in screen space. Note that the shape of a
   * geodesic polygon may appear to change when dragged, as the dimensions are
   * maintained relative to the surface of the earth. Defaults to false.
   */
  readonly geodesic?: boolean;
  /** The icons to be rendered along the polyline. */
  // icons?: IconSequence[];
  /** Map on which to display Polyline. */
  // map?: Map;
  /**
   * The ordered sequence of coordinates of the Polyline.
   * This path may be specified using either a simple array of LatLngs, or an
   * MVCArray of LatLngs. Note that if you pass a simple array, it will be
   * converted to an MVCArray Inserting or removing LatLngs in the MVCArray
   * will automatically update the polyline on the map.
   */
  // path?: MVCArray<LatLng> | LatLng[] | LatLngLiteral[];
  /**
   * The stroke color. All CSS3 colors are supported except for extended
   * named colors.
   */
  readonly strokeColor?: string;
  /** The stroke opacity between 0.0 and 1.0. */
  readonly strokeOpacity?: number;
  /** The stroke width in pixels. */
  readonly strokeWeight?: number;
  /** Whether this polyline is visible on the map. Defaults to true. */
  readonly visible?: boolean;
  /** The zIndex compared to other polys. */
  readonly zIndex?: number;
};

export type PolylineBaseProps = HideableProps &
  Omit<PolylineOptions, 'visible'> &
  Pick<PolylineProps, 'draggable' | 'editable' | 'onClick' | 'onLoad' | 'path'>;

/**
 * @param props
 * @param props.strokeColor
 * @param props.strokeOpacity
 * @param props.strokeWeight
 * @param props.editable
 * @param props.geodesic
 * @param props.draggable
 * @param props.clickable
 * @param props.isHidden
 * @param props.onClick
 * @param props.onLoad
 * @param props.zIndex
 * @param props.path
 * @param props.rest
 */
export default function PolylineBase({
  strokeColor,
  strokeOpacity,
  strokeWeight,
  editable,
  geodesic,
  draggable,
  clickable,
  isHidden,
  onClick,
  onLoad,
  zIndex,
  // ...rest
  path
}: PolylineBaseProps): JSX.Element {
  const visible = isHidden !== true;
  const optionsMemo = React.useMemo((): PolylineOptions => {
    return {
      clickable,
      draggable,
      editable,
      geodesic,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      zIndex
    };
  }, [
    clickable,
    draggable,
    editable,
    geodesic,
    strokeColor,
    strokeOpacity,
    strokeWeight,
    zIndex
  ]);
  return (
    <Polyline
      onClick={onClick}
      onLoad={onLoad}
      options={optionsMemo}
      path={path}
      visible={visible}
    />
  );
}
