import { isString } from 'lodash-es';
import type { ValuesType } from 'utility-types';

const passwordPatterns = [
  {
    message: `Password must contain at least one lowercase letter`,
    value: /^(?=.*[a-z])/
  },
  {
    message: `Password must contain at least one uppercase letter`,
    value: /^(?=.*[A-Z])/
  },
  // {
  //   message: 'Password must contain at least one special character (!@#$$^&*)',
  //   value: /^(?=.*[!@#$%^&*])/,
  // },
  {
    message: `Password must be at least 8 characters long`,
    value: /^(?=.{8,})/
  }
] as const;
const required = `This field is required.` as const;
const decimalNumber = {
  message: `This field must be a number (maximum 2 decimal places).`,
  value: new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/)
} as const;
const intOnly = {
  message: `This field must be a whole number.`,
  value: new RegExp(/^[0-9]*$/)
} as const;
const REQUIRED_INT = {
  pattern: intOnly,
  required,
  valueAsNumber: true
} as const;
type ValidatePasswordResult =
  | ValuesType<typeof passwordPatterns>['message']
  | true;

// https://stackoverflow.com/questions/308122/simple-regular-expression-for-a-decimal-with-a-precision-of-2
// ^                         # Start of string
//  [0-9]+                   # Require one or more numbers
//        (                  # Begin optional group
//         \.                # Point must be escaped or it is treated as "any character"
//           [0-9]{1,2}      # One or two numbers
//                     )?    # End group--signify that it's optional with "?"
//                       $   # End of string
export const FORM_VALIDATION = {
  authCode: {
    length: {
      message: `Code length must be 6 digits`,
      value: 6
    }
  },
  patterns: {
    decimalNumber,
    intOnly,
    newPassword: {
      // abc123222,
      // message: 'Password must contain lowercase and
      // uppercase characters and at least one number',
      // value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
    }
  },
  required,
  requiredInt: REQUIRED_INT,
  /**
   * @param newPassword
   */
  validateNewPassword: (newPassword: string) =>
    passwordPatterns.reduce<ValidatePasswordResult>(
      (prevResult, nextPattern) => {
        // If a failing message is accumulated, do not check further
        if (isString(prevResult)) {
          return prevResult;
        }
        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
        const isMatch = nextPattern.value.test(newPassword);

        if (isMatch) {
          // Continue checking if more patterns are left
          return true;
        }
        // Otherwise Stop new checks and set the acc to be the failing message
        return nextPattern.message;
      },
      true
    )
} as const;

export type SelectOption<T extends number | string = string> = {
  readonly key: T;
  readonly label: string;
};
/**
 * @param target
 */
