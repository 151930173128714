import { isFunction, isNumber, isUndefined, range } from 'lodash';
import { isNullish, notNullish } from 'src/types';
import { isPrimitive } from 'utility-types';

import { isPlainObject } from '@reduxjs/toolkit';
import * as turf from '@turf/turf';

export type LatLng = { latitude: number; longitude: number }
export type Format = "geojson" | "google" | "native"
export type Coordinates = number[]
export type PointGeoJson = { coordinates: Coordinates; type: "Point" }
export type LinearRing = Coordinates[]
export type PolygonGeoJson = { coordinates: LinearRing[]; type: "Polygon" }
export type PolygonPathGmaps = google.maps.LatLngLiteral[]
export type PolygonpathGeoJson = LatLng[]
export type InvalidGeometry = { geometry?: string; type: "invalid" }

const TEN_TO_THE_SEVENTH = 10000000
export const roundToMaxEightDigits = (c: number): number =>
	Math.round(c * TEN_TO_THE_SEVENTH) / TEN_TO_THE_SEVENTH

export const GEO_TYPES = ["google", "native", "geojson"] as const
export type GeoFormat = typeof GEO_TYPES[number]

/**
 * Converts any bearing angle from the north line direction (positive clockwise)
 * and returns an angle between 0-360 degrees (positive clockwise), 0 being the north line
 *
 * @name bearingToAzimuth
 * @param {number} bearing angle, between -180 and +180 degrees
 * @returns {number} angle between 0 and 360 degrees
 */
export function bearingToAzimuth(bearing: number): number {
	let angle = bearing % 360
	if (angle < 0) {
		angle += 360
	}
	return angle
}

/**
 * @param azimuth
 */

export function azimuthToTurfBearing(azimuth: number): { bearing: number } {
	// azimuth is between 0 and 360, where 0 is due north in both cases.
	// bearing goes from -180 to 180
	let bearing = azimuth
	if (azimuth === 360) {
		bearing = 0
	} else if (bearing > 180) {
		const degreesPastNorth = bearing % 180
		bearing = degreesPastNorth - 180 // distance from north line in opposite direction
	}
	return {
		bearing
	}
}

/**
 * Converts azimuth to human-readable bearing text
 *
 * @example
 *     // returns `10`\u00b0` SW
 *    convertAzimuthToBearingText(100)
 * @param azimuth
 * @throws {Error} if quadrant is calculated as anything but an integer 0-4
 */
export function convertAzimuthToBearingText(azimuth: number): string {
	const quadrantAsNumber = Math.floor(azimuth / 90)
	const degreesPastQuadrant = azimuth % 90
	/**
	 * Add degrees symbol and quadrant name
	 *
	 * @param quadrant
	 */
	const formatString = (quadrant: string): string =>
		`${degreesPastQuadrant}${`\u00b0`} ${quadrant}`

	switch (Math.abs(quadrantAsNumber)) {
		case 0:
		case 4:
			return formatString(`NE`)
		case 1:
			return formatString(`SE`)
		case 2:
			return formatString(`SW`)
		case 3:
			return formatString(`NW`)
		default:
			throw new Error(
				`Invalid quadrant: ${quadrantAsNumber} (azimuth=${azimuth})`
			)
	}
}

/**
 * Convert azimuth value to bearing with cardinal direction and degrees
 * between zero and 90
 *
 * @param azimuth
 */
export function humanizeAzimuth(azimuth: number): string {
	const rounded = Math.round(Math.abs(azimuth))
	switch (rounded) {
		case 0:
		case 360:
			return `Due North`
		case 90:
			return `Due East`
		case 180:
			return `Due South`
		case 270:
			return `Due West`
		default: {
			return convertAzimuthToBearingText(rounded)
		}
	}
}

/**
 * @param bounds
 * @throws {Error} if bounds type is invalid
 */
export function boundsToLatLngBoundsLiteral(
	bounds: google.maps.LatLngBoundsLiteral | turf.BBox
): google.maps.LatLngBoundsLiteral {
	if (Array.isArray(bounds)) {
		const [west, south, east, north] = bounds
		return boundsToLatLngBoundsLiteral({ east, north, south, west })
	} else if (isPlainObject(bounds)) {
		const { west, south, east, north } = bounds
		if ([west, south, east, north].every(isNumber)) {
			return { east, north, south, west } as google.maps.LatLngBoundsLiteral
		}
	}
	return bounds
}

export class Bounds implements google.maps.LatLngBoundsLiteral {
	readonly east: number
	readonly north: number
	readonly south: number
	readonly west: number

	constructor(initialBox: google.maps.LatLngBoundsLiteral | turf.BBox) {
		if (Array.isArray(initialBox)) {
			const [west, south, east, north] = initialBox
			this.east = east
			this.north = north
			this.south = south
			this.west = west
		} else {
			this.east = initialBox.east
			this.west = initialBox.west
			this.north = initialBox.north
			this.south = initialBox.south
		}
	}

	public getCoords(): google.maps.LatLngBoundsLiteral {
		return {
			east: this.east,
			north: this.north,
			south: this.south,
			west: this.west
		}
	}

	/**
	 * A GeoJSON object MAY have a member named "bbox" to include information on the coordinate range for its Geometries, Features, or FeatureCollections.
	 * The value of the bbox member MUST be an array of length 2*n where n is the number of dimensions represented in the contained geometries,
	 * with all axes of the most southwesterly point followed by all axes of the more northeasterly point.
	 * The axes order of a bbox follows the axes order of geometries.
	 *
	 * @returns Bbox
	 *
	 */
	public toBbox(): turf.BBox {
		return [this.west, this.south, this.east, this.north]
	}
}

/**
 * GeoJSON supports the following geometry types: Point, LineString, Polygon,
 * MultiPoint, MultiLineString, and MultiPolygon. Geometric objects with
 * additional properties are Feature objects. Sets of features are
 * contained by FeatureCollection objects.
 */
type PointFeature = turf.Feature<turf.Point>
export type PointInput =
	| google.maps.LatLng
	| google.maps.LatLngLiteral
	| LatLng
	| PointFeature
	| turf.Coord
	| turf.Point
	| turf.Position

export type PointOfFormat<F extends GeoFormat> = F extends F
	? F extends "google"
		? google.maps.LatLngLiteral
		: F extends "native"
		? LatLng
		: F extends "geojson"
		? {
				coordinates: turf.Position
				type: "Point"
		  }
		: never
	: never

export type AnyPoint =
	| PointOfFormat<"geojson">
	| PointOfFormat<"google">
	| PointOfFormat<"native">

/**
 * @param arg
 */

// export function drawPoint<F extends GeoFormat>(arg: {
//   azimuth: number;
//   distanceMm: number;
//   format: F;
//   origin: Point;
// }) {
//   const [lng, lat] = turf.destination(
//     new Point(arg.origin).to("geojson").coordinates,
//     arg.distanceMm / 1000,
//     arg.azimuth,
//     {
//       units: "meters",
//     }
//   ).geometry.coordinates;
//   if (typeof lng === "undefined" || typeof lat === "undefined") {
//     throw new Error("Non-number coordinate");
//   }
//   return new Point({ lng, lat });
// }

export class Point implements PointFeature {
	type: "Feature"
	geometry: turf.Point
	id?: turf.Id | undefined
	properties: {
		isValid?: boolean
	}
	bbox?: turf.BBox | undefined

	constructor(
		coordinates: PointInput,
		id?: string,
		properties?: { [key: string]: unknown }
	) {
		this.id = id
		this.properties = properties ?? {}
		this.type = "Feature"

		const pt = Point.fromCoords(coordinates)
		this.geometry = pt.geometry
	}
	/**
	 * getLng
	 */
	public getLng(): number {
		const lng = this.geometry.coordinates[0]
		if (isNullish(lng)) {
			throw new Error("lng is undefined")
		}
		return roundToMaxEightDigits(lng)
	}
	/**
	 * getLat
	 */
	public getLat(): number {
		const lat = this.geometry.coordinates[1]
		if (isNullish(lat)) {
			throw new Error("Lat is undefined")
		}
		return roundToMaxEightDigits(lat)
	}
	public getCoords(): number[] {
		return [this.getLng(), this.getLat()]
	}
	/**
	 *
	 * @param pointInput
	 * @returns
	 */
	public static fromCoords(pointInput: PointInput): turf.Feature<turf.Point> {
		if (isNullish(pointInput)) {
			throw new Error("Coordinates were undefined")
		}
		if (Array.isArray(pointInput)) {
			if (pointInput.length > 3) {
				throw new Error("Got extra points for coordinates")
			}
			const [lng, lat] = pointInput
			if (isNumber(lng) && isNumber(lat)) {
				return turf.point([lng, lat], { bbox: undefined, id: undefined })
			}
			throw new Error("Got invalid coordinates")
		}

		//  From google maps lat lng
		if ("lng" in pointInput && "lat" in pointInput) {
			if (isNumber(pointInput.lng) && isNumber(pointInput.lat)) {
				return this.fromCoords([pointInput.lng, pointInput.lat])
			} else if (isFunction(pointInput.lng) && isFunction(pointInput.lat)) {
				return this.fromCoords([pointInput.lng(), pointInput.lat()])
			}
		}
		// From react native maps point
		if ("longitude" in pointInput) {
			return this.fromCoords([pointInput.longitude, pointInput.latitude])
		}
		// From geojson
		if ("type" in pointInput) {
			if (pointInput.type === "Point") {
				return this.fromCoords(pointInput.coordinates)
			}
			return this.fromCoords(pointInput.geometry.coordinates)
		}
		throw new Error(`Invalid point input${JSON.stringify(pointInput)}`)
	}

	/**
	 * toJson
	 */
	public toJson(): PointFeature {
		return {
			bbox: this.bbox,
			geometry: {
				bbox: this.geometry.bbox,
				coordinates: [...this.geometry.coordinates],
				type: "Point"
			},
			properties: { ...this.properties },
			type: this.type
		}
	}

	/**
	 * toString
	 */
	public toString(): string {
		return JSON.stringify(this.toJson())
	}

	/**
	 * to
	 * @param geoFormat
	 */
	public to<F extends GeoFormat, P extends PointOfFormat<F>>(geoFormat: F): P {
		const lng = this.getLng()
		const lat = this.getLat()
		switch (geoFormat) {
			case "geojson": {
				return { coordinates: [lng, lat], type: "Point" } as P
			}
			case "google": {
				return { lat, lng } as P
			}
			case "native": {
				return { latitude: lat, longitude: lng } as P
			}
			default: {
				throw new Error(`Invalid format specified: ${geoFormat}`)
			}
		}
	}

	/**
	 * coordsEqual
	 * @param other
	 */
	public coordsEqual(
		other: google.maps.LatLngLiteral | LatLng | Point | turf.Point | undefined
	): boolean {
		if (notNullish(other)) {
			let target: Point
			if (other instanceof Point) {
				target = other
			} else {
				target = new Point(other)
			}

			return (
				this.getLng() === target.getLng() && this.getLat() === target.getLat()
			)
		}
		return false
	}

	/**
	 * project
	 *
	 *
	 * Note that negative latitudes represent the southern hemisphere,
	 * and negative longitudes represent the western hemisphere.
	 * @param arg
	 * @param arg.distanceMm
	 * @param arg.azimuth
	 */
	public projectNewPoint(arg: { azimuth: number; distanceMm: number }): Point {
		const bearing = arg.azimuth

		const [lng, lat] = turf.destination(
			this.to("geojson").coordinates,
			arg.distanceMm / 1000,
			bearing, // turf uses bearing -180 to 180, so we need to convert to azimuth
			{ units: "meters" }
		).geometry.coordinates
		if (isNullish(lng) || isNullish(lat)) {
			throw new Error("Non-number coordinate")
		}
		return new Point({ lat, lng })
	}

	public isWithinPolygon(
		linearRing: LinearRing[],
		options?: { bufferMeters?: number }
	): boolean {
		return turf.booleanPointInPolygon(
			this.getCoords(),
			turf.buffer(turf.polygon(linearRing), options?.bufferMeters ?? 0, {
				units: "meters"
			}).geometry
		)
	}

	/**
	 *
	 * @param param0
	 * @param param0.azimuth
	 * @param param0.lengthMm
	 * @param param0.widthMm
	 * @param param0.semiCircle
	 * @returns
	 *
	 *
	 * A linear ring MUST follow the right-hand rule with respect to the
	 * area it bounds, i.e., exterior rings are counterclockwise, and
	 * holes are clockwise
	 *
	 * Note: the [GJ2008] specification did not discuss linear ring winding
	 * order.  For backwards compatibility, parsers SHOULD NOT reject
	 * Polygons that do not follow the right-hand rule.
	 */
	public projectRectangle({
		widthMm,
		lengthMm,
		semiCircle,
		azimuth
	}: {
		azimuth: number
		lengthMm: number
		semiCircle: boolean
		widthMm: number
	}): {
		originReflected: Point
		outline: LinearRing
	} {
		const semicircleRadius = widthMm / 2
		/*
    Start at origin
    
                                 1
                                 |
    relative azimuth 0 <---      O
    */
		const corner1 = this.projectNewPoint({
			azimuth: azimuth + 90,
			distanceMm: semicircleRadius
		})
		const outline: LinearRing = [corner1.getCoords()]
		/*
    Turn left
                      
       2 --------------- 1
                         |
                         0
    */
		const corner2 = corner1.projectNewPoint({ azimuth, distanceMm: lengthMm })
		outline.push(corner2.getCoords())

		/*
    Turn left
                      
      2 --------------- 1
      |                 |
     tmp                0
    */
		const semicircleCenter = corner2.projectNewPoint({
			azimuth: azimuth + 270,
			distanceMm: semicircleRadius
		})
		if (semiCircle) {
			// make a new point every 10 degrees in 180-degree arc
			const start = (azimuth - 90) % 360
			const end = (azimuth + 90) % 360
			range(start, end, azimuth).forEach((angle) => {
				outline.push(
					semicircleCenter
						.projectNewPoint({
							azimuth: angle,
							distanceMm: semicircleRadius
						})
						.getCoords()
				)
			})
		}
		const lastPoint = outline[outline.length - 1]
		if (isUndefined(lastPoint)) {
			throw new Error("last point is undefined")
		}

		/* All the way across
      2 --------------- 1
      |                 |
      |                 0
      |
      3
    */
		const corner3 = corner2.projectNewPoint({
			azimuth: azimuth + 90,
			distanceMm: semicircleRadius
		})
		outline.push(corner3.getCoords())

		/*
    Back to  baseline
      2 --------------- 1
      |                 |
      |                 O
      |                 
      3 --------------- 5
    */
		const corner4 = corner2.projectNewPoint({
			azimuth: azimuth - 180,
			distanceMm: lengthMm
		})
		outline.push(corner4.getCoords())

		const closingPoint = corner4.projectNewPoint(
			/*
      Close it
      2 --------------- 1
      |                 |
      |                 6 
      |                 |
      3 --------------- 5
    */
			{
				azimuth: azimuth + 90,
				distanceMm: semicircleRadius
			}
		)
		outline.push(closingPoint.getCoords())

		return {
			originReflected: semicircleCenter,
			outline
		}
	}
}
export type PolygonOfType<T extends GeoFormat> = T extends "google"
	? {
			geometry: google.maps.LatLngLiteral[]
			type: "google"
	  }
	: T extends "native"
	? {
			geometry: LatLng[]
			type: "native"
	  }
	: T extends "geojson"
	? turf.Feature<turf.Polygon>
	: never

export function extractPolygonAsLinearRing(
	input: google.maps.Polygon
): turf.Position[] {
	return input
		.getPath()
		.getArray()
		.map(function convertLatLngToCoord(p: google.maps.LatLng): turf.Position {
			return new Point([p.lng(), p.lat()]).to("geojson").coordinates
		})
}

export type PolygonFeature = turf.Feature<turf.Polygon>

export type AnyPolygonInput =
	| google.maps.LatLng[][]
	| google.maps.LatLngLiteral[][]
	| google.maps.Polygon
	| LinearRing[]
	| PolygonFeature
	| turf.Point[][]
	| turf.Polygon

function makeError(input: unknown): InvalidGeometry {
	return {
		geometry: JSON.stringify(input),
		type: "invalid"
	}
}
export class Polygon implements turf.Feature<turf.Polygon> {
	type: "Feature"
	geometry: turf.Polygon
	id?: turf.Id | undefined
	properties: { isValid: boolean }
	bbox?: turf.BBox | undefined
	/**
	 * @param geometry
	 * @param id
	 */
	constructor(geometry: AnyPolygonInput, id?: string) {
		this.properties = { isValid: true }
		this.type = "Feature"
		this.id = id
		const polygon = Polygon.create(geometry)
		if (polygon.type === "Feature") {
			this.geometry = polygon.geometry
		} else {
			this.properties.isValid = false
			this.geometry = { coordinates: [], type: "Polygon" }
		}
	}
	// public static isValid<P extends AnyPoint>(
	//   target: unknown[] | undefined
	// ): target is [P, P, P, P] {
	//   if (notNullish(target)) {
	//     if (target.length > 3) {
	//       const first = target[0];
	//       const last = target[target.length - 1];
	//       if (
	//         notNullish(first) &&
	//         notNullish(last) &&
	//         Point &&
	//         isNumber(last)
	//       ) {
	//         return new Point(first).coordsEqual(new Point(last));
	//       }
	//     }
	//   }
	//   return false;
	// }

	public static create(
		input: AnyPolygonInput
	): InvalidGeometry | PolygonFeature {
		if (isPrimitive(input)) {
			return makeError(input)
		}

		if (Array.isArray(input)) {
			const rings: turf.Position[][] = input.map((ring) => {
				return ring.map((p) => {
					return new Point(p).to("geojson").coordinates
				})
			})

			const [outerRing, ...innerRings] = rings

			if (isNullish(outerRing) || outerRing.length < 3) {
				return makeError(outerRing)
			}
			const first = outerRing[0]
			const last = outerRing[outerRing.length - 1]

			if (isNullish(first) || isNullish(last)) {
				return makeError(outerRing)
			}
			const p1 = new Point(first)
			const pN = new Point(last)

			if (outerRing.length === 3 || !p1.coordsEqual(pN)) {
				outerRing.push(p1.to("geojson").coordinates)
			}
			const polygon = turf.polygon([outerRing, ...innerRings])
			if (turf.booleanClockwise(outerRing)) {
				/*
        A linear ring MUST follow the right-hand rule with respect to the
        area it bounds, i.e., exterior rings are counterclockwise, and
        holes are clockwise.  

        Note: the [GJ2008] specification did not discuss linear ring winding
        order.  For backwards compatibility, parsers SHOULD NOT reject
        Polygons that do not follow the right-hand rule.
        */
				return turf.rewind(polygon)
			}
			return polygon
		}
		if ("getPath" in input) {
			return Polygon.create([extractPolygonAsLinearRing(input)])
		}
		if (input.type === "Polygon") {
			return Polygon.create(input.coordinates)
		}
		return Polygon.create(input.geometry.coordinates)
	}

	public getCenter(): Point {
		return new Point(turf.centerOfMass(this.geometry))
	}
	public addBuffer(meters = 50): Polygon {
		return new Polygon(turf.buffer(this, meters, { units: "meters" }))
	}

	public getOuterRing(): {
		toCoords(): turf.Position[]
		toGmaps(): google.maps.LatLngLiteral[]
		toNative(): LatLng[]
		toPoints: () => Point[]
	} {
		const ring = this.geometry.coordinates[0] ?? []

		const toPoints = (): Point[] => {
			return ring.map((p) => new Point(p))
		}
		return {
			toCoords(): turf.Position[] {
				return ring
			},
			toGmaps(): google.maps.LatLngLiteral[] {
				return toPoints().map((p) => p.to("google"))
			},
			toNative(): LatLng[] {
				return toPoints().map((p) => p.to("native"))
			},
			toPoints
		}
	}
	public to<F extends GeoFormat, P extends PolygonOfType<F>>(geoFormat: F): P {
		const outerRing = this.getOuterRing()

		if (geoFormat === "geojson") {
			return turf.polygon([outerRing.toCoords()]) as P
		}

		if (geoFormat === "google") {
			return {
				geometry: outerRing.toGmaps(),
				type: "google"
			} as P
		}

		return {
			geometry: outerRing.toNative(),
			type: "native"
		} as P
	}
	public toGeoJson(): turf.Feature<turf.Polygon, turf.Properties> {
		return this.to("geojson")
	}
	public toGoogleMaps(): google.maps.LatLngLiteral[] {
		return this.to("google").geometry
	}
	public toNative(): LatLng[] {
		return this.to("native").geometry
	}
	public getSquareAround(): Bounds {
		return new Bounds(turf.square(turf.bbox(this)))
	}
}
