import { Box, Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { AppButton } from 'src/components';
import { useConfigMangerActions } from './configManager.reducer';

type Props = React.PropsWithChildren<{
  submitBtn?: React.ReactNode | false;
  testId?: string;
}>;

/**
 * @param props
 * @param props.children
 * @param props.submitBtn
 * @param props.testId
 */
export default function FormWrapper({
  testId,
  children,
  submitBtn
}: Props): JSX.Element {
  const { cancel: handleCancel } = useConfigMangerActions();
  const { palette } = useTheme();

  return (
    <Box
      border={`2px ${palette.primary.light} solid`}
      borderRadius={5}
      data-cy={testId}
      id={testId}
      padding={2}
      width="100%"
    >
      <Grid container>
        <Grid item xs={12}>
          <Box marginBottom={1} width="100%">
            {children}
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <AppButton
              fullWidth
              id="cancel-btn"
              onClick={handleCancel}
              text="cancel"
            />
          </Grid>
          <Grid item xs={6}>
            {submitBtn === false
              ? null
              : submitBtn ?? <AppButton fullWidth presetKey="SUBMIT" />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
