import { RequestNames } from 'src/constants';
import { createAsyncAppThunk } from 'src/requests';
import { humanizeAzimuth } from 'src/utilities';
const { UPDATE_REEL_RUN_DIRECTION } = RequestNames;
type RunDirectionArg = {
  readonly reelRunId: number;
  readonly directionAzimuthDegrees: number;
};

export const asyncAdjustReelRunDirection = createAsyncAppThunk<RunDirectionArg>(
  UPDATE_REEL_RUN_DIRECTION,
  {
    successToast: ({ arg }) =>
      `Set direction to ${humanizeAzimuth(arg.directionAzimuthDegrees)}`
  }
);
