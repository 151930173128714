import type { ContainerProps } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { FormWrapperAvatar, GridWrapper, ShowOnlyIf } from 'src/components';
import { ACCOUNT_PATHS } from 'src/constants/Paths';
import { FarmJoinCodes, FarmUsersList, UserPermissionEditor } from 'src/farm';
import CreateFarmJoinCode from 'src/farm/CreateFarmJoinCode';
import { useIsMobile } from 'src/hooks';
import PageLayout from 'src/PageLayout';
import testIds from 'src/testIds';
import { notNullish } from 'src/types';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Toolbar,
  Typography
} from '@material-ui/core';

import { getActiveFarm, User } from '../userSession.reducer';
import AssociatedFarmsList from './AssociatedFarmsList';
import CreateFarm from './CreateFarm';
import JoinFarmWithCode from './JoinFarmWithCode';
import MetricToggleBtn from './MetricToggleBtn';
import PhoneNumbers from './PhoneNumbers';

const Ids = testIds.accountSettings;

/**
 * @param props
 * @param props.children
 * @param props.title
 * @param props.maxWidth
 */
function FormWrapper({
  children,
  maxWidth = 'xs',
  title
}: React.PropsWithChildren<{
  title: string;
  maxWidth?: ContainerProps['maxWidth'];
}>) {
  const isMobile = useIsMobile();
  return (
    <Container disableGutters={isMobile} maxWidth={maxWidth}>
      <Box mt={2}>
        <Card>
          <CardHeader avatar={<FormWrapperAvatar />} title={title} />
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </Container>
  );
}
/**
 *
 */
export default function AccountSettings(): JSX.Element {
  const { url } = useRouteMatch();
  const email = useSelector(User.getEmail);

  const activeFarm = useSelector(getActiveFarm);

  const isMetric = User.useIsMetric();

  return (
    <PageLayout id={testIds.accountSettings.root}>
      <Switch>
        <Route
          exact
          path={ACCOUNT_PATHS.joinFarm}
          render={(props) => (
            <FormWrapper title="Join Another Farm">
              <JoinFarmWithCode isFirstFarm={false} {...props} />
            </FormWrapper>
          )}
        />
        <Route
          exact
          path={ACCOUNT_PATHS.newFarm}
          render={(props) => (
            <FormWrapper title="Create Another Farm Account">
              <CreateFarm {...props} />
            </FormWrapper>
          )}
        />
        <Route
          exact
          path={ACCOUNT_PATHS.newJoinCode}
          render={(props) => (
            <FormWrapper maxWidth="lg" title="New Join Code">
              <CreateFarmJoinCode {...props} />
            </FormWrapper>
          )}
        />
        <Route
          component={UserPermissionEditor}
          exact
          path={ACCOUNT_PATHS.permissionEditor}
        />
        <Route exact path={url}>
          <Box paddingTop={2}>
            <GridWrapper
              alignItems="baseline"
              gridItemProps={{
                xs: 12,
                sm: 6
              }}
              spacing={2}
            >
              <GridWrapper
                alignItems="stretch"
                data-cy={Ids.userSettings.root}
                direction="column"
                id={Ids.userSettings.root}
                spacing={2}
              >
                <CardHeader subheader={email} title="Account Settings" />
                <PhoneNumbers />
                <Card
                  data-cy={Ids.userSettings.preferences}
                  id={Ids.userSettings.preferences}
                >
                  <CardHeader title="Measurement Preference" />
                  <CardActions>
                    <Typography variant="body2">
                      {`Measurements: ${isMetric ? `Metric` : `US`}`}
                    </Typography>
                    <MetricToggleBtn />
                  </CardActions>
                </Card>
                <AssociatedFarmsList />
              </GridWrapper>
              {notNullish(activeFarm?.name) ? (
                <GridWrapper
                  alignItems="stretch"
                  data-cy={testIds.farmManager.root}
                  direction="column"
                  id={testIds.farmManager.root}
                  spacing={2}
                >
                  <CardHeader
                    data-cy={testIds.farmManager.farmNameAndAddress}
                    id={testIds.farmManager.farmNameAndAddress}
                    subheader={activeFarm?.name}
                    title="Farm Settings"
                  />
                  <FarmJoinCodes />
                  <ShowOnlyIf requiredPermissions="canManageUserPermission">
                    <FarmUsersList />
                  </ShowOnlyIf>
                </GridWrapper>
              ) : null}
            </GridWrapper>
          </Box>
        </Route>
      </Switch>
      <Toolbar />
    </PageLayout>
  );
}
