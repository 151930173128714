import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { range } from 'lodash-es';
import React from 'react';
import { AppButton } from 'src/components';
import { RequestNames } from 'src/constants';
import type {
  BaseDeviceObject,
  PropInstallationType
} from 'src/devices/models';
import { useThunk } from 'src/hooks';
import { createAsyncAppThunk } from 'src/requests';
import { isNullish } from 'src/types';
import { useAppIsLoaded } from 'src/userSession.reducer';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

type DeviceData = Pick<
  BaseDeviceObject,
  'deviceId' | 'deviceName' | 'firmwareVersion' | 'hardwareGeneration'
> &
  PropInstallationType & {
    readonly farmId: number;
    readonly farmName: number;
  };

type Response = {
  readonly [farmId: number]: readonly DeviceData[];
};

const asyncGetAllDevicesByFarm = createAsyncAppThunk<null, Response>(
  RequestNames.GET_ALL_DEVICES_BY_FARM
);
/**
 *
 */
function Dev(): JSX.Element {
  const appIsLoaded = useAppIsLoaded();
  const { sendRequest } = useThunk(asyncGetAllDevicesByFarm);
  const [state, setState] = React.useState<{
    readonly shouldFetch: boolean;
    readonly responseData: Response | null;
  }>({
    responseData: null,
    shouldFetch: true
  });
  React.useEffect(() => {
    if (state.shouldFetch && appIsLoaded) {
      setState((prevState) => ({ ...prevState, shouldFetch: false }));
      sendRequest(null)
        .then((responseData) =>
          setState((prevState) => ({
            ...prevState,
            responseData
          }))
        )
        .catch((err) => {
          throw new Error(JSON.stringify(err));
        });
    }
  }, [appIsLoaded, sendRequest, state.shouldFetch]);

  const dataArray = state.responseData
    ? Object.entries(state.responseData).map(([farmId, devices]) => ({
        farmId,
        numDevices: devices.length
      }))
    : null;
  const maxDevices =
    dataArray?.reduce((currentMax, nextFarm) => {
      const { numDevices } = nextFarm;
      if (numDevices >= currentMax) {
        return numDevices;
      }
      return currentMax;
    }, 0) ?? 10;
  const chart =
    !isNullish(state.responseData) && !isNullish(dataArray) ? (
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        domainPadding={20}
      >
        <VictoryAxis
          dependentAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickFormat={(x: number) => `${x}`}
          tickValues={range(0, maxDevices + 4)}
        />
        <VictoryAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x: number) => `Farm ${x}`}
        />
        <VictoryBar data={dataArray} x="farmId" y="numDevices" />
      </VictoryChart>
    ) : null;
  const handleRefresh = () => {
    return setState((prevState) => ({
      ...prevState,
      responseData: null,
      shouldFetch: true
    }));
  };
  const reloadButton = (
    <AppButton
      color="secondary"
      iconKey="REFRESH"
      onClick={handleRefresh}
      text="Reload"
      variant="contained"
    />
  );
  return (
    <Container>
      <Card>
        <CardHeader
          subheader="Unique active configurations in database per farm"
          title="Farm Device Count"
        />

        <CardContent>{chart}</CardContent>
        <Divider />
        <CardActions>{reloadButton}</CardActions>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Raw Data
          </AccordionSummary>
          <AccordionDetails>
            {JSON.stringify(state.responseData, null, 4)}
          </AccordionDetails>
        </Accordion>
      </Card>
    </Container>
  );
}

export default Dev;
