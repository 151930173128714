import type { EntityState, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import asyncGetUserData from 'src/asyncGetUserData';
import type { DevicePermissionsMap } from 'src/permissions';
import type { RootState } from 'src/root.reducer';
import { isNullish } from 'src/types';
import { asyncAddDeviceToFarmAccount } from './AddDevice';

interface DeviceFarmAssociation {
  createDate: string;
  createdByUserId: string;
  deviceId: string;
  expirationDate: string;
  farmName: string;
  expiredByUserId: string;
  farmId: number;
  id: number;
  isPrimaryOwner: boolean;
  permissions: DevicePermissionsMap;
}
const getSlice = (state: RootState) => state.deviceFarmAssociations;
const adapter = createEntityAdapter<DeviceFarmAssociation>({
  selectId: (dfa) => dfa.deviceId,
  sortComparer: (a, b) =>
    new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
});
const selectors = adapter.getSelectors(getSlice);

type State = EntityState<DeviceFarmAssociation>;
const initialState: State = adapter.getInitialState({});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(asyncAddDeviceToFarmAccount.fulfilled, (state, { payload }) => {
        if (payload.status === 'VALID') {
          adapter.upsertOne(state, payload.deviceFarmAssociation);
        }
      })
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        if (payload.activeFarm?.deviceFarmAssociations) {
          adapter.upsertMany(state, [
            ...payload.activeFarm.deviceFarmAssociations
          ]);
        }
      }),
  initialState,
  name: `deviceFarmAssociations`,
  reducers: {
    clearDenied: (state) => {
      return {
        ...state,
        denyMessage: ``,
        isDenied: false
      };
    },

    denyAccess: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        denyMessage: payload,
        isDenied: true
      };
    }
  }
});

// const { actions } = slice;

const getDeviceId = (_state: RootState, deviceId: string) => deviceId;
const getIsPrimaryDeviceOwnerCached = createCachedSelector(
  getDeviceId,
  (state: RootState, deviceId: string) => selectors.selectById(state, deviceId),
  (deviceId, association) => {
    if (isNullish(association)) {
      throw new Error(`No association for device${deviceId}`);
    }
    return association.isPrimaryOwner;
  }
)(getDeviceId);

const deviceFarmAssociations: Reducer<typeof initialState> = slice.reducer;
export default deviceFarmAssociations;

export type { DeviceFarmAssociation };
export const DeviceFarmAssociations = {
  adapter,
  getIsPrimaryDeviceOwnerCached,
  ...selectors
} as const;
