import type { ListSubheaderProps } from '@material-ui/core';
import { createStyles, ListSubheader, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.paper
    }
  });
});

const ListHeader = React.forwardRef<any, ListSubheaderProps>(
  ({ className, ...rest }, ref) => {
    const { root } = useStyles();
    return (
      <ListSubheader
        ref={ref}
        {...rest}
        className={classNames(root, className)}
      />
    );
  }
);
ListHeader.displayName = 'ListHeader';

export default ListHeader;
