import type { MenuProps, Theme } from '@material-ui/core';
import {
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Switch as ToggleSwitch,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import ShowOnlyIf from 'src/components/util/ShowOnlyIf';
import { useAppDispatch } from 'src/hooks';
import useAppSelector from 'src/hooks/useAppSelector';
import store from 'storejs';
import Actions from './actions';
import { getThemePreference } from './appSelectors';
import { AppMenuItem } from './components/menu';
import PATHS from './constants/Paths';

import testIds from './testIds';
import type { IconKey } from './theme';
import { AppIcon } from './theme';
import { setThemePreference, User } from './userSession.reducer';
import { humanize } from './utilities';
/**
 *
 */
export function useLinksAreInMenu(): boolean {
  return useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
}
const Ids = testIds.appBar.menu;

const INPUT_PROPS = {
  id: Ids.adminViewToggleSwitch
} as const;
/**
 *
 */
export function AdminViewToggle(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const isAdmin = useSelector((state) => state.userSession.isAdmin);
  const initialValue = store.get('adminMode') as 'true' | undefined;
  const adminMode = useAppSelector(
    (state) =>
      state.userSession.isAdmin &&
      state.userSession.preferences.adminMode === true
  );
  React.useEffect(() => {
    if (adminMode) {
      store.set('adminMode', adminMode ? 'true' : '');
    }
  }, [adminMode]);
  React.useEffect(() => {
    dispatch(Actions.setAdminMode(initialValue === 'true'));
  }, [dispatch, initialValue]);

  const handleChange = () => dispatch(Actions.setAdminMode(!adminMode));

  return isAdmin ? (
    <MenuItem
      button={false}
      data-cy={Ids.adminViewToggle}
      dense
      id={Ids.adminViewToggle}
    >
      <ListItemText primary="Admin Mode" />
      <ListItemSecondaryAction>
        <ToggleSwitch
          checked={adminMode}
          data-cy={Ids.adminViewToggleSwitch}
          inputProps={INPUT_PROPS}
          onChange={handleChange}
          value={adminMode ? `on` : `off`}
        />
      </ListItemSecondaryAction>
    </MenuItem>
  ) : null;
}

type Props = MenuProps & {
  links: ReadonlyArray<{ path: string; name: IconKey }>;
};

const UserAccountMenu = React.forwardRef<any, Props>(
  ({ anchorEl, links, onClose, ...rest }, ref) => {
    const dispatch = useAppDispatch();
    const themePreference = useSelector(getThemePreference);
    const isDarkMode = themePreference === 'dark';
    const onThemeChange = () => {
      dispatch(setThemePreference(isDarkMode ? 'light' : 'dark'));
    };

    const userHasFarms = !useAppSelector(User.getHasNoFarms);
    const handleClose = () => onClose && onClose({}, 'backdropClick');
    const email = useSelector(User.getEmail);

    const handleClickChangeFarms = () =>
      dispatch(Actions.setActiveFeature({ name: 'CHANGE_FARMS' }));

    const handleClickMapType = () => {
      handleClose();
      return dispatch(
        Actions.setActiveFeature({
          name: 'SET_MAP_TYPE_ID'
        })
      );
    };

    return (
      <Menu
        {...rest}
        MenuListProps={{
          id: Ids.root
        }}
        anchorEl={anchorEl}
        data-cy={Ids.root}
        onClose={onClose}
        ref={ref}
      >
        <ListSubheader
          component={ListItemText}
          primary={`${email ?? 'Farm HQ'}`}
          secondary={<ShowOnlyIf activeUserIsAdmin>Admin User</ShowOnlyIf>}
        />
        <Divider />
        <AdminViewToggle />
        <ShowOnlyIf userHasAFarm>
          <ListSubheader>Pages</ListSubheader>
          {links.map((page) => (
            <AppMenuItem
              dense
              divider
              iconKey={page.name}
              id={`${page.name}-link`}
              key={page.path}
              linkTo={page.path}
              onClick={handleClose}
              textPrimary={humanize(page.name)}
            />
          ))}
        </ShowOnlyIf>
        <ListSubheader>Style</ListSubheader>
        <AppMenuItem
          button
          dense
          divider
          icon={
            <ListItemIcon>
              <AppIcon iconKey={isDarkMode ? 'DARK_THEME' : 'LIGHT_THEME'} />
            </ListItemIcon>
          }
          id={Ids.themeToggle(themePreference)}
          onClick={onThemeChange}
          textPrimary="Theme"
          value={themePreference ?? undefined}
        />
        <Route exact path="/">
          <AppMenuItem
            button
            dense
            iconKey="map"
            id={Ids.changeMapType}
            isHidden={!userHasFarms}
            onClick={handleClickMapType}
            textPrimary="Map Style"
          />
        </Route>
        <ShowOnlyIf activeUserIsAdmin>
          <ListSubheader>Admin Actions</ListSubheader>
          <AppMenuItem
            button
            dense
            divider
            id={Ids.adminSwitchFarms}
            onClick={handleClickChangeFarms}
            textPrimary="Switch Farms"
          />
        </ShowOnlyIf>
        <ShowOnlyIf isDev>
          <AppMenuItem
            button
            dense
            divider
            id={testIds.development.linkTo}
            isAdminOnly
            linkTo={PATHS.development.root}
            onClick={handleClose}
            textPrimary="Development"
          />
        </ShowOnlyIf>
        <ShowOnlyIf userIsAuthenticated>
          <AppMenuItem
            button
            dense
            iconKey="EXIT"
            id={testIds.auth.signOut.linkTo}
            linkTo={PATHS.auth.signOut}
            textPrimary="Sign Out"
          />
        </ShowOnlyIf>
      </Menu>
    );
  }
);
UserAccountMenu.displayName = 'UserAccountMenu';
export default UserAccountMenu;
