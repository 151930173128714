import type { GridProps } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import { isNullish } from 'src/types';

/**
 * @param props
 * @param props.children
 * @param props.xs
 * @param props.spacing
 * @param props.rest
 * @param props.alignChildren
 * @param props.justifyChildren
 * @param props.childSizes
 * @param props.justifyContent
 * @param props.alignItems
 */

export const FlexCol = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  GridProps & {
    readonly alignChildren?: GridProps['alignItems'];
    readonly justifyChildren?: GridProps['justifyContent'];
    readonly childSizes?: GridProps['xs'];
  }
>(
  (
    {
      children,
      spacing = 1,
      alignChildren,
      justifyChildren,
      childSizes = 12,
      justify = `space-between`,
      alignItems = `stretch`,
      ...rest
    },
    ref
  ) => {
    return (
      <Grid
        alignItems={alignItems}
        container
        direction="column"
        justifyContent={justify}
        ref={ref}
        spacing={spacing}
        {...rest}
      >
        {React.Children.map(children, (child) => {
          return isNullish(child) ? null : (
            <Grid item xs={childSizes}>
              <Grid
                alignItems={alignChildren ?? alignItems}
                container
                justifyContent={justifyChildren ?? justify}
              >
                {child}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
);
FlexCol.displayName = 'FlexCol';
