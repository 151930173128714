import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton } from 'src/components';
import { useResponsiveText } from 'src/hooks';
import testIds from 'src/testIds';
import { notNullish } from 'src/types';
import { CreateField } from './createField.reducer';

type Props = React.PropsWithChildren<{
  cancelText?: string;
  testId: string;
  submitBtn?: React.ReactNode;
  instructions?: string;
}>;

const WRAPPER_CARD_STYLE: React.CSSProperties = {
  borderRadius: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
};
/**
 * @param props
 * @param props.submitBtn
 * @param props.testId
 * @param props.children
 * @param props.stageName
 * @param props.cancelText
 * @param props.instructions
 */
export function StageWrapper({
  submitBtn,
  testId,
  children,
  cancelText,
  instructions
}: Props): JSX.Element {
  const text = useResponsiveText(instructions);
  const fieldName = useSelector((state) => state.createField.fieldName);
  const bearingText = useSelector(CreateField.getBearingText);
  return (
    <div data-cy={testIds.createField.root}>
      <Card data-cy={testId} id={testId} style={WRAPPER_CARD_STYLE}>
        <CardHeader
          subheader={
            <React.Fragment>
              <Typography component="span">
                {notNullish(fieldName) ? (
                  <Typography>Name: {fieldName ?? ''}</Typography>
                ) : null}
                {notNullish(bearingText) ? (
                  <Typography
                    component="span"
                    data-cy={testIds.createField.setRowDirection.bearingTextRow}
                    id={testIds.createField.setRowDirection.bearingTextRow}
                  >
                    Row Direction: {bearingText}
                  </Typography>
                ) : null}
              </Typography>
              <Divider />
              <Typography component="span">{text}</Typography>
            </React.Fragment>
          }
          title="Create Field"
        />
        {notNullish(children) ? <CardContent>{children}</CardContent> : null}
        <CardActions>
          <AppButton id={testIds.createField.cancelBtn} presetKey="CANCEL">
            {cancelText ?? 'Cancel'}
          </AppButton>
          {submitBtn}
        </CardActions>
      </Card>
    </div>
  );
}
