import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import type { ConfigSensorName } from 'src/devices/sensors';
import { SensorNames } from 'src/devices/sensors';
import { useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import type { DeviceIdProp } from './models';
import { getConfiguredSensorsByDeviceId } from './selectors';
const ids = testIds.triggerSelect('sourceSensor');

/**
 * @param props
 * @param props.deviceId
 * @param props.isGlobal
 * @param props.value
 * @param props.onChange
 */
export default function SensorSelect({
  deviceId,
  isGlobal,
  value,
  onChange
}: DeviceIdProp & {
  isGlobal: boolean;
  value: ConfigSensorName | undefined;
  onChange: (value: ConfigSensorName) => void;
}): JSX.Element {
  const configuredSensors = useAppSelector((state) =>
    isGlobal
      ? SensorNames.getList()
      : getConfiguredSensorsByDeviceId(state, deviceId)
  );

  const handleChange = ({
    target
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>): void => {
    if (!SensorNames.is.valid(target.value)) {
      throw new Error(`Not a sensor name: ${JSON.stringify(value)}`);
    }
    if (target.value === 'device') {
      throw new Error('Got device sensor');
    }
    onChange(target.value);
  };
  return (
    <Select
      MenuProps={{ id: ids.menu }}
      data-cy={ids.root}
      id={ids.root}
      inputProps={{
        id: ids.input
      }}
      name="sourceSensor"
      onChange={handleChange}
      value={value ?? ''}
      variant="standard"
    >
      {configuredSensors?.map((sn) => (
        <MenuItem data-cy={sn} id={sn} key={sn} value={sn}>
          {SensorNames.humanize(sn)} Sensor
        </MenuItem>
      ))}
    </Select>
  );
}
