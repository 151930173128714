import type { GridProps } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import type { EnhancedButtonProps } from 'src/components';
import { IconBtn } from 'src/components';

/**
 * @param props
 * @param props.onClick
 * @param props.rest
 */
export function ReloadButton({
  onClick,
  ...rest
}: Pick<EnhancedButtonProps, 'id' | 'onClick'> &
  Pick<GridProps, 'xs'>): JSX.Element {
  return (
    <Grid
      {...rest}
      component={IconBtn}
      iconKey="REFRESH"
      item
      onClick={onClick}
      size="small"
    />
  );
}
