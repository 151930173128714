import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import React from 'react';
import { generatePath, useHistory } from 'react-router';
import { IconBtn } from 'src/components';
import { NoListItems } from 'src/components/lists';
import PATHS from 'src/constants/Paths';
import { useAppSelector } from 'src/hooks';
import testIds from 'src/testIds';
import { FarmUsers } from './farmUsers.reducer';

/**
 * @param email.email
 * @param email
 */
function makeDataCy({ email }: { readonly email: string }) {
  return `edit-permissions-${email.replace(`@`, ``).replace(`.`, ``)}`;
}
/**
 * @param email
 */
function makeLinkPath(email: string): string {
  return generatePath(PATHS.account.permissionEditor, {
    email
  });
}

const Ids = testIds.farmManager;
/**
 * Shows list of the active farms users and allows active user to modify their permissions
 */
function FarmUsersList(): JSX.Element {
  /**
   * Create a test id from the user email
   */
  const usersFiltered = useAppSelector(FarmUsers.getFarmUsersFiltered);
  const history = useHistory();
  const rootId = Ids.usersListRoot;
  const isOnlyUser = usersFiltered.length === 0;
  return (
    <Card data-cy={rootId} id={rootId}>
      <CardHeader
        subheader={isOnlyUser ? `` : `Click to manage user permissions`}
        title="Farm Users"
      />
      <CardContent>
        {isOnlyUser ? (
          <NoListItems
            id={Ids.onlyUserView}
            title="You are the only user of this farm account"
          />
        ) : (
          <List data-cy={Ids.usersList} dense disablePadding id={Ids.usersList}>
            {usersFiltered.map(({ userId, email, isAdmin }, index) => {
              const handleOpenPermissions = () =>
                history.push(makeLinkPath(email));
              return (
                <ListItem
                  data-cy={Ids.usersListItem(index)}
                  divider={index !== usersFiltered.length - 1}
                  id={Ids.usersListItem(index)}
                  key={userId}
                  onClick={handleOpenPermissions}
                >
                  <ListItemText
                    primary={email}
                    secondary={isAdmin ? 'Admin User' : 'Not Admin'}
                  />
                  <ListItemIcon>
                    <IconBtn iconKey="EDIT_ITEM" id={makeDataCy({ email })} />
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
}

export default FarmUsersList;
