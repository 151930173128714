import type { EntityState, Reducer } from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import Actions from 'src/actions';
import asyncGetUserData from 'src/asyncGetUserData';
import { RequestNames } from 'src/constants';
import { createAsyncAppThunk } from 'src/requests';
import createEntitySelectors from 'src/utilities/createEntitySelectors';

import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import type { RootState } from 'src/root.reducer';
interface DeviceUserAssociation {
  deviceId: string;
  notificationsSuppressed: boolean;
}

/**
 * @param state
 */
const getSlice = (state: RootState) => state.deviceUserAssociations;

const adapter = createEntityAdapter<DeviceUserAssociation>({
  selectId: (model) => model.deviceId
});
const selectors = adapter.getSelectors(
  (state: RootState) => state.deviceUserAssociations
);
const getPhoneNumbers = createEntitySelectors(
  (state) => state.userPhoneNumbers
).all;

const getNotificationStatusForDevice = createCachedSelector(
  (state: RootState, deviceId: string) => selectors.selectById(state, deviceId),
  getPhoneNumbers,
  (association, phoneNumbers) => {
    const verified = phoneNumbers.filter((pn) => pn.isVerified);
    if (verified.length === 0) {
      return 'no phone numbers';
    }
    if (association?.notificationsSuppressed === true) {
      return 'suppressed';
    }
    return 'enabled';
  }
)((_, deviceId) => deviceId);

const { TOGGLE_NOTIFICATIONS_FROM_DEVICES } = RequestNames;

const asyncToggleNotificationsFromDevices = createAsyncAppThunk<{
  ids: string[];
  action: 'ENABLE' | 'SUPPRESS';
}>(TOGGLE_NOTIFICATIONS_FROM_DEVICES);

type State = EntityState<DeviceUserAssociation> & {
  readonly requestStatus: 'denied' | 'pending' | null;
};
const initialState: State = adapter.getInitialState({
  requestStatus: null
});

const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(Actions.closeDialog, (state) => {
        state.requestStatus = null;
      })
      .addCase(asyncGetUserData.pending, (state) => {
        adapter.removeAll(state);
      })
      .addCase(asyncGetUserData.fulfilled, (state, { payload }) => {
        adapter.upsertMany(state, [...payload.deviceUserAssociations]);
      })
      .addCase(
        asyncToggleNotificationsFromDevices.fulfilled,
        (state, { meta: { arg } }) => {
          if (arg.action === 'ENABLE') {
            adapter.removeMany(state, arg.ids);
          } else {
            adapter.upsertMany(
              state,
              arg.ids.map((id) => ({
                deviceId: id,
                notificationsSuppressed: true
              }))
            );
          }
        }
      ),

  initialState,
  name: `deviceUserAssociations`,
  reducers: {
    showAlertsToggleDenied: (state) => {
      state.requestStatus = 'denied';
    }
  }
});

const isAlertsTogglePermissionDenied = createSelector(
  getSlice,
  (state) => state.requestStatus === 'denied'
);

const { showAlertsToggleDenied } = slice.actions;
const DeviceUserAssociations = {
  adapter,
  ...selectors,
  getNotificationStatusForDevice,

  isAlertsTogglePermissionDenied
} as const;
const deviceUserAssociations: Reducer<State> = slice.reducer;

export {
  DeviceUserAssociations,
  showAlertsToggleDenied,
  asyncToggleNotificationsFromDevices
};
export type { DeviceUserAssociation };
export default deviceUserAssociations;
