import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import React from 'react';
import { AppButton } from 'src/components';
import { AppIcon } from 'src/theme';
import { isNullish } from 'src/types';

interface FallbackProps {
  readonly error: Error;
  readonly componentStack: string | null;
  readonly eventId: string | null;
  resetError(): void;
}

/**
 * @param props
 * @param props.error
 * @param props.resetError
 * @param props.componentStack
 */
export default function Page500({
  error,
  resetError,
  componentStack
}: FallbackProps): JSX.Element {
  React.useEffect(() => {
    return function cleanup() {
      if (!isNullish(error)) {
        resetError();
      }
    };
  }, [componentStack, error, resetError]);
  return (
    <Container maxWidth="xs">
      <Box
        alignItems="center"
        data-cy="page-500"
        display="flex"
        height="70vh"
        id="page-500"
        justifyContent="center"
      >
        <Card>
          <CardContent>
            <Grid
              alignItems="flex-start"
              container
              justifyContent="space-around"
            >
              <Grid item>
                <AppIcon iconKey="Leaf" />
              </Grid>
              <Grid item>
                <Typography component="h1" paragraph variant="h4">
                  Something went wrong.
                </Typography>
                <Divider />
              </Grid>
            </Grid>
            <Typography align="center" paragraph>
              Our team has been notified and we are working on the issue. Thank
              you for your patience.
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              height={100}
              justifyContent="space-evenly"
            >
              <AppButton
                linkTo="/"
                onClick={resetError}
                size="large"
                text="home"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
