import { Box, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AppButton } from 'src/components';
import { useThunk } from 'src/hooks';
import testIds from 'src/testIds';
import { isNullish } from 'src/types';
import { showErrorToast, showSuccessToast } from 'src/theme/toasts';
import { asyncDeleteTriggers, asyncRestoreConfigDefaults } from '../actions';

import {
  useConfigManagerState,
  useConfigMangerActions
} from './configManager.reducer';
import { useSelector } from 'react-redux';
import { getSelectedDeviceMetadata } from '../selectors';
import { useSelectedDeviceTriggers } from '../triggers.reducer';

const Ids = testIds.manageConfiguration.resetConfig;
const TEST_ID = Ids.root;
/**
 *
 */
export default function ResetConfigToDefaults(): JSX.Element | null {
  const state = useConfigManagerState();
  const { cancel: handleCancel } = useConfigMangerActions();
  const md = useSelector(getSelectedDeviceMetadata);
  const deleteTriggers = useThunk(asyncDeleteTriggers);
  const restoreDefaults = useThunk(asyncRestoreConfigDefaults);
  const triggers = useSelectedDeviceTriggers();
  const hasTriggers = triggers.length > 0;
  if (isNullish(md)) {
    return null;
  }
  const { deviceId, deviceName } = md;
  const triggerWarning = hasTriggers
    ? `All and device pairs and custom notifications 
    for the device will also be deleted.`
    : ``;
  const warning = `
  You will lose any sensor configurations and any other customizations. 
  ${triggerWarning} This cannot be undone.
  `;
  const handleSubmit = async () => {
    try {
      if (hasTriggers) {
        await deleteTriggers.sendRequest({
          triggerIds: triggers.map((t) => t.id)
        });
      }
      await restoreDefaults.sendRequest({ deviceId });
      showSuccessToast();
      handleCancel();
    } catch (err) {
      showErrorToast();
      throw new Error(err);
    }
  };
  return (
    <Dialog
      aria-labelledby="reset defaults"
      data-cy={TEST_ID}
      id={TEST_ID}
      onClose={handleCancel}
      open={state.action === 'RESET'}
    >
      <DialogTitle id="rename-device-title">Confirm Changes</DialogTitle>
      <DialogContent>
        <Box my={1}>
          <Alert severity="info">
            {`Are you sure you want to restore ${deviceName} to factory defaults?`}
          </Alert>
        </Box>
        <Box my={1}>
          <Alert severity="warning">{warning}</Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <AppButton
          color="default"
          id={testIds.cancelBtn}
          onClick={handleCancel}
          text="cancel"
        />
        <AppButton
          id={testIds.submitBtn}
          onClick={handleSubmit}
          presetKey="SUBMIT"
          text="confirm"
        />
      </DialogActions>
    </Dialog>
  );
}
