// import type { InfoWindowProps } from '@react-google-maps/api';
// import { InfoWindow } from '@react-google-maps/api';
// import React from 'react';
// import Actions from 'src/actions';
// import { getSelectedDeviceId } from 'src/appSelector';
// import type { GmapsLatLngLiteral } from 'src/geo';
// import { useAppDispatch, useAppSelector } from 'src/hooks';
// import { isNullish } from 'src/types';
// import type { Overwrite } from 'utility-types';

// type Props = React.PropsWithChildren<
//   Overwrite<
//     InfoWindowProps,
//     {
//       position: GmapsLatLngLiteral;
//     }
//   >
// >;
// const DEFAULT_OPTIONS: google.maps.InfoWindowOptions = {
//   maxWidth: 200,
// };

// /**
//  * @param props
//  * @param props.children
//  * @param props.options
//  * @param props.rest
//  * @param props.onCloseClick
//  * @param props.position
//  */
// export default function MapCallout({
//   children,
//   options,
//   onCloseClick,
//   position,
//   ...rest
// }: Props): JSX.Element | null {
//   const dispatch = useAppDispatch();
//   const isOpen = useAppSelector(getSelectedDeviceId);
//   const optionsMemo = React.useMemo(
//     () => ({ ...DEFAULT_OPTIONS, ...options }),
//     [options]
//   );

//   const handleClose = () => {
//     if (onCloseClick) {
//       onCloseClick();
//     }
//     return dispatch(Actions.onMapCalloutClosed());
//   };
//   return !isNullish(position) && isOpen ? (
//     <InfoWindow
//       {...rest}
//       onCloseClick={handleClose}
//       options={optionsMemo}
//       position={position}
//     >
//       <div data-cy="map-callout" id="map-callout">
//         {children}
//       </div>
//     </InfoWindow>
//   ) : null;
// }
import { createStyles, makeStyles } from '@material-ui/core';
import type { InfoWindowProps } from '@react-google-maps/api';
import { InfoWindow } from '@react-google-maps/api';
import classNames from 'classnames';
import React from 'react';
import Actions from 'src/actions';
import { useAppDispatch } from 'src/hooks';

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return createStyles({
    root: {
      color: palette.common.black
    }
  });
});

type Props = React.PropsWithChildren<
  InfoWindowProps & {
    className?: string;
  }
>;

/**
 * @param props
 * @param props.options
 * @param props.rest
 * @param props.onCloseClick
 * @param props.className
 * @param props.children
 */
function MapCallout({
  options,
  onCloseClick,
  className,
  children,
  ...rest
}: Props): JSX.Element {
  const dispatch = useAppDispatch();

  let handleClose = onCloseClick;
  if (typeof handleClose === 'undefined') {
    handleClose = () => dispatch(Actions.onMapCalloutClosed());
  }

  const classes = useStyles();
  const optionsMemo = React.useMemo((): google.maps.InfoWindowOptions => {
    return {
      ...options,
      disableAutoPan: true
    };
  }, [options]);
  return (
    <InfoWindow onCloseClick={handleClose} {...rest} options={optionsMemo}>
      <div className={classNames(classes.root, className)}>{children}</div>
    </InfoWindow>
  );
}

MapCallout.displayName = 'MapCallout';
export default MapCallout;
