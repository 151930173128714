import { kebabCase } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Actions from 'src/actions';
import { AppButton, GridWrapper, Success } from 'src/components';
import PATHS from 'src/constants/Paths';
import { useAppDispatch } from 'src/hooks';
import testIds from 'src/testIds';
import { notNullish } from 'src/types';
import { User } from 'src/userSession.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ActiveFeaturePayload } from 'src/actions';
import type { BasePagePath } from 'src/constants/Paths';
const SUCCESS_LINKS: Array<{
  btnText: string;
  text: string;
  id: string;
  to?: BasePagePath;
  action?: PayloadAction<ActiveFeaturePayload | null>;
}> = [
  {
    btnText: 'open map',
    text: `Explore the Device Status Map, where you can monitor and interact with your equipment and fields.`,
    to: '/',
    id: testIds.newFarmSuccess.openMapBtn
  },
  {
    btnText: 'add devices',
    text: 'Register your Farm HQ devices',
    id: testIds.newFarmSuccess.addDevicesBtn,
    action: Actions.setActiveFeature({ name: 'ADD_DEVICE' })
  },
  {
    btnText: 'get support',
    text: `Need more info about your devices, including installation steps or
  usage instructions? Visit our support documentation section.`,
    to: PATHS.support.base,
    id: testIds.newFarmSuccess.getSupportBtn
  }
];

const Ids = testIds.newFarmSuccess;
/**
 *
 */
function CreateFarmSuccess(): JSX.Element {
  const farmStatus = useSelector((state) => state.userSession.farmStatus);
  const dispatch = useAppDispatch();
  const handleClear = () => dispatch(Actions.clearNewFarm());
  const name = User.useActiveFarm()?.name ?? 'the farm';
  const subheader = "What's next?";
  const history = useHistory();

  const createdFarm = farmStatus === 'USER_CREATED_FARM';
  const isOpen = notNullish(farmStatus);
  React.useEffect(() => {
    if (isOpen) {
      history.push('/');
    }
  }, [history, isOpen]);

  return (
    <Dialog data-cy={Ids.root} id={Ids.root} open={isOpen}>
      <DialogTitle disableTypography>
        <Typography variant="h5">Success</Typography>
        <Success>
          {createdFarm
            ? `Your farm account has been created`
            : `Your account is now linked to ${name}`}
        </Success>
      </DialogTitle>
      <DialogContent>
        <div data-cy={Ids.root} id={Ids.root}>
          <Typography variant="h6">{subheader}</Typography>
          <Divider />
          <Alert data-cy={Ids.links} icon={false} severity="info">
            <GridWrapper
              direction="column"
              gridItemProps={{ xs: 12 }}
              justifyContent="space-between"
              spacing={2}
              xs={12}
            >
              {SUCCESS_LINKS.map((link) => (
                <React.Fragment key={link.id}>
                  <DialogContentText>{link.text}</DialogContentText>
                  <AppButton
                    color="secondary"
                    fullWidth
                    id={`${kebabCase(link.btnText)}-btn`}
                    linkTo={link.to}
                    onClick={() => {
                      if (link.action) {
                        dispatch(link.action);
                      }
                      handleClear();
                    }}
                    variant="contained"
                  >
                    {link.btnText}
                  </AppButton>
                </React.Fragment>
              ))}
            </GridWrapper>
          </Alert>
        </div>
      </DialogContent>
      <DialogActions>
        <AppButton id={testIds.dialog.closeBtn} onClick={handleClear}>
          Close
        </AppButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateFarmSuccess;
