import { isNumber } from 'lodash-es';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getActiveFeature, getSelectedReelRunId } from 'src/appSelectors';
import { getRunEtaByDeviceId } from 'src/devices/calculateReelEta';
import useFormatSensorKey from 'src/devices/sensors/useSensorKeyFormatter';
import { handleApiRequestError } from 'src/requests';

import {
    CardActionArea, CardContent, CardHeader, Divider, Grid, Table, TableBody, TableCell, TableHead,
    TableRow, Typography
} from '@material-ui/core';

import Actions, { useClearActiveFeature } from '../actions';
import { AppButton, GridWrapper } from '../components';
import { MapCallout, RowDirection, useSetDirection } from '../components/map';
import MapCard from '../components/MapCard';
import { getConfigById } from '../devices/selectors';
import { useAppDispatch, useThunk } from '../hooks';
import { getSelectedReelRun, ReelRunsActive } from '../reel-runs';
import { asyncAdjustReelRunDirection } from '../reel-runs/actions';
import { getFieldForReelRun, getSwathOutlineForRun } from '../reel-runs/getReelRunGeometry';
import RunObservationReports from '../reel-runs/RunObservationReports';
import { showSuccessToast } from '../theme';
import { isNullish, isTruthyString, notNullish } from '../types';
import { RunPolygon } from './RunGeometry';

import type { ConnectedProps } from 'react-redux';
/**
 * @param props
 * @param props.reelRunId
 */
function RunCallout({ reelRunId }: { reelRunId: number }): JSX.Element | null {
  const geoms = useSelector((state) => getSwathOutlineForRun(state, reelRunId));
  const centerGmaps = geoms?.centerGmaps;

  return centerGmaps ? (
    <MapCallout position={centerGmaps}>ReelRun {reelRunId}</MapCallout>
  ) : null;
}

const KEYS = [
  RunObservationReports.flowRateEstimatedLpm,
  RunObservationReports.applicationRateEstimatedMm
];

const TABLE_CONTAINER_STYLE: React.CSSProperties = {
  maxHeight: 300,
  overflowY: 'scroll'
};
/**
 * @param props
 * @param props.selectedId
 * @param props.direction
 */
function SetDirection({
  selectedId,
  ...direction
}: ReturnType<typeof useSetDirection> & { selectedId: number }) {
  const { sendRequest: submitRunDirection } = useThunk(
    asyncAdjustReelRunDirection
  );
  const handleCancel = useClearActiveFeature();

  const handleCommitDirection = async (value: number): Promise<void> => {
    if (isNullish(selectedId)) {
      throw new Error('No selected run');
    }
    await submitRunDirection({
      directionAzimuthDegrees: value,
      reelRunId: selectedId
    })
      .then(() => showSuccessToast())
      .catch(handleApiRequestError);
  };
  const handleFlipSwath = async (): Promise<void> => {
    await handleCommitDirection(direction.flipValue());
  };

  return (
    <MapCard>
      <CardHeader title="Set Run Direction" />
      <CardContent>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={8}>
            <RowDirection.Slider
              onChange={direction.handleDragSlider}
              onChangeCommitted={handleCommitDirection}
              value={direction.azimuth}
            />
          </Grid>
          <Grid component={Typography} item xs={4}>
            {direction.bearingText}
          </Grid>
          <GridWrapper gridItemProps={{ xs: 6 }} item xs={12}>
            <AppButton
              fullWidth
              iconKey="SWITCH_VERTICAL"
              onClick={handleFlipSwath}
              text="flip"
            />
            <AppButton
              fullWidth
              iconKey="CHECK_MARK"
              onClick={handleCancel}
              text="done"
            />
          </GridWrapper>
        </Grid>
      </CardContent>
    </MapCard>
  );
}
/**
 * @param props
 * @param props.runId
 */
function ObservationsTable({ runId }: { runId: number }) {
  const observations = useSelector(
    (state) => ReelRunsActive.selectById(state, runId)?.observations
  );
  const formatKey = useFormatSensorKey();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>N</TableCell>
          {KEYS.map((key) => (
            <TableCell key={key}>{formatKey(key)} </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {observations?.map((obs, index) => (
          <TableRow key={`${obs.distanceObservedMm}/${index}`}>
            <TableCell padding="checkbox">{index + 1}</TableCell>
            {KEYS.map((k) => (
              <TableCell align="center" key={k}>
                {obs[k] ?? 'N/A'}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const connector = connect((state) => {
  const runIds = ReelRunsActive.selectIds(state) as number[];
  const selectedRun = getSelectedReelRun(state);
  const activeFeature = getActiveFeature(state);
  const selectedRunDeviceId = selectedRun?.deviceId;
  const selectedRunConfig = getConfigById(state, selectedRunDeviceId ?? '');
  const selectedId = selectedRun?.reelRunId;
  return {
    observations: selectedRun?.observations,
    runIds,
    selectedId,
    selectedRunSprinklerType: selectedRunConfig?.reel?.sprinklerType,
    selectedRunDeviceId,
    selectedRunInstallationType: selectedRunConfig?.deviceInstallationType,
    isSettingDirection: activeFeature === 'SET_RUN_DIRECTION',
    selectedRunDeviceName: selectedRunConfig?.deviceName,
    field: notNullish(selectedId)
      ? getFieldForReelRun(state, selectedId)
      : null,
    selectedAzimuth:
      selectedRun?.directionOverrideAzimuthDegrees ??
      selectedRun?.fieldRowDirectionAzimuthDegrees
  };
});

type Props = ConnectedProps<typeof connector>;
type RunActionsProps = React.PropsWithChildren<
  Pick<
    Props,
    | 'selectedAzimuth'
    | 'selectedId'
    | 'selectedRunDeviceId'
    | 'selectedRunDeviceName'
  >
>;

/**
 * @param props
 * @param props.selectedRunDeviceId
 * @param props.selectedRunDeviceName
 * @param props.children
 * @param props.selectedAzimuth
 * @param props.selectedId
 */
function RunActionCard({
  selectedRunDeviceId,
  selectedRunDeviceName,
  children
}: RunActionsProps) {
  const selectedId = useSelector(getSelectedReelRunId);
  const dispatch = useAppDispatch();
  const [showObservations, setShowObservations] = React.useState(false);
  const runEta = useSelector((state) =>
    isNumber(selectedId) ? getRunEtaByDeviceId(state, selectedId) : undefined
  );

  
  const handleClickDevice = () => {
    if (isTruthyString(selectedRunDeviceId)) {
      dispatch(Actions.selectItem({ id: selectedRunDeviceId, kind: 'device' }));
    }
  };
  const handleClickSetDirection = () => {
    if (notNullish(selectedId)) {
      dispatch(
        Actions.setActiveFeature({
          id: selectedId,
          kind: 'reelRun',
          name: 'SET_RUN_DIRECTION'
        })
      );
    }
  };
  const handleToggleObservations = () => setShowObservations(!showObservations);
  return (
    <MapCard>
      <CardContent>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={2}
        >
          {notNullish(selectedRunDeviceName) ? (
            <Grid
              component={CardActionArea}
              item
              onClick={handleClickDevice}
              xs={7}
            >
              <Typography variant="subtitle1">
                {selectedRunDeviceName}
                <Divider />
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="subtitle2"
                >
                  Reel
                </Typography>
              </Typography>
              <Typography component="span" variant="subtitle2">
                ETA:
                <Typography>{runEta}</Typography>
              </Typography>
            </Grid>
          ) : null}
          {/* <Grid
            component={AppButton}
            item
            onClick={handleClickSetDirection}
            size="small"
            variant="outlined"
            xs={5}
          >
            Adjust Direction
          </Grid> */}
        </Grid>
        {/* <AppButton iconKey="EXPAND_MORE" onClick={handleToggleObservations}>
          Observation Data
        </AppButton>
        <Collapse in={showObservations}>
          <TableContainer style={TABLE_CONTAINER_STYLE}>
            {children}
          </TableContainer>
          <Toolbar />
        </Collapse> */}
      </CardContent>
    </MapCard>
  );
}
/**
 * @param props
 * @param props.selectedId
 * @param props.selectedAzimuth
 * @param props.selectedRunDeviceName
 * @param props.selectedRunDeviceId
 * @param props.runIds
 * @param props.observations
 * @param props.isSettingDirection
 * @param props.field
 */
function StatusMapReelRuns({
  selectedId,
  selectedAzimuth,
  selectedRunDeviceName,
  selectedRunDeviceId,
  runIds,
  isSettingDirection
}: Props): JSX.Element | null {
  const direction = useSetDirection({ initialValue: selectedAzimuth });
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {runIds.map((id) => {
        return <RunPolygon id={id} key={id} variant="ACTIVE" />;
      })}
      {notNullish(selectedId) ? (
        <div>
          {isSettingDirection ? (
            <SetDirection {...direction} selectedId={selectedId} />
          ) : (
            <RunActionCard
              selectedAzimuth={selectedAzimuth}
              selectedId={selectedId}
              selectedRunDeviceId={selectedRunDeviceId}
              selectedRunDeviceName={selectedRunDeviceName}
            >
              <ObservationsTable runId={selectedId} />
            </RunActionCard>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default connector(StatusMapReelRuns);
