import type { BackdropProps, Theme } from '@material-ui/core';
import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { APP_STYLE } from 'src/AppThemeProvider';

import {
  AppBar,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import MuiAppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';

import { IconBtn, ShowOnlyIf, StyledLink } from './components';
import { useMenuState } from './components/menu';
import { appConfig } from './configure-app';
import useLogo from './hooks/useLogo';
import testIds from './testIds';
import AppIcon from './theme/AppIcon';
import UserAccountMenu from './UserAccountMenu';

import type { IconKey } from './theme/AppIcon';
const STYLES: {
  [key in 'logo' | 'logoContainer' | 'toolbar']: React.CSSProperties;
} = {
  logo: {
    height: APP_STYLE.toolbarHeightPx,
    padding: 10
  },
  logoContainer: {
    flex: 1,
    maxHeight: APP_STYLE.toolbarHeightPx
  },

  toolbar: {
    alignItems: 'center',
    maxHeight: APP_STYLE.toolbarHeightPx,
    display: 'flex',
    justifyContent: 'flex-end'
  }
};
/**
 *
 */
function AppbarLogo(): JSX.Element {
  const logoUrl = useLogo('InlineLockup');
  return (
    <div
      style={{
        flex: 1
        // maxHeight: APP_STYLE.toolbarHeightPx - 10,
      }}
    >
      <img alt="Farm HQ" src={logoUrl} style={STYLES.logo} />
    </div>
  );
}

/**
 * @param p
 */
function getPathBase(p: string): string {
  const pathSplit = p.split('/');
  const pathName = pathSplit[1];
  return pathName ?? '';
}

const useStyles = makeStyles(() => {
  return createStyles({
    nav: {
      display: 'flex',
      flexDirection: 'row'
    },

    navItem: {
      minWidth: 60
    }
  });
});

type Props = React.PropsWithChildren<{
  links: ReadonlyArray<{ name: IconKey; path: string }>;
  classes?: {
    appbar?: string;
    toolbar?: string;
  };
}>;

export const DashboardAppBar = React.memo<Props>(({ classes, links }) => {
  const { handleCloseMenu, menuOpen, handleOpenMenu, menuAnchor } =
    useMenuState();
  const history = useHistory();
  const showAppbarLinks = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md')
  );

  const ownClasses = useStyles();
  const { pathname } = useLocation();

  const pathBase = getPathBase(pathname);
  const menuBackdropProps = React.useMemo(
    (): Partial<BackdropProps> => ({
      onClick: () => handleCloseMenu()
    }),
    [handleCloseMenu]
  );

  return (
    <React.Fragment>
      <UserAccountMenu
        BackdropProps={menuBackdropProps}
        anchorEl={menuAnchor}
        links={links}
        onClose={handleCloseMenu}
        open={menuOpen}
      />
      <MuiAppBar
        className={classes?.appbar}
        data-cy={testIds.appBar.root}
        elevation={1}
        id={testIds.appBar.root}
        position="absolute"
      >
        <Toolbar className={classes?.toolbar} component="nav">
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={3}>
              <NavLink to="/">
                <AppbarLogo />
              </NavLink>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={6}>
              {showAppbarLinks ? (
                <Tabs centered value={pathBase} variant="standard">
                  {links.map(({ path, name }) => {
                    const itemBasePath = getPathBase(path);
                    return (
                      <Tab
                        className={ownClasses.navItem}
                        data-cy={name}
                        href={path}
                        id={name}
                        key={path}
                        label={name}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(path);
                        }}
                        selected={itemBasePath === pathBase}
                        value={itemBasePath}
                      />
                    );
                  })}
                </Tabs>
              ) : null}
            </Grid>
            <Grid item xs={2}>
              {appConfig.deployment === 'PROD' ? null : (
                <Typography variant="overline">
                  {appConfig.deployment}
                </Typography>
              )}
            </Grid>
            <Grid container item justifyContent="flex-end" xs={1}>
              <IconBtn id={testIds.appBar.menuToggle} onClick={handleOpenMenu}>
                <AppIcon iconKey="farm" />
              </IconBtn>
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </React.Fragment>
  );
});
DashboardAppBar.displayName = 'AppBar';

/**
 *
 */
export function NoFarmsAppBar(): JSX.Element {
  return (
    <AppBar position="static">
      <Toolbar style={STYLES.toolbar}>
        <AppbarLogo />
        <ShowOnlyIf userIsAuthenticated>
          <StyledLink
            data-cy={testIds.auth.signOut.linkTo}
            id={testIds.auth.signOut.linkTo}
            linkTo="/sign-out"
          >
            Sign Out
          </StyledLink>
        </ShowOnlyIf>
      </Toolbar>
    </AppBar>
  );
}
