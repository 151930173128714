import type { TableCellProps } from '@material-ui/core';
import { Grow, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import type { ConfigSensorName } from 'src/devices/sensors';
import Sensors, { SensorConfigs } from 'src/devices/sensors';
import ChoiceField from './ChoiceField';
import EditPressureThresholds from './EditPressureThresholds';
import { MagnetForm } from './MagnetForm';
import type { FieldProps } from './NumericTextField';
import { NumericTextField } from './NumericTextField';

/**
 * @param props
 * @param props.sensorName
 * @param props.fieldName
 * @param props.initialValue
 */
function Form<S extends ConfigSensorName>(props: FieldProps<S>) {
  const { sensorName, fieldName, initialValue, deviceId } = props;
  if (
    SensorConfigs.isSensorWithSensorPort(sensorName) &&
    fieldName === 'sensorPort'
  ) {
    return (
      <ChoiceField
        deviceId={deviceId}
        fieldName="sensorPort"
        initialValue={initialValue}
        sensorName={sensorName}
      />
    );
  }
  if (sensorName === 'reel' || sensorName === 'wheel') {
    if (fieldName === 'sprinklerType') {
      return (
        <ChoiceField
          deviceId={deviceId}
          fieldName="sprinklerType"
          initialValue={initialValue}
          sensorName="reel"
        />
      );
    } else if (fieldName === 'nMagnets') {
      return (
        <MagnetForm
          deviceId={deviceId}
          initialValue={initialValue}
          sensorName={sensorName}
        />
      );
    }
  } else if (SensorConfigs.isKeyOfPressureThresholds(fieldName)) {
    return <EditPressureThresholds deviceId={deviceId} />;
  }
  if (sensorName === 'pressureSwitch' && fieldName === 'switchType') {
    return (
      <ChoiceField
        deviceId={deviceId}
        fieldName={fieldName}
        initialValue={initialValue}
        sensorName={sensorName}
      />
    );
  }

  return <NumericTextField {...props} />;
}

/**
 * @param props
 * @param props.fieldName
 * @param props.value
 * @param props.sensorName
 * @param props.isEditing
 * @param props.children
 * @param props.initialValue
 * @param props.deviceId
 */
export function SensorKeyValueRow<S extends ConfigSensorName>({
  fieldName,
  initialValue,
  sensorName,
  deviceId,
  isEditing,
  children
}: React.PropsWithChildren<FieldProps<S>> & {
  isEditing: boolean;
}): JSX.Element | null {
  const formatKey = Sensors.useFormatKey();
  const formatValue = Sensors.useFormatValue();
  const cellPadding: TableCellProps['padding'] = 'none';
  const testId = `${fieldName}-row`;
  const keyFormatted = formatKey(fieldName);

  if (keyFormatted === null) {
    return null;
  }

  return (
    <TableRow data-cy={testId} id={testId}>
      {isEditing ? (
        <Grow in>
          <TableCell colSpan={3} padding={cellPadding}>
            <Form
              deviceId={deviceId}
              fieldName={fieldName}
              initialValue={initialValue}
              sensorName={sensorName}
            />
          </TableCell>
        </Grow>
      ) : (
        <React.Fragment>
          <TableCell align="left" padding={cellPadding}>
            {keyFormatted}
          </TableCell>
          <TableCell align="center" padding={cellPadding}>
            {formatValue(fieldName, initialValue)}
          </TableCell>
          <TableCell align="right" padding={cellPadding}>
            {children}
          </TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
}
