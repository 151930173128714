import { isNumber } from 'lodash';
import GeoPoints from 'src/geo/points';
import { isArrayOrReadonlyArray, isNullish } from 'src/types';
import { appLogger } from 'src/utilities';

import type { PointGeoJson } from 'src/geo';
import type { ReelRunObservationReport } from './RunObservationReports';

type GunPositions = {
  readonly gunStartPointGeoJSON: PointGeoJson;
  readonly gunEndPointGeoJSON: PointGeoJson;
};
type PartialReport = Partial<GunPositions> & ReelRunObservationReport;
type CompleteReport = GunPositions & Partial<ReelRunObservationReport>;
type CompletedReports = readonly CompleteReport[];

/**
 * Calculate panes of heat map based on row direction , azimuth, reel run reports,
 * and provided start point (TODO:where does this come from?)
 *
 * The gun start point is the location the run started at
 * @param args
 * @param args.observations
 * @param args.azimuth
 * @param args.distanceMmMax
 * @param args.initialGunPosition
 * @param args.gunPositionMax
 */
function addGunPositionsToObservationReports({
  observations,
  azimuth,
  distanceMmMax,
  gunPositionMax
}: {
  readonly observations: readonly ReelRunObservationReport[];
  readonly azimuth: number;
  readonly distanceMmMax: number;
  readonly gunPositionMax: PointGeoJson;
}): CompletedReports {
  const [firstReport] = observations;

  if (observations.length === 0 || isNullish(firstReport)) {
    appLogger.log('BAILING OUT OF REEL RUN HEATMAPS -  NO REPORTS IN ARRAY');
    return [];
  }
  const firstDistance = firstReport.distanceObservedMm ?? 0;
  const firstElapsedDistance = distanceMmMax - firstDistance;
  const initialProjection = GeoPoints.make.projection({
    azimuth,
    distanceMm: firstElapsedDistance,
    origin: gunPositionMax
  });
  const initializer: CompleteReport = {
    ...firstReport,
    distanceObservedMm: distanceMmMax - firstElapsedDistance,
    gunEndPointGeoJSON: initialProjection.geometry,
    gunStartPointGeoJSON: gunPositionMax
  };

  /**
   * Transform the list of reports into a list of completed reports which include
   * gps location.
   *
   * @param acc - reel run reports with geoJSON points added in
   * @param current - the report that needs location points added
   * @param i - number in the list
   * @returns A list of completed reports to make polygons from or null if the process fails
   */
  const result = observations.reduce<CompletedReports>(
    (acc: CompletedReports, current: PartialReport, i): CompletedReports => {
      const previousReport = {
        ...acc[i - 1]
      };
      /*
      Well you can take the last distance report which will be the 
      largest negative number and add that number to all of the reports
      And that will at least give a positive number for everything
      */
      const prevDistance = previousReport.distanceObservedMm;
      const currentDistance = current.distanceObservedMm;
      if (!(isNumber(prevDistance) && isNumber(currentDistance))) {
        return acc;
      }
      const distanceTraveledSincePrevious = prevDistance - currentDistance;
      // use the previous end as the start
      const newGunStartPoint = previousReport.gunEndPointGeoJSON;
      if (isNullish(newGunStartPoint)) {
        throw new Error(`No start point`);
      }

      // get the new end point
      const newGunEndPoint = GeoPoints.make.projection({
        azimuth,
        distanceMm: distanceTraveledSincePrevious,
        origin: newGunStartPoint
      }).geometry;
      // add the new report to the list
      return [
        ...acc,
        {
          ...previousReport,
          distanceObservedMm: currentDistance,
          gunEndPointGeoJSON: newGunEndPoint,
          gunStartPointGeoJSON: newGunStartPoint
        }
      ];
    },
    [initializer]
  );

  return result;
}

/**
 * Find the most recent location of the gun
 *
 * @param reports
 */
function getLastGunPosition(
  reports: CompletedReports | null | undefined
): PointGeoJson | null | undefined {
  if (isArrayOrReadonlyArray(reports)) {
    const report = reports[reports.length - 1];
    if (!isNullish(report)) {
      return report.gunEndPointGeoJSON;
    }
  }
  return null;
}

export type { CompletedReports };
const HeatMaps = {
  addGunPositionsToObservationReports,

  getLastGunPosition
};
export default HeatMaps;
